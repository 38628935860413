import { FC, useMemo, useRef } from "react";

import { Heading, LoaderWrapperCustom, OdinChart } from "@/components";
import { GaugeChart } from "@/components/Chart/GaugeChart";
import { useInViewportElement, useAppSelector } from "@/hooks";
import { updateOdinChartsState } from "@/store/focus/updateOdinCharts";
import { parseTextAsObject, prepareChartData } from "@/utils";

import { IChatMessage } from "../../../../../../types/odinChat";
import { TableChatObj } from "../Tables/OdinChatTable/OdinChatTable";

import styles from "./Charts.module.scss";

interface IProps {
  message: IChatMessage;
}

const DEFAULT_CHART_HEIGHT = 190;

export const OdinChatCharts: FC<IProps> = ({ message }) => {
  const rawData = parseTextAsObject(message?.text || "");
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewportElement(ref, false);
  const chart = useMemo(() => (rawData ? prepareChartData(rawData) : null), [rawData]);
  const { list, idToUpdate } = useAppSelector(updateOdinChartsState);

  const isCurrentUpdate = message.hash === idToUpdate?.hash;

  const inProgress = list?.filter((msg) => msg.status === "In progress");

  if (!chart) {
    return (
      <div className={styles.error}>
        <p>Data is not available.</p>
      </div>
    );
  }
  return (
    <div className={styles.container} ref={ref}>
      <LoaderWrapperCustom loading={isCurrentUpdate && !!inProgress?.length} transparent>
        <Heading className={styles.heading} type="h5">
          <span>Odin:</span>
          {rawData?.table ? rawData.table.title : chart.title}
        </Heading>
        <div className={styles.content}>
          {isInViewport ? (
            rawData?.table ? (
              <TableChatObj data={rawData.table} />
            ) : (
              <>
                {chart.gaugeData ? (
                  <GaugeChart
                    height={DEFAULT_CHART_HEIGHT}
                    gaugeData={chart.gaugeData}
                    title={chart.title}
                  />
                ) : (
                  <OdinChart height={DEFAULT_CHART_HEIGHT} chartData={chart} isChartPreviw />
                )}
              </>
            )
          ) : (
            <div style={{ height: DEFAULT_CHART_HEIGHT, width: "100%" }}></div>
          )}
        </div>
      </LoaderWrapperCustom>
    </div>
  );
};
