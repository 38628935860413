import { FC, Fragment, useCallback, useMemo, useState } from "react";

import { Filter, Heading, Loader, Navigation, Pagination } from "@/components";
import { useScrollNavigation, useAppSelector } from "@/hooks";
import { userRole } from "@/store/profile";
import { IPortfolio } from "@/types";

import { DragPortfolioItem } from "../DragPortfolioItem/DragPortfolioItem";

import styles from "./SharedPortfolios.module.scss";

interface IProps {
  list: IPortfolio[];
  loading: boolean;
}

export const SharedPortfolios: FC<IProps> = ({ list, loading }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const { isSuperAdmin, isAdmin } = useAppSelector(userRole);

  const totalPages = list ? Math.ceil(list.length / perPage) : null;
  const paginatedList = useMemo(
    () => (list ? list.slice((page - 1) * perPage, (page - 1) * perPage + perPage) : null),
    [page, list, perPage]
  );

  const { toRightHandler, toLeftHandler, navState, scrollItemRef, elementRef } =
    useScrollNavigation(paginatedList);

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);

  const disabled = !(list && list.length > 0);

  return (
    <div className={styles.container}>
      <div className={styles.headingGroup}>
        <Heading type="h3" className={styles.heading}>
          Shared Portfolios
        </Heading>
        {/* <Filter disabled={disabled} /> */}
      </div>

      <div className={styles.wrapper}>
        {!disabled && (
          <Navigation onLeft={toLeftHandler} onRight={toRightHandler} navState={navState} />
        )}
        <div className={styles.scrollContainer}>
          <div className={styles.inner} ref={scrollItemRef}>
            {loading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                {paginatedList ? (
                  paginatedList.map((item, index) => (
                    <div className={styles.portfolioItem} key={item.id}>
                      <DragPortfolioItem
                        elementRef={elementRef}
                        index={index}
                        portfolio={item}
                        hasMenu
                      />
                    </div>
                  ))
                ) : (
                  <div className={styles.empty}>No Common Templates yet.</div>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <Pagination
          onPage={setPage}
          onChangePerPage={selectPerPageHandler}
          perPage={perPage}
          currentPage={page}
          totalCount={totalPages}
          count={list?.length}
          perPageList={[10, 20, 50, 100]}
        />
      </div>
    </div>
  );
};
