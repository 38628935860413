import { useCallback, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "@/hooks";
import { ProfileService } from "@/services";
import { profileInfo, updateProfileInfo } from "@/store/profile";
import { IUserUpdate } from "@/types";
import { validate, notification } from "@/utils";

type FormType = Record<string, { value: string; error: string | null }>;

export const useForm = (formInit: FormType) => {
  const [form, setForm] = useState<FormType>(formInit);
  const [isDisabled, setDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(profileInfo);

  useEffect(() => {
    if (!profile) return;
    setForm((state) => ({
      ...state,
      firstName: {
        value: profile.first_name || "",
        error: "",
      },
      lastName: {
        value: profile.last_name || "",
        error: "",
      },
      email: {
        value: profile.email || "",
        error: "",
      },
      phone: {
        value: profile.phone || "",
        error: "",
      },
    }));
  }, [profile]);

  const updateUserData = (updateData: IUserUpdate) => {
    dispatch(updateProfileInfo(updateData));
  };

  useEffect(() => {
    const { firstName, lastName, password, email, phone } = form;
    const updatedData: IUserUpdate = {};

    if (password.value) {
      updatedData.password = password.value;
    }
    if (firstName.value !== profile?.first_name) {
      updatedData.first_name = firstName.value;
    }
    if (lastName.value !== profile?.last_name) {
      updatedData.last_name = lastName.value;
    }
    if (email.value !== profile?.email) {
      updatedData.email = email.value;
    }
    if (phone.value !== profile?.phone) {
      updatedData.phone = phone.value;
    }
    if (Object.keys(updatedData).length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form, profile?.email, profile?.first_name, profile?.last_name, profile?.phone]);

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!form) return;

    const { firstName, lastName, password, email, phone } = form;

    const pass = password?.value.trim();
    const lstName = lastName?.value.trim();
    const fstName = firstName?.value.trim();
    const phoneNumber = phone?.value.trim();
    const userEmail = email?.value.trim();

    let updatedData: IUserUpdate = {};

    if (pass) {
      updatedData.password = pass;
    }
    if (fstName !== profile?.first_name) {
      updatedData.first_name = fstName;
    }
    if (lstName !== profile?.last_name) {
      updatedData.last_name = lstName;
    }
    if (userEmail !== profile?.email) {
      updatedData.email = userEmail;
    }
    if (phoneNumber !== profile?.phone) {
      updatedData.phone = phoneNumber;
    }

    const [validatedForm, isValidForm] = validate(form);
    const [validated, isValid] = validate(validatedForm, ["password", "repassword"]);

    if (isValid && isValidForm) {
      if (isValid) {
        updateUserData(updatedData);
        updatedData = {};
        setForm((state) => ({
          ...state,
          password: {
            value: "",
            error: "",
          },
          repassword: {
            value: "",
            error: "",
          },
        }));
      } else {
        setForm(validated);
      }
    } else {
      setForm(validated);
    }
  };

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    const fieldKey = ev.target.name;
    setForm((prev) => ({
      ...prev,
      [fieldKey]: { error: null, value: inputValue },
    }));
  }, []);

  return { form, submitHandler, changeHandler, isDisabled };
};
