import { useState } from "react";

export const useUploadFile = () => {
  const [file, setFile] = useState<File | null>(null);

  const uploadHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      setFile(ev.target.files[0]);
    }
  };

  const fileReset = () => {
    setFile(null);
  };

  return {
    file,
    fileReset,
    uploadHandler,
  };
};
