import React, { FC, Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Button } from "@/components";

import styles from "./Cookies.module.scss";

import CookieIcon from "@images/cookie.png";

interface IProps {
  children: React.ReactNode;
}

const COOKIE_DELAY = 3_000;
const COOKIE_KEY = "odin-ultra-cookie";
const MAX_COOKIE_AGE = process.env.NODE_ENV === "production" ? 30 * 24 * 3600 : 60; // 30 days or 60s for dev mode

export const Cookies: FC<IProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookies, setCookies, deleteCookie] = useCookies();

  const odinCookie = cookies[COOKIE_KEY];

  const rejectHandle = () => {
    setIsVisible(false);
    setCookies(COOKIE_KEY, "rejected", { maxAge: MAX_COOKIE_AGE });
  };
  const acceptHandle = () => {
    setCookies(COOKIE_KEY, "accepted", { maxAge: MAX_COOKIE_AGE });
    setIsVisible(false);
  };

  useEffect(() => {
    if (!odinCookie) {
      setTimeout(() => {
        setIsVisible(true);
      }, COOKIE_DELAY);
    }
    // deleteCookie(COOKIE_KEY);
  }, []);

  return (
    <Fragment>
      <div className={`${styles.container} ${isVisible ? styles.show : ""}`}>
        <div className={styles.inner}>
          <div className={styles.topGroup}>
            <img src={CookieIcon} alt="cookie" />
            <h3 className={styles.title}>We value your privacy</h3>
          </div>
          <p className={styles.text}>
            Our website uses cookies to better serve you. We use cookies to enhance your browsing
            experience, personalize content, and analyze site traffic. We do not share information
            about your use of our site with advertisers and others.
          </p>
          <div className={styles.btnGroup}>
            <div className={styles.btnWrapper}>
              <Button text="Reject" type="white" onClick={rejectHandle} />
            </div>
            <div className={styles.btnWrapper}>
              <Button text="Accept" type="white" onClick={acceptHandle} />
            </div>
          </div>
        </div>
      </div>
      {children}
    </Fragment>
  );
};
