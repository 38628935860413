import { FC, Fragment, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Dropdown, FooterLayout, GoBack, InputItem, Loader, RadioList } from "@/components";
import { Dialog } from "@/components/Dialogs/Dialog";
import { Slogan } from "@/components/Slogan/Slogan";
import { BDF_COMPANY_ID, UserRoleEnum, productList, usersRoleList } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { companiesState, fetchCompanies } from "@/store/companies";
import { profileInfo, userRole } from "@/store/profile";
import { IRole } from "@/types";

import { useInviteForm } from "./hooks/useInviteForm";
import { ProductFieldsRow } from "../UsersManagement/components/UserPermissions/UserPermissions";

import styles from "./UserInviteForm.module.scss";

interface IProps {
  noSlogan?: boolean;
}

const formInit: Record<string, { value: string; error: string }> = {
  email: {
    value: "",
    error: "",
  },
};

const REGULAR_USER = usersRoleList[0];
const ADMIN_USER = usersRoleList[2];

export const UserInviteForm: FC<IProps> = ({ noSlogan }) => {
  const { isAdmin, isSuperAdmin, isSales } = useAppSelector(userRole);
  const { companies, isLoading } = useAppSelector(companiesState);
  const { profile } = useAppSelector(profileInfo);
  const { role, company } = profile || {};
  const navigate = useNavigate();

  const {
    form,
    loading,
    disable,
    completeStatus,
    selectedCompany,
    selectedRole,
    isCompanyOwner,

    selectProductHandle,
    selectStatusHandle,
    statusList,
    activeStatus,
    product,
    isVisible,

    slelectHandler,
    submitHandler,
    changeHandler,
    selectRoleHandler,
  } = useInviteForm(formInit, REGULAR_USER, companies);

  const dispatch = useAppDispatch();

  const availableCompanies = useMemo(() => {
    if (selectedRole.key === "sales") {
      const foundCompany = companies?.find((c) => c.id === BDF_COMPANY_ID);
      return foundCompany ? [{ key: `${foundCompany.id}`, value: foundCompany.name }] : null;
    }
    return (
      companies
        ?.filter((c) => c.is_active)
        .map((company) => ({
          key: `${company.id}`,
          value: company.name,
        })) || null
    );
  }, [companies, selectedRole]);

  const availableUserRoles = useMemo(() => {
    if (isSales) return [REGULAR_USER];
    else if (isAdmin) return [REGULAR_USER, ADMIN_USER];
    else if (isSuperAdmin) return usersRoleList;
    else return [];
  }, [isSales, usersRoleList]);

  const ownerCompany =
    isCompanyOwner && company ? { key: `${company.id}`, value: company.name } : null;

  useEffect(() => {
    if (role === UserRoleEnum.SUPERADMIN || role === UserRoleEnum.SALES) dispatch(fetchCompanies());
  }, [role]);

  return (
    <FooterLayout>
      <div className={styles.container}>
        {noSlogan ? null : <Slogan size="medium" />}
        <div className={styles.loaderContainer}>{loading && <Loader />}</div>

        {completeStatus.error ? (
          <Fragment>
            <Dialog title="Invite User" type="error" messages={completeStatus.error} noAction />
          </Fragment>
        ) : (
          <div className={styles.formContainer}>
            <p className={styles.title}>User Invite</p>
            {loading && <div className={styles.frontBg}></div>}
            <form onSubmit={submitHandler}>
              <div className={styles.formInnerWrapper}>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label="User invite Email"
                    placeholder="email@company.com"
                    name="email"
                    type="email"
                    onChange={changeHandler}
                    error={form.email.error}
                    value={form.email.value}
                  />
                </div>
                <div className={styles.dropdownWrapper}>
                  <Dropdown<"role", IRole["key"]>
                    list={availableUserRoles}
                    selected={selectedRole}
                    onSelect={selectRoleHandler}
                    label="User Role"
                    name="role"
                    disable={availableUserRoles.length <= 1}
                  />
                </div>
                {availableCompanies !== null ? (
                  <div className={styles.dropdownWrapper}>
                    <Dropdown
                      key={"1"}
                      list={availableCompanies}
                      selected={selectedCompany}
                      onSelect={slelectHandler}
                      label="Company"
                      name="company"
                      placeholder="select company"
                      loading={isLoading}
                      disable={isCompanyOwner || (isSuperAdmin && selectedRole.key === "sales")}
                    />
                  </div>
                ) : (
                  <div className={styles.dropdownWrapper}>
                    <Dropdown
                      key={"2"}
                      list={ownerCompany ? [ownerCompany] : []}
                      selected={ownerCompany}
                      onSelect={() => {
                        //
                      }}
                      label="Company"
                      name="company"
                      disable={true}
                    />
                  </div>
                )}
                {isVisible ? (
                  <div className={styles.productWrapper}>
                    <p>Product Permissions</p>
                    <ProductFieldsRow
                      onSelectProduct={selectProductHandle}
                      onSelectStatus={selectStatusHandle}
                      productList={productList}
                      statusList={statusList}
                      product={product}
                      status={activeStatus}
                      flow="column"
                    />
                  </div>
                ) : null}
              </div>
              <div className={styles.btnGroup}>
                <GoBack onClick={() => navigate(-1)} text="Go Back" type="secondary" />
                <div>
                  <Button text="Invite" disabled={disable} />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </FooterLayout>
  );
};
