import { AxiosResponse } from "axios";

import { ICompareResponseData } from "@/types";

import { ICompareConfig } from "./../types/compare";
import axios from "./axios.config";

export const compareService = {
  abort: new AbortController(),

  getCompareResult(config: ICompareConfig): Promise<AxiosResponse<ICompareResponseData>> {
    this.abort = new AbortController();
    return axios.post(`/strategies/compare`, config, {
      signal: this.abort.signal,
    });
  },
};
