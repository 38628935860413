import { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchMyStrategiesData, selectMyStrategies } from "@/store/strategies/my_strategies";
import { fetchMetricsList, setActiveStrategy } from "@/store/strategies/strategies";

interface IParams {
  isEditMode?: boolean;
  strategyId?: string;
}

export const useGetData = ({ isEditMode, strategyId }: IParams) => {
  const [loadingData, setLoadingData] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null);
  const { list } = useAppSelector(selectMyStrategies);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getDataForCreate = () => {
      setIsEmpty(false);
    };
    const getDataForEdit = async () => {
      if (Number.isInteger(Number(strategyId)) && strategyId) {
        await dispatch(fetchMetricsList());
        await dispatch(fetchMyStrategiesData());
        setLoadingData(false);
        setIsEmpty((prev) => (prev ? true : false));
      } else {
        setIsEmpty(true);
      }
    };

    if (!isEditMode) getDataForCreate();
    if (isEditMode) {
      // return empty if in edit mode and [:id] not provided
      if (!Number.isInteger(Number(strategyId))) setIsEmpty(true);
      else {
        setLoadingData(true);
        getDataForEdit();
      }
    }
  }, [strategyId, isEditMode]);

  useEffect(() => {
    if (list && list.length > 0 && strategyId && Number.isInteger(Number(strategyId))) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(strategyId));
      if (foundStrategy && foundStrategy.permissions?.has_write) {
        dispatch(setActiveStrategy(foundStrategy));
        return;
      }
      setIsEmpty(true);
    }
  }, [list, strategyId]);

  return {
    isEmpty,
    isLoadingData: isEditMode && loadingData,
  };
};
