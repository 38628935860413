import { ComponentWithPermissions } from "@/components";

const odinChatLoader = () =>
  import("./OdinChat").then(({ OdinChat }) => ({
    default: OdinChat,
  }));

export const OdinChatPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={odinChatLoader}
      title="Ask Odin"
      pageKey={"Odin-Chat"}
    />
  );
};
