import { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";

import { Button, Loader, FileSelect } from "@/components";
import { InputTextItem } from "@/components/InputItem/InputTextItem";
import { ModalLayout } from "@/components/Layout/ModalLayout/ModalLayout";
import { FeedbackTypeEnum } from "@/constants";

import { useFeedback } from "./hooks/useFeedback";
import { useUploadFile } from "./hooks/useUploadFile";

import styles from "./FeedbackModal.module.scss";

import { ReactComponent as BugIcon } from "@images/bug.svg";
import { ReactComponent as CommentIcon } from "@images/comment.svg";
import { ReactComponent as SuccessIcon } from "@images/success.svg";
import { ReactComponent as SuggestionIcon } from "@images/suggestion.svg";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const feddbackButtons = [
  {
    label: "Bug",
    value: FeedbackTypeEnum.BUG,
    Icon: BugIcon,
  },
  {
    label: "Comment",
    value: FeedbackTypeEnum.COMMENT,
    Icon: CommentIcon,
  },
  {
    label: "Suggestion",
    value: FeedbackTypeEnum.SUGGESTION,
    Icon: SuggestionIcon,
  },
];

const PLACEHOLDER = "Image file not selected.";
const EXTENTIONS = ["image/png", "image/jpg", "image/jpeg"];

export const FeedbackModal: FC<IProps> = ({ show, onClose }) => {
  const [type, setType] = useState(FeedbackTypeEnum.BUG);
  const [text, setText] = useState("");

  const { isLoading, sendFeedback, isOk, setOk } = useFeedback();
  const { file, uploadHandler, fileReset } = useUploadFile();

  const handleTypeSelect = (type: FeedbackTypeEnum) => {
    setType(type as FeedbackTypeEnum);
    setOk(null);
  };

  const handleTextInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    setOk(null);
  };

  const handleCancel = () => {
    onClose();
    setOk(null);
    setText("");
    setType(FeedbackTypeEnum.BUG);
    fileReset();
  };

  const handleSend = () => {
    const form = new FormData();
    form.append("body", text);
    form.append("type", type);
    if (file) form.append("file", file);
    sendFeedback(form);
  };

  useEffect(() => {
    if (isOk) {
      setText("");
      setType(FeedbackTypeEnum.BUG);
      fileReset();
    }
  }, [isOk]);

  return (
    <>
      <ModalLayout show={show} onClose={isOk ? null : handleCancel}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h2>Send us some Feedback!</h2>
            {isOk ? (
              <div className={styles.success}>
                <div className={styles.successInner}>
                  <h3>Thank you for your message!</h3>
                  <SuccessIcon />
                </div>
                <div className={styles.close}>
                  <div>
                    <Button onClick={handleCancel} type="primary" text="Close" />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.inner}>
                <div className={styles.infobox}>
                  <p>
                    Do you have a suggestion or found some bug? <br />
                    Let us know in the field bellow.
                  </p>
                </div>
                <div className={styles.buttonTypes}>
                  {feddbackButtons.map(({ Icon, label, value }) => (
                    <FeedbackButton
                      label={label}
                      value={value}
                      isActive={value === type}
                      onSelect={handleTypeSelect}
                      key={value}
                    >
                      <Icon />
                    </FeedbackButton>
                  ))}
                </div>
                <div className={styles.textInput}>
                  <InputTextItem
                    onChange={handleTextInput}
                    value={text}
                    name="textInput"
                    label="Your Message"
                    placeholder="Describe your issue or idea..."
                  />
                </div>
                <div className={styles.uploadInfo}>
                  <p>
                    Issue image: <span>{file ? file.name : PLACEHOLDER}</span>
                  </p>
                  <div className={styles.loader}>{isLoading && <Loader />}</div>
                </div>
                <div className={styles.modalFooter}>
                  <div className={styles.buttons}>
                    <FileSelect
                      disabled={false}
                      onChange={uploadHandler}
                      text="Select Issue Image"
                      helpText={file ? file.name : PLACEHOLDER}
                      isSelected={!!file}
                      extentions={EXTENTIONS}
                    />
                  </div>

                  <div className={styles.buttons}>
                    <Button onClick={handleCancel} text="Close" />
                    <Button
                      disabled={isLoading || text.length <= 3 || isOk === false}
                      onClick={handleSend}
                      text="Send Feedback"
                      type="secondary"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

interface IFeedbackButtonProps {
  label: string;
  children: ReactNode;
  isActive: boolean;
  value: FeedbackTypeEnum;
  onSelect: (type: FeedbackTypeEnum) => void;
}

export const FeedbackButton: FC<IFeedbackButtonProps> = ({
  children,
  label,
  isActive,
  onSelect,
  value,
}) => {
  return (
    <div className={`${styles.feedbackButtonContainer} ${isActive ? styles.active : ""}`}>
      <div className={styles.feedbackButtonIconWrapper} onClick={() => onSelect(value)}>
        {children}
      </div>
      <p>{label}</p>
    </div>
  );
};
