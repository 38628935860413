import { Navigate, Route, Routes } from "react-router-dom";

import { FooterLayout } from "@/components";
import { ROUTES } from "@/constants";
import { MyData, MyDataDetails } from "@/features";

export const UserData = () => {
  return (
    <FooterLayout showLiveMarketSlider>
      <Routes>
        <Route index element={<MyData />} />
        <Route path="details/:id" element={<MyDataDetails />} />
        <Route path="*" element={<Navigate to={ROUTES.userData.path} />} />
      </Routes>
    </FooterLayout>
  );
};
