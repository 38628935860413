import { FC } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "@/constants";
import { FeedbackModal } from "@/features";

import styles from "./Footer.module.scss";

interface IProps {
  withoutStyle?: boolean;
}

export const Footer: FC<IProps> = ({ withoutStyle }) => {
  const year = new Date().getFullYear();
  return (
    <div className={`${styles.container} ${withoutStyle ? styles.withoutStyle : ""}`}>
      <div className={styles.inner}>
        © 2015-{year} Big Data Federation, Inc. All rights reserved.{" "}
        <span>
          <Link to={ROUTES.disclosures.path}>Disclosures</Link>{" "}
          <Link to={ROUTES.privacyPolicy.path}>Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
};
