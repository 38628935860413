import { useEffect, useRef, useState } from "react";

import { pagesPermissionService } from "@/services";
import { IProfilePagePermissionType, IUser } from "@/types";
import { notification } from "@/utils";
import { pagePermission } from "@/utils/pagesPermissions";

export const useGetUserAccessStatus = (selectedUser: IUser | null) => {
  const [progress, setProgress] = useState(false);
  const configRef = useRef<Record<string, IProfilePagePermissionType> | null>(null);
  const [accessConfig, setAccessConfig] = useState<Record<
    string,
    IProfilePagePermissionType
  > | null>(null);

  useEffect(() => {
    const getUserPermissions = async () => {
      try {
        const { data } = await pagesPermissionService.getPagesStatus(selectedUser?.email || "");
        const config = pagePermission.getProduct(data);
        setAccessConfig(config);
        configRef.current = config;
      } catch (e) {
        notification.error("Can`t get product Data!");
      } finally {
        setProgress(false);
      }
    };

    setProgress(true);
    getUserPermissions();
  }, []);

  return {
    configRef,
    progress,
    accessConfig,
    setAccessConfig,
    setProgress,
  };
};
