import { FC, memo, useEffect, useMemo, useState } from "react";

import { LoaderWrapper } from "@/components/LoaderWrapper/LoaderWrapper";
import { IFolder } from "@/types";

import { TabContent, TabNavItem } from "./components";
import { FolderModal } from "./components/FolderModal/FolderModal";
import { InnerTab } from "./components/InnerTab/InnerTab";

import styles from "./FocusTabs.module.scss";

interface IProps {
  list: IFolder[];
  isLoading: boolean;
}

export const FocusTabs: FC<IProps> = ({ list, isLoading }) => {
  const [activeTab, setActiveTab] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [isNew, setNew] = useState(false);

  const hasTab = useMemo(() => list.some((folder) => folder.id === activeTab), [activeTab, list]);

  useEffect(() => {
    if (hasTab && (!list.length || activeTab)) return;
    setActiveTab(list[0]?.id);
  }, [activeTab, hasTab, list]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleSetNew = (e: boolean) => {
    setNew(e);
  };

  const handleSetActiveTab = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <LoaderWrapper loading={isLoading} atom>
      <div className={styles.tabs}>
        <div className={styles.navWrapper}>
          <ul className={styles.nav}>
            {list.map((folder) => (
              <TabNavItem
                key={folder.id}
                title={folder.name}
                id={folder.id}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                handleShowModal={handleShowModal}
                handleSetNew={handleSetNew}
              />
            ))}
            <TabNavItem
              key={0.1}
              title="+ New Folder"
              id={0.1}
              activeTab={activeTab}
              handleSetActiveTab={handleSetActiveTab}
              isLast
              handleSetNew={handleSetNew}
              handleShowModal={handleShowModal}
            />
          </ul>
        </div>

        <div className={styles.outlet}>
          {list.map((folder) => (
            <TabContent key={folder.id} id={folder.id} activeTab={activeTab}>
              <InnerTab handleShowModal={handleShowModal} folder={folder} />
            </TabContent>
          ))}
        </div>
      </div>
      <FolderModal
        showModal={showModal}
        setShowModal={setShowModal}
        folderId={activeTab}
        list={list}
        isNew={isNew}
        handleSetNew={handleSetNew}
      />
    </LoaderWrapper>
  );
};
