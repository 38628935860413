import dayjs from "dayjs";
import { FC, Fragment, memo, useEffect, useState } from "react";

import { Loader } from "@/components";
import { DATE_ISO_US } from "@/constants";
import { useAppSelector } from "@/hooks";
import { profileInfo } from "@/store/profile";
import { IMessageExtendData, IMessageItem } from "@/types/odinChat";

import { useMessagesObserevr } from "../../hooks/useMessagesObserevr";
import { usePreloadMessages } from "../../hooks/usePreloadMessages";
import { useScrollmessages } from "../../hooks/useScrollMessages";
import { DataChartObj } from "../DataChartObj/DataChartObj";
import { EmptyList } from "../EmptyList/EmptyList";
import { LinksListObj } from "../LinksListObj/LinksListObj";
import { MessageItem } from "../MessageItem/MessageItem";
import { TableChatObj } from "../TableChatObj/TableChatObj";
import { VideoObj } from "../VideoObj/VideoObj";

import styles from "./MessagesListBox.module.scss";

const SHOW_DATE_DELAY_TIME = 1500;

interface IProps {
  list: IMessageItem[][];
  loading: boolean;
  onLoadMoreData: () => void;
  preloader: boolean;
  unreaded: string[] | null;
}

export const MessagesListBox: FC<IProps> = memo(
  ({ list, loading, onLoadMoreData, preloader, unreaded }) => {
    const { profile } = useAppSelector(profileInfo);
    const { disableScrollHandler, listRef, isAutoScroll } = useScrollmessages(
      list,
      preloader,
      loading
    );

    const [newMessageReceivedID] = useMessagesObserevr(loading, unreaded, isAutoScroll);
    const [invewportItemRef] = usePreloadMessages(list, preloader, onLoadMoreData, listRef);

    const isEmptyMessagesList = list.length === 0;

    return (
      <div className={styles.messageContainer} ref={listRef} onScroll={disableScrollHandler}>
        {loading && (
          <div className={styles.laderContainer}>
            <Loader helpText="Please wait, loading chat messages..." atom />
          </div>
        )}
        {!loading && (
          <div className={`${styles.inner} ${isEmptyMessagesList ? styles.empty : ""}`}>
            {isEmptyMessagesList ? (
              <EmptyList />
            ) : (
              <Fragment>
                <div ref={invewportItemRef}></div>
                {list.map((group, idx) => (
                  <Fragment key={`${group[0].date}-${idx}`}>
                    <DateBox dateString={group[0].date} scrollBoxRef={listRef} />
                    <ul className={styles.messagesGroup}>
                      {group.map((messageItem, key) => (
                        <Fragment key={`message-${key}`}>
                          {newMessageReceivedID === messageItem.id && (
                            <li className={styles.newMessageDividerContainer}>
                              <span className={styles.newMessageDividerInner}> New Messages</span>
                            </li>
                          )}
                          <MessageItem
                            messageData={messageItem}
                            profileImage={profile?.profile_image}
                          >
                            {messageItem.data && (
                              <DataObject
                                messageData={messageItem.data}
                                messageId={messageItem.id}
                              />
                            )}
                          </MessageItem>
                        </Fragment>
                      ))}
                    </ul>
                  </Fragment>
                ))}
              </Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
);

interface IDataObjectProps {
  messageData?: IMessageExtendData;
  messageId?: string;
}

export const DataObject: FC<IDataObjectProps> = ({ messageData, messageId }) => {
  if (!messageData) return null;
  if (
    messageData.oneAxis ||
    messageData.twoAxes ||
    messageData.pie ||
    messageData.bar ||
    messageData.stackedBar ||
    messageData.multyAxis ||
    messageData.gauge ||
    messageData.clock
  )
    return <DataChartObj data={messageData} messageId={messageId} />;
  if (messageData.table) return <TableChatObj messageId={messageId} data={messageData.table} />;
  if (messageData.video) return <VideoObj messageId={messageId} data={messageData.video} />;
  if (messageData.linksText) return <LinksListObj text={messageData.linksText} />;
  return null;
};

interface IDateBoxProps {
  dateString: string | null;
  scrollBoxRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const DateBox: FC<IDateBoxProps> = ({ dateString, scrollBoxRef }) => {
  const [isDateVisible, setDateVisible] = useState(true);

  const formatDate = (date: string | null) => {
    if (dayjs(date).isYesterday()) return "Yesterday";
    if (dayjs(date).isToday()) return "Today";
    return dayjs(date).format(DATE_ISO_US);
  };

  useEffect(() => {
    let timerID: NodeJS.Timer | null = null;
    const scrollHandler = (ev: Event) => {
      if (timerID) clearTimeout(timerID);
      timerID = setTimeout(() => {
        setDateVisible(false);
      }, SHOW_DATE_DELAY_TIME);
      setDateVisible(true);
    };

    scrollBoxRef.current?.addEventListener("scroll", scrollHandler);

    return () => {
      scrollBoxRef.current?.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div className={`${styles.dateSeparator} ${isDateVisible ? "" : styles.hidden}`}>
      <time dateTime={formatDate(dateString)}>{formatDate(dateString)}</time>
    </div>
  );
};
