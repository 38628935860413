import { RefObject, useEffect, useState } from "react";

export const useInViewportElement = (
  ref: RefObject<Element>,
  initialValue: boolean,
  { root = null, threshold = 0, rootMargin = "0px" }: IntersectionObserverInit = {}
): boolean => {
  const [isIntersecting, setIntersecting] = useState(initialValue);

  useEffect(() => {
    const element = ref.current as Element;
    if (!element) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        root,
        threshold,
        rootMargin,
      }
    );

    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [ref, root, threshold, rootMargin]);

  return isIntersecting;
};
