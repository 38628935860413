import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";

import { AdminPanelService } from "@/services";
import { IAdminPanelDocuments, ICreatePanelDoc } from "@/types/adminPanel";
import { notification } from "@/utils/notification";

import { RootState } from "..";

export interface IAdminPanelState {
  isLoading: boolean;
  isLoadingDocumnet: boolean;
  documents: IAdminPanelDocuments[] | null;
}

const initialState: IAdminPanelState = {
  isLoading: false,
  isLoadingDocumnet: false,
  documents: [],
};

export const createDocument = createAsyncThunk(
  "adminDocuments/create",
  async (data: ICreatePanelDoc) => {
    const response = await AdminPanelService.createDocuments(data);
    return response.data.result;
  }
);

export const getDocuments = createAsyncThunk("adminDocuments/get", async () => {
  const response = await AdminPanelService.getDocuments();
  return response.data.result;
});

export const uploadDocument = createAsyncThunk(
  "adminDocuments/upload",
  async ({ docId, data }: { docId: number; data: FormData }) => {
    const response = await AdminPanelService.uploadDocument(docId, data);
    return response.data.result;
  }
);

export const deleteDocument = createAsyncThunk("adminDocuments/delete", async (docId: number) => {
  const response = await AdminPanelService.deleteDocument(docId);
  return response.data.result;
});

export const adminDocumentsSlice = createSlice({
  name: "adminDocuments",
  initialState,
  reducers: {
    updateOrdering: (state, action) => {
      state.documents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(createDocument.fulfilled, (state, action) => {
        state.documents?.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDocument.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      //
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.isLoading = false;
      })
      .addCase(getDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      //
      .addCase(uploadDocument.fulfilled, (state, action) => {
        if (!Array.isArray(state?.documents)) return;
        state.documents = state?.documents.map((item: IAdminPanelDocuments) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        state.isLoadingDocumnet = false;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.isLoadingDocumnet = true;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoadingDocumnet = false;
      })
      //
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!Array.isArray(state?.documents)) return;
        state.documents = state.documents?.filter((page) => page.id !== action.payload.id);
        notification.success("Deleted!");
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      });
  },
});

export const { updateOrdering } = adminDocumentsSlice.actions;

const state = (state: RootState) => state;

export const AdminDocumentsState = createSelector(state, (state) => state.adminDocuments);

export default adminDocumentsSlice.reducer;
