class CookiesService {
  kookieKey = "odin-ultra-cookie";
  accept() {
    localStorage.setItem(this.kookieKey, "accept-all");
  }
  isAccepted() {
    const value = localStorage.getItem(this.kookieKey);
    return value === "accept-all" ? true : false;
  }
  isRejected() {
    const value = localStorage.getItem(this.kookieKey);
    return value === "rejected" ? true : false;
  }
  hasCookieState() {
    const value = localStorage.getItem(this.kookieKey);
    return value ? true : false;
  }
  reject() {
    localStorage.setItem(this.kookieKey, "rejected");
  }
}

export const cookieService = new CookiesService();
