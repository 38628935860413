import { Page, Text, View, Document, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { Fragment } from "react";

import { PDFColor, PDFfontSize } from "@/constants";

const styles = StyleSheet.create({
  section: {
    display: "flex",
    flexDirection: "column",
    rowGap: 16,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 40,
  },
  title: {
    fontSize: PDFfontSize.large,
    color: PDFColor.primary,
    fontWeight: 600,
  },
  text: {
    fontSize: PDFfontSize.main,
    color: PDFColor.black50,
    fontWeight: 400,
  },
  textDisclaimer: {
    fontSize: PDFfontSize.main,
    color: PDFColor.black,
    fontWeight: 400,
  },
  subTitle: {
    fontSize: PDFfontSize.main,
    color: PDFColor.primary,
    fontWeight: 500,
    textDecoration: "underline",
    textDecorationColor: PDFColor.primary,
  },
  link: {
    color: PDFColor.secondary,
    paddingLeft: 8,
    paddingRight: 8,
  },
});

export const About = () => {
  return (
    <Fragment>
      <View style={styles.section}>
        <Text style={styles.title}>About Big Data Federation, Inc.</Text>
        <Text style={styles.text}>
          Big Data Federation’s (BDF) end-to-end solution, OdinUltra, brings specialized AI/ML to
          theever more challenging and complex financial world. BDF leverages oceans of
          disorderedpublic data generated at an accelerating pace in today’s dynamically
          interconnected world.Extracting meaning from mass data without advanced technologies is
          humanly impossible.
          <Link style={styles.link} src="https://odinultra.ai">
            OdinUltra
          </Link>{" "}
          is an innovative solution that deciphers mass data leading to actionable
          financialdecisions, and enables better investments through data-driven research, custom
          portfoliobuilding, testing, forward scenario planning, and forecasting company, industry,
          andeconomic fundamentals. Asset managers can enhance existing products and rapidly
          developnew ones with OdinUltra, from idea exploration and construction to verification
          anddeployment, securing a competitive edge in the dynamic world of asset management.
        </Text>
        <Text style={styles.text}>
          The company was founded in 2015 and is headquartered in San Jose, California. We are
          apassionate team of high-caliber engineers, researchers, and data scientists.
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>Disclaimers</Text>
        <Text style={styles.textDisclaimer}>
          Clients and affiliates of BDF may have positions in securities discussed in this Insight
          article.Without making any public or other disclosure except as may be required by
          applicable law,these clients or affiliates may, at any time, buy and sell investment
          instruments of the sectorsdiscussed in this Insight article. Insights are for
          informational purposes only and not asolicitation to invest in any of the products or
          services provided by the adviser.
        </Text>
        <Text style={styles.subTitle}>Limitation of Liability</Text>
        <Text style={styles.textDisclaimer}>
          BDF and its affiliates shall not be liable for any direct, indirect, incidental, special,
          orconsequential damages arising out of Client's use or inability to use the Odin Ultra,
          includingbut not limited to loss of profits or data.
        </Text>
        <Text style={styles.textDisclaimer}>
          Odin Ultra provides market information. It is not to be considered as market research
          andshould not be interpreted as professional or financial advice.
        </Text>
        <Text style={styles.textDisclaimer}>
          Please see the link to disclaimers related to OdinUltra:
          <Link src="https://odinultra.ai/terms" style={styles.link}>
            https://odinultra.ai/terms
          </Link>
        </Text>
      </View>
    </Fragment>
  );
};
