import { FC, memo, useMemo, useState } from "react";

import { LoaderWrapper, ModalLayout } from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { useAppDispatch } from "@/hooks/redux";
import { deleteItemInFolder } from "@/store/focus/folderSelected";
import { FolderTypeEnum, ICommandData, IFolder } from "@/types";

import { useGetFolderCharts } from "../../hooks/useGetChart";
import { FolderItem } from "../FolderItem/FolderItem";
import { PreviewModal } from "../PreviewModal/PreviewModal";

import styles from "./FolderContent.module.scss";

interface IProps {
  folder: IFolder;
}

export const FolderContent: FC<IProps> = memo(({ folder }) => {
  const [idToDelete, setIdToDelete] = useState<number | null>();
  const [commandData, setCommandData] = useState<ICommandData | undefined>();
  const { data, isLoading } = useGetFolderCharts(folder);
  const dispatch = useAppDispatch();
  const chartDataList = Object.keys(data || {});

  const handleDelete = (id: string) => {
    setIdToDelete(folder.saved_data.find(({ command }) => id === command)?.id);
  };

  const handelConfirmDelete = () => {
    if (!idToDelete) return;
    dispatch(deleteItemInFolder(idToDelete));
    setIdToDelete(null);
  };

  const handleCancelDelete = () => {
    setIdToDelete(null);
  };

  const handlePreview = (
    id: string,
    title: string | null,
    type: FolderTypeEnum,
    description: string | null
  ) => {
    setCommandData({ id, type, title, description });
  };

  const closeModal = () => {
    setCommandData(undefined);
  };

  return (
    <LoaderWrapper loading={isLoading} atom fullWidth>
      {chartDataList.length === 0 ? (
        <div className={styles.emptyData}>Folder is empty.</div>
      ) : (
        <div className={styles.container} id="#focus">
          {chartDataList.map((type) => {
            return Object.keys(data?.[type] || {}).map((command, i) => {
              return (
                <FolderItem
                  key={command + i}
                  command={command}
                  type={type as FolderTypeEnum}
                  folder={folder}
                  handleDelete={handleDelete}
                  handlePreview={handlePreview}
                  data={data}
                />
              );
            });
          })}
        </div>
      )}
      <PreviewModal folder={folder} closeModal={closeModal} data={data} commandData={commandData} />
      <ModalLayout show={!!idToDelete} onClose={handleCancelDelete}>
        <Confirm
          onCancel={handleCancelDelete}
          onConfirm={handelConfirmDelete}
          text="Do you really want to delete this Item?"
        />
      </ModalLayout>
    </LoaderWrapper>
  );
});
