import { ComponentWithPermissions } from "@/components";

const marketLoader = () =>
  import("./Market").then(({ Market }) => ({
    default: Market,
  }));

export const MarketViewPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={marketLoader}
      title="Market View"
      pageKey={"Market-View"}
    />
  );
};
