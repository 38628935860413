import { useCallback, useEffect, useMemo, useState } from "react";

import { debounce } from "@/utils";

const SEARCH_DELAY_TIME = 100;

const filterByKey = <U>(value: string, data: U[], fields: Array<keyof U>) => {
  const result = data.filter((row) =>
    fields.some((key) => (row[key] as string).toLowerCase().includes(value.toLowerCase().trim()))
  );
  return result;
};

export const useSearch = <U>(data: U[], fields: Array<keyof U>) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<U[]>([]);

  const searchHandler = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((search = "") => {
        setFilteredData(filterByKey(search, data, fields));
      }, SEARCH_DELAY_TIME),
    [data, fields]
  );

  useEffect(() => {
    debouncedSearch(search);
  }, [search]);

  return {
    search,
    filteredData,
    searchHandler,
  };
};
