import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IEHTable, IExtraEHTable } from "@/types/forecast";
import { notification } from "@/utils/notification";

import { ForecastService } from "../../services/forecastService";
import { RootState } from "../index";

interface IMacroTableState {
  data: IEHTable[];
  extraTable: IExtraEHTable | null;
  isLoading: boolean;
}

const initialState: IMacroTableState = {
  data: [],
  extraTable: null,
  isLoading: false,
};

export const fetchForecastTable = createAsyncThunk("macro/getTabel", async (ticker: string) => {
  const response = await ForecastService.getMacroTracker(ticker);
  const result = {
    meta: {
      ...response.data.result.meta,
      indicator: ticker,
    },
    values: response.data.result.values,
  };
  return result;
});

export const fetchForecastExtraTable = createAsyncThunk(
  "macro/getExtraTabel",
  async (ticker: string) => {
    const response = await ForecastService.getMacroExtraTracker(ticker);
    const result = {
      meta: {
        ...response.data.result.meta,
        indicator: ticker,
      },
      values: response.data.result.values,
    };
    return result;
  }
);

const macroTableSlice = createSlice({
  name: "forecast/macro-table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForecastTable.fulfilled, (state, action) => {
        if (action.payload) {
          state.data.push(action.payload);
        }
        state.isLoading = false;
      })
      .addCase(fetchForecastTable.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchForecastTable.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(fetchForecastExtraTable.fulfilled, (state, action) => {
        if (action.payload) {
          state.extraTable = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(fetchForecastExtraTable.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchForecastExtraTable.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const macroTableState = createSelector(state, (state) => state.forecastTable);

export default macroTableSlice.reducer;
