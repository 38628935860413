import { useCallback, useEffect, useMemo, useState } from "react";

import { ItemType } from "@/components";
import {
  customBasketExtraList,
  directionList,
  rebalanceFrequencyList,
  tradingBasketList,
  updateFrequencyList,
  weightingSchemeList,
} from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useGetWeightingSchemeListQuery } from "@/services/apiQuery/strategies";
import { selectMyStrategies, setBasketTickersList } from "@/store/strategies/my_strategies";
import {
  setBasketTickers,
  setDirection,
  setMaxAllowedWeight,
  setRebalanceFrequency,
  setTradingBasket,
  setUpdateFrequency,
  setWeightScheme,
  strategiesState,
} from "@/store/strategies/strategies";
import {
  ITickersKeyValueData,
  RebalanceFrequencyType,
  TradingBasketType,
  UpdateFrequencyType,
  WeightingScheme,
} from "@/types";
import { notification } from "@/utils";

const findDropdownItem = <U extends string>(
  value: string | null,
  list: ItemType<U>[],
  key: "key" | "value" = "value"
) => {
  return value ? list.find((i) => i[key] === value) || null : null;
};

const parseTickers = (content: string) => {
  const rows = content.replaceAll("\r", "").split("\n");
  const [label] = rows;
  if (label.toLowerCase() === "ticker") {
    return rows.slice(1).map((ticker) => ticker.toLowerCase());
  } else {
    notification.error("Sorry! The current CSV file not valid. Please, try to upload another one.");
    return [];
  }
};

export const useBasketsForm = (tickersList: ITickersKeyValueData) => {
  const dispatch = useAppDispatch();
  const { newStartegyForm } = useAppSelector(strategiesState);
  const { selectedTickersList } = useAppSelector(selectMyStrategies);

  const { updateFrequency, basket, rebalanceFrequency, weightScheme, tickers } =
    newStartegyForm.tradingInstrument.equityBaskets;

  const { isFetching: isWeightingShemeLoading, data: customWeightingSchemeList } =
    useGetWeightingSchemeListQuery(selectedTickersList);

  const selectDirectionHendler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setDirection(item.key));
  }, []);

  const selectTickerHendler = useCallback((name: string, items: ItemType<string>[]) => {
    dispatch(setBasketTickers(items.map(({ key }) => key)));
  }, []);

  const slectTradingBasket = useCallback((name: string, item: ItemType<TradingBasketType>) => {
    dispatch(setTradingBasket(item.key));
  }, []);

  const selectFraquencyHandler = useCallback(
    (name: string, item: ItemType<UpdateFrequencyType>) => {
      dispatch(setUpdateFrequency(item.key));
    },
    []
  );

  const selectRebalanceHandler = useCallback(
    (name: string, item: ItemType<RebalanceFrequencyType>) => {
      dispatch(setRebalanceFrequency(item.key));
    },
    []
  );

  const selectWeightHandler = useCallback((name: string, item: ItemType<WeightingScheme>) => {
    dispatch(setWeightScheme(item.key));
  }, []);

  const onGetListHandler = () => {
    dispatch(setBasketTickersList(tickers));
    dispatch(setWeightScheme(null));
  };

  const uploadTickersHandler = async (file: File) => {
    const content = await file.text();
    const tickers = parseTickers(content);

    if (tickers.length) {
      const exitedTickers = tradingTickers
        .filter((item) => tickers.includes(item.key.toLowerCase()))
        .map(({ key }) => key);
      dispatch(setBasketTickers(exitedTickers));
      dispatch(setBasketTickersList(exitedTickers));
      dispatch(setWeightScheme(null));
    }
  };

  const changeAllowedWeightHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMaxAllowedWeight(ev.target.value));
  };

  const blurHandler = () => {
    const value = newStartegyForm.tradingInstrument.equityBaskets.maxAllowedWeight;
    if (value === "" || Number.isNaN(Number(value))) {
      dispatch(setMaxAllowedWeight(""));
    }
  };

  const tradingTickers = useMemo(() => {
    const result = [...tickersList.basket].sort((a, b) => a.value.localeCompare(b.value));
    result.unshift(
      ...customBasketExtraList,
      ...(tradingBasketList.slice(0, -1) as { key: string; value: string }[])
    );
    return result;
  }, [tickersList]);

  const selectedTradingBasket = useMemo(() => {
    return findDropdownItem(basket, tradingBasketList || [], "key");
  }, [basket]);

  const selectedTicker = useMemo(() => {
    const tickers = newStartegyForm.tradingInstrument.equityBaskets.tickers;
    const result = tickers.map((key) => ({
      value:
        tradingBasketList.find((t) => t.key === key)?.value ||
        customBasketExtraList.find((t) => t.key === key)?.value ||
        key,
      key,
    }));
    return result;
  }, [newStartegyForm.tradingInstrument.equityBaskets.tickers, tradingTickers, tradingBasketList]);

  const selectedDirection = useMemo(
    () => directionList.find((item) => item.key === newStartegyForm.direction) || null,
    [newStartegyForm.direction, directionList]
  );

  const selectedFrequency = useMemo(
    () => updateFrequencyList.find((item) => item.key === updateFrequency) || null,
    [updateFrequency, updateFrequencyList]
  );

  const selectedRebalance = useMemo(
    () => rebalanceFrequencyList.find((item) => item.key === rebalanceFrequency) || null,
    [rebalanceFrequency, rebalanceFrequencyList]
  );
  const selectedWeightScheme = useMemo(
    () => weightingSchemeList.find((item) => item.key === weightScheme) || null,
    [weightScheme, weightingSchemeList]
  );

  const disableOtions = !newStartegyForm.tradingInstrument;
  const maxAllowedWeightValue = newStartegyForm.tradingInstrument.equityBaskets.maxAllowedWeight;
  const isWeightingShemeRequired = selectedTradingBasket?.key === "custom-basket";
  const isWeitingSchemeDisbled = isWeightingShemeRequired && tickers.length === 0;

  useEffect(() => {
    if (!isWeightingShemeRequired) {
      dispatch(setBasketTickersList(null));
    }
  }, [isWeightingShemeRequired]);

  return {
    tradingTickers,
    selectedTradingBasket,
    selectedTicker,
    selectedDirection,
    selectedFrequency,
    selectedRebalance,
    selectedWeightScheme,
    disableOtions,
    maxAllowedWeightValue,
    weightingSchemeList: customWeightingSchemeList || [],
    isWeightingShemeLoading,
    isWeitingSchemeDisbled,
    isCustomBasket: isWeightingShemeRequired,

    selectDirectionHendler,
    selectTickerHendler,
    slectTradingBasket,
    selectFraquencyHandler,
    selectRebalanceHandler,
    selectWeightHandler,
    uploadTickersHandler,
    changeAllowedWeightHandler,
    blurHandler,
    onGetListHandler,
  };
};
