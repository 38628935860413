import { FC } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import { PORTFOLIO_ROUTES } from "@/constants";
import AddNewIcon from "@/images/add-new.svg";

import styles from "./CreateNew.module.scss";

interface IProps {
  className?: string;
  to: string;
  text: string;
}

export const CreateNew: FC<IProps> = ({ className, to, text }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <NavLink to={to}>
        <div className={styles.addIcon}>
          <img src={AddNewIcon} alt="add-icon" />
        </div>
        <span className={styles.info}>{text}</span>
      </NavLink>
    </div>
  );
};
