/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useState, useCallback } from "react";

import { DATE_ISO_US } from "@/constants";

import styles from "./Chart.module.scss";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

interface LineChartProps {
  ticker?: string;
  data?: any[];
  dates?: string[];
  isQuarter?: boolean;
}

const CHART_HEIGHT = 45;
const CHART_WIDTH = 230;

const CHART_PADDING = 8;

export const SmallChart: FC<LineChartProps> = ({ ticker, data, isQuarter }) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      valueSuffix: "",
      valuePrefix: "",
      formatter(): any {
        const dateFormattter = (date?: string | number) => {
          const d = dayjs(date);
          return isQuarter ? `F${d.format("Q")}Q${d.format("YY")}` : d.format(DATE_ISO_US);
        };
        return this.points?.reduce(function (str, point, idx) {
          return `${str}<p style="margin:0; margin-top: ${idx > 0 ? 0 : 8}px;"><span style="color: ${point.series.color}; font-weight: bold;">${point.series.name}</span>: ${point.y?.toFixed(4)}</p>`;
        }, `<p style="padding-bottom: 4px; border-bottom: 1px solid silver; font-weight: bold">${dateFormattter(
          this.points?.[0]?.key
        )}</p>`);
      },
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
      selected: 5,
    },
    chart: {
      type: "spline",
      height: CHART_HEIGHT + CHART_PADDING * 2,
      // width: CHART_WIDTH,
      borderWidth: 0,
      plotBorderWidth: 0,
      spacingBottom: CHART_PADDING,
      spacingLeft: CHART_PADDING,
      spacingTop: CHART_PADDING,
      spacingRight: CHART_PADDING,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: CHART_PADDING,
      plotShadow: false,
      animation: false,
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    colors: [
      "#093B79",
      "#008DDD",
      "#8085e9",
      "#2b908f",
      "#c42525",
      "#1aadce",
      "#492970",
      "#f28f43",
      "#77a1e5",
      "#f7a35c",
      "#f15c80",
      "#e4d354",
      "#f45b5b",
      "#91e8e1",
      "#2f7ed8",
      "#8bbc21",
      "#910000",
      "#a6c96a",
      "#4572A7",
      "#AA4643",
      "#89A54E",
      "#80699B",
      "#3D96AE",
      "#DB843D",
      "#92A8CD",
      "#A47D7C",
      "#B5CA92",
    ],
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      crosshair: false,
      tickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: "",
      },
    },
    plotOptions: {
      line: {
        lineWidth: 1.5,
      },
      series: {
        animation: false,
      },
    },
  });

  const getMinMax = (data: number[][]): { min: number; max: number } => {
    let minValue = Infinity;
    let maxValue = -Infinity;

    for (const point of data) {
      const value = point[1];
      if (value < minValue) {
        minValue = value;
      }
      if (value > maxValue) {
        maxValue = value;
      }
    }

    if (minValue === Infinity || maxValue === -Infinity) {
      throw new Error("No valid data points found.");
    }

    return { min: minValue, max: maxValue };
  };

  const getSeries = useCallback((data: any) => {
    const initData = data?.[0].data;
    const start = initData[0][1];
    const end = initData[initData.length - 3][1];
    const chartColor = start < end ? "#00be4f" : "#ff372b";
    const startGradient = start < end ? "rgba(0, 190, 79, 0.2)" : "rgba(255, 55, 43, 0.2)";
    const stopGradient = start < end ? "rgba(0, 190, 79, 0)" : "rgba(255, 55, 43, 0)";

    const mainLine = data?.map((serie: any, idx: number) => ({
      ...serie,
      type: "area",
      color: chartColor,
      marker: {
        enabled: false,
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 2 },
        stops: [
          [0, startGradient],
          [0.5, stopGradient],
        ],
      },
      data: [...data[idx].data],
    }));

    const line = {
      data: initData.map((_: any, i: number) => [initData[i][0], initData[0][1]]),
      type: "line",
      dashStyle: "dash",
      color: chartColor,
      lineWidth: 1,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    };
    return mainLine?.concat(line).reverse();
  }, []);

  useEffect(() => {
    setChartOptions((state) => ({
      ...state,
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: "",
        },
        min: getMinMax(data?.[0].data).min,
        max: getMinMax(data?.[0].data).max,
      },
      series: getSeries(data),
    }));
  }, [data, getSeries]);

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};
