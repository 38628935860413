import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { FC } from "react";

import { PDFColor, PDFfontSize } from "@/constants";

interface ICardProps {
  title: string;
  imageSrc: string | null;
  description: string | null;
}

const CHART_WIDTH = 920;

const styles = StyleSheet.create({
  // chart card styles
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: 4,
    marginBottom: 16,
  },
  cardTitle: {
    color: PDFColor.primary,
    fontSize: PDFfontSize.small,
    padding: "8px 16px",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: PDFColor.header,
    textTransform: "capitalize",
  },
  cardSection: {
    display: "flex",
    flexDirection: "row",
    columnGap: 4,
    width: "100%",
  },
  cardImageSection: {
    display: "flex",
    alignItems: "center",
    height: "auto",
    padding: 8,
    border: "1px solid " + PDFColor.black30,
    borderRadius: 4,
    width: CHART_WIDTH,
  },
  cardTextSection: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    rowGap: 8,
    width: "100%",
    padding: 8,
    border: "1px solid " + PDFColor.black30,
    borderRadius: 4,
  },
  cardLabel: {
    color: PDFColor.black,
    fontSize: PDFfontSize.default,
    fontWeight: 600,
  },
  cardDescription: {
    color: PDFColor.black,
    fontSize: PDFfontSize.small,
    fontWeight: 400,
  },
  cardEmptyDescription: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 1,
  },
  cardEmptyText: {
    color: PDFColor.black30,
    fontSize: PDFfontSize.small,
    fontWeight: 400,
  },
  image: {
    width: "100%",
  },
});

export const Card: FC<ICardProps> = ({ title, description, imageSrc }) => {
  return (
    <View style={styles.cardContainer} wrap={false}>
      <Text style={styles.cardTitle}>{title}</Text>
      <View style={styles.cardSection}>
        {imageSrc ? (
          <View style={styles.cardImageSection}>
            <Image src={imageSrc} style={styles.image} />
          </View>
        ) : null}
        <View style={styles.cardTextSection}>
          {imageSrc ? (
            description ? (
              <Text style={styles.cardLabel}>Description:</Text>
            ) : null
          ) : null}
          {description ? (
            <Text style={styles.cardDescription}>{description}</Text>
          ) : (
            <View style={styles.cardEmptyDescription}>
              <Text style={styles.cardEmptyText}>No description has been added yet.</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
