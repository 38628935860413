import { FC } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  IndicatorOperatorType,
  addOperator,
  changeOperatorValue,
  deleteOperator,
  selectIndicatorConstructor,
  setActiveOperator,
} from "@/store/strategies/customIndicator";

import { ConstructorItem } from "../ConstructorItem/ConstructorItem";

import styles from "./ConstructorDesk.module.scss";

export const ConstructorDesk: FC = () => {
  const { constructor, activeOperator, isReplaceMode } = useAppSelector(selectIndicatorConstructor);
  const dispatch = useAppDispatch();

  const selectOperatorHandler = (item: string, operator?: IndicatorOperatorType) => {
    const newItem = { key: item, value: item };
    dispatch(addOperator({ item: newItem, isSimpleOperator: true }));
  };

  const changeValueHandler = (value: string, index: number) => {
    dispatch(changeOperatorValue({ value, index }));
  };

  const deleteHandler = (operator: IndicatorOperatorType) => {
    dispatch(deleteOperator(operator));
  };
  const tickerSelectHandler = () => {
    //
  };

  const activeItemHandler = (operator?: IndicatorOperatorType) => {
    dispatch(setActiveOperator(operator || null));
  };

  const isActive = (operator: IndicatorOperatorType | null): boolean => {
    const active = constructor && activeOperator ? constructor[activeOperator.index] : null;
    return active === operator;
  };

  return (
    <div
      className={`${styles.container} ${isReplaceMode ? styles.replaceMode : ""}`}
      id="constructor-table"
    >
      <div className={styles.inner}>
        {constructor?.map((operator, idx) => (
          <ConstructorItem
            isCustom
            operator={operator}
            key={`operator-${idx + 1}`}
            onSelect={selectOperatorHandler}
            onDelete={deleteHandler}
            onTickerSelect={tickerSelectHandler}
            onChangeValue={changeValueHandler}
            isChecked={isReplaceMode}
            disableList={[]}
            info={operator.info}
            isActive={isActive(operator)}
            onActive={activeItemHandler}
          />
        ))}
        <ConstructorItem
          onSelect={selectOperatorHandler}
          onChangeValue={changeValueHandler}
          disableList={[]}
          onActive={activeItemHandler}
          isActive={isActive(null)}
          isCustom
        />
      </div>
      {!(constructor && constructor.length) && (
        <div className={styles.helpContainer}>
          <p>Here You can create indicator expression.</p>
        </div>
      )}
    </div>
  );
};
