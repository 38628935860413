import { useState } from "react";

import { ExpectType, economMetricConfig, expectTypeList, sliders } from "@/constants/warRoom";
import { AssetRecordType, IConfigData } from "@/types/warRoom";

const initialConfig: IConfigData = {
  change_rate: sliders.changeRate.defaultValue as number,
  horizon: sliders.horizon.defaultValue as number,
  portfolio_leverage: sliders.leverage.defaultValue as number,
  max_interval: (sliders.interval.defaultValue as number[])[1],
  min_interval: (sliders.interval.defaultValue as number[])[0],
  portfolio_beta: sliders.beta.defaultValue as number,
  assets: [],
  econ_metric: "",
};

export const useBaseAssets = (
  tickersList: AssetRecordType,
  onConfirm?: (config: IConfigData) => void
) => {
  const [config, setConfig] = useState<IConfigData>(() => initialConfig);
  const [assets, setAssets] = useState<AssetRecordType>(tickersList);

  const [expectType, setExpectType] = useState<keyof ExpectType>("fedFundRate");
  const [checked, setChecked] = useState(false);

  const disabledHorizon = expectType === "nominalGDP" || expectType === "realGDP";
  const disabledAllOptions = Object.values(assets).every((i) => !i.checked);

  const confirmHandler = (
    assetsList: Record<string, AssetRecordType> | null,
    csvFilesIDs?: number[]
  ) => {
    delete config.interval;
    delete config.portfolio_returns;

    if (csvFilesIDs && assetsList) {
      config.portfolio_returns = csvFilesIDs;
      const assetGroup = Object.values(assetsList)
        .map((group) => Object.values(group))
        .flat();
      config.assets = assetGroup.filter((i) => i.checked).map((i) => i.key);
    } else {
      config.assets = Object.values(assets)
        .filter((i) => i.checked)
        .map((i) => i.key);
    }
    config.econ_metric = expectTypeList[expectType].key;
    config.include_current_state = checked;

    if (onConfirm) onConfirm(config);
  };

  const changeExpectTypeHandler = (value: string) => {
    setConfig((prev) => ({
      ...prev,
      change_rate: economMetricConfig[value as keyof ExpectType].defaultValue,
      horizon: sliders.horizon.defaultValue as number,
    }));
    setExpectType(value as keyof ExpectType);
  };

  const changeHandler = (value: number | number[], name: string) => {
    setConfig((prev) => ({
      ...prev,
      [name]: value,
      min_interval: name === "interval" && Array.isArray(value) ? value[0] : prev.min_interval,
      max_interval: name === "interval" && Array.isArray(value) ? value[1] : prev.max_interval,
    }));
  };

  const changeCurrentStatusHandler = () => {
    setChecked((prev) => !prev);
  };

  return {
    changeExpectTypeHandler,
    changeCurrentStatusHandler,
    confirmHandler,
    changeHandler,
    setAssets,

    disabledAllOptions,
    disabledHorizon,
    expectType,
    checked,
    assets,
    config,
  };
};
