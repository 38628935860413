import { forwardRef, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { ModalLayout, ActionsMenu, IMenuItem } from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { ROUTES } from "@/constants";
import { useAppDispatch } from "@/hooks";
import { ReactComponent as CopyIcon } from "@/images/copy.svg";
import { ReactComponent as DeleteIcon } from "@/images/nest.svg";
import PortfolioIcon from "@/images/portfolio-icon.svg";
import {
  changeFavoriteStatus,
  deletePortfolio,
  duplicatePortfolio,
  fetchChangeFavoriteStatus,
} from "@/store/portfolios/portfolio";
import { IDuplicate, IPortfolio } from "@/types";
import { getFormattedDate, searchStringInText } from "@/utils";

import styles from "./PortfolioItem.module.scss";

interface IProps {
  portfolio: IPortfolio;
  hasMenu?: boolean;
  isMyPortfolio?: boolean;
  isFavorites?: boolean;
  isFavProgress?: boolean;
  idx?: number;
  searchText?: string;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    icon: <CopyIcon />,
    type: "copy",
    label: "Copy",
    key: "copy",
  },
  {
    icon: <DeleteIcon />,
    type: "delete",
    label: "Delete",
    key: "delete",
  },
];

export const PortfolioItem = forwardRef<HTMLDivElement, IProps>(
  ({ portfolio, hasMenu, idx, isFavorites, searchText }, ref) => {
    const { email } = portfolio.user ?? {};
    const { show_on_home, id, is_template, name, permissions, created_at } = portfolio;
    const [showModal, setShowModal] = useState(false);
    const itemRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useAppDispatch();
    const handleSelect = (menuItem: IMenuItem) => {
      const { key } = menuItem;
      if (!id) return;
      if (key === "delete") {
        setShowModal(true);
      }
      if (key === "copy") {
        const data: IDuplicate = {
          is_template: false,
          name,
        };
        dispatch(duplicatePortfolio({ id, data }));
      }
    };

    const handleConfirmDelete = () => {
      if (!id) return;
      dispatch(deletePortfolio(id));
      setShowModal(true);
    };
    const handleCancel = () => {
      setShowModal(false);
    };

    const animationEndHandler = (ev: React.AnimationEvent<HTMLDivElement>) => {
      if (isFavorites && show_on_home && ev.target === itemRef.current && id !== undefined) {
        dispatch(changeFavoriteStatus(id));
        dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
      }
    };

    const menu = MENU_ITEMS.filter(
      ({ type }) =>
        !(
          (isFavorites && type === "copy") ||
          ((!permissions?.has_delete || isFavorites) && type === "delete") ||
          (!permissions?.has_write && type === "edit")
        )
    );

    const isActionMenuDisabled = menu.length === 0;

    useEffect(() => {
      setTimeout(() => {
        if (itemRef.current) itemRef.current.classList.add(styles.onStartAnimated);
      }, 50 + (idx || 0) * 35);
    }, [idx]);

    const groupName = (name: string) => {
      const result = searchStringInText(name, searchText);

      return result.length ? (
        result.map((item, idx) => (
          <span data-match={searchText?.toLowerCase() === item.toLowerCase()} key={idx}>
            {item}
          </span>
        ))
      ) : (
        <span>{name}</span>
      );
    };

    return (
      <div className={styles.container} ref={itemRef} onAnimationEnd={animationEndHandler}>
        <div ref={ref} className={styles.inner}>
          {is_template && <span className={styles.template}>Template</span>}
          <NavLink to={`${ROUTES.portfolioEdit.path}/${id}`}>
            <div className={`${styles.innerContainer}`}>
              <div className={styles.portfolioIcon}>
                <img src={PortfolioIcon} alt="portfolio-icon" />
              </div>
              <div className={styles.info}>
                <h5 className={styles.title}>{groupName(name)}</h5>
                <span>ID: {id}</span>
              </div>
            </div>
            <div className={styles.innerContainerHovered}>
              <div className={styles.hoveredDragIcon}>
                <img src={PortfolioIcon} alt="drag-icon" />
              </div>
              <div className={styles.infoHovered}>
                <h5>Created By</h5>
                <span>{email}</span>
                <span>{getFormattedDate(created_at)}</span>
              </div>
            </div>
          </NavLink>
        </div>
        {hasMenu && (
          <ActionsMenu menuItems={menu} onSelect={handleSelect} disabled={isActionMenuDisabled} />
        )}
        {/* <div className={styles.favIconContainer} onClick={favoriteHandler}>
          <TooltipWrapper
            text={show_on_home ? "Click to hide from Home Page" : "Click to show on Home Page"}
          >
            <StarIcon className={`${show_on_home ? styles.active : ""}`} />
          </TooltipWrapper>
        </div> */}
        <ModalLayout show={showModal} onClose={handleCancel}>
          <Confirm onConfirm={handleConfirmDelete} onCancel={handleCancel} />
        </ModalLayout>
      </div>
    );
  }
);
