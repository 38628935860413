import { useCallback } from "react";

import { ChartConfigType, DataType, IYoYData } from "@/types/forecast";
import { PDFDataType } from "@/types/pdf";
import { formatingDates as formattingDates, getSeriesDataCategory } from "@/utils";

export const useEquitiesChartConfig = () => {
  const createConfig = useCallback(
    (activeTabButton: string, data: DataType, type?: PDFDataType): ChartConfigType => {
      const getSireType = (sireType: string) => {
        if (type === "price-action") return "column";
        return sireType;
      };
      const { data: chartData } = getSeriesDataCategory(
        data?.[activeTabButton] as Record<string, Record<string, number>>,
        {
          defaultSelected: false,
          type: getSireType(
            (data?._meta?.[activeTabButton]?.type as unknown as string) || "spline"
          ),
          isHideEmpty: true,
        }
      );

      const quarters =
        data && activeTabButton
          ? (data._meta[activeTabButton]?.fiscal_quarters as Record<string, string>) || null
          : null;

      const quartersEndMonth =
        data && activeTabButton
          ? (data._meta[activeTabButton]?.quarters_end_month as Record<string, string>) || null
          : null;

      const yoyData = (): IYoYData | undefined => {
        const { actual_yoy, odin_yoy_forecast, consensus_yoy } = data?._meta[activeTabButton] || {};
        if (!actual_yoy && !odin_yoy_forecast && !consensus_yoy) return undefined;

        return {
          actual: formattingDates(actual_yoy as Record<string, number | null> | null),
          forecast: formattingDates(odin_yoy_forecast as Record<string, number | null> | null),
          consensus: formattingDates(consensus_yoy as Record<string, number | null> | null),
        };
      };

      const deltaData = (): IYoYData | undefined => {
        const { odin_delta_forecast, actual_delta, consensus_delta } =
          data?._meta[activeTabButton] || {};
        if (!actual_delta && !consensus_delta && !odin_delta_forecast) return undefined;

        return {
          actual: formattingDates(actual_delta as Record<string, number | null> | null),
          forecast: formattingDates(odin_delta_forecast as Record<string, number | null> | null),
          consensus: formattingDates(consensus_delta as Record<string, number | null> | null),
        };
      };

      return {
        chartData,
        quarters,
        quartersEndMonth,
        yoyData: yoyData(),
        deltaData: deltaData(),
      };
    },
    []
  );

  return {
    createConfig,
  };
};
