import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dataBrowserService } from "@/services";
import { IEconomicCalendar } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IEconomicCalendarState {
  data: IEconomicCalendar[];
  countries: string[];
  isLoading: boolean;
  isLoadingCountries: boolean;
}

const initialState: IEconomicCalendarState = {
  data: [],
  countries: [],
  isLoading: false,
  isLoadingCountries: false,
};

export const getEconomicCalendar = createAsyncThunk(
  "economicCalendar/get",
  async ({ start, end, country }: { start?: string; end?: string; country?: string }) => {
    const response = await dataBrowserService.getEconomicCalendar(start, end, country);
    return response.data.result;
  }
);

export const getCountryList = createAsyncThunk("countryList/get", async () => {
  const response = await dataBrowserService.getCountryList();
  return response.data.result;
});

export const portfolioBuilderSlice = createSlice({
  name: "economicCalendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEconomicCalendar.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getEconomicCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEconomicCalendar.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.isLoadingCountries = false;
      })
      .addCase(getCountryList.pending, (state) => {
        state.isLoadingCountries = true;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.isLoadingCountries = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const economicCalendarState = createSelector(state, (state) => state.economicCalendar);

export default portfolioBuilderSlice.reducer;
