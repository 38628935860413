import { ComponentWithPermissions } from "@/components";

const focusLoader = () =>
  import("./FocusPage").then(({ FocusPage }) => ({
    default: FocusPage,
  }));

export const FocusPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={focusLoader}
      title="Event Calendar"
      pageKey={"Events-Calendar"}
    />
  );
};
