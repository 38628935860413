import { FC, useState } from "react";

import { LoaderWrapperCustom, ModalLayout } from "@/components";
import { SideBarKeyEnum, TUTORIALS } from "@/constants";
import { useAppSelector, useOuterClick } from "@/hooks";
import Play from "@/images/play.svg";
import { AdminPanelState } from "@/store/adminPanel/adminPanel";
import { IAdminPanelPages } from "@/types/adminPanel";

import styles from "./Tutorials.module.scss";

export const Tutorials: FC = () => {
  const [selected, setSelected] = useState<IAdminPanelPages | null>(null);
  const handleClose = () => {
    setSelected(null);
  };
  const [videoItemRef] = useOuterClick(handleClose, false);
  const handelVideClick = (tutorial: IAdminPanelPages) => () => {
    setSelected(tutorial);
  };
  const { pages, isLoading } = useAppSelector(AdminPanelState);

  const getName = (tutorial: IAdminPanelPages | undefined | null) => {
    if (!tutorial?.key) return "N/A";
    return tutorial.meta.videoTitle || tutorial?.key || "N/A";
  };

  return (
    <>
      <div className={styles.videoWrapper}>
        {pages
          ?.filter(({ video_url }) => video_url)
          .map((tutorial, idx) => {
            const key = tutorial.key as SideBarKeyEnum;
            const posterImage = TUTORIALS?.[key]?.posterImage || null;

            return (
              <div key={idx}>
                <LoaderWrapperCustom loading={isLoading} transparent atom>
                  <h3 className={styles.videoTitle}>{getName(tutorial)}</h3>
                  <div
                    key={tutorial.key}
                    className={styles.card}
                    onClick={handelVideClick(tutorial)}
                  >
                    <div
                      className={styles.videoContent}
                      style={{
                        backgroundImage: `url(${posterImage})`,
                        color: "red",
                      }}
                    >
                      <div className={styles.playIcon}>
                        <img src={Play} alt="play" />
                      </div>
                      {!posterImage && (
                        <div className={styles.videoPreview}>
                          <video className={styles.video} muted loop>
                            <source
                              src={tutorial.video_url || TUTORIALS.default.url}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      )}
                    </div>
                    {tutorial.meta.infoText && (
                      <div className={styles.description}>
                        <div className={styles.inner}>
                          <p dangerouslySetInnerHTML={{ __html: tutorial.meta.infoText }} />
                        </div>
                      </div>
                    )}
                  </div>
                </LoaderWrapperCustom>
              </div>
            );
          })}
      </div>
      <ModalLayout show={!!selected?.video_url} onClose={handleClose}>
        <div className={styles.videoTutorialContainer} ref={videoItemRef}>
          <div className={styles.title}>{getName(selected)}</div>
          <div className={styles.close} onClick={handleClose}></div>
          <div className={styles.inner}>
            <video autoPlay controls>
              <source src={selected?.video_url || TUTORIALS.default.url} type="video/mp4" />
            </video>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};
