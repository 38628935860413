import { FC, memo, useRef } from "react";

import { Heading, TooltipWrapper } from "@/components";
import { Chart } from "@/features/Discover/components/Chart/Chart";
import { DrsTable } from "@/features/Discover/components/DrsTable/DrsTable";
import { useInViewportElement } from "@/hooks";
import { TIME_SERIES_TYPE } from "@/types";

import styles from "./Charts.module.scss";

interface IProps {
  chartData: any;
  command?: string;
  title: string | null;
  description?: string | null;
}

const DEFAULT_CHART_HEIGHT = 190;
const WITH_DESCRIPTION_HEIGHT = 175;

export const DiscoverChart: FC<IProps> = memo(({ chartData, command = "", title, description }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewportElement(ref, false);
  let data: any;
  try {
    data = JSON.parse(decodeURIComponent(command));
  } catch (e) {
    console.error(e);
    return (
      <div className={styles.error}>
        <p>Data is not available.</p>
      </div>
    );
  }
  return (
    <div className={styles.container} ref={ref}>
      <Heading className={styles.heading} type="h5">
        <span>DATA:</span>
        {title?.replace(/\(.*$/, "")}
      </Heading>
      {!!description && <span className={styles.description}>{description}</span>}
      <div className={styles.content}>
        {isInViewport ? (
          <>
            {"time_series" === data?.query?.output?.format ? (
              <Chart
                toggleButtonEnambled={false}
                results={chartData.results}
                height={description ? WITH_DESCRIPTION_HEIGHT : DEFAULT_CHART_HEIGHT}
                isChartPreviw
              />
            ) : (
              <div className={styles.tabel}>
                <DrsTable
                  meta={chartData.meta}
                  results={chartData?.results || []}
                  seriesType={TIME_SERIES_TYPE.DEFAULT}
                  small
                />
              </div>
            )}
          </>
        ) : (
          <div style={{ height: DEFAULT_CHART_HEIGHT, width: "100%" }}></div>
        )}
      </div>
    </div>
  );
});
