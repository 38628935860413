import * as Sentry from "@sentry/react";

export const initErrorsLogging = () => {
  if (process.env?.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://2b891ef4d2654f58ae107b72f4a7775e@o1188302.ingest.sentry.io/4505589423603712",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost"],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

export const sentryReportError = (message: string) => {
  if (process.env?.NODE_ENV === "production") {
    Sentry.withScope((scope) => {
      scope.setExtra("error_payload", message);
      scope.setExtra("user", userSeesion.getUser());
      Sentry.captureMessage("OdinUltra-error");
    });
  }
};

class UserSeesion {
  constructor() {
    //
  }
  setUser(user: string) {
    localStorage.setItem("odin-ultra-user", window.btoa(user));
  }
  getUser() {
    const encrypted = localStorage.getItem("odin-ultra-user");
    return encrypted ? window.atob(encrypted) : null;
  }
  remove() {
    localStorage.removeItem("odin-ultra-user");
  }
}

export const userSeesion = new UserSeesion();
