export interface ISliderCard {
  title: string;
  quarter: string;
  value: string | null;
}

export enum CardEnum {
  SP500 = "S&P 500",
}

export type CardData = Record<string, string>;

export type CardDataResponse = {
  Sector: CardEnum;
  Reported: string;
  LastUpdated: string;
  Quarter: string;
} & Record<string, number>;
