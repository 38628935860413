import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useState } from "react";

import { COLORS } from "@/constants";
import { ICahrtData } from "@/types/warRoom";

interface LineChartProps {
  ticker?: string;
  data?: ICahrtData[];
  dates?: string[];
}
export const ChartWarRoom: FC<LineChartProps> = ({ ticker, data }) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      valueSuffix: "",
      valuePrefix: "",
      formatter(): any {
        return this.points?.reduce(function (str, point, idx) {
          return `${str}<p style="margin:0; margin-top: ${idx > 0 ? 0 : 8}px;"><span style="color: ${point.series.color}; font-weight: bold;">Allocation</span>: ${point.y?.toFixed(1)}%</p>`;
        }, `<p style="padding-bottom: 4px; border-bottom: 1px solid silver; font-weight: bold">${this.x}</p>`);
      },
    },
    plotOptions: {
      column: {
        borderRadius: 4,
      },
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
      selected: 5,
    },
    chart: {
      type: "column",
      marginTop: 35,
      style: {
        fontFamily: "Inter",
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    colors: COLORS,
    yAxis: {
      title: {
        text: ticker,
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "",
      },
      type: "category",
      categories: data?.map((i) => i.name) || [],
      labels: {
        style: {
          width: 50,
        },
      },
    },
  });

  useEffect(() => {
    setChartOptions((state) => ({
      ...state,
      series: [
        {
          type: "column",
          data: data?.map((i) => i.value) || [],
        },
      ],
    }));
  }, [data]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: 300 } }}
      />
    </div>
  );
};
