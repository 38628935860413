import { useEffect } from "react";

import { debounce } from "@/utils";

interface IOptionsProps {
  rootRef: HTMLDivElement | null;
  listRef: HTMLDivElement | null;
  increment: number;
  functionCB: (cb: (count: number) => number) => void;
  listLen: number;
  showMore: boolean;
}

const MARGIN_BOTTOM = 100; //magrin bottom from root element - px

export const useInfiniteScroll = (options: IOptionsProps) => {
  const { rootRef, listRef, increment, functionCB, listLen, showMore } = options;
  useEffect(() => {
    const debouncedScroll = debounce(() => {
      if (rootRef && listRef && showMore) {
        const rootHeight = rootRef.clientHeight;
        const listHeight = listRef.clientHeight;
        const scrollheight = rootRef.scrollTop;
        if (listHeight && scrollheight + rootHeight + MARGIN_BOTTOM > listHeight) {
          functionCB((prev) => {
            return prev + increment < listLen ? prev + increment : listLen;
          });
        }
      }
    }, 100);
    if (rootRef) {
      rootRef.addEventListener("scroll", debouncedScroll);
    }
    return () => {
      if (rootRef) {
        rootRef.removeEventListener("scroll", debouncedScroll);
      }
    };
  }, [listLen, showMore, rootRef, listRef, increment, functionCB]);
};
