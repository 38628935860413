import { FC, Fragment, useCallback, useEffect, useMemo } from "react";

import { LoaderWrapper } from "@/components";
import { paginator } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { profileInfo } from "@/store/profile";
import {
  fetchMyStrategiesData,
  selectMyStrategies,
  setPerPage,
  setPage,
} from "@/store/strategies/my_strategies";
import { init } from "@/store/strategies/strategies";
import { prepareStrategiesList } from "@/utils";

import { MyStrategies } from "./components";

import styles from "./StrategiesDashboard.module.scss";

export const StrategiesDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const { list, isLoading, page, perPage } = useAppSelector(selectMyStrategies);
  const { profile } = useAppSelector(profileInfo);

  const strategiesList = useMemo(() => {
    const myStartegiesList = list && profile && list.filter((item) => item.user_id === profile.id);
    return myStartegiesList ? prepareStrategiesList(myStartegiesList) : [];
  }, [list, profile]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const strategiesPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  useEffect(() => {
    dispatch(init());
    if (!list) {
      dispatch(fetchMyStrategiesData());
    }
  }, []);

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <LoaderWrapper loading={isLoading}>
            <MyStrategies strategiesList={strategiesList} paginator={strategiesPaginator} />
          </LoaderWrapper>
        </div>
      </div>
    </Fragment>
  );
};
