import dayjs from "dayjs";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Heading, Loader, Dropdown, ItemType, Button, InfoHeading } from "@/components";
import { DATE_ISO, SideBarKeyEnum } from "@/constants";
import {
  DEFAULT_ASSET_ITEM,
  DEFAULT_CATEGORY_ITEM,
  DEFAULT_MANAGEMENT_ITEM,
  selectAssetItemsList,
  selectCategoryItemsList,
  selectManagementItemsList,
} from "@/constants/etfIntelligence";
import { faqDescriptionList } from "@/constants/faqDescription";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import {
  etfIntelligenceState,
  fetchInfoMetaData,
  initialize,
  makeRequest,
} from "@/store/forecast/forecastEtfIntelligence";
import { Assets, Categories, Management } from "@/types/etfIntelligence";

import { ChartsList } from "./components/ChartsList/ChartsList";
import { PieChartList } from "./components/ChartsList/PieChartList";
import { ETFTable } from "./components/EFTTable/EFTTable";
import { NewETFTable } from "./components/EFTTable/NewETFTable";
import { InfoTable } from "./components/InfoTable/InfoTable";
import { TickersTable } from "./components/TickersTable/TickersTable";

import styles from "./Intelligence.module.scss";

export const Intelligence: FC = () => {
  const [activeCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY_ITEM);
  const [activeManagement, setSelectedManagement] = useState(DEFAULT_MANAGEMENT_ITEM);
  const [activeAsset, setActiveAsset] = useState(DEFAULT_ASSET_ITEM);
  const dispatch = useAppDispatch();
  const {
    requestStr: request,
    loading,
    initialized,
    requestCount,
    metaDate,
    category,
  } = useAppSelector(etfIntelligenceState);

  const { textInfo, video } = usePageInfo(SideBarKeyEnum.ETF_INTELLIGENCE);

  const isReady = requestCount <= 0 ? true : false;

  const selectHandle = (name: string, item: ItemType<string>) => {
    setSelectedCategory(item);
  };

  const selectManagmentHandler = (name: string, item: ItemType<string>) => {
    setSelectedManagement(item);
  };

  const selectAssetHandler = (name: string, item: ItemType<string>) => {
    setActiveAsset(item);
  };

  const requestStr = useMemo(() => {
    return `${activeCategory.key}/${activeManagement.key}/${activeAsset.key}`.toLocaleLowerCase();
  }, [activeCategory.key, activeManagement.key, activeAsset.key]);

  const executeHandle = useCallback(() => {
    if (isReady)
      dispatch(
        makeRequest({
          request: requestStr,
          management: activeManagement.key as Management,
          category: activeCategory.key as Categories,
          assets: activeAsset.key as Assets,
        })
      );
  }, [requestStr, isReady]);

  useEffect(() => {
    dispatch(fetchInfoMetaData());
    dispatch(initialize());
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.section}>
          <InfoHeading helpInfo={textInfo} faqList={faqDescriptionList.etfIntelligence}>
            ETF Intelligence
          </InfoHeading>
          <Heading className={styles.mainHeading} type="h2">
            <span></span>
          </Heading>
          <div className={styles.infoContainer}>
            <div className={styles.category}>
              Category: <span>{category}</span>
            </div>
            <div className={styles.category}>
              As of date: <span>{dayjs(metaDate?.last_updated).format(DATE_ISO)}</span>
            </div>
            {!isReady && (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}
          </div>
          <div className={styles.selectorsGroup}>
            <div>
              <Dropdown<"category", string>
                selected={activeCategory}
                onSelect={selectHandle}
                list={selectCategoryItemsList}
                disable={isReady ? false : true}
                name="category"
                label="Select Category"
              />
            </div>
            <div>
              <Dropdown<"management", string>
                onSelect={selectManagmentHandler}
                list={selectManagementItemsList}
                selected={activeManagement}
                disable={isReady ? false : true}
                name="management"
                label="Management strategy"
              />
            </div>
            <div>
              <Dropdown<"assetClass", string>
                onSelect={selectAssetHandler}
                list={selectAssetItemsList}
                selected={activeAsset}
                disable={isReady ? false : true}
                name="assetClass"
                label="Asset Class Style"
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                type="primary"
                onClick={executeHandle}
                disabled={request === requestStr || !isReady}
                text="Execute"
              />
            </div>
          </div>
        </div>

        <section className={styles.mainSection}>
          <div className={styles.infoTableSection}>
            <InfoTable />
          </div>
          <div className={styles.pieChrtsSection}>
            <PieChartList />
          </div>
          <div className={styles.chartsSection}>
            <Heading className={styles.heading} type="h3">
              ETF Data
            </Heading>
            <ChartsList />
          </div>
          <div className={styles.tickersSection}>
            <Heading className={styles.heading} type="h3">
              {activeCategory.value} ETFs
            </Heading>
            <TickersTable />
          </div>

          <div className={styles.etfTableSection}>
            <Heading className={styles.heading} type="h3">
              {activeCategory.value} ETF Metrics
            </Heading>
            <ETFTable />
          </div>
          <div className={styles.etfTableSection}>
            <Heading className={styles.heading} type="h3">
              {activeCategory.value} New ETFs
            </Heading>
            <NewETFTable tableName={`${activeCategory.value} New ETFs`} />
          </div>
        </section>
      </div>
    </div>
  );
};
