import { FC, memo } from "react";

import { Button, Dropdown } from "@/components";
import { ButtonCollapse } from "@/components/Button/ButtonCollapse";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { indicatorsType } from "@/constants";
import {
  Status,
  FieldType,
  AdvancedFilter,
  useConstructor,
  ConstructorItem,
} from "@/features/StrategyCreate";

import styles from "./Constructor.module.scss";

import { ReactComponent as EditIcon } from "@images/edit.svg";
import TrashIcon from "@images/nest.svg";
import { ReactComponent as CreateIcon } from "@images/plus.svg";

interface IProps {
  isSaving: boolean;
}

export const Constructor: FC<IProps> = memo(({ isSaving }) => {
  const {
    isChecked,
    metricsList,
    indicatorsList,
    constructorList,
    isIndicatorLoading,
    isBasketFormType,
    disabled,

    selectIndicatorHandler,
    selectOperatorHandler,
    setIsChecked,
    clearHandle,
    deleteHandler,
    addMetricFilter,
    addIndicatorHandler,
    clearFilterHandler,
    changeValueHandler,
    customIndicatorEditHandler,
    createNewCustomIndicatorHandler,

    canEditUserIndicator,
    advancedFilterStatus,
    selectedIndicatorType,
    selectedIndicator,
    status,
    error,
  } = useConstructor(isSaving);

  const disabledNumberList = constructorList.map(({ index, isNumber }) => {
    if (isNumber) return false;
    return true;
  });

  const EditCustomIndicator = () => {
    if (!canEditUserIndicator) return null;
    return (
      <div className={styles.actionContainer} onClick={customIndicatorEditHandler}>
        <EditIcon />
        <span>Edit</span>
      </div>
    );
  };

  const CreateCustomIndicator = () => {
    return (
      <div className={styles.actionContainer} onClick={createNewCustomIndicatorHandler}>
        <CreateIcon style={{ color: "var(--primary)" }} />
        <span>New</span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.constructorContainer}>
        <div className={styles.wrapper}>
          <p className={styles.label}>Constructor</p>
          <div className={`${styles.table} ${error ? styles.error : ""}`} id="constructor-table">
            <div className={styles.inner}>
              {constructorList.map((operator, idx) => (
                <ConstructorItem
                  operator={operator}
                  key={`operator-${idx + 1}`}
                  onSelect={selectOperatorHandler}
                  onDelete={deleteHandler}
                  onChangeValue={changeValueHandler}
                  onCheck={setIsChecked}
                  isChecked={isChecked}
                  disableList={disabledNumberList}
                  info={operator.info}
                />
              ))}
              <ConstructorItem
                onSelect={selectOperatorHandler}
                onChangeValue={changeValueHandler}
                disableList={[]}
                isEmpty
              />
            </div>
          </div>
        </div>
        <div className={styles.controls}>
          <Dropdown<FieldType, string>
            label="Indicator Type"
            name="category"
            placeholder="Select Indicator Category"
            list={indicatorsType}
            selected={selectedIndicatorType}
            onSelect={selectIndicatorHandler}
            actionElement={CreateCustomIndicator}
            disable={disabled}
          />
          <DropdownSearch<"indicator">
            list={indicatorsList}
            onSelect={selectIndicatorHandler}
            selected={selectedIndicator}
            placeholder="Select Indicator"
            name="indicator"
            label="Indicator"
            disable={!selectedIndicatorType || disabled}
            loading={isIndicatorLoading}
            actionElement={EditCustomIndicator}
            visibleRows={8}
          />

          <div className={styles.btnGroup}>
            {constructorList.length > 0 && (
              <Button icon={TrashIcon} danger text="Clear All" onClick={clearHandle} />
            )}
            <Button
              text="Add Indicator"
              onClick={addIndicatorHandler}
              disabled={!selectedIndicator}
            />
            {isBasketFormType && (
              <ButtonCollapse text="Advanced Filtering Conditions">
                <AdvancedFilter metricList={metricsList} onSelect={addMetricFilter} />
              </ButtonCollapse>
            )}
          </div>
        </div>
      </div>
      {isBasketFormType && (
        <div className={styles.footer}>
          <Status
            status={advancedFilterStatus}
            error={null}
            placeholder="Advanced Filter Condition"
            label="Filter:"
            onClear={clearFilterHandler}
          />
        </div>
      )}
      <div className={styles.footer}>
        <Status status={status} error={null} placeholder="Condition expression" label="Result:" />
      </div>
    </div>
  );
});
