import { ComponentWithPermissions } from "@/components";

const etfIntelligenceLoader = () =>
  import("./ETFIntelligence").then(({ ETFIntelligence }) => ({
    default: ETFIntelligence,
  }));

export const ETFIntelligencePermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={etfIntelligenceLoader}
      title="ETF Intelligence"
      pageKey={"ETFIntelligence"}
    />
  );
};
