import { createSlice, createSelector } from "@reduxjs/toolkit";

import { navLinks } from "@/constants";
import { ICollapsedItems } from "@/types";
import { getCollapsedItems, isMenuOpen, setIsMenuOpen } from "@/utils";

import { RootState } from "./index";

interface MenuState {
  isOpen: boolean;
  collapsedItems: ICollapsedItems;
}

const initialState: MenuState = {
  isOpen: isMenuOpen(),
  collapsedItems: getCollapsedItems(navLinks),
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleMenu: (state) => {
      const isOpen = !state.isOpen;
      setIsMenuOpen(isOpen);

      state.isOpen = isOpen;
    },
    setCollapsedItems: (state, actions) => {
      state.collapsedItems = actions.payload;
    },
  },
});

export const { toggleMenu, setCollapsedItems } = menuSlice.actions;

const root = (state: RootState) => state;

export const selectIsOpen = createSelector(root, (state) => state.menu.isOpen);
export const collapsedItems = createSelector(root, (state) => state.menu.collapsedItems);

export default menuSlice.reducer;
