import { ComponentWithPermissions } from "@/components";

const modelingLoader = () =>
  import("./Modeling").then(({ Modeling }) => ({
    default: Modeling,
  }));

const portfolioBuilderLoader = () =>
  import("../PortfolioBuilderPage/PortfolioBuilderPage").then(({ PortfolioBuilderPage }) => ({
    default: PortfolioBuilderPage,
  }));

const strategiesLoader = () =>
  import("../Strategies/Strategies").then(({ Strategies }) => ({
    default: Strategies,
  }));

const compareLoader = () =>
  import("../Strategies/Compare/Compare").then(({ Compare }) => ({
    default: Compare,
  }));

const allocationLoader = () =>
  import("../Strategies/Allocation/Allocation").then(({ Allocation }) => ({
    default: Allocation,
  }));

export const ModelingPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={modelingLoader}
      title="Modeling"
      pageKey="Portfolio-MultiStrategy"
    />
  );
};

export const PortfolioBuilderPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={portfolioBuilderLoader}
      title="Portfolio Builder"
      pageKey="Portfolio-MultiStrategy"
    />
  );
};

export const StrategiesPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={strategiesLoader}
      title="Portfolio Builder"
      pageKey="Portfolio-SingleStrategy"
    />
  );
};

export const ComparePermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={compareLoader}
      title="Compare"
      pageKey="Portfolio-Compare"
    />
  );
};

export const AllocationPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={allocationLoader}
      title="Allocation Optimizer"
      pageKey="Portfolio-AllocationOptimizer"
    />
  );
};
