import dayjs from "dayjs";
import { FC, memo, useEffect, useRef } from "react";

import { DATE_ISO_TIME } from "@/constants";
import { IUserDataItem } from "@/types";

import styles from "./DataItem.module.scss";

import { ReactComponent as AllocationIcon } from "@images/allocation.svg";
import { ReactComponent as RemoveIcon } from "@images/nest.svg";
import { ReactComponent as ReturnIcon } from "@images/war-room.svg";

interface IProps {
  item: IUserDataItem;
  index: number;
  onSelect: (item: IUserDataItem) => void;
  onRemove: (item: IUserDataItem) => void;
}

const ANIMATION_DELAY = 30; //ms

export const DataItem: FC<IProps> = memo(({ item, index, onSelect, onRemove }) => {
  const { name, created, type } = item;
  const itemRef = useRef<HTMLLIElement | null>(null);
  const isAllocationType = type === "allocations";

  const removeHandler = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    ev.stopPropagation();
    onRemove(item);
  };

  useEffect(() => {
    setTimeout(() => {
      itemRef.current?.classList.add(styles.visible);
    }, ANIMATION_DELAY * index);
  }, [index]);

  return (
    <li className={styles.container} onClick={() => onSelect(item)} ref={itemRef}>
      <span className={styles.leftIcon}>
        {isAllocationType ? <AllocationIcon color={"var(--primary"} /> : <ReturnIcon />}
      </span>
      <div className={styles.textGroup}>
        <span
          className={styles.text}
          data-index={`${index}.`}
          data-label={`${isAllocationType ? "Allocation" : "Return"} CSV:`}
        >
          <span>{name}</span>
        </span>
        <span className={styles.text}>
          Created:<span>{dayjs(created).format(DATE_ISO_TIME)}</span>
        </span>
      </div>
      <span className={styles.rightIcon} onClick={removeHandler}>
        <RemoveIcon />
      </span>
    </li>
  );
});
