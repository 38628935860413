import React, { FC, Suspense, memo, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "@/hooks";
import { NoPageFound } from "@/pages";
import { pagesStatusState } from "@/store/pagesPermission";
import { PageItemType, pagePermission } from "@/utils/pagesPermissions";

import { FallBackLoader } from "../Loader/FallBackLoader";
import { NoAccess } from "../NoAccess/NoAccess";

export type PageKey =
  | "MacroPlanner-MultiAsset"
  | "MacroPlanner-EquitySectors"
  | "MacroPlanner-FixedIncome"
  | "MacroPlanner-Commodities"
  | "MacroPlanner-Custom"
  | "Portfolio-Compare"
  | "Portfolio-AllocationOptimizer"
  | "Portfolio-MultiStrategy"
  | "Portfolio-SingleStrategy"
  | "Home"
  | "Odin-Chat"
  | "Market-View"
  | "Discover-Data"
  | "Events-Calendar"
  | "ETFIntelligence"
  | "ForecastMacro"
  | "ForecastEquities"
  | "ForecastIndustries"
  | "ForecastSectors";

interface IOptions {
  pageKey: PageKey;
  pages: Record<string, PageItemType> | null;
  loader: () => Promise<{
    default: () => JSX.Element;
  }>;
  callBack: ((value: { default: () => JSX.Element }) => void) | null;
  title: string;
}

const getPageFallback = async (options: IOptions) => {
  const { pageKey, pages, title, loader, callBack } = options;
  const foundPage = pagePermission.findPage(pages, pageKey);

  const result = await pagePermission.checkPageStatus(pageKey, foundPage).then((isActive) => {
    if (isActive) return loader();

    const isNotFound = pagePermission.isPageNotFound(foundPage, isActive);
    const isRedirected = !(pages && pages["market_view"]?.status === "active");

    return defaultComponent(title, isNotFound, isRedirected);
  });

  if (callBack) {
    setTimeout(() => callBack(result), 500);
  }
};

export const defaultComponent = (title: string, isNotFound: boolean, isRedirected: boolean) => {
  const NoAccessComponent = () => <NoAccess title={title} />;
  const NotFound = () => <NoPageFound />;
  const Redirected = () => <Navigate to="/insights" />;

  return new Promise<{ default: () => JSX.Element }>((resolve) =>
    setTimeout(
      () =>
        resolve({
          default: isNotFound ? (isRedirected ? Redirected : NotFound) : NoAccessComponent,
        }),
      500
    )
  );
};

export const ComponentWithPermissions: FC<{
  componentLoader: () => Promise<{ default: () => JSX.Element }>;
  title: string;
  pageKey: PageKey;
}> = memo(({ componentLoader, title, pageKey }) => {
  const { pages } = useAppSelector(pagesStatusState);
  const cbRef = useRef<any>(null);

  useEffect(() => {
    getPageFallback({ pageKey, pages, loader: componentLoader, callBack: cbRef.current, title });
  }, [pages, pageKey]);

  const Component = React.lazy(() => {
    return new Promise<{ default: () => JSX.Element }>((resolve) => {
      cbRef.current = resolve;
    });
  });

  return (
    <Suspense fallback={<FallBackLoader />}>
      <Component />
    </Suspense>
  );
});
