import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants";

import styles from "./MessagseCounter.module.scss";

import { ReactComponent as MessageIcon } from "@images/message.svg";

interface IMessagesCounterProps {
  count: number;
}

export const MessagesCounter: FC<IMessagesCounterProps> = ({ count }) => {
  const navigate = useNavigate();
  if (count === 0) return null;

  return (
    <div
      className={styles.messagesContainer}
      onClick={() => navigate(ROUTES.odinchat.path)}
      title={`${count} new messages`}
    >
      <div className={styles.count}>
        {/* <span>
          <MessageIcon />
        </span> */}
        <span>{count > 99 ? "99+" : count}</span>
      </div>
    </div>
  );
};
