import dayjs from "dayjs";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  CreateNew,
  Filter,
  IMenuItem,
  InputItem,
  Pagination,
} from "@/components";
import { DATE_ISO, MyStrategyFilterType, ROUTES } from "@/constants";
import { useDownloadLivePositions } from "@/features/StrategyCreate";
import { useAppDispatch, useAppSelector, usePagination, useSearchTextInList } from "@/hooks";
import {
  fetchCopyItem,
  resetFilter,
  selectMyStrategies,
  setFilter,
} from "@/store/strategies/my_strategies";
import { IPaginator, IStartegyCard } from "@/types";

import { StrategyItem } from "../StrategyItem/StrategyItem";

import styles from "./MyStrategies.module.scss";

import { ReactComponent as ResetIcon } from "@images/reload.svg";

interface IProps {
  isFavorites?: boolean;
  strategiesList: IStartegyCard[];
  paginator: IPaginator;
}

export const MyStrategies: FC<IProps> = ({ isFavorites = false, strategiesList, paginator }) => {
  const { page, perPage, setPage, setPerPage, perPageList } = paginator;
  const { filter } = useAppSelector(selectMyStrategies);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectHandler = useCallback((item: IStartegyCard) => {
    navigate(`${ROUTES.strategiesBuilder.path}/edit/${item.id}?name=${item.name}`);
  }, []);

  const selectMenuHandler = useCallback((menuItem: IMenuItem, item: IStartegyCard) => {
    if (menuItem.type === "copy") {
      dispatch(fetchCopyItem(item));
    }
    if (menuItem.type === "edit") {
      navigate(`${ROUTES.strategiesBuilder.path}/edit/${item.id}`);
    }
    if (menuItem.type === "view") {
      navigate(`${ROUTES.strategiesPreview.path}/${item.id}?name=${encodeURIComponent(item.name)}`);
    }
  }, []);

  const {
    filteredList: searchFiltered,
    searchText,
    handleSearch,
    handleEnter,
  } = useSearchTextInList(strategiesList, setPage);

  const isFilterChecked = Object.entries(filter).some(([_, checked]) => checked);

  const filteredList = useMemo(() => {
    const isTypeNotSelected = !filter.typeCore && !filter.typeOverlay;
    const isInstrumentNotSelected = !(
      filter.baskets ||
      filter.cryptos ||
      filter.options ||
      filter.stockEtfs
    );
    const result = isFilterChecked
      ? searchFiltered.filter(
          (el) =>
            ((el.config.type === "core" && filter.typeCore) ||
              (el.config.type === "overlay" && filter.typeOverlay) ||
              isTypeNotSelected) &&
            ((el.config.trading_instrument === "baskets" && filter.baskets) ||
              (el.config.trading_instrument === "cryptos" && filter.cryptos) ||
              (el.config.trading_instrument === "derivatives" && filter.options) ||
              (el.config.trading_instrument === "etfs" && filter.stockEtfs) ||
              (el.config.trading_instrument === "equities" && filter.stockEtfs) ||
              isInstrumentNotSelected)
        )
      : searchFiltered;

    return result;
  }, [searchFiltered, filter, isFilterChecked]);

  const { totalPages, paginatedList } = usePagination<IStartegyCard>({
    list: filteredList,
    page,
    perPage,
  });

  const resetFilterHandle = () => {
    dispatch(resetFilter());
  };

  const changeCheckHandle = useCallback((checked: boolean, name?: string) => {
    dispatch(setFilter({ name: name as MyStrategyFilterType, checked }));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.searchWrapper}>
        <InputItem
          onChange={handleSearch}
          onEnter={handleEnter}
          label=""
          name="strategy-search"
          type="search"
          placeholder="Search Strategy"
          noPaddingBottom
        />

        <Filter active={isFilterChecked}>
          <p className={styles.filterTitle}>Trading Instrument</p>
          <div className={styles.filterCheckList}>
            <Checkbox
              id="stockEtfs"
              name="stockEtfs"
              label="Stocks/ETFs"
              checked={filter.stockEtfs}
              onChange={changeCheckHandle}
            />
            <Checkbox
              id="baskets"
              name="baskets"
              label="Baskets"
              checked={filter.baskets}
              onChange={changeCheckHandle}
            />
            <Checkbox
              id="options"
              name="options"
              label="Options"
              checked={filter.options}
              onChange={changeCheckHandle}
            />
            <Checkbox
              id="cryptos"
              name="cryptos"
              label="Cryptos"
              checked={filter.cryptos}
              onChange={changeCheckHandle}
            />
          </div>
          <p className={styles.filterTitle}>Strategy Type</p>
          <div className={styles.filterCheckList}>
            <Checkbox
              id="typeCore"
              name="typeCore"
              label="Core"
              checked={filter.typeCore}
              onChange={changeCheckHandle}
            />
            <Checkbox
              id="typeOverlay"
              name="typeOverlay"
              label="Overlay"
              checked={filter.typeOverlay}
              onChange={changeCheckHandle}
            />
          </div>
          <div className={styles.filterButtonWrapper}>
            <Button
              text="Reset to Default"
              type="secondary"
              IconComponent={ResetIcon}
              onClick={resetFilterHandle}
            />
          </div>
        </Filter>
      </div>

      <div className={`${styles.wrapper} ${isFavorites ? styles.noPadding : ""}`}>
        <div className={styles.paginationContainer}>
          <Pagination
            count={filteredList?.length || 0}
            perPageList={perPageList}
            totalCount={totalPages}
            perPage={perPage}
            currentPage={page}
            onChangePerPage={setPerPage}
            onPage={setPage}
            countLabel="Strategies"
          />
        </div>
        <StrtaegiesList
          isFavorites={isFavorites}
          strategiesList={paginatedList}
          selectHandler={selectHandler}
          selectMenuHandler={selectMenuHandler}
          searchText={searchText}
        />
      </div>
    </div>
  );
};

interface IStrtaegiesListProps {
  isFavorites: boolean;
  strategiesList: IStartegyCard[];
  selectHandler: (item: IStartegyCard) => void;
  selectMenuHandler: (menuItem: IMenuItem, item: IStartegyCard) => void;
  searchText?: string;
}

export const StrtaegiesList: FC<IStrtaegiesListProps> = ({
  isFavorites,
  strategiesList,
  selectHandler,
  selectMenuHandler,
  searchText,
}) => {
  const [downloadCSV, progress] = useDownloadLivePositions();
  const [progressId, setProgressId] = useState<number | null>(null);

  const downloadLivePositionHandler = useCallback((item: IStartegyCard) => {
    const fileName = `${item.name}_live_position_${dayjs().format(DATE_ISO)}`;
    downloadCSV(item.id, fileName);
    setProgressId(item.id);
  }, []);

  useEffect(() => {
    if (!progress) setProgressId(null);
  }, [progress]);

  return (
    <div className={styles.inner}>
      <div className={styles.listWrapper}>
        {!isFavorites && (
          <div className={styles.createNewItem}>
            <CreateNew
              className={styles.newItem}
              to={ROUTES.strategyCreate.path}
              text="Create New Strategy"
            />
          </div>
        )}
        {strategiesList?.map((strategy, idx) => (
          <StrategyItem
            item={strategy}
            onSelect={selectHandler}
            onPositionDownload={downloadLivePositionHandler}
            key={`${strategy.id}-${idx}`}
            style={{ maxWidth: "100%", minWidth: "100%" }}
            isFavorites={isFavorites}
            onSelectMenu={selectMenuHandler}
            idx={idx}
            progress={progressId === strategy.id}
            disabledDownload={progress}
            searchText={searchText}
            hasMenu
          />
        ))}
      </div>
    </div>
  );
};
