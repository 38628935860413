import { AxiosResponse } from "axios";

import { ICompany, ICompanyPayload } from "@/types/company";

import axios from "./axios.config";

export const CompanyService = {
  getCompanies: (): Promise<AxiosResponse<ICompany[]>> => axios.get("/companies"),
  updateCompany: (id: number, data: ICompanyPayload): Promise<AxiosResponse<ICompany>> =>
    axios.put(`/companies/${id}`, data),
  createCompany: (data: ICompanyPayload): Promise<AxiosResponse<ICompany>> =>
    axios.post("/companies", data),
};
