import { FC, Fragment, useRef, useState } from "react";

import { Button, Confirm, DropdownMultiSearch, ItemType, Loader, ModalLayout } from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { ReactComponent as RemoveIcon } from "@/images/nest.svg";
import { ReactComponent as UploadIcon } from "@/images/upload-icon.svg";

import styles from "./UploadCSVForm.module.scss";

interface IProps {
  csvList: ItemType<string>[];
  isLoading: boolean;
  canDelete: boolean;
  selectedCSVFiles?: ItemType<string>[] | null;
  selectedCSVFile?: ItemType<string> | null;
  deletingFileName: string | null;
  isMultiSelect?: boolean;

  onSelectCSVHandler?: (name: string, item: ItemType<string>[]) => void;
  onSelectOneCSVHandler?: (name: string, item: ItemType<string>) => void;
  onUploadCSVHandler?: (file: FileList | null) => void;
  onRemoveCSVHandler?: () => void;
  onUnselectCSVHandler?: () => void;

  InfoHelpComponent?: () => JSX.Element;
  CustomLabel?: () => JSX.Element;
  withInfo?: boolean;

  uploadButtonText?: string;
  removeButtonText?: string;
  placeholder?: string;
}

export const UploadCSVForm: FC<IProps> = ({
  csvList,
  isLoading,
  canDelete,
  selectedCSVFiles,
  selectedCSVFile,
  deletingFileName,
  isMultiSelect = true,
  withInfo,

  onSelectCSVHandler,
  onSelectOneCSVHandler,

  onUploadCSVHandler,
  onRemoveCSVHandler,
  onUnselectCSVHandler,

  CustomLabel,
  InfoHelpComponent,

  uploadButtonText = "Upload CSV",
  removeButtonText = "Remove CSV",
  placeholder,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showModal, setShowModal] = useState(false);

  const uploadHandler = () => {
    if (onUploadCSVHandler) onUploadCSVHandler(fileInputRef.current?.files || null);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const uploadClickHandler = () => {
    fileInputRef.current?.click();
  };

  const closeHandler = () => {
    setShowModal(false);
  };

  const deleteHandler = () => {
    if (onUnselectCSVHandler) onUnselectCSVHandler();
    else setShowModal(true);
  };

  const confirmDelete = () => {
    if (onRemoveCSVHandler) onRemoveCSVHandler();
    setShowModal(false);
  };

  return (
    <Fragment>
      <div className={styles.itemWrapper}>
        {isMultiSelect ? (
          <DropdownMultiSearch
            name="csvFileMulty"
            placeholder={placeholder || "CSV file name"}
            list={csvList}
            onSelect={
              onSelectCSVHandler
                ? onSelectCSVHandler
                : () => {
                    //
                  }
            }
            selected={selectedCSVFiles || []}
            loading={isLoading}
            disable={csvList ? !csvList.length : true}
            transformTextToUpper={false}
            CustomLabel={CustomLabel}
          />
        ) : (
          <DropdownSearch
            name="csvFile"
            placeholder={placeholder || "CSV file name"}
            list={csvList}
            onSelect={
              onSelectOneCSVHandler
                ? onSelectOneCSVHandler
                : () => {
                    //
                  }
            }
            selected={selectedCSVFile || null}
            loading={isLoading}
            disable={csvList ? !csvList.length : true}
            actionElement={CustomLabel}
            visibleRows={7}
          />
        )}
        {InfoHelpComponent ? <InfoHelpComponent /> : null}
      </div>

      <div className={`${styles.buttonsGroup} ${withInfo ? styles.smallWidth : ""}`}>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          onChange={uploadHandler}
          accept=".csv"
        />
        <Button
          type="gost"
          text={removeButtonText}
          IconComponent={RemoveIcon}
          onClick={deleteHandler}
          disabled={(!selectedCSVFile && !selectedCSVFiles) || isLoading || !canDelete}
          danger
        />
        {!withInfo ? (
          <Button
            type="primary"
            text={uploadButtonText}
            IconComponent={UploadIcon}
            onClick={uploadClickHandler}
            disabled={isLoading}
          />
        ) : null}
      </div>
      <ModalLayout show={showModal} onClose={closeHandler}>
        <Confirm
          type="delete"
          onConfirm={confirmDelete}
          onCancel={closeHandler}
          text={`Do you really want to delete the '${deletingFileName}' file? You may click 'Delete' to delete this CSV file or 'Cancel' to cancel the action.`}
        />
      </ModalLayout>
    </Fragment>
  );
};
