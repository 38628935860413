export const enum AdminPanelTabsEnum {
  PAGE_INFO = "Page Info",
  DOCUMENTS = "Documents",
}

export const enum AdminTabsEnum {
  TUTORIALS = "Tutorials",
  DOCUMENTS = "Documents",
  USERS_APPROVAL = "Users Approval",
}
