import { AxiosResponse } from "axios";

import { IFolder, IFolderDataPayload, IUpdateFolderPayload } from "@/types";

import axios from "./axios.config";

export const FocusService = {
  getFolders: (): Promise<AxiosResponse<{ result: IFolder[] }>> => axios.get("/databrowser/folder"),
  createFolder: (name: string): Promise<AxiosResponse<{ result: IFolder }>> =>
    axios.post("/databrowser/folder", { name }),
  deleteFolder: (id: number): Promise<AxiosResponse<any>> =>
    axios.delete(`/databrowser/folder/${id}`),
  updateFolder: (id: number, name: string): Promise<AxiosResponse<{ result: IFolder }>> =>
    axios.put(`/databrowser/folder/${id}`, { name }),
  getFolder: (id: string | number): Promise<AxiosResponse<{ result: IFolder }>> =>
    axios.get(`/databrowser/folder/${id}`),
  addDataToFolder: (
    id: string | number,
    data: IFolderDataPayload
  ): Promise<AxiosResponse<{ result: any }>> => axios.post(`/databrowser/folder/${id}`, data),

  updateDataInFolder: (
    savedDataId: string | number,
    command: IUpdateFolderPayload
  ): Promise<AxiosResponse<{ result: any }>> =>
    axios.put(`/databrowser/saved-data/${savedDataId}`, { command }),
  replaceDataInFolder: (
    savedDataId: string | number,
    folder_id: number,
    action: "copy" | "move"
  ): Promise<AxiosResponse<{ result: any }>> =>
    axios.put(`/databrowser/saved-data/${savedDataId}`, { folder_id, action }),
  deleteDataInFolder: (savedDataId: string | number): Promise<AxiosResponse<{ result: any }>> =>
    axios.delete(`/databrowser/saved-data/${savedDataId}`),
};
