import { FC } from "react";

import { Button, InfoHeading, Loader } from "@/components";

import { usePresentation } from "./hooks/usePresentation";

import styles from "./WelcomeVideGuide.module.scss";

import BgImage from "@images/bg-image.jpg";
import { ReactComponent as InfoIcon } from "@images/InfoIcon.svg";
import { ReactComponent as LogoIcon } from "@images/logo-odin.svg";
import { ReactComponent as PlayIcon } from "@images/play.svg";

interface IProps {
  isMenuFullWidth: boolean;
  isMobile: boolean;
}

const VIDEO_GUIDE_LINK =
  "https://odin-media.s3.amazonaws.com/tutorials/v4/OdinUltra_Intro_11.21.mp4";

export const WelcomeVideGuide: FC<IProps> = ({ isMenuFullWidth, isMobile }) => {
  const {
    isPresentationShowed,
    startAnimation,
    isMobileDevice,
    documentReady,
    showWelcome,
    videoRef,
    loading,

    onError,
    playVideoHandler,
    manuallyPlayHandler,
    closeWelcomeWindowHandler,
  } = usePresentation();

  if (!showWelcome || isPresentationShowed) return null;

  return (
    <div
      className={`${styles.container} ${isMenuFullWidth || isMobile ? styles.fullWidth : ""} ${
        isMobile ? styles.mobileView : ""
      } ${startAnimation.first ? styles.animate : ""}`}
    >
      <div className={styles.inner} style={{ backgroundImage: `url(${BgImage})` }}>
        <div className={styles.wrapper}>
          <div className={styles.hederWrapper}>
            <InfoHeading>Welcome to OdinUltra</InfoHeading>
          </div>
          <section className={styles.section}>
            <div className={styles.topInfoTitleContainer}>
              <div className={styles.icon}>
                <InfoIcon />
              </div>
              <h3 className={styles.title}>
                This short video guide will help you understand how OdinUltra works.
              </h3>
            </div>
            <div className={styles.videoContainer}>
              <div className={styles.videoWrapper}>
                <div
                  className={`${styles.videoBlock} ${
                    !loading && !documentReady.hasIteractions ? styles.darken : ""
                  }`}
                >
                  {startAnimation.third && (
                    <video
                      src={VIDEO_GUIDE_LINK}
                      ref={videoRef}
                      onCanPlay={playVideoHandler}
                      onError={onError}
                      playsInline
                      muted
                      controls={false}
                    >
                      <source src={VIDEO_GUIDE_LINK} type="video/mp4" />
                    </video>
                  )}
                </div>
                {loading && (
                  <div className={styles.loaderContainer}>
                    <Loader />
                  </div>
                )}
                {!loading && !documentReady.hasIteractions && !isMobileDevice && (
                  <div className={styles.helpTextContainer}>
                    <p className={styles.helpText}>
                      Please click here or somewhere on the page to start the presentation.
                    </p>
                  </div>
                )}
                {documentReady.error && (
                  <div className={styles.errorContainer}>
                    <p className={styles.helpText}>
                      Sorry, the video guide is not available. Please click Close to hide this help.
                    </p>
                  </div>
                )}
                {
                  <div
                    className={`${styles.imagePoster} ${startAnimation.second ? styles.show : ""}`}
                  >
                    <img
                      src="https://odin-media.s3.amazonaws.com/OdinUltra-placeholder.jpg"
                      alt="odin-ultra"
                    />
                    <LogoIcon />
                  </div>
                }
                {isMobileDevice && startAnimation.third && !documentReady.hasIteractions && (
                  <div className={styles.playButtonBlock} onClick={manuallyPlayHandler}>
                    <button className={styles.playButton}>
                      <PlayIcon />
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  text={documentReady.error ? "Close" : "Got it, Thanks"}
                  onClick={closeWelcomeWindowHandler}
                  disabled={!documentReady.canClose}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
