import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { ItemType, Popover, RadioOption, RadioSelect, Tag } from "@/components";
import { DropdownMultiSearch } from "@/components/Dropdown/DropdownMultiSearch";
import { DIMENSIONS_FILTER_OPERATORS, FILTER_OPERATORS } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";

import styles from "./Dimensions.module.scss";

interface IProps {
  filterParams: Record<string, string[]>;
  setSelected: Dispatch<SetStateAction<Record<string, ItemType<string>[]>>>;
  selected: Record<string, ItemType<string>[]>;
  dimensions: string[];
  setDimensions: Dispatch<SetStateAction<string[]>>;
  operation: Record<string, string>;
  setOperation: Dispatch<SetStateAction<Record<string, string>>>;
}

export const Dimensions: FC<IProps> = ({
  filterParams,
  setSelected,
  selected,
  dimensions,
  setDimensions,
  operation,
  setOperation,
}) => {
  const { drsMeta } = useAppSelector(discoverDrsMetaState);
  const handleSelect = (name: string, item: ItemType<string>[]) => {
    const filteredItem = item.filter((subItem) => subItem.value.trim() !== "");

    setSelected((state) => {
      const nextState = { ...state };

      if (filteredItem.length === 0) {
        delete nextState[name];
      } else {
        nextState[name] = filteredItem;
      }

      return nextState;
    });
  };

  useEffect(() => {
    if (!dimensions.length) return;
    const defaultOperators = dimensions.reduce((acc, dim) => {
      acc = { ...acc, [dim]: FILTER_OPERATORS.eq.id };
      return acc;
    }, {});
    setOperation(defaultOperators);
  }, [dimensions, setOperation]);

  useEffect(() => {
    setDimensions(drsMeta?.format?.dimensions ?? []);
  }, [drsMeta?.format.dimensions, setDimensions]);

  const handleRemove = (key: string) => () => {
    setDimensions(dimensions.filter((label) => label !== key));
    setSelected({});
  };

  const handleSelectOperator = (id: string, dimension: string) => () => {
    setOperation((state) => ({
      ...state,
      [dimension]: id,
    }));
  };

  if (!dimensions.length) {
    return null;
  }
  const content = (dimension: string) => {
    return (
      <div className={styles.content}>
        <RadioSelect>
          {DIMENSIONS_FILTER_OPERATORS.map((item) => (
            <RadioOption
              key={item.key}
              selected={operation[dimension] === item.key}
              onClick={handleSelectOperator(item.key, dimension)}
            >
              {item.value}
            </RadioOption>
          ))}
        </RadioSelect>
        <DropdownMultiSearch
          list={
            filterParams?.[dimension]?.map((param) => ({
              key: param,
              value: param,
            })) || []
          }
          onSelect={handleSelect}
          selected={selected[dimension] || []}
          label=""
          name={dimension}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionName}>
        <span>Dimensions</span>
      </div>
      {dimensions.map((dimension) => (
        <Popover key={dimension} content={<div>{content(dimension)}</div>}>
          <Tag color="primary" canDelete={dimensions.length > 1} onDelete={handleRemove(dimension)}>
            <div className={styles.tagContent}>
              <div>{dimension}</div>
              {selected?.[dimension]?.length && (
                <div>
                  [
                  <span>
                    {
                      DIMENSIONS_FILTER_OPERATORS.find((oper) => oper.key === operation[dimension])
                        ?.value
                    }{" "}
                  </span>
                  {selected?.[dimension]?.map((item) => (
                    <span className={styles.comma} key={item.id}>
                      {item.value}
                    </span>
                  ))}
                  ]
                </div>
              )}
            </div>
          </Tag>
        </Popover>
      ))}
    </div>
  );
};
