import { FC } from "react";

import styles from "./RadioSelect.module.scss";
interface IProps {
  children: React.ReactNode;
}

interface IPropsOption {
  children: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
}
const RadioSelect: FC<IProps> = ({ children }) => {
  return <ul className={styles.radioSelect}>{children}</ul>;
};

const RadioOption: FC<IPropsOption> = ({ children, onClick, selected }) => {
  return (
    <li className={`${styles.option} ${selected ? styles.selected : ""}`} onClick={onClick}>
      {children}
    </li>
  );
};

export { RadioSelect, RadioOption };
