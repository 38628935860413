import { useEffect, useRef } from "react";

import { DEFAULT_PER_PAGE } from "@/store/odinchat";

import { useInViewport } from "./useInViewport";

export const usePreloadMessages = (
  list: any,
  preloader: boolean,
  onLoadMoreData: () => void,
  listRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const canScrollIntoView = useRef(false);
  const lastId = useRef(list.length ? list[0][0].id : null);
  const listLenRef = useRef(list.flat(2).length);

  const [invewportItemRef, isInViewport] = useInViewport<HTMLDivElement>(listRef);

  // scroll on preload old history messages
  useEffect(() => {
    const lastID = list && list.length ? list[0][0].id : null;

    if (preloader) {
      lastId.current = lastID;
      canScrollIntoView.current = true;
    } else {
      if (canScrollIntoView.current) {
        const item = document.querySelector(`[data-message-id="${lastId.current}"]`);
        item?.scrollIntoView({ block: "center" });
        canScrollIntoView.current = false;
        lastId.current = lastID;
      }
    }
  }, [list, preloader]);

  useEffect(() => {
    if (isInViewport && listLenRef.current >= DEFAULT_PER_PAGE) onLoadMoreData();
  }, [isInViewport]);

  listLenRef.current = list.flat(2).length;

  return [invewportItemRef];
};
