import { FC, memo, useCallback, useMemo, useState } from "react";

import { ExtendedColumns, LoaderWrapper, Pagination, Table } from "@/components";
import { ICompanyCalendar } from "@/types";

import styles from "./CompanyCalendarTable.module.scss";

interface IProps {
  CompanyCalendarData: ICompanyCalendar[];
  isLoading: boolean;
}

export const CompanyCalendarTable: FC<IProps> = memo(({ CompanyCalendarData, isLoading }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);
  const paginatedList = useMemo(
    () =>
      CompanyCalendarData
        ? CompanyCalendarData.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
        : [],
    [page, CompanyCalendarData, perPage]
  );
  const totalPages = CompanyCalendarData ? Math.ceil(CompanyCalendarData.length / perPage) : null;
  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "display_date",
        canSort: false,
        disableSortBy: true,
        minWidth: 100,
        canExpand: true,
      },
      {
        Header: "Ticker",
        accessor: "ticker",
        canSort: true,
        isTruncated: true,
        minWidth: 100,
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        canSort: true,
        isTruncated: true,
        minWidth: 100,
      },
      {
        Header: "Quarter",
        accessor: "quarter",
        canSort: true,
        isTruncated: true,
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <LoaderWrapper loading={isLoading} atom fullWidth>
      <Table<ICompanyCalendar> columns={columns} data={paginatedList} isNotClickable />
      {paginatedList.length > 0 && (
        <div className={styles.pagination}>
          <Pagination
            onPage={setPage}
            onChangePerPage={selectPerPageHandler}
            perPage={perPage}
            currentPage={page}
            totalCount={totalPages}
            count={CompanyCalendarData?.length}
            perPageList={[10, 20, 50, 100]}
            countLabel="Total items"
          />
        </div>
      )}
      <div className={styles.unconfirmed}>
        <span>* Unconfirmed</span>
      </div>
    </LoaderWrapper>
  );
});
