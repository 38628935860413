import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import { Errors } from "@/constants/errors";
import { strategiesService } from "@/services";
import { IPermissionsData, StrategyUserPermission } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "..";

interface IInitialState {
  isLoading: boolean;
  permissions: StrategyUserPermission[];
  isError: boolean | null;
}

const initialState: IInitialState = {
  isLoading: false,
  permissions: [],
  isError: null,
};

export const fetchPermissions = createAsyncThunk(
  "strategyPermissions/fetchPermissions",
  async (key: string) => {
    if (Number.isInteger(Number(key))) {
      const { data } = await strategiesService.getStrategyPermissions(Number(key));
      return data?.result;
    } else return [];
  }
);
export const fetchSavePermissions = createAsyncThunk(
  "strategyPermissions/fetchSavePermissions",
  async (permissions: IPermissionsData) => {
    const { data } = await strategiesService.savePermissions(permissions.id, permissions.data);
    return data;
  }
);

export const strategyPermissionsSlice = createSlice({
  name: "strategyPermissions",
  initialState,
  reducers: {
    init: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.isLoading = true;
        state.permissions = [];
        state.isError = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
        if (payload) {
          state.permissions = payload;
        }
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(fetchSavePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSavePermissions.fulfilled, (state) => {
        state.isLoading = false;
        notification.success("Permissions was updated!");
      })
      .addCase(fetchSavePermissions.rejected, (state) => {
        state.isLoading = false;
        notification.error(Errors.update.permissions);
      });
  },
});

const state = (state: RootState) => state;

export const strategyPermissionState = createSelector(state, (state) => state.strategyPermissions);

export const {
  init,
  //
} = strategyPermissionsSlice.actions;

export default strategyPermissionsSlice.reducer;
