import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScrollRestoration, useNavigate, useSearchParams } from "react-router-dom";

import { Heading, Loader, ModalLayout, NoData, Pagination } from "@/components";
import { LoaderWrapper } from "@/components/LoaderWrapper/LoaderWrapper";
import { PREVENT_SCROLL, ROUTES, SCROLL_TO_ID, paginator } from "@/constants";
import { useAppDispatch, useAppSelector, usePagination } from "@/hooks";
import {
  fetchCommonStrategies,
  commonStrategiesState,
  setPage,
  setPerPage,
} from "@/store/strategies/commonStrategies";
import { ICommonStrategyData } from "@/types";

import { CommonStrategyItem } from "../CommonStrategyItem/CommonStrategyItem";
import { DateSelectConfirmation } from "../DateSelectConfirmation/DateSelectConfirmation";
import { Search } from "../Search/Search";

import styles from "./CommonStartegies.module.scss";

export const CommonStartegies: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { list, isLoading, hasMore, page, perPage } = useAppSelector(commonStrategiesState);
  const selectedItemRef = useRef<ICommonStrategyData | null>(null);
  const [search, setSearch] = useSearchParams();
  const id = search.get(SCROLL_TO_ID);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((selectedPerPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(selectedPerPage));
  }, []);

  const { totalPages, paginatedList, isEmpty } = usePagination<any>({
    list: list || [],
    page,
    perPage,
  });

  const getMoreHandler = () => {
    dispatch(fetchCommonStrategies());
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectHandler = useCallback(
    (item: ICommonStrategyData) => {
      if (item) {
        selectedItemRef.current = item;
        setShowModal(true);
        setSearch({ [SCROLL_TO_ID]: item.id.toString(), [PREVENT_SCROLL]: "true" });
      }
    },
    [navigate, search]
  );

  const confirmHandler = (dateRange: { start: string | null; end: string | null } | null) => {
    const item = selectedItemRef.current;
    if (item) {
      const start = dateRange?.start ? `&start_date=${dateRange?.start}` : "";
      const end = dateRange?.end ? `&end_date=${dateRange?.end}` : "";
      navigate(
        `${ROUTES.strategiesBuilder.path}/preview/${encodeURIComponent(
          item.key
        )}?name=${encodeURIComponent(item.name)}${start}${end}`
      );
    }
    setShowModal(false);
  };

  const closeHandler = () => {
    setShowModal(false);
    selectedItemRef.current = null;
    setSearch({ [PREVENT_SCROLL]: "true" });
  };

  useEffect(() => {
    if (!list) dispatch(fetchCommonStrategies());
  }, [dispatch, list]);

  useEffect(() => {
    if (id && showModal === false) {
      const item = document.querySelector(`[data-id="${id}"]`);
      if (item) {
        setTimeout(() => {
          item.scrollIntoView({ block: "center", behavior: "smooth" });
        }, 10);
        setSearch({ [PREVENT_SCROLL]: "true" });
      }
    }
  }, [id, showModal]);

  const isFirstTimeListLoading = !!(isLoading && list === null);
  const isLoadingMoreData = !!(isLoading && list?.length);

  return (
    <div className={styles.container}>
      <div className={styles.headingGroup}>
        <Heading type="h3" className={styles.subHeading}>
          Prebuilt Strategies
        </Heading>
      </div>
      <div className={styles.wrapper}>
        <Search
          hasSearch={(e) => {
            //
          }}
        />
        <div className={styles.paginationContainer}>
          <Pagination
            count={list?.length || 0}
            perPageList={paginator.perPageList}
            totalCount={totalPages}
            perPage={perPage}
            currentPage={page}
            onChangePerPage={selectPerPageHandler}
            onPage={selectPageHandler}
            onGetMore={getMoreHandler}
            hasMoreItems={hasMore}
            disabled={isLoadingMoreData}
          />
        </div>
        <LoaderWrapper loading={isFirstTimeListLoading}>
          <div className={styles.listContainer}>
            {paginatedList.map((item) => (
              <CommonStrategyItem key={`${item.id}`} item={item} onSelect={selectHandler} />
            ))}
          </div>
          {isLoadingMoreData && (
            <div className={styles.moreDataLoadContainer}>
              <Loader />
            </div>
          )}
          {isEmpty ? <NoData text="Search result is empty." /> : null}
        </LoaderWrapper>
      </div>
      <ModalLayout show={showModal} onClose={closeHandler}>
        <DateSelectConfirmation
          name={selectedItemRef.current?.name || null}
          onConfirm={confirmHandler}
          onCancel={closeHandler}
          type={selectedItemRef.current?.type || null}
          description={selectedItemRef.current?.description || null}
        />
      </ModalLayout>
    </div>
  );
};
