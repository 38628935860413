import { FC, useMemo } from "react";

import { Pagination, TableExpand } from "@/components";
import { paginator as defaultPaginator } from "@/constants";
import { useAppSelector, useExtendPagination } from "@/hooks";
import { selectIndicatorConstructor } from "@/store/strategies/customIndicator";

import styles from "./SignalsTable.module.scss";

interface IProps {
  visible?: boolean;
}

export const SignalsTable: FC<IProps> = ({ visible = true }) => {
  const { isLoading, signal } = useAppSelector(selectIndicatorConstructor);

  const tableData = useMemo(() => {
    return signal ? signal : [];
  }, [signal]);

  const { totalPages, perPageHandler, pageHandler, resetPages, paginator } = useExtendPagination({
    page: 1,
    perPage: defaultPaginator.small,
    list: tableData,
  });

  const columns = useMemo(
    () => [
      { Header: "Date", accessor: "date", minWidth: 200, canSort: true },
      { Header: "Ticker", accessor: "ticker", minWidth: 200, canSort: true },
      { Header: "Signal", accessor: "signal", minWidth: 200, canSort: true },
    ],
    []
  );

  if (!signal || !visible || (signal && signal.length === 0)) return null;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Signals</p>
      <div className={styles.innerContent}>
        <div className={styles.tableWrapper}>
          <TableExpand
            columns={columns}
            data={tableData}
            size="medium"
            currentPage={paginator.page}
            perPage={paginator.perPage}
            resetPage={resetPages}
          />
        </div>
        <div className={styles.paginationWrapper}>
          <Pagination
            onPage={pageHandler}
            onChangePerPage={perPageHandler}
            perPage={paginator.perPage}
            currentPage={paginator.page}
            totalCount={totalPages}
            count={tableData.length}
            perPageList={defaultPaginator.defaultPages}
            showTotal={true}
            countLabel="Total Rows"
          />
        </div>
      </div>
    </div>
  );
};
