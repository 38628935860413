import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { discoverService } from "@/services";
import { IDrsSerachResult } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IDiscoverSearchState {
  data: IDrsSerachResult[];
  isLoading: boolean;
}

const initialState: IDiscoverSearchState = {
  data: [],
  isLoading: false,
};

export const searchDrs = createAsyncThunk(
  "discover/search",
  async ({
    query,
    drsId,
    granularity,
  }: {
    query: string;
    drsId?: number;
    granularity?: string;
  }) => {
    const response = await discoverService.searchDRS(query, drsId, granularity);
    return response.data;
  }
);

export const discoverSearchSlice = createSlice({
  name: "discoverSearch",
  initialState,
  reducers: {
    resetSearchResult: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchDrs.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(searchDrs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchDrs.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const { resetSearchResult } = discoverSearchSlice.actions;

const state = (state: RootState) => state;

export const discoverSearchState = createSelector(state, (state) => state.discoverSearch);

export default discoverSearchSlice.reducer;
