import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IInitialState {
  isVideoMuted: boolean;
}

const initialState: IInitialState = {
  isVideoMuted: true,
};

export const homeStartSlice = createSlice({
  name: "homeStart",
  initialState,
  reducers: {
    init: (state) => {
      //
    },
    muteVideo: (state, { payload }: PayloadAction<boolean>) => {
      state.isVideoMuted = payload;
    },
  },
});

const state = (state: RootState) => state;

export const selectHomeStart = createSelector(state, (state) => state.homeStart);

export const { init, muteVideo } = homeStartSlice.actions;

export default homeStartSlice.reducer;
