import { constants } from "buffer";

import { FC, useEffect } from "react";

import { TooltipWrapper } from "@/components";
import { DEFAULT_FORMAT_OPTONS, repalceList } from "@/constants/discover";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import { ReactComponent as ChartIcon } from "@/images/chart-histogram-two-filled.svg";
import { ReactComponent as TableIcon } from "@/images/table-file-filled.svg";
import { getDrsList, discoverDrsListState } from "@/store/discover/drsList";
import { IDrs, ITable } from "@/types";
import { drsGroupe } from "@/utils";

import styles from "./Categories.module.scss";

interface IProps {
  selectedDrs: (drs: IDrs[]) => void;
}

export const Categories: FC<IProps> = ({ selectedDrs }) => {
  const { drsList } = useAppSelector(discoverDrsListState);
  const dispatch = useAppDispatch();

  const drsGroupeByCategory = drsGroupe(drsList, "category");
  const drsGroupeBySubcategory = drsGroupe(drsList, "subcategory");

  useEffect(() => {
    if (drsList?.length) return;
    dispatch(getDrsList());
  }, [dispatch, drsList?.length]);

  const handleSubcategoryClick = (subcategory: string) => () => {
    selectedDrs(drsGroupeBySubcategory[subcategory]);
  };

  const getNumberOfTables = (category: IDrs[]) => {
    return category.reduce((acc: ITable[], drs: IDrs) => {
      acc = [...acc, ...drs.meta.tables];
      return acc;
    }, []).length;
  };

  const getNumberOfTimeSeries = (category: IDrs[]) => {
    const tables = category.reduce((acc: ITable[], drs: IDrs) => {
      acc = [...acc, ...drs.meta.tables];
      return acc;
    }, []);
    const count = tables.reduce((acc: number, table) => {
      return acc + (table?.count || 0);
    }, 0);
    return count ? count.toLocaleString("en-us", DEFAULT_FORMAT_OPTONS) : count;
  };

  const replaceCayegoryName = (category: string) => {
    const replaceItem = repalceList.find((i) => i.key === category);
    return replaceItem ? replaceItem.replace : category;
  };

  return (
    <>
      {Object.keys(drsGroupeByCategory)
        .sort()
        .map((category) => {
          return (
            <div key={category} className={styles.container}>
              <div className={styles.category}>
                <h3>{replaceCayegoryName(category)}</h3>
                <div className={styles.badgesContainer}>
                  <TooltipWrapper
                    text={`${getNumberOfTables(drsGroupeByCategory[category])} Data tables `}
                  >
                    <span className={styles.numberOfCategories}>
                      <TableIcon />
                      <span>{getNumberOfTables(drsGroupeByCategory[category])}</span>
                    </span>
                  </TooltipWrapper>
                  <TooltipWrapper
                    text={`${getNumberOfTimeSeries(drsGroupeByCategory[category])}+ Time series`}
                  >
                    <span className={styles.numberOfCategories}>
                      <ChartIcon />
                      <span>{getNumberOfTimeSeries(drsGroupeByCategory[category])}+</span>
                    </span>
                  </TooltipWrapper>
                </div>
              </div>
              <div className={styles.subcategories}>
                {Object.keys(drsGroupe(drsGroupeByCategory[category], "subcategory"))
                  .sort()
                  .map((subcategory) => {
                    return (
                      <div
                        onClick={handleSubcategoryClick(subcategory)}
                        className={styles.subcategory}
                        key={subcategory}
                      >
                        <div className={styles.group}>
                          <div className={styles.subcategoryTitle}>
                            <span>{subcategory}</span>
                          </div>
                        </div>
                        <div className={styles.arrow}>
                          <Arrow />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
};
