import { FC, useEffect } from "react";

import { InfoHeading } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getFolders, foldersManageState } from "@/store/focus/foldersManage";

import { FocusTabs } from "../components";

import styles from "./Focus.module.scss";

export const Focus: FC = () => {
  const dispatch = useAppDispatch();
  const { list, isLoading } = useAppSelector(foldersManageState);

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <InfoHeading>Focus</InfoHeading>
      <div className={styles.foldersSection}>
        <div className={styles.inner}>
          <FocusTabs list={list} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};
