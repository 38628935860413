import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "@images/icons/arrow-2.svg";

import { Button } from "../Button/Button";
import { Loader } from "../Loader/Loader";

import styles from "./ActionNavigation.module.scss";

interface IActionNavigation {
  link: string;
  text?: string;
  loading?: boolean;
  hasAccess?: boolean;
}

export const ActionNavigation: FC<IActionNavigation> = ({
  link,
  text = "LEARN MORE",
  loading,
  hasAccess,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.btnWrapper}>
      {hasAccess && !loading ? (
        <Button
          text={text}
          IconComponent={ArrowIcon}
          type="action"
          onClick={() => navigate(link)}
          rightIcon
        />
      ) : null}
      {loading ? <Loader white /> : null}
    </div>
  );
};
