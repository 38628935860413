import dayjs from "dayjs";
import { FC, memo, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./DatePickerRange.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear.svg";
import { ReactComponent as ArrowRight } from "@images/date-arrow.svg";

type DateType = "start" | "end";

export interface DateObj {
  start: Date | null;
  end: Date | null;
}

interface IProps {
  onSelect: (dateRange: DateObj) => void;
  label?: string;
  isRequired?: boolean;
  dateFormat?: string;
  date?: { start: string | null; end: string | null };
  disabled?: boolean;
  MaxDateDisabled?: boolean;
}

const setNewDate = (date?: { start: string | null; end: string | null }) => {
  return {
    start: date?.start ? dayjs(date.start).toDate() : null,
    end: date?.end ? dayjs(date.end).toDate() : null,
  };
};

export const DatePickerRange: FC<IProps> = memo(
  ({
    onSelect,
    isRequired,
    label = "Select Date Range",
    dateFormat = "yyyy-MM-dd",
    date,
    disabled,
    MaxDateDisabled,
  }) => {
    const [localDate, setLocalDate] = useState<DateObj>({ start: null, end: null });

    const onChange = (type: DateType) => (newDate: Date | null) => {
      if (!date) {
        setLocalDate((prev) => ({ ...prev, [type]: newDate }));
      } else {
        onSelect({ ...setNewDate(date), [type]: newDate });
      }
    };

    useEffect(() => {
      if (date) return;
      if (localDate.start || localDate.end) {
        onSelect(localDate);
      }
    }, [date, localDate, onSelect]);

    const resetHandler = () => {
      if (!date) setLocalDate(setNewDate());
      onSelect(setNewDate());
    };

    const showReset = date?.start || date?.end;
    const startDate = date ? (date.start ? dayjs(date.start).toDate() : null) : localDate.start;
    const endDate = date ? (date.end ? dayjs(date.end).toDate() : null) : localDate.end;

    return (
      <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
        {label && <p className={`${styles.label} ${isRequired ? styles.required : ""}`}>{label}</p>}
        <div className={styles.wrapper}>
          <DatePicker
            selected={startDate}
            onChange={onChange("start")}
            selectsStart
            placeholderText="Start Date"
            dateFormat={dateFormat}
            showMonthDropdown
            showYearDropdown
            maxDate={MaxDateDisabled ? null : new Date()}
          />

          <div className={styles.arrowWrapper}>
            <ArrowRight width={22} />
          </div>

          <DatePicker
            selected={endDate}
            onChange={onChange("end")}
            selectsEnd
            minDate={startDate}
            placeholderText="End Date"
            dateFormat={dateFormat}
            showMonthDropdown
            showYearDropdown
            maxDate={MaxDateDisabled ? null : new Date()}
          />

          {showReset && (
            <div className={styles.clearWrapper} onClick={resetHandler}>
              <ClearIcon width={22} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
