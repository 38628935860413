import { FC, useEffect, useRef } from "react";

import { FooterNavigation } from "@/components";
import BgImage from "@/images/themes/background-theme.png";

import styles from "./About.module.scss";

import BannerImage from "@images/banner.jpeg";

export const About: FC = () => {
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${BgImage})` }}>
      <div className={styles.inner}>
        <h2 className={styles.title}>About</h2>
        <blockquote className={styles.info}>
          <p>
            OdinUltra is a cutting-edge no-code specialized financial AI platform for the ever more
            challenging and complex financial word. Oceans of disordered public data generated at an
            accelerating pace in today’s dynamically interconnected world. Extracting meaning from
            mass data without advanced technologies is humanly impossible. OdinUltra is an
            end-to-end solution designed to cater to a wide array of professionals in the asset
            management industry.
          </p>
          <p>
            OdinUltra is a battle-tested specialized partner that empowers users including
            discretionary, quantitative, multi-asset, and multi-strategy managers to harness the
            immense potential of big data and sophisticated investment techniques. Our innovative
            solutions offer actionable financial decisions to enable better investments through data
            driven research, custom portfolio building, testing, forward scenario planning, and
            forecasting company, industry, and economic fundamentals. From idea exploration and
            construction to verification and deployment, OdinUltra is an all-encompassing tool.
            Asset managers can enhance existing products and rapidly develop new ones, securing a
            competitive edge in the dynamic world of asset management.
          </p>
          <p>
            OdinUltra is a product of <a href="https://bigdatafed.com">Big Data Federation, Inc.</a>
            , which was founded in 2015 and headquartered in San Jose, California. We are a
            passionate team of high-caliber engineers, researchers, and data scientists.
          </p>
        </blockquote>

        <section className={styles.presentationSection}>
          <div className={styles.banner}>
            <img src={BannerImage} alt="banner" />
          </div>
        </section>

        <FooterNavigation fullWidth />
      </div>
    </div>
  );
};
