import { useMemo } from "react";

import { ICompareResponseData } from "@/types";
import { createAllocationsChartData, createAllocationsTableData } from "@/utils";
import { createCompareTableColumns } from "@/utils/compare";

export const useConvertAllocationData = (
  result: ICompareResponseData | null,
  isDataReady: boolean
) => {
  const chartData = useMemo(() => {
    return result ? createAllocationsChartData(result) : [];
  }, [result]);

  const tableData = useMemo(() => {
    return result ? createAllocationsTableData(result) : [];
  }, [result]);

  const columns = useMemo(() => {
    const columnNames = Object.keys(result || {});
    return createCompareTableColumns(columnNames);
  }, [result]);

  const isEmptyData = useMemo(() => result === null && isDataReady, [isDataReady, result]);

  return { tableData, chartData, columns, isEmptyData };
};
