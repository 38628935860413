import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { DateObj, ItemType } from "@/components";
import { DATE_ISO, ERRORS, directionList, indexTickerList, instrumentList } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useGetTickersListQuery } from "@/services/apiQuery/strategies";
import { setBasketTickersList } from "@/store/strategies/my_strategies";
import {
  fetchMetricsList,
  setBencmark,
  setDate,
  setDirection,
  setInstrument,
  setName,
  setTicker,
  setType,
  strategiesState,
} from "@/store/strategies/strategies";
import { TradingInstrumentType } from "@/types";
import { findDropdownItem, notification } from "@/utils";

export const useCommonForm = () => {
  const { newStartegyForm } = useAppSelector(strategiesState);

  const {
    tradingInstrument: { type, equityBaskets },
    ticker,
    tradingInstrument,
    benchmarkList,
    date,
    name,
    error,
    benchmark,
    direction,
  } = newStartegyForm;

  const { data: tickers, isLoading: isLoadingTickers } = useGetTickersListQuery();

  const dispatch = useAppDispatch();
  const isRequested = useRef(false);

  useEffect(() => {
    if (isLoadingTickers === false && tickers === undefined)
      notification.error(ERRORS.getDataError);
  }, [isLoadingTickers, tickers]);

  const tradingTickers = useMemo(() => {
    return {
      etfs: tickers?.etfs.map((item) => ({ key: item, value: item })) || [],
      equities: tickers?.equities.map((item) => ({ key: item, value: item })) || [],
      index: indexTickerList.map((item) => ({ key: item, value: item })),
      basket:
        [...new Set([...(tickers?.basket || [])])].map((item) => ({ key: item, value: item })) ||
        [],
      cryptos: tickers?.cryptos.map((item) => ({ key: item, value: item })) || [],
    };
  }, [tickers]);

  const selectDirectionHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setDirection(item.key));
  }, []);

  const radioChangeHandler = useCallback((label: string) => {
    dispatch(setType(label === "core" ? "core" : "overlay"));
  }, []);

  const selectDateHandler = useCallback((date: DateObj) => {
    dispatch(
      setDate({
        start: date.start ? dayjs(date.start).format(DATE_ISO) : null,
        end: date.end ? dayjs(date.end).format(DATE_ISO) : null,
      })
    );
  }, []);

  const changeNameHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    dispatch(setName(inputValue));
  };

  const selectBenchmarkHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setBencmark(item.key));
  }, []);

  const getData = (instrumentType: TradingInstrumentType | null) => {
    const merticList = equityBaskets.metricsList;
    if (instrumentType === "baskets" && !merticList) dispatch(fetchMetricsList());
  };

  const selectInstrumentHandler = useCallback(
    (name: string, item: ItemType<TradingInstrumentType>) => {
      const instrumentType = item.key;

      if (item.key === "baskets") {
        dispatch(setBasketTickersList(newStartegyForm.tradingInstrument.equityBaskets.tickers));
      } else {
        dispatch(setBasketTickersList(null));
      }

      if (instrumentType !== type) {
        dispatch(setInstrument(item.key));
        getData(instrumentType);
      }
    },
    [newStartegyForm]
  );

  const selectedInstrument = useMemo(() => {
    let activeItem: ItemType<TradingInstrumentType> | null = null;
    instrumentList.some((item) => {
      if (item.key === type) {
        activeItem = item;
        return true;
      }
      return false;
    });
    return activeItem;
  }, [type, instrumentList]);

  const selectTickerHandler = useCallback(
    (name: string, item: ItemType<string>) => {
      if (tradingTickers.equities.find((i) => i.value === item.value))
        dispatch(setTicker({ ticker: item.value, type: "equities" }));
      else if (tradingTickers.etfs.find((i) => i.value === item.value))
        dispatch(setTicker({ ticker: item.value, type: "etfs" }));
    },
    [tradingTickers.equities, tradingTickers.etfs]
  );

  const selectedTicker = useMemo(() => {
    const allList = [...tradingTickers.equities, ...tradingTickers.etfs];
    if (ticker.equities) return findDropdownItem(ticker.equities, allList);
    else if (ticker.etfs) return findDropdownItem(ticker.etfs, allList);
    return null;
  }, [ticker, tradingTickers]);

  const combinedTickersList = useMemo(() => {
    const { etfs, equities } = tradingTickers;
    return [...etfs, ...equities].sort((a, b) => a.value.localeCompare(b.value));
  }, [tradingTickers]);

  const selectedDirection = useMemo(
    () => directionList.find((item) => item.key === direction) || null,
    [direction, directionList]
  );

  const selectedBenchmark = useMemo(() => {
    const found = benchmarkList?.find((i) => i.ticker === benchmark);
    return found ? { key: `${found.id}`, value: found.name } : null;
  }, [benchmark, benchmarkList]);

  const benchmarkItemsList = useMemo(() => {
    return (
      benchmarkList?.map((item) => ({
        key: item.ticker,
        value: item.name,
      })) || []
    );
  }, [benchmarkList]);

  const disableOptions = !tradingInstrument;

  useEffect(() => {
    if (type && !isRequested.current) {
      getData(type);
      isRequested.current = true;
    }
  }, [type]);

  return {
    selectDirectionHandler,
    changeNameHandler,
    selectInstrumentHandler,
    selectedInstrument,
    selectDateHandler,
    radioChangeHandler,

    selectTickerHandler,
    selectedTicker,
    selectedDirection,
    disableOptions,
    isLoadingTickers,

    tradingInstrumentType: type,
    newStrategyFormError: error,
    newStartegyFormName: name,
    newStartegyFormDate: date,
    newStartegyFormType: newStartegyForm.type,
    tradingTickers,
    combinedTickersList,

    benchmarkList: benchmarkItemsList,
    selectBenchmarkHandler,
    selectedBenchmark,
  };
};
