import { IUserDataFilesResult, IUserDataItem, IUserFileItem, UserDataFileType } from "@/types";

const COLUMN_WIDTH = 180;

export const createFilesList = (data?: IUserDataFilesResult) => {
  const entries = Object.entries(data || {}) as [UserDataFileType, IUserFileItem[]][];
  const result = entries.reduce((acc, [type, items]) => {
    const newItems = items.map(({ name, created_at: created, id }) => ({
      id,
      name,
      type,
      created,
    }));
    acc.push(...newItems);
    return acc;
  }, [] as IUserDataItem[]);

  return result;
};

export const parseCSVData = (data?: string) => {
  if (data) {
    try {
      const rows = data.split("\n").filter((row) => row !== "");
      const cols =
        rows
          .at(0)
          ?.split(",")
          .map((i) => i.replace("\r", "")) || [];
      const restRows = rows.slice(1);

      const convertedData = restRows?.map((row) => {
        const rowData = cols.reduce((acc, column, idx) => {
          const values = row.split(",").map((i) => i.replace("\r", ""));
          acc[column] = values[idx];
          return acc;
        }, {} as Record<string, string>);

        return rowData;
      });
      return convertedData;
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const createColumns = (type: UserDataFileType | null, columnNames: string[]) => {
  if (type === "allocations") {
    const columns = [
      {
        Header: "Ticker",
        accessor: "ticker",
        key: "ticker",
        canSort: true,
        minWidth: COLUMN_WIDTH,
      },
    ];
    if (columnNames.includes("allocation")) {
      columns.push({
        Header: "Allocation",
        accessor: "allocation",
        key: "allocation",
        canSort: true,
        minWidth: COLUMN_WIDTH,
      });
    } else {
      columns.push({
        Header: "Share",
        accessor: "share",
        key: "share",
        canSort: true,
        minWidth: COLUMN_WIDTH,
      });
    }
    return columns;
  } else
    return columnNames.map((key, idx) => ({
      key,
      Header: key.charAt(0).toUpperCase() + key.slice(1),
      accessor: key,
      canSort: true,
      minWidth: COLUMN_WIDTH,
    }));
};
