import { FC, useState } from "react";

import styles from "./ButtonCollapse.module.scss";

interface IProps {
  text: string;
  children: React.ReactNode;
}

export const ButtonCollapse: FC<IProps> = ({ children, text }) => {
  const [toggle, setToggle] = useState(false);

  const toggleHandler = () => {
    setToggle((prev) => !prev);
  };
  return (
    <div className={`${styles.container} ${toggle ? styles.open : ""}`}>
      <div className={styles.btnContainer} onClick={toggleHandler}>
        <p className={styles.title}>{text}</p>
      </div>
      <div className={styles.collapsContainer}>
        <div className={styles.collapseInner}>{children}</div>
      </div>
    </div>
  );
};
