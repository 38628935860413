import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Loader } from "@/components";
import BgImage from "@/images/themes/background-theme.png";

import styles from "./Insights.module.scss";

const HOST = "https://www.bigdatafed.com";
// const HOST = "http://192.168.0.108:3000";

interface IPostMessageData {
  link?: string;
  cmd: "not-found" | "subscribe" | "same-page" | "article" | "update";
}

interface IProps {
  theme?: "white" | "black";
}

export const Insights: FC<IProps> = ({ theme }) => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useSearchParams();
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const messageHandlerFromIFrame = (event: any) => {
      if (event.origin !== HOST) {
        // something from another host
        return;
      }

      const { link, cmd } = JSON.parse(event.data || null) as IPostMessageData;

      if (cmd === "not-found") {
        setNotFound(true);
        return;
      }
      if (cmd === "subscribe") {
        navigate("/subscribe");
        return;
      } else if (cmd === "article" && link) {
        setSearch({ article: encodeURIComponent(link as string) });
      } else if (cmd === "update") {
        setSearch();
      }

      iFrameRef.current?.setAttribute("style", `opacity: 1;`);
      setLoading(false);
    };
    window.addEventListener("message", messageHandlerFromIFrame);
    return () => {
      window.removeEventListener("message", messageHandlerFromIFrame);
    };
  }, []);

  const article = useMemo(() => search.get("article"), []);

  const backgroundImage = theme === "white" ? "none" : `url(${BgImage})`;
  const contentTheme = theme === "white" ? `&theme=white` : "&theme=black";

  return (
    <div
      className={`${styles.container} ${theme === "white" ? styles.themWhite : ""}`}
      style={{ backgroundImage }}
    >
      <div className={styles.inner}>
        {loading && !notFound ? <Loader white={theme === "white" ? false : true} /> : null}
        {notFound ? (
          <div className={styles.notFound}>
            <p>Sorry, this page not found!</p>
          </div>
        ) : (
          <iframe
            src={`${HOST}/insights${
              article ? "/" + article + "" : ""
            }?headless=true${contentTheme}`}
            ref={iFrameRef}
            className={loading ? styles.loading : ""}
          ></iframe>
        )}
      </div>
    </div>
  );
};
