import { FC } from "react";

import Empty from "@/images/empty.svg";

import styles from "./NoData.module.scss";

interface IProps {
  text?: string;
}

export const NoData: FC<IProps> = ({ text }) => {
  return (
    <div className={styles.container}>
      <img src={Empty} alt="empty" />
      {text && <span>{text}</span>}
    </div>
  );
};
