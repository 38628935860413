import dayjs from "dayjs";

import { helpReplaceList, SideBarKeyEnum, TUTORIALS } from "@/constants";
import { IAdminPanelPages } from "@/types/adminPanel";
import {
  IChatMessage,
  IChatSerieData,
  IMessageExtendData,
  IMessageItem,
  IMessageUser,
  ISocketMessage,
  IVideoType,
} from "@/types/odinChat";

import { DATE_ISO } from "./../constants/date";

const STORAGE_SIZE = 20;
const STORAGE_HELPER_NAME = "odin-helper-list";

const hasLinks = (parsedObj: string | null): boolean => {
  return typeof parsedObj === "string"
    ? !!parsedObj.match(/(https:\/\/)|(http:\/\/)|(www\.)/gi)
    : false;
};

export const parseTextAsObject = (text: string): IMessageExtendData | null => {
  const obj: IMessageExtendData = {
    type: [],
    oneAxis: null,
    twoAxes: null,
    pie: null,
    table: null,
    bar: null,
    gauge: null,
    stackedBar: null,
    multyAxis: null,
    video: null,
    clock: null,
    linksText: null,
  };
  try {
    const parsedObj = JSON.parse(text);
    if (parsedObj?.type) {
      obj.type = parsedObj.type;
      if (parsedObj.type.includes("one_axis")) return { ...obj, oneAxis: parsedObj };
      if (parsedObj.type.includes("two_axis")) return { ...obj, twoAxes: parsedObj };
      if (parsedObj.type.includes("pie")) return { ...obj, pie: parsedObj };
      if (parsedObj.type.includes("table")) return { ...obj, table: parsedObj };
      if (parsedObj.type.includes("stacked_bar_chart")) return { ...obj, stackedBar: parsedObj };
      if (parsedObj.type.includes("bar_chart")) return { ...obj, bar: parsedObj };
      if (parsedObj.type.includes("two_axis_highcharts")) return { ...obj, twoAxes: parsedObj };
      if (parsedObj.type.includes("chart_data_forecast")) return { ...obj, twoAxes: parsedObj };
      if (parsedObj.type.includes("multi_axis")) return { ...obj, multyAxis: parsedObj };
      if (parsedObj.type.includes("gauge_chart")) return { ...obj, gauge: parsedObj };
      if (parsedObj.type.includes("video_content")) return { ...obj, video: parsedObj };
      if (parsedObj.type.includes("clock_chart")) return { ...obj, clock: parsedObj };
    }

    return null;
  } catch (e) {
    console.log("error parsing data...");
    if (text && hasLinks(text)) {
      obj.linksText = text;
      return obj;
    }
    return null;
  }
};

export const replaceWithEmoji = (text: string | null): string => {
  return (
    text?.replace(/:\w+:/gi, (match) => {
      const str = match.split(":");
      if (str.length === 3 && str[1] && !Number.isNaN(Number(str[1]))) return match;
      return `<em-emoji set="native" shortcodes="${match}" size="20" />`;
    }) || ""
  );
};

export const convertedWithUrlLinks = (text: string | null): [string, boolean] => {
  const urlRegex = /(http[s]?:\/\/[^\s]+)/g;
  const isURL = !!(text && urlRegex.test(text));

  // Check if the text contains a URL
  if (isURL) {
    const modifiedText = text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return [modifiedText, isURL];
  }
  return [text || "", isURL];
};

export const prepareMessagesList = (
  list: IChatMessage[] | null,
  uid: string,
  user: IMessageUser
): IMessageItem[][] => {
  let prevMessage: string | null = null;
  const preparedList =
    list?.map((msg) => {
      const { id, created_at, text, from_phone, status, is_read, is_new_user_message } = msg;
      const isOdin = from_phone === "CHATBOT";

      const paresedData = parseTextAsObject(text || "");
      const isSameUeserMessage = prevMessage === msg.from_phone;
      prevMessage = msg.from_phone;

      const calculateRequestTime = (time1: string, time2: string): string => {
        const requestTime = time1 ? Number(time1) : null;
        const responseTime = time2 ? Math.round(Number(time2) / 1_000_000) : null;
        const resultTime = requestTime && responseTime && responseTime - requestTime;

        return resultTime
          ? resultTime >= 1_000
            ? `${Math.round(resultTime / 1_000).toFixed(2)}s.`
            : `${resultTime}ms.`
          : "n/a";
      };

      const checkOnPingPong = (text: string): string => {
        const strArray = text.split(" ");

        const [modifiedText, isURL] = convertedWithUrlLinks(text);
        if (isURL) return modifiedText;

        // Rest of the function logic
        if (strArray.slice(0, 1)[0]?.toLowerCase() === "odinping" && strArray.length === 2)
          return `${strArray.slice(0, 1)[0]}`;
        if (strArray.slice(0, 1)[0]?.toLowerCase() === "pong" && strArray.length === 3)
          return `Ping reply time: ${calculateRequestTime(
            strArray.slice(1, 2)[0] as string,
            strArray.slice(2, 3)[0] as string
          )}`;

        return text;
      };

      const messageItem: IMessageItem = {
        id,
        uid,
        date: created_at,
        message: checkOnPingPong(text || ""),
        user: !isOdin ? user : undefined,
        isOdin,
        status,
        isReaded: is_read,
        isNewUserMessage: is_new_user_message,
        data: paresedData ? paresedData : undefined,
        isSameUeserMessage,
      };

      return messageItem;
    }) || [];

  const uniqueDates = [
    ...Array.from(
      new Map(
        preparedList.map((v) => {
          const date = dayjs(v.date).format(DATE_ISO);
          return [date, date];
        })
      ).values()
    ),
  ];

  const groupedByDateList = uniqueDates.reduce<IMessageItem[][]>((acc, uniqDate) => {
    const filtered = preparedList.filter((m) => dayjs(m.date).format(DATE_ISO) === uniqDate);
    if (filtered) acc.push(filtered);
    return acc;
  }, []);

  return groupedByDateList ? groupedByDateList : [];
};

export const prepareChartData = (data: IMessageExtendData, showData = true): IChatSerieData => {
  const chartData: IChatSerieData = {
    seriesData: [],
    type: "spline",
    title: null,
    ylabel: null,
  };

  if (!showData) return chartData;

  const getCategoryArray = (obj: Record<string, string[] | (number | null)[]>) => {
    return (
      (Object.entries(obj).find((i) => typeof i[1][0] === "string") as [string, string[]]) || []
    );
  };
  const getEntriesArray = (obj: Record<string, string[] | (number | null)[]>) => {
    return (
      (Object.entries(obj).filter((i) => typeof i[1][0] === "number") as [
        string,
        (number | null)[]
      ][]) || []
    );
  };
  const getValues = (obj: Record<string, string | (number | null)>, like: "number" | "string") => {
    if (like === "number")
      return (
        (Object.entries(obj)
          .filter((e) => typeof e[1] === "number")
          .map((e) => e[1]) as (number | null)[]) || []
      );
    if (like === "string")
      return (
        (Object.entries(obj)
          .filter((e) => typeof e[1] === "string")
          .map((e) => e[1]) as string[]) || []
      );
    return [];
  };

  const getName = (name: object | string[] | string | null): string => {
    if (Array.isArray(name)) return name[0];
    if (typeof name === "object") return "";
    if (typeof name === "string") return name;
    return "";
  };

  try {
    // one axes chart
    if (data.oneAxis) {
      chartData.title = getName(data.oneAxis.title);
      const dates = getCategoryArray(data.oneAxis.df)[1];
      const isDates = dayjs(dates[0]).isValid();
      const values = getEntriesArray(data.oneAxis.df)[0];

      if (isDates) {
        chartData.seriesData.push({
          type: "spline",
          name: getName(data.oneAxis.YName),
          data: dates.map((date, idx): [number, number | null] => [
            dayjs(date).valueOf(),
            values[1][idx],
          ]),
        });
      } else {
        chartData.categories = data.oneAxis.df.X || [];
        chartData.seriesData.push({
          type: "spline",
          name: getName(data.oneAxis.YName),
          data: values[1],
        });
      }
    }

    // two axes chart
    if (data.twoAxes && "X" in data.twoAxes.df && "X" in data.twoAxes.df2) {
      const dates = Array.from(new Set([...data.twoAxes.df.X, ...data.twoAxes.df2.X]));
      const isDates = dayjs(dates[0]).isValid();
      const names = [getName(data.twoAxes.YName1), getName(data.twoAxes.YName2)];
      const values = [data.twoAxes.df.Y1, data.twoAxes.df2.Y2] as (number | null)[][];
      chartData.title = getName(data.twoAxes.title);

      if (isDates) {
        chartData.seriesData = values.map((serieValues, sireIdx) => ({
          type: "spline",
          name: names[sireIdx],
          data: serieValues.map((value, idx): [number, number | null] => [
            dayjs(dates[idx]).valueOf(),
            value,
          ]),
        }));
      } else {
        chartData.categories = Array.from(
          new Set([...(data.twoAxes.df.X as string[]), ...data.twoAxes.df2.X] as string[])
        );
        chartData.seriesData = values.map((serieValues, sireIdx) => ({
          type: "spline",
          name: names[sireIdx],
          data: serieValues,
        }));
      }
    }

    // two_axes_forecast
    if (data.twoAxes && "Quarters" in data.twoAxes.df && Array.isArray(data.twoAxes.df2)) {
      chartData.chartType = "2_axes_type2";
      chartData.ylabel = ["Revenue"];
      chartData.title = getName(data.twoAxes.title);
      const len = data.twoAxes.df.Revenue.length;
      chartData.categories = Array.from(
        new Set([
          ...(Object.values(data.twoAxes.df)[0] as string[]),
          ...data.twoAxes.df2.map((i) => Object.values(i)[0] as string),
        ])
      );

      chartData.seriesData = [
        {
          type: "spline",
          name: getName(data.twoAxes.YName1),
          data: data.twoAxes.df.Revenue,
        },
        {
          type: "spline",
          dashStyle: "Dash",
          name: getName(data.twoAxes.YName2) || "Prediction " + getName(data.twoAxes.YName1),
          data: data.twoAxes.df2
            .map((item, idx) => ({
              x: idx + len - 2,
              y: Object.values(item)[1] as number | null,
            }))
            .slice(1),
        },
      ];
    }

    // two_axes_highcharts
    if (data.twoAxes && Array.isArray(data.twoAxes.df) && Array.isArray(data.twoAxes.df2)) {
      chartData.ylabel = [getName(data.twoAxes.YName1), getName(data.twoAxes.YName2)];
      chartData.title = getName(data.twoAxes.title);
      chartData.categories = Array.from(
        new Set([
          ...(data.twoAxes.df.map((i) => getValues(i, "string")).flat() as string[]),
          ...(data.twoAxes.df2.map((i) => getValues(i, "string")).flat() as string[]),
        ])
      );

      const name1 = Object.entries(data.twoAxes.df[0]).find((i) => typeof i[1] === "number");
      const name2 = Object.entries(data.twoAxes.df2[0]).find((i) => typeof i[1] === "number");
      chartData.seriesData = [
        {
          type: "spline",
          name: name1 && (name1[0] || "Type1"),
          data: data.twoAxes.df.map(
            (i) => Object.values(i).find((i) => typeof i === "number") || null
          ),
          yAxis: 0,
        },
        {
          type: "spline",
          name: name2 && (name2[0] || "Type2"),
          data: data.twoAxes.df2.map(
            (i) => Object.values(i).find((i) => typeof i === "number") || null
          ),
          yAxis: 1,
        },
      ];
    }

    // multy_axes
    if (data.multyAxis) {
      chartData.ylabel = [getName(data.multyAxis.YName)];
      chartData.title = getName(data.multyAxis.title);
      const categoryValues = getCategoryArray(data.multyAxis.df);
      const values = getEntriesArray(data.multyAxis.df);
      chartData.chartType = "multy_axes";
      chartData.XName = categoryValues[0];
      chartData.categories = categoryValues[1];

      chartData.seriesData = values.map((serieData, idx) => ({
        type: "spline",
        name: serieData[0],
        data: serieData[1],
      }));
    }

    // pie chart
    if (data.pie) {
      const values = data.pie.Values.flat() || [];
      chartData.type = "pie";
      chartData.title = getName(data.pie.Name);
      chartData.seriesData.push({
        name: data.pie.Name[0],
        type: "pie",
        data: data.pie.Labels.flat().map((label, idx) => ({
          name: Array.isArray(label) ? label[0] : label,
          y: values[idx],
        })),
      });
    }

    // stacked bar chart
    if (data.stackedBar) {
      const dates = data.stackedBar.df.Quarters || [];
      chartData.categories = dates as unknown as string[];
      chartData.type = data.stackedBar?.horizontal ? "bar" : "column";
      chartData.inPercentage = data.stackedBar.inPercentage;
      chartData.ylabel = [data.stackedBar.ylabel || ""];
      chartData.title = getName(data.stackedBar.title);
      chartData.seriesData = Object.entries(data.stackedBar.df)
        .filter((serieValues) => "Quarters" !== serieValues[0])
        .map((serieValues) => ({
          type: data.stackedBar?.horizontal ? "bar" : "column",
          name: serieValues[0],
          data: serieValues[1],
        }));
    }

    // bar chart
    if (data.bar) {
      chartData.categories = data.bar.labels as unknown as string[];
      chartData.type = data.bar.horizontal ? "bar" : "column";
      chartData.title = getName(data.bar.title);
      chartData.XName = typeof data.bar.XName !== "object" ? data.bar.XName : "";
      chartData.ylabel = [getName(data.bar.YName)];
      chartData.seriesData = [
        {
          type: data.bar.horizontal ? "bar" : "column",
          name: data.bar.title || "",
          data: data.bar.values,
        },
      ];
    }

    //gauge chart
    if (data.gauge) {
      chartData.title = data.gauge.title;
      chartData.gaugeData = {
        min: data.gauge.number_min || 0,
        max: data.gauge.number_max || 100,
        value: data.gauge.number || 0,
        unit: data.gauge.dataUnit || "",
        subtitle: data.gauge.subtitle || "",
        labelName: data.gauge.dataLabel || "Value",
      };
    }

    //clock chart
    if (data.clock) {
      chartData.title = data.clock.title;
      chartData.time = `${dayjs().format(DATE_ISO)} ${data.clock.time_str}`;
      chartData.ylabel = [data.clock.subtitle || ""];
    }
  } catch (e) {
    console.warn("Something happens during prepare chart data.", e);
  }

  return chartData;
};

class CommandHelper {
  name: string;
  itemIndex = 0;
  constructor(name: string) {
    this.name = name;
  }

  setItem(item: string) {
    try {
      const result = localStorage.getItem(this.name);
      if (result) {
        const itemsList: string[] = JSON.parse(result);
        itemsList.push(item);
        localStorage.setItem(this.name, JSON.stringify(itemsList.slice(-STORAGE_SIZE)));
        this.itemIndex = itemsList.length - 1;
      } else {
        localStorage.setItem(this.name, JSON.stringify([item]));
      }
    } catch {
      console.warn("Can not memorize command...");
    }
  }
  getPrevItem(): string | null {
    try {
      const result = localStorage.getItem(this.name);
      if (result) {
        const index = this.itemIndex;
        const list: string[] = JSON.parse(result);
        if (list.length > 0) {
          this.itemIndex = this.itemIndex - 1;
          if (this.itemIndex < 0) this.itemIndex = list.length - 1;
          return list[index];
        }
      }
      return null;
    } catch {
      return null;
    }
  }
  getNexItem(): string | null {
    try {
      const result = localStorage.getItem(this.name);
      if (result) {
        const index = this.itemIndex;
        const list: string[] = JSON.parse(result);
        if (list.length > 0) {
          this.itemIndex = this.itemIndex + 1;
          if (this.itemIndex >= list.length) this.itemIndex = list.length - 1;
          return list[index];
        }
      }
      return null;
    } catch {
      return null;
    }
  }
}

export const commandHelper = new CommandHelper(STORAGE_HELPER_NAME);

export const convertAsNewMessage = (newmMessage: ISocketMessage): IChatMessage => {
  const { created_at, id, to_phone, parent_id, text, hash, status, from_phone, is_read, type } =
    newmMessage;
  let date = created_at;
  if (created_at >= 10_000_000_000_000) {
    date = Math.floor(created_at / 1_000_000);
  }

  return {
    id,
    to_phone,
    parent_id: parent_id?.toString() || "",
    text,
    status,
    from_phone,
    is_read,
    type,
    created_at: dayjs(date).format(),
    hash,
  };
};

export const convertToChatMessages = (messages: IChatMessage[]): IChatMessage[] => {
  return Array.isArray(messages)
    ? messages.map((msg) => ({ ...msg, id: msg.msg_id ? msg.msg_id : msg.id }))
    : [];
};

export const createVideoHelpObj = (
  command: string,
  pages: IAdminPanelPages[] | null
): string[] | null => {
  const helpReplaceList = Object.values(SideBarKeyEnum).map((key) => {
    const tutorials = (pages || []).filter((page) => page.meta.attr === key || page.key === key);
    console.log(tutorials);

    const keyWithCapitalizeCaseAndSlashes = key
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return {
      message: [
        ...[SideBarKeyEnum.ODINCHAT === key ? "help" : ""],
        `help ${key.replace("-", " ").toLocaleLowerCase()}`,
      ],
      tutorials: tutorials.map((tutorial) => ({
        title: tutorial?.meta.videoTitle || keyWithCapitalizeCaseAndSlashes,
        url: tutorial?.video_url ?? TUTORIALS.default.url,
      })),
    };
  });
  const obj = helpReplaceList.find((help) => help.message.includes(command.trim().toLowerCase()));
  if (obj) {
    const videoObjList = obj.tutorials.map((tutorial) =>
      JSON.stringify({
        type: "video_content",
        title: tutorial.title,
        url: tutorial.url,
      })
    );
    // const videoObj: IVideoType = {
    //   type: "video_content",
    //   title: obj?.tutorial.title,
    //   url: obj?.tutorial.url,
    // };
    return videoObjList;
  }
  return null;
};

export const commandsInterceptor = (
  command: string,
  pages: IAdminPanelPages[] | null
): [string[] | null, string] => {
  let helpTextList: string[] | null = null;
  let newText = command;

  helpTextList = createVideoHelpObj(command, pages);

  if (command.trim().toLowerCase() === "odinping") newText = `odinping ${new Date().valueOf()}`;
  return [helpTextList, newText];
};
