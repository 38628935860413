import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { discoverService } from "@/services";
import { IFieldMeta, IResult, IQueryData } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IDiscoverQueryDataState {
  meta: IFieldMeta[];
  results: (string | number)[][] | IResult[];
  isLoading: boolean;
}

const initialState: IDiscoverQueryDataState = {
  meta: [],
  results: [],
  isLoading: false,
};

export const getQueryData = createAsyncThunk("discover/queryData", async (query: IQueryData) => {
  const response = await discoverService.fetchData(query);
  return response.data;
});

export const discoverQueryDataSlice = createSlice({
  name: "discoverQueryData",
  initialState,
  reducers: {
    resetSelected: (state) => {
      state.meta = initialState.meta;
      state.results = initialState.results;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQueryData.fulfilled, (state, action) => {
        state.meta = action.payload.meta;
        state.results = action.payload.results;
        state.isLoading = false;
      })
      .addCase(getQueryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQueryData.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const { resetSelected } = discoverQueryDataSlice.actions;

const state = (state: RootState) => state;

export const discoverQueryDataState = createSelector(state, (state) => state.discoverQueryData);

export default discoverQueryDataSlice.reducer;
