import { FC, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { InfoHeading, TabList } from "@/components";
import { TabContent } from "@/components/TabsList/TabContent";
import { AdminTabsEnum } from "@/constants/adminPanel";
import { ReactComponent as DocIcon } from "@/images/document.svg";
import { ReactComponent as UserApprovalsIcon } from "@/images/icons/user-approval.svg";
import { ReactComponent as TutorialIcon } from "@/images/tutorialIcon.svg";

import { Documents } from "./components/Documents/Documents";
import { PageInfo } from "./components/PageInfo/PageInfo";
import { UsersApproval } from "./components/UsersApproval/UsersApproval";

import styles from "./AdminPanel.module.scss";

const tabsList = [
  {
    key: AdminTabsEnum.TUTORIALS.toLowerCase(),
    name: AdminTabsEnum.TUTORIALS,
    icon: <TutorialIcon />,
  },
  {
    key: AdminTabsEnum.DOCUMENTS.toLowerCase(),
    name: AdminTabsEnum.DOCUMENTS,
    icon: <DocIcon />,
  },
  {
    key: AdminTabsEnum.USERS_APPROVAL.toLowerCase(),
    name: AdminTabsEnum.USERS_APPROVAL,
    icon: <UserApprovalsIcon />,
  },
];

export const AdminPanel: FC = () => {
  const [activeTab, setActiveTab] = useState<string | null>(() => AdminTabsEnum.TUTORIALS);
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    const targetTab = searchParams.get("target");
    const tab = tabsList.find((t) => t.key === targetTab);
    setActiveTab(tab?.name || AdminTabsEnum.TUTORIALS);
  }, []);

  const handleSelectTab = (tab: string) => {
    setActiveTab(tab);
    const targetTab = tabsList.find((t) => t.name === tab);
    setSearchParams({ target: targetTab?.key || "" });
  };

  return (
    <div className={styles.container}>
      <InfoHeading>Admin Panel</InfoHeading>
      <TabList tabsList={tabsList} active={activeTab} onSelect={handleSelectTab} />
      <TabContent id={AdminTabsEnum.TUTORIALS} activeTab={activeTab}>
        <PageInfo />
      </TabContent>
      <TabContent id={AdminTabsEnum.DOCUMENTS} activeTab={activeTab}>
        <Documents />
      </TabContent>
      <TabContent id={AdminTabsEnum.USERS_APPROVAL} activeTab={activeTab}>
        <UsersApproval />
      </TabContent>
    </div>
  );
};
