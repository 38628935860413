import dayjs from "dayjs";
import { FC, Fragment, useMemo } from "react";

import { Menu, OdinChart } from "@/components";
import { ClockChart } from "@/components/Chart/ClockChart";
import { GaugeChart } from "@/components/Chart/GaugeChart";
import { DATE_ISO } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import FolderFocusIcon from "@/images/folder-focus.svg";
import FolderIcon from "@/images/folder.svg";
import { ReactComponent as SaveIcon } from "@/images/save.svg";
import { addDataToFolder } from "@/store/focus/folderSelected";
import { foldersManageState } from "@/store/focus/foldersManage";
import { FolderTypeEnum, IFolderDataPayload } from "@/types";
import { IMessageExtendData } from "@/types/odinChat";
import { prepareChartData } from "@/utils";

import { useInViewportElement } from "../../hooks/useInViewportElemet";

import styles from "./DataChartObj.module.scss";

interface IDataChartObjProps {
  data: IMessageExtendData;
  messageId?: string;
}

export const DataChartObj: FC<IDataChartObjProps> = ({ data, messageId }) => {
  const [isIntersecting, itemRef] = useInViewportElement(false);

  const chartData = useMemo(() => prepareChartData(data, isIntersecting), [data, isIntersecting]);
  const { list, isLoading: isLoadingFolders } = useAppSelector(foldersManageState);
  const dispatch = useAppDispatch();

  const folders = list.map((folder) => ({
    icon: "FOCUS" === folder.name ? FolderFocusIcon : FolderIcon,
    title: folder.name,
    key: folder.id,
  }));

  const handleSelectedFolder = (key: string | number) => {
    if (!messageId) return;
    const payload: IFolderDataPayload = {
      command: messageId.toString(),
      type: FolderTypeEnum.ODIN_CHAT,
      description: "OdinChat",
    };
    dispatch(addDataToFolder({ id: key, data: payload }));
  };

  return (
    <div ref={itemRef} className={styles.chartWrapper}>
      {isIntersecting && (
        <Fragment>
          <div className={styles.head}>
            {chartData.title && <h2>{chartData.title}</h2>}
            {!chartData.time && (
              <Menu menuItems={folders} handelMenuSelect={handleSelectedFolder}>
                <div className={styles.menuInner}>
                  <div className={styles.icon}>
                    <SaveIcon />
                  </div>
                </div>
              </Menu>
            )}
          </div>
          {chartData.gaugeData ? (
            <GaugeChart gaugeData={chartData.gaugeData} title={chartData.title} />
          ) : chartData.time ? (
            <ClockChart time={chartData.time} subtitle={chartData.ylabel} />
          ) : (
            <OdinChart chartData={chartData} />
          )}
        </Fragment>
      )}
    </div>
  );
};
