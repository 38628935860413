import dayjs from "dayjs";

import { DATE_ISO_US } from "@/constants";
import { DEFAULT_AGG } from "@/constants/discover";
import { IDrs, IFieldMeta, IResult, IResultSeries, TIME_SERIES_TYPE } from "@/types";

export const drsGroupe = (drsList: IDrs[], groupBy: "category" | "subcategory") => {
  const drsGroupe = drsList.reduce((acc: Record<string, IDrs[]>, drs) => {
    const category = drs[groupBy].toLocaleLowerCase().includes("Unassigned")
      ? "miscellaneuos"
      : drs[groupBy];
    if (!acc[category]) {
      return { ...acc, ...{ [category]: [drs] } };
    }
    if (acc[category]) {
      acc[category].push(drs);
      return acc;
    }
    return acc;
  }, {});
  return drsGroupe;
};

export const formatValue = (value: string | number): string | number => {
  if (value === null) return "NULL";
  if (value === undefined || value === "undefined") return "-";
  if (typeof value === "number") {
    return value.toLocaleString("en-US", { maximumFractionDigits: 6 });
  }
  if (typeof value === "object") return JSON.stringify(value);
  return value;
};

const formatDimensions = (data: Record<string, string>): string => {
  let stringFormat = "";
  for (const key in data) {
    stringFormat += `<div style="font-size: 10px"><strong>${key}</strong> = <i>${data[key]}</i></div>\n`;
  }
  return stringFormat;
};

const convertDate = (data: Record<string, number>, dates: string[]): any[][] => {
  const dataMap = new Map<string, number>(Object.entries(data));
  const result = dates.map((date) => {
    const myDate = dayjs(date).format(DATE_ISO_US);
    return [myDate, dataMap.get(date) ?? null];
  });

  return result.reverse();
};

const getSeriesData = (
  results: IResult[] | (string | number)[][]
): Record<string, Record<string, number>> => {
  if (!results?.length) return {};
  return (results as IResult[])?.reduce((acc, item) => {
    const getLabel = `${item?.series_meta?.metric} ${formatDimensions(
      item.series_meta?.dimensions
    )}`;
    const format = item?.data?.reduce((obj: any, data: IResultSeries) => {
      obj = {
        ...obj,
        [data.date]: data.value,
      };
      return obj;
    }, {});
    acc = {
      ...acc,
      [getLabel]: format,
    };
    return acc;
  }, {});
};

export const getPrepSeriesData = (
  results: IResult[] | (string | number)[][],
  {
    type = "spline",
  }: {
    defaultSelected?: boolean;
    selectedList?: string[];
    type?: string;
  } = {}
): { data: any[]; dates: string[] } => {
  const initData: Record<string, Record<string, number>> = getSeriesData(results);

  const allDates = new Set<string>();
  Object.values(initData).forEach((seriesData) => {
    Object.keys(seriesData).forEach((date) => {
      allDates.add(date);
    });
  });
  const dates = Array.from(allDates).sort();

  const data = Object.entries(initData || {}).map(([seriesName, seriesData]) => {
    return {
      data: convertDate(seriesData, dates),
      name: seriesName,
      type,
    };
  });

  return {
    data,
    dates,
  };
};

export const getMetaConfig = (name: string, meta: IFieldMeta[] | undefined) => {
  if (!meta) return null;
  return meta.find((field) => field.name === name);
};

export const getOriginalName = (label: string, meta: IFieldMeta[] | undefined) => {
  if (!meta) return label;
  return meta.find((field) => field.label === label)?.original_name || label;
};

export const getLabel = (name: string, meta: IFieldMeta[] | undefined) => {
  if (!meta) return name;
  return meta.find((field) => field.name === name)?.label || name;
};

export const getExtraFields = (drsMetaFields: IFieldMeta[], regularDimensions: string[]) =>
  drsMetaFields.reduce((acc: any[], { name, label, type, primary_key }) => {
    if (!regularDimensions.includes(name) && !primary_key) {
      acc.push({
        name,
        label,
        type,
      });
      return acc;
    }
    return acc;
  }, []);
