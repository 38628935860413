import { DateObj, ItemType } from "@/components";
import { FILTER_OPERATORS } from "@/constants/discover";
import {
  IAggregators,
  IExtraDimansion,
  IFilter,
  IFilterCriteria,
  IGroup,
  IIncludeTruncateFilters,
} from "@/types";
import { getDateISOString } from "@/utils";

interface ExternalFilterCriteria {
  setFilterCriteria: (params: IFilterCriteria) => void;
  filterCriteria: IFilterCriteria | undefined;
  setCurrentPage: (current: number) => void;
}

interface InitData {
  isOneTimeDimension: boolean;
  timeDimension: string | undefined;
  dimensions: string[];
  extraDimensions: IExtraDimansion[];
  aggregators: IAggregators[];
  timeDimensions: string[];
  groups: IGroup[] | undefined;
  includeTruncateFilters: IIncludeTruncateFilters;
  selected: Record<string, ItemType<string>[]>;
  extraSelected: Record<string, ItemType<string>[]>;
  metricSelected: Record<string, IFilter>;
  dateRanges: Record<string, DateObj>;
  operation: Record<string, string>;
  extraOperation: Record<string, string>;
  metrics: string[];
}

export function useFiltering(
  initialData: InitData,
  { filterCriteria, setFilterCriteria, setCurrentPage }: ExternalFilterCriteria
) {
  const handleApply = () => {
    // Access other necessary data from initialData
    const {
      isOneTimeDimension,
      timeDimension,
      dimensions,
      extraDimensions,
      aggregators,
      timeDimensions,
      groups,
      includeTruncateFilters,
      selected,
      extraSelected,
      metricSelected,
      dateRanges,
      operation,
      extraOperation,
      metrics,
    } = initialData;

    const dimensionsSelected = Object.keys(selected).map((key) => ({
      field: key,
      operator: operation[key],
      value: selected[key].map((item) => item.value),
    }));

    const extraDimensionsSelected = Object.keys(extraSelected).map((key) => ({
      field: key,
      operator: extraOperation[key],
      value: extraSelected[key].map((item) => item.value),
    }));

    const metricsSelected = Object.keys(metricSelected).map((key) => {
      return metricSelected[key];
    });

    const dateRangeFilters = Object.keys(dateRanges).reduce((result: IFilter[], key) => {
      const range = dateRanges[key];
      if (range.start) {
        result.push({
          field: key,
          operator: FILTER_OPERATORS.gte.id,
          value: getDateISOString(range.start),
        });
      }
      if (range.end) {
        result.push({
          field: key,
          operator: FILTER_OPERATORS.lte.id,
          value: getDateISOString(range.end),
        });
      }
      return result;
    }, []);

    const metricFields = metrics.map((metric) => {
      const find = aggregators.find((agg) => agg.field === metric);
      if (find) {
        return find.label;
      }
      return metric;
    });

    if (isOneTimeDimension) {
      let mergedCriteria: IFilter[] = [];
      if (filterCriteria?.filter.filters?.length) {
        const filtred = filterCriteria.filter.filters.filter(
          ({ field }) => field === timeDimension
        );
        mergedCriteria = [
          ...filtred,
          ...dimensionsSelected,
          ...metricsSelected,
          ...extraDimensionsSelected,
        ];
      } else {
        mergedCriteria = [...dimensionsSelected, ...metricsSelected, ...extraDimensionsSelected];
      }

      const filter = {
        ...(mergedCriteria?.length >= 2 && {
          filter_operator: FILTER_OPERATORS.and.id,
        }),
        filters: mergedCriteria,
      };

      setFilterCriteria({
        filter,
        aggregators,
        fields: [
          ...dimensions,
          ...metricFields,
          ...extraDimensions.map((item) => item?.name),
          timeDimension || "",
        ],
        groups,
        includeTruncateFilters,
      });
      setCurrentPage(1);
      return;
    }

    const mergedCriteria = [
      ...dateRangeFilters,
      ...dimensionsSelected,
      ...metricsSelected,
      ...extraDimensionsSelected,
    ];

    const filter = {
      ...(mergedCriteria.length >= 2 && { filter_operator: FILTER_OPERATORS.and.id }),
      filters: mergedCriteria,
    };

    setFilterCriteria({
      filter,
      aggregators,
      fields: [
        ...timeDimensions,
        ...dimensions,
        ...metricFields,
        ...extraDimensions.map((item) => item?.name),
      ],
      groups,
      includeTruncateFilters,
    });
  };
  return {
    handleApply,
  };
}
