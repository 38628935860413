import { UserDataFileType } from "@/types";
import { IAdminPanelDocuments } from "@/types/adminPanel";

import { mainApi } from "./main";

export const publicDocuments = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentsList: builder.query({
      query: (arg?: any) => {
        return {
          url: "/admin/documents-public",
          method: "get",
          headers: {
            Authorization: "",
          },
        };
      },
      transformResponse: (response: { result: IAdminPanelDocuments[] | null }, meta, arg) =>
        response.result?.filter((docItem) => docItem.meta.is_public),
    }),
  }),
});

export const { useGetDocumentsListQuery } = publicDocuments;
