import { Fragment, useEffect } from "react";
import { useTable, useSortBy, Column, useExpanded, usePagination, HeaderGroup } from "react-table";

import { useFixedColumn } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ReactComponent as Arrow } from "@/images/arrow.svg";

import { SortArrows } from "./Table";

import styles from "./Table.module.scss";

export type ExtendedColumnsExpand = Array<
  Column<object> & {
    minWidth: number;
    isTruncated?: boolean;
    canExpand?: boolean;
  }
>;

interface IProps<T> {
  columns: ExtendedColumnsExpand;
  data: T[];
  scrollWidth?: number;
  onSelect?: (row: T) => void;
  expandable?: {
    expandedRowRender: (row: object) => JSX.Element;
    rowExpandable: (row: object) => boolean;
  };
  size?: "small" | "medium";
  currentPage?: number;
  perPage?: number;
  resetPage?: () => void;
  isLoading?: boolean;
  isNotClickable?: boolean;
}

export function TableExpand<T extends object>({
  columns,
  data,
  expandable,
  onSelect,
  size,
  currentPage,
  perPage,
  resetPage,
  isLoading,
  isNotClickable,
}: IProps<T>) {
  const { itemRef, showScroll, tableColumnsWidth } = useFixedColumn(columns);
  const isMobileView = useMediaQuery("(max-width: 575px)");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (currentPage) gotoPage(currentPage - 1);
  }, [currentPage, gotoPage]);

  useEffect(() => {
    if (perPage) setPageSize(perPage);
    else setPageSize(data.length);
  }, [perPage, data, setPageSize]);

  const handleMultiSortBy = (column: HeaderGroup<object>) => {
    const desc =
      column.isSortedDesc === true ? undefined : column.isSortedDesc === false ? true : false;

    if (resetPage) resetPage();
    if (desc === undefined) setSortBy([]);
    else setSortBy([{ id: column.id, desc }]);
  };

  return (
    <div className={styles.tableContainer} ref={itemRef}>
      {data.length > 0 && (
        <table
          {...getTableProps()}
          className={`${styles.table} ${
            size ? (size === "small" ? styles.small : styles.medium) : ""
          }`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`${styles.tableRow} ${isNotClickable ? styles.notClickable : ""}`}
                style={{ gridTemplateColumns: tableColumnsWidth }}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={`${styles.tableHeaderCell} ${showScroll ? styles.showScroll : ""}`}
                      onClick={() => handleMultiSortBy(column)}
                    >
                      <span className={styles.cellTextExpandTable}>{column.render("Header")}</span>
                      {column.canSort && (
                        <SortArrows
                          isSorting={column.isSorted}
                          isDescDir={column.isSortedDesc}
                          isSortable={column.canSort}
                        />
                      )}
                      <span className={styles.tableHeaderSeparator}></span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const isRowExpandable = expandable?.rowExpandable(row);
              return (
                <Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className={styles.tableRow}
                    style={{ gridTemplateColumns: tableColumnsWidth }}
                    onClick={() => (onSelect ? onSelect(row.original as T) : null)}
                  >
                    {row.cells.map((cell, idx) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => row.toggleRowExpanded()}
                          className={`${styles.tableRowCell} ${
                            showScroll ? styles.showScroll : ""
                          }`}
                        >
                          <div className={styles.tableRowWrappwer}>
                            <div className={styles.simpleCell}>
                              {columns[idx].isTruncated ? (
                                <div className={styles.truncated} title={cell.value}>
                                  <span>{cell.render("Cell")}</span>
                                </div>
                              ) : (
                                <div className={styles.simpleCell}>{cell.render("Cell")}</div>
                              )}
                            </div>
                            {idx === 0 && isRowExpandable && (
                              <span className={styles.arrow}>
                                <Arrow
                                  className={`${
                                    row.isExpanded ? styles.arrowUp : styles.arrowDown
                                  }`}
                                />
                              </span>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr
                    className={`${
                      isRowExpandable && row.isExpanded ? styles.expand : styles.expandHide
                    }`}
                  >
                    <td colSpan={columns.length}>{expandable?.expandedRowRender(row.original)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      {data.length === 0 && !isLoading && (
        <div className={styles.emptyData}>Data not available yet.</div>
      )}
    </div>
  );
}
