import { FC, ReactNode } from "react";

import styles from "../../FocusTabs.module.scss";

interface IProps {
  id: string | number | undefined;
  activeTab: string | number | undefined;
  children: ReactNode;
}

export const TabContent: FC<IProps> = ({ id, activeTab, children }) => {
  return activeTab === id ? <div className={styles.tabContent}>{children}</div> : null;
};
