import dayjs from "dayjs";
import Highcharts, { AxisCrosshairOptions, SeriesOptionsType } from "highcharts/highstock";
import { useMemo } from "react";

import {
  AXIS_MAIN_COLOR,
  AXIS_PREVIEW_COLOR,
  CHART_COLORS_SET_2,
  DATE_ISO,
  DATE_ISO_US,
  DATE_ISO_US_SHORT,
} from "@/constants";
import { ISeries } from "@/types";
import { IYoYData } from "@/types/forecast";

import { FLOAT_DIGITS, getExtraPointData, exportingOptions } from "../utils";

import styles from "../Chart.module.scss";

export interface IConfig {
  ticker?: string;
  data?: ISeries[];
  isQuarters?: boolean;
  quarters?: Record<string, string> | null;
  forecastKey?: string;
  hasEndDate?: boolean;
  endQuartersDate?: Record<string, string | null>;
  isChartPreview?: boolean;
  name?: string;
  yoyData: IYoYData | null;
  deltaData: IYoYData | null;
}

const PREDICTION_COLOR = "#008DDD";

export const useEquitiesChartOptions = (config: IConfig) => {
  const {
    isChartPreview,
    forecastKey,
    name,
    ticker,
    data,
    quarters,
    isQuarters,
    hasEndDate,
    endQuartersDate,
    yoyData,
    deltaData,
  } = config;

  const crossChair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };

  const createChartOptions = (config: IConfig) => {
    const { isChartPreview, forecastKey, ticker, data, isQuarters, hasEndDate } = config;

    const lineColor = isChartPreview ? AXIS_PREVIEW_COLOR : AXIS_MAIN_COLOR;
    const createForecastZone = (len: number) => {
      return forecastKey
        ? {
            zoneAxis: "x",
            zones: [
              { value: len - 2, dashStyle: "Solid" },
              { dashStyle: "Dash", color: PREDICTION_COLOR },
            ],
          }
        : {};
    };

    const getSireType = (sireType?: string) => {
      if (sireType === "bar") return "bar";
      if (sireType === "column") return "column";
      return "spline";
    };

    const createChartData = (data?: ISeries[]): SeriesOptionsType[] => {
      try {
        return (
          (data?.map((serie, idx) => ({
            ...serie,
            data: serie.data.length ? [...serie.data].reverse() : [],
            color:
              serie.name.toLocaleLowerCase() === "consensus"
                ? CHART_COLORS_SET_2[7]
                : CHART_COLORS_SET_2[idx],
            type: getSireType(serie.type),
            showInLegend: data.length !== 1,
            negativeColor: ["bar", "column"].includes(serie.type || "") ? "#AB0317" : "",
            ...createForecastZone(serie.data.length),
          })) as SeriesOptionsType[]) || []
        );
      } catch (e) {
        if (process.env.NODE_ENV !== "production")
          console.error("Error during calculate sires...", e);
        return [];
      }
    };

    const categories = () => {
      const categoriesList = data?.map((serie) => serie.data.map((item) => item[0])).flat() || [];
      const result = ([...new Set(categoriesList)] as string[])
        .map((d) => dayjs(d).valueOf())
        .sort((a, b) => a - b)
        .map((d) => dayjs(d).format(DATE_ISO_US));

      return result;
    };

    const createWithForecastData = (data?: ISeries[]): SeriesOptionsType[] => {
      try {
        const forecastData =
          data?.reduce((acc, serie) => {
            if (serie.name === forecastKey) {
              const lastForecastDate = data[1].data.slice(-1)[0].at(0);
              const lastDate = data[0].data.slice(-1)[0].at(0);
              const lastForecastItem = data[1].data.slice(-1);
              const restData =
                lastForecastDate !== lastDate ? data[0].data : data[0].data.slice(0, -1);

              acc[0].data = [...restData, ...lastForecastItem];
            } else acc.push(serie);
            return acc;
          }, [] as ISeries[]) || [];

        return createChartData(forecastData);
      } catch (e) {
        if (process.env.NODE_ENV !== "production")
          console.error("Error during calculate sires...", e);
        return [];
      }
    };

    const options: Highcharts.Options = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      chart: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        zoomType: "x",
        type: "spline",
        style: {
          fontFamily: `'Inter', 'Arial', sans-serif`,
        },
        width: undefined,
        marginTop: isChartPreview ? 15 : 35,
      },
      legend: {
        enabled: isChartPreview ? false : true,
        useHTML: true,
      },
      exporting: {
        enabled: false,
      },
      colors: CHART_COLORS_SET_2,

      yAxis: {
        title: {
          text: ticker || "Revenue ($B)",
          margin: isChartPreview ? 0 : undefined,
          x: isChartPreview ? -10 : 0,
        },
        lineWidth: 1,
        gridLineWidth: 0,
        crosshair: crossChair,
        labels: {
          enabled: isChartPreview ? false : true,
        },
        lineColor,
      },
      scrollbar: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            radius: isChartPreview ? 2.5 : 3,
          },
        },
      },
      xAxis: {
        title: {
          text: "",
        },
        type: "category",
        labels: {
          formatter(): string {
            const date = dayjs(this.value);
            return isQuarters
              ? hasEndDate
                ? date.format(DATE_ISO_US_SHORT)
                : `F${date.format("Q")}Q${date.format("YY")}`
              : date.format(DATE_ISO);
          },
          enabled: isChartPreview ? false : true,
        },
        crosshair: crossChair,
        categories: categories(),
        lineColor,
      },
      series: forecastKey ? createWithForecastData(data) : createChartData(data),
    };

    return options;
  };

  const chartOptions = useMemo(() => {
    const options = createChartOptions(config);

    options.tooltip = {
      enabled: true,
      shared: true,
      useHTML: true,
      valueSuffix: "",
      valuePrefix: "",
      formatter(): any {
        const dateFormatter = (date?: string | number) => {
          const d = dayjs(date);
          const dateKey = dayjs(date).format("YYYY-MM-DD");
          let formattedDate = "";

          if (isQuarters && quarters && typeof date === "string") {
            const dateString = quarters[dateKey];
            formattedDate = `${dateString ? dateString + " / " + date : date}`;
          } else {
            formattedDate = isQuarters
              ? `F${d.format("Q")}Q${d.format("YY")}`
              : d.format(DATE_ISO_US);
          }

          const dateToQuarters = (dateValue: string | null) => {
            const dateExp = /(^F[1-4]Q\d\d$)/;
            const isValidDate = dayjs(dateValue).isValid();

            if (dateExp.test(dateValue || "")) {
              return `${dateValue} / ${d.format(DATE_ISO_US_SHORT)}`;
            } else if (isValidDate)
              return `F${d.format("Q")}Q${d.format("YY")} / ${dayjs(dateValue).format(
                DATE_ISO_US_SHORT
              )}`;

            return dateValue;
          };

          return hasEndDate && endQuartersDate && date && endQuartersDate[dateKey]
            ? dateToQuarters(endQuartersDate[dateKey])
            : formattedDate;
        };

        const inner = this.points?.reduce(
          (str, point, idx) => {
            const { extraValue, paramName } = getExtraPointData(
              { delta: deltaData, yoy: yoyData },
              point.series.name,
              point.x
            );

            const value = extraValue
              ? `<span class="${styles.yoyLabel}"> / ${paramName}: <span>${extraValue.toFixed(
                  FLOAT_DIGITS
                )}</span>%</span>`
              : "";

            return `${str}
          <p class="${styles.chartTooltip}" style="margin-top: ${idx > 0 ? 0 : 8}px;">
            <span style="color: ${point.series.color};">${point.series.name}: </span>
            <span>${point.y?.toFixed(FLOAT_DIGITS)}</span>
            ${value}
          </p>`;
          },
          `
        <p class="${styles.chartTooltip}">
          ${dateFormatter(this.points?.[0]?.key)}
        </p>`
        );

        return `<div class="${styles.tooltipContainer}">${inner}</div>`;
      },
    };

    options.exporting = {
      enabled: isChartPreview ? false : true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "separator",
            "downloadCSV",
          ],
        },
      },
      ...exportingOptions(name),
    };

    return options;
  }, [
    name,
    data,
    ticker,
    isQuarters,
    quarters,
    hasEndDate,
    forecastKey,
    yoyData,
    deltaData,
    endQuartersDate,
    isChartPreview,
  ]);

  return {
    chartOptions,
    createChartOptions,
  };
};
