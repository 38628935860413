import { useEffect, useState } from "react";

type EventType = "hidden" | "webkitHidden" | "mozHidden" | "msHidden";
type BrowserEventType =
  | "visibilitychange"
  | "webkitvisibilitychange"
  | "mozvisibilitychange"
  | "msvisibilitychange";
type StateKeyType = Record<EventType, BrowserEventType>;

const stateKyes: StateKeyType = {
  hidden: "visibilitychange",
  webkitHidden: "webkitvisibilitychange",
  mozHidden: "mozvisibilitychange",
  msHidden: "msvisibilitychange",
};

export const useActiveWindowTab = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let eventKey: BrowserEventType | null = null;
    const onChange = () => {
      setIsActive(document.visibilityState === "visible");
    };

    for (const key in stateKyes) {
      if (key in document) {
        eventKey = stateKyes[key as EventType];
        document.addEventListener(eventKey, onChange);
        break;
      }
    }

    return () => {
      if (eventKey) document.removeEventListener(eventKey, onChange);
    };
  }, []);

  return [isActive];
};
