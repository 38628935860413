import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { MarketServices } from "@/services/marketServices";
import { IClosedMarket, IDaysRange } from "@/types";
import { IForecastMacroItem } from "@/types/forecast";
import { createBubbleCard, MARKET_PRICE_KEY } from "@/utils";
import { notification } from "@/utils/notification";

import { ForecastService } from "../../services/forecastService";
import { RootState } from "../index";

interface IMacroState {
  data: IForecastMacroItem[];
  isLoading: boolean;
  isFetchingData: boolean;
  marketPriceData: IClosedMarket | null;
  marketPriceRangeData: IClosedMarket | null;
}

const initialState: IMacroState = {
  data: [],
  isLoading: false,
  marketPriceData: null,
  isFetchingData: false,
  marketPriceRangeData: null,
};

export const getMacroBubbles = createAsyncThunk("macro/getMacroBubbles", async () => {
  const response = await ForecastService.getMacroBubbles();
  const marketResponse = await MarketServices.postMarketData(MARKET_PRICE_KEY, null);

  return {
    bubbles: response.data.result,
    marketPrice: marketResponse.data.result as IClosedMarket,
  };
});

export const refreshMarketPriceData = createAsyncThunk(
  "macro/refreshMarketPrice",
  async (range: IDaysRange | null) => {
    const { data } = await MarketServices.postMarketData(MARKET_PRICE_KEY, range);
    return data.result as IClosedMarket;
  }
);

const macroSlice = createSlice({
  name: "forecast/macro",
  initialState,
  reducers: {
    restMarketRangeData: (state) => {
      state.marketPriceRangeData = null;
    },
    resetData: (state) => {
      state.data = [];
      state.marketPriceRangeData = null;
      state.marketPriceData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMacroBubbles.fulfilled, (state, { payload }) => {
        const { bubbles, marketPrice } = payload;
        state.marketPriceData = marketPrice;
        state.data = bubbles.filter((i) => !["gdp_growth_real"].includes(i.indicator));
        state.isLoading = false;
        // removing SP500 Index card
        // const bubbleMarket = createBubbleCard(marketPrice);
        // state.data.splice(1, 0, bubbleMarket);
      })
      .addCase(getMacroBubbles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMacroBubbles.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(refreshMarketPriceData.pending, (state) => {
        state.isFetchingData = true;
      })
      .addCase(refreshMarketPriceData.fulfilled, (state, { payload }) => {
        state.marketPriceRangeData = payload;
        state.isFetchingData = false;
      })
      .addCase(refreshMarketPriceData.rejected, (state) => {
        state.isFetchingData = false;
      });
  },
});

const state = (state: RootState) => state;

export const macroState = createSelector(state, (state) => state.forecastMacro);

export const { restMarketRangeData, resetData } = macroSlice.actions;

export default macroSlice.reducer;
