import { AxiosResponse } from "axios";

import { IDrs, IDrsSerachResult, IQueryData, IResponeData } from "@/types";

import axios, { $instansCommonApiAxios } from "./axios.config";

export const discoverService = {
  fetchDRS: (): Promise<AxiosResponse<IDrs[]>> => $instansCommonApiAxios.get("/drs"),
  searchDRS: (
    searchTerm: string,
    drsId?: number,
    granularity?: string
  ): Promise<AxiosResponse<IDrsSerachResult[]>> =>
    $instansCommonApiAxios.get(
      `/search?q=${searchTerm}${drsId != null ? `&drs_id=${drsId}` : ""}${
        granularity ? `&periodicity=${granularity}` : ""
      }`
    ),
  fetchDRSByTag: (tag: number, granularity: string): Promise<AxiosResponse<any>> =>
    $instansCommonApiAxios.post(`/time-series/group-by-drs`, {
      tag_id: tag,
      granularity,
    }),
  fetchDRSByTicker: (ticker: string, granularity: string): Promise<AxiosResponse<any>> =>
    $instansCommonApiAxios.post(`/time-series/group-by-drs`, { ticker, granularity }),
  fetchDRSFiltersByTag: (drsId: number, tag: number, granularity: string) =>
    $instansCommonApiAxios.post(`/time-series/drs/${drsId}`, {
      tag_id: tag,
      granularity,
    }),
  fetchDRSFiltersByTicker: (
    drsId: number,
    ticker: string,
    granularity: string
  ): Promise<AxiosResponse<any>> =>
    $instansCommonApiAxios.post(`/time-series/drs/${drsId}`, { ticker, granularity }),
  fetchMeta: (drsId: number, tableName: string) =>
    $instansCommonApiAxios.get(`/meta/${drsId}/${tableName}`, {
      headers: {
        "sender-type": "Pulse",
      },
    }),
  fetchDrsDetails: (drsId: number) =>
    $instansCommonApiAxios.get(`/drs/${drsId}`, {
      headers: {
        "sender-type": "Pulse",
      },
    }),

  fetchData: (query: IQueryData): Promise<AxiosResponse<IResponeData>> =>
    $instansCommonApiAxios.post(`/query`, query, {
      headers: {
        "sender-type": "Pulse",
      },
    }),
  exportToCommonAPI: (query: string) =>
    $instansCommonApiAxios.post(`/r/query_to_r`, query, {
      headers: {
        "sender-type": "Pulse",
      },
    }),
  getSupportedDataSources: (): Promise<AxiosResponse<{ result: number[] }>> =>
    axios.get("/data-sources"),
};
