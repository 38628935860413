import { useEffect, useMemo, useRef, useState } from "react";
import { Column } from "react-table";

import { debounce } from "@/utils";

export type ExtendedColumns = Array<Column<object> & { minWidth: number; isTruncated?: boolean }>;

export const useFixedColumn = (columns: ExtendedColumns) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const memoRef = useRef<{ isShowScroll: boolean | null }>({ isShowScroll: null });
  const [showScroll, setShowScroll] = useState(false);

  const scrollWidth = useMemo(() => {
    return columns.reduce((width, col) => width + col.minWidth, 0);
  }, [columns]);

  useEffect(() => {
    const onScroll = debounce(() => {
      if (
        scrollWidth &&
        itemRef.current &&
        itemRef.current.clientWidth <= scrollWidth &&
        !memoRef.current.isShowScroll
      ) {
        memoRef.current.isShowScroll = true;
        setShowScroll(true);
      }
      if (
        scrollWidth &&
        itemRef.current &&
        itemRef.current.clientWidth > scrollWidth &&
        (memoRef.current.isShowScroll || memoRef.current.isShowScroll === null)
      ) {
        memoRef.current.isShowScroll = false;
        setShowScroll(false);
      }
    }, 100);
    if (itemRef.current) {
      window.addEventListener("resize", onScroll);
    }

    onScroll();

    return () => {
      window.removeEventListener("resize", onScroll);
    };
  }, [scrollWidth]);

  const tableColumnsWidth = useMemo(() => {
    const totalCellsWidth = columns.reduce(
      (style, col) =>
        style +
        ` minmax(${col.minWidth}px, ${(col.minWidth / (scrollWidth || col.minWidth)) * 100}%)`,
      ""
    );
    return totalCellsWidth;
  }, [columns]);

  return { itemRef, showScroll, tableColumnsWidth };
};
