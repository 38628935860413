/* eslint-disable @typescript-eslint/ban-ts-comment */
import Highcharts, { AxisCrosshairOptions, SeriesOptionsType } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, memo, useEffect, useRef, useState } from "react";

import { AXIS_MAIN_COLOR, AXIS_PREVIEW_COLOR, COLORS } from "@/constants";
import { ISeries } from "@/types";
import { IPlotBands, IYoYData } from "@/types/forecast";

import { exportingOptions, extraTooltipFormatter } from "./utils";
import { Button } from "../Button/Button";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

interface LineChartProps {
  title?: string | null;
  data?: ISeries[];
  plotBands?: IPlotBands[];
  height?: number;
  isChartPreviw?: boolean;
  yoyData?: IYoYData;
  deltaData?: IYoYData;
}

Highcharts.Chart.prototype.showResetZoom = function showResetButton() {
  //
};

export const ChartForecast: FC<LineChartProps> = memo(
  ({ title, data, plotBands, height = 500, isChartPreviw, yoyData, deltaData }) => {
    const chartComponent = useRef<HighchartsReact.RefObject | null>(null);
    const [isZoomed, setIsZoomed] = useState(false);

    const crossHair: AxisCrosshairOptions = {
      width: 1,
      dashStyle: "Dot",
      color: "#bababa",
    };
    const lineColor = isChartPreviw ? AXIS_PREVIEW_COLOR : AXIS_MAIN_COLOR;
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueSuffix: "",
        valuePrefix: "",
        useHTML: true,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            radius: isChartPreviw ? 2.5 : 3,
          },
        },
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
        selected: 5,
      },
      chart: {
        //@ts-ignore
        zoomType: "x",
        width: undefined,
        events: {
          selection(e) {
            if (e.resetSelection) {
              setIsZoomed(false);
            } else {
              setIsZoomed(true);
            }
            return true;
          },
        },
        type: "spline",
        marginTop: isChartPreviw ? 5 : 35,
        style: {
          fontFamily: "Inter",
        },
      },
      legend: {
        enabled: isChartPreviw ? false : true,
      },
      exporting: {
        enabled: isChartPreviw ? false : true,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "separator",
              "downloadCSV",
            ],
          },
        },
        ...exportingOptions(),
      },
      colors: COLORS,

      yAxis: {
        title: {
          text: title || "",
          margin: isChartPreviw ? 0 : undefined,
          x: isChartPreviw ? -10 : 0,
        },
        gridLineWidth: 0,
        lineWidth: 1,
        crosshair: crossHair,
        labels: {
          enabled: isChartPreviw ? false : true,
        },
        lineColor,
      },
      scrollbar: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: "",
        },
        crosshair: crossHair,
        labels: {
          enabled: isChartPreviw ? false : true,
        },
        lineColor,
        type: "category",
      },
    });

    const resetZoom = () => {
      if (chartComponent && chartComponent.current) {
        chartComponent.current.chart.zoomOut();
      }
    };

    useEffect(() => {
      try {
        setChartOptions((state) => ({
          ...state,
          series: data as SeriesOptionsType[],
          yAxis: {
            title: {
              text: title || "",
            },
          },
          xAxis: {
            title: {
              text: "",
            },
            type: "category",
            plotBands,
          },
          tooltip: {
            ...state.tooltip,
            formatter: extraTooltipFormatter({
              styles,
              extraData: { yoy: yoyData || null, delta: deltaData || null },
            }),
          },
        }));
      } catch (e) {
        console.warn("Some data are corrupted...");
      }
    }, [data, plotBands, title, yoyData, deltaData]);

    return (
      <div className={styles.chartContainer}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height } }}
        />
        <div className={styles.zoomButton}>
          {isZoomed && (
            <Button text="Reset Zoom" type="secondary" onClick={resetZoom} size="small" />
          )}
        </div>
        <div className={`${styles.logo} ${isChartPreviw ? styles.leftAligned : ""}`}>
          <OdinLogoIcon />
        </div>
      </div>
    );
  }
);
