import Highcharts from "highcharts/highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { FC, useMemo } from "react";

import { CHART_COLORS } from "@/constants";
import { IGaugeChartData } from "@/types/odinChat";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

more(Highcharts);

const GAUGE_COLORS_RANGE = ["#AB0317", "#D87B78", "#FAD978", "#C6E17E", "#9ECB3F"];

interface IGaugeChartProps {
  title: string | null;
  gaugeData: IGaugeChartData;
  height?: number;
}

export const GaugeChart: FC<IGaugeChartProps> = ({ gaugeData, height = 350 }) => {
  const createGaugeSerie = (): [Highcharts.SeriesGaugeOptions] => {
    return [
      {
        type: "gauge",
        name: gaugeData.labelName || "Value",
        data: [gaugeData.value || 0],
        tooltip: {
          valueSuffix: ``,
        },
        dataLabels: {
          format: gaugeData.labelName,
          borderWidth: 0,
          style: {
            fontSize: "16px",
            fontWeight: "600",
          },
        },
        dial: {
          radius: "80%",
          backgroundColor: "gray",
          baseWidth: 12,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "gray",
          radius: 6,
        },
      },
    ];
  };

  const createGaugeYaxis = (): Highcharts.YAxisOptions => {
    const { min, max } = gaugeData;
    const duration = Math.abs(max || 0) + Math.abs(min || 0) || 0;
    const createColorBands = () => {
      let offset = 0;
      const shift = duration / GAUGE_COLORS_RANGE.length;
      const bands = GAUGE_COLORS_RANGE.map((color) => {
        const diff = offset;
        offset = offset + shift;
        return {
          from: (min || 0) + diff,
          to: (min || 0) + diff + shift,
          color,
          thickness: 20,
        };
      });
      return bands;
    };

    return {
      min,
      max,
      tickPixelInterval: 150,
      tickPosition: "inside",
      tickColor: "#FFFFFF",
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: "14px",
        },
      },
      lineWidth: 0,
      plotBands: createColorBands(),
    };
  };

  const chartOptions = useMemo(() => {
    const options: Highcharts.Options = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "gauge",
        marginTop: 35,
        style: {
          fontFamily: "Inter",
        },
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      colors: CHART_COLORS,
      yAxis: createGaugeYaxis(),
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: [
          {
            backgroundColor: "transparent",
            borderColor: "transparent",
          },
        ],
        center: ["50%", "75%"],
        size: "110%",
      },
      series: createGaugeSerie(),
    };
    return options;
  }, [gaugeData]);

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height } }}
      />
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
