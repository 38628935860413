import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { IChartData } from "@/types";

import { RootState } from "..";

interface IInitialState {
  data: IChartData | null;
}

const initialState: IInitialState = {
  data: null,
};

export const foldersDataSlice = createSlice({
  name: "focusFoldersData",
  initialState,
  reducers: {
    setFolderData: (state, { payload }: PayloadAction<any | null>) => {
      state.data = payload;
    },
    resetFolderData: (state) => {
      state.data = initialState.data;
    },
  },
});

const root = (state: RootState) => state;

export const foldersDataState = createSelector(root, (state) => state.foldersData);

export const { setFolderData, resetFolderData } = foldersDataSlice.actions;

export default foldersDataSlice.reducer;
