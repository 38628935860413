import { FC, useMemo, useState } from "react";
import { useDrag } from "react-dnd";

import { Menu } from "@/components";
import { useAppDispatch } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Copy from "@/images/copy.svg";
import { ReactComponent as DragIcon } from "@/images/drag-item.svg";
import { ReactComponent as Eye } from "@/images/eye.svg";
import { ReactComponent as Dots } from "@/images/menu-dots.svg";
import Move from "@/images/move.svg";
import Nest from "@/images/nest.svg";
import Update from "@/images/update.svg";
import { fetchUserMessageFocus, setIdToUpdate, setRequested } from "@/store/focus/updateOdinCharts";
import { FolderTypeEnum, IChartReturnData, IFolder, IFolderData } from "@/types";
import { IChatMessage } from "@/types/odinChat";
import { notification } from "@/utils";

import { DiscoverChart } from "../Charts/DiscoverChart";
import { ForecastChart } from "../Charts/ForecastChart";
import { MacroChart } from "../Charts/MacroChart";
import { OdinChatCharts } from "../Charts/OdinChatCharts";
import { MoveToFolderModal } from "../MoveToFolderModal/MoveToFolderModal";

import styles from "./FolderItem.module.scss";

export const GetChartByType = (
  type: FolderTypeEnum,
  data: IChatMessage | Record<string, Record<string, number>> | undefined,
  command: string,
  param: {
    title: string | null;
    description: string | null;
  }
): JSX.Element | null => {
  if (!data) return null;
  if (FolderTypeEnum.FORECAST_EQUITIES === type) {
    return <ForecastChart chartData={data} command={command} />;
  }
  if (FolderTypeEnum.ODIN_CHAT === type) {
    return <OdinChatCharts message={data as IChatMessage} />;
  }
  if (FolderTypeEnum.COMMON_API === type)
    return (
      <DiscoverChart
        chartData={data}
        title={param.title}
        command={command}
        description={param?.description}
      />
    );
  if (FolderTypeEnum.MACRO === type)
    return <MacroChart chartData={data} title={param?.title} command={command} />;
  return null;
};

interface IProps {
  command: string;
  folder: IFolder;
  type: FolderTypeEnum;
  data: IChartReturnData | null;
  handleDelete: (command: string) => void;
  handlePreview: (
    id: string,
    title: string | null,
    type: FolderTypeEnum,
    description: string | null
  ) => void;
}

const actionMenu = [
  {
    icon: Nest,
    title: "Delete",
    key: "delete",
  },
  {
    icon: Copy,
    title: "Copy",
    key: "copy",
  },
  {
    icon: Move,
    title: "Move",
    key: "move",
  },
  {
    icon: Update,
    title: "Update",
    key: "update",
  },
];

export const FolderItem: FC<IProps> = ({
  folder,
  command,
  handleDelete,
  handlePreview,
  type,
  data,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const dispatch = useAppDispatch();

  const getTitle = (command: string) => {
    const item = folder.saved_data.find((i) => i.command === command);
    return item && item.description !== "new chart" ? item.description : null;
  };

  const getDescription = (command: string) => {
    const item = folder.saved_data.find((i) => i.command === command);
    return item && item.title !== "new chart" ? item.title : null;
  };

  const getItem = (command: string): IFolderData | undefined => {
    const item = folder.saved_data.find((i) => i.command === command);
    return item;
  };
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "folder",
      item: getItem(command),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [folder]
  );

  const handleSelectedAction = (key: string | number) => {
    if (key === "delete") {
      handleDelete(command);
    }
    if (key === "copy") {
      setShowModal(true);
      setCopy(true);
    }
    if (key === "move") {
      setShowModal(true);
      setCopy(false);
    }
    if (key === "update") {
      const message = data?.[type]?.[command] as IChatMessage;
      if (!message.parent?.text) return;
      notification.success("Update starting...");
      dispatch(setRequested());
      const hasFocusInId = message.parent?.from_phone.includes("focus");
      dispatch(
        fetchUserMessageFocus({
          text: message.parent?.text.trim(),
          user: hasFocusInId ? message.parent?.from_phone : `focus-${message.parent?.from_phone}`,
        })
      );
      const savedDataId = getItem(command)?.id;
      if (!message.hash || !savedDataId) return;
      dispatch(
        setIdToUpdate({
          hash: message.hash,
          savedDataId,
        })
      );
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const actions = useMemo(
    () =>
      actionMenu.filter((action) =>
        FolderTypeEnum.ODIN_CHAT === type ? true : action.key !== "update"
      ),
    [type]
  );

  return (
    <div className={styles.chartCard} key={command}>
      <div className={styles.buttonsContainer}>
        <div className={styles.menu}>
          <Menu hasArrow={false} menuItems={actions} handelMenuSelect={handleSelectedAction}>
            <div className={styles.menuInner}>
              <Dots />
            </div>
          </Menu>
        </div>
        <div className={styles.buttons}>
          <div
            className={styles.preview}
            onClick={() =>
              handlePreview(
                command,
                getTitle(command),
                type as FolderTypeEnum,
                getDescription(command)
              )
            }
          >
            <span>Preview</span>
            <Eye />
          </div>
          {!isMobile && (
            <div ref={drag} style={{ opacity }} className={styles.dragIcon}>
              <DragIcon />
            </div>
          )}
        </div>
      </div>
      {GetChartByType(type as FolderTypeEnum, data?.[type]?.[command], command, {
        title: getTitle(command),
        description: getDescription(command),
      })}
      <MoveToFolderModal
        show={showModal}
        onClose={onCloseModal}
        id={getItem(command)?.id}
        isCopy={isCopy}
        folderId={folder.id}
      />
    </div>
  );
};
