import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { discoverService } from "@/services";
import { IDrs } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IDiscoverdrsMetaState {
  drsList: IDrs[];
  isLoading: boolean;
  whiteList: number[];
}

const initialState: IDiscoverdrsMetaState = {
  drsList: [],
  whiteList: [],
  isLoading: false,
};

export const getDrsList = createAsyncThunk("discover/drsList", async () => {
  const whiteList = await discoverService.getSupportedDataSources();
  const response = await discoverService.fetchDRS();
  return {
    data: response.data?.filter((drs) => whiteList.data?.result.includes(drs.drs_id)).sort(),
    whiteList: whiteList.data?.result,
  };
});

export const discoverDrsListSlice = createSlice({
  name: "discoverDrsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDrsList.fulfilled, (state, action) => {
        state.drsList = action.payload.data;
        state.whiteList = action.payload.whiteList;
        state.isLoading = false;
      })
      .addCase(getDrsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDrsList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const discoverDrsListState = createSelector(state, (state) => state.discoverDrsList);

export default discoverDrsListSlice.reducer;
