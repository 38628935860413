import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useOuterClick } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { debounce } from "@/utils";

import { ItemType } from "../DropdownSearch";

export const useDropdownSearch = (
  list: ItemType[],
  selected: ItemType | null,
  onSelect: (item: ItemType) => void,
  loading = false,
  isUpperCase = false
) => {
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState("");
  const [filteredList, setFilteredList] = useState<ItemType[]>(list);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 575px)");

  const close = (selected: ItemType | null) => {
    setToggle(false);
    setValue(selected?.value || "");
    setFilteredList(list);
  };

  const [itemRef] = useOuterClick(close, selected);

  const selectHandler = useCallback(
    (item: ItemType) => {
      if (item.key !== "_group_") {
        onSelect(item);
        setToggle(false);
        setFilteredList(list);
      }
    },
    [onSelect, list]
  );

  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpperCase) setValue(ev.target.value.toUpperCase());
    else setValue(ev.target.value);
  };

  const filterList = (value: string | null) => {
    if (value) {
      const filtered = list.filter((i) =>
        i.value.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
      if (filtered.length) {
        setFilteredList(filtered);
        return;
      }
    }
    setFilteredList(list);
  };

  const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    setToggle(true);
    if (!isMobile) ev.target.select();
  };

  const debuncedSearch = useMemo(() => {
    return debounce(filterList, 300);
  }, [list]);

  const onClear = () => {
    inputRef.current?.blur();
    onSelect({ key: "", value: "" });
    setToggle(false);
  };

  useEffect(() => {
    if (document.activeElement === inputRef.current) {
      debuncedSearch(value);
      setToggle(true);
    }
  }, [value, debuncedSearch]);

  useEffect(() => {
    if (selected) setValue(selected.value);
    else setValue("");
  }, [selected]);

  useEffect(() => {
    if (loading === false && selected === null) {
      setValue("");
    }
    if (loading === false) setFilteredList(list);
  }, [loading, list, selected]);

  useEffect(() => {
    const escHandler = (ev: KeyboardEvent) => {
      if (ev.code === "Escape") {
        setToggle(false);
        inputRef.current?.blur();
      }
      if (ev.code === "Enter" && inputRef.current) {
        const inputValue = inputRef.current.value;
        const found = list.find(
          (i) => inputValue.trim().toLowerCase() === i.value.trim().toLowerCase()
        );

        if (found) {
          onSelect(found);
          setToggle(false);
          inputRef.current?.blur();
          setValue(found.value);
        }
      }
    };
    window.addEventListener("keydown", escHandler);
    return () => {
      window.removeEventListener("keydown", escHandler);
    };
  }, [list, onSelect]);

  return {
    itemRef,
    inputRef,
    value,
    toggle,
    selectHandler,
    changeHandler,
    focusHandler,
    filteredList,
    onClear,
  };
};
