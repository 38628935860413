import React, { FC } from "react";

import styles from "./HomeThemeLayout.module.scss";

interface IProps {
  children: React.ReactNode;
  bgImage?: string;
}

export const HomeThemeLayout: FC<IProps> = ({ children, bgImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.theme} style={{ backgroundImage: `url(${bgImage})` }}></div>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};
