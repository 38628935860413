import { AxiosResponse } from "axios";

import { IUserReport } from "@/types/report";

import axios from "./axios.config";

export const ReportService = {
  getUserReport: (data: IUserReport): Promise<AxiosResponse<any>> => {
    const payload = {
      ...data,
      website_id:
        process.env?.REACT_APP_ANALYTICS_CONTAINER_ID || "17b3912a-746c-435c-85ff-3b8b98e34f45",
    };
    return axios.post("/analytics/pages", payload);
  },
};
