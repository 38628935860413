import dayjs from "dayjs";
import Highcharts, { AxisCrosshairOptions, Chart as HighchartsChart } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { COLORS, DATE_ISO_US_SHORT_YEAR } from "@/constants";
import { ISeries } from "@/types";

import { exportingOptions, formatter } from "./utils";
import { Button } from "../Button/Button";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

const CHART_HEIGHT = 500;
const SERIE_TYPE = "spline";
const BENCHMARK_COLOR = "#c42525";

interface LineChartProps {
  ticker?: string;
  data?: ISeries[];
  dates?: string[];
  isQuarter?: boolean;
  fileTitleName?: string;
}

interface IGetColorOption {
  name: string;
  idx: number;
  hasBenchmark: boolean;
  defaultColor?: string;
}

const getSerieColor = (config: IGetColorOption) => {
  const { name, idx, hasBenchmark, defaultColor } = config;
  if (hasBenchmark) {
    const colors = COLORS.filter((c) => c !== BENCHMARK_COLOR);
    return name === "benchmark" ? BENCHMARK_COLOR : defaultColor ? defaultColor : colors[idx];
  }

  return defaultColor ? defaultColor : COLORS[idx];
};

export const Chart: FC<LineChartProps> = ({ ticker, data, isQuarter, fileTitleName }) => {
  const chartComponent = useRef<HighchartsChart | null>(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const crossChair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };

  const getCategories = () => {
    const datesList =
      data
        ?.reduce<(string | number | null)[][]>((acc, { data }) => {
          const dates = data.map(([date]) => date);
          acc.push(dates);
          return acc;
        }, [])
        .flat() || [];
    return [...new Set(datesList)].sort((a, b) => {
      if (typeof a === "number" && typeof b === "number") return a - b;
      if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);
      return 0;
    }) as string[];
  };

  const chartCallback = (chart: HighchartsChart) => {
    chartComponent.current = chart;
  };

  const chartOptions = useMemo((): Highcharts.Options => {
    const hasBenchmark = !!data?.some((ser) => ser.name === "benchmark");
    const categories = getCategories();

    return {
      title: {
        text: "",
      },
      boost: {
        useGPUTranslations: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueSuffix: "",
        valuePrefix: "",
        useHTML: true,
        formatter,
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      chart: {
        zooming: {
          type: "x",
        },
        type: "spline",
        marginTop: 35,
        events: {
          selection(e) {
            if (e.resetSelection) {
              setIsZoomed(false);
            } else {
              setIsZoomed(true);
            }
            return true;
          },
        },
        style: {
          fontFamily: "Inter",
        },
      },
      legend: {
        enabled: true,
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "separator",
              "downloadCSV",
            ],
          },
        },
        ...exportingOptions(fileTitleName),
      },
      colors: COLORS,

      yAxis: {
        title: {
          text: ticker || "Value",
        },
        crosshair: crossChair,
        gridLineWidth: 0,
        lineWidth: 1,
      },
      scrollbar: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: "",
        },
        type: "category",
        labels: {
          formatter(): string {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const date = dayjs(this.value);
            return isQuarter
              ? `F${date.format("Q")}Q${date.format("YY")}`
              : date.format(DATE_ISO_US_SHORT_YEAR);
          },
        },
        crosshair: crossChair,
        categories,
      },
      plotOptions: {
        series: {
          //set it to a larger threshold, it is by default to 1000
          turboThreshold: 10_000,
        },
      },
      series: data?.map((serie, idx) => ({
        ...serie,
        type: SERIE_TYPE,
        color: getSerieColor({ name: serie.name, idx, hasBenchmark, defaultColor: serie.color }),
        marker: {
          enabled: false,
        },
        shadow: false,
      })),
    };
  }, [data, ticker, isQuarter, getCategories]);

  const resetZoom = () => {
    if (chartComponent && chartComponent.current) {
      chartComponent.current.zoomOut();
    }
  };

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { CHART_HEIGHT } }}
        callback={chartCallback}
      />
      <div className={styles.zoomButton}>
        {isZoomed && <Button text="Reset Zoom" type="secondary" onClick={resetZoom} size="small" />}
      </div>
      <div className={`${styles.logo} ${isZoomed ? styles.withZoomedSpace : ""}`}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
