import dayjs from "dayjs";
import { io, Socket } from "socket.io-client";

import { ISocketChatMessage, ISocketMessage } from "@/types/odinChat";

const baseURL = process.env.REACT_APP_API_URL || "/default";
const baseURLSOcket = baseURL?.replace("/v1", "");
const path = "/v1/odin-chat-stream/socket.io";
export const ODIN_HELP_ID_PREFIX = "odin-help-video";

const DATE_TIME_IN_MILLISECOND = 1_000_000_000_000;

class CreateWebSocketService {
  socket: Socket | null = null;
  uid: string | null = null;
  constructor() {
    //
  }
  helpContent: ISocketMessage[] = [];

  connect(uid: string) {
    this.socket = io(baseURLSOcket, { path });
    console.log("Connecting....");
    this.uid = uid;
    const socket = this.socket;

    socket.on("connect", () => {
      console.log("Socket connected.");
      socket.emit("subscribe", { to_phone: uid });
    });

    socket.on("disconnect", (reason) => {
      console.log("Socket Disconnected: ", reason);
    });

    socket.on("error", (err) => {
      console.log("Socket connection error! ", err);
    });
  }

  disconnect() {
    if (this.socket) this.socket.disconnect();
  }

  formatTime(msg: ISocketChatMessage): ISocketChatMessage {
    const body = msg.Body as ISocketMessage;
    const createdAt =
      body.created_at && body.created_at < DATE_TIME_IN_MILLISECOND
        ? body.created_at * 1_000
        : body.created_at;
    return {
      ...msg,
      Body: {
        ...body,
        created_at: createdAt,
      },
    };
  }

  listenMessages(callback: (msg: ISocketChatMessage) => void) {
    const socket = this.socket;

    if (socket) {
      socket.on("message", (msg: ISocketChatMessage) => {
        // logging for development mode
        if (process.env?.REACT_APP_STAGING === "staging") {
          console.log("Socket Message: ", msg);
        }
        if (this.helpContent?.length) {
          this.helpContent.forEach((content, id) => {
            callback({
              Body: content,
              MessageId: `${id}`,
            });
          });
          console.log(this.helpContent);
          // this.helpContent = [];
        }
        if (Object.hasOwnProperty.call(msg.Body, "created_at")) {
          const formattedMessage = this.formatTime(msg);
          callback(formattedMessage);
        } else {
          callback(msg);
        }
      });
    }
  }

  addHelpMessage(message: string, uid: string, id: number) {
    const newVideoHelpMSG: ISocketMessage = {
      created_at: dayjs().valueOf(),
      text: message,
      from_phone: "CHATBOT",
      parent_id: -1,
      status: "Completed",
      to_phone: uid,
      id: `${ODIN_HELP_ID_PREFIX}-${new Date().valueOf()}-${id}`,
      type: "WEB",
      is_read: true,
    };
    this.helpContent.push(newVideoHelpMSG);
  }
}

export const socketService = new CreateWebSocketService();
