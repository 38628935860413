import { FC } from "react";

import { IEtfTicker, ITickerMeta } from "@/types";

import styles from "./Ticker.module.scss";

interface ITickerProps {
  data: IEtfTicker;
  formatter: (data: IEtfTicker, meta: ITickerMeta | null) => string;
  meta: ITickerMeta | null;
}

export const Ticker: FC<ITickerProps> = ({ data, formatter, meta }) => {
  return (
    <div className={styles.tickerContainer}>
      <span>{data.name.toUpperCase()}</span>
      <span>{formatter(data, meta)}</span>
    </div>
  );
};
