import { FC, useCallback, useEffect } from "react";

import { LoaderWrapperCustom, NoData } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AdminDocumentsState, getDocuments, updateOrdering } from "@/store/adminPanel/documents";

import { DocumentItem } from "./Document/DocumentItem";
import { DocumentUpload } from "./DocumentUpload/DocumentUpload";

import styles from "./Documents.module.scss";

export const Documents: FC = () => {
  const { documents, isLoading, isLoadingDocumnet } = useAppSelector(AdminDocumentsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (documents?.length) return;
    dispatch(getDocuments());
  }, [dispatch, documents?.length]);

  const updateOrder = useCallback(
    (hoverIndex: number, dragIndex: number) => {
      const cloned = [...(documents || [])];
      const [target] = cloned.splice(hoverIndex, 1);
      cloned.splice(dragIndex, 0, target);
      dispatch(updateOrdering(cloned));
    },
    [dispatch, documents]
  );
  return (
    <>
      <DocumentUpload />
      <div className={styles.container}>
        <h3>Documents</h3>
        <LoaderWrapperCustom loading={isLoading} transparent>
          <div className={styles.listContainer}>
            {documents?.length ? (
              documents.map((document, index) => (
                <DocumentItem
                  key={document?.id}
                  document={document}
                  index={index}
                  updateOrder={updateOrder}
                  disabled={isLoadingDocumnet}
                />
              ))
            ) : (
              <NoData />
            )}
          </div>
        </LoaderWrapperCustom>
      </div>
    </>
  );
};
