import React, { FC } from "react";

import styles from "./SubTitle.module.scss";

interface IProps {
  text: string;
}

export const SubTitle: FC<IProps> = ({ text }) => {
  return <span className={styles.subtitle}>{text}</span>;
};
