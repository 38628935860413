import React, { useState } from "react";

import { useOuterClick } from "@/hooks";

import styles from "./Popover.module.scss";

interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
}

export const Popover: React.FC<PopoverProps> = ({
  children,
  content,
  placement = "top",
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTogglePopover = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };
  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  };
  const [ref] = useOuterClick(handleClickOutside, false);

  return (
    <div className={`${styles.popover} ${isOpen ? styles.active : ""}`} ref={ref}>
      <div onClick={handleTogglePopover}>{children}</div>
      <div className={`${styles.popoverContent} ${styles[placement]}`}>{content}</div>
    </div>
  );
};
