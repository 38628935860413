import { FC } from "react";

import { CustomSelect, ModalLayout } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as FolderMove } from "@/images/folder-move.svg";
import { replaceItemInFolder } from "@/store/focus/folderSelected";
import { foldersManageState } from "@/store/focus/foldersManage";

import styles from "./MoveToFolderModal.module.scss";

interface IProps {
  show: boolean;
  onClose: () => void;
  id: number | undefined;
  isCopy: boolean;
  folderId: number;
}

export const MoveToFolderModal: FC<IProps> = ({ show, onClose, id, isCopy, folderId }) => {
  const { list } = useAppSelector(foldersManageState);
  const dispatch = useAppDispatch();

  const options = list
    .filter(({ id }) => folderId !== id)
    .map((folder) => ({
      label: folder.name,
      value: folder.id,
    }));

  const handleSelect = (value: number) => {
    if (!id) return;
    dispatch(replaceItemInFolder({ id, folder_id: value, action: isCopy ? "copy" : "move" }));
  };

  return (
    <ModalLayout show={show} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.head}>
          <FolderMove />
          <h2>{`${isCopy ? "Copy" : "Moving"} item to the selected folder.`}</h2>
        </div>
        <div className={styles.close} onClick={onClose}></div>
        <div className={styles.inner}>
          <div className={styles.select}>
            <div className={styles.field}>
              <span>{`Select a folder ${isCopy ? "copy" : "moving"} to`}</span>
            </div>
            <CustomSelect options={options} onChange={handleSelect} />
          </div>
          <div className={styles.text}>
            <span>{`Please select a destination folder where you want to ${
              isCopy ? "copy" : "moving"
            } the selected item.`}</span>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
