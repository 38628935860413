import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button, Dropdown, InputItem, ItemType, Loader, ModalLayout } from "@/components";
import { BENCHMARKS } from "@/constants/PortfolioHedging";
import { HedgingTypeEnum, IPortfolioHedging } from "@/types";
import { notification } from "@/utils";

import useGetRecommendation from "../../hooks/useGetRecommendation";
import useSaveData from "../../hooks/useSaveData";
import { RecommendationInfo } from "../Recommendation/RecommendationInfo";

import styles from "./HedgingActions.module.scss";

import Recommendation from "@images/recommend.svg";

interface IProps {
  selected: IPortfolioHedging | null;
  showModal: boolean;
  isLoading?: boolean;
  setSelected: Dispatch<SetStateAction<IPortfolioHedging | null>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  isRecomendation: boolean;
  setRecomendation: React.Dispatch<React.SetStateAction<boolean>>;
}
const MAX_CSV_SIZE = 500 * 1024 * 1024; // Maximum size of 500MB;

const typs = Object.values(HedgingTypeEnum).map((key) => ({
  key,
  value: key,
}));

const benchmarks = BENCHMARKS.map((key) => ({
  key,
  value: key,
}));

export const HedgingActions: FC<IProps> = ({
  selected,
  isLoading,
  showModal,
  setShowModal,
  setSelected,
  isRecomendation,
  setRecomendation,
}) => {
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<HedgingTypeEnum>(HedgingTypeEnum.DAILY_RETURN);
  const [benchmark, setBenchmark] = useState<string>(BENCHMARKS[0]);

  const { handleUpload, handleSave, csvFile, setCsvFile } = useSaveData({
    id: selected?.id,
    name,
    type,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    getRecommendation,
    text,
    resetText,
    isLoading: loadingRecommend,
  } = useGetRecommendation(benchmark);

  const handleImputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleClose = useCallback(() => {
    setShowModal(false);
    setSelected(null);
    setName("");
    setCsvFile(null);
    setRecomendation(false);
    setType(HedgingTypeEnum.DAILY_RETURN);
    resetText();
  }, [resetText, setCsvFile, setRecomendation, setSelected, setShowModal]);

  useEffect(() => {
    if (!selected) return;
    setName(selected.name);
    setType(selected.type);
  }, [selected]);

  useEffect(() => {
    handleClose();
  }, [handleClose, isLoading]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file && file.size <= MAX_CSV_SIZE) {
      handleUpload(file);
    } else {
      notification.error("File size exceeds the maximum limit of 500MB.");
    }
  };

  const handleSelectType = (_name: string, item: ItemType<HedgingTypeEnum>) => {
    setType(item.key);
  };

  const handleSelectBenchmark = (_name: string, item: ItemType<string>) => {
    setBenchmark(item.key);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleRecommendation = () => {
    if (!selected?.id) return;
    getRecommendation(selected?.id);
  };

  return (
    <ModalLayout show={showModal || !!selected} onClose={handleClose}>
      <div className={styles.container}>
        <h2>
          {showModal
            ? "Create Portfolio Hedging"
            : isRecomendation
            ? `Recommendation For '${selected?.name}'`
            : "Edit Portfolio Hedging"}
        </h2>
        <div className={styles.close} onClick={handleClose}></div>
        {isRecomendation ? (
          <>
            <div className={styles.recommend}>
              <div className={styles.select}>
                <Dropdown
                  list={benchmarks}
                  onSelect={handleSelectBenchmark}
                  selected={{ key: benchmark, value: benchmark }}
                  label="Benchmark"
                  name="Benchmark"
                  placeholder="Select Benchmark"
                />
              </div>
              <div className={styles.button}>
                {loadingRecommend ? (
                  <div className={styles.loader}>
                    <Loader />
                  </div>
                ) : (
                  <Button
                    text="Get Recommendation"
                    type="secondary"
                    onClick={handleRecommendation}
                  />
                )}
              </div>
            </div>
            <div className={styles.textInfo}>{text && <RecommendationInfo text={text} />}</div>
          </>
        ) : (
          <>
            <InputItem
              onChange={handleImputChange}
              label="Name"
              name="Name"
              type="input"
              placeholder="Type Name"
              value={name}
            />
            <div className={styles.section}>
              <div className={styles.type}>
                <Dropdown
                  list={typs}
                  onSelect={handleSelectType}
                  selected={{ key: type, value: type }}
                  label="Type"
                  name="type"
                />
              </div>
              <div className={styles.upload}>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  id="profileImage"
                  onChange={handleChange}
                  accept=".csv"
                />
                <Button onClick={handleButtonClick} type="secondary" text="Upload CSV" />
                {csvFile && <p className={styles.name}>{csvFile.name}</p>}
              </div>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.buttons}>
                <Button text="Cancel" onClick={handleClose} />
                <Button text="Save" type="secondary" onClick={handleSave} />
              </div>
            </div>
          </>
        )}
      </div>
    </ModalLayout>
  );
};
