import { FC } from "react";

import styles from "./EmptyList.module.scss";

export const EmptyList: FC = () => {
  return (
    <div className={styles.emptyListContainer}>
      <p>Messages not created yet.</p>
      <p>Please, You can type some text message for Odin.</p>
    </div>
  );
};
