/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import Highcharts, { AxisCrosshairOptions, SeriesOptionsType } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useMemo, useRef, useState } from "react";

import { COLORS, DATE_ISO_US, DATE_ISO_US_SHORT, labels } from "@/constants";
import { ISeries } from "@/types";
import { IPlotBands } from "@/types/forecast";

import { exportingOptions, formatter } from "./utils";
import { Button } from "../Button/Button";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

interface LineChartProps {
  title?: string | null;
  data?: ISeries[];
  plotBands?: IPlotBands[];
  height?: number;
  type?: string;
  isMultyAxes?: boolean;
  isFullDate?: boolean;
}

Highcharts.Chart.prototype.showResetZoom = function showResetButton() {
  //
};

export const ChartHomePage: FC<LineChartProps> = ({
  title,
  data,
  plotBands,
  height = 500,
  type = "spline",
  isMultyAxes = true,
  isFullDate = false,
}) => {
  const chartComponent = useRef<HighchartsReact.RefObject | null>(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const crossHair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };

  const getNumbers = (data?: ISeries[]): number[] => {
    return data
      ? data
          .map((serie) => {
            return serie.data
              .map((i) => i[1] as number | null)
              .filter((i) => i !== null) as number[];
          })
          .flat()
      : [0];
  };
  const minY = Math.min(...getNumbers(data));
  const maxY = Math.max(...getNumbers(data));

  const yAxisDefault = {
    title: {
      text: title || "",
    },
    gridLineWidth: 0,
    lineWidth: 1,
    crosshair: crossHair,
    labels: {
      style: {
        fontWeight: "500",
      },
    },
  };

  const seriesConfig = () =>
    data?.map((seriesData, idx) => ({
      ...seriesData,
      yAxis: isMultyAxes ? idx : 0,
      data: seriesData.data.map((item) => [type === "column" ? item[0] : item[0], item[1]]),
      type: seriesData.type || "spline",
    })) || [];

  const yAxisOptions = () =>
    (isMultyAxes &&
      data?.map((seriesData, idx) => {
        return {
          title: {
            text: seriesData.name ?? (title || ""),
          },
          opposite: idx > 0,
          lineWidth: 1,
          gridLineWidth: 0,
          crosshair: crossHair,
          min: data && data.length > 1 ? minY : undefined,
          max: data && data.length > 1 ? maxY : undefined,
          labels: {
            style: {
              fontWeight: "500",
            },
          },
        };
      })) ||
    yAxisDefault;

  const chartOptions = useMemo((): Highcharts.Options | undefined => {
    try {
      const series = seriesConfig();
      const categories = [
        ...new Set(
          series.map((s) => s.data.map((item) => (item[0] ? item[0].toString() : ""))).flat()
        ),
      ];
      return {
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          valueSuffix: "",
          valuePrefix: "",
          useHTML: true,
          formatter:
            type === "spline"
              ? formatter
              : function () {
                  return `<div>
                ${this.points
                  ?.map(
                    (point) =>
                      `<p style="color: ${point.color}; padding-bottom: 4px; margin-bottom: 4px; border-bottom: 1px solid #cacaca;"><b>${point.x}</b></p>
                      <p>${point.series.name}: <b>${point.y}</b></p>`
                  )
                  .join("")}
              </div>`;
                },
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
          selected: 5,
        },
        chart: {
          //@ts-ignore
          zoomType: "x",
          width: undefined,
          events: {
            selection(e) {
              if (e.resetSelection) {
                setIsZoomed(false);
              } else {
                setIsZoomed(true);
              }
              return true;
            },
          },
          type,
          marginTop: 35,
          style: {
            fontFamily: "Inter",
            fontSize: "10px",
          },
          animation: {
            duration: 300,
          },
        },
        exporting: {
          enabled: false,
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "separator",
                "downloadCSV",
              ],
            },
          },
          ...exportingOptions(),
        },
        colors: COLORS,
        scrollbar: {
          enabled: false,
        },
        series:
          series.length > 1
            ? (series as SeriesOptionsType[])
            : (series.map((serie) => ({
                ...serie,
                data: serie.data.map((i) => i[1]),
              })) as SeriesOptionsType[]),
        yAxis: yAxisOptions(),
        legend: {
          enabled: seriesConfig.length > 1,
          itemStyle: {
            fontSize: "10px",
          },
        },
        xAxis: {
          title: {
            text: "",
          },
          crosshair: crossHair,
          type: "category",
          plotBands,
          ...((type === "column" || type === "spline") && { categories }),
          labels: {
            style: {
              fontSize: "10px",
              opacity: 0.5,
            },
            ...(type === "spline" && {
              formatter(): string {
                const date = dayjs(this.value);
                return isFullDate ? date.format(DATE_ISO_US) : date.format(DATE_ISO_US_SHORT);
              },
            }),
          },
        },
      };
    } catch (e) {
      console.warn("Some data are corrupted...:", e);
    }
  }, [title, data, plotBands, height, type]);

  const resetZoom = () => {
    if (chartComponent && chartComponent.current) {
      chartComponent.current.chart.zoomOut();
    }
  };

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height } }}
      />
      <div className={styles.zoomButton}>
        {isZoomed && <Button text="Reset Zoom" type="secondary" onClick={resetZoom} size="small" />}
      </div>
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
