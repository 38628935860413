import { FC, ReactNode, useEffect, useRef } from "react";

import { AtomLoader, DotsLoader } from "@/components";
import { debounce } from "@/utils";

import styles from "./LoaderWrapper.module.scss";

interface IProps {
  children: ReactNode;
  loading: boolean;
  atom?: boolean;
  transparent?: boolean;
  fullWidth?: boolean;
  animatedHeight?: boolean;
  removeContentOnLoading?: boolean;
}

const CONTAINER_ID = "loder-inner-container";
const SHIFT = 2;

export const LoaderWrapper: FC<IProps> = ({
  children,
  loading,
  atom,
  transparent,
  fullWidth,
  animatedHeight,
  removeContentOnLoading = true,
}) => {
  const rootItemRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const resizeHandler = () => {
    setTimeout(() => {
      if (rootItemRef.current && animatedHeight && innerRef.current) {
        const scrollHeight = innerRef.current.scrollHeight;
        const height = `max-height: ${scrollHeight + SHIFT}px`;

        rootItemRef.current.setAttribute("style", height);
        const item = innerRef.current;
        item?.setAttribute("style", "opacity: 1");
      }
    }, 100);
  };

  useEffect(() => {
    resizeHandler();
  }, [loading]);

  useEffect(() => {
    const debouncedResizeHandler = debounce(resizeHandler, 100);
    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, []);

  return (
    <div
      className={`${styles.wrapper} ${loading ? styles.loading : ""} ${
        fullWidth ? styles.fullWidth : ""
      } ${animatedHeight ? styles.withAnimation : ""}`}
      ref={rootItemRef}
    >
      {loading && (
        <div className={`${styles.innerLoading} ${transparent ? styles.transparent : ""}`}>
          <div className={transparent ? styles.innerEmptyNoBorder : styles.innerEmpty}>
            {atom ? <AtomLoader /> : <DotsLoader />}
          </div>
        </div>
      )}
      {(!loading || !removeContentOnLoading) && (
        <div
          className={`${styles.inner} ${loading ? styles.hidden : ""}`}
          id={CONTAINER_ID}
          ref={innerRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};
