import { FC, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useAppDispatch } from "@/hooks";
import { ReactComponent as CloseMenuIcon } from "@/images/icons/close.svg";
import { ReactComponent as CollapsedMenuIcon } from "@/images/icons/collapsed-menu.svg";
import { ReactComponent as OdinUltraLogoIcon } from "@/images/icons/logo-powered.svg";
import { muteVideo } from "@/store/homeStart";

import { useMenuNavigation } from "./hooks/useMenuNavigation";
import { TopNavigation } from "../TopNavigation/TopNavigation";

import styles from "./HeaderNavigation.module.scss";

const menuList = [
  {
    label: "Solutions",
    path: "/solutions?type=discretionary",
    key: "solutions",
  },
  {
    label: "Insights",
    path: "/insights",
    key: "insights",
  },
  {
    label: "About",
    path: "/about",
    key: "about",
  },
];

type MenuItemType = typeof menuList;

export const HeaderNavigation: FC = () => {
  const { isMobileMode, isOpen, menuToggle } = useMenuNavigation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isHomePage = pathname === "/";

  const selectMenuHandle = () => {
    menuToggle(false);
    dispatch(muteVideo(true));
  };

  const activePage = pathname.toLowerCase().replace("/", "");

  return (
    <header className={`${styles.headerContainer} ${isHomePage ? styles.home : ""}`}>
      <div className={styles.inner}>
        <div className={styles.logoContainer}>
          <NavLink to="/" onClick={() => menuToggle(false)}>
            {<OdinUltraLogoIcon />}
          </NavLink>
        </div>

        {isMobileMode ? (
          <Fragment>
            <div
              className={`${styles.collapsedMenu} ${isOpen ? styles.close : ""}`}
              onClick={() => menuToggle()}
            >
              <CollapsedMenuIcon />
              <CloseMenuIcon />
            </div>
            <NavigationList
              list={menuList}
              isOpen={isOpen}
              isMobile={true}
              onSelect={selectMenuHandle}
              activeItem={activePage}
            />
          </Fragment>
        ) : (
          <Fragment>
            <NavigationList
              list={menuList}
              onSelect={() => dispatch(muteVideo(true))}
              activeItem={activePage}
            />
            <TopNavigation />
          </Fragment>
        )}
      </div>
    </header>
  );
};

interface INavigationList {
  list: MenuItemType;
  isMobile?: boolean;
  isOpen?: boolean;
  onSelect?: () => void;
  activeItem?: string;
}

const NavigationList: FC<INavigationList> = ({
  list,
  isMobile = false,
  isOpen = false,
  onSelect,
  activeItem,
}) => {
  const { pathname } = useLocation();

  const isInverse = pathname !== "/";
  return (
    <nav
      className={`${styles.navigation} ${isMobile ? styles.mobile : ""} ${
        isOpen ? styles.open : ""
      }`}
    >
      <ul className={styles.navList}>
        {list.map(({ label, path, key }) => (
          <li
            className={`${styles.navItem} ${activeItem === key ? styles.active : ""}`}
            data-inverse={isInverse}
            key={label}
          >
            <NavLink to={path} key={key} onClick={onSelect}>
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
      {isMobile ? <TopNavigation inverse={isMobile} onSelect={onSelect} /> : null}
    </nav>
  );
};
