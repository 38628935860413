import { FC } from "react";
import { useDrop } from "react-dnd";

import { useAppDispatch } from "@/hooks";
import { replaceItemInFolder } from "@/store/focus/folderSelected";
import { IFolderData } from "@/types";

import styles from "../../FocusTabs.module.scss";

import { ReactComponent as FolderIcon } from "@images/folder.svg";

interface IProps {
  id: number;
  title: string;
  activeTab: number | undefined;
  handleSetActiveTab: (tab: number) => void;
  isLast?: boolean;
  handleShowModal: () => void;
  handleSetNew: (e: boolean) => void;
}

export const TabNavItem: FC<IProps> = ({
  id,
  title,
  activeTab,
  handleSetActiveTab,
  isLast,
  handleShowModal,
  handleSetNew,
}) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    handleSetActiveTab(id);
    handleSetNew(false);
  };

  const handleCreateNew = () => {
    handleShowModal();
    handleSetNew(true);
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "folder",
      drop: (item: IFolderData) => {
        if (item?.folder_id === id) return;
        dispatch(replaceItemInFolder({ id: item.id, folder_id: id, action: "move" }));
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        return {
          isOver: monitor.isOver(),
          canDrop: item?.folder_id === id ? false : monitor.canDrop(),
        };
      },
    }),
    []
  );

  const isActive = isOver && canDrop;
  let backgroundColor = "";
  let height = "36px";
  if (isActive) {
    backgroundColor = "#91ca87";
    height = "50px";
  } else if (canDrop) {
    backgroundColor = "#fcffe5";
  }

  return (
    <>
      {!isLast ? (
        <li
          ref={drop}
          style={{ backgroundColor, height }}
          onClick={handleClick}
          className={activeTab === id ? styles.active : ""}
        >
          <div className={styles.tabInner}>
            <div className={styles.icon}>
              <FolderIcon />
            </div>
            <span>{title}</span>
          </div>
        </li>
      ) : (
        <li onClick={handleCreateNew}>
          <div className={styles.tabInner}>
            <span>{title}</span>
          </div>
        </li>
      )}
    </>
  );
};
