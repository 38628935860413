import { ItemType } from "@/components";
import { ConstructorInputType, CustomBasketIndicatorType } from "@/types";

export const customBasketsIndicatorsList: ItemType<CustomBasketIndicatorType>[] = [
  {
    key: "sp500",
    value: "S&P 500",
    replaceWith: "sp500",
  },
  {
    key: "nasdaq",
    value: "NASDAQ 100",
    replaceWith: "nasdaq100",
  },
  {
    key: "small-cap",
    value: "Small-Cap",
    replaceWith: "small_cap",
  },
  {
    key: "mid-cap",
    value: "Mid-Cap",
    replaceWith: "mid_cap",
  },
  {
    key: "custom-basket",
    value: "Custom Basket",
  },
];

export const customOperatorInputTypeList: ItemType<ConstructorInputType>[] = [
  {
    key: "function",
    value: "Operator Functions​",
  },
  {
    key: "instrument",
    value: "Instrument",
  },
];

export const ERRORS = {
  createError: "Error during creating custom indicator! Please, try later.",
  formatError: "Can not format the exit formula condition!",
  exitFormulaError:
    "Some of parameters did not contains correct Instrument Ticker! Please, replace them and try again.",
  alreadyExist:
    "Custom indicator with provided name ALREADY EXIST! Please, change the indicator name and try again.",
  comparativeError:
    "Expression should contain at least one comparative sign! Modify the expression and try again.",
  deleteError: "Something went wrong during DELETE INDICATOR operation! Please, try again later.",
  updateError: "Something went wrong during UPDATE INDICATOR operation! Please, try again later.",
  getDataError: "Some error occurred while getting data! Please, try again later.",
};

export const SUCCESS = {
  creaeated: "Indicator successful CREATED!",
  deleted: "Indicator successful DELETED!",
  updated: "Indicator successful UPDATED!",
};
