import { FC, useCallback, Fragment, useState, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  Heading,
  GoBack,
  Button,
  Loader,
  Chart,
  ModalLayout,
  Table,
  InfoHeading,
} from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { EmptyContainer } from "@/components/EmptyContainer/EmptyContainer";
import { ROUTES, SideBarKeyEnum } from "@/constants";
import { useDownloadHistorical } from "@/features/StrategyCreate/hooks/useDownloadHistorical";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import EditIcon from "@/images/editIcon.svg";
import DeleteIcon from "@/images/nest.svg";
import { fetchDeleteStrategy, initList } from "@/store/strategies/my_strategies";
import { strategiesState } from "@/store/strategies/strategies";

import { useGetData } from "./hooks/useGetData";
import { usePreviewPerformanceData } from "./hooks/usePreviewPerformanceData";

import styles from "./StrategiesPreview.module.scss";

import DownloadIcon from "@images/download.svg";
import { ReactComponent as StrategyIcon } from "@images/strategy.svg";

const addQueryParam = () => {
  let paramStr = "";
  return (paramName: string, paramValue: string | null): string => {
    if (paramStr.length === 0) paramStr = paramValue ? paramName + "=" + paramValue : "";
    else paramStr = paramValue ? paramStr + "&" + paramName + "=" + paramValue : paramStr;
    return paramStr;
  };
};

export const StrategiesPreview: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const { strategyId } = useParams();
  const [search] = useSearchParams();
  const dispatch = useAppDispatch();
  const { strategy, historical } = useAppSelector(strategiesState);
  const { downloadCSV, progress: downloadProgress } = useDownloadHistorical();
  const { textInfo } = usePageInfo(SideBarKeyEnum.SINGLE_STRATEGY);

  const type: string | null = strategy && strategy[0]?.type[0] === "overlay" ? "overlay" : "core";
  const strategyTitleName = search.get("name");

  const dateRangeQuery = useMemo(() => {
    const addParam = addQueryParam();
    addParam("start_date", search.get("start_date"));
    return addParam("end_date", search.get("end_date"));
  }, [search]);

  const { showActionsNavigation, isGlobalEditable, isDataLoading, isUserAllow, isEmpty } =
    useGetData(dateRangeQuery, strategyId);

  const { chartData, tableData, noData, columns } = usePreviewPerformanceData();

  const editHandler = useCallback(() => {
    const strategyName = encodeURIComponent(strategyTitleName || "");
    navigate(`${ROUTES.strategiesBuilder.path}/edit/${strategyId}?name=${strategyName}`);
  }, []);

  const deleteHandler = useCallback(() => {
    setShowModal(true);
  }, []);

  const confirmDeleteHandler = useCallback(() => {
    const id = Number(strategyId);
    if (Number.isInteger(id)) {
      setProgress(true);
      dispatch(fetchDeleteStrategy(id)).then(() => {
        dispatch(initList());
        setProgress(false);
        navigate("..");
      });
    }
    setShowModal(false);
  }, [strategyId]);

  const cancelHandler = () => {
    setShowModal(false);
  };

  const goBackHandler = useCallback(() => {
    navigate(-1);
  }, []);

  const downloadHistoricalHandler = () => {
    if (historical) downloadCSV(strategyTitleName, historical);
  };

  return (
    <EmptyContainer
      title="Sorry, Such Strategy not found..."
      isEmpty={isEmpty}
      loadingHelpText="Please wait, loading strategy performance data ..."
    >
      <div className={styles.container}>
        <ModalLayout show={showModal} onClose={cancelHandler}>
          <Confirm
            onConfirm={confirmDeleteHandler}
            onCancel={cancelHandler}
            text="Do you really want to delete strategy?"
          />
        </ModalLayout>
        <div className={styles.innerContainer}>
          <Fragment>
            <InfoHeading helpInfo={textInfo}>Strategy Builder</InfoHeading>
            <div className={styles.topTitle}>
              <StrategyIcon className={`${type === "overlay" ? styles.overlay : ""}`} />
              <p className={styles.title}>
                <span>Name:</span>
                {strategyTitleName}
              </p>
            </div>
            <div className={styles.navigationWrapper}>
              <div className={styles.btnWrapper}>
                <GoBack onClick={goBackHandler} />
              </div>
              {showActionsNavigation && (
                <div className={styles.buttonsGroup}>
                  {progress && <Loader />}
                  {!!historical && (
                    <Button
                      type="download"
                      text="Historical Trades/Positions"
                      disabled={downloadProgress}
                      onClick={downloadHistoricalHandler}
                      icon={DownloadIcon}
                    />
                  )}
                  {isUserAllow?.canUpdate && isGlobalEditable && (
                    <Button
                      text="Edit Strategy"
                      onClick={editHandler}
                      disabled={isDataLoading || progress}
                      icon={EditIcon}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={`${styles.sectionsWraper} ${progress ? styles.loading : ""}`}>
              <section className={styles.chartSection}>
                <div className={styles.chartContainer}>
                  {noData === true || noData === null ? (
                    <div className={styles.emptyData}>Data not available yet.</div>
                  ) : (
                    <Chart data={chartData} ticker="US Dollar ($)" />
                  )}
                </div>
              </section>
              <section className={styles.tableSection}>
                <Heading type="h3" className={styles.heading}>
                  Performance Metrics
                </Heading>
                <div className={styles.tableWrapper}>
                  <Table data={tableData} columns={columns} size="medium" />
                </div>
              </section>
            </div>
          </Fragment>
          <div className={styles.buttonsGroup}>
            {isUserAllow?.canDelete && isGlobalEditable && (
              <Button
                text="Delete Strategy"
                onClick={deleteHandler}
                disabled={isDataLoading || progress}
                type="gost"
                icon={DeleteIcon}
                danger
              />
            )}
          </div>
        </div>
      </div>
    </EmptyContainer>
  );
};
