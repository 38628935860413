import { useCallback, useEffect, useRef, useState } from "react";

import { useDeleteUserFileMutation } from "@/services/apiQuery/userData";
import { UserDataFileType } from "@/types";
import { notification } from "@/utils";

export interface IRemoveItemConfig {
  id: number;
  type: UserDataFileType;
}

export const useRemoveModal = (onCompleteCB?: () => void) => {
  const [showModal, setShowModal] = useState(false);
  const [remove, result] = useDeleteUserFileMutation();
  const configRef = useRef<IRemoveItemConfig | null>(null);
  const { isLoading, isError, isSuccess } = result;

  const confirmRemoveHandler = useCallback(() => {
    setShowModal(false);
    if (configRef.current) remove(configRef.current);
  }, []);

  const removeHandler = useCallback(
    (removeData: IRemoveItemConfig) => {
      setShowModal(true);
      configRef.current = removeData;
    },
    [remove]
  );

  const cancelHandler = useCallback(() => {
    setShowModal(false);
    configRef.current = null;
  }, []);

  useEffect(() => {
    if (isError) {
      notification.error("Sorry, something went wrong while removing file!");
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success("File successful removed.");
      if (onCompleteCB) onCompleteCB();
    }
  }, [isSuccess, onCompleteCB]);

  return {
    confirmRemoveHandler,
    removeHandler,
    cancelHandler,
    showModal,
    isRemoving: isLoading,
  };
};
