import { useMemo } from "react";

import { UserDataFileType } from "@/types";
import { createColumns, parseCSVData } from "@/utils";

export const useTableDate = (type: UserDataFileType | null, data?: string) => {
  const tableData = useMemo(() => {
    return parseCSVData(data) || [];
  }, [data]);

  const columns = useMemo(() => {
    if (data && type) {
      const columns = tableData ? Object.keys(tableData[0] || {}) : [];
      return createColumns(type, columns);
    }
    return [];
  }, [type, tableData]);

  return {
    tableData,
    columns,
  };
};
