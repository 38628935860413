import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { PortfolioService } from "@/services";
import { IBenchmark } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IBenchmarksState {
  results: IBenchmark[];
  isLoading: boolean;
  requested: boolean;
}

const initialState: IBenchmarksState = {
  results: [],
  isLoading: false,
  requested: false,
};

export const fetchBenchmarks = createAsyncThunk("benchmark/list", async () => {
  const res = await PortfolioService.getBenchmarks();
  return res?.data.result;
});

export const benchmarksSlice = createSlice({
  name: "benchmark",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBenchmarks.fulfilled, (state, action) => {
        state.requested = true;
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchBenchmarks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBenchmarks.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const BenchmarksState = createSelector(state, (state) => state.benchmarks);

export default benchmarksSlice.reducer;
