export const DATE_ISO = "YYYY-MM-DD";
export const DATE_ISO_TIME = "MMM DD, YYYY, HH:mm";
export const DATE_ISO_TIME_US = "MMM DD, YYYY, h:mm a";
export const DATE_ISO_US = "MMM DD, YYYY";
export const DATE_ISO_US_YEAR_SHORT = "MMM DD, `YY";
export const DATE_ISO_US_SHORT_YEAR = "MMM DD, YY";
export const DATE_ISO_US_SHORT = "MMM 'YY";
export const TIME_ISO = "HH:mm";
export const TIME_ISO_US = "h:mm a";

export const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const checkTimeFormat = () => {
  const language = navigator.language;
  const date = new Date().toLocaleTimeString(language, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeStyle: "short",
  });
  if (date.toLowerCase().includes("am") || date.toLowerCase().includes("pm")) return false;
  return true;
};

export const is24hFormat = checkTimeFormat();
