import { useEffect, useState } from "react";

import { useMediaQuery } from "@/hooks/useMediaQuery";

export const useMenuNavigation = () => {
  const isMobileMode = useMediaQuery("(max-width: 991px)");
  const [isOpen, setOpenState] = useState(false);

  useEffect(() => {
    if (isMobileMode === false) setOpenState(false);
  }, [isMobileMode]);

  const menuToggleHandle = (state?: boolean) => {
    if (state === undefined) setOpenState((prev) => !prev);
    else setOpenState(state);
  };

  return {
    isOpen,
    menuToggle: menuToggleHandle,
    isMobileMode,
  };
};
