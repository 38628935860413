import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ROUTES } from "@/constants";
import { Errors } from "@/constants/errors";
import { globalNavigate } from "@/GlobalNavigate";
import { PortfolioService, strategiesService } from "@/services";
import {
  ConditionType,
  IIndicator,
  INewStartegyForm,
  IStrategyBase,
  ICommonStrategyData,
  OperatorType,
  DirectionType,
  IConstructorErrors,
  StrategyType,
  IStrategyData,
  TradingInstrumentType,
  NewStartegyConfig,
  UpdateFrequencyType,
  RebalanceFrequencyType,
  WeightingScheme,
  TradingBasketType,
  OptionType,
  InputFieldNames,
  UnderlyingType,
  StrikeRatioType,
  IInputField,
  IHistoryData,
  ErorrsType,
  AllocationStyleType,
  ExitStyleType,
} from "@/types";
import {
  clearConditionForm,
  createDefaultEquityBaskets,
  createFormula,
  createNewStartegyForm,
  prepareEditForm,
  prepareNewStartegyData,
  preparePerformaceData,
  resetInstrumentsStrategyForm,
  validateOptionInputs,
} from "@/utils";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IStrategiesState {
  isLoading: boolean;
  requested: boolean;
  results: ICommonStrategyData[];
  strategy: IStrategyBase[] | null;
  newStartegyForm: INewStartegyForm;
  indicatorsList: IIndicator[] | null;
  constructorIsLoading: boolean;
  isPreviewLoading: boolean;
  isNewStrategySvaed: boolean;
  historical: IHistoryData[] | null;
}

const initialState: IStrategiesState = {
  results: [],
  isLoading: false,
  requested: false,
  strategy: null,
  newStartegyForm: createNewStartegyForm(),
  indicatorsList: null,
  constructorIsLoading: false,
  isPreviewLoading: false,
  isNewStrategySvaed: false,
  historical: null,
};

export const fetchBenchmarksList = createAsyncThunk("strategies/fetchBenchmarksList", async () => {
  const res = await PortfolioService.getBenchmarks();
  return res?.data.result;
});

export const fetchMetricsList = createAsyncThunk("strategies/fetchMetricsList", async () => {
  const { data } = await strategiesService.getMetricsList();
  return data.result;
});

export const fetchPerformancePrefligth = createAsyncThunk(
  "strategies/fetchPerformancePrefligth",
  async (configData: { data: NewStartegyConfig }, { rejectWithValue }) => {
    try {
      const { data } = await strategiesService.getStrategyPerformancePreview(configData.data);
      return data.result;
    } catch (err) {
      const error = err as AxiosError | { isCanceled: boolean };
      if (Object.hasOwn(error, "isCanceled")) return rejectWithValue({ isCanceled: true });
      throw err;
    }
  }
);

export const fetchStrategiesData = createAsyncThunk("strategies/strategyList", async () => {
  const { data } = await strategiesService.fetchStrategyList();
  return data.result;
});

export const fetchStrategyPerformanceData = createAsyncThunk(
  "strategies/fetchStrategyData",
  async (config: { strategyKey: string; dateRangeQuery?: string }, { rejectWithValue }) => {
    try {
      if (Number.isInteger(Number(config.strategyKey))) {
        const { data } = await strategiesService.fecthStrategyPerformanceData(
          Number(config.strategyKey)
        );
        return data.result ? data.result : [];
      } else if (!Number.isInteger(Number(config.strategyKey))) {
        const { data } = await strategiesService.fecthStrategyPerformanceData(
          config.strategyKey,
          config.dateRangeQuery
        );
        return data.result || [];
      }
      throw Error;
    } catch (err) {
      const error = err as AxiosError | { isCanceled: boolean };
      if (Object.hasOwn(error, "isCanceled")) return rejectWithValue({ isCanceled: true });
      throw err;
    }
  }
);

export const fetchCreateStrategy = createAsyncThunk(
  "strategies/fetchCreateStrategy",
  async (form: INewStartegyForm, { rejectWithValue }) => {
    try {
      const { data } = await strategiesService.createStrategy(prepareNewStartegyData(form));
      return data;
    } catch (err) {
      const error = err as AxiosError<{ error: any }>;
      if (error?.response?.data?.error?.error_code === 301) {
        return rejectWithValue(ErorrsType.exist);
      }
      throw err;
    }
  }
);

export const fetchUpdateStrategy = createAsyncThunk(
  "strategies/fetchUpdateStrategy",
  async (meta: { form: INewStartegyForm; id: number | null }, { rejectWithValue }) => {
    const { form, id } = meta;
    if (id === null) throw Error;
    const { data } = await strategiesService.updateStrategy(prepareNewStartegyData(form), id);
    return { data, id };
  }
);

const activeCondition = (state: IStrategiesState) => state.newStartegyForm.activeCondition;

export const strategiesSlice = createSlice({
  name: "strategyList",
  initialState,
  reducers: {
    init: (state) => {
      state.newStartegyForm = createNewStartegyForm();
      state.indicatorsList = null;
      state.isNewStrategySvaed = false;
    },
    resetStrategyForm: (state) => {
      const instrument = state.newStartegyForm.tradingInstrument.type;
      state.newStartegyForm = resetInstrumentsStrategyForm(instrument, state.newStartegyForm);
      state.indicatorsList = null;
      state.isNewStrategySvaed = false;
    },
    resetStrategy: (state) => {
      state.strategy = null;
      state.historical = null;
    },
    setActiveStrategy: (state, { payload }: PayloadAction<IStrategyData>) => {
      const metricsList = state.newStartegyForm.tradingInstrument.equityBaskets.metricsList;
      if (payload) state.newStartegyForm = prepareEditForm(payload, metricsList);
    },
    // for testing data
    setTicker: (
      state,
      { payload }: PayloadAction<{ ticker: string; type: UnderlyingType | "basket" | "cryptos" }>
    ) => {
      state.newStartegyForm.ticker = {
        equities: payload.type === "equities" ? payload.ticker : null,
        etfs: payload.type === "etfs" ? payload.ticker : null,
        basket: null,
        index: null,
        cryptos: payload.type === "cryptos" ? payload.ticker : null,
      };
    },
    setType: (state, { payload }: PayloadAction<StrategyType>) => {
      state.newStartegyForm.type = payload;
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.name = payload;
      state.newStartegyForm.error = null;
    },
    setDate: (state, { payload }: PayloadAction<{ start: string | null; end: string | null }>) => {
      state.newStartegyForm.date = payload;
    },
    setErrors: (state, { payload }: PayloadAction<IConstructorErrors>) => {
      const { entryStatus, exitStatus } = payload;
      state.newStartegyForm.error = payload.name;
      state.newStartegyForm.conditions.entry.error = entryStatus;
      state.newStartegyForm.conditions.exit.error = exitStatus;
      if (entryStatus) state.newStartegyForm.activeCondition = "entry";
      if (exitStatus) state.newStartegyForm.activeCondition = "exit";
    },
    setIndicator: (
      state,
      {
        payload,
      }: PayloadAction<{
        fieldName: string;
        item: string;
      }>
    ) => {
      const { fieldName, item } = payload;
      const prop = fieldName as "category" | "indicator";
      state.newStartegyForm.conditions[activeCondition(state)][prop] = item;
      if (fieldName === "category")
        state.newStartegyForm.conditions[activeCondition(state)].indicator = null;
    },
    setActiveTab: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.activeCondition = payload as ConditionType;
    },
    clearAll: (state) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      state.newStartegyForm.conditions[activeCondition(state)] = clearConditionForm(condition);
      condition.formula = null;
      condition.error = null;
    },
    deleteOperator: (state, { payload }: PayloadAction<OperatorType>) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      const filteredList = condition.list?.filter((i) => i.index !== payload.index);
      if (filteredList) {
        filteredList.forEach((i, idx) => (i.index = idx));
        condition.list = filteredList;

        condition.formula = createFormula(filteredList);
        condition.error = null;
      }
    },
    updateOperator: (state, { payload }: PayloadAction<OperatorType & { isInserted: boolean }>) => {
      const list = state.newStartegyForm.conditions[activeCondition(state)].list;
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      if (list) {
        if (payload.isInserted) {
          list[payload.index] = payload;
        } else {
          const oldOperator = list[payload.index];
          list[payload.index] = payload;
          oldOperator.index = oldOperator.index + 1;
          list.splice(payload.index + 1, 0, oldOperator);
          list.forEach((operator, idx) => {
            if (idx > oldOperator.index) {
              operator.index = operator.index + 1;
            }
          });
        }

        if (payload.isNumber) list[payload.index].isNumber = false;
        if (payload.value === "Number") {
          list[payload.index].isNumber = true;
          list[payload.index].numValue = "";
        }

        condition.formula = createFormula(list);
        condition.error = null;
      }
      condition.list = list;
    },
    addOperator: (state, { payload }: PayloadAction<OperatorType>) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      const prevState = condition.list || [];
      const list = [...prevState, payload];
      condition.list = list;

      condition.formula = createFormula(list);
      condition.error = null;
      if (!payload.isOperator) {
        condition.indicator = null;
      }
    },
    setNumFieldValue: (state, { payload }: PayloadAction<{ value: string; index: number }>) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      const { index, value } = payload;
      const list = condition.list;
      if (list) {
        list[index].numValue = value;
        condition.formula = createFormula(list);
      }
    },
    setDirection: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.direction = payload as DirectionType;
    },
    slectTicker: (state, { payload }: PayloadAction<OperatorType>) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      const { category, value } = payload;
      condition.category = category || null;
      condition.indicator = value;
    },
    setInstrument: (state, { payload }: PayloadAction<TradingInstrumentType>) => {
      const condition = state.newStartegyForm.conditions[activeCondition(state)];
      state.newStartegyForm.tradingInstrument.type = payload;
      condition.category = "Technical";
      condition.indicator = null;
      state.newStartegyForm.tradingInstrument.equityBaskets = createDefaultEquityBaskets(
        state.newStartegyForm.tradingInstrument.equityBaskets
      );
    },
    setTradingBasket: (state, { payload }: PayloadAction<TradingBasketType>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.basket = payload;
      state.newStartegyForm.tradingInstrument.equityBaskets.tickers = [];
      state.newStartegyForm.tradingInstrument.equityBaskets.weightScheme = null;
    },
    setUpdateFrequency: (state, { payload }: PayloadAction<UpdateFrequencyType>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.updateFrequency = payload;
    },
    setRebalanceFrequency: (state, { payload }: PayloadAction<RebalanceFrequencyType>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.rebalanceFrequency = payload;
    },
    setWeightScheme: (state, { payload }: PayloadAction<WeightingScheme | null>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.weightScheme = payload;
    },
    setBasketTickers: (state, { payload }: PayloadAction<string[]>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.tickers = payload;
      state.newStartegyForm.tradingInstrument.equityBaskets.weightScheme = null;
    },
    setAdvancedFilter: (state, { payload }: PayloadAction<string | null>) => {
      let advancedFilter = state.newStartegyForm.tradingInstrument.equityBaskets.advancedFilter;
      if (advancedFilter) {
        if (payload) {
          advancedFilter = advancedFilter + " -> " + payload;
          state.newStartegyForm.tradingInstrument.equityBaskets.advancedFilter = advancedFilter;
        } else {
          state.newStartegyForm.tradingInstrument.equityBaskets.advancedFilter = null;
        }
      } else {
        state.newStartegyForm.tradingInstrument.equityBaskets.advancedFilter = payload;
      }
    },
    // for option type
    setOptionsType: (state, { payload }: PayloadAction<OptionType>) => {
      state.newStartegyForm.tradingInstrument.options.optionsType = payload;
      if (payload === "buy call" || payload === "buy put") {
        state.newStartegyForm.tradingInstrument.options.exitStyle = "entry-exit-signals";
        state.newStartegyForm.tradingInstrument.options.allocationStyle = "valueBased";
      } else {
        state.newStartegyForm.tradingInstrument.options.exitStyle = "hold-to-maturity";
        state.newStartegyForm.tradingInstrument.options.allocationStyle = "notional";
      }
    },
    setInput: (
      state,
      { payload }: PayloadAction<{ filedName: InputFieldNames; value: string }>
    ) => {
      state.newStartegyForm.tradingInstrument.options[payload.filedName].value = payload.value;
      const { minDays, maxDays, stopGain, stopLoss, ratio, percentage } =
        state.newStartegyForm.tradingInstrument.options;
      const validated = validateOptionInputs({
        minDays,
        maxDays,
        stopGain,
        stopLoss,
        ratio,
        percentage,
      });
      state.newStartegyForm.tradingInstrument.options = {
        ...state.newStartegyForm.tradingInstrument.options,
        ...validated,
      };
    },
    setStrikeRatio: (state, { payload }: PayloadAction<StrikeRatioType>) => {
      state.newStartegyForm.tradingInstrument.options.strikeRatioType = payload;
      state.newStartegyForm.tradingInstrument.options.ratio.value = "";
      state.newStartegyForm.tradingInstrument.options.ratio.error = "";
      state.newStartegyForm.tradingInstrument.options.percentage.value = "";
      state.newStartegyForm.tradingInstrument.options.percentage.error = "";
    },
    setAllocationStyle: (state, { payload }: PayloadAction<AllocationStyleType>) => {
      state.newStartegyForm.tradingInstrument.options.allocationStyle = payload;
    },
    setExitStyleType: (state, { payload }: PayloadAction<ExitStyleType>) => {
      state.newStartegyForm.tradingInstrument.options.exitStyle = payload;
    },
    setValidatedFields: (
      state,
      { payload }: PayloadAction<Record<InputFieldNames, IInputField>>
    ) => {
      state.newStartegyForm.tradingInstrument.options = {
        ...state.newStartegyForm.tradingInstrument.options,
        ...payload,
      };
    },
    setOptionTikcer: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.tradingInstrument.options.ticker = payload;
    },
    setBencmark: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.benchmark = payload || null;
    },
    setMaxAllowedWeight: (state, { payload }: PayloadAction<string>) => {
      state.newStartegyForm.tradingInstrument.equityBaskets.maxAllowedWeight = payload;
    },
  },
  extraReducers: (builder) => {
    // get strategies list
    builder
      .addCase(fetchStrategiesData.fulfilled, (state, { payload }) => {
        state.requested = true;
        if (payload) state.results = payload;
        else state.results = [];
        state.isLoading = false;
      })
      .addCase(fetchStrategiesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStrategiesData.rejected, (state, action) => {
        state.isLoading = false;
        state.results = [];
        console.error(action.error.message);
        notification.error(Errors.data.get);
      });

    // get strategy preview data performance
    builder
      .addCase(fetchStrategyPerformanceData.pending, (state) => {
        state.strategy = null;
        state.historical = null;
      })
      .addCase(fetchStrategyPerformanceData.fulfilled, (state, { payload }) => {
        if (Array.isArray(payload)) state.strategy = payload;
        else if (payload.equity) {
          state.strategy = preparePerformaceData(payload);
          state.historical = payload.positions || null;
        } else state.strategy = [];
      })
      .addCase(fetchStrategyPerformanceData.rejected, (state, { payload }) => {
        if (!Object.hasOwn(payload || {}, "isCanceled")) {
          notification.error(Errors.data.get);
        }
      });

    // creating new strategy
    builder
      .addCase(fetchCreateStrategy.pending, (state) => {
        state.constructorIsLoading = true;
      })
      .addCase(fetchCreateStrategy.fulfilled, (state, { payload }) => {
        state.constructorIsLoading = false;
        notification.success("New Strategy was successful created!");
        state.isNewStrategySvaed = true;
        const strategyName = encodeURIComponent(state.newStartegyForm.name || "");
        globalNavigate(`${ROUTES.strategiesBuilder.path}/edit/${payload.id}?name=${strategyName}`);
      })
      .addCase(fetchCreateStrategy.rejected, (state, { payload }) => {
        state.constructorIsLoading = false;
        if (payload === ErorrsType.exist) {
          state.newStartegyForm.error =
            "A strategy with this name already exists. Please try again with a new name.";
        } else {
          notification.error(Errors.create.startegy);
        }
      });

    // updating existing strategy
    builder
      .addCase(fetchUpdateStrategy.pending, (state) => {
        state.constructorIsLoading = true;
      })
      .addCase(fetchUpdateStrategy.fulfilled, (state, { payload }) => {
        state.constructorIsLoading = false;

        const strategyName = encodeURIComponent(state.newStartegyForm.name || "");
        globalNavigate(`${ROUTES.strategiesBuilder.path}/edit/${payload.id}?name=${strategyName}`);
        notification.success("Strategy was successful updated!");
      })
      .addCase(fetchUpdateStrategy.rejected, (state, { payload }) => {
        state.constructorIsLoading = false;
        notification.error(Errors.update.strategy);
        state.newStartegyForm.name = null;
      });

    // performance preview on preflight
    builder
      .addCase(fetchPerformancePrefligth.pending, (state) => {
        state.isPreviewLoading = true;
        state.strategy = null;
        state.historical = null;
      })
      .addCase(fetchPerformancePrefligth.fulfilled, (state, { payload }) => {
        state.isPreviewLoading = false;
        if (payload) {
          state.strategy = preparePerformaceData(payload);
          state.historical = payload.positions || null;
        } else state.strategy = [];
      })
      .addCase(fetchPerformancePrefligth.rejected, (state, { payload }) => {
        if (!Object.hasOwn(payload || {}, "isCanceled")) {
          notification.error(Errors.preview.strategy);
        }
        state.isPreviewLoading = false;
      });

    // get Metics list
    builder
      .addCase(fetchMetricsList.fulfilled, (state, { payload }) => {
        if (payload) state.newStartegyForm.tradingInstrument.equityBaskets.metricsList = payload;
      })
      .addCase(fetchMetricsList.rejected, (state, { payload }) => {
        //
      });

    // get Benchmark list
    builder
      .addCase(fetchBenchmarksList.fulfilled, (state, { payload }) => {
        if (payload) state.newStartegyForm.benchmarkList = payload;
      })
      .addCase(fetchBenchmarksList.rejected, (state, { payload }) => {
        state.newStartegyForm.benchmarkList = [];
      });
  },
});

const state = (state: RootState) => state;

export const strategiesState = createSelector(state, (state) => state.strategies);
export const selectStartegyForm = createSelector(
  state,
  (state) => state.strategies.newStartegyForm
);

export const {
  init,
  resetStrategyForm,
  setActiveStrategy,
  resetStrategy,
  setActiveTab,
  setTicker,
  slectTicker,
  setType,
  setName,
  setDate,
  clearAll,
  setDirection,
  setIndicator,
  deleteOperator,
  addOperator,
  updateOperator,

  setWeightScheme,
  setTradingBasket,
  setUpdateFrequency,
  setBasketTickers,
  setRebalanceFrequency,
  setAdvancedFilter,
  setMaxAllowedWeight,

  setInstrument,
  setErrors,
  setNumFieldValue,

  setBencmark,

  setInput,
  setOptionsType,
  setOptionTikcer,
  setStrikeRatio,
  setValidatedFields,

  setAllocationStyle,
  setExitStyleType,
} = strategiesSlice.actions;

export default strategiesSlice.reducer;
