import { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./Button.module.scss";

interface IProps {
  text: string;
  to: string;
  transparent?: boolean;
  active?: boolean;
  icon?: string;
  type?: "primary" | "secondary" | "white" | "gost";
  danger?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
  disable?: boolean;
}

export const ButtonLink: FC<IProps> = ({
  icon,
  text,
  to,
  transparent,
  active,
  danger,
  type = "primary",
  width,
  height,
  fontSize,
  disable,
}) => {
  return (
    <div className={styles.container}>
      <Link to={to} className={disable ? styles.disableLink : ""}>
        <button
          className={`${styles.button} ${danger ? styles.danger : styles[type]} ${
            transparent ? styles.transparent : ""
          } ${active ? styles.active : ""}
          `}
          style={{ width, height, fontSize }}
        >
          {!!icon && <img src={icon} alt="icon" />}
          <span>{text}</span>
        </button>
      </Link>
    </div>
  );
};
