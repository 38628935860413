import { IUserDataFilesResult, UserDataFileType } from "@/types";
import { ICSVReturn } from "@/types/warRoom";

import { mainApi } from "./main";

const requestType: Record<UserDataFileType, string> = {
  allocations: "/portfolio-allocations/",
  returns: "/return-csvs/",
};

export const userDataFilesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDataFiles: builder.query<IUserDataFilesResult, null>({
      query: (arg: unknown) => ({
        url: `/my-data`,
        method: "get",
      }),
      transformResponse: (response: { result: IUserDataFilesResult }, meta, arg) => response.result,
      providesTags: ["UserData"],
    }),

    deleteUserFile: builder.mutation<
      IUserDataFilesResult,
      { id: number | null; type: UserDataFileType | null }
    >({
      query: (data) => {
        const { id, type } = data;
        return {
          url: type === "returns" ? `/return-csvs/${id}` : `/portfolio-allocations/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: ["UserData", "AllocationData", "Returns"],
    }),

    getUserDataDetails: builder.query<string, { id: string; type: UserDataFileType }>({
      query: ({ id, type }) => ({
        url: `${requestType[type]}${id}/csv`,
        method: "get",
      }),
      transformResponse: (response: string, meta, arg) => response,
    }),

    getAllocationCSVsList: builder.query({
      query: (arg: unknown) => ({
        url: `/portfolio-allocations`,
        method: "get",
      }),
      transformResponse: (response: { result: ICSVReturn[] | null }, meta, arg) => response.result,
      providesTags: ["AllocationData"],
    }),

    getReturnCSVsList: builder.query({
      query: (arg: unknown) => ({
        url: `/return-csvs`,
        method: "get",
      }),
      transformResponse: (response: { result: ICSVReturn[] | null }, meta, arg) => response.result,
      providesTags: ["Returns"],
    }),
  }),
});

export const {
  useGetUserDataFilesQuery,
  useDeleteUserFileMutation,
  useGetUserDataDetailsQuery,
  useGetAllocationCSVsListQuery,
  useGetReturnCSVsListQuery,
} = userDataFilesApi;

export const invalidateAllocationData = () =>
  userDataFilesApi.util.invalidateTags(["AllocationData", "UserData"]);

export const invalidateReturnData = () =>
  userDataFilesApi.util.invalidateTags(["Returns", "UserData"]);
