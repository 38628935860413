import { FC, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@/components";
import { convertedWithUrlLinks, replaceWithEmoji } from "@/utils";

import styles from "./LinksListObj.module.scss";

interface ILinksListProps {
  text: string | null;
}

export const LinksListObj: FC<ILinksListProps> = ({ text }) => {
  const [showAll, setShowAll] = useState(false);
  const [count, setCount] = useState(0);
  const itemRef = useRef<HTMLPreElement | null>(null);

  const formattedMessage = useMemo(() => {
    let result = replaceWithEmoji(text);

    if (!showAll) {
      try {
        const matches = result.match(/(http[s]:\/\/)|(wwww\.)/gi);
        if (matches) {
          const startIndex = result.indexOf(matches[0]);
          const endStrMatch = result.slice(startIndex).match(/(\s)|(\\n)/gi);
          const count = endStrMatch ? result.slice(startIndex).indexOf(endStrMatch[0]) : -1;

          result = result.slice(0, startIndex + count);
        }
      } catch {
        console.log("Parsing links list error!");
      }
    }

    const [converted] = convertedWithUrlLinks(result);
    return converted;
  }, [text, showAll]);

  const toggleHandler = () => {
    setShowAll((prev) => !prev);
  };

  useEffect(() => {
    if (itemRef.current) {
      const count = itemRef.current.querySelectorAll("a").length;
      console.log("C:", count);
      setCount(count);
    }
  }, [formattedMessage]);

  return (
    <div className={styles.container}>
      <pre
        className={styles.inner}
        dangerouslySetInnerHTML={{ __html: formattedMessage }}
        ref={itemRef}
      ></pre>
      {(count > 1 || !showAll) && (
        <Button
          text={showAll ? "Show Less" : "Show More"}
          size="small"
          type="secondary"
          onClick={toggleHandler}
        />
      )}
    </div>
  );
};
