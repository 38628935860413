import { useCallback, useMemo } from "react";

import { ItemType } from "@/components";
import {
  allocationStyleList,
  exitStyleList,
  indexTickers,
  optionsType,
  strikeRatioList,
} from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  setInput,
  setOptionsType,
  setStrikeRatio,
  setOptionTikcer,
  strategiesState,
  setAllocationStyle,
  setExitStyleType,
} from "@/store/strategies/strategies";
import {
  AllocationStyleType,
  ExitStyleType,
  ITickersKeyValueData,
  InputFieldNames,
  OptionType,
  StrikeRatioType,
} from "@/types";
import { findDropdownItem } from "@/utils";

export const useOptionsForm = (tickersList: ITickersKeyValueData) => {
  const dispatch = useAppDispatch();
  const {
    newStartegyForm: {
      tradingInstrument: { options, type },
    },
  } = useAppSelector(strategiesState);

  const selectOptionsHandler = (name: string, item: ItemType<OptionType>) => {
    dispatch(setOptionsType(item.key));
  };

  const selectStrikeRatioHandler = (name: string, item: ItemType<StrikeRatioType>) => {
    dispatch(setStrikeRatio(item.key));
  };

  const numberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    dispatch(setInput({ filedName: name as InputFieldNames, value }));
  };

  const selectTickerHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setOptionTikcer(item.value));
  }, []);

  const selectExitStyleHandle = (name: string, item: ItemType<ExitStyleType>) => {
    dispatch(setExitStyleType(item.key));
  };

  const selectAllocationStyleHandle = (name: string, item: ItemType<AllocationStyleType>) => {
    dispatch(setAllocationStyle(item.key));
  };

  const blurHandler = () => {
    //
  };

  const tradingTickers = useMemo(() => {
    return [...tickersList.equities, ...tickersList.etfs, ...indexTickers].sort((a, b) =>
      a.value.localeCompare(b.value)
    );
  }, [tickersList]);

  const selectedTicker = useMemo(
    () => findDropdownItem(options.ticker, tradingTickers || []),
    [options.ticker, tradingTickers]
  );

  const strikeRatio = strikeRatioList.find((i) => i.key === options.strikeRatioType) || null;
  const activeAllocationStyle =
    allocationStyleList.find((i) => i.key === options.allocationStyle) || null;
  const activeExitStyle = exitStyleList.find((i) => i.key === options.exitStyle) || null;

  const option = optionsType.find((i) => i.key === options.optionsType) || null;
  const isPercentageShow =
    strikeRatio &&
    (strikeRatio.key === "XX percentage ITM" || strikeRatio.key === "XX percentage OTM");
  const isRatioShow =
    strikeRatio && (strikeRatio.key === "XX STDEV ITM" || strikeRatio?.key === "XX STDEV OTM");

  return {
    selectedTicker,
    strikeRatio,
    option,
    isPercentageShow,
    isRatioShow,
    tradingInstrumentOptions: options,
    tradingTickers,

    selectOptionsHandler,
    selectStrikeRatioHandler,
    numberChangeHandler,
    selectTickerHandler,
    blurHandler,

    selectAllocationStyleHandle,
    selectExitStyleHandle,
    activeAllocationStyle,
    activeExitStyle,
  };
};
