import { FC, memo, useEffect, useMemo, useRef } from "react";

import { ChartEquities, ChartForecast, Heading } from "@/components";
import { useInViewportElement, useAppDispatch, useAppSelector } from "@/hooks";
import { fetchForecastTable, macroTableState } from "@/store/forecast/forecastTable";
import {
  checkDataMacroInfo,
  getPlotBands,
  getSeriesDataCategory,
  getSeriesExtraDataCategory,
} from "@/utils";

import styles from "./Charts.module.scss";

interface IProps {
  chartData: any;
  command?: string;
  title: string | null;
  height?: number;
}

type ChartDataType = { data: any[]; dates: string[] };

const DEFAULT_CHART_HEIGHT = 190;
const WITH_TABS_CHART_HEIGHT = 175;

export const MacroChart: FC<IProps> = memo(({ chartData, title, command, height = 300 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isInViewport = useInViewportElement(ref, false);

  const [macroInfo, isValid] = useMemo(() => checkDataMacroInfo(command || null), [command]);

  const tabs = useMemo(() => {
    return macroInfo?.isExtraData && chartData?.values
      ? (Object.keys(chartData.values).filter((tab) => tab !== "_meta") as string[])
      : null;
  }, [chartData, macroInfo]);

  const chart = useMemo((): ChartDataType => {
    if (isValid && macroInfo && chartData.values) {
      if (macroInfo.isExtraData && tabs) {
        return getSeriesExtraDataCategory(chartData.values || {}, {
          selectedGroup: tabs[0],
        });
      } else {
        return getSeriesDataCategory(chartData?.values || {}, { defaultSelected: false });
      }
    }
    return { data: [], dates: [] };
  }, [tabs]);

  const tableData = useAppSelector(macroTableState);
  const table = tableData.data?.find(({ meta }) => meta.indicator === macroInfo?.indicator) || null;

  useEffect(() => {
    if (macroInfo?.indicator && macroInfo.indicator === "recession_prob") {
      dispatch(fetchForecastTable(macroInfo.indicator));
    }
  }, [macroInfo?.indicator, dispatch]);

  return (
    <div className={styles.container} ref={ref}>
      <Heading className={styles.heading} type="h5">
        <span>MACRO:</span>
        {chartData?.meta?.title}
      </Heading>
      <div className={styles.content}>
        {macroInfo?.isExtraData && (
          <div className={styles.labels}>
            {tabs?.map((tab, idx) => (
              <span key={tab} className={`${idx === 0 ? styles.selected : ""}`}>
                {tab}
              </span>
            ))}
          </div>
        )}
        {isInViewport ? (
          <ChartForecast
            title={title}
            data={chart.data}
            height={tabs && tabs.length ? WITH_TABS_CHART_HEIGHT : DEFAULT_CHART_HEIGHT}
            plotBands={getPlotBands(table, ["recession_prob"])}
            isChartPreviw
          />
        ) : (
          <div
            style={{
              height: `${macroInfo?.isExtraData ? height : DEFAULT_CHART_HEIGHT}px`,
              width: `${macroInfo?.isExtraData ? height : DEFAULT_CHART_HEIGHT}px`,
            }}
          ></div>
        )}
      </div>
    </div>
  );
});
