import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: "default",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    init: (state) => {
      //
    },
  },
});

export const { init } = loginSlice.actions;

export default loginSlice.reducer;
