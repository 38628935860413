import { ComponentWithPermissions } from "@/components";

const macroPlannerLoader = () =>
  import("./MacroPlannerRoot").then(({ MacroPlannerRoot }) => ({
    default: MacroPlannerRoot,
  }));

export const MacroPlannerPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={macroPlannerLoader}
      title="Macro Planner"
      pageKey={"MacroPlanner-MultiAsset"}
    />
  );
};
