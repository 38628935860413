import { ChartTypeEnum } from "@/types";

const defaultStartDate = new Date();
const defaultEndDate = new Date();
defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 4);

export const ITEM_CONFIG = [
  {
    id: 1,
    title: "Market Spider",
    type: ChartTypeEnum.SPIDER,
    dropdown: null,
    link: "market-spider",
    requestType: "GET",
    hasDateRange: false,
    defaultRageSelect: null,
    dropdownLabel: null,
  },
  // {
  //   id: 2,
  //   title: "Forecasted S&P 500 Move",
  //   type: ChartTypeEnum.TABLE,
  //   dropdown: null,
  //   link: "closest-market-matches",
  //   requestType: "POST",
  //   hasDateRange: false,
  //   defaultRageSelect: null,
  //   dropdownLabel: null,
  //   hasTable: true,
  // },
  {
    id: 3,
    title: "",
    type: ChartTypeEnum.LINE,
    dropdown: {
      index: [
        "S&P 500",
        "Nasdaq 100",
        "Dow 30",
        "Information Technology Sector",
        "Energy Sector",
        "Real Estate Sector",
        "Consumer Staples Sector",
        "Financials Sector",
        "Communication Services Sector",
        "Health Care Sector",
        "Industrials Sector",
        "Consumer Discretionary Sector",
        "Materials",
        "Utilities Sector",
      ],
      title: "Valuation by",
      metric: ["Price-to-Earnings", "Price-to-Sales", "Price-to-EBITDA"],
    },
    dropdownWidth: {
      index: 110,
      metric: 110,
    },
    link: "valuations",
    requestType: "POST",
    hasDateRange: false,
    defaultRageSelect: [defaultStartDate, defaultEndDate],
    dropdownLabel: null,
  },
  {
    id: 4,
    title: "",
    type: ChartTypeEnum.LINE,
    link: "market-put-call-ratio-trend",
    requestType: "GET",
    hasDateRange: false,
    defaultRageSelect: null,
    dropdown: {
      title: "Put/Call Ratio Trend",
      ticker: [
        "*",
        "compare",
        "SPY",
        "XLK",
        "XLE",
        "XLRE",
        "XLP",
        "XLF",
        "XLC",
        "XLI",
        "XLU",
        "XLY",
        "XLV",
        "XLB",
      ],
    },
    dropdownLabel: {
      "*": "Total Market",
      compare: "Compare",
      SPY: "SPY ETF",
      XLK: "Technology sector",
      XLE: "Energy Sector",
      XLRE: "Real Estate Sector",
      XLP: "Consumer Staples Sector",
      XLF: "Financials Sector",
      XLC: "Consumer Services Sector",
      XLI: "Industrials Sector",
      XLU: "Utilities Sector",
      XLY: "Consumer Discretionary Sector",
      XLV: "Health Care Sector",
      XLB: "Materials Sector",
    },
    dropdownWidth: {
      ticker: 120,
    },
  },
  {
    id: 5,
    title: "",
    type: ChartTypeEnum.COLUMN,
    dropdown: {
      side: ["Best", "Worst"],
      title: "Performing",
      index: ["Large Cap", "Mid Cap", "Small Cap", "Sectors"],
      period: ["Today", "7 days", "30 Days", "90 Days", "YTD", "3 Years"],
    },
    dropdownWidth: {
      side: 75,
      index: 75,
      period: 72,
    },
    link: "best-worst-performers",
    requestType: "POST",
    hasDateRange: false,
    defaultRageSelect: null,
    dropdownLabel: null,
  },
  {
    id: 6,
    title: "",
    type: ChartTypeEnum.LINE,
    dropdown: {
      title: "Nominal Consumer Spending",
      type: ["Total", "Ex-Gas", "Ex-Auto/Gas"],
    },
    dropdownWidth: {
      type: 100,
    },
    dropdownLabel: null,
    link: "nominal-consumer-spending",
    requestType: "GET",
    hasDateRange: false,
    defaultRageSelect: null,
  },
];
