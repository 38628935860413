import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Button, InputItem, LoaderWrapper, ModalLayout } from "@/components";
import { Heading } from "@/components/Heading/Heading";
import { useAppDispatch, useAppSelector, useOuterClick } from "@/hooks";
import { createFolder, foldersManageState, updateFolder } from "@/store/focus/foldersManage";
import { IFolder } from "@/types/focus";

import styles from "./FolderModal.module.scss";

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  folderId?: number | undefined;
  list: IFolder[];
  isNew: boolean;
  handleSetNew: (e: boolean) => void;
}

export const FolderModal: FC<IProps> = ({
  showModal,
  setShowModal,
  folderId,
  list,
  isNew,
  handleSetNew,
}) => {
  const [inputKeyword, setInputKeyword] = useState<string>("");
  const { isLoading } = useAppSelector(foldersManageState);

  const dispatch = useAppDispatch();

  const handleSave = () => {
    if (!inputKeyword) return;
    if (isNew) {
      dispatch(createFolder(inputKeyword));
    } else {
      if (folderId) {
        dispatch(updateFolder({ id: folderId, name: inputKeyword }));
      }
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleSetNew(false);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setShowModal(false);
        handleSetNew(false);
      }, 500);
      return;
    }
    if (list?.length) {
      const found = list.find((folder) => folder.id === folderId)?.name || "";
      if (found) {
        setInputKeyword(found);
      }
    }
    if (isNew) {
      setInputKeyword("");
    }
  }, [folderId, handleSetNew, isLoading, isNew, list, setShowModal]);

  return (
    <ModalLayout show={showModal} onClose={() => setShowModal(false)}>
      <div className={styles.container}>
        <Heading type="h4">{isNew ? "Create New Folder" : "Rename Folder"}</Heading>
        <div className={styles.close} onClick={handleCloseModal} />
        <div className={styles.content}>
          <InputItem
            onChange={(e) => setInputKeyword(e.target.value)}
            label=""
            name=""
            type="input"
            placeholder="Type folder name"
            value={inputKeyword}
          />
          <div className={styles.button}>
            <LoaderWrapper loading={isLoading}>
              <Button
                disabled={!inputKeyword}
                onClick={handleSave}
                text={isNew ? "Create" : "Update"}
              />
            </LoaderWrapper>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
