import { FC, useCallback, useEffect, useState } from "react";

import { DateObj, DatePickerRange, Heading, InputItem } from "@/components";
import { CompanyCalendarTable } from "@/features/DataPortal/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { companyCalendarState, getCompanyCalendar } from "@/store/dataBrowser/companyCalendar";
import { ICompanyCalendar } from "@/types";
import { getDate, getDateISOString } from "@/utils";

import { useSearch } from "../../hooks/useSearch";
import { CompanyFilter } from "../CompanyFilter/CompanyFilter";

import styles from "./CompanyCalendar.module.scss";

import { ReactComponent as EarningsCalendarIcon } from "@images/earnings-calendar-icon.svg";

interface IDate {
  start: string;
  end: string;
}

const SEARCH_FIELD_KEYS: Array<keyof ICompanyCalendar> = ["ticker", "company_name"];

const date = getDate();

interface IProps {
  data?: any;
}

export const CompanyCalendar: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(companyCalendarState);

  const [etfHoldings, setEtfHoldings] = useState<string | undefined>();
  const { search, filteredData, searchHandler } = useSearch<ICompanyCalendar>(
    data,
    SEARCH_FIELD_KEYS
  );

  const [CompanyCalendarRange, setCompanyCalendarRange] = useState<IDate>(() => ({
    start: date.currentDate,
    end: date.futureDate,
  }));

  const handleSelectCompanyCalendarRange = useCallback((range: DateObj) => {
    setCompanyCalendarRange({
      start: getDateISOString(range.start),
      end: getDateISOString(range.end),
    });
  }, []);

  useEffect(() => {
    dispatch(
      getCompanyCalendar({
        start: CompanyCalendarRange.start,
        end: CompanyCalendarRange.end,
        index: etfHoldings,
      })
    );
  }, [CompanyCalendarRange, dispatch, etfHoldings]);

  return (
    <section className={styles.section}>
      <div className={styles.headingGroup}>
        <EarningsCalendarIcon />
        <Heading type={"h3"}>Earnings calendar</Heading>
      </div>
      <div className={styles.innerWrapper}>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => searchHandler(ev.target.value)}
            value={search}
            label="Search Ticker or Company"
            name="search"
            type="search"
            placeholder="Ticker, Company"
            noPaddingBottom
            disabled={isLoading}
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.picker}>
            <DatePickerRange
              label="Select Date interval"
              onSelect={handleSelectCompanyCalendarRange}
              date={CompanyCalendarRange}
              MaxDateDisabled
            />
          </div>
          <CompanyFilter etfHoldings={etfHoldings} setEtfHoldings={setEtfHoldings} />
        </div>
        <CompanyCalendarTable
          CompanyCalendarData={search ? filteredData : data}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
};
