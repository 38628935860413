import dayjs from "dayjs";
import { Dispatch, FC, memo, SetStateAction, useCallback, useMemo, useState } from "react";

import { ExtendedColumns, Pagination, Table, TooltipWrapper } from "@/components";
import { DATE_ISO } from "@/constants";
import { ICompany } from "@/types/company";

import styles from "./CompanyTable.module.scss";

interface IProps {
  companyData: ICompany[];
  isLoading: boolean;
  setSelected: Dispatch<SetStateAction<ICompany | null>>;
}

export const CompanyTable: FC<IProps> = memo(({ companyData, setSelected }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);
  const paginatedList = useMemo(
    () =>
      companyData ? companyData.slice((page - 1) * perPage, (page - 1) * perPage + perPage) : [],
    [page, companyData, perPage]
  );
  const totalPages = companyData ? Math.ceil(companyData.length / perPage) : null;
  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "name",
        canSort: true,
        disableSortBy: true,
        minWidth: 270,
        canExpand: true,
        Cell: ({ value }: object & { value: string | null }) => (
          <span className={styles.companyName}>{value}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "is_active",
        canSort: true,
        isTruncated: true,
        minWidth: 140,
        Cell: ({ value }: object & { value: number | null }) => (
          <span className={value ? styles.statusActive : styles.statusInactive}>
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
      {
        Header: "Create Date",
        accessor: "created_at",
        canSort: true,
        isTruncated: true,
        minWidth: 180,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{dayjs(value).format(DATE_ISO)}</span>,
      },
      {
        Header: "AuM",
        accessor: "aum",
        canSort: true,
        isTruncated: true,
        minWidth: 120,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? value : " - "}</span>,
      },
      {
        Header: "Strategy",
        accessor: "strategy",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? value : " - "}</span>,
      },
      {
        Header: "Type of Client",
        accessor: "type",
        canSort: true,
        isTruncated: true,
        minWidth: 200,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? value : " - "}</span>,
      },
      {
        Header: "Location",
        accessor: "location",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? value : " - "}</span>,
      },
      {
        Header: "Comment",
        accessor: "comment",
        canSort: true,
        isTruncated: true,
        minWidth: 350,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => (
          <TooltipWrapper text={value ? value : "No comment added yet."}>
            <p className={styles.comment}>{value ? value : " - "}</p>
          </TooltipWrapper>
        ),
      },
    ],
    []
  );

  const handleSelect = (row: any) => {
    setSelected(row as ICompany);
  };

  return (
    <div className={styles.container}>
      <Table<ICompany> columns={columns} data={paginatedList} onSelect={handleSelect} />
      <div className={styles.pagination}>
        <Pagination
          onPage={setPage}
          onChangePerPage={selectPerPageHandler}
          perPage={perPage}
          currentPage={page}
          totalCount={totalPages}
          count={companyData?.length}
          perPageList={[10, 20, 50, 100]}
          countLabel="Total items"
        />
      </div>
    </div>
  );
});
