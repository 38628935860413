import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { updateItemInFolder } from "@/store/focus/folderSelected";
import {
  fetchMessagesListFocus,
  setIdToUpdate,
  updateOdinChartsState,
} from "@/store/focus/updateOdinCharts";
import { profileInfo } from "@/store/profile";

import { notification } from "../../../../../utils/notification";

export const UPDATE_MSG_FREQUENCY = 60000; // milliseconds
export const UPDATE_FAST_FREQUENCY = 5000; // milliseconds

export const useUpdateChart = () => {
  const { list, idToUpdate } = useAppSelector(updateOdinChartsState);
  const { uid } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!idToUpdate?.hash) return;
    let timerId: NodeJS.Timer | null = null;
    const watch = (updateDelay: number) => {
      timerId = setInterval(() => {
        if (uid) {
          dispatch(fetchMessagesListFocus(`focus-${uid}`));
        }
      }, updateDelay);
    };

    if (location.pathname !== ROUTES.home.path) {
      watch(UPDATE_MSG_FREQUENCY);
    } else {
      watch(UPDATE_FAST_FREQUENCY);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [uid, location, dispatch, idToUpdate, list]);

  const inProgress = useMemo(
    () => list?.filter((msg) => ["In progress", "New"].includes(msg.status)),
    [list]
  );

  useEffect(() => {
    const found = list?.find((message) => {
      if (!message.hash) return false;
      return idToUpdate?.hash === message.hash;
    });
    if (found) {
      const item = idToUpdate?.hash === found.hash;
      if (!item || !idToUpdate?.savedDataId) return;
      dispatch(
        updateItemInFolder({
          id: idToUpdate.savedDataId,
          data: found.id.toString(),
          hash: idToUpdate.hash,
        })
      );
      dispatch(setIdToUpdate(null));
    } else if (!inProgress?.length && idToUpdate) {
      notification.warning(
        "It seems that the requested data is no longer available. Please check OdinChat and replace it with new data manually."
      );
      dispatch(setIdToUpdate(null));
    }
  }, [dispatch, list, idToUpdate, inProgress?.length]);

  return { list };
};
