import { FC } from "react";

import { InfoHeading } from "@/components";

import { EditUserData } from "./components/EditUserData/EditUserData";
import { UserInfo } from "./components/UserInfo/UserInfo";

import styles from "./UserProfile.module.scss";

export const UserProfile: FC = () => {
  return (
    <div className={styles.container}>
      <InfoHeading>User Profile</InfoHeading>
      <div className={styles.userInfoSection}>
        <UserInfo />
      </div>
      <div className={styles.editDataSection}>
        <EditUserData />
      </div>
    </div>
  );
};
