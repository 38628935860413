import { FC, useEffect, useMemo } from "react";

import { Heading, Menu, OdinChart } from "@/components";
import { GaugeChart } from "@/components/Chart/GaugeChart";
import { useAppDispatch, useAppSelector } from "@/hooks";
import NestIcon from "@/images/nest.svg";
import UpdateIcon from "@/images/update.svg";
import { deleteItemInFolder } from "@/store/focus/folderSelected";
import {
  fetchUserMessageFocus,
  setIdToUpdate,
  setRequested,
  updateOdinChartsState,
} from "@/store/focus/updateOdinCharts";
import { IChatMessage } from "@/types/odinChat";
import {
  getListToUpdate,
  notification,
  parseTextAsObject,
  prepareChartData,
  setListToUpdate,
} from "@/utils";

import { TableChatObj } from "../../../Tables/OdinChatTable/OdinChatTable";

import styles from "./OdinChatDetilsView.module.scss";

interface IProps {
  message: IChatMessage;
  savedDataId: number | undefined;
  commandId: string;
  closeModal: () => void;
}

export const OdinChatDetilsView: FC<IProps> = ({ message, savedDataId, commandId, closeModal }) => {
  const rawData = parseTextAsObject(message?.text || "");
  const chart = useMemo(() => (rawData ? prepareChartData(rawData) : null), [rawData]);
  const dispatch = useAppDispatch();
  const { list } = useAppSelector(updateOdinChartsState);

  const menuItems = [
    {
      icon: UpdateIcon,
      title: "Update",
      key: "1",
    },
    // {
    //   icon: NestIcon,
    //   title: "Delete",
    //   key: "2",
    // },
  ];

  if (!chart) return null;

  const handleSelectedAction = (key: string | number) => {
    if (!savedDataId || !message.hash) return;
    if ("1" === key) {
      if (!message.parent?.text) return;
      dispatch(setRequested());
      notification.success("Update starting...");
      const hasFocusInId = message.parent?.from_phone.includes("focus");
      dispatch(
        fetchUserMessageFocus({
          text: message.parent?.text.trim(),
          user: hasFocusInId ? message.parent?.from_phone : `focus-${message.parent?.from_phone}`,
        })
      );

      dispatch(
        setIdToUpdate({
          hash: message.hash,
          savedDataId,
        })
      );
      closeModal();
    }
    if ("2" === key) {
      closeModal();
      dispatch(deleteItemInFolder(savedDataId));
    }
  };

  return (
    <div>
      <div className={styles.head}>
        <Heading type="h3">{rawData?.table ? rawData?.table?.title : chart.title}</Heading>
        <Menu menuItems={menuItems} handelMenuSelect={handleSelectedAction}>
          <div className={styles.menuInner}>
            <span>Chart Actions</span>
          </div>
        </Menu>
      </div>
      {rawData?.table ? (
        <TableChatObj data={rawData.table} />
      ) : (
        <>
          {chart.gaugeData ? (
            <GaugeChart gaugeData={chart.gaugeData} title={chart.title} />
          ) : (
            <OdinChart chartData={chart} height={480} />
          )}
        </>
      )}
    </div>
  );
};
