import React, { FC, ForwardRefExoticComponent, RefAttributes } from "react";

import styles from "./InfoHelpComponent.module.scss";

interface IProps {
  title: string;
  textList: string[];
  image: string;
  alt: string;
  maxImageWidth?: number;
}

export const InfoHelpComponent = React.forwardRef<HTMLDivElement | null, IProps>(
  ({ title, textList, image, alt, maxImageWidth }, itemRef) => {
    return (
      <div className={styles.infoContainer} ref={itemRef}>
        <h3 className={styles.title}>{title}</h3>
        <div>
          {textList.map((text) => (
            <p className={styles.text} key={text}>
              {text}
            </p>
          ))}
        </div>
        <img src={image} alt={alt} style={{ maxWidth: maxImageWidth }} />
      </div>
    );
  }
);
