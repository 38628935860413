import { useCallback, useEffect, useMemo, useState } from "react";

import { ItemType } from "@/components";
import { customDesiredAssets, customGroupsList } from "@/constants/warRoom";
import { useAppDispatch } from "@/hooks";
import { invalidateReturnData, useGetReturnCSVsListQuery } from "@/services/apiQuery/userData";
import { AssetItem, AssetRecordType } from "@/types/warRoom";
import { notification } from "@/utils";
import { canCalculateAllocation, hasCSVFile, removeCSVFiles, uploadCSV } from "@/utils/warRoom";

export const useCustomAssets = (
  confirmCB: (assets: Record<string, AssetRecordType> | null, csvFilesIDs?: number[]) => void,
  isCustom?: boolean
) => {
  const dispatch = useAppDispatch();
  const { data, isFetching: isCSVListLoading } = useGetReturnCSVsListQuery(null);

  const [progress, setProgress] = useState(false);
  const [desiredGroup, setDesiredGroup] = useState<ItemType<string>>(
    customGroupsList.at(0) as ItemType<string>
  );

  const [customAssets, setCustomAssets] =
    useState<Record<string, AssetRecordType>>(customDesiredAssets);

  const [selectedCSVs, setSelectedCSVs] = useState<ItemType<string>[] | null>(null);

  const selectAssetsHandler = useCallback(
    (name: string, items: ItemType<string>[]) => {
      if (desiredGroup) {
        const group = Object.entries(customAssets[desiredGroup.key]);

        const getKey = (key: string) => {
          const [foundKey] = group.find(([_, value]) => value.key === key) || [];
          return foundKey;
        };

        const reset = (list: AssetRecordType) => {
          return Object.entries(list).reduce((assets, [key, value]) => {
            assets[key] = { ...value, checked: false };
            return assets;
          }, {} as AssetRecordType);
        };

        setCustomAssets((prev) => ({
          ...prev,
          [desiredGroup.key]: {
            ...prev[desiredGroup.key],
            ...reset(prev[desiredGroup.key]),
            ...items.reduce((assets, asset) => {
              const key = getKey(asset.key);
              const groupKey = desiredGroup.key;

              if (key) assets[key] = { ...prev[groupKey][key], checked: true };
              return assets;
            }, {} as AssetRecordType),
          },
        }));
      }
    },
    [customAssets, desiredGroup]
  );

  const removeAssetHandler = useCallback(
    (item: AssetItem, group: string) => {
      const groups = Object.values(customAssets)
        .map((group) => Object.entries(group))
        .flat();
      const [foundKey] = groups.find(([_, value]) => value.key === item.key) || [];
      if (foundKey) {
        setCustomAssets((prev) => ({
          ...prev,
          [group]: {
            ...prev[group],
            [foundKey]: { ...prev[group][foundKey], checked: false },
          },
        }));
      }
    },
    [customAssets]
  );

  const removeFileHandler = useCallback((fileItem: ItemType<string>) => {
    setSelectedCSVs((prev) => prev?.filter((file) => file.key !== fileItem.key) || null);
  }, []);

  const assetsGroupList = useMemo(() => {
    return Object.values(customAssets[desiredGroup.key]).map(({ key, label: value }) => ({
      key,
      value,
    }));
  }, [customAssets, desiredGroup]);

  const selectDesiredGroupHandler = (name: string, item: ItemType<string>) => {
    setDesiredGroup(item);
  };

  const currentSelectedGroup = useMemo(() => {
    return customAssets[desiredGroup.key] || {};
  }, [customAssets, desiredGroup]);

  const selectedAssets = useMemo(() => {
    return Object.values(customAssets[desiredGroup.key])
      .filter((asset) => asset.checked)
      .map(({ key, label: value }) => ({
        key,
        value,
      }));
  }, [customAssets, desiredGroup]);

  const csvList = useMemo(() => {
    return (
      (data?.map((csv) => ({
        key: `${csv.id}`,
        value: csv.name,
      })) as ItemType<string>[]) || []
    );
  }, [data]);

  const selectCSVHandler = (name: string, item: ItemType<string>[]) => {
    setSelectedCSVs(item);
  };

  const uploadCSVHandler = (files: FileList | null) => {
    const upload = async () => {
      const file = files ? files[0] : null;
      if (!hasCSVFile(csvList, file)) {
        const fileData = await uploadCSV(file);
        if (fileData) dispatch(invalidateReturnData());
      } else {
        notification.warning(
          "CSV File with this name already exist! Please, rename file and try again."
        );
      }
      setProgress(false);
    };

    setProgress(true);
    upload();
  };

  const removeCSVHandler = async () => {
    const remove = async () => {
      if (selectedCSVs && selectedCSVs.length === 1) {
        const result = await removeCSVFiles(selectedCSVs[0]);
        if (result) dispatch(invalidateReturnData());
        setProgress(false);
        setSelectedCSVs(null);
      }
    };

    setProgress(true);
    remove();
  };

  const confirmFindAllocation = () => {
    const csvs = selectedCSVs?.map((csv) => +csv.key) || [];
    if (isCustom) confirmCB(customAssets, csvs);
    else confirmCB(null);
  };

  const canDelete = !!(selectedCSVs && selectedCSVs.length === 1);
  const deletingFileName = (selectedCSVs && !!selectedCSVs.length && selectedCSVs[0].value) || null;
  const canCalculate = canCalculateAllocation(customAssets, isCustom);

  useEffect(() => {
    if (!isCustom) {
      setSelectedCSVs([]);
    }
  }, [isCustom]);

  return {
    selectDesiredGroupHandler,
    confirmFindAllocation,
    selectCSVHandler,
    uploadCSVHandler,
    removeCSVHandler,

    setCustomAssets,
    assetsGroupList,
    selectedAssets,
    selectAssetsHandler,
    removeAssetHandler,
    removeFileHandler,

    isCSVListLoading: progress || isCSVListLoading,
    currentSelectedGroup,
    deletingFileName,
    desiredGroup,
    customAssets,
    selectedCSVs,
    canCalculate,
    canDelete,
    csvList,
  };
};
