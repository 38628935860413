import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { ForecastQueryType, IForecastEquities } from "@/types/forecast";
import { filterObjectsByFields } from "@/utils";
import { notification } from "@/utils/notification";

import { ForecastService } from "../../services/forecastService";
import { RootState } from "../index";

interface IEquitiesState {
  data: IForecastEquities[];
  rawData: IForecastEquities[];
  hidden_columns: string[];
  isLoading: boolean;
}

const initialState: IEquitiesState = {
  data: [],
  rawData: [],
  hidden_columns: [],
  isLoading: false,
};

export const getForecastEquities = createAsyncThunk(
  "forecast/getEquities",
  async (query: ForecastQueryType) => {
    const response = await ForecastService.getEquities(query);
    return response.data;
  }
);

const equitiesSlice = createSlice({
  name: "forecast/equities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getForecastEquities.fulfilled, (state, action) => {
        state.data = filterObjectsByFields(
          action.payload.result,
          action.payload?._meta?.hidden_columns
        );
        state.rawData = action.payload.result;
        state.hidden_columns = action.payload?._meta?.hidden_columns;
        state.isLoading = false;
      })
      .addCase(getForecastEquities.pending, (state) => {
        state.isLoading = true;
        state.data = [];
      })
      .addCase(getForecastEquities.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const equitiesState = createSelector(state, (state) => state.forecastEquities);

export default equitiesSlice.reducer;
