import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { odinChatState, unmarkNewUserMessage } from "@/store/odinchat";
import { IMessageItem } from "@/types/odinChat";

import { ITEM_DATA_NAME } from "./useMessagesObserevr";

interface IScrollStatus {
  isAutoScroll: boolean;
  isMouseScroll: boolean;
  prevLoadingState: boolean;
  scrollTopHeight: number;
}

export const useScrollmessages = (list: IMessageItem[][], preloader: boolean, loading: boolean) => {
  const dispatch = useAppDispatch();
  const listRef = useRef<HTMLDivElement | null>(null);

  const scrollStatus = useRef<IScrollStatus>({
    isAutoScroll: false,
    isMouseScroll: false,
    prevLoadingState: false,
    scrollTopHeight: 0,
  });
  const listLenRef = useRef(0);
  const { unreaded, isStoped } = useAppSelector(odinChatState);

  // autoscroll to new message or fist render scroll
  useEffect(() => {
    const { isAutoScroll } = scrollStatus.current;
    const isUpdated = scrollStatus.current.prevLoadingState && !loading;

    if (loading) scrollStatus.current.prevLoadingState = true;

    if (list.flat().length > 0 && listLenRef.current === 0 && isUpdated) {
      // fist render
      // scroll auto
      setTimeout(() => {
        if (unreaded && unreaded.length > 0) {
          const id = unreaded[0];
          const item = document.querySelector(`[${ITEM_DATA_NAME}="${id}"]`);
          item?.scrollIntoView({ block: "center", behavior: "auto" });
        } else {
          listRef.current?.scrollTo({
            top: listRef.current?.scrollHeight,
            behavior: "auto",
          });
        }
        listLenRef.current = list.flat().length;
      }, 0);
    } else if (list.flat().length > 0 && listLenRef.current > 0 && isUpdated && isAutoScroll) {
      setTimeout(() => {
        listRef.current?.scrollTo({
          top: listRef.current?.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    }

    // scroll when user send new message
    if (list.length && loading === false) {
      const fullList = list.flat();

      if (fullList.find((m) => m.isNewUserMessage)) {
        dispatch(unmarkNewUserMessage());
        listRef.current?.scrollTo({
          top: listRef.current?.scrollHeight,
          behavior: "auto",
        });
        scrollStatus.current.isAutoScroll = true;
        scrollStatus.current.isMouseScroll = false;
      }
    }
  }, [list, loading, unreaded]);

  useEffect(() => {
    if (isStoped && list.flat().length) {
      setTimeout(() => {
        listRef.current?.scrollTo({
          top: listRef.current?.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [isStoped, list]);

  const disableScrollHandler = (ev: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = ev.currentTarget;

    const prevScrollTop = scrollStatus.current.scrollTopHeight;

    if (prevScrollTop - scrollTop > 0) {
      scrollStatus.current.isAutoScroll = false;
    }

    scrollStatus.current.scrollTopHeight = scrollTop;
  };

  return { disableScrollHandler, listRef, isAutoScroll: scrollStatus.current.isAutoScroll };
};
