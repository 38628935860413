import { Loader } from "./Loader";

import styles from "./FallBackLoader.module.scss";

export const FallBackLoader = () => {
  return (
    <div className={styles.container}>
      <Loader />
    </div>
  );
};
