import { FC } from "react";

import { CustomIndicatorConstructor } from "@/features";
import { ConstructorMode } from "@/types";

import styles from "./CustomIndicator.module.scss";

interface IProps {
  mode: ConstructorMode;
}

export const CustomIndicator: FC<IProps> = ({ mode }) => {
  return (
    <div className={styles.container}>
      <CustomIndicatorConstructor mode={mode} />
    </div>
  );
};
