import { FC, Fragment } from "react";

import { Button, ButtonLink, DeniedUserDialog, InputItem, Loader } from "@/components";
import { ROUTES } from "@/constants";

import { useForm } from "./hooks/useForm";

import styles from "./UserLoginForm.module.scss";

const formInit: Record<string, { value: string; error: string }> = {
  login: {
    value: "",
    error: "",
  },
  password: {
    value: "",
    error: "",
  },
};

export const UserLoginForm: FC = () => {
  const { form, showPass, loading, isUserDeactivated, submitHandler, changeHandler, resetForm } =
    useForm(formInit);
  const disabled =
    (!form.password.value && showPass) ||
    (!form.login.value && !showPass) ||
    !!form.password.error ||
    !!form.login.error;

  return (
    <div className={styles.container}>
      {/* <Logo link="/" white /> */}
      <div className={styles.loaderContainer}>{loading && <Loader white />}</div>

      {isUserDeactivated ? (
        <Fragment>
          <DeniedUserDialog />
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.formContainer}>
            <p className={styles.title}>Sign in</p>
            <form onSubmit={submitHandler} className={styles.form}>
              <div className={styles.formInnerWrapper}>
                <div className={`${styles.slideGroup}  ${showPass ? styles.showPass : ""}`}>
                  <div className={styles.inputWrapper}>
                    <InputItem
                      label=""
                      placeholder="email@company.com"
                      name="login"
                      type="email"
                      onChange={changeHandler}
                      error={form.login.error}
                      value={form.login.value}
                      disabled={loading}
                      autofocus
                      themed
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <InputItem
                      label=""
                      placeholder="password"
                      name="password"
                      type="password"
                      onChange={changeHandler}
                      error={form.password.error}
                      value={form.password.value}
                      autofocus={showPass}
                      disabled={loading}
                      themed
                    />
                  </div>
                </div>
              </div>
              <Button text="Submit" disabled={disabled || loading} type="white" />
            </form>
            {showPass ? (
              <button className={styles.resetButton} onClick={resetForm} disabled={loading}>
                Go Back
              </button>
            ) : null}
            <ButtonLink
              to={ROUTES.forgotPassword.path}
              text="Forgot password?"
              disable={loading}
              transparent
              active
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
