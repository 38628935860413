import { FC } from "react";

import { FooterNavigation } from "@/components";
import { UserForgotPassword } from "@/features";

export const ForgotPassword: FC = () => {
  return (
    <>
      <UserForgotPassword />
      <FooterNavigation />
    </>
  );
};
