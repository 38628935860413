import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { GoBack, Heading } from "@/components";
import { useAppDispatch } from "@/hooks";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import { resetSearchResult } from "@/store/discover/discoverSearch";
import { IDrsSerachResult } from "@/types/discover";

import styles from "./SearchResult.module.scss";

interface IProps {
  setSearchResult: (drs: IDrsSerachResult[]) => void;
  searchResult: IDrsSerachResult[];
}

export const SearchResult: FC<IProps> = ({ setSearchResult, searchResult }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleBack = () => {
    setSearchResult([]);
    dispatch(resetSearchResult());
  };

  const handleClick = (drs: IDrsSerachResult) => () => {
    navigate(`details/${drs.drs_id}`);
  };

  return (
    <div className={styles.container}>
      <Heading type="h3" className={styles.subHeading}>
        Search Result
      </Heading>
      <GoBack onClick={handleBack} />
      <div className={styles.sourcesAvailable}>
        <span>{`${searchResult.length} sources available`}</span>
      </div>
      <ul className={styles.list}>
        {searchResult.map((source) => (
          <li key={source.drs_id} onClick={handleClick(source)}>
            <div className={styles.nameGroupe}>
              <span
                className={styles.directory}
                dangerouslySetInnerHTML={{ __html: `${source.category}/${source.sub_category}` }}
              />
              <span className={styles.name} dangerouslySetInnerHTML={{ __html: source.name }} />
            </div>
            <div className={styles.tagsArrowContainer}>
              <div className={styles.arrow}>
                <Arrow />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
