import { FC, useEffect, useMemo, useState } from "react";

import { ChartForecast, LoaderWrapper } from "@/components";
import { Heading } from "@/components/Heading/Heading";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchForecastTable, macroTableState } from "@/store/forecast/forecastTable";
import { IForcastChart, IForecastExtraChart } from "@/types/forecast";
import {
  checkDataMacroInfo,
  getPlotBands,
  getSeriesDataCategory,
  getSeriesExtraDataCategory,
} from "@/utils";

import { useGetMacroChart } from "../../../../hooks/useGetMacroChart";

import styles from "./MacroDetailsView.module.scss";

interface IProps {
  command: string | null;
}

type ChartDataType = { data: any[]; dates: string[] };

export const MacroDetailsView: FC<IProps> = ({ command }) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [macroInfo, isValid] = useMemo(() => checkDataMacroInfo(command || null), [command]);

  const { isLoading, data } = useGetMacroChart(macroInfo?.indicator || null);

  const tabs = useMemo(() => {
    return macroInfo?.isExtraData && data?.values
      ? (Object.keys(data.values).filter((tab) => tab !== "_meta") as string[])
      : null;
  }, [data, macroInfo]);

  const chart = useMemo((): ChartDataType => {
    if (isValid && macroInfo && data?.values) {
      if (macroInfo.isExtraData && tabs) {
        return getSeriesExtraDataCategory((data.values as IForecastExtraChart["values"]) || {}, {
          selectedGroup: activeTab ? activeTab : tabs[0],
        });
      } else {
        return getSeriesDataCategory((data.values as IForcastChart["values"]) || {}, {
          defaultSelected: false,
        });
      }
    }
    return { data: [], dates: [] };
  }, [tabs, activeTab, data]);

  const selectTabHandler = (tab: string) => {
    setActiveTab(tab);
  };

  const tableData = useAppSelector(macroTableState);
  const table = tableData.data?.find(({ meta }) => meta.indicator === macroInfo?.indicator) || null;

  useEffect(() => {
    if (macroInfo?.indicator && macroInfo.indicator === "recession_prob") {
      dispatch(fetchForecastTable(macroInfo.indicator));
    }
  }, [macroInfo?.indicator, dispatch]);

  useEffect(() => {
    if (tabs) setActiveTab(tabs[0]);
  }, [tabs]);

  return (
    <LoaderWrapper loading={isLoading} atom fullWidth animatedHeight>
      <Heading type="h3">MACRO: {data?.meta.title}</Heading>
      <div className={styles.content}>
        {macroInfo?.isExtraData && (
          <div className={styles.tabs}>
            {tabs?.map((tab) => (
              <span
                key={tab}
                className={`${styles.tab} ${tab === activeTab ? styles.active : ""}`}
                onClick={() => selectTabHandler(tab)}
              >
                {tab}
              </span>
            ))}
          </div>
        )}
        <ChartForecast
          title={macroInfo?.isExtraData ? activeTab : data?.meta.title}
          data={chart.data}
          plotBands={getPlotBands(table, ["recession_prob"])}
          height={macroInfo?.isExtraData ? 400 : 450}
        />
      </div>
    </LoaderWrapper>
  );
};
