import React, { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, TouchTransition, MouseTransition } from "react-dnd-multi-backend";
import withScrolling from "react-dnd-scrolling";
import { TouchBackend } from "react-dnd-touch-backend";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Loader } from "@/components/Loader/Loader";
import { WelcomeVideGuide } from "@/components/WelcomeVideoGuide/WelcomeVideoGuide";
import { PREVENT_SCROLL } from "@/constants";
import { Header, SideBar } from "@/features";
import { useAppSelector, usePageView } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { selectIsOpen } from "@/store/menu";
import { profileInfo } from "@/store/profile";

import styles from "./ProtectedLayout.module.scss";

// * PDF Reporting
// import { PDFProgress } from "@/components/PDFProgress/PDFProgress";
// import { ForecastPDF } from "@/features/ForecastPDF/ForecastPDF";

interface IPprops {
  children: React.ReactNode;
}

export const ProtectedLayout: FC<IPprops> = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  const isMenuFullWidth = useSelector(selectIsOpen);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const location = useLocation();
  const { profile } = useAppSelector(profileInfo);

  usePageView(children);

  const toggleHandler = useCallback((state?: boolean) => {
    if (state !== undefined) setToggle(state);
    else setToggle((prev) => !prev);
  }, []);

  const selectMenuHandler = useCallback(() => {
    setToggle(false);
  }, []);

  useEffect(() => {
    if (!location.search?.includes(`${PREVENT_SCROLL}=`)) {
      const scrollContainer = document.querySelector("#main-scroll-layout");
      setTimeout(() => scrollContainer?.scrollTo({ top: 0, behavior: "auto" }), 10);
    }
  }, [location]);

  const showContent = !!profile?.id;
  const showVideoGuide = process.env.NODE_ENV === "production";

  return (
    <div className={styles.wrapper}>
      <Header onMenuToggle={toggleHandler} toggle={toggle} />
      <Fragment>
        {showContent ? (
          <Fragment>
            {isMobile ? (
              <div className={`${styles.sidebarWrapper} ${toggle ? styles.show : ""}`}>
                <SideBar isMobile={isMobile} onSelect={selectMenuHandler} />
              </div>
            ) : (
              <SideBar isMobile={isMobile} onSelect={selectMenuHandler} />
            )}
            {showVideoGuide ? (
              <WelcomeVideGuide isMobile={isMobile} isMenuFullWidth={isMenuFullWidth} />
            ) : null}
            <DnDContainer isMenuFullWidth={isMenuFullWidth} isMobile={isMobile}>
              {children}
            </DnDContainer>
          </Fragment>
        ) : (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
      </Fragment>

      {/* --- PDF Reporting --- */}
      {/* <PDFProgress />
      <ForecastPDF /> */}
    </div>
  );
};

interface IDnDContainerProps {
  isMenuFullWidth: boolean;
  isMobile: boolean;
  children: React.ReactNode;
}

const DnDContainer: FC<IDnDContainerProps> = memo(({ isMenuFullWidth, isMobile, children }) => {
  const ScrollingComponent = useMemo(() => withScrolling("div"), []);
  const HTML5toTouch = useMemo(
    () => ({
      backends: [
        {
          id: "html5",
          backend: HTML5Backend,
          transition: MouseTransition,
        },
        {
          id: "touch",
          backend: TouchBackend,
          options: { enableMouseEvents: true },
          preview: true,
          transition: TouchTransition,
        },
      ],
    }),
    []
  );

  return (
    <div
      className={`${styles.main} ${isMenuFullWidth || isMobile ? styles.fullWidth : ""} ${
        isMobile ? styles.mobileView : ""
      }`}
    >
      <DndProvider options={HTML5toTouch}>
        <ScrollingComponent className={styles.scrollContainer} id="main-scroll-layout">
          <div className={styles.container}>{children}</div>
        </ScrollingComponent>
      </DndProvider>
    </div>
  );
});
