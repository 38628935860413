import { FC } from "react";

import { Dropdown } from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { ITickersKeyValueData } from "@/types";

import { useCryptosForm } from "../../hooks/useCryptosForm";

import styles from "./CryptosForm.module.scss";

interface IProps {
  tradingTickersList: ITickersKeyValueData;
  isLoading: boolean;
}

export const CryptosForm: FC<IProps> = ({ tradingTickersList, isLoading }) => {
  const {
    cryptosTickersList,
    selectTickerHandler,
    selectedTicker,
    disableOptions,
    selectDirectionHandler,
    directionList,
    selectedDirection,
  } = useCryptosForm(tradingTickersList);

  return (
    <div className={`${styles.row} ${styles.noSpace}`}>
      <div className={styles.itemWrapper}>
        <DropdownSearch<"ticker">
          list={cryptosTickersList}
          onSelect={selectTickerHandler}
          selected={selectedTicker}
          placeholder="Search Ticker Name"
          name="ticker"
          label="Crypto Ticker"
          disable={disableOptions || cryptosTickersList.length === 0}
          isRequired={true}
          loading={isLoading}
          isUpperCase
        />
      </div>
      <div className={styles.itemWrapper}>
        <Dropdown
          list={directionList}
          onSelect={selectDirectionHandler}
          selected={selectedDirection}
          placeholder="Select Strategy Direction"
          name="direction"
          label="Strategy Direction"
          disable={disableOptions}
        />
      </div>
      <div className={`${styles.itemWrapper} ${styles.empty}`}></div>
    </div>
  );
};
