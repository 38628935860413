import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserService } from "@/services";
import { IUserLearnMoreRequest } from "@/types";
import { notification, validate } from "@/utils";

type FieldsType = "firstName" | "lastName" | "email" | "company";

const formInit: Record<FieldsType, { value: string; error: string | null }> = {
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  company: {
    value: "",
    error: "",
  },
};

const viewPDF = (blob: any, name: string | null) => {
  const blobData = new Blob([blob], { type: "application/pdf" });
  const href = URL.createObjectURL(blobData);

  window.open(href, "_blank");
};

export const useLearnMoreForm = (accessType: string | null, docId: string) => {
  const [form, setForm] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const learnMoreRequest = async (
    userData: IUserLearnMoreRequest,
    id: string,
    name: string | null
  ) => {
    try {
      const response = await UserService.learnMoreRequest(userData, id);
      if (response.data.type === "application/json") {
        const text = await response.data.text();
        const dataObject = JSON.parse(text) as { message: string };
        setMessage(dataObject.message);
      } else {
        viewPDF(response.data, name);
        setForm((prev) => ({ ...prev, email: { ...prev.email, value: "" } }));
      }
    } catch (err) {
      notification.error("Sorry, something went wrong during request! Please try later.", true);
      console.log("Error!", err);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    navigate("/");
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { firstName, lastName, company, email } = form;
    const [validatedForm, isValid] = validate(form);
    if (isValid) {
      setLoading(true);
      learnMoreRequest(
        {
          email: email.value.trim(),
          first_name: firstName.value.trim(),
          last_name: lastName.value.trim(),
          company: company.value.trim(),
        },
        docId,
        accessType
      );
    } else {
      setForm(validatedForm);
    }
  };

  const changeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = ev.target.value;
      const fieldKey = ev.target.name;
      setForm((prev) => ({
        ...prev,
        [fieldKey]: { error: null, value: inputValue },
      }));
    },
    []
  );

  const canRequest = !Object.keys(form).some((key) => {
    return form[key as FieldsType].error || !form[key as FieldsType].value;
  });

  return { form, loading, canRequest, message, submitHandler, changeHandler, onClose };
};
