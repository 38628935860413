import { FC } from "react";

import { Button } from "@components/Button/Button";

import styles from "./Dialog.module.scss";

import { ReactComponent as ErrorIcon } from "@images/error.svg";
import { ReactComponent as SuccessIcon } from "@images/success.svg";

interface IProps {
  title: string;
  subtitle?: string;
  messages?: string[];
  type: "success" | "error";
  onSubmit?: () => void;
  buttonText?: string;
  noAction?: boolean;
}

export const Dialog: FC<IProps> = ({
  title,
  messages,
  subtitle,
  onSubmit,
  type,
  buttonText,
  noAction,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.textContainer}>
        {type === "success" && (
          <p className={styles.subtitle}>{subtitle || "Operation successful completed!"}</p>
        )}
        {type === "error" && (
          <p className={styles.subtitleError}>{subtitle || "Operation failed!"}</p>
        )}
        {messages?.map((message) => (
          <p className={styles.message} key={message}>
            {message}
          </p>
        ))}
      </div>
      <div className={styles.iconContainer}>
        {type === "success" && <SuccessIcon />}
        {type === "error" && <ErrorIcon />}
      </div>
      {type !== "error" && !noAction && !!onSubmit ? (
        <div className={styles.buttonWrapper}>
          {" "}
          <Button onClick={onSubmit} text={buttonText || "Continue"} type="black" />
        </div>
      ) : null}
      {type === "error" && !noAction && !!onSubmit ? (
        <div className={styles.buttonWrapper}>
          <Button onClick={onSubmit} text={buttonText || "Close"} type="black" />
        </div>
      ) : null}
    </div>
  );
};
