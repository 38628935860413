import dayjs from "dayjs";
import Highcharts, { AxisCrosshairOptions, SeriesOptionsType } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useRef, useState } from "react";

import { COLORS, DATE_ISO_US_SHORT_YEAR } from "@/constants";
import { IExpectedReturn } from "@/types";

import { createForecastZone, exportingOptions, extraLabelsFormatter } from "./utils";
import { Button } from "../Button/Button";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

const SERIE_TYPE = "spline";

interface LineChartProps {
  data: { name: string; data: IExpectedReturn[] | null };
  height: number;
}

export const ChartClosestMarket: FC<LineChartProps> = ({ data, height = 400 }) => {
  const chartComponent = useRef<HighchartsReact.RefObject>(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const crossChair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };

  const createSireData = (chartData: IExpectedReturn[] | null) => {
    if (!chartData) return [];
    const mappedData = chartData.map(({ Value, date, Low, High, "Weighted Median": WM }) => {
      if (Value === null && Low !== null && High !== null) {
        const parsedValue = Number.parseFloat(WM || "");
        return [date, Number.isNaN(parsedValue) ? null : parsedValue];
      }

      return [date, Value];
    });
    return mappedData;
  };

  const createAreaSireData = (chartData: IExpectedReturn[] | null) => {
    if (chartData) {
      const filteredWithNoValue = chartData?.filter(({ Value }, idx) => Value === null);

      const startIndex = filteredWithNoValue ? chartData.length - filteredWithNoValue.length : 0;
      const firstRangeValue = chartData[startIndex - 1].Value || null;

      const mappedData = filteredWithNoValue?.map(
        ({ "Weighted 90-Percentile": High, "Weighted 10-Percentile": Low }, idx) => ({
          low: Number.parseFloat(Low || ""),
          high: Number.parseFloat(High || ""),
          x: idx + startIndex,
        })
      );

      if (firstRangeValue !== null) {
        mappedData.unshift({
          low: chartData[startIndex - 1].Value || 0,
          high: chartData[startIndex - 1].Value || 0,
          x: startIndex - 1,
        });
      }
      return mappedData;
    }

    return [];
  };

  const createCategories = (chartData: IExpectedReturn[] | null): string[] => {
    return chartData?.map(({ date }) => date) || [];
  };

  const getAreaStartIndex = (chartData: IExpectedReturn[] | null): number => {
    return (
      chartData?.reduce((index, { Value, Low, High }, idx) => {
        if (Low && High && Value === null && index === 0) index = idx + 1;
        return index;
      }, 0) || 0
    );
  };

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      valueSuffix: "",
      valuePrefix: "",
      useHTML: true,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    chart: {
      zooming: {
        type: "x",
      },
      type: "line",
      marginTop: 35,
      events: {
        selection(e) {
          if (e.resetSelection) {
            setIsZoomed(false);
          } else {
            setIsZoomed(true);
          }
          return true;
        },
      },
      style: {
        fontFamily: "Inter",
      },
      height,
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "separator",
            "downloadCSV",
          ],
        },
      },
      ...exportingOptions(),
    },
    colors: COLORS,

    yAxis: {
      title: {
        text: "",
      },
      crosshair: crossChair,
      gridLineWidth: 0,
      lineWidth: 1,
      labels: {
        style: {
          fontWeight: "500",
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "",
      },
      type: "category",
      categories: [],
      crosshair: crossChair,
      labels: {
        formatter(): string {
          const date = this.value;
          return dayjs(date).format(DATE_ISO_US_SHORT_YEAR);
        },
        // style: {
        //   fontSize: "10px",
        //   // opacity: 0.5,
        // },
      },
    },
    plotOptions: {
      line: {
        lineWidth: 1.2,
      },
    },
  });

  const resetZoom = () => {
    if (chartComponent && chartComponent.current) {
      chartComponent.current.chart.zoomOut();
    }
  };

  useEffect(() => {
    setChartOptions((state) => ({
      ...state,
      tooltip: {
        ...state.tooltip,
        formatter: extraLabelsFormatter(data.data, data.name || ""),
      },
      xAxis: {
        ...state.xAxis,
        categories: createCategories(data.data),
      },
      series: [
        {
          name: "CM",
          type: "areasplinerange",
          data: createAreaSireData(data.data),
          marker: {
            enabled: false,
          },
          lineWidth: 1,
          color: "var(--secondary-hover)",
        },
        {
          name: data.name,
          data: createSireData(data.data),
          type: SERIE_TYPE,
          marker: {
            enabled: false,
          },
          lineWidth: 1,
          ...createForecastZone(getAreaStartIndex(data.data), "zone"),
        },
      ] as SeriesOptionsType[],
    }));
  }, [data]);

  return (
    <div className={styles.chartContainer} style={{ height }}>
      <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={chartOptions} />
      <div className={styles.zoomButton}>
        {isZoomed && <Button text="Reset Zoom" type="secondary" onClick={resetZoom} size="small" />}
      </div>
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
