import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Loader, LoaderWrapperCustom, TooltipWrapper } from "@/components";
import { TabsEnum, queryKeys } from "@/constants";
import { AdminPanelService } from "@/services";
import { IAdminPanelDocuments } from "@/types/adminPanel";

import styles from "./Documents.module.scss";

import { ReactComponent as PDFdocIcon } from "@images/pdf-document.svg";

interface IProps {
  list: IAdminPanelDocuments[] | null;
  isLoading: boolean;
  tab: TabsEnum;
}

export const getPDF = async (name: string, id: number) => {
  try {
    const { data } = await AdminPanelService.getDocument(id);
    const blobUrl = URL.createObjectURL(data);
    window.open(`${blobUrl}#toolbar=0&filename=${name}`)?.focus();
    return true;
  } catch (e) {
    console.warn("Can`t download files...");
    return false;
  }
};

export const Documents: FC<IProps> = ({ list, isLoading, tab }) => {
  const [active, setActive] = useState<string | null>(null);
  const [search, setSearch] = useSearchParams();
  const queryName = search.get("name");

  const activeHandler = (name: string, id: string) => {
    const queryID = search.get("id");
    if (queryName && queryID && name === queryName) {
      getPDF(name, Number(queryID)).finally(() => {
        setActive(null);
      });
    } else {
      setSearch({ target: queryKeys[tab], id, name });
    }
    setActive(name);
  };

  useEffect(() => {
    const previewPDFDoc = async (name: string, id: number) => {
      getPDF(name, id).finally(() => {
        setActive(null);
      });
    };

    if (queryName) {
      const queryID = search.get("id");
      setActive(queryName);
      previewPDFDoc(queryName, Number(queryID));
    }
  }, [queryName]);

  return (
    <div className={styles.container}>
      <div className={styles.hederRow}>
        <span>Type</span>
        <span>Name</span>
      </div>
      <LoaderWrapperCustom loading={isLoading} atom>
        <div className={styles.listContainer}>
          {(list || []).map((item) => (
            <DocumentItem
              name={item.key}
              key={item.key}
              id={item.id}
              active={active}
              onActive={activeHandler}
            />
          ))}
        </div>
      </LoaderWrapperCustom>
    </div>
  );
};

interface IDocumentItemProps {
  name: string;
  active: string | null;
  id?: number;
  onActive: (name: string, id: string) => void;
}

export const DocumentItem: FC<IDocumentItemProps> = ({ name, active, id, onActive }) => {
  const itemName = name.replaceAll("_", " ");
  const isDisabled = active === name;

  const clickHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (!active) onActive(name, id?.toString() || "#");
  };

  return (
    <a
      className={`${styles.itemRow} ${active ? styles.disabled : ""}`}
      href={`resources/target=overview&name=${name}&id=${id}`}
      target="_blank"
      rel="noreferrer"
      onClick={clickHandler}
    >
      <TooltipWrapper text={itemName}>
        <div className={`${styles.col} ${isDisabled ? styles.active : ""}`}>{<PDFdocIcon />}</div>
        <div className={`${styles.col} ${isDisabled ? styles.active : ""}`}>
          <p className={styles.title}>{itemName}</p>
          {isDisabled && <Loader />}
        </div>
      </TooltipWrapper>
    </a>
  );
};
