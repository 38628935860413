import { ItemType } from "@/components";

import { TradingInstrumentType } from "./strategies";

export type ConstructorInputType = "function" | "instrument";
export type CustomIndicatorType = "equities" | "etfs" | "baskets" | "indices";
export type IndicatorsType = "technical" | "fundamental" | "economic" | "event";
export enum ConstructorMode {
  edit = "edit",
  create = "create",
}
export type CustomBasketIndicatorType =
  | "sp500"
  | "nasdaq"
  | "small-cap"
  | "mid-cap"
  | "custom-basket";

export interface IOperator {
  operator: string;
  input_arguments: string[];
  default_values: Record<string, string>;
  description: Record<string, string>;
  operator_description?: string;
}

export interface IConstructorForm {
  inputType: ConstructorInputType | null;
  functionItem: IOperator | null;
  instrumentType: TradingInstrumentType | null;
  ticker: string | null;
  name: string | null;
  error: string | null;
  description: string | null;
  descriptionError: string | null;
  basket: ItemType<string>[] | null;
}

export interface IMetaData {
  constructor: string | null;
  fileName?: string;
}

export interface ISignalItem {
  date: string;
  ticker: string;
  signal: number;
}

export interface IIndicatorData {
  type: IndicatorsType;
  formula: string[] | null;
  name: string;
  description: string | null;
  updated_at?: string;
  created_at?: string;
  id?: number;
  user_id?: number;
  meta?: IMetaData;
  internal_type?: "signal";
  signal?: ISignalItem[];
}

export interface INewIndicatorData {
  name: string;
  description: string | null;
  type: IndicatorsType;
  formula: string[] | null;
  meta: {
    constructor: string | null;
  };
}

export enum ErorrsType {
  exist = "exist",
  someError = "some",
}

export type FormErrorFieldsType = { name: string | null; description: string | null };
