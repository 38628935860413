import { useEffect, useRef } from "react";

const A_INCLUDES_B_FLAG = 20;
const PROPERTY_NAME = "data-exclude-id";

export const useOuterClick = <P>(callback: (p: P) => void, param: P, excludeDataId?: string) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const mouseClickHandler = (event: MouseEvent) => {
      const element = event.target as Node;
      const compareResult = itemRef.current && itemRef.current.compareDocumentPosition(element);
      const isOuterClick =
        element && compareResult && event.target && compareResult !== A_INCLUDES_B_FLAG;

      // excluding action with excluded id
      const excludeItem = document.querySelector(`[${PROPERTY_NAME}="${excludeDataId}"]`);

      if (excludeItem) {
        const compareExcludeItem = excludeItem.compareDocumentPosition(element);
        if (compareExcludeItem && compareExcludeItem === A_INCLUDES_B_FLAG) return;
      }

      if (isOuterClick) {
        callback(param);
      }
    };

    window.addEventListener("mouseup", mouseClickHandler);

    return () => {
      window.removeEventListener("mouseup", mouseClickHandler);
    };
  }, [callback, param]);

  return [itemRef];
};
