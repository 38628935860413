import dayjs from "dayjs";

import { DATE_ISO_TIME } from "@/constants";

export const getDateISOString = (date: Date | string | null): string => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  return "";
};

export const getDate = (): { futureDate: string; currentDate: string } => {
  const currentDate = dayjs();
  const futureDate = currentDate.add(30, "days");
  return {
    futureDate: futureDate.format("YYYY-MM-DD"),
    currentDate: currentDate.format("YYYY-MM-DD"),
  };
};

export const getFormattedDate = (date: Date | string): string => {
  if (date) {
    return dayjs(date).format(DATE_ISO_TIME);
  }
  return "";
};
