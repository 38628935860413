import Highcharts, { Chart as HighchartsChart } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { FC, memo, useEffect, useMemo, useRef, useState } from "react";

import { Button, Heading, Loader } from "@/components";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { etfIntelligenceState, fetchChartData } from "@/store/forecast/forecastEtfIntelligence";
import { IEtfCHartData } from "@/types";
import { getETFChartOptions } from "@/utils";

import { NoData } from "../NoData/NoData";

import styles from "./EtfChart.module.scss";

Highcharts.Chart.prototype.showResetZoom = function showResetButton() {
  //
};

interface IProps {
  chartData: IEtfCHartData;
}

export const EtfChart: FC<IProps> = memo(({ chartData }) => {
  const dispatch = useAppDispatch();
  const { requestStr: request, category } = useAppSelector(etfIntelligenceState);
  const [showZoom, setShowZoom] = useState(true);
  const isRequested = useRef<string | null>(null);
  const [isInViewport, invewportItemRef] = useInViewportElement(false);
  const instance = useRef<HighchartsChart | null>(null);

  const noData = !chartData.data?.values.length;

  const chartTitle = useMemo(() => {
    return chartData.meta.name?.replace("{category}", category);
  }, [category, chartData]);

  useEffect(() => {
    if (isRequested.current !== request && isInViewport && !chartData.data) {
      chartData.meta.url &&
        dispatch(
          fetchChartData({
            query: chartData.meta.url.replace("{category}", category),
            id: chartData.meta.id,
          })
        );
      isRequested.current = request;
    }
  }, [chartData, category, isInViewport, request]);

  const chartOptions = useMemo(() => {
    return getETFChartOptions({ meta: chartData.meta, data: chartData.data }, setShowZoom);
  }, [chartData]);

  const resetZoomHandler = () => {
    if (instance.current) instance.current.zoomOut();
    setShowZoom(false);
  };

  const chartCallback = (chart: HighchartsChart) => {
    const points = chart.series[0].points;
    if (points.length < 20) return;
    const min = points[0].x;
    const max = points[50].x;
    chart.xAxis[0].setExtremes(min, max);
    instance.current = chart;
  };

  return (
    <div className={styles.container} ref={invewportItemRef}>
      {chartData.data ? (
        <div className={styles.inner}>
          {!noData && (
            <div className={styles.header}>
              <Heading className={styles.heading} type="h3">
                {chartTitle}
              </Heading>
              {showZoom && (
                <Button
                  text="Reset Zoom"
                  type="secondary"
                  onClick={resetZoomHandler}
                  size="small"
                />
              )}
            </div>
          )}

          {/* <Error message={chartData.error}> */}
          <NoData show={noData}>
            {chartData.data && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                callback={chartCallback}
                containerProps={{
                  style: {
                    height: "100%",
                    width: "100%",
                    minWidth: 200,
                  },
                }}
              />
            )}
          </NoData>
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          {chartData.error ? (
            <div className={styles.errorContainer}>
              <p>Ooops! Something went wrong during loading chart.</p>
              <p>Try to reload page.</p>
            </div>
          ) : (
            <Loader helpText="Loading chart..." />
          )}
        </div>
      )}
    </div>
  );
});
