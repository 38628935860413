import { FC, useMemo } from "react";

import { DiscoverChart, LoaderWrapper } from "@/components";
import { IResult } from "@/types";
import { getPrepSeriesData } from "@/utils";

interface IProps {
  results: IResult[] | (string | number)[][];
  isLoading?: boolean;
  height: number;
  toggleButtonEnambled?: boolean;
  isChartPreviw?: boolean;
}

export const Chart: FC<IProps> = ({
  results,
  isLoading = false,
  height = 500,
  toggleButtonEnambled = true,
  isChartPreviw,
}) => {
  const chart = useMemo(() => {
    try {
      return getPrepSeriesData(results);
    } catch (error) {
      console.error(error);
      return { data: [] };
    }
  }, [results]);

  return (
    <LoaderWrapper loading={isLoading} atom fullWidth animatedHeight>
      <DiscoverChart
        toggleButtonEnambled={toggleButtonEnambled}
        data={chart.data}
        height={height}
        isChartPreviw={isChartPreviw}
      />
    </LoaderWrapper>
  );
};
