import { FC, useEffect, useMemo, useRef, useState } from "react";

import { ExtendedColumns, Table } from "@/components";
import { Skeleton } from "@/components/Skeleton/Skeleton";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchETFTableData, etfIntelligenceState } from "@/store/forecast/forecastEtfIntelligence";
import { IETFTableItemMeta } from "@/types";

import { CellItem } from "./CellItem";

import styles from "./ETFTable.module.scss";

export const ETFTable: FC = () => {
  const dispatch = useAppDispatch();
  const { requestStr: request, etfTable, category } = useAppSelector(etfIntelligenceState);
  const tableData = etfTable[category];
  const [isInViewport, invewportItemRef] = useInViewportElement(false);
  const isRequested = useRef<string | null>(null);
  const [show, setShow] = useState(false);

  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "ETF Name",
        accessor: "symbol",
        canSort: false,
        minWidth: 180,
      },
      {
        Header: "Expense Ratio",
        accessor: "Expense ratio",
        disableSortBy: true,
        minWidth: 140,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "AUM ($M)",
        accessor: "Aum",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "Dividend Yield",
        accessor: "Dividend yield",
        disableSortBy: true,
        minWidth: 140,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "1M Return",
        accessor: "1m return",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "3M Return",
        accessor: "3m return",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "YTD Return",
        accessor: "Ytd return",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "1Y Return",
        accessor: "1y return",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "20D Vol",
        accessor: "20d volatility",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "50D Vol",
        accessor: "50d volatility",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
      {
        Header: "200D Vol",
        accessor: "200d volatility",
        disableSortBy: true,
        minWidth: 120,
        Cell: ({ value }: object & { value: IETFTableItemMeta }) => (
          <CellItem valueText={value.value} postfix="%" tooltip={value.ticker} />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (isRequested.current !== request && isInViewport && tableData.table === null) {
      dispatch(fetchETFTableData());
      isRequested.current = request;
    }
    if (isInViewport) setShow(true);
  }, [isInViewport, tableData, request]);

  return (
    <div className={styles.container} ref={invewportItemRef}>
      {show && (
        <div className={styles.table}>
          {tableData.table ? (
            <Table
              columns={columns}
              data={tableData.table || []}
              scrollWidth={1400}
              size="small"
              isNotClickable
            />
          ) : (
            <Skeleton loading active lineHeight="28px" rows={5}>
              <div></div>
            </Skeleton>
          )}
        </div>
      )}
    </div>
  );
};
