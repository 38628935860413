import { FC, Fragment, memo, useRef } from "react";

import { usePaginationReq } from "./hooks/usePaginationReq";

import styles from "./Pagination.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";
import { ReactComponent as ArrowsGroupIcon } from "@images/arrows-group.svg";
import { ReactComponent as DotsIcon } from "@images/dots.svg";

interface IProps {
  onPage?: (page: number) => void;
  onChangePerPage: (value: number) => void;
  perPage: number;
  currentPage: number;
  perPageList?: number[];
  totalCount: number | null;
  count: number;
  showTotal?: boolean;
  disabled?: boolean;
}

export const PaginationReq: FC<IProps> = memo(
  ({ onPage, onChangePerPage, perPage, currentPage, totalCount, count, perPageList, disabled }) => {
    const { prevPageHandler, nextPageHandler } = usePaginationReq({
      onPage,
      currentPage,
      totalCount,
    });

    return (
      <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
        <div className={styles.inner}>
          {perPageList && (
            <PerPageReq perPage={perPage} perPageList={perPageList} onSelect={onChangePerPage} />
          )}
          <div className={styles.pagesWrapper}>
            <PrevPageIconReq onClick={prevPageHandler} disabled={currentPage === 1} />
            <ul className={styles.pagesList}>
              <li>{currentPage}</li>
            </ul>
            <NextPageIconReq onClick={nextPageHandler} disabled={count < perPage} />
          </div>
        </div>
      </div>
    );
  }
);

interface IPageIcon {
  onClick: () => void;
  disabled: boolean;
}

export const PrevPageIconReq: FC<IPageIcon> = ({ onClick, disabled }) => {
  return (
    <div
      className={`${styles.iconContainer} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
      title="Previous page"
    >
      <Arrow style={{ transform: "rotate(-90deg)" }} />
    </div>
  );
};

export const NextPageIconReq: FC<IPageIcon> = ({ onClick, disabled }) => {
  return (
    <div
      className={`${styles.iconContainer} ${disabled ? styles.disabled : ""}`}
      onClick={disabled ? () => null : onClick}
      title="Next page"
    >
      <Arrow style={{ transform: "rotate(90deg)" }} />
    </div>
  );
};

interface IArrowsGroupNavProps {
  dir: "left" | "right";
  show: boolean;
  onClick: () => void;
}

export const ArrowsGroupNavReq: FC<IArrowsGroupNavProps> = ({
  dir = "left",
  show = true,
  onClick,
}) => {
  if (!show) return null;
  return (
    <Fragment>
      {dir === "left" ? (
        <li className={styles.navGroupArrows} onClick={onClick}>
          <ArrowsGroupIcon style={{ transform: "rotate(-180deg)" }} title="Previous 5" />
          <DotsIcon />
        </li>
      ) : (
        <li className={styles.navGroupArrows} onClick={onClick} title="Next 5">
          <ArrowsGroupIcon />
          <DotsIcon />
        </li>
      )}
    </Fragment>
  );
};

interface IPerPageProps {
  perPage: number;
  perPageList: number[];
  onSelect: (value: number) => void;
}

export const PerPageReq: FC<IPerPageProps> = ({ perPage, perPageList, onSelect }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const selectHandle = (item: number) => {
    onSelect(item);
    itemRef.current?.setAttribute("style", "height: 0");
    setTimeout(() => {
      itemRef.current?.setAttribute("style", "height: auto");
    }, 300);
  };
  return (
    <div className={styles.perPageWrapper}>
      <p className={styles.perPageText}>Per page:</p>
      <p className={styles.perPageCount}>{perPage}</p>
      <div className={styles.listContainer} ref={itemRef}>
        <ul>
          {perPageList.map((item) => (
            <li
              key={item}
              onClick={() => selectHandle(item)}
              className={perPage === item ? styles.active : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
