import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DateObj, ItemType } from "@/components";
import { DATE_ISO } from "@/constants";
import { useAppDispatch } from "@/hooks";
import { PortfolioService } from "@/services";
import {
  invalidateAllocationData,
  useGetAllocationCSVsListQuery,
} from "@/services/apiQuery/userData";
import { IAllocationFormData, ICompareResponseData } from "@/types";
import { notification, verifyIsEmptyDataFields } from "@/utils";
import { hasCSVFile, removeCSVFiles, uploadCSV } from "@/utils/warRoom";

import { useConvertAllocationData } from "./useConvertAllocatioonData";

type DateType = { start: string | null; end: string | null };

const DELAY_TIMEOUT = 1000;

const createConfig = (
  date: DateType | null,
  selectedCSV: ItemType<string>
): IAllocationFormData => {
  return {
    allocation: Number(selectedCSV.key),
    start_date: date?.start || undefined,
    end_date: date?.end || undefined,
  };
};

export const useAllocationOptimizer = () => {
  const [progress, setProgress] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedCSV, setSelectedCSV] = useState<ItemType<string> | null>(null);
  const [dataResult, setDataResult] = useState<ICompareResponseData | null>(null);
  const [date, setDate] = useState<DateType | null>(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const prevConfig = useRef<string | null>(null);
  const resultRefItem = useRef<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const [search, setSearchParams] = useSearchParams();

  const queryFileName = search.get("name");
  const queryFileId = search.get("id");

  const abort = PortfolioService.abort;

  const { data, isFetching: isCSVListLoading } = useGetAllocationCSVsListQuery(null);

  const selectCSVHandler = (name: string, item: ItemType<string>) => {
    setSelectedCSV(item);
    setSearchParams({ name: encodeURIComponent(item.value), id: item.key });
  };

  const { chartData, tableData, columns, isEmptyData } = useConvertAllocationData(
    dataResult,
    isDataReady
  );

  const calculateHandler = () => {
    const getCalculatedAllocation = async (formData: IAllocationFormData) => {
      try {
        const { data } = await PortfolioService.getAllocationsData(formData);
        setDataResult(data.result);
        setIsDataReady(true);
        if (data.result) prevConfig.current = JSON.stringify(formData);
      } catch (err) {
        const error = err as AxiosError | { isCanceled: boolean };
        if (Object.hasOwn(error, "isCanceled")) return;
        notification.error("Something went wrong during compare data results!");
      } finally {
        setProgress(false);
      }
    };

    if (selectedCSV) {
      const configData = createConfig(date, selectedCSV);
      setProgress(true);
      setIsDataReady(false);
      setDataResult(null);
      getCalculatedAllocation(configData);
    }
  };

  const cancelHandler = () => {
    abort.abort();
  };

  const selectDateHandler = (selectedDate: DateObj) => {
    setDate({
      start: selectedDate.start ? dayjs(selectedDate.start).format(DATE_ISO) : null,
      end: selectedDate.end ? dayjs(selectedDate.end).format(DATE_ISO) : null,
    });
  };

  const csvList = useMemo(() => {
    return (
      (data?.map(({ id, name }) => ({
        key: `${id}`,
        value: name,
      })) as ItemType<string>[]) || []
    );
  }, [data]);

  const isSameConfigData = useMemo(() => {
    if (selectedCSV && isDataReady) {
      const configData = createConfig(date, selectedCSV);
      return JSON.stringify(configData) === prevConfig.current;
    }
    return false;
  }, [selectedCSV, date, isDataReady]);

  const uploadCSVHandler = (files: FileList | null) => {
    const upload = async () => {
      const file = files ? files[0] : null;
      if (!hasCSVFile(csvList, file)) {
        const fileData = await uploadCSV(file, "/portfolio-allocations");
        if (fileData) dispatch(invalidateAllocationData());
        if (fileData) setSelectedCSV({ key: `${fileData.id}`, value: fileData.name });
      } else {
        notification.warning(
          "CSV File with this name already exist! Please, rename file and try again."
        );
      }
      setUploading(false);
    };

    setUploading(true);
    upload();
  };

  const removeCSVHandler = async () => {
    const remove = async () => {
      if (selectedCSV) {
        const url = "/portfolio-allocations/";
        const result = await removeCSVFiles(selectedCSV, url);
        if (result) dispatch(invalidateAllocationData());
        setUploading(false);
        setSelectedCSV(null);
      }
    };

    setUploading(true);
    remove();
  };

  const refCallback = useCallback((el: HTMLElement | null) => {
    resultRefItem.current = el;
  }, []);

  const isCalculationResultEmpty =
    !!(dataResult && verifyIsEmptyDataFields(dataResult)) || isEmptyData;
  const fileName = selectedCSV?.value || "";
  const selectedCSVCreatedDate =
    data?.find((file) => file.id.toString() === selectedCSV?.key?.toString())?.created_at || null;

  useEffect(() => {
    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    if (!isCalculationResultEmpty && isDataReady) {
      setTimeout(() => {
        resultRefItem.current?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, DELAY_TIMEOUT);
    }
  }, [isCalculationResultEmpty, isDataReady]);

  useEffect(() => {
    if (queryFileName && queryFileId) {
      setSelectedCSV({ key: queryFileId, value: decodeURIComponent(queryFileName) });
    }
  }, [queryFileName, queryFileId]);

  return {
    removeCSVHandler,
    uploadCSVHandler,
    selectCSVHandler,
    selectDateHandler,

    calculateHandler,
    cancelHandler,
    refCallback,

    csvList,
    selectedCSV,
    isCSVListLoading,
    isSameConfigData,
    isCalculationResultEmpty,
    selectedCSVCreatedDate,
    isDataReady,
    uploading,
    progress,
    fileName,

    chartData,
    tableData,
    columns,
    date,
  };
};
