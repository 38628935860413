import { MENU_LS_KEY } from "../constants/menu";

export function isMenuOpen(): boolean {
  const lsValue = window.localStorage.getItem(MENU_LS_KEY);
  return lsValue === null || lsValue === MENU_LS_KEY;
}

export function setIsMenuOpen(isOpen: boolean): void {
  window.localStorage.setItem(MENU_LS_KEY, isOpen ? MENU_LS_KEY : "");
}
