import { FC, Fragment, useState } from "react";

import {
  Button,
  Dropdown,
  GoBack,
  Heading,
  InfoHeading,
  InfoHelpComponent,
  InputItem,
  Loader,
  ModalLayout,
  Switch,
  TooltipWrapper,
  UploadCSVForm,
} from "@/components";
import { CustomUploadLabel } from "@/components/CustomLabel/CustomLabel";
import { Confirm } from "@/components/Dialogs/Confirm";
import { DropdownMultiSearch } from "@/components/Dropdown/DropdownMultiSearch";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { customOperatorInputTypeList, instrumentList } from "@/constants";
import { useOuterClick } from "@/hooks";
import CSVInfoImage from "@/images/custom-table.jpeg";
import { ConstructorInputType, ConstructorMode, TradingInstrumentType } from "@/types";

import { ConstructorDesk } from "./components/ConstructorDesk/ConstructorDesk";
import { SignalsTable } from "./components/SignalsTable/SignalsTable";
import { useConstructor } from "./hooks/useConstructor";
import { useUploadSignalsCSV } from "./hooks/useUpladSignalsCSV";

import styles from "./CustomIndicatorConstructor.module.scss";

import { ReactComponent as ClearAllIcon } from "@images/clear-all.svg";
import { ReactComponent as CreateIndicatorIcon } from "@images/create-new.svg";
import { ReactComponent as DeleteIcon } from "@images/nest.svg";
import { ReactComponent as AddOperatorIcon } from "@images/plus.svg";
import { ReactComponent as SaveIcon } from "@images/save-v2.svg";

const helpText = [
  "1. At least it needs a column named 'date', 'signal' (for single ticker indicators) OR 'date', 'ticker', 'signal' (for basket indicators).",
  "2. The 'date' column should be in YYYY-MM-DD format.",
  "3. The 'signal' column should be a number 0 or 1",
];

const EXCLUDE_ITEM_KEY = "custom-csv";

interface IProps {
  mode: ConstructorMode;
}

export const CustomIndicatorConstructor: FC<IProps> = ({ mode }) => {
  const {
    form,
    showModal,
    fileName,
    oldIndicatorName,

    backHandler,
    selectInputTypeHandler,
    selectInstrumentTypeHandler,
    selectOperatorTypeHandler,
    selectTickerHandler,
    selectBasketTickerHandler,
    addOperatorHandler,
    clearAllHandler,
    changeNameHandler,
    changeDescriptionHandler,
    saveActionHandler,
    deleteActionHandler,
    modalHandler,
    constructorModeHandler,

    selectedOperator,
    selectedTicker,
    selectedBasketTicker,

    operatorsList,
    selectedInstrumentType,
    disableIndicators,
    disableOperators,
    disableTickers,
    isAddActionActive,
    buttonTextAction,
    isConstructorEmpty,
    exitFormula,
    tickersMappedList,
    operatorsMappedList,
    customBasketTickersList,
    selectedInputType,
    isLoading,
    isFetching,
    isReplaceMode,
    isCustomBasket,
    isBasketsInstrument,
    error,
  } = useConstructor(mode);

  const [showInfo, setShowInfo] = useState(false);
  const [itemRef] = useOuterClick(setShowInfo, false, EXCLUDE_ITEM_KEY);

  const showInfoHandler = () => {
    setShowInfo((prev) => !prev);
  };

  const CustomLabel = () => {
    const selectedTickersList = selectedBasketTicker.map(({ value }) => value).join(", ");
    return (
      <span className={styles.customLabel}>
        <TooltipWrapper title={"Selected Tickers"} text={selectedTickersList} maxWidth={"100%"}>
          <span className={styles.labelText}>Instrument List of Tickers</span>
        </TooltipWrapper>
      </span>
    );
  };

  const InfoHelp = () => {
    return (
      <Fragment>
        {showInfo ? (
          <InfoHelpComponent
            textList={helpText}
            ref={itemRef}
            title="The CSV file data format help"
            image={CSVInfoImage}
            alt="csv-info"
            maxImageWidth={400}
          />
        ) : null}
      </Fragment>
    );
  };

  const {
    csvList,
    selectedCSVFile,

    csvFile,
    canDelete,
    isConstructorDisabled,

    onSelectCSVHandler,
    onUploadCSVHandler,
    onRemoveCSVHandler,
  } = useUploadSignalsCSV(fileName);

  const isSignal = !!csvFile || !!fileName;
  const disableSave = isSignal
    ? !form.name || isLoading
    : !form.name || isConstructorEmpty || isLoading;

  return (
    <div className={styles.container}>
      <InfoHeading>Custom Indicator</InfoHeading>
      {mode === "edit" ? (
        <div className={styles.editSubHeading}>
          <p>Edit Indicator:</p> <p>{oldIndicatorName}</p>
        </div>
      ) : (
        <Heading type="h1" className={styles.subHeading}>
          Create Indicator
        </Heading>
      )}
      <div className={styles.navigation}>
        <GoBack onClick={backHandler} />
        <div className={styles.navGroup}>{isLoading && <Loader />}</div>
      </div>

      <div className={`${styles.mainContent} ${isLoading ? styles.disable : ""}`}>
        <section className={styles.topBox}>
          <div className={styles.infoSection}>
            <p>
              Please build your indicator using the menus below OR upload your indicator time-series
              as a CSV file.{" "}
            </p>
            <p>
              Uploaded CSV file requires date (in yyyy-mm-dd format), signal (signal: 0 or 1), and
              ticker (in case of multi-ticker indicator) columns.
            </p>
          </div>
          <div className={styles.groupRow}>
            <div className={styles.itemWrapper}>
              <InputItem
                name="name"
                label="Indicator Name"
                onChange={changeNameHandler}
                type="input"
                placeholder="Indicator Name"
                value={form.name || ""}
                error={form.error}
                tooltipHelp="Name of Custom indicator. Should contain only letters and numbers without break spaces."
                isRequired
                noPaddingBottom
              />
            </div>
            <div className={styles.itemWrapper}>
              {mode === ConstructorMode.edit && (
                <Button
                  text="Delete"
                  onClick={deleteActionHandler}
                  danger
                  IconComponent={DeleteIcon}
                />
              )}
              <Button
                text={mode === ConstructorMode.create ? "Create Indicator" : "Update Indicator"}
                onClick={() => saveActionHandler(csvFile)}
                disabled={disableSave}
                IconComponent={mode === ConstructorMode.edit ? SaveIcon : CreateIndicatorIcon}
              />
            </div>
          </div>
          <div className={styles.groupRow}>
            <UploadCSVForm
              csvList={csvList}
              isLoading={false}
              canDelete={canDelete}
              selectedCSVFile={selectedCSVFile}
              deletingFileName={null}
              onSelectOneCSVHandler={onSelectCSVHandler}
              onUploadCSVHandler={onUploadCSVHandler}
              onUnselectCSVHandler={onRemoveCSVHandler}
              CustomLabel={() => (
                <CustomUploadLabel
                  onClick={showInfoHandler}
                  label="CSV Signal file"
                  excludeId={EXCLUDE_ITEM_KEY}
                />
              )}
              InfoHelpComponent={InfoHelp}
              isMultiSelect={false}
              uploadButtonText="Upload CSV Signal file"
              placeholder="CSV file"
            />
          </div>
          <div style={{ width: "100%" }}>
            <SignalsTable visible={!csvFile} />
          </div>
          <div className={styles.groupRow}>
            <div className={styles.itemWrapper}>
              <InputItem
                name="description"
                label="Short Description"
                onChange={changeDescriptionHandler}
                type="input"
                placeholder="Description"
                value={form.description || ""}
                error={form.descriptionError}
                tooltipHelp="Additional short description of Custom Indicator."
              />
            </div>
          </div>
        </section>
        <section
          className={`${styles.constructorBox} ${isConstructorDisabled ? styles.disabled : ""}`}
        >
          <div className={styles.constructorDesk}>
            <p className={styles.label}>Indicator Custom Formula</p>
            <div className={`${styles.innerDesk} ${error ? styles.error : ""}`}>
              <ConstructorDesk />
              <div className={styles.errorContainer}>{error}</div>
            </div>
            <div className={styles.footer}>
              <p>
                Exit Formula: <span>{exitFormula}</span>
              </p>
            </div>
          </div>
          <div className={styles.constructorItemsForm}>
            <div className={styles.itemWrapper}>
              <Dropdown<"input_type", ConstructorInputType>
                list={customOperatorInputTypeList}
                onSelect={selectInputTypeHandler}
                selected={selectedInputType}
                name="input_type"
                label="Input Type"
                disable={isConstructorDisabled}
              />
            </div>
            <div className={styles.itemWrapper}>
              <DropdownSearch
                list={operatorsMappedList}
                onSelect={selectOperatorTypeHandler}
                selected={selectedOperator}
                name="function"
                placeholder="function"
                label="Operator Function"
                disable={disableOperators || isConstructorDisabled}
                loading={!operatorsList}
                isClearable
                visibleRows={7}
              />
            </div>
            <div className={`${styles.itemWrapper} ${styles.switchContainer}`}>
              <Switch
                label={isReplaceMode ? "Replace Mode" : "Insert Mode"}
                onChange={constructorModeHandler}
                checked={isReplaceMode || false}
                color={isReplaceMode ? "var(--error)" : "var(--secondary)"}
                disabled={isConstructorDisabled}
              />
            </div>
            <div className={styles.itemWrapper}>
              <Dropdown<"instrument", TradingInstrumentType>
                list={instrumentList}
                onSelect={selectInstrumentTypeHandler}
                selected={selectedInstrumentType}
                name="instrument"
                placeholder="Type"
                label="Instrument Type"
                disable={disableIndicators || isConstructorDisabled}
              />
            </div>
            <div className={styles.itemWrapper}>
              {isBasketsInstrument ? (
                <Dropdown<"ticker", string>
                  list={tickersMappedList}
                  onSelect={selectTickerHandler}
                  selected={selectedTicker}
                  name="ticker"
                  placeholder="Ticker"
                  label={"Instrument Ticker"}
                  disable={disableTickers || isConstructorDisabled}
                  loading={isFetching}
                  key="1"
                />
              ) : (
                <DropdownSearch
                  list={tickersMappedList}
                  onSelect={selectTickerHandler}
                  selected={selectedTicker}
                  name="ticker"
                  placeholder="Ticker"
                  label={"Instrument Ticker"}
                  disable={disableTickers || isConstructorDisabled}
                  loading={isFetching}
                  isClearable
                  key="2"
                  visibleRows={7}
                />
              )}
            </div>
            {isCustomBasket && (
              <div className={`${styles.itemWrapper} ${styles.fullWidth}`}>
                <DropdownMultiSearch<"tickers-list">
                  list={customBasketTickersList}
                  onSelect={selectBasketTickerHandler}
                  selected={selectedBasketTicker}
                  name="tickers-list"
                  placeholder="Search Ticker Name"
                  label=""
                  disable={disableTickers || isConstructorDisabled}
                  loading={isFetching}
                  visibleRows={10}
                  CustomLabel={CustomLabel}
                />
              </div>
            )}
            <div className={styles.bottomWrapper}>
              <Button
                text={buttonTextAction}
                onClick={addOperatorHandler}
                disabled={!isAddActionActive || isConstructorDisabled}
                IconComponent={AddOperatorIcon}
                type="secondary"
              />
            </div>
            <div className={styles.bottomWrapper}>
              <Button
                text="Clear All"
                type="gost"
                onClick={clearAllHandler}
                disabled={isConstructorEmpty || isConstructorDisabled}
                IconComponent={ClearAllIcon}
                danger
              />
            </div>
          </div>
        </section>
        <section className={styles.formulaBox}></section>
      </div>
      <ModalLayout show={showModal} onClose={() => modalHandler("cancel")}>
        <Confirm
          onCancel={() => modalHandler("cancel")}
          onConfirm={() => modalHandler("submit")}
          text="Do you really want to delete the Indicator?"
        />
      </ModalLayout>
    </div>
  );
};
