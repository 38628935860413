import { FC, useEffect, useMemo, useState } from "react";

import { TableInfinitList } from "@/components";
import { ChartClosestMarket } from "@/components/Chart/ChartClosestMarket";
import { IDaysRange, IExpectedReturn, IMatchingRank, IMatchingRankTableData } from "@/types";

import { DetailsViewModal } from "../DetailsViewModal/DetailsViewModal";

import styles from "./TableMarketView.module.scss";

interface IProps {
  data: IMatchingRank | null;
  selectDaysRange: (range?: IDaysRange) => void;
  onRefrash: () => void;
  isLoading: boolean;
  height?: number;
  toggle: boolean;
}

export const TableMarketView: FC<IProps> = ({
  data,
  onRefrash,
  selectDaysRange,
  isLoading,
  height = 360,
  toggle,
}) => {
  const [selected, setSelected] = useState<IMatchingRankTableData | null>(null);
  const [daysRange, setDaysRange] = useState<IDaysRange | null>(null);
  const onHandleSelect = (item: object) => {
    setSelected(item as IMatchingRankTableData);
  };

  const onHandleRefrash = () => {
    onRefrash();
  };

  const resetDaysRange = () => {
    setDaysRange(null);
    selectDaysRange(undefined);
    onRefrash();
  };

  useEffect(() => {
    if (!daysRange) return;
    selectDaysRange(daysRange);
  }, [daysRange, selectDaysRange]);

  const columns = [
    {
      Header: "Rank",
      accessor: "Rank",
      canSort: true,
      minWidth: 120,
      Cell: ({ value }: { value: string }) => {
        return <span>#{value}</span>;
      },
    },
    {
      Header: "Date",
      accessor: "Date",
      canSort: true,
      minWidth: 120,
    },
    {
      Header: "Description",
      accessor: "Description",
      canSort: true,
      minWidth: 200,
      Cell: ({ value }: { value: string }) => {
        return <div className={styles.description}>{value}</div>;
      },
    },
  ];

  const chartData = useMemo(() => {
    return {
      data: data?.expected_return || null,
      name: "Market Value",
    };
  }, [data]);

  return (
    <div className={styles.container} style={{ height }}>
      {toggle && (
        <TableInfinitList<IMatchingRankTableData>
          size="small"
          columns={columns}
          data={data?.table || []}
          onSelect={onHandleSelect}
          key="table"
          defaultCount={50}
        />
      )}
      {!toggle && <ChartClosestMarket data={chartData} height={height} key="chart" />}
      <DetailsViewModal
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelected}
        data={data}
        setDaysRange={setDaysRange} //move in context
        onRefrash={onHandleRefrash} //move in context
        daysRange={daysRange}
        resetDaysRange={resetDaysRange}
      />
    </div>
  );
};
