import { useEffect, useState } from "react";

import { debounce } from "@/utils";

export const useMediaQuery = (mediaQuery: string) => {
  const [isMatch, setIsMatch] = useState(window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      setIsMatch(window.matchMedia(mediaQuery).matches);
    }, 300);

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [mediaQuery]);

  return isMatch;
};
