import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateObj, DatePickerRange } from "@/components";
import { FILTER_OPERATORS } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { IFilter, IFilterCriteria } from "@/types";
import { getDateISOString } from "@/utils";

import styles from "./SelectTimeSeriesRange.module.scss";

interface IProps {
  setFilterCriteria: (params: IFilterCriteria) => void;
  filterCriteria: IFilterCriteria | undefined;
  setCurrentPage: (current: number) => void;
}

interface IDate {
  start: string | null;
  end: string | null;
}

export const SelectTimeSeriesRange: FC<IProps> = memo(
  ({ setFilterCriteria, filterCriteria, setCurrentPage }) => {
    const { drsMeta } = useAppSelector(discoverDrsMetaState);
    const [dateRanges, setDateRanges] = useState<DateObj>();

    const timeDimension = drsMeta?.format?.time_dimensions[0];

    const setCriteriaFilter = useCallback(
      (range: DateObj) => {
        const result: IFilter[] = [];
        if (range.start) {
          result.push({
            field: timeDimension || "",
            operator: FILTER_OPERATORS.gte.id,
            value: getDateISOString(range.start),
          });
        }
        if (range.end) {
          result.push({
            field: timeDimension || "",
            operator: FILTER_OPERATORS.lte.id,
            value: getDateISOString(range.end),
          });
        }

        let mergedCriteria: IFilter[] = [];

        if (filterCriteria?.filter.filters?.length) {
          const filters = filterCriteria.filter.filters.filter(
            ({ field }) => field !== timeDimension
          );
          mergedCriteria = [...result, ...filters];
        } else {
          mergedCriteria = result;
        }

        const filter = {
          ...(mergedCriteria?.length >= 2 && {
            filter_operator: FILTER_OPERATORS.and.id,
          }),
          filters: mergedCriteria,
        };

        setFilterCriteria({ filter });
        setCurrentPage(1);
      },
      [filterCriteria?.filter.filters, setCurrentPage, setFilterCriteria, timeDimension]
    );

    const hasTimeSeries = useMemo(
      () => 1 === drsMeta?.format?.time_dimensions.length,
      [drsMeta?.format.time_dimensions.length]
    );

    const handleSelectRange = (range: DateObj) => {
      setDateRanges(range);
      setCriteriaFilter(range);
    };

    return (
      <div className={styles.container}>
        {hasTimeSeries && (
          <div className={styles.datePickerRange}>
            <DatePickerRange
              date={(dateRanges || { start: null, end: null }) as IDate}
              key={drsMeta?.format?.time_dimensions[0]}
              onSelect={handleSelectRange}
              label=""
            />
          </div>
        )}
      </div>
    );
  }
);
