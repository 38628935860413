import React, { Fragment, useEffect, FC, useMemo } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { MessagesCounter, UserInfo, HelpMenu } from "@/components";
import { ROUTES } from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useUnreadedMessages } from "@/hooks/useUnreadedMessages";
import { ReactComponent as Collapse } from "@/images/collapse.svg";
import { ReactComponent as InsightIcon } from "@/images/icons/insight.svg";
import { ReactComponent as ResourcesIcon } from "@/images/resources.svg";
import { toggleMenu, selectIsOpen, setCollapsedItems } from "@/store/menu";
import { pagesStatusState } from "@/store/pagesPermission";
import { ILink } from "@/types";
import { getCollapsedItems } from "@/utils";
import { pagePermission } from "@/utils/pagesPermissions";

import { SideBarLink } from "./SideBarLink";

import styles from "./SideBar.module.scss";

interface IProps {
  isMobile: boolean;
  onSelect: () => void;
}

export const SideBar: FC<IProps> = ({ isMobile, onSelect }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { pages } = useAppSelector(pagesStatusState);

  const isMenuOpen = useAppSelector(selectIsOpen) || isMobile;

  const navMenuList: ILink[] = useMemo(() => {
    return pagePermission.createMenu(pages);
  }, [pages]);

  // collapse or open collapsed menu items
  useEffect(() => {
    if (isMenuOpen) dispatch(setCollapsedItems(getCollapsedItems(navMenuList, isMenuOpen)));
  }, [dispatch, isMenuOpen, navMenuList]);

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
    // we do this because the highcharts adjust their size on the 'resize' event
    // but toggling the sidebar is not dipatching that event by default
    const transition =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue("--sidenav-transition"),
        10
      ) || 0;

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, transition + 50);
  };

  const mouseEnterHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    const submenuItem = ev.currentTarget.querySelector(`[data-submenu="true"]`);
    const cords = ev.currentTarget.getBoundingClientRect();
    submenuItem?.setAttribute(
      "style",
      `display: block; left: ${cords.right}px; top: ${cords.top}px`
    );
  };

  const mouseLeaveHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    const submenuItem = ev.currentTarget.querySelector(`[data-submenu]`);
    submenuItem?.setAttribute("style", `display: none;`);
  };

  return (
    <aside
      className={`${styles[isMenuOpen ? "sidenav" : "sidenavCollapsed"]} ${
        isMobile ? styles.mobileView : ""
      }`}
      id="side-bar-nav"
    >
      <div className={styles.innerWrapper}>
        <div className={`${styles.wrapper} ${isMenuOpen ? styles.wrapperOpen : ""}`}>
          <nav className={styles.nav}>
            {isMobile && <UserInfo isMobile onSelect={onSelect} />}
            {navMenuList.map((link, i) => (
              <div
                className={styles.firstLevelLink}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                key={i}
              >
                {!isMenuOpen && !!link.items && (
                  <div className={styles.submenu} data-submenu={!!link.items && !isMenuOpen}>
                    <div className={styles.innerSubmenu}>
                      {link.items.map((subLink, idx) => (
                        <Fragment key={idx}>
                          <SideBarLink link={subLink} isMenuOpen={isMenuOpen} onSelect={onSelect} />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}
                <SideBarLink link={link} isMenuOpen={isMenuOpen} onSelect={onSelect} />
                {!!link.items && isMenuOpen && (
                  <div className={styles[isMenuOpen ? "linkWrapper" : "linkWrapperMenuClose"]}>
                    {link.items.map((subLink, idx) => (
                      <Fragment key={idx + subLink.name}>
                        <SideBarLink link={subLink} isMenuOpen={isMenuOpen} onSelect={onSelect} />
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <OdinChatMenuItem isMenuOpen={isMenuOpen} onSelect={onSelect} />
          </nav>

          <div className={`${styles.separator} ${isMenuOpen ? styles.toggleOpen : ""}`}>
            <div className={styles.line}></div>
            <div className={styles.separatorInner} onClick={handleToggleMenu}>
              {!isMobile && <Collapse />}
            </div>
          </div>

          <div className={`${styles.sideBarFooter} ${isMenuOpen ? styles.open : ""}`}>
            <NavLink
              to={ROUTES.insights.path}
              className={`${styles[isMenuOpen ? "link" : "linkCollapsed"]} ${styles.mainLink} ${
                styles.paddingLVL1
              } ${
                pathname === ROUTES.insights.path
                  ? styles[isMenuOpen ? "selected" : "selectedCollapsed"]
                  : ""
              }`}
              onClick={onSelect}
            >
              <span className={styles.sideMenuBorder}></span>
              <div className={styles.menuIcon}>
                <InsightIcon />
              </div>
              {isMenuOpen && <span className={styles.text}>Insights</span>}
              <span className={styles.sideMenuBorder}></span>
            </NavLink>
            <NavLink
              to={ROUTES.resources.path}
              className={`${styles[isMenuOpen ? "link" : "linkCollapsed"]} ${styles.mainLink} ${
                styles.paddingLVL1
              } ${
                pathname === ROUTES.resources.path
                  ? styles[isMenuOpen ? "selected" : "selectedCollapsed"]
                  : ""
              }`}
              onClick={onSelect}
            >
              <span className={styles.sideMenuBorder}></span>
              <div className={styles.menuIcon}>
                <ResourcesIcon />
              </div>
              {isMenuOpen && <span className={styles.text}>Resources</span>}
              <span className={styles.sideMenuBorder}></span>
            </NavLink>
            <HelpMenu />
          </div>
        </div>
      </div>
    </aside>
  );
};

interface IOdinChatMenuItemProps {
  isMenuOpen: boolean;
  onSelect: () => void;
}

const OdinChatMenuItem: FC<IOdinChatMenuItemProps> = ({ isMenuOpen, onSelect }) => {
  const navigate = useNavigate();
  const { pages } = useAppSelector(pagesStatusState);
  const odinPageStatus = pagePermission.getOdinPageStatus(pages);
  const { count, show } = useUnreadedMessages(odinPageStatus);

  const clickHandler = () => {
    onSelect();
    navigate(ROUTES.odinchat.path);
  };

  const odinMenuStatus = useMemo(() => {
    return pagePermission.getPageStatus(pages, "odinchat");
  }, [pages]);

  const isPageHidden = pagePermission.isPageHidden(odinMenuStatus);

  if (isPageHidden) return <div></div>;

  return (
    <div
      className={`${styles.odinMenuContainer} ${isMenuOpen ? "" : styles.collapsled} ${
        styles[odinMenuStatus]
      }`}
    >
      <div className={styles.menuItemInner} onClick={clickHandler}>
        {!isMenuOpen && <p className={styles.title}>OdinChat</p>}
        {
          <div className={styles.buttonWrapper}>
            <span>
              Ask<span>Odin</span>
            </span>
          </div>
        }
        {show && (
          <div className={`${styles.badgeContainer} ${isMenuOpen ? "" : styles.collapsed}`}>
            <MessagesCounter count={count} />
          </div>
        )}
      </div>
    </div>
  );
};
