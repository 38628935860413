import { FC } from "react";

import { strategyType } from "@/constants";
import { StrategyCompareItemType } from "@/types";

import { CompareItem } from "../CompareItem/CompareItem";

import styles from "./BaseStrategyItem.module.scss";

interface IProps {
  name?: string | null;
  type?: StrategyCompareItemType | null;
}

const typeMapper: Record<StrategyCompareItemType, string> = {
  core: "STRATEGY",
  overlay: "STRATEGY",
  benchmark: "BENCHMARK",
  portfolio: "PORTFOLIO",
  ticker: "TICKER",
};

export const BaseStrategyItem: FC<IProps> = ({ name, type }) => {
  const labelText = type && name ? `BASE ${typeMapper[type]}:` : "";
  return (
    <div className={styles.container}>
      <CompareItem name={name} type={type} label={labelText} />
    </div>
  );
};
