import { FC, useRef } from "react";

import { Button, LoaderWrapper } from "@/components";
import { TextCopy } from "@/components/TextCopy/TextCopy";
import { ProductTypes } from "@/constants";
import { useAppSelector } from "@/hooks/redux";
import UserDefaultIcon from "@/images/user-icon.svg";
import { profileInfo } from "@/store/profile";
import { IUser } from "@/types";
import { notification } from "@/utils/notification";

import useUploadImage from "../../hooks/useUploadImage";

import styles from "./UserInfo.module.scss";

const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // Maximum size of 2MB;

export const UserInfo: FC = () => {
  const { profile, isLoadingImage } = useAppSelector(profileInfo);
  const { profileImage, handleUpload } = useUploadImage();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file && file.size <= MAX_IMAGE_SIZE) {
      handleUpload(file);
    } else {
      notification.error("File size exceeds the maximum limit of 2MB.");
    }
  };

  const getImage = () => {
    if (profileImage) {
      return URL.createObjectURL(profileImage);
    } else if (profile?.profile_image) {
      return profile?.profile_image;
    }
    return UserDefaultIcon;
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarSection}>
        <LoaderWrapper loading={isLoadingImage} transparent>
          <div className={styles.image}>
            <div
              className={styles.bgimage}
              style={{
                backgroundImage: `url(${getImage()})`,
              }}
            />
            <span>Max image size up to 2Mb</span>
          </div>
        </LoaderWrapper>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          id="profileImage"
          onChange={handleChange}
          accept=".jpg, .jpeg, .png, .gif"
        />
        <Button onClick={handleButtonClick} type="secondary" text="Change Avatar Image" />
      </div>
      <UserInfoDetails profile={profile} product="modeling" />
    </div>
  );
};

interface IUserInfoDetails {
  profile: IUser | null;
  showActiveStatus?: boolean;
  product: ProductTypes;
}

export const UserInfoDetails: FC<IUserInfoDetails> = ({ profile, showActiveStatus }) => {
  return (
    <div className={styles.userInfoSection}>
      {showActiveStatus ? (
        <h3>
          User Info
          <span className={`${styles.status} ${profile?.is_active ? "" : styles.inactive}`}>
            {profile?.is_active ? "Active" : "Inactive"}
          </span>
        </h3>
      ) : (
        <h3>User Info</h3>
      )}

      <div className={styles.info}>
        <span className={styles.label}>Email:</span>
        <TextCopy copyText={profile?.email} helpText="Copy Email" actionText="Email Copied!">
          <span>{profile?.email}</span>
        </TextCopy>
      </div>
      <div className={styles.info}>
        <span className={styles.label}>First Name:</span>
        <span>{profile?.first_name}</span>
      </div>
      <div className={styles.info}>
        <span className={styles.label}>Last Name:</span>
        <span>{profile?.last_name}</span>
      </div>
      <div className={styles.info}>
        <span className={styles.label}>Phone Number:</span>
        <TextCopy
          copyText={profile?.phone}
          helpText="Copy Phone number"
          actionText="Phone number Copied!"
        >
          <span>{profile?.phone ? profile.phone : "-"}</span>
        </TextCopy>
      </div>

      <div className={styles.infoCompany}>
        <span className={styles.label}>Company:</span>
        <span>{profile?.company?.name}</span>
      </div>
    </div>
  );
};
