import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserService } from "@/services";
import { IUserDemoRequest } from "@/types";
import { notification, validate } from "@/utils";

type FormType = Record<string, { value: string; error: string | null }>;

export const useDemoInviteForm = (formInit: FormType) => {
  const [form, setForm] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [isCompleted, setCompleted] = useState(false);
  const navigate = useNavigate();

  const demoRequest = async (userData: IUserDemoRequest) => {
    try {
      const { data } = await UserService.demoRequest(userData);
      if (data.result === "ok") setCompleted(true);
    } catch (err) {
      notification.error("Sorry, something went wrong during request! Please try later.");
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    navigate("/");
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { name, company, message, email } = form;
    const [validatedForm, isValid] = validate(form);
    if (isValid) {
      setLoading(true);
      demoRequest({
        email: email.value.trim(),
        full_name: name.value.trim(),
        company_name: company.value.trim(),
        phone: "",
        message: message.value.trim(),
      });
    } else {
      setForm(validatedForm);
    }
  };

  const changeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = ev.target.value;
      const fieldKey = ev.target.name;
      setForm((prev) => ({
        ...prev,
        [fieldKey]: { error: null, value: inputValue },
      }));
    },
    []
  );

  const canRequest = !Object.keys(form).some((key) => {
    return form[key].error || !form[key].value;
  });

  return { form, loading, canRequest, isCompleted, submitHandler, changeHandler, onClose };
};
