import { AxiosResponse } from "axios";

import axios from "@/services/axios.config";
import { IPortfolioHedging } from "@/types";

export const PortfolioHedgingService = {
  createPortfoliosHedging: (data: any): Promise<AxiosResponse<{ result: IPortfolioHedging }>> =>
    axios.post("/portfolios-hedging", data),
  getPortfoliosHedgingList: (): Promise<AxiosResponse<{ result: IPortfolioHedging[] }>> =>
    axios.get("/portfolios-hedging"),
  updatePortfolioHeding: (
    hedging_id: number,
    data: any
  ): Promise<AxiosResponse<{ result: IPortfolioHedging }>> =>
    axios.put(`/portfolios-hedging/${hedging_id}`, data),
  deletePortfolioHeding: (hedging_id: number): Promise<AxiosResponse<any>> =>
    axios.delete(`/portfolios-hedging/${hedging_id}`),
  getPortfolioHedingCsv: (hedging_id: number): Promise<AxiosResponse<File>> =>
    axios.get(`/portfolios-hedging/${hedging_id}/csv`),
  getPortfolioHedingRecommendation: (
    hedging_id: number,
    benchmark: string
  ): Promise<
    AxiosResponse<{
      result: { text: string | null };
    }>
  > => axios.get(`/portfolios-hedging/${hedging_id}/recommendations`, { params: { benchmark } }),
};
