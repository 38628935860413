import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { discoverService } from "@/services";
import { IDrs } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IDiscoverdrsMetaState {
  drsData: IDrs | null;
  isLoading: boolean;
}

const initialState: IDiscoverdrsMetaState = {
  drsData: null,
  isLoading: false,
};

export const getDrsDetails = createAsyncThunk("discover/drsdetails", async (drsId: number) => {
  const response = await discoverService.fetchDrsDetails(drsId);
  return response.data;
});

export const discoverDrsDetailsSlice = createSlice({
  name: "discoverDrsDetails",
  initialState,
  reducers: {
    resetSelected: (state) => {
      state.drsData = initialState.drsData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrsDetails.fulfilled, (state, action) => {
        state.drsData = action.payload;
        state.isLoading = false;
      })
      .addCase(getDrsDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDrsDetails.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const { resetSelected } = discoverDrsDetailsSlice.actions;

const state = (state: RootState) => state;

export const discoverDrsDetailsState = createSelector(state, (state) => state.discoverDrsDetails);

export default discoverDrsDetailsSlice.reducer;
