import { FC, Fragment, memo, useEffect, useRef } from "react";

import { Chart, Heading, NoData } from "@/components";
import { useAppSelector } from "@/hooks/redux";
import { portfolioBuilderState } from "@/store/portfolios/portfolioBuilder";
import { getSeriesData } from "@/utils";

import styles from "./PortfolioChart.module.scss";

interface IProps {
  isCompareMode?: boolean;
}

export const PortfolioChart: FC<IProps> = memo(({ isCompareMode }) => {
  const { results, isLoading, isPerformanceView } = useAppSelector(portfolioBuilderState);
  const itemRef = useRef<HTMLDivElement | null>(null);

  const visibleSeries =
    isCompareMode && results?.portfolio ? Object.keys(results.portfolio) : undefined;

  const { data } = getSeriesData(results?.portfolio, true, visibleSeries);

  useEffect(() => {
    if (itemRef.current && isLoading === false) {
      setTimeout(() => {
        itemRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 1000);
    }
  }, [results]);

  return (
    <Fragment>
      {!isLoading && isPerformanceView ? (
        <Fragment>
          {Object.values(results?.portfolio || {}).length ? (
            <div id="performance" className={styles.container} ref={itemRef}>
              <Heading type="h3" className={styles.heading}>
                Performance
              </Heading>
              <div style={{ minHeight: "300px" }} className={styles.innerContainer}>
                <div className={styles.wrapper}>
                  <Chart data={data} />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.container} ref={itemRef}>
              <Heading type="h3" className={styles.heading}>
                Performance
              </Heading>
              <div className={styles.noDataContainer}>
                <p className={styles.subTitle}>Benchmark</p>
                <NoData text="No Data" />
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
});
