import { IChatMessage } from "./odinChat";

export const enum FolderTypeEnum {
  ODIN_CHAT = "OdinChat",
  COMMON_API = "CommonApi",
  FORECAST_EQUITIES = "ForecastEquities",
  MACRO = "Macro",
}

export interface IFolderData {
  command: string;
  type: FolderTypeEnum;
  folder_id: 7;
  id: number;
  description: string | null;
  title: string | null;
}

export interface IFolderDataPayload {
  command: string;
  type: FolderTypeEnum;
  description?: string | null;
  title?: string | null;
}

export interface IUpdateFolderPayload {
  command: string;
}

export interface IFolder {
  id: number;
  name: string;
  user_id: number;
  created_at?: string;
  updated_at?: string;
  saved_data: IFolderData[];
}

export interface ICommandData {
  id: string;
  type: FolderTypeEnum;
  title?: string | null;
  description?: string | null;
}

export interface IListItemToUpdate {
  hash: string;
  savedDataId: number;
}

export type IChartData = {
  [key: string]: Record<string, Record<string, Record<string, Record<string, number>>>>;
};

export type IChartReturnData = {
  [key: string]: Record<string, Record<string, Record<string, number>> | IChatMessage>;
};
