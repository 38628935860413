import { useMemo } from "react";

import { IUserDataFilesResult, IUserDataItem } from "@/types";
import { createFilesList } from "@/utils";

export const useUserFilesList = (data?: IUserDataFilesResult) => {
  const list: IUserDataItem[] = useMemo(() => createFilesList(data), [data]);
  const isEmptyList = list.length === 0;

  return {
    list,
    isEmptyList,
  };
};
