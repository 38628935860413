import { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { strategiesService } from "@/services";
import { profileInfo } from "@/store/profile";
import { fetchMyStrategiesData, selectMyStrategies } from "@/store/strategies/my_strategies";
import {
  fetchStrategiesData,
  fetchStrategyPerformanceData,
  resetStrategy,
} from "@/store/strategies/strategies";

export const useGetData = (dateRangeQuery: string, strategyKey?: string) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(profileInfo);
  const [isUserAllow, setIsUserAllow] = useState({
    canRead: false,
    canDelete: false,
    canUpdate: false,
    isOwner: false,
  });
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null);
  const isRequested = useRef(false);

  const { list } = useAppSelector(selectMyStrategies);

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchMyStrategiesData());
      await dispatch(fetchStrategiesData());
    };

    getData();

    return () => {
      dispatch(resetStrategy());
    };
  }, [strategyKey]);

  useEffect(() => {
    const getStartegyData = async () => {
      isRequested.current = true;
      const foundMyStrategy = list?.find((i) => i.id === Number(strategyKey));
      if (strategyKey && Number.isInteger(Number(strategyKey)) && foundMyStrategy) {
        await dispatch(fetchStrategyPerformanceData({ strategyKey })).then(() => {
          setIsEmpty(false);
        });
        setIsUserAllow({
          canRead: true,
          canDelete: foundMyStrategy.permissions?.has_delete || false,
          canUpdate: foundMyStrategy.permissions?.has_write || false,
          isOwner: profile?.id === foundMyStrategy.user_id,
        });

        return;
      }
      if (strategyKey && !Number.isInteger(Number(strategyKey))) {
        await dispatch(fetchStrategyPerformanceData({ strategyKey, dateRangeQuery })).then(() => {
          setIsEmpty(false);
        });
        setIsUserAllow({ canRead: true, canDelete: false, canUpdate: false, isOwner: false });

        return;
      }
      setIsEmpty(true);
    };
    if (profile && !isRequested.current && list) getStartegyData();
  }, [list, profile, dateRangeQuery]);

  useEffect(() => {
    return () => {
      strategiesService.abort.abort();
    };
  }, []);

  const isDataLoading = isEmpty !== false;
  const isGlobalEditable = Number.isInteger(Number(strategyKey));
  const showActionsNavigation =
    (isUserAllow?.canUpdate || isUserAllow?.canDelete || isGlobalEditable) && !isDataLoading;

  return {
    showActionsNavigation,
    isGlobalEditable,
    isDataLoading,
    isUserAllow,
    isEmpty,
  };
};
