import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ActionNavigation, FooterNavigation, SolutionSlider } from "@/components";
import { navMenuList, sliderList } from "@/constants";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useGetDocumentsListQuery } from "@/services/apiQuery/home";

import styles from "./Solutions.module.scss";

const getActiveTabItem = (activeTab: string | null) => {
  const foundTabItem = navMenuList.find((tab) => tab.key === activeTab);
  return foundTabItem ? foundTabItem : navMenuList[0];
};

export const Solutions: FC = () => {
  const [search, setSearch] = useSearchParams();
  const [active, setActive] = useState(() => getActiveTabItem(search.get("solution")));
  const { description, list } = active;
  const sectionRef = useRef<HTMLHeadingElement | null>(null);
  const isFirstRenderRef = useRef<boolean>(true);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const { data, isFetching } = useGetDocumentsListQuery(null);

  const selectHandle = (itemKey: string) => {
    const foundActive = navMenuList.find((item) => item.key === itemKey);
    if (foundActive) {
      setActive(foundActive);
      setSearch({ solution: foundActive.key });
    }
  };

  useEffect(() => {
    if (sectionRef.current && isMobile && !isFirstRenderRef.current) {
      sectionRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    isFirstRenderRef.current = false;
  }, [active, isMobile]);

  const hasDocuments = useMemo(() => {
    return (
      data?.some(
        (doc) =>
          doc.meta.type === "learn_more" && doc.meta["additional-ctx"]?.toLowerCase() === active.key
      ) || false
    );
  }, [active, data]);

  const DescriptionContent = useCallback(() => {
    return (
      <div className={styles.contentDescription} ref={sectionRef}>
        <div className={styles.wrapper}>
          <p className={styles.description}>{description}</p>
          <ul className={styles.descriptionList}>
            {list.map((text, index) => (
              <li className={styles.descriptionItem} key={index}>
                {text}
              </li>
            ))}
          </ul>
          <div className={styles.actionNav}>
            <ActionNavigation
              link={`/learn-more?solution=${active.key}`}
              loading={isFetching}
              hasAccess={hasDocuments}
            />
          </div>
          <div className={styles.sliderContainer}>
            <SolutionSlider list={sliderList[active.key] || []} />
          </div>
        </div>
      </div>
    );
  }, [active, hasDocuments, isFetching]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.tabsNavigation}>
          <ul className={styles.navList}>
            {navMenuList.map(({ title, key }) => (
              <li
                className={`${styles.listItem} ${key === active.key ? styles.active : ""}`}
                key={title}
                onClick={() => selectHandle(key)}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>
        <DescriptionContent />
        <FooterNavigation fullWidth />
      </div>
    </div>
  );
};
