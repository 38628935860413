import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as OdinUltraLogoWhiteIcon } from "@images/icons/logo-powered.svg";

import styles from "./Slogan.module.scss";

import { ReactComponent as OdinUltraLogoIcon } from "@images/logo-odin.svg";
// import { ReactComponent as OdinUltraLogoPoweredIcon } from "@images/icons/logo-powered-color.svg";

interface IProps {
  black?: boolean;
  asLink?: boolean;
  size?: "medium" | "small" | "large";
}

export const Slogan: FC<IProps> = ({ black, asLink, size = "large" }) => {
  const navigate = useNavigate();

  const navigateHandle = () => {
    if (asLink) navigate("/");
  };

  return (
    <div
      className={`${styles.container} ${asLink ? styles.asLink : ""} ${styles[size]}`}
      onClick={navigateHandle}
    >
      {black ? <OdinUltraLogoWhiteIcon /> : <OdinUltraLogoIcon />}
    </div>
  );
};
