import dayjs from "dayjs";

import { ItemType } from "@/components";
import { DATE_ISO, fields, operatorsList, strikeRatioList } from "@/constants";
import {
  IConditionForm,
  IData,
  IStrategyData,
  INewStartegyForm,
  INewStrategyData,
  IStrategiesResponse,
  IUserStartegyDataResponse,
  OperatorType,
  PermisionsEnum,
  IStartegyCard,
} from "@/types";
import {
  DerivativeOptionType,
  IBenchmarkData,
  IBenchmarkMetric,
  ICommonStrategyData,
  IDataPerformance,
  IEquitiesBaskets,
  IInputField,
  IInstrumentOptionsType,
  IMetricIndicator,
  IStrategyBase,
  InputFieldNames,
  StrategyPerformanceChartType,
  StrategyPerformanceMetricType,
  StrategyTypeEnum,
  StrikeRatioType,
  TickerType,
  TradingBasketType,
  TradingInstrumentConfigType,
  TradingInstrumentType,
} from "@/types/strategies";

export const prepareStrategies = (strategiesData: IStrategiesResponse) => {
  return strategiesData.strategies;
};

export const prepareStrategyTable = (
  stratyegyData: IData<string | IBenchmarkMetric>
): StrategyPerformanceMetricType => {
  return Object.keys(stratyegyData.metrics).map((key) => {
    let value: string | null = null;
    let benchmark: string | null = null;

    if (typeof stratyegyData.metrics[key] === "string") {
      value = stratyegyData.metrics[key] as string;
      benchmark = stratyegyData.metrics[key] as string;
    } else if (stratyegyData.metrics[key] !== null) {
      value = (stratyegyData.metrics[key] as IBenchmarkMetric).value;
      benchmark = (stratyegyData.metrics[key] as IBenchmarkMetric).benchmark || null;
    }

    if (value === "NaN" || value === "-Inf" || value === "Inf" || value === null) value = " - ";
    if (benchmark === "NaN" || benchmark === "-Inf" || benchmark === "Inf" || benchmark === null)
      benchmark = " - ";

    return {
      name: key,
      value,
      benchmark,
    };
  });
};

export const clearConditionForm = (condition?: IConditionForm): IConditionForm => ({
  category: condition ? condition.category : "Technical",
  indicator: null,
  formula: null,
  list: null,
  error: null,
});

export const createDefaultEquityBaskets = (basketForm?: IEquitiesBaskets): IEquitiesBaskets => ({
  ...(basketForm
    ? basketForm
    : {
        advancedFilter: null,
        metricsList: null,
        basket: null,
        tickers: [],
      }),
  weightScheme: "Cap-Weight",
  updateFrequency: "Daily",
  rebalanceFrequency: "Quarterly",
  maxAllowedWeight: "",
});

export const convertOperatorToLogic = (value: string) => {
  if (value === "or") return "|";
  if (value === "and") return "&";
  if (value === "xor") return "^";
  if (value === "not") return "!";
  if (value === "=") return "==";
  if (value === "≠") return "!=";
  return value;
};

export const convertOperatorFromLogic = (value: string) => {
  if (value === "|") return "or";
  if (value === "&") return "and";
  if (value === "^") return "xor";
  if (value === "!") return "not";
  if (value === "==") return "=";
  if (value === "!=") return "≠";
  return value;
};

export const convertToMetrics = (
  metricsList: IMetricIndicator[] | null,
  formula?: string | null
): string | undefined => {
  if (!formula || !metricsList) return undefined;
  let result = formula;
  metricsList.forEach(({ value, name }) => {
    result = result.replaceAll(name, value);
  });
  return result;
};

export const convertFromMetrics = (
  metricsList: IMetricIndicator[] | null,
  formula?: string | null
): string | null => {
  if (!formula || !metricsList) return null;
  let result = formula;
  metricsList.forEach(({ value, name }) => {
    result = result.replaceAll(value, name);
  });
  return result;
};

export const createFormula = (list: OperatorType[]) => {
  const result = list.reduce((acc, item) => {
    const value = item.isNumber ? item.numValue || "" : item.value;
    if (item.userID !== undefined) {
      acc += ` ${value}${item.userID !== null ? "-" + item.userID : ""}`;
    } else {
      acc += ` ${value}`;
    }
    return acc;
  }, "");

  return result;
};

const createEquityBaskets = (
  config: IUserStartegyDataResponse["config"],
  metricsList: IMetricIndicator[] | null
): IEquitiesBaskets => {
  const {
    trading_basket,
    rebalance_freq,
    update_freq,
    weight_scheme,
    advanced_filtering_condition,
    max_aum_fraction,
    is_custom_basket,
  } = config;
  let basket: TradingBasketType | null = null;
  let tickers: string[] = [];

  if (is_custom_basket) {
    basket = "custom-basket";
    tickers = trading_basket?.split(",") || [];
  } else if (trading_basket?.match(/(sp500)|(snp500)|(s&p500)/gi)?.length) basket = "sp500";
  else if (trading_basket === "nasdaq100") basket = "nasdaq100";
  else if (trading_basket === "mid_cap") basket = "mid_cap";
  else if (trading_basket === "small_cap") basket = "small_cap";
  else if (trading_basket) {
    basket = "custom-basket";
    tickers = trading_basket?.split(",") || [];
  }

  return {
    metricsList,
    advancedFilter: convertFromMetrics(metricsList, advanced_filtering_condition),
    updateFrequency: update_freq || "Daily",
    weightScheme: weight_scheme || "Cap-Weight",
    rebalanceFrequency: rebalance_freq || "Quarterly",
    tickers,
    basket,
    maxAllowedWeight: max_aum_fraction ? max_aum_fraction.toString() : "",
  };
};

export const createCondition = (condition: string[] | null): IConditionForm => {
  const operatorCheck = (value: string) => operatorsList.includes(value);

  const operatorCreator = (value: string, index: number): OperatorType => {
    const convertedValue = convertOperatorFromLogic(value);
    const isNumber = Number.isNaN(Number(convertedValue)) ? false : true;
    const isCustomInicator = !!convertedValue.match(/[a-zA-Z0-9]+-[0-9]{1,}/g);
    const userID = isCustomInicator ? Number(convertedValue.split("-").slice(-1)[0]) : undefined;
    return {
      value: isNumber ? "Number" : convertedValue.replaceAll(/([a-zA-Z0-9]+)-[0-9]{1,}/g, "$1"),
      index,
      isOperator: isNumber ? true : operatorCheck(convertedValue),
      isNumber,
      numValue: isNumber ? convertedValue : "",
      userID: userID ? (!Number.isNaN(userID) ? userID : undefined) : undefined,
    };
  };

  const list = condition?.map((value, index) => operatorCreator(value, index)) || null;

  return {
    ...clearConditionForm(),
    list,
    formula: createFormula(list || []),
  };
};

export const createRatioType = (strikeRatio?: string): StrikeRatioType => {
  if (!strikeRatio) return "ATM";
  const foundItem = strikeRatioList.find((i) =>
    strikeRatio.toLowerCase().includes(i.value.toLowerCase())
  );
  return foundItem ? foundItem.key : "ATM";
};

export const createOptions = (
  config: IUserStartegyDataResponse["config"]
): IInstrumentOptionsType => {
  const getValue = (key: string) => {
    if (key === "strike_ratio") {
      const value = Number.parseFloat(config.strike_ratio || "") || "";
      return value.toString();
    }
    return (config[key as keyof typeof config] as string) || "";
  };

  const optionsType = config.option_type || "buy call";

  return {
    optionsType,
    ...fields.reduce<Record<InputFieldNames, IInputField>>((acc, { key, name }) => {
      acc[name as InputFieldNames] = {
        value: getValue(key),
        error: "",
      };
      return acc;
    }, {} as Record<InputFieldNames, IInputField>),
    underlying: "equities",
    ticker: config.underlying || null,
    strikeRatioType: createRatioType(config.strike_ratio),
    allocationStyle:
      config.strike_bet ||
      (optionsType === "sell call" || optionsType === "sell put" ? "notional" : "valueBased"),
    exitStyle:
      config.keep_until_maturity === undefined
        ? optionsType === "sell call" || optionsType === "sell put"
          ? "hold-to-maturity"
          : "entry-exit-signals"
        : config.keep_until_maturity
        ? "hold-to-maturity"
        : "entry-exit-signals",
  };
};

export const clearOptions = (): IInstrumentOptionsType => ({
  optionsType: "buy call",
  minDays: { value: "", error: "" },
  maxDays: { value: "", error: "" },
  stopGain: { value: "", error: "" },
  stopLoss: { value: "", error: "" },
  underlying: "equities",
  ticker: null,
  strikeRatioType: "ATM",
  ratio: { value: "", error: "" },
  percentage: { value: "", error: "" },
  allocationStyle: "valueBased",
  exitStyle: "entry-exit-signals",
});

export const createNewStartegyForm = (): INewStartegyForm => ({
  name: null,
  type: "overlay",
  ticker: { etfs: null, equities: null, basket: null, index: null, cryptos: null },
  direction: "long",
  error: null,
  activeCondition: "entry",
  date: { start: null, end: null },
  conditions: {
    entry: clearConditionForm(),
    exit: clearConditionForm(),
  },
  isTemplate: false,
  tradingInstrument: {
    type: "stocks/etfs",
    equityBaskets: createDefaultEquityBaskets(),
    options: clearOptions(),
    futures: null,
  },
  benchmark: null,
  benchmarkList: null,
});

export const resetInstrumentsStrategyForm = (
  activeInsrument: TradingInstrumentType | null,
  form: INewStartegyForm
): INewStartegyForm => {
  const index =
    form.tradingInstrument.type === "options" &&
    form.tradingInstrument.options.underlying === "index"
      ? form.ticker.index
      : null;

  const defaultForm: INewStartegyForm = {
    ...form,
    ticker: {
      etfs: form.tradingInstrument.type === "stocks/etfs" ? form.ticker.etfs : null,
      equities: form.tradingInstrument.type === "stocks/etfs" ? form.ticker.equities : null,
      basket: form.tradingInstrument.type === "baskets" ? form.ticker.basket : null,
      index,
      cryptos: form.tradingInstrument.type === "cryptos" ? form.ticker.cryptos : null,
    },
    direction: form.direction || "long",
    error: form.error,
    tradingInstrument: {
      ...form.tradingInstrument,
      futures: null,
    },
  };
  if (activeInsrument !== "baskets") {
    defaultForm.tradingInstrument.equityBaskets = createDefaultEquityBaskets();
  }
  if (activeInsrument !== "options") {
    defaultForm.tradingInstrument.options = clearOptions();
  }
  return defaultForm;
};

export const makeOptionsConfig = (form: INewStartegyForm) => {
  const {
    minDays,
    maxDays,
    stopGain,
    stopLoss,
    ratio,
    optionsType,
    percentage,
    strikeRatioType,
    underlying,
    ticker,
    allocationStyle,
    exitStyle,
  } = form.tradingInstrument.options;

  const strike_ratio = strikeRatioType.replace(
    "XX",
    strikeRatioType.includes("percentage")
      ? percentage.value
      : strikeRatioType.includes("ATM")
      ? ""
      : ratio.value
  );

  const convertedStopGain = Number(stopGain.value);
  const convertedStopLoss = Number(stopLoss.value);

  return {
    option_type: optionsType,
    expiration_min_days: Number(minDays.value),
    expiration_max_days: Number(maxDays.value),
    stop_gain: convertedStopGain === 0 ? null : convertedStopGain,
    stop_loss: convertedStopLoss === 0 ? null : convertedStopLoss,
    strike_ratio,
    derivative_type: "options" as DerivativeOptionType,
    underlying_type: underlying,
    underlying: ticker || "",
    strike_bet: allocationStyle,
    keep_until_maturity: exitStyle === "hold-to-maturity" ? true : false,
  };
};

export const makeCondition = (list: OperatorType[] | null) => {
  const formatedList = list
    ? list
        .map((i) =>
          i.isNumber
            ? `${i.numValue}`
            : i.userID
            ? `${convertOperatorToLogic(i.value)}-${i.userID}`
            : convertOperatorToLogic(i.value)
        )
        .filter((i) => i.length !== 0)
    : null;
  return formatedList || [];
};

export const makeBasketConfig = (
  equityBaskets: IEquitiesBaskets,
  instrumentType: TradingInstrumentType | null
) => {
  const {
    updateFrequency,
    rebalanceFrequency,
    weightScheme,
    basket,
    tickers,
    advancedFilter,
    metricsList,
    maxAllowedWeight,
  } = equityBaskets;

  const is_custom_basket = basket === "custom-basket";

  return (
    (instrumentType === "baskets" && {
      rebalance_freq: rebalanceFrequency,
      trading_basket: is_custom_basket ? tickers?.join(",") : basket,
      is_custom_basket,
      update_freq: updateFrequency,
      weight_scheme: weightScheme,
      advanced_filtering_condition: convertToMetrics(metricsList, advancedFilter),

      max_aum_fraction:
        Number.isNaN(Number(maxAllowedWeight)) || maxAllowedWeight === ""
          ? 100
          : Number(maxAllowedWeight),
    }) ||
    {}
  );
};

export const prepareNewStartegyData = (form: INewStartegyForm) => {
  const { name, type, ticker, direction, date } = form;
  const { entry, exit } = form.conditions;
  const instrumentType = form.tradingInstrument.type;
  const equityBaskets = form.tradingInstrument.equityBaskets;
  const exit_condition = makeCondition(exit.list);
  const entry_condition = makeCondition(entry.list);
  const tickerType = ticker.equities ? "equities" : ticker.cryptos ? "cryptos" : "etfs";

  const tradingInstrument = instrumentTypeMapToDeEqEtBa(instrumentType);

  const requestData: INewStrategyData = {
    name: name || "Performance live view",
    config: {
      type,
      start_date: date.start || undefined,
      end_date: date.end || undefined,
      entry_condition,
      exit_condition: exit_condition.length ? exit_condition : undefined,
      trading_instrument: tradingInstrument,
      ...(instrumentType === "baskets" && makeBasketConfig(equityBaskets, instrumentType)),
      ...(instrumentType === "options" && makeOptionsConfig(form)),
    },
  };
  if (!["options", "futures"].includes(instrumentType || "")) {
    if (direction) requestData.config.direction = direction;
    requestData.config.trading_ticker =
      instrumentType !== "baskets" ? ticker[tickerType] : undefined;
  }

  // if (entry_condition.length) requestData.config.entry_condition = entry_condition;
  // if (exit_condition.length) requestData.config.exit_condition = exit_condition;

  if (form.benchmark) {
    requestData.config.benchmark = form.benchmark;
  }
  return requestData;
};

export const prepareStrategiesCommonList = (
  list: ICommonStrategyData[] | null
): IStartegyCard[] => {
  if (!list) return [];
  const createConfig = (): IStartegyCard["config"] => ({
    type: "overlay",
    direction: "long",
    trading_ticker: null,
    entry_condition: null,
    exit_condition: null,
    trading_instrument: null,
  });

  return list.map((strategy) => ({
    id: 0,
    key: strategy.key,
    name: strategy.key,
    config: createConfig(),
    updated_at: null,
    created_at: null,
    user: {
      first_name: null,
      last_name: null,
      email: null,
    },
    company_id: null,
  }));
};

export const prepareStrategiesList = (list: IStrategyData[]): IStartegyCard[] => {
  return list.map((strategy) => ({
    id: strategy.id,
    key: strategy.name,
    name: strategy.name,
    config: strategy.config,
    updated_at: strategy.updated_at,
    created_at: strategy.created_at,
    user: {
      first_name: strategy.user?.first_name || null,
      last_name: strategy.user?.last_name || null,
      email: strategy.user?.email || null,
    },
    company_id: strategy.company_id,
    permissions: strategy.permissions,
    show_on_home: strategy.show_on_home,
  }));
};

export const isPermissionsAllow = (
  permissions: IStrategyData["permissions"],
  value: PermisionsEnum
) => {
  if (permissions?.has_delete && value === PermisionsEnum.DELETE) return true;
  if (permissions?.has_read && value === PermisionsEnum.READ) return true;
  if (permissions?.has_write && value === PermisionsEnum.UPDATE) return true;
  return false;
};

export const prepareEditForm = (
  strategy: IUserStartegyDataResponse,
  metricsList: IMetricIndicator[] | null
): INewStartegyForm => {
  const { name, config } = strategy;

  const getInstrument = (instrument: TradingInstrumentConfigType | null): TradingInstrumentType => {
    if (instrument === "derivatives") return "options";
    if (instrument === "equities" || instrument === "etfs") return "stocks/etfs";
    if (instrument === "cryptos") return "cryptos";
    return "baskets";
  };

  const tickerType = ["equities", "etfs"].includes(config.trading_instrument || "")
    ? "equities"
    : config.trading_instrument === "cryptos"
    ? "cryptos"
    : null;

  const form: INewStartegyForm = {
    ...createNewStartegyForm(),
    name,
    type: config.type,
    direction: config.direction || "long",
    date: {
      start: config.start_date ? config.start_date : null,
      end: config.end_date ? config.end_date : null,
    },
    conditions: {
      entry: createCondition(config?.entry_condition || null),
      exit: createCondition(config?.exit_condition || null),
    },
    tradingInstrument: {
      type: getInstrument(config.trading_instrument),
      equityBaskets: {
        ...createDefaultEquityBaskets(),
        ...createEquityBaskets(config, metricsList),
      },
      futures: null,
      options: createOptions(config),
    },
    isChanged: false,
    benchmark: config.benchmark || null,
  };

  if (tickerType) form.ticker[tickerType] = config.trading_ticker || null;

  return form;
};

export const preparePerformaceData = (performanceData: IDataPerformance): IStrategyBase[] => {
  const [key] = Object.keys(performanceData.equity);
  const isBencmarkData =
    typeof performanceData.equity[key] !== "number" && performanceData.equity[key] !== null;

  const createEquitylineData = (fieldKey: keyof IBenchmarkData): Record<string, number | null> => {
    if (isBencmarkData) {
      const equity = performanceData.equity as Record<string, IBenchmarkData>;
      return Object.entries(equity).reduce<Record<string, number | null>>(
        (acc, [date, valueRec]) => {
          acc[date] = valueRec[fieldKey] as number | null;
          return acc;
        },
        {}
      );
    } else {
      return performanceData.equity as Record<string, number | null>;
    }
  };

  const createMetricData = () => {
    return performanceData.metrics.reduce<Record<string, IBenchmarkMetric>>((acc, metric) => {
      acc[metric.metric] = {
        value: isBencmarkData ? metric.strategy || null : metric.value || null,
        benchmark: metric.benchmark,
      };
      return acc;
    }, {});
  };

  try {
    const baseStrategy: IStrategyBase = {
      name: "",
      key: "",
      data: {
        equity: {
          equityLine: createEquitylineData("strategy"),
          benchmarkLine: isBencmarkData ? createEquitylineData("benchmark") : null,
        },
        metrics: createMetricData(),
      },
      parameters: {},
      type: [],
    };

    return [baseStrategy];
  } catch (e) {
    console.warn("Can not convert performance data properly!");
    return [];
  }
};

export const findDropdownItem = (value: string | null, list: ItemType<string>[]) => {
  return value ? list.find((i) => i.value === value) || null : null;
};

export const isFormTouched = (
  form: INewStartegyForm,
  prevForm?: INewStartegyForm | null
): boolean => {
  let isTouched = false;
  if (prevForm) {
    // for basket form
    if (form.tradingInstrument.type === "baskets") {
      const slicedPrevBasketForm: IEquitiesBaskets = {
        ...prevForm.tradingInstrument.equityBaskets,
      };
      const slicedBasketForm: IEquitiesBaskets = { ...form.tradingInstrument.equityBaskets };
      slicedBasketForm.metricsList = null;
      slicedPrevBasketForm.metricsList = null;

      if (JSON.stringify(slicedPrevBasketForm) !== JSON.stringify(slicedBasketForm))
        isTouched = true;
      if (prevForm.direction !== form.direction) isTouched = true;
    }
    // for option form
    else if (form.tradingInstrument.type === "options") {
      if (
        JSON.stringify(prevForm.tradingInstrument.options) !==
        JSON.stringify(form.tradingInstrument.options)
      )
        isTouched = true;
    }
    // for stock/etfs form
    else if (form.tradingInstrument.type === "stocks/etfs") {
      if (
        prevForm.ticker.etfs !== form.ticker.etfs ||
        prevForm.ticker.equities !== form.ticker.equities
      )
        isTouched = true;
      if (JSON.stringify(prevForm.direction) !== JSON.stringify(form.direction)) isTouched = true;
    }
    // for cryptos form
    else if (form.tradingInstrument.type === "cryptos") {
      if (prevForm.ticker.cryptos !== form.ticker.cryptos) isTouched = true;
      if (JSON.stringify(prevForm.direction) !== JSON.stringify(form.direction)) isTouched = true;
    }
    // for common options
    if (JSON.stringify(prevForm.date) !== JSON.stringify(form.date)) isTouched = true;
    if (JSON.stringify(prevForm.conditions) !== JSON.stringify(form.conditions)) isTouched = true;
    if (prevForm.name !== form.name) isTouched = true;
    if (prevForm.type !== form.type) isTouched = true;
    if (prevForm.benchmark !== form.benchmark) isTouched = true;
  } else {
    isTouched = true;
  }

  return isTouched;
};

const typeMapper: Record<TradingInstrumentType, Omit<TradingInstrumentConfigType, "etfs">> = {
  options: "derivatives",
  "stocks/etfs": "equities",
  baskets: "baskets",
  cryptos: "cryptos",
};

export const instrumentTypeMapToDeEqEtBa = (
  instrumentType: TradingInstrumentType | null
): TradingInstrumentConfigType | null => {
  const types = Object.entries(typeMapper);
  const [_, exitType] = types.find((t) => t[0] === instrumentType) || [];

  return exitType === "equities" ? "equities" : (exitType as TradingInstrumentConfigType) || null;
};

interface IGetTickerNameParams {
  selectedTickersList: string | null;
  basket: TradingBasketType | null;
  ticker: TickerType;
  optionTicker: string | null;
  instrument: TradingInstrumentType | null;
}

export const getTickerName = ({
  selectedTickersList,
  basket,
  ticker,
  optionTicker,
  instrument,
}: IGetTickerNameParams) => {
  const tickerName = ticker.equities || ticker.etfs;
  if (instrument === "baskets")
    return selectedTickersList || (basket !== "custom-basket" && basket) || null;
  if (instrument === "stocks/etfs") return tickerName;
  if (instrument === "options") return optionTicker;

  return null;
};

export const createPerformanceSeriesData = (
  performanceData: IStrategyBase | null
): StrategyPerformanceChartType => {
  const equity = performanceData?.data.equity.equityLine || null;
  const benchmark = performanceData?.data.equity.benchmarkLine || null;
  const series = [
    {
      name: "strategy",
      data: equity
        ? Object.entries(equity)
            .map(([date, value]) => [dayjs(date).format(DATE_ISO), value])
            .slice()
        : [],
    },
  ];
  if (benchmark && performanceData) {
    series.push({
      name: "benchmark",
      data: benchmark
        ? Object.entries(benchmark).map(([date, value]) => [dayjs(date).format(DATE_ISO), value])
        : [],
    });
  }
  // removing last item from series
  for (const serie of series) {
    serie.data.pop();
  }

  return series;
};

export const createStrategyTableColumns = (isBenchmark: boolean) => {
  const cols = [
    {
      Header: "Metric Name",
      accessor: "name",
      canSort: false,
      minWidth: 300,
    },
    {
      Header: "Strategy",
      accessor: "value",
      disableSortBy: true,
      minWidth: 180,
    },
  ];
  if (isBenchmark)
    cols.push({
      Header: "Benchmark",
      accessor: "benchmark",
      disableSortBy: true,
      minWidth: 180,
    });
  return cols;
};

export const searchStringInText = (name: string, searchText?: string): string[] => {
  try {
    if (searchText === undefined || searchText?.length === 0) return [];

    const regexp = new RegExp(`${searchText}`, "gi");
    const indexes = Array.from(name.matchAll(regexp), ({ index }) => index);
    const result: string[] = [];
    let shift = 0;
    indexes.forEach((index, idx) => {
      if (index !== null && index !== undefined) {
        const str = name.slice(shift, index);
        if (str) result.push(str);

        const findSubStr = name.slice(index, index + searchText.length);
        result.push(findSubStr);
        shift = index + searchText.length;
      }
    });

    if (shift < name.length) {
      result.push(name.slice(shift));
    }

    return result;
  } catch {
    return [];
  }
};
