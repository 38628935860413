import { FC, BaseSyntheticEvent, SetStateAction, Dispatch, useEffect } from "react";

import { InputItem, ItemType, Popover, RadioOption, RadioSelect, Tag } from "@/components";
import { FILTER_OPERATORS, LIST_FILTER_OPERATORS, regex } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { IFilter } from "@/types";

import styles from "./Metrics.module.scss";

interface IProps {
  setMetricSelected: Dispatch<SetStateAction<Record<string, IFilter>>>;
  metricSelectd: Record<string, IFilter>;
  metrics: string[];
  setMetrics: Dispatch<SetStateAction<string[]>>;
  operationMetrics: Record<string, string>;
  setOperationMetrics: Dispatch<SetStateAction<Record<string, string>>>;
}

export const Metrics: FC<IProps> = ({
  setMetricSelected,
  metricSelectd,
  setMetrics,
  metrics,
  setOperationMetrics,
  operationMetrics,
}) => {
  const { drsMeta } = useAppSelector(discoverDrsMetaState);
  useEffect(() => {
    setMetrics(drsMeta?.format?.metrics ?? []);
  }, [drsMeta?.format?.metrics, setMetrics]);

  useEffect(() => {
    if (!metrics.length) return;
    const defaultOperators = metrics.reduce((acc, dim) => {
      acc = { ...acc, [dim]: FILTER_OPERATORS.eq.id };
      return acc;
    }, {});
    setOperationMetrics(defaultOperators);
  }, [metrics, setOperationMetrics]);

  const handleInputValues = (name: string) => (e: BaseSyntheticEvent) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      const cloneObj = { ...metricSelectd };
      delete cloneObj[name];
      setMetricSelected(cloneObj);
      return;
    }
    setMetricSelected({
      ...metricSelectd,
      [name]: {
        operator: operationMetrics[name],
        field: name,
        value: num,
      },
    });
  };

  const handleSelectOperator = (name: string, item: ItemType<string>) => () => {
    setOperationMetrics((state) => ({
      ...state,
      [name]: item.key,
    }));
  };

  const handleRemove = (key: string) => () => {
    setMetrics(metrics.filter((label) => label !== key));
  };

  if (!metrics.length) {
    return null;
  }

  const content = (metric: string) => {
    return (
      <div className={styles.content}>
        <RadioSelect>
          {LIST_FILTER_OPERATORS.map((item) => (
            <RadioOption
              key={item.key}
              selected={item.key === operationMetrics?.[metric]}
              onClick={handleSelectOperator(metric, item)}
            >
              {item.value}
            </RadioOption>
          ))}
        </RadioSelect>
        <div className={styles.inputItem}>
          <InputItem
            noPaddingBottom
            onChange={handleInputValues(metric)}
            label=""
            name={metric}
            type="number"
            symbol=""
            tooltipHelp={metric}
            value={metricSelectd?.[metric]?.value?.toString() ?? ""}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionName}>
        <span>Metrics</span>
      </div>
      {metrics.map((m) => {
        const metric = m.replace(regex, "").trim();
        return (
          <Popover key={metric} content={<div>{content(metric)}</div>}>
            <Tag color="primary" canDelete={metrics.length > 1} onDelete={handleRemove(metric)}>
              <div className={styles.tagContent}>
                <div>{metric}</div>
                {metricSelectd?.[metric] && (
                  <div>
                    [
                    <span>
                      {
                        LIST_FILTER_OPERATORS.find((oper) => oper.key === operationMetrics[metric])
                          ?.value
                      }{" "}
                    </span>
                    {metricSelectd[metric].value}]
                  </div>
                )}
              </div>
            </Tag>
          </Popover>
        );
      })}
    </div>
  );
};
