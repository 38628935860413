import { useEffect, useState } from "react";

import { ItemType } from "@/components";
import { useAppDispatch } from "@/hooks";
import { removeCSVFile } from "@/store/strategies/customIndicator";

export const useUploadSignalsCSV = (fileName: string | null) => {
  const [selectedCSV, setSelectedCSV] = useState<ItemType<string> | null>(null);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();

  const onSelectCSVHandler = (name: string, item: ItemType<string>): void => {
    setSelectedCSV(item);
  };
  const onUploadCSVHandler = (files: FileList | null): void => {
    const file = files ? files[0] : null;
    if (file) {
      setCsvFile(file);
      setSelectedCSV({ key: file.name, value: file.name });
    }
  };

  const onRemoveCSVHandler = (): void => {
    setSelectedCSV(null);
    setCsvFile(null);
    if (fileName) dispatch(removeCSVFile());
  };

  useEffect(() => {
    if (fileName) setSelectedCSV({ key: fileName, value: fileName });
  }, [fileName]);

  const canDelete = !!selectedCSV;
  const isConstructorDisabled = !!selectedCSV;

  return {
    csvList: selectedCSV ? [selectedCSV] : [],
    selectedCSVFile: selectedCSV,

    csvFile,
    canDelete,
    isConstructorDisabled,

    onSelectCSVHandler,
    onUploadCSVHandler,
    onRemoveCSVHandler,
  };
};
