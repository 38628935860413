import { Fragment, useEffect, useState } from "react";
import { useTable, useSortBy, Column, useExpanded, usePagination } from "react-table";

import { useFixedColumn } from "@/hooks";
import { ReactComponent as Arrow } from "@/images/arrow.svg";

import { SortArrows } from "./Table";

import styles from "./Table.module.scss";

export type ExtendedColumnsSort = Array<
  Column<object> & { minWidth: number; isTruncated?: boolean; canExpand?: boolean }
>;

interface IProps<T> {
  columns: ExtendedColumnsSort;
  data: T[];
  scrollWidth?: number;
  onSelect?: (row: T) => void;
  expandable?: {
    expandedRowRender: (row: object) => JSX.Element;
    rowExpandable: (row: object) => boolean;
  };
  size?: "small" | "medium";
  currentPage?: number;
  perPage?: number;
  resetPage?: () => void;
  isLoading?: boolean;
  onSort?: (sortObj: Record<string, boolean>) => void;
  isNotClickable?: boolean;
  hasResetSort?: boolean;
  oneColumnSort?: boolean;
}

export function TableSort<T extends object>({
  columns,
  data,
  expandable,
  onSelect,
  size,
  currentPage,
  perPage,
  resetPage,
  isLoading,
  onSort,
  isNotClickable,
  hasResetSort,
  oneColumnSort,
}: IProps<T>) {
  const { itemRef, showScroll, tableColumnsWidth } = useFixedColumn(columns);
  const [sorting, setSorting] = useState<Record<string, boolean>>({});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (currentPage) gotoPage(currentPage - 1);
  }, [currentPage, gotoPage]);

  useEffect(() => {
    if (perPage) setPageSize(perPage);
    else setPageSize(data.length);
  }, [perPage, data, setPageSize]);

  useEffect(() => {
    setSorting({});
  }, [hasResetSort]);

  const sort = (column: any) => {
    let sortObj = { ...sorting };
    if (sortObj[column.id] === undefined) {
      sortObj = {
        ...(!oneColumnSort && { ...sortObj }),
        [column.id]: false,
      };
    } else if (sortObj[column.id] === false) {
      sortObj = {
        ...(!oneColumnSort && { ...sortObj }),
        [column.id]: true,
      };
    } else {
      delete sortObj[column.id];
    }
    setSorting(sortObj);
    if (onSort) {
      onSort(sortObj);
    }
  };

  const getHeaderProps = (column: any) => {
    const prop = column.getHeaderProps(column.getSortByToggleProps());
    const onClickHandler = () => {
      if (prop.onClick && resetPage) {
        resetPage();
      }
      sort(column);
    };
    return {
      ...prop,
      onClick: onClickHandler,
    };
  };

  return (
    <div className={styles.tableContainer} ref={itemRef}>
      {data.length > 0 && (
        <table
          {...getTableProps()}
          className={`${styles.table} ${
            size ? (size === "small" ? styles.small : styles.medium) : ""
          }`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`${styles.tableRow} ${isNotClickable ? styles.notClickable : ""}`}
                style={{ gridTemplateColumns: tableColumnsWidth }}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...getHeaderProps(column)}
                      className={`${styles.tableHeaderCell} ${showScroll ? styles.showScroll : ""}`}
                    >
                      <span className={styles.cellTextExpandTable}>{column.render("Header")}</span>
                      {column.canSort && (
                        <SortArrows
                          isSorting={sorting?.[column.id] !== undefined}
                          isDescDir={sorting?.[column.id]}
                          isSortable={column.canSort}
                        />
                      )}
                      <span className={styles.tableHeaderSeparator}></span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const isRowExpandable = expandable?.rowExpandable(row);
              return (
                <Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className={styles.tableRow}
                    style={{ gridTemplateColumns: tableColumnsWidth }}
                    onClick={() => (onSelect ? onSelect(row.original as T) : null)}
                  >
                    {row.cells.map((cell, idx) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => row.toggleRowExpanded()}
                          className={`${styles.tableRowCell} ${
                            showScroll ? styles.showScroll : ""
                          }`}
                        >
                          <div className={styles.tableRowWrappwer}>
                            <div style={{ wordBreak: "break-word" }} className={styles.simpleCell}>
                              {columns[idx].isTruncated ? (
                                <div className={styles.truncated} title={cell.value}>
                                  <span>{cell.render("Cell")}</span>
                                </div>
                              ) : (
                                <div
                                  style={{ wordBreak: "break-word" }}
                                  className={styles.simpleCell}
                                >
                                  {cell.render("Cell")}
                                </div>
                              )}
                            </div>
                            {idx === 0 && isRowExpandable && (
                              <span className={styles.arrow}>
                                <Arrow
                                  className={`${
                                    row.isExpanded ? styles.arrowUp : styles.arrowDown
                                  }`}
                                />
                              </span>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr
                    className={`${
                      isRowExpandable && row.isExpanded ? styles.expand : styles.expandHide
                    }`}
                  >
                    <td colSpan={columns.length}>{expandable?.expandedRowRender(row.original)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      {data.length === 0 && !isLoading && (
        <div className={styles.emptyData}>Data not available yet.</div>
      )}
    </div>
  );
}
