import { useEffect, useMemo, useRef } from "react";

import { useAppDispatch } from "@/hooks";
import { fetchMarkMessagesAsReaded } from "@/store/odinchat";

export const ITEM_DATA_NAME = "data-message-id";

const FIRST_RENDER_DELAY = 0;

export const useMessagesObserevr = (
  loading: boolean,
  unreaded: string[] | null,
  isAutoScroll: boolean
) => {
  const newMessageReceivedID = useRef((unreaded && unreaded.slice(0, 1)[0]) || null);
  const unreadedRef = useRef<string[] | null>(unreaded);
  const isFerstRender = useRef(true);
  const dispatch = useAppDispatch();

  const obserever = useMemo(() => {
    return new IntersectionObserver(([entry], observer) => {
      // const clientRect = entry.boundingClientRect;
      // const isVisible =
      //   entry.isIntersecting || (clientRect.top >= 0 && clientRect.top <= window.innerHeight);

      const unreadedMsgs = unreadedRef.current;
      if (entry.isIntersecting && unreadedMsgs && unreadedMsgs?.length > 0 && !isAutoScroll) {
        observer.unobserve(entry.target);
        dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids: unreadedMsgs }));
      }
    }, {});
  }, []);

  // observing unreaded messgaes
  useEffect(() => {
    // set first unreaded message count once
    if (unreaded && unreaded.length > 0 && newMessageReceivedID.current === null) {
      newMessageReceivedID.current = unreaded.slice(0, 1)[0] || null;
    }
    // set all unreaded messages
    unreadedRef.current = unreaded;
    if (unreaded?.length && !isFerstRender.current) {
      const id = unreaded.slice(0, 1)[0];
      const item = document.querySelector(`[${ITEM_DATA_NAME}="${id}"]`);
      if (item) setTimeout(() => obserever.observe(item), FIRST_RENDER_DELAY);
    }
    if (isAutoScroll && unreaded?.length) {
      dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids: unreaded }));
    }
  }, [unreaded, obserever]);

  useEffect(() => {
    // removing observer
    return () => {
      obserever.disconnect();
    };
  }, []);

  // mark messages as readed on first render
  useEffect(() => {
    if (!loading && unreadedRef.current?.length && isFerstRender.current) {
      const ids = unreadedRef.current.map((id) => id);
      dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids }));
      isFerstRender.current = false;
    }
    // console.log("UNREADED:", unreadedRef.current, loading);
  }, [loading]);

  return [newMessageReceivedID.current];
};
