import { FC, useEffect, useState } from "react";

import { Button, Heading, InfoHeading, LoaderWrapper } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import PlusIcon from "@/images/plus.svg";
import { companiesState, fetchCompanies } from "@/store/companies";
import { profileInfo } from "@/store/profile";
import { ICompany } from "@/types/company";

import { CompanyEdit } from "./components/CompanyEdit/CompanyEdit";
import { CompanyTable } from "./components/CompanyTable/CompanyTable";

import styles from "./CompanyManagement.module.scss";

export const CompanyManagement: FC = () => {
  const [selected, setSelected] = useState<ICompany | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { companies, isLoading } = useAppSelector(companiesState);
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  const len = companies?.length;

  useEffect(() => {
    if (len) return;
    dispatch(fetchCompanies());
  }, [len, dispatch, profile?.email]);

  return (
    <LoaderWrapper loading={isLoading} atom transparent>
      <div className={styles.container}>
        <InfoHeading>Company Management</InfoHeading>
        <div className={styles.rowGroup}>
          <Heading type="h3">Companies List</Heading>
          <div>
            <Button icon={PlusIcon} text="Add Company" onClick={() => setShowModal(true)} />
          </div>
        </div>

        <CompanyTable
          companyData={companies || []}
          isLoading={isLoading}
          setSelected={setSelected}
        />
        <CompanyEdit
          showModal={showModal}
          selected={selected}
          setShowModal={setShowModal}
          setSelected={setSelected}
        />
      </div>
    </LoaderWrapper>
  );
};
