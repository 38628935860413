import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button, ButtonLink, InputItem, Loader } from "@/components";
import { Dialog } from "@/components/Dialogs/Dialog";
import { ROUTES } from "@/constants";
import { useForgotForm } from "@/features";

import styles from "./UserForgotPassword.module.scss";

const formInit: Record<string, { value: string; error: string }> = {
  email: {
    value: "",
    error: "",
  },
  password: {
    value: "",
    error: "",
  },
  repassword: {
    value: "",
    error: "",
  },
};

export const UserForgotPassword: FC = () => {
  const navigate = useNavigate();
  const { form, loading, completeStatus, showPasswords, submitHandler, changeHandler } =
    useForgotForm(formInit);

  const loginHandle = useCallback(() => {
    navigate(ROUTES.login.path);
  }, []);

  const isFormComplete = completeStatus.email || completeStatus.password || completeStatus.error;

  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>{loading && <Loader white />}</div>
      {completeStatus.email && (
        <Dialog
          title="Reset Password"
          subtitle="Password reset instructions sent!"
          type="success"
          messages={["Please check your email."]}
          noAction
        />
      )}
      {completeStatus.password && (
        <Dialog
          title="Reset Password"
          subtitle="Your password was successfully changed!"
          type="success"
          messages={["Please go to the login page to access your account."]}
          onSubmit={loginHandle}
          buttonText="LogIn"
        />
      )}
      {completeStatus.error && (
        <Dialog
          title="Reset Password"
          subtitle="Reset password failed!"
          // onSubmit={completeStatus.error ? undefined : submitErrorHandler}
          type="error"
          messages={completeStatus.error}
        />
      )}
      {!isFormComplete && (
        <div className={styles.formContainer}>
          <p className={styles.title}>Reset Password</p>
          {loading && <div className={styles.frontBg}></div>}
          <form onSubmit={submitHandler} className={styles.form}>
            {showPasswords ? (
              <Fragment>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="New Password"
                    name="password"
                    type="password"
                    onChange={changeHandler}
                    error={form.password.error}
                    value={form.password.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Confirm new Password"
                    name="repassword"
                    type="password"
                    onChange={changeHandler}
                    error={form.repassword.error}
                    value={form.repassword.value}
                    disabled={loading}
                    themed
                  />
                </div>
              </Fragment>
            ) : (
              <div className={styles.inputWrapper}>
                <InputItem
                  label=""
                  placeholder="email@company.com"
                  name="email"
                  type="email"
                  onChange={changeHandler}
                  error={form.email.error}
                  value={form.email.value}
                  disabled={loading}
                  themed
                />
              </div>
            )}
            <div className={styles.btnGroup}>
              {showPasswords ? (
                <Button
                  text="Reset"
                  type="white"
                  disabled={
                    (!form.password.value && showPasswords) ||
                    !!form.password.error ||
                    !!form.repassword.error
                  }
                />
              ) : (
                <Button
                  text="Send email"
                  type="white"
                  disabled={(!form.email.value && !showPasswords) || !!form.email.error}
                />
              )}
            </div>
          </form>
          <ButtonLink to={ROUTES.root.path} text="To Home" transparent active />
        </div>
      )}
    </div>
  );
};
