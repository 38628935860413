import { StrategyMapType } from "@/types";

export const strategyType: StrategyMapType = {
  core: {
    key: "core",
    label: "Core",
  },
  overlay: {
    key: "overlay",
    label: "Overlay",
  },
  ticker: {
    key: "ticker",
    label: "Ticker",
  },
  portfolio: {
    key: "portfolio",
    label: "Portfolio",
  },
  benchmark: {
    key: "benchmark",
    label: "Benchmark",
  },
};
