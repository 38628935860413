import { FC, useEffect, useState } from "react";

import { InfoHeading, LoaderWrapper } from "@/components";
import { SideBarKeyEnum } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { useAppSelector, usePageInfo } from "@/hooks";
import { discoverSearchState } from "@/store/discover/discoverSearch";
import { discoverDrsListState } from "@/store/discover/drsList";
import { IDrs, IDrsSerachResult } from "@/types";

import { Categories } from "../components/Categories.tsx/Categories";
import { Search } from "../components/Search/Search";
import { SearchResult } from "../components/SearchResult/SearchResult";
import { SubcategoryInner } from "../components/SubcategoryInner/SubcategoryInner";

import styles from "./DiscoverMain.module.scss";

export const DiscoverMain: FC = () => {
  const [selectedDrs, setSelectedDrs] = useState<IDrs[]>([]);
  const [searchResult, setSearchResult] = useState<IDrsSerachResult[]>([]);

  const { data, isLoading } = useAppSelector(discoverSearchState);
  const { isLoading: isLoadingDrsList, whiteList } = useAppSelector(discoverDrsListState);
  const { textInfo, video } = usePageInfo(SideBarKeyEnum.DATA);

  useEffect(() => {
    if (!data?.length) return;
    setSearchResult(data.filter((drs) => whiteList.includes(drs.drs_id)));
  }, [data, whiteList]);

  return (
    <div className={styles.container}>
      <InfoHeading helpInfo={textInfo} tutorialLink={video} faqList={faqDescriptionList.discover}>
        Catalog
      </InfoHeading>
      {/* <div className={styles.headerInfo}>
        <div className={styles.inner}>Search and Access 15M+ Time Series</div>
      </div> */}
      <section className={styles.sectionWrapper}>
        <Search />
        <LoaderWrapper loading={isLoading || isLoadingDrsList} atom fullWidth>
          <div className={styles.innerContent}>
            {searchResult.length ? (
              <SearchResult setSearchResult={setSearchResult} searchResult={searchResult} />
            ) : selectedDrs?.length ? (
              <SubcategoryInner setSelectedDrs={setSelectedDrs} selectedDrs={selectedDrs} />
            ) : (
              <Categories selectedDrs={setSelectedDrs} />
            )}
          </div>
        </LoaderWrapper>
      </section>
    </div>
  );
};
