import { AxiosResponse, CancelTokenSource } from "axios";

import { ICSVReturn, IConfigData, IPerformanceResponseData } from "@/types/warRoom";

import axios from "./axios.config";

export const WarRoomService = {
  abort: new AbortController(),
  getPerformance(
    config: IConfigData,
    query?: string
  ): Promise<AxiosResponse<{ result: IPerformanceResponseData | null }>> {
    this.abort = new AbortController();
    return axios.post(`/war-room${query || ""}`, config, { signal: this.abort.signal });
  },
  getPeriodReturns: (
    key: string,
    abort: CancelTokenSource
  ): Promise<AxiosResponse<{ result: Record<string, Record<string, number | null>> | null }>> =>
    axios.post(`/war-room/performance?ticker=${key || ""}`, undefined, {
      cancelToken: abort.token,
    }),
  uploadCSVFile: (data: FormData, url?: string): Promise<AxiosResponse<{ result: ICSVReturn }>> =>
    axios({
      method: "post",
      url: url ? url : "/return-csvs",
      data,
      headers: { "Content-Type": "multipart/form-data" },
    }),
  removeCSV: (id: string, url?: string): Promise<AxiosResponse<{ result: string }>> =>
    axios.delete(`${url ? url : "/return-csvs/"}${id}`),
};
