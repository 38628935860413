import { FC } from "react";

import { Button, FooterNavigation, InputItem, Loader } from "@/components";
import { Dialog } from "@/components/Dialogs/Dialog";
import { InputTextItem } from "@/components/InputItem/InputTextItem";

import { useDemoInviteForm } from "./hooks/useDemoInviteForm";

import styles from "./UserDemoInvite.module.scss";

const formInit: Record<string, { value: string; error: string }> = {
  name: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  company: {
    value: "",
    error: "",
  },
  message: {
    value: "",
    error: "",
  },
};

export const UserDemoInvite: FC = () => {
  const { form, loading, canRequest, isCompleted, submitHandler, changeHandler, onClose } =
    useDemoInviteForm(formInit);

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <div className={styles.loaderContainer}>{loading && <Loader white />}</div>

        {isCompleted ? (
          <div className={styles.infoContainer}>
            <Dialog
              title="Request a DEMO"
              subtitle="Your DEMO request was sent!"
              onSubmit={onClose}
              type="success"
              messages={[
                "Thanks for your request submission.",
                "A member of Odin-Ultra team will contact you shortly.",
              ]}
              buttonText="To Home"
            />
          </div>
        ) : (
          <div className={styles.formContainer}>
            <p className={styles.title}>Request a Demo</p>
            <form onSubmit={submitHandler} className={styles.form}>
              <div className={styles.formInnerWrapper}>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Full Name"
                    name="name"
                    type="name"
                    onChange={changeHandler}
                    error={form.name.error}
                    value={form.name.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Email"
                    name="email"
                    type="email"
                    onChange={changeHandler}
                    error={form.email.error}
                    value={form.email.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Company"
                    name="company"
                    type="company"
                    onChange={changeHandler}
                    error={form.company.error}
                    value={form.company.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputTextItem
                    label=""
                    placeholder="Message"
                    name="message"
                    onChange={changeHandler}
                    error={form.message.error}
                    value={form.message.value}
                    disabled={loading}
                    themed
                  />
                </div>
              </div>
              <Button text="Send Request" disabled={!canRequest || loading} type="white" />
            </form>
          </div>
        )}
      </div>
      <FooterNavigation />
    </div>
  );
};
