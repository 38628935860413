import { FC, useMemo } from "react";

import { ExtendedColumns, Menu, Table } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import FolderFocusIcon from "@/images/folder-focus.svg";
import FolderIcon from "@/images/folder.svg";
import { ReactComponent as SaveIcon } from "@/images/save.svg";
import { addDataToFolder } from "@/store/focus/folderSelected";
import { foldersManageState } from "@/store/focus/foldersManage";
import { FolderTypeEnum } from "@/types";
import { IChatTableData } from "@/types/odinChat";

import styles from "./TableChatObj.module.scss";

interface ITableChatObjProps {
  data: IChatTableData;
  messageId?: string;
}

export const TableChatObj: FC<ITableChatObjProps> = ({ data, messageId }) => {
  const { df, title } = data;
  const { list, isLoading: isLoadingFolders } = useAppSelector(foldersManageState);
  const dispatch = useAppDispatch();

  const folders = list.map((folder) => ({
    icon: "FOCUS" === folder.name ? FolderFocusIcon : FolderIcon,
    title: folder.name,
    key: folder.id,
  }));

  const handleSelectedFolder = (key: string | number) => {
    if (!messageId) return;
    const payload = {
      command: messageId.toString(),
      type: FolderTypeEnum.ODIN_CHAT,
      description: "OdinChat",
    };
    dispatch(addDataToFolder({ id: key, data: payload }));
  };

  const columns: ExtendedColumns = useMemo(() => {
    const cols = Object.keys(df).map((colName, idx) => ({
      Header: colName,
      accessor: colName,
      canSort: false,
      disableSortBy: true,
      minWidth: colName.length * 8 < 50 ? 50 : colName.length * 8 > 400 ? 400 : colName.length * 8,
    }));
    return cols;
  }, [df]);

  const rowData = useMemo(() => {
    try {
      const keys = Object.keys(df);
      let values = Object.values(df);
      if (values && typeof values[0] === "string")
        values = values.map((r) => [r as unknown as string | number | null]);
      const rows = values[0].map((_, index) => {
        const obj = keys.reduce<Record<string, number | string | null>>((acc, key, idx) => {
          acc[key] = values[idx][index];
          return acc;
        }, {});
        return obj;
      });
      return rows;
    } catch {
      console.warn("Some chat data table are wrong!");
      return [];
    }
  }, []);

  const canSvaeData =
    title && Array.isArray(title)
      ? !title?.map((t) => t.toLowerCase()).includes("odinchat main commands")
      : title && (title as string).toLowerCase() !== "odinchat main commands";

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <h2>{title}</h2>
        {canSvaeData && (
          <Menu menuItems={folders} handelMenuSelect={handleSelectedFolder}>
            <div className={styles.menuInner}>
              <div className={styles.icon}>
                <SaveIcon />
              </div>
              <span>Save</span>
            </div>
          </Menu>
        )}
      </div>
      <div className={styles.tableWrapper}>
        <Table columns={columns} data={rowData} small isNotClickable />
      </div>
    </div>
  );
};
