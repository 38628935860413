import { FC, Fragment, useState } from "react";

import {
  Dropdown,
  ItemType,
  DropdownMultiSearch,
  InfoHelpComponent,
  UploadCSVForm,
} from "@/components";
import { CustomUploadLabel } from "@/components/CustomLabel/CustomLabel";
import { customGroupsList } from "@/constants/warRoom";
import { useOuterClick } from "@/hooks";
import CSVInfoImage from "@/images/table.jpg";

import styles from "./CustomAssetsForm.module.scss";

interface ICustomAssetsFormProps {
  onSelectDesiredGroup: (name: string, item: ItemType<string>) => void;
  onSelectCSVHandler: (name: string, item: ItemType<string>[]) => void;
  onRemoveCSVHandler: () => void;
  onUploadCSVHandler: (files: FileList | null) => void;

  assetsGroupList: ItemType<string>[];
  onSelectAsset: (name: string, item: ItemType<string>[]) => void;
  selectedAssets: ItemType<string>[];

  desiredGroup: ItemType<string> | null;
  selectedCSVFile: ItemType<string>[] | null;
  isCustom?: boolean;
  csvList: ItemType<string>[];
  isLoading: boolean;
  canDelete: boolean;
  deletingFileName: string | null;
  disabled?: boolean;
}

const helpText = [
  "1. At least it needs a column named 'date'.",
  "2. 'date' column should be in YYYY-MM-DD format.",
  "3. CSV files need one or more daily return columns. Returns should be expressed as decimal values, NOT in percent.",
];

export const CustomAssetsForm: FC<ICustomAssetsFormProps> = ({
  csvList,
  isCustom,
  desiredGroup,
  selectedCSVFile,
  isLoading,
  canDelete,
  deletingFileName,
  disabled,

  assetsGroupList,
  selectedAssets,

  onSelectAsset,
  onSelectDesiredGroup,
  onSelectCSVHandler,
  onRemoveCSVHandler,
  onUploadCSVHandler,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [itemRef] = useOuterClick(setShowInfo, false);

  const showInfoHandler = () => {
    if (!showInfo) setShowInfo(true);
  };

  const InfoHelp = () => {
    return (
      <Fragment>
        {showInfo ? (
          <InfoHelpComponent
            textList={helpText}
            ref={itemRef}
            title="The CSV file data format help"
            image={CSVInfoImage}
            alt="csv-info"
          />
        ) : null}
      </Fragment>
    );
  };

  if (!isCustom) return null;

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.row}>
        <UploadCSVForm
          csvList={csvList}
          isLoading={isLoading}
          canDelete={canDelete}
          selectedCSVFiles={selectedCSVFile || []}
          deletingFileName={deletingFileName}
          onSelectCSVHandler={onSelectCSVHandler}
          onUploadCSVHandler={onUploadCSVHandler}
          onRemoveCSVHandler={onRemoveCSVHandler}
          CustomLabel={() => <CustomUploadLabel onClick={showInfoHandler} label="Select CSV" />}
          InfoHelpComponent={InfoHelp}
          isMultiSelect={true}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <Dropdown
            name="assets"
            label="Select desired assets group"
            list={customGroupsList}
            onSelect={onSelectDesiredGroup}
            selected={desiredGroup}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <DropdownMultiSearch
            name="desired-assets"
            label="Select Desired assets​"
            placeholder="Indices desired assets"
            list={assetsGroupList}
            onSelect={onSelectAsset}
            selected={selectedAssets}
            disable={false}
            transformTextToUpper={false}
            isRequired
          />
        </div>
      </div>
    </div>
  );
};
