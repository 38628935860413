import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { IPDFDataItem } from "@/types/pdf";

import { RootState } from ".";

export type ProgressType = "generating" | "rendering" | "done" | "none";

interface IInitialState {
  progress: ProgressType;
  pdfData: IPDFDataItem[] | null;
  togglePopup: boolean;
  title: string;
  downloadUrl: string | null;
}

const initialState: IInitialState = {
  progress: "none",
  pdfData: null,
  togglePopup: false,
  title: "",
  downloadUrl: null,
};

export const pdfReportSlice = createSlice({
  name: "pdfReport",
  initialState,
  reducers: {
    setTogglePopup: (state, { payload }: PayloadAction<"close" | "open" | undefined>) => {
      if (payload === "close") state.togglePopup = true;
      else if (payload === "open") state.togglePopup = false;
      else state.togglePopup = !state.togglePopup;
    },
    setPDFReportData: (state, { payload }: PayloadAction<IPDFDataItem[]>) => {
      state.pdfData = payload;
    },
    resetPDFData: (state) => {
      state.pdfData = null;
      state.togglePopup = false;
      state.progress = "none";
      state.title = "";
    },
    setTitle: (state, { payload }: PayloadAction<string>) => {
      if (state.progress === "none") {
        state.title = payload;
        state.pdfData = null;
      }
    },
    setPDFProgress: (state, { payload }: PayloadAction<{ status: ProgressType; url?: string }>) => {
      state.progress = payload.status;
      if (payload.status === "none") {
        state.pdfData = null;
        state.togglePopup = false;
      } else if (payload.status === "done" && payload.url) {
        state.togglePopup = false;
        state.downloadUrl = payload.url;
      }
    },
    setDownloadUrl: (state, { payload }: PayloadAction<string>) => {
      state.downloadUrl = payload;
    },
  },
  extraReducers: (builder) => {
    //
  },
});

const root = (state: RootState) => state;

export const selectPdfReport = createSelector(root, (state) => state.pdfReport);

export const {
  setTogglePopup,
  setPDFReportData,
  resetPDFData,
  setTitle,
  setPDFProgress,
  setDownloadUrl,
} = pdfReportSlice.actions;

export default pdfReportSlice.reducer;
