import { Dispatch, FC, useEffect, useMemo } from "react";

import { Popover, Tag, RadioOption, RadioSelect, Checkbox } from "@/components";
import { DATE_LEVEL_OPTIONS, GRANULARITY_TO_DATE_LEVEL_MAP } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { IAggregators, IIncludeTruncateFilters } from "@/types";
import { getLabel } from "@/utils";

import styles from "./DateDimensionsOptions.module.scss";

interface IProps {
  timeDimensionsOptions: any;
  setTimeDimensionsOptions: Dispatch<React.SetStateAction<any>>;
  timeDimensions: string[];
  aggregators: IAggregators[];
  includeTruncateFilters: IIncludeTruncateFilters;
  setIncludeTruncateFilters: Dispatch<React.SetStateAction<IIncludeTruncateFilters>>;
}

export const DateDimensionsOptions: FC<IProps> = ({
  timeDimensionsOptions,
  setTimeDimensionsOptions,
  timeDimensions,
  aggregators,
  includeTruncateFilters,
  setIncludeTruncateFilters,
}) => {
  const { drsMeta } = useAppSelector(discoverDrsMetaState);

  const garnularity = useMemo(
    () =>
      GRANULARITY_TO_DATE_LEVEL_MAP[
        drsMeta?.granularity as keyof typeof GRANULARITY_TO_DATE_LEVEL_MAP
      ] || GRANULARITY_TO_DATE_LEVEL_MAP.yearly,
    [drsMeta?.granularity]
  );

  const handleSelectOperator = (key: string, name: string) => () => {
    setTimeDimensionsOptions((state: any) => ({
      ...state,
      [name]: {
        label: `${[getLabel(name, drsMeta?.fields)]} [${key}]`,
        date_level: key,
        name,
      },
    }));
  };

  const hadleChangeIncludeTruncate = (checked: boolean) => {
    setIncludeTruncateFilters((state) => ({
      ...state,
      truncateFilterDates: checked,
    }));
  };

  useEffect(() => {
    if (!aggregators?.length || !drsMeta) {
      setTimeDimensionsOptions(null);
      return;
    }
    const dateOptions = timeDimensions.reduce((acc: any, name: string) => {
      acc = {
        ...acc,
        [name]: {
          label: `${[getLabel(name, drsMeta?.fields)]} [${garnularity}]`,
          date_level: garnularity,
          name,
        },
      };
      return acc;
    }, {});
    setTimeDimensionsOptions(dateOptions);
  }, [aggregators?.length, drsMeta, garnularity, setTimeDimensionsOptions, timeDimensions]);

  const content = (timeDimensions: any) => {
    return (
      <div className={styles.content}>
        <RadioSelect>
          {DATE_LEVEL_OPTIONS.map((item) => (
            <RadioOption
              key={item.id}
              selected={timeDimensions?.date_level === item.id}
              onClick={handleSelectOperator(item.id, timeDimensions?.name)}
            >
              {item.name}
            </RadioOption>
          ))}
        </RadioSelect>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionName}>
        <span>Date Dimensions</span>
      </div>
      {timeDimensions.map((dateField) => (
        <Popover
          key={dateField}
          disabled={!timeDimensionsOptions?.[dateField]}
          content={<div>{content(timeDimensionsOptions?.[dateField])}</div>}
        >
          <Tag color="primary">
            {timeDimensionsOptions?.[dateField]?.label || getLabel(dateField, drsMeta?.fields)}
          </Tag>
        </Popover>
      ))}
      {includeTruncateFilters.isTruncateFilterEnabled && (
        <Checkbox
          label="Include only complete intervals"
          id="Include"
          onChange={hadleChangeIncludeTruncate}
          checked={includeTruncateFilters.truncateFilterDates}
        />
      )}
    </div>
  );
};
