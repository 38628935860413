import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTES } from "@/constants";

import {
  ModelingPermissions,
  PortfolioBuilderPermissions,
  StrategiesPermissions,
} from "./ModelingPermissions";
import { MacroPlannerPermissions } from "../MacroPlanner/MacroPlannerPermissions";

export const ModelingRoot: FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.modeling.path} element={<ModelingPermissions />} />
      <Route
        path={`${ROUTES.portfolioBuilderRoot.path}/*`}
        element={<PortfolioBuilderPermissions />}
      />
      <Route path={`${ROUTES.strategiesBuilderRoot.path}/*`} element={<StrategiesPermissions />} />
      <Route path={"/macro-planner"} element={<MacroPlannerPermissions />} />
    </Routes>
  );
};
