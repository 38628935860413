import { FC, Fragment } from "react";

import { Footer } from "@/components/Footer/Footer";
import { LiveMarketSlider } from "@/features";
import { useMediaQuery } from "@/hooks/useMediaQuery";

import styles from "./FooterLayout.module.scss";

interface IProps {
  children: React.ReactNode;
  showFooter?: boolean;
  autoscroll?: boolean;
  showLiveMarketSlider?: boolean;
}

export const FooterLayout: FC<IProps> = ({
  children,
  showFooter = true,
  autoscroll,
  showLiveMarketSlider,
}) => {
  const isShowSlider = useMediaQuery("(max-width: 991px)");
  return (
    <Fragment>
      <div className={`${styles.container} ${autoscroll ? styles.scrolled : ""}`}>
        {showLiveMarketSlider && isShowSlider && (
          <div className={styles.sliderWrapper}>
            <LiveMarketSlider />
          </div>
        )}
        {children}
      </div>
      {showFooter && <Footer />}
    </Fragment>
  );
};
