/* eslint-disable @typescript-eslint/ban-ts-comment */
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useMemo } from "react";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

interface IProps {
  height: number;
  data: number[];
}

(function (H) {
  const defaultPlotOptions = H.getOptions().plotOptions,
    //@ts-ignore
    columnType = H.seriesTypes.column,
    wrap = H.wrap,
    each = H.each;
  //@ts-ignore
  defaultPlotOptions.lineargauge = H.merge(defaultPlotOptions.column, {});
  //@ts-ignore
  H.seriesTypes.lineargauge = H.extendClass(columnType, {
    type: "lineargauge",
    inverted: true,
    setVisible() {
      columnType.prototype.setVisible.apply(this);
      if (this?.markLine) {
        this.markLine[this.visible ? "show" : "hide"]();
      }
    },
    drawPoints() {
      // Draw the Column like always
      columnType.prototype.drawPoints.apply(this);

      // Add a Marker
      const chart = this.chart;
      const inverted = chart.inverted;
      const xAxis = this.xAxis;
      const yAxis = this.yAxis;
      const point = this.points[0]; // we know there is only 1 point
      let markLine = this.markLine;
      const ani = markLine ? "animate" : "attr";

      // Hide column
      point.graphic?.hide();

      if (!markLine) {
        const path = inverted
          ? ["M", 0, 0, "L", -10, -10, "L", 10, -10, "L", 0, 0, "L", 0, 0 + xAxis.len]
          : ["M", 0, 0, "L", -10, -10, "L", -10, 10, "L", 0, 0, "L", xAxis.len * 0.7, 0]; // Adjust the length here
        markLine = this.markLine = chart.renderer
          .path(path)
          .attr({
            fill: this.color,
            stroke: this.color,
            "stroke-width": 2,
            zIndex: 1,
            "pointer-events": "none",
          })
          .add();
      }

      // Replace the previous markLine[ani] block with this
      markLine[ani]({
        translateX: inverted ? xAxis.left + yAxis.translate(point.y || 0) : xAxis.left,
        translateY: inverted ? xAxis.top : yAxis.top + yAxis.len - yAxis.translate(point.y || 0),
      });

      // Integrate your SVG code here for the updated appearance
      markLine.attr({
        d: "M7.20711 1.20711C6.81658 0.816583 6.18342 0.816582 5.79289 1.20711L0.292893 6.70711C0.105357 6.89464 0 7.149 0 7.41421V46.5C0 47.0523 0.447715 47.5 1 47.5H12C12.5523 47.5 13 47.0523 13 46.5V7.41422C13 7.149 12.8946 6.89464 12.7071 6.70711L7.20711 1.20711Z",
        fill: "#093B79",
      });
    },
  });
})(Highcharts);

const HEIGHT = 45;

export const GaugeLineChart: FC<IProps> = ({ height, data }) => {
  const marginTopBottom = (height - HEIGHT) / 2;
  const options = useMemo(
    () => ({
      chart: {
        type: "lineargauge",
        inverted: true,
        margin: [marginTopBottom, 20, marginTopBottom, 20],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: ``,
        enabled: true,
        formatter: function () {
          const value = this.y;
          return `<b>Recession Probability in 6 months</b><br>Value: ${value}%`;
        } as Highcharts.TooltipFormatterCallbackFunction,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        lineColor: "#C0C0C0",
        labels: {
          enabled: false,
        },
        tickLength: 2,
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPositions: [0, 20, 40, 60, 80, 100],
        tickLength: 5,
        tickWidth: 1,
        tickColor: "#C0C0C0",
        gridLineColor: "#C0C0C0",
        gridLineWidth: 1,
        minorTickInterval: 5,
        minorTickWidth: 1,
        minorTickLength: 5,
        minorGridLineWidth: 0,
        title: null,
        opposite: true,
        labels: {
          format: "{value}%",
          style: {
            fontWeight: "500",
          },
        },
        plotBands: [
          {
            from: 0,
            to: 40,
            color: "#158F01",
          },
          {
            from: 40,
            to: 80,
            color: "#F1D22E",
          },
          {
            from: 80,
            to: 100,
            color: "#ff0000",
          },
        ],
      },
      series: [
        {
          type: "lineargauge",
          data: data || [],
          color: "#ffffff",
          dataLabels: {
            enabled: true,
            align: "center",
            format: "{point.y}%", // Format for the data labels
            style: {
              color: "#101b62",
              opacity: 0,
              fontSize: 14,
            },
            x: 8,
            className: "gauge-line-data-arrow",
          },
        },
      ],
    }),
    [data]
  );

  useEffect(() => {
    const gaugeLabel = document.querySelector(".gauge-line-data-arrow");
    if (gaugeLabel && data) {
      const text = gaugeLabel.querySelector("text");
      text?.setAttribute("y", "60");
      gaugeLabel.setAttribute("style", "opacity: 1");
    }
  }, [height, data]);

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height } }}
      />
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
