import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IForcastChart, IForecastExtraChart } from "@/types/forecast";
import { notification } from "@/utils/notification";

import { ForecastService } from "../../services/forecastService";
import { RootState } from "../index";

interface IMacroChartState {
  data: IForcastChart[];
  extraData: IForecastExtraChart | null;
  isLoading: boolean;
}

const initialState: IMacroChartState = {
  data: [],
  extraData: null,
  isLoading: false,
};

export const fetchForecastChart = createAsyncThunk("macro/getChart", async (ticker: string) => {
  const response = await ForecastService.getMacroChart(ticker);
  return response.data.result;
});

export const fetchForecastExtraChart = createAsyncThunk(
  "macro/getExtraChart",
  async (ticker: string) => {
    const response = await ForecastService.getMacroExtraChart(ticker);
    return response.data.result;
  }
);

const macroCahrtSlice = createSlice({
  name: "forecast/macro-chart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForecastChart.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.isLoading = false;
      })
      .addCase(fetchForecastChart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchForecastChart.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(fetchForecastExtraChart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchForecastExtraChart.fulfilled, (state, action) => {
        state.extraData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchForecastExtraChart.rejected, (state, action) => {
        if (action.error.message) {
          notification.error(action.error.message);
        }
        state.isLoading = false;
      });
  },
});

const state = (state: RootState) => state;

export const macroChartState = createSelector(state, (state) => state.forecastChart);

export default macroCahrtSlice.reducer;
