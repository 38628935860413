import { Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { FC, Fragment } from "react";

import { PDFColor, PDFfontSize } from "@/constants";
import { IPDFDataItem, PDFImageItem } from "@/types/pdf";
import { createGroups } from "@/utils/pdf";

import { About } from "../About/About";
import { Card } from "../Card/Card";
import { DefaultLayout } from "../DefaultLayout/DefaultLayout";
import { SectionTitle } from "../SectionTitle/SectionTitle";

import MainLogo from "@images/pdf-main-logo.png";
import QRCode from "@images/qr-code.png";

// Create styles
const styles = StyleSheet.create({
  titlePageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 20,
    flexGrow: 1,
    rowGap: 40,
  },
  mainLogo: {
    width: 400,
  },
  mainQrImage: {
    width: 170,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingBottom: 12,
    paddingTop: 12,
  },
  titleText: {
    fontSize: PDFfontSize.main,
    fontWeight: 400,
    color: PDFColor.black30,
  },
  titleName: {
    fontSize: PDFfontSize.main,
    fontWeight: 600,
    color: PDFColor.primary,
  },
});

interface IProps {
  imagesList: PDFImageItem[];
  dataList: IPDFDataItem[];
  title: string;
}

export const CompaniesDOC: FC<IProps> = ({ imagesList, title, dataList }) => {
  const groups = createGroups(imagesList, dataList);
  return (
    <Document>
      <DefaultLayout noFooter>
        <View style={styles.titlePageContainer}>
          <Image style={styles.mainLogo} src={MainLogo} />
          <Text>Forecast Company Report</Text>
          <Image style={styles.mainQrImage} src={QRCode} />
        </View>
      </DefaultLayout>
      <DefaultLayout>
        <View style={styles.header}>
          <Text style={styles.titleText}>Odin Forecasts Company:</Text>
          <Text style={styles.titleName}>{title.replace("Company:", "")}</Text>
        </View>
        {groups.map(([key, title, cardsList]) => (
          <Fragment key={key}>
            <SectionTitle title={title} />
            {cardsList.map(({ subKey, image, description }) => (
              <Card
                title={subKey}
                imageSrc={image}
                description={description}
                key={`sub-${subKey}`}
              />
            ))}
          </Fragment>
        ))}
      </DefaultLayout>
      <DefaultLayout>
        <About />
      </DefaultLayout>
    </Document>
  );
};
