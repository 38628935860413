import { FC } from "react";

import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";

import { useBenchmarkForm } from "../../hooks/useBenchmarkForm";

import styles from "./BenchmarkForm.module.scss";

import { ReactComponent as BenchmarkIcon } from "@images/benchmark.svg";

export const BenchmarkForm: FC = () => {
  const { disableOptions, benchmarkItemsList, selectedBenchmark, selectBenchmarkHandler } =
    useBenchmarkForm();

  return (
    <div
      className={`${styles.container} ${styles.benchmark} ${disableOptions ? styles.disabled : ""}`}
    >
      <p>Benchmark</p>
      <div className={styles.benchmarkWrapper}>
        <BenchmarkIcon />
        <div className={styles.inputWrapper}>
          <DropdownSearch
            label=""
            list={benchmarkItemsList}
            placeholder="Select a benchmark"
            name="benchmark"
            onSelect={selectBenchmarkHandler}
            selected={selectedBenchmark}
            loading={benchmarkItemsList === null}
            disable={disableOptions}
            isClearable
          />
        </div>
      </div>
    </div>
  );
};
