import { AxiosResponse } from "axios";

import { FeedbackTypeEnum } from "@/constants";

import axios from "./axios.config";

export const FeedbackService = {
  feedback: (form: FormData): Promise<AxiosResponse<{ result: any }>> =>
    axios.post("/feedback", form),
};
