import { AxiosResponse } from "axios";

import axios from "./axios.config";
import { IUser, IUserUpdate } from "../types";

export const ProfileService = {
  getProfile: (): Promise<AxiosResponse<IUser>> => axios.get("/profile"),
  updateProfile: (data: IUserUpdate): Promise<AxiosResponse<IUser>> => axios.put("/profile", data),
  updateProfileImage: (data: any): Promise<AxiosResponse<IUser>> =>
    axios.put("/profile-image", data),
};
