import { FC } from "react";

import { Button } from "../Button/Button";

import styles from "./DeniedUserDialog.module.scss";

interface IProps {
  onSubmit?: () => void;
}

export const DeniedUserDialog: FC<IProps> = ({ onSubmit }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>User Login Restriction!</h2>
      <div className={styles.textContainer}>
        <p className={styles.subtitle}>Sorry, for now your account is temporary deactivated!</p>
        <p className={styles.message}>
          For more information or activating/deactivating user account, please contact to{" "}
          <span>OdinUltra </span>
          <a href="mail:support@odinultra.ai">support@odinultra.ai</a>
        </p>
      </div>
    </div>
  );
};
