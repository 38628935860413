import { FC, Fragment, useEffect, useMemo, useState } from "react";

import { LoaderWrapper, TooltipWrapper } from "@/components";
import { Heading } from "@/components/Heading/Heading";
import { Chart } from "@/features/Discover/components/Chart/Chart";
import { DrsTable } from "@/features/Discover/components/DrsTable/DrsTable";
import { useAppSelector } from "@/hooks";
import { useAppDispatch } from "@/hooks/redux";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { discoverQueryDataState, getQueryData } from "@/store/discover/queryData";
import { ISorting, TIME_SERIES_TYPE } from "@/types";

import styles from "./DiscoverDetailsView.module.scss";

interface IProps {
  command: string | null;
  description?: string | null;
  title?: string | null;
}

const DEFAULT_HEIGHT = 450;
const MOBILE_HEIGHT = 500;

export const DiscoverDetailsView: FC<IProps> = ({ command, description, title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sort, setSort] = useState<ISorting[]>([]);
  const { meta, results, isLoading } = useAppSelector(discoverQueryDataState);
  const isMobileView = useMediaQuery("(max-width: 575px)");
  const dispatch = useAppDispatch();
  const data = useMemo(() => JSON.parse(decodeURIComponent(command || "")), [command]);

  const perPageUpdate = (perPage: number) => {
    setPerPage(perPage);
  };
  const currentPageUpdate = (current: number) => {
    setCurrentPage(current);
  };

  useEffect(() => {
    const query = {
      ...data?.query,
      sort,
      page: currentPage,
      per_page: perPage,
    };
    dispatch(getQueryData(query));
  }, [currentPage, data, dispatch, perPage, sort]);

  return (
    <Fragment>
      <div className={styles.head}>
        <TooltipWrapper
          text={description ? description : title}
          title={(description && title) || ""}
          truncate={false}
          maxWidth={"80%"}
        >
          <Heading className={styles.heading} type="h3">
            <span>Name:</span>
            {title}
          </Heading>
        </TooltipWrapper>
        {!!description && (
          <span className={styles.description}>
            <span>Description:</span> {description}
          </span>
        )}
      </div>
      {"time_series" === data?.query?.output?.format ? (
        <Chart
          results={results}
          height={isMobileView ? MOBILE_HEIGHT : DEFAULT_HEIGHT}
          isLoading={isLoading}
        />
      ) : (
        <div className={styles.tabel}>
          <DrsTable
            meta={meta}
            results={results}
            seriesType={TIME_SERIES_TYPE.DEFAULT}
            setCurrentPage={currentPageUpdate}
            setPerPage={perPageUpdate}
            currentPage={currentPage}
            perPage={perPage}
            setSort={setSort}
            isLoading={isLoading}
          />
        </div>
      )}
    </Fragment>
  );
};
