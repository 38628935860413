import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IFolder } from "@/types";
import { notification } from "@/utils/notification";

import { FocusService } from "../../services/focusService";
import { RootState } from "../index";

interface IFolderManagmentState {
  list: IFolder[];
  isLoading: boolean;
}

const initialState: IFolderManagmentState = {
  list: [],
  isLoading: false,
};

export const getFolders = createAsyncThunk("focus/getFolders", async () => {
  const response = await FocusService.getFolders();
  return response.data.result;
});

export const createFolder = createAsyncThunk("focus/createFolder", async (name: string) => {
  const response = await FocusService.createFolder(name);
  return response.data.result;
});

export const updateFolder = createAsyncThunk(
  "focus/updateFolders",
  async ({ id, name }: { id: number; name: string }) => {
    const response = await FocusService.updateFolder(id, name);
    return response.data.result;
  }
);

export const deleteFolder = createAsyncThunk("focus/deleteFolders", async (id: number) => {
  const response = await FocusService.deleteFolder(id);
  return response.data.result;
});

const foldersManageSlice = createSlice({
  name: "focus/managment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFolders.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
      })
      .addCase(getFolders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFolders.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        state.list.push(action.payload);
        state.isLoading = false;
        notification.success("Folder Created!");
      })
      .addCase(createFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(updateFolder.fulfilled, (state, action) => {
        state.list = state.list.map((folder) => {
          if (folder.id === action.payload.id) {
            return action.payload;
          }
          return folder;
        });
        state.isLoading = false;
        notification.success("Folder Updated!");
      })
      .addCase(updateFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        state.list = state.list.filter((folder) => folder.id !== action.payload.id);
        state.isLoading = false;
        notification.success("Folder Deleted!");
      })
      .addCase(deleteFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const foldersManageState = createSelector(state, (state) => state.foldersManage);

export default foldersManageSlice.reducer;
