import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "@/constants";
import Message from "@/sounds/message.mp3";
import {
  fetchMarkMessagesAsReaded,
  fetchMessagesListSilent,
  fetchUnreaded,
  odinChatState,
} from "@/store/odinchat";
import { profileInfo } from "@/store/profile";
import { debounce } from "@/utils";

import { useAppDispatch, useAppSelector } from "./redux";
import { useActiveWindowTab } from "./useActiveWindowTab";

export const UPDATE_MSG_FREQUENCY = 60_000; // milliseconds
export const UPDATE_FAST_FREQUENCY = 3000; // milliseconds
export const PLAY_SOUND_DELAY = 2000; // milliseconds
const PLAY_SOUND_COUNT_DELAY = 500; // milliseconds

export const useUnreadedMessages = (status: "stop" | "active" = "active") => {
  const [show, setShow] = useState(false);
  const { unreaded, list } = useAppSelector(odinChatState);
  const { uid } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const playSoundRef = useRef(0);
  const inProgressRef = useRef(false);

  const [isWindowTabActive] = useActiveWindowTab();

  useEffect(() => {
    const inProgress = list?.some((msg) => msg.status === "In progress") || false;
    inProgressRef.current = inProgress;
  }, [list]);

  // get messages list on window tab is active
  useEffect(() => {
    if (isWindowTabActive && uid && status === "active") {
      dispatch(fetchUnreaded(uid));
      dispatch(fetchMessagesListSilent(uid));
    }
  }, [uid, isWindowTabActive, status]);

  // run it once to remove all unreaded messages
  // !!! remove this effect after remove all unreaded messages
  useEffect(() => {
    const data = localStorage.getItem("odin-unreaded-status");
    if (!data && unreaded && unreaded.length) {
      dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids: unreaded, isUnmarkAll: true })).then(
        () => {
          localStorage.setItem("odin-unreaded-status", "completed");
        }
      );
      console.log("Removed all unreaded messages!");
    }
  }, [unreaded]);
  //--

  useEffect(() => {
    let timerId: NodeJS.Timer | null = null;
    const startWatch = (updateDelay = UPDATE_FAST_FREQUENCY) => {
      timerId = setTimeout(() => {
        if (uid) {
          dispatch(fetchUnreaded(uid));
          dispatch(fetchMessagesListSilent(uid));
        }
        if (inProgressRef.current) startWatch();
        else startWatch(UPDATE_MSG_FREQUENCY);
      }, updateDelay);
    };

    if (!location.pathname.includes(ROUTES.odinchat.path) && status === "active") {
      startWatch();
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [uid, location, status]);

  // play message sound after timeout
  const debounced = useMemo(
    () =>
      debounce((cb: () => void) => {
        cb();
      }, PLAY_SOUND_COUNT_DELAY),
    []
  );

  useEffect(() => {
    let timerID: NodeJS.Timer | null = null;
    const playSound = () => {
      const audio = new Audio(Message);
      const playedPromise = audio.play();
      if (playedPromise) {
        playedPromise
          .then(() => {
            playSoundRef.current = unreaded?.length || 0;
            if (timerID) clearTimeout(timerID);
          })
          .catch(() => {
            console.warn("Not allowed yet...");
            timerID = setTimeout(() => {
              playSound();
            }, PLAY_SOUND_DELAY);
          });
      }
    };

    if (
      unreaded &&
      unreaded.length > 0 &&
      show &&
      unreaded.length > playSoundRef.current &&
      isWindowTabActive &&
      status === "active"
    )
      debounced(playSound);
    if (unreaded && unreaded.length === 0) playSoundRef.current = 0;

    return () => {
      if (timerID && !playSoundRef.current) clearTimeout(timerID);
    };
  }, [unreaded, show, status]);

  return { count: unreaded?.length || 0, show };
};
