import { FC } from "react";

import { Dropdown, InputItem } from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import {
  allocationStyleList,
  exitStyleList,
  optionsType,
  strikeRatioList,
  toolTipHelperList,
} from "@/constants";
import {
  AllocationStyleType,
  ExitStyleType,
  ITickersKeyValueData,
  InputFieldNames,
  OptionType,
  StrikeRatioType,
} from "@/types";

import { useOptionsForm } from "../../hooks/useOptionsForm";

import styles from "./DerivatevesForm.module.scss";

interface IProps {
  tradingTickersList: ITickersKeyValueData;
  isLoading: boolean;
}

export const DerivatevesForm: FC<IProps> = ({ tradingTickersList, isLoading }) => {
  const {
    selectedTicker,
    strikeRatio,
    option,
    isPercentageShow,
    isRatioShow,
    tradingInstrumentOptions,
    tradingTickers,

    selectOptionsHandler,
    selectStrikeRatioHandler,
    numberChangeHandler,
    selectTickerHandler,
    blurHandler,

    selectAllocationStyleHandle,
    selectExitStyleHandle,
    activeAllocationStyle,
    activeExitStyle,
  } = useOptionsForm(tradingTickersList);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <Dropdown<"optionType", OptionType>
            list={optionsType}
            onSelect={selectOptionsHandler}
            selected={option}
            placeholder="Select Options Type"
            name="optionType"
            label="Sell Buy Type"
          />
        </div>

        <div className={`${styles.itemWrapper} ${styles.nospace}`}>
          <div className={styles.innerItemWrapper}>
            <InputItem<InputFieldNames>
              name={"minDays"}
              placeholder="Days"
              label="Min Days to Expire"
              type="number"
              value={tradingInstrumentOptions.minDays.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              symbol=""
              error={tradingInstrumentOptions.minDays.error}
              isRequired
              tooltipHelp={toolTipHelperList.minDays}
            />
            <InputItem<InputFieldNames>
              name={"maxDays"}
              placeholder="Days"
              label="Max Days to Expire"
              type="number"
              value={tradingInstrumentOptions.maxDays.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              symbol=""
              error={tradingInstrumentOptions.maxDays.error}
              isRequired
              tooltipHelp={toolTipHelperList.maxDays}
            />
          </div>
        </div>
        <div className={`${styles.itemWrapper} ${styles.nospace}`}>
          <div className={styles.innerItemWrapper}>
            <InputItem<InputFieldNames>
              name={"stopGain"}
              placeholder="Infinite"
              label="Stop Gain"
              type="number"
              value={tradingInstrumentOptions.stopGain.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              error={tradingInstrumentOptions.stopGain.error}
              tooltipHelp={toolTipHelperList.stopGain}
            />
            <InputItem<InputFieldNames>
              name={"stopLoss"}
              placeholder="-Infinite"
              label="Stop Loss"
              type="number"
              value={tradingInstrumentOptions.stopLoss.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              error={tradingInstrumentOptions.stopLoss.error}
              tooltipHelp={toolTipHelperList.stopLoss}
            />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <DropdownSearch<"ticker">
            list={tradingTickers || []}
            onSelect={selectTickerHandler}
            selected={selectedTicker}
            placeholder="Search Ticker Name"
            name="ticker"
            label="Trading Ticker"
            disable={false}
            isRequired={true}
            loading={isLoading}
          />
        </div>
        <div className={styles.itemWrapper}>
          <Dropdown<"strike-ratio", StrikeRatioType>
            list={strikeRatioList}
            onSelect={selectStrikeRatioHandler}
            selected={strikeRatio}
            placeholder="Select Strike Ratio"
            name="strike-ratio"
            label="Strike Ratio"
          />
        </div>
        {isPercentageShow && (
          <div className={`${styles.itemWrapper} ${styles.nospace}`}>
            <InputItem<InputFieldNames>
              name={"percentage"}
              placeholder="Number"
              label="Percentage"
              type="number"
              value={tradingInstrumentOptions.percentage.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              error={tradingInstrumentOptions.percentage.error}
              isRequired
              tooltipHelp={toolTipHelperList.percentage}
            />
          </div>
        )}

        {isRatioShow && (
          <div className={`${styles.itemWrapper} ${styles.nospace}`}>
            <InputItem<InputFieldNames>
              name={"ratio"}
              placeholder="Number"
              label="Ratio of STDEV"
              type="number"
              value={tradingInstrumentOptions.ratio.value}
              onChange={numberChangeHandler}
              onBlur={blurHandler}
              error={tradingInstrumentOptions.ratio.error}
              isRequired
              symbol=""
              tooltipHelp={toolTipHelperList.ratio}
            />
          </div>
        )}
        <div className={`${styles.row}`}>
          <div className={`${styles.itemWrapper}`}>
            <Dropdown<"allocation-style", AllocationStyleType>
              list={allocationStyleList}
              onSelect={selectAllocationStyleHandle}
              selected={activeAllocationStyle}
              name="allocation-style"
              label="Allocation Style"
            />
          </div>
          <div className={`${styles.itemWrapper}`}>
            <Dropdown<"exit-style", ExitStyleType>
              list={exitStyleList}
              onSelect={selectExitStyleHandle}
              selected={activeExitStyle}
              name="exit-style"
              label="Exit Style"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
