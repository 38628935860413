import { FC, Fragment } from "react";
import { useTable, useSortBy, Column } from "react-table";

import { useFixedColumn } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ReactComponent as ArrowSorter } from "@/images/sorter.svg";
import { IStrategyAction } from "@/types";

import styles from "./Table.module.scss";

export type ExtendedColumns = Array<
  Column<object> & {
    minWidth: number;
    isTruncated?: boolean;
  }
>;

interface IProps<T> {
  columns: ExtendedColumns;
  data: T[];
  scrollWidth?: number;
  onSelect?: (row: object) => void;
  small?: boolean;
  size?: "small" | "medium";
  isNotClickable?: boolean;
}

export function Table<T extends object>({
  columns,
  data,
  onSelect,
  small,
  size,
  isNotClickable = false,
}: IProps<T>) {
  const { itemRef, showScroll, tableColumnsWidth } = useFixedColumn(columns);
  const isMobileView = useMediaQuery("(max-width: 575px)");

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div
      className={`${styles.tableContainer} ${data.length === 0 ? styles.empty : ""}`}
      ref={itemRef}
    >
      <table
        {...getTableProps()}
        className={`${styles.table} ${size ? (small ? styles.small : styles.medium) : ""}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
              style={{ gridTemplateColumns: tableColumnsWidth }}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className={`${styles.tableHeaderCell} ${showScroll ? styles.showScroll : ""}`}
                  >
                    <span className={styles.cellText}>{column.render("Header")}</span>
                    <SortArrows
                      isSorting={column.isSorted}
                      isDescDir={column.isSortedDesc}
                      isSortable={column.canSort}
                    />
                    <span className={styles.tableHeaderSeparator}></span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`${styles.tableRow} ${isNotClickable ? styles.notClickable : ""}`}
                style={{ gridTemplateColumns: tableColumnsWidth }}
                onClick={() => (onSelect ? onSelect(row.original) : null)}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`${styles.tableRowCell} ${showScroll ? styles.showScroll : ""} ${
                        small ? styles.small : ""
                      }`}
                    >
                      {columns[idx].isTruncated ? (
                        <div className={styles.truncated}>
                          <span>{cell.render("Cell")}</span>
                        </div>
                      ) : (
                        <div className={styles.simpleCell}>{cell.render("Cell")}</div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && <div className={styles.emptyData}>Data not available yet.</div>}
    </div>
  );
}

interface ISortArrowsProps {
  isSorting?: boolean;
  isDescDir?: boolean;
  isSortable?: boolean;
}

export const SortArrows: FC<ISortArrowsProps> = ({ isSorting, isDescDir, isSortable }) => {
  if (!isSortable) return null;
  return (
    <Fragment>
      {isSorting ? (
        <span className={styles.arrowsGroup}>
          <ArrowSorter className={`${isDescDir ? styles.sortUp : styles.sortDown}`} />
        </span>
      ) : (
        <span className={styles.arrowsGroup}>
          <ArrowSorter className={styles.sortDisabled} />
        </span>
      )}
    </Fragment>
  );
};

export const ActionCell: FC<{ data: IStrategyAction }> = ({ data }) => {
  const { type, description } = data;

  return (
    <div className={`${styles.actionCell} ${type === "REQUIRED" ? styles.active : ""}`}>
      <span className={styles.actionTitle}>{type}</span>
      {description && (
        <div className={styles.actionInner}>
          <ul className={`${styles.actionList}`}>
            {description.map((i, key) => (
              <li key={key}>
                <b>{key + 1}.</b> {i}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const CellWithStatus: FC<{ value: number | null; postfix?: string }> = ({
  value,
  postfix,
}) => {
  const statusStyle =
    value !== null ? (value >= 0 ? styles.positive : styles.negative) : styles.noValue;
  const textValue = value === null ? "-" : value;
  return (
    <span className={statusStyle}>
      {textValue}
      {postfix}
    </span>
  );
};
