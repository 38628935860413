import { useCallback, useEffect, useState } from "react";

import { DocumentSectionType, DocumentsFoldersType } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AdminDocumentsState, createDocument, uploadDocument } from "@/store/adminPanel/documents";
import { ICreatePanelDoc } from "@/types/adminPanel";
import { notification } from "@/utils";

export const useUploadPDF = (isApprovalDoc: boolean) => {
  const [pdf, setPDF] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector(AdminDocumentsState);

  const handleUpload = (
    pdfFile: File | null,
    folder: DocumentsFoldersType,
    documentSection: DocumentSectionType | null
  ) => {
    if (pdfFile) {
      if (pdfFile?.name.length > 255) {
        notification.warning("File names should have a maximum of 255 characters.");
        return;
      }
      const allowedFormats = ["application/pdf"]; // Update allowed PDF formats
      const isValidFormat = allowedFormats.includes(pdfFile.type);
      if (isValidFormat) {
        setPDF(pdfFile);
        const fileData: ICreatePanelDoc = {
          key: pdfFile.name.replace(".pdf", ""),
          meta: { folder },
        };
        if (isApprovalDoc && documentSection) {
          fileData.meta.type = "learn_more";
          fileData.meta["additional-ctx"] = documentSection;
          fileData.meta.is_public = true;
          fileData.meta.requires_approval = true;
        }
        dispatch(createDocument(fileData));
      } else {
        notification.warning("Invalid file format. Please upload a valid PDF.");
      }
    } else {
      setPDF(null);
    }
  };

  const handleClear = () => {
    setPDF(null);
  };

  const onUpload = useCallback(
    (docId: number, pdfFile: File | null) => {
      const formData = new FormData();
      formData.append("document", pdfFile || "");
      dispatch(uploadDocument({ docId, data: formData }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!pdf) return;
    const doc = documents?.find((doc) => doc.key === pdf.name.replace(".pdf", ""));
    if (doc) {
      onUpload(doc.id, pdf);
      handleClear();
    }
  }, [documents?.length, onUpload, pdf]);

  return {
    pdf,
    handleUpload,
    handleClear,
    onUpload,
  };
};
