import { FC } from "react";

import styles from "./Loader.module.scss";

interface IProps {
  helpText?: string;
  small?: boolean;
  atom?: boolean;
  black?: boolean;
  white?: boolean;
}

export const Loader: FC<IProps> = ({ helpText, small, atom, black, white }) => {
  return (
    <div
      className={`${styles.container} ${small ? styles.small : ""} ${white ? styles.white : ""} ${
        black ? styles.black : ""
      }`}
    >
      {!atom && <DotsLoader />}
      {atom && <AtomLoader />}
      {!!helpText && <div className={styles.helpText}>{helpText}</div>}
    </div>
  );
};

export const DotsLoader = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.item}></span>
      <span className={styles.item}></span>
      <span className={styles.item}></span>
    </div>
  );
};

export const AtomLoader = () => {
  return (
    <div className={styles.atomContainer}>
      <span className={styles.loader}></span>
    </div>
  );
};
