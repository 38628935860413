export const faqDescriptionList = {
  forecastIndustry: [
    {
      question: "What is Odin's methodology behind industry fundamentals forecasting?",
      answer:
        "Odin uses a bottom up approach and aggregates fundamentals and its forecasts of companies that form an industry. Odin's fundamentals algorithms generate industries' fundamentals including revenues, gross income, gross margin. Users can click on any row to view graphs of historical comparison between Odin forecasts, consensus and the actual reported items. These forecasts are automatically updated as new data are ingested.",
    },
    {
      question: "Which industry classification does Odin use?",
      answer:
        "A combination of Global Industry Classification Standard (GICS) and Revere Business Industry Classification System (RBICS) sub-industries is used to categorize companies.",
    },
    {
      question: "How does Odin align fundamentals of companies with varying fiscal quarters ends?",
      answer:
        "To align and aggregate fundamentals, the calendar quarter with biggest portion of each company's fiscal quarter is assigned to it.",
    },
    {
      question: "How does Odin ensure data integrity and reliability?",
      answer:
        "Odin delays sophisticated algorithms to continuously ensure various data integrity aspects such as ingestion, data formatting, unit conversions, outlier discovery, consistency, and timeliness. Odin escalate issues to the engineering team.",
    },
    {
      question: "How often are quarterly company fundamentals forecasts updated?",
      answer:
        "Forecasts are updated at a new data arrival or due to model ensembling processing. The forecasting algorithms run on daily basis, however, the one-quarter out forecasts are generally stable and slow moving.",
    },
    {
      question: "Why are the forecast seem to track consensus estimates?",
      answer:
        "One of the key forecast drivers we use is companies' own explicit/implicit guidance since it is an important material information available. Consensus estimates are usually set at the mid-points of such guidance. This will lead to tracking resemblance of Odin forecasts with those of consensus estimates.",
    },
    {
      question: "Why sometimes forecasts are exactly the same as analyst estimates?",
      answer:
        "Sometimes, Odin determines that the consensus estimates are accurate and better than its own forecasts for one or more metrics. In those cases, Odin just shows consensus estimates for those metrics. ",
    },
    {
      question: "How do I know which forecasts are high confidence ones?",
      answer:
        "Odin watches its own forecasting capability at metric level of each company. If it has been able to forecast a particular metric accurately in the recent past, it will flag that with * in front of that metric in the forecast table or explicitly states that on forecast charts.",
    },
    {
      question: "How often do the models change?",
      answer:
        "We use various models to forecast financial metrics. We use a proprietary ensembling process to allows us introduce new models while keeping the previous models too.",
    },
    {
      question: "Can Odin forecast every company every quarter?",
      answer:
        "Odin tracks it owns recent forecast performance and it only shows forecasts that have met its quality thresholds. This means that Odin may not be able to forecast certain metrics of some companies (or some companies in entirety) some quarters.",
    },
    {
      question: "How are the forecast errors calculated?",
      answer:
        "Forecast errors are Mean Absolute Percent Error over trailing twelve months (4 fiscal quarters)",
    },
    {
      question: "How far back the actual forecast history go?",
      answer:
        "We are currently showing our historical forecasts for the past 8 quarters. We have escrowed our sales forecast in a third party since escrow company since September 2016.",
    },
  ],
  forecastSector: [
    {
      question: "What is Odin's methodology behind sector fundamentals forecasting?",
      answer:
        "Odin uses a bottom-up approach and aggregates fundamentals and its forecasts of companies that form a sector. Odin's algorithms generate sectors fundamentals including revenues, gross income, and gross margin. Users can click on any row to view graphs of historical comparison between Odin forecasts, consensus, and the actual reported items. These forecasts are automatically updated as new data are ingested. Users can save desired data to a folder for later use.",
    },
    {
      question: "Which industry classification does Odin use?",
      answer:
        "Global Industry Classification Standard (GICS) and Revere Business Industry Classification System (RBICS) sub-industries is used to categorize companies.",
    },
    {
      question: "How does Odin align fundamentals of companies with varying fiscal quarters ends?",
      answer:
        "To align and aggregate fundamentals, the calendar quarter with biggest portion of each company`s fiscal quarter is assigned to it.",
    },
    {
      question: "How does Odin ensure data integrity and reliability?",
      answer:
        "Odin delays sophisticated algorithms to continuously ensure various data integrity aspects such as ingestion, data formatting, unit conversions, outlier discovery, consistency, and timeliness. Odin escalate issues to the engineering team.",
    },
    {
      question: "How often are quarterly company fundamentals forecasts updated?",
      answer:
        "Forecasts are updated at a new data arrival or due to model ensembling processing. The forecasting algorithms run on daily basis, however, the one-quarter out forecasts are generally stable and slow moving.",
    },
    {
      question: "Why are the forecast seem to track consensus estimates?",
      answer:
        "One of the key forecast drivers we use is companies' own explicit/implicit guidance since it is an important material information available. Consensus estimates are usually set at the mid-points of such guidance. This will lead to tracking resemblance of Odin forecasts with those of consensus estimates.",
    },
    {
      question: "Why sometimes forecasts are exactly the same as analyst estimates?",
      answer:
        "Sometimes, Odin determines that the consensus estimates are accurate and better than its own forecasts for one or more metrics. In those cases, Odin just shows consensus estimates for those metrics.",
    },
    {
      question: "How do I know which forecasts are high confidence ones?",
      answer:
        "Odin watches its own forecasting capability at metric level of each company. If it has been able to forecast a particular metric accurately in the recent past, it will flag that with * in front of that metric in the forecast table or explicitly states that on forecast charts.",
    },
    {
      question: "How often do the models change?",
      answer:
        "We use various models to forecast financial metrics. We use a proprietary ensembling process to allows us introduce new models while keeping the previous models too.",
    },
    {
      question: "Can Odin forecast every company every quarter?",
      answer:
        "Odin tracks it owns recent forecast performance and it only shows forecasts that have met its quality thresholds. This means that Odin may not be able to forecast certain metrics of some companies (or some companies in entirety) some quarters.",
    },
    {
      question: "How are the forecast errors calculated?",
      answer:
        "Forecast errors are Mean Absolute Percent Error over trailing twelve months (4 fiscal quarters)",
    },
    {
      question: "How far back the actual forecast history go?",
      answer:
        "We are currently showing our historical forecasts for the past 8 quarters. We have escrowed our sales forecast in a third party since escrow company since September 2016.",
    },
  ],
  macroPlannerMultiAsset: [
    {
      question: "What can I do on Macro Planner pages?",
      answer:
        "Using Macro Planner, users can hypothesize potential economic conditions and then find the optimal Sharpe ratio, highest return, or minimal variance portfolios under the hypothesized conditions using data as far back as possible. Users can select a subset of the listed indices or instruments to construct the optimal portfolios for multi-asset, equity sectors, fixed income or commodities.",
    },
    {
      question: "Which macro conditions can I choose?",
      answer:
        "Currently the available macro variables include CPI core, Fed fund rate, real or nominal GDP, 10 year or 2 year treasury spread, and 10 or 2 year treasury yield. Users can choose to specify changes of these variables during a fixed horizon. Use can specify whether or not to include the current level of the tracked variable in addition to changes of this variable. ",
    },
    {
      question: "What is the methodology for finding these optimal portfolios?",
      answer:
        "For a user specified macro condition, we first find all data points or periods that matched this specified macro condition using the relevant historical market data. We then apply a mean variance optimization approach to find the optimal Sharpe ratio, highest return, or minimal variance portfolios. ",
    },
    {
      question: "What do Bonds and Commodities refer to?",
      answer:
        "Bonds refer to the US long term (20+ year) government bond index or related tradable ETFs. Commodities refer to the Bloomberg Commodity Index or related tradable ETFs.",
    },
    {
      question: "What is the Portfolio Beta?",
      answer:
        "This is the beta of the proposed optimal portfolio relative to the equal weighted benchmark using all allowed instruments. ",
    },
    {
      question: "What do returns and risks mean for the optimal portfolios?",
      answer:
        "Returns are average monthly returns of the optimal portfolios for the matched periods, risks are annualized standard deviation of the monthly returns of the matched periods.",
    },
  ],
  discover: [
    {
      question: "What can I find here?",
      answer:
        "Odin offers effortless access to an extensive collection of diverse data sets, spanning multiple domains. Odin currently houses 1,400+ authoritative data sources and 15M+ timeseries, and processes 1.5+ trillion data points daily. ",
    },
    {
      question: "How far back does the data go?",
      answer:
        "We capture the entire time series issued by a source. As such, historical data depends on the source. Some sources publish data going as far back as the 1920s. While others may be more recent to capture newly established metrics and current trends. ",
    },
    {
      question: "What data sources are used?",
      answer:
        "Our massive data spans across a broad range of reliable diverse sources, from industry associations, trade groups, government administrations, non-profit organizations, and private third-party institutes. ",
    },
    {
      question: "How does Odin ensure data integrity and reliability?",
      answer:
        "Odin delays sophisticated algorithms to continuously ensure various data integrity aspects such as ingestion, data formatting, unit conversions, outlier discovery, consistency, and timeliness. Odin escalate issues to the engineering team.",
    },
    {
      question: "What data is available?",
      answer:
        "High-frequency alternative data, company financials, industry trends, and macroeconomic indicators are accessible. Users can investigate supply chain interconnections, currency rates, commodity prices, company guidance, and more for a comprehensive foundation of data-driven insights and analysis.",
    },
    {
      question: "How often are the data updated?",
      answer:
        "Data is ingested shortly after release by the original source, and it is usually available within a few hours of publication. For example, mortgage rates are released each Thursday at noon eastern time and are accessible within a few hours of publication. ",
    },
    {
      question: "How do you track on-time ingestion?",
      answer:
        "We have a strong technological framework for sourcing, ingesting, cleaning, and modeling data. We constantly monitor the health of our data platform, which is linked to Jira, and receive notifications if an ingestion fails.",
    },
    {
      question: "Do you capture revisions?",
      answer:
        "Yes. Most economic data is revised to become more precise as information rolls in. As such, previous releases can be replaced and no longer available. Real time economic data allows users to backtest correctly and build more accurate forecasting models.",
    },
  ],
  macroPlannerCommodities: [
    {
      question: "What can I do on Macro Planner pages?",
      answer:
        "Using Macro Planner, users can hypothesize potential economic conditions and then find the optimal Sharpe ratio, highest return, or minimal variance portfolios under the hypothesized conditions using data as far back as possible. Users can select a subset of the listed indices or instruments to construct the optimal portfolios for multi-asset, equity sectors, fixed income or commodities.",
    },
    {
      question: "Which macro conditions can I choose?",
      answer:
        "Currently the available macro variables include CPI core, Fed fund rate, real or nominal GDP, 10 year or 2 year treasury spread, and 10 or 2 year treasury yield. Users can choose to specify changes of these variables during a fixed horizon. Use can specify whether or not to include the current level of the tracked variable in addition to changes of this variable. ",
    },
    {
      question: "What is the methodology for finding these optimal portfolios?",
      answer:
        "For a user specified macro condition, we first find all data points or periods that matched this specified macro condition using the relevant historical market data. We then apply a mean variance optimization approach to find the optimal Sharpe ratio, highest return, or minimal variance portfolios. ",
    },
    {
      question: "What is the Portfolio Beta?",
      answer:
        "This is the beta of the proposed optimal portfolio relative to the equal weighted benchmark using all allowed instruments. ",
    },
    {
      question: "What do returns and risks mean for the optimal portfolios?",
      answer:
        "Returns are average monthly returns of the optimal portfolios for the matched periods, risks are annualized standard deviation of the monthly returns of the matched periods.",
    },
  ],
  home: [
    {
      question: "How can I use the Home page?",
      answer:
        "Home page shows the users' favorite data series, single strategies, and multi-strategy portfolios. Users can populate the Home page by adding their favorite portfolios, strategies, or data series that they would like to track closely.",
    },
    {
      question: "What is the difference between a Portfolio and a Strategy?",
      answer:
        "A strategy is a set of trading rules applied to a trading universe which can be single ticker equity or ETF, options, or baskets. A portfolio can be a single strategy or a portfolio of strategies. Strategies can be constructed from Portfolio Builder's Single Strategy page. Portfolios are single or multiple strategy portfolios that are built from Portfolio Builder's Multi Strategy page.",
    },
    {
      question: "How do I add a portfolio to My Portfolios on the Home page?",
      answer:
        "To add a portfolio to My portfolios on Home page, go to the Portfolio Builder Multi Strategy page. Select a portfolio under My portfolios by clicking the star to the right of the portfolio name. This portfolio will show up under My Portfolios on the Home page. Click on the highlighted star to remove the portfolio from My portfolios on Home page.",
    },
    {
      question: "How do I add a Strategy to My Strategies on the Home page?",
      answer:
        "To add a strategy to My Strategies on Home page, go to the Portfolio Builder Single Strategy page. Select a strategy under My strategies by clicking the star to the right of the strategy name. This strategy will then show up under My Strategies on the Home page. ",
    },
    {
      question: "How do I add data that I would like to track to My Data on the Home page?",
      answer:
        "Depending on the source of the data series, click on the hard disc icon or the Save button on the upper right corners of a data series chart or table will prompt the user to choose a folder to save the data series to. This can be done for example from specific company or macro forecasts, or from the Data portal.",
    },
    {
      question: "How do I remove a data series from Home page?",
      answer:
        "On the right side of the chart or table of the data series, click on the three dots and then Delete.",
    },
    {
      question: "How do I add (remove) a folder to (from) My Data on the Home page?",
      answer:
        "There is a default FOCUS folder under My Data on the Home page. Users can create new folders by clicking New Folder. To remove a folder, click Folder Actions to the upper right corner of this folder's view.",
    },
    {
      question: "How do I download a data series from Home page?",
      answer:
        "Move your mouse over to the desired data series, and click on the Preview button. On the data series chart, click on the three bars to the right of the chart and select Download CSV.",
    },
  ],
  macroplannerEquitySector: [
    {
      question: "What can I do on Macro Planner pages?",
      answer:
        "Using Macro Planner, users can hypothesize potential economic conditions and then find the optimal Sharpe ratio, highest return, or minimal variance portfolios under the hypothesized conditions using data as far back as possible. Users can select a subset of the listed indices or instruments to construct the optimal portfolios for multi-asset, equity sectors, fixed income or commodities.",
    },
    {
      question: "Which macro conditions can I choose?",
      answer:
        "Currently the available macro variables include CPI core, Fed fund rate, real or nominal GDP, 10 year or 2 year treasury spread, and 10 or 2 year treasury yield. Users can choose to specify changes of these variables during a fixed horizon. Use can specify whether or not to include the current level of the tracked variable in addition to changes of this variable. ",
    },
    {
      question: "What is the methodology for finding these optimal portfolios?",
      answer:
        "For a user specified macro condition, we first find all data points or periods that matched this specified macro condition using the relevant historical market data. We then apply a mean variance optimization approach to find the optimal Sharpe ratio, highest return, or minimal variance portfolios. ",
    },
    {
      question: "What is the Portfolio Beta?",
      answer:
        "This is the beta of the proposed optimal portfolio relative to the equal weighted benchmark using all allowed instruments. ",
    },
    {
      question: "What do returns and risks mean for the optimal portfolios?",
      answer:
        "Returns are average monthly returns of the optimal portfolios for the matched periods, risks are annualized standard deviation of the monthly returns of the matched periods.",
    },
  ],
  macroplannerFixedIncome: [
    {
      question: "What can I do on Macro Planner pages?",
      answer:
        "Using Macro Planner, users can hypothesize potential economic conditions and then find the optimal Sharpe ratio, highest return, or minimal variance portfolios under the hypothesized conditions using data as far back as possible. Users can select a subset of the listed indices or instruments to construct the optimal portfolios for multi-asset, equity sectors, fixed income or commodities.",
    },
    {
      question: "Which macro conditions can I choose?",
      answer:
        "Currently the available macro variables include CPI core, Fed fund rate, real or nominal GDP, 10 year or 2 year treasury spread, and 10 or 2 year treasury yield. Users can choose to specify changes of these variables during a fixed horizon. Use can specify whether or not to include the current level of the tracked variable in addition to changes of this variable. ",
    },
    {
      question: "What is the methodology for finding these optimal portfolios?",
      answer:
        "For a user specified macro condition, we first find all data points or periods that matched this specified macro condition using the relevant historical market data. We then apply a mean variance optimization approach to find the optimal Sharpe ratio, highest return, or minimal variance portfolios. ",
    },
    {
      question: "What is the Portfolio Beta?",
      answer:
        "This is the beta of the proposed optimal portfolio relative to the equal weighted benchmark using all allowed instruments. ",
    },
    {
      question: "What do returns and risks mean for the optimal portfolios?",
      answer:
        "Returns are average monthly returns of the optimal portfolios for the matched periods, risks are annualized standard deviation of the monthly returns of the matched periods.",
    },
  ],
  portfolioMultiStrategy: [
    {
      question: "What are basic functions of the multi strategy page?",
      answer:
        "Users can combine multiple core strategies and overlay strategy to explore different allocations, to examine the combine portfolio performance during different testing periods.",
    },
    {
      question: "Can I use historical testing periods with Select Date Range box?",
      answer: "Yes, backtesting will only be performed for the period specified. ",
    },
    {
      question: "What is Core Strategies Rebalancing Frequency?",
      answer:
        "Users can build a portfolio with multiple core strategies with desired allocations. As core strategies performances vary, the allocations can gradually deviate from the initial specification. Core Strategies Rebalancing Frequency determines how often the allocations to the core strategies should be reset to the initial specifications, for example, monthly or quarterly.",
    },
    {
      question: "When I have multiple core strategies, do the allocations need to add up to 100%?",
      answer: "No, users can choose higher allocations if desired.",
    },
    {
      question: "Is there a limit on how many core or overlay strategies that I can use?",
      answer: "This is no limit on both.",
    },
  ],
  portfolioSingleStrategy: [
    {
      question: "What are core and overlay strategies?",
      answer:
        "Overlay strategies tend to be short term in nature. Core strategies are users' main workhorse strategies.",
    },
    {
      question: "What is the difference between core and overlay strategy?",
      answer:
        "Th only difference is that they are treated differently in a multi strategy portfolio when rebalancing are performed. Only core strategies are rebalanced at specified frequency in a multi strategy portfolio.",
    },
    {
      question:
        "For Trading Instrument type Baskets, what is Update Frequency? What is Rebalance Frequency?",
      answer:
        "Update frequency is used to determine how often a trading model is updated, for example if new stocks will be selected depending on whether they pass the advanced filtering condition if there is any. At model update time, new portfolio weights will be determined according to the selected Weighting Scheme. For a single strategy, rebalance frequency controls how often the weights for the selected tickers will be adjusted in the trading basket, possibly without model update.",
    },
    {
      question: "What is Weighting Scheme?",
      answer:
        "For a single strategy for a basket type of trading instruments, weighting scheme determines the capital allocation among the selected tickers. Cap-weight indicates capital is allocated proportional to market cap. Inverse volatility weight allocates larger capital to less volatile instruments. For factor type strategies, Factor weight allocates more based on the strength of the factor.",
    },
    {
      question: "What is Max Allowed Weight?",
      answer: "This is the allowed maximum weight on each selected ticker in the trading basket.",
    },
    {
      question: "When trading instruments are baskets, what are Advanced Filtering Conditions?",
      answer:
        "Advanced Filtering Conditions are used to select subset of tickers among a basket of tickers based on relative rank of a desired metric for example P/E ratio or Current Ratio. This selection is performed at a model update time cross-sectionally among tickers in the trading basket.",
    },
    {
      question: "Do I need to specify both entry and exit conditions?",
      answer:
        "No. If both entry and exit conditions are left blank, the trading instruments or basket are assumed to be held during the backtesting period. In other words, stocks or list of stocks will not change during the backtesting period. Rebalance to the desired weighting scheme for a basket can still happen if the Rebalance frequency is defined. If only entry conditions are specified without exit conditions, entry conditions become holding positions, i.e., positions will remain as long as entry conditions hold.",
    },
    {
      question: "What are the technical, fundamental, economic, event, or valuation indicators?",
      answer:
        "These are pre-built entry or exit conditions. For example under Technical, SpyAboveMA200 selects trading days when SPY is above the 200-day simple moving average, meaning a position will be initiated when SPY closes above 200-day simple moving average. A condition name without ticker applies to all potential tickers in a basket, for example PriceAboveMA20 filters out trading days for the relevant ticker unless its price is above 20-day simple moving average. ",
    },
    {
      question: "How do I build my specific entry and exit condition? ",
      answer:
        "On the Single Strategy page, after you choose your Trading Instrument, the Indicator Type pull down menu in the Entry Conditions or Exit Conditions is activated. The last item on the menu after Valuation is Create custom indicator. Click on Create custom indicator. On the Indicator Constructor Tool page, choose your Indicator Type. Most of custom indicator building is using the Input Type box, Operator Function box, and Instrument Type and Ticker box. Please watch the tutorial Custom Indicator under Resources/Tutorials.",
    },
    {
      question: "How do I know if the calculation results are correct?",
      answer:
        "There are many ways for users to verify calculation results. Users can download the strategy NAV curve and historical positions and use these positions and users' own price data to verify the NAV history. Users can also replicate known strategies using OdinUltra and compare performance.",
    },
  ],
  forecastCompany: [
    {
      question: "What is Odin's methodology behind company fundamentals forecasts?",
      answer:
        "Odin uses high quality big data, supply chain interconnects, and proprietary finance-trained pre-processing, and machine learning algorithms to model and forecast companies' financials and operating metrics. Through a selection process, Odin zeros in on a sub-set of the data in its possession to model and forecast each target metric separately. A variety of self-learning models are used in the process to adopt with companies evolving business segments, availability of new data, and economic conditions. Currently, Odin's vast data sets include high frequency alternative data sourced from over 1,400+ authoritative data sources generating 15M+ time-series. 51,000+ supply-chain interconnects, global macro-economic indicators, currency rates, commodity prices, and companies' own explicit/implicit guidance.",
    },
    {
      question: "What are Odin's data sources?",
      answer:
        "The inputs to our forecasting models are factual data. We do not use any panel data (such as email receipts or credit card data). Through years of experimentations, we discovered that panel data could lead into very inaccuracy forecasting due to inherent sampling challenges such as risk of bias, sample sizing, sampling errors, and sampling methods. We rely mostly on large datasets from government agencies globally, industry associations, companies' financials/operating metrics, ecosystem interconnects, and more.",
    },
    {
      question: "How does Odin ensure data integrity and reliability?",
      answer:
        "Odin delays sophisticated algorithms to continuously ensure various data integrity aspects such as ingestion, data formatting, unit conversions, outlier discovery, consistency, and timeliness. Odin escalate issues to the engineering team.",
    },
    {
      question: "How often are quarterly company fundamentals forecasts updated?",
      answer:
        "Forecasts are updated at a new data arrival or due to model ensembling processing. The forecasting algorithms run on daily basis, however, the one-quarter out forecasts are generally stable and slow moving.",
    },
    {
      question: "Why are the forecast seem to track consensus estimates?",
      answer:
        "One of the key forecast drivers we use is companies' own explicit/implicit guidance since it is an important material information available. Consensus estimates are usually set at the mid-points of such guidance. This will lead to tracking resemblance of Odin forecasts with those of consensus estimates.",
    },
    {
      question: "Why sometimes forecasts are exactly the same as analyst estimates?",
      answer:
        "Sometimes, Odin determines that the consensus estimates are accurate and better than its own forecasts for one or more metrics. In those cases, Odin just shows consensus estimates for those metrics.",
    },
    {
      question: "How do I know which forecasts are high confidence ones?",
      answer:
        "Odin watches its own forecasting capability at metric level of each company. If it has been able to forecast a particular metric accurately in the recent past, it will flag that with * in front of that metric in the forecast table or explicitly states that on forecast charts.",
    },
    {
      question: "How often do the models change?",
      answer:
        "We use various models to forecast financial metrics. We use a proprietary ensembling process to allows us introduce new models while keeping the previous models too.",
    },
    {
      question: "Can Odin forecast every company every quarter?",
      answer:
        "Odin tracks it owns recent forecast performance and it only shows forecasts that have met its quality thresholds. This means that Odin may not be able to forecast certain metrics of some companies (or some companies in entirety) some quarters.",
    },
    {
      question: "How are the forecast errors calculated?",
      answer:
        "Forecast errors are Mean Absolute Percent Error over trailing twelve months (4 fiscal quarters)",
    },
    {
      question: "How far back the actual forecast history go?",
      answer:
        "We are currently showing our historical forecasts for the past 8 quarters. We have escrowed our sales forecast in a third party since escrow company since September 2016.",
    },
  ],
  forecastMacro: [
    {
      question: "What is Odin's methodology behind macroeconomics forecasts?",
      answer:
        "Odin uses high quality big data and machine learning algorithms to model and forecast economic metrics. We start by dissecting key economic metrics into its separate components. For example, with GDP, there are four components: consumption, investment, government spending, and net exports. Each component is predicted independently, with relevant, high frequency, and quality data. For instance, we model consumer spending based on factors like income trends, retail sales, consumer sentiment, and inflation. Once the components have finalized projections, we combine them into the high-level economic metric forecast. Since economic metrics like GDP and CPI have several moving parts, this allows us to be highly accurate and capture where shifts are originating from",
    },
    {
      question: "How often are our macroeconomics forecasts updated?",
      answer:
        "Forecasts are updated at a new data arrival or due to model ensembling processing. The forecasting algorithms run on daily basis. Our models generally become more accurate as more data arrives. So, for one-month out forecasts, at the end of the month, and for one-quarter forecasts, at the end of the calendar quarter.",
    },
    {
      question: "Do you use consensus?",
      answer:
        "Our macroeconomic forecasts are completely independent of consensus. However, we do track it to understand where the markets believe economic metrics are headed. As such, we can understand where our forecasts are versus market's expectations.",
    },
    {
      question: "What are Odin's data sources?",
      answer:
        "The inputs to our forecasting models are factual data. We do not use any panel data (such as email receipts or credit card data). Through years of experimentations, we discovered that panel data could lead into very inaccuracy forecasting due to inherent sampling challenges such as risk of bias, sample sizing, sampling errors, and sampling methods. We rely mostly on large datasets from government agencies globally, industry associations, companies' financials/operating metrics, ecosystem interconnects, and more.",
    },
    {
      question: "How does Odin ensure data integrity and reliability?",
      answer:
        "Odin delays sophisticated algorithms to continuously ensure various data integrity aspects such as ingestion, data formatting, unit conversions, outlier discovery, consistency, and timeliness. Odin escalate issues to the engineering team.",
    },
    {
      question: "How can users check the accuracy of our forecasts?",
      answer:
        "We show our historical forecasts versus the actual reported figures in the charts to show accuracy levels and trends.",
    },
    {
      question: "How often do the models change?",
      answer:
        "We use various models to forecast economic metrics. We use a proprietary ensembling process to allows us to introduce new models while keeping the previous models too.",
    },
    {
      question: "Can Odin forecast macro metrics for international economies?",
      answer: "Currently, Odin's forecasts are focused on the U.S. economy.",
    },
    {
      question: "How are the forecast errors calculated?",
      answer:
        "Forecast errors for CPI are Mean Absolute Error over trailing twelve months (4 fiscal quarters). Forecast errors for GDP are Root Mean Squared Error over sixteen quarters.",
    },
    {
      question: "How far back the actual forecast history go?",
      answer:
        "For CPI, our forecast history dates back to Jan 2022. For GDP, our forecast history dates back to Dec 2018. We escrow our macro forecasts at a third party, which was established in September 2016.",
    },
  ],
  marketView: [
    {
      question: "How do I read the Closest Market Pattern Matches?",
      answer:
        "We track a comprehensive list of economic and market indicators near real-time. On any trading day, we compare these indicators using up-to-date data with the same indicators historically, and subsequently list the closest matches.",
    },
    {
      question: "What are the Market Drivers from Closest Market Pattern Matches?",
      answer:
        "The overall matching score we use is a comprehensive one. Two previous trading days with similar matching scores to the current day can be different themselves. Each of those two trading days can match to current day with a similar score, yet contributions to the total match scores can be from on different indicators. Market Drivers page displays the most relevant indicators to this match.",
    },
    {
      question: "How do I read the Market Spider chart?",
      answer:
        "We capture economic conditions using decile scores from six different aspects. The further outer a point is on the spider web, the higher the score it is. The macro score is based on key macroeconomic indicators, the higher the score the better the macroeconomic condition. The fundamental score measures the growth and acceleration of key company fundamentals. The valuation score measures the valuation of major US equity indices. Liquidity measures the change in the money supply in the US financial system. A low liquidity score indicates fast liquidity drain in the US financial system. Sentiment score measures the rank of the bull bear spread in the AAII sentiment survey. A low sentiment score indicates a high bearish versus bullish sentiment. Technical score measures rank of the market momentum.",
    },
    {
      question: "What is the Put/Call ratio trend for the total market?",
      answer:
        "This is the time series of the ratio of total traded put options volume to the total traded call option volume for the whole US market. The trend line is the simple 50-day moving average of this total market put-call ratio. A high put-call ratio indicates that wide-spread bearish sentiment on the overall US equity market.",
    },
    {
      question: "How do I read Put/Call ratio trend for sectors?",
      answer:
        "For each sector, we show the time series of the ratio of total traded put options volume to the call options volume for the relevant sector ETF. Compare page shows all sector ETFs' put-call ratios' 50-day moving averages.",
    },
    {
      question:
        "Many economic releases including NBER recession declaration are revised regularly. Do you use the revised data or first release?",
      answer:
        "Whenever practical we use the first release of the macro-economic inputs in our model. For the forecast target NBER recession declaration, we use the first release as well as revisions within subsequent six months. We adopt this methodology as many users of this model are financial market practitioners who look for better estimates of expected future economic activities and such expectation can affect market activity.",
    },
  ],
  etfIntelligence: [
    {
      question: "What does it provide?",
      answer:
        "ETF Intelligence offers a comprehensive overview of the overall ETF markets. It helps users understand current trends in the ETF markets. For example, the most popular ticker among ETFs and how many ETFs hold it. Through its advanced filtering search capability, users can investigate specific subgroups deeper.",
    },
    {
      question: "What filters can be applied?",
      answer:
        "Advanced filtering capability include by category, management strategy, and asset class style. Categories encompasses various providers, such as iShares, SPDR, Vanguard, VanEck, and more. Categories also covers themes, such as AI, dividend, volatility, income, momentum, and others. Management strategies include passive and active. Asset class styles include blend, growth, and value. ",
    },
    {
      question: "How far back does the data go?",
      answer:
        "We have been collecting this data since 2020. Aggregated fund flows into ETFs, as well as put and call volumes of ETFs, go back as far as 2010.",
    },
    {
      question: "Where does the data come from?",
      answer:
        "Our data comes from ETFdatabase.com. We aggregate the data at the large-scale to extract meaningful insights. ",
    },
  ],
  eventsCalendar: [
    {
      question: "What kind of events are covered?",
      answer:
        "Odin offers two calendars. One related to earnings and another related to economic releases. Currently, events such as investor days and dividend dates are not captured.",
    },
    {
      question: "How many tickers are in the earnings calendar?",
      answer:
        "We capture earnings dates for 6,000 tickers. It includes all the tickers in the major U.S. indices, such as S&P 500, Nasdaq Composite, Dow Jones, and Russell 2000. ",
    },
    {
      question: "How do I know if an earnings date is confirmed? ",
      answer:
        "An earnings date is confirmed if it does not have an asterisk symbol (*) next to the date. ",
    },
    {
      question: "What countries are included in the economic calendar?",
      answer: "We capture economic releases for over 100 countries, spanning multiple regions. ",
    },
    {
      question: "What kind of releases are included?",
      answer:
        "We have nearly 12,000 economic releases. It covers bond auction dates, FOMC meetings and minutes, FOMC speeches, government budget publications, retail sales, employment figures, inflation indexes, and many more. ",
    },
    {
      question: "Are historical release dates available?",
      answer:
        "Yes, historical release dates are available. For the earnings calendar, we display three years history (2020). For the economic calendar, we display fifteen years history (2009).",
    },
    {
      question: "How far ahead do the calendars show?",
      answer:
        "For the earnings calendar, we display up to five months ahead. For the economic calendar, we display up to eight months ahead. ",
    },
  ],
  portfolioAllocationOptimizer: [
    {
      question: "What is the format of the input portfolio?",
      answer:
        "Currently users can upload CSV files with two columns. The column names should be “ticker, allocation”, or “ticker,share”. The first format “ticker,allocation” shows the input portfolio’s tickers and weights. The second format “ticker,share” shows the input portfolio’s tickers and number of shares.",
    },
    {
      question: "For my uploaded portfolio, what does the performance mean?",
      answer:
        "The performance measure for the uploaded portfolio assumes no rebalancing.  If the uploaded portfolio is in the “ticker,allocation” format, it is first converted to number of shares assuming an initial portfolio NAV. These shares number will then remain fixed during the testing period.",
    },
    {
      question: "Can I access my portfolio that I uploaded previously?",
      answer:
        "Yes, you can choose from your previously uploaded portfolios by clicking the arrow head in the “Select Allocations CSV” drop down box.",
    },
    {
      question: "Do I have to put in a date range?",
      answer: "No, in this case the date range where all tickers have data will be used.",
    },
  ],
};
