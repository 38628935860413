import { useMemo, useState } from "react";

export const usePagination = <T>(options: { list: T[]; page: number; perPage: number }) => {
  const { page, perPage, list } = options;

  const paginatedList = useMemo(
    () => (list ? list.slice((page - 1) * perPage, (page - 1) * perPage + perPage) : []),
    [page, list, perPage]
  );

  const totalPages = list ? Math.ceil(list.length / perPage) : null;
  const isEmpty = paginatedList.length === 0;

  return {
    isEmpty,
    totalPages,
    paginatedList,
  };
};

export const useExtendPagination = <T>(options: { list: T[]; page: number; perPage: number }) => {
  const { list, ...rest } = options;
  const [paginator, setPaginator] = useState(rest);

  const perPageHandler = (perPage: number) => {
    setPaginator((prev) => ({
      ...prev,
      perPage,
    }));
  };

  const pageHandler = (page: number) => {
    setPaginator((prev) => ({
      ...prev,
      page,
    }));
  };

  const resetPages = () => {
    setPaginator((prev) => ({ ...prev, page: rest.page }));
  };

  const paginatedList = useMemo(
    () =>
      list
        ? list.slice(
            (paginator.page - 1) * paginator.perPage,
            (paginator.page - 1) * paginator.perPage + paginator.perPage
          )
        : [],
    [paginator, list]
  );

  const totalPages = list ? Math.ceil(list.length / paginator.perPage) : null;

  return {
    totalPages,
    paginatedList,
    perPageHandler,
    pageHandler,
    resetPages,
    paginator,
  };
};
