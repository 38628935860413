import { FC, Fragment } from "react";

import { Button, Dropdown, ILabelItem, ItemType, LoaderWrapper, RadioList } from "@/components";
import { productList } from "@/constants";
import { IProfilePagePermissionType, IUser } from "@/types";

import { useGetUserAccessStatus } from "../../hooks/useGetUserAccessStatus";
import { useSelectPageAccessProduct } from "../../hooks/useSelectPageAccessProduct";
import { useUpdateAccessConfig } from "../../hooks/useUpdateAccessConfig";

import styles from "./UserPermissions.module.scss";

interface IProps {
  selectedUser: IUser | null;
}

export const UserPermissions: FC<IProps> = ({ selectedUser }) => {
  const email = selectedUser?.email || "";
  const { setAccessConfig, setProgress, accessConfig, configRef, progress } =
    useGetUserAccessStatus(selectedUser);

  const { selectProductHandle, selectStatusHandle, activeStatus, statusList, product } =
    useSelectPageAccessProduct(setAccessConfig, accessConfig);

  const { saveHandle, canSaveConfig } = useUpdateAccessConfig(
    accessConfig,
    configRef,
    email,
    setProgress
  );

  return (
    <div className={styles.container} style={{ minHeight: progress ? 240 : "auto" }}>
      <LoaderWrapper loading={progress} transparent>
        <div className={styles.fieldRow}>
          <p className={styles.groupLabel}>Change permissions by Product</p>
          <ProductFieldsRow
            statusList={statusList}
            productList={productList}
            onSelectProduct={selectProductHandle}
            onSelectStatus={selectStatusHandle}
            product={product}
            status={activeStatus}
            label="Product"
          />
        </div>
      </LoaderWrapper>

      <div className={styles.bntWrapper}>
        <Button text="Save Changes" type="primary" onClick={saveHandle} disabled={!canSaveConfig} />
      </div>
    </div>
  );
};

interface IProductFieldsRowProps {
  statusList: ILabelItem<string>[];
  productList: ItemType<string>[];
  onSelectStatus: (name: string) => void;
  onSelectProduct: (name: string, item: ItemType<string>) => void;
  product: ItemType<string> | null;
  status: ItemType<IProfilePagePermissionType> | null;
  label?: string;
  flow?: "column" | "row";
}

export const ProductFieldsRow: FC<IProductFieldsRowProps> = ({
  onSelectStatus,
  onSelectProduct,
  product,
  status,
  statusList,
  productList,
  label = "",
  flow = "row",
}) => {
  return (
    <Fragment>
      <div className={styles.group} data-flow={flow}>
        <div className={styles.itemWrapper}>
          <Dropdown<"product", string>
            name="product"
            onSelect={onSelectProduct}
            list={productList}
            selected={product}
            placeholder="Select  Forecast, Modeling or All. "
            label={label}
          />
        </div>
        <div className={styles.statusGroup}>
          <RadioList
            name="status"
            labels={statusList}
            value={status?.key || ""}
            onChange={onSelectStatus}
            disabled={product === null}
          />
        </div>
      </div>
    </Fragment>
  );
};
