import dayjs from "dayjs";
import { FC, useState } from "react";

import { Button, DateObj, DatePickerRange } from "@/components";
import { DATE_ISO } from "@/constants";
import { StrategyTypeEnum } from "@/types";

import styles from "./DateSelectConfirmation.module.scss";

import { ReactComponent as PerformanceIcon } from "@images/chart-histogram-two-filled.svg";
import { ReactComponent as InfoIcon } from "@images/info.svg";
import { ReactComponent as CoreIcon } from "@images/strategy.svg";

type DateType = { start: string | null; end: string | null };

interface IProps {
  onConfirm: (dateRange: DateType | null) => void;
  onCancel: () => void;
  name: string | null;
  description: string | null;
  type?: StrategyTypeEnum[] | null;
}

export const DateSelectConfirmation: FC<IProps> = ({
  onConfirm,
  onCancel,
  name,
  type = [StrategyTypeEnum.STRATEGY_BASE],
  description,
}) => {
  const [dateRange, setDateRange] = useState<DateType | undefined>();
  const selectDateHandler = (selectedDateRange: DateObj) => {
    setDateRange({
      start: selectedDateRange.start ? dayjs(selectedDateRange.start).format(DATE_ISO) : null,
      end: selectedDateRange.end ? dayjs(selectedDateRange.end).format(DATE_ISO) : null,
    });
  };

  const confirmHandler = () => {
    onConfirm(dateRange || null);
  };

  const color = type?.includes(StrategyTypeEnum.STRATEGY_OVERLAY)
    ? "var(--secondary)"
    : "var(--primary)";

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>View Performance</h2>
      <div className={styles.mainSection}>
        <p className={styles.title}>
          <div className={styles.iconWrapper} style={{ color }}>
            <CoreIcon />
          </div>
          Strategy: <span>{name}</span>
        </p>
        <p className={styles.description}>
          <div className={styles.iconWrapper} style={{ color }}>
            <InfoIcon />
          </div>
          Description: <span>{name}</span>
        </p>
        <div className={styles.dateGroup}>
          <DatePickerRange
            date={dateRange}
            onSelect={selectDateHandler}
            label="Select Date Interval"
          />
        </div>
        <div className={styles.btnWrapper}>
          <Button text="Cancel" type="gost" onClick={onCancel} />
          <Button
            text="View Performance"
            type="primary"
            onClick={confirmHandler}
            IconComponent={PerformanceIcon}
          />
        </div>
      </div>
    </div>
  );
};
