import { FC, forwardRef, Ref, useImperativeHandle, useRef } from "react";

import { Button, ItemType, LoaderWrapperCustom } from "@/components";
import { useAppSelector, useAppDispatch } from "@/hooks/redux";
import { ReactComponent as NestIcon } from "@/images/nest.svg";
import { ReactComponent as NoVideoIcon } from "@/images/no-video.svg";
import { ReactComponent as UploadIcon } from "@/images/upload-icon.svg";
import { AdminPanelState, deletePage } from "@/store/adminPanel/adminPanel";
import { IAdminPanelPages, IMeta, ResetVideoRef } from "@/types/adminPanel";
import { notification } from "@/utils";

import { useUploadVideo } from "../../hooks/useUploadVideo";

import styles from "./VideoUpload.module.scss";

interface IProps {
  page: IAdminPanelPages | undefined;
  ref: Ref<ResetVideoRef>;
  selectedSub: ItemType<string> | null;
  meta: IMeta;
}
const MAX_VIDEO_SIZE = 200 * 1024 * 1024; // Maximum size of 200MB;

export const VideoUpload: FC<IProps> = forwardRef<ResetVideoRef, IProps>(
  ({ page, selectedSub, meta }, ref) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { isVideoLoading } = useAppSelector(AdminPanelState);
    const dispatch = useAppDispatch();

    const { video, handleUpload, handleClear, onUpload } = useUploadVideo(page, meta);

    useImperativeHandle(ref, () => ({
      reset: () => handleClear(),
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] || null;
      if (file && file.size <= MAX_VIDEO_SIZE) {
        handleUpload(file);
      } else if (file) {
        notification.error("File size exceeds the maximum limit of 200MB.");
      }
    };

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };

    const handleDelete = () => {
      if (!page?.id) return;
      if (page.meta.attr && selectedSub?.key !== page.meta.attr) {
        dispatch(deletePage(page?.id));
      } else {
        onUpload(page?.id, null);
      }
    };

    return (
      <div className={styles.container}>
        <LoaderWrapperCustom loading={isVideoLoading}>
          <div className={styles.videoSection}>
            {page?.video_url ? (
              <div className={styles.videoContant}>
                <video key={page?.video_url} controls>
                  <source src={page?.video_url || ""} type="video/mp4" />
                </video>
              </div>
            ) : (
              <div className={styles.noVideo}>
                <span>No Video</span>
                <NoVideoIcon />
              </div>
            )}
          </div>
        </LoaderWrapperCustom>
        <div className={styles.uploadSection}>
          <h3>Upload Video</h3>
          <div className={styles.videoName}>
            <span>
              {video?.name || page?.meta?.videoFileName
                ? video?.name || page?.meta?.videoFileName
                : "Video Not Selected"}
            </span>
          </div>
          <input
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            id="profileImage"
            onChange={handleChange}
            accept=".mp4, .webm, .mov"
          />
          <div className={styles.buttons}>
            <Button
              IconComponent={NestIcon}
              onClick={handleDelete}
              type="secondary"
              text="Delete"
              danger
            />
            <Button
              IconComponent={UploadIcon}
              onClick={handleButtonClick}
              type="primary"
              text="Select Video File"
              disabled={!page?.id}
            />
          </div>
        </div>
      </div>
    );
  }
);
