import { FC, Fragment, useEffect, useMemo } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Slogan } from "@/components";
import { UserInfo } from "@/components/UserInfo/UserInfo";
import { ROUTES } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getProfileInfo, profileInfo } from "@/store/profile";

import { useUpdateChart } from "../DataPortal/components/FocusTabs/hooks/useUpdateChart";
import { LiveMarketSlider } from "../LiveMarketSlider/LiveMarketSlider";

import styles from "./Header.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";

interface IProps {
  onMenuToggle: (state?: boolean) => void;
  toggle: boolean;
}

export const Header: FC<IProps> = ({ onMenuToggle, toggle }) => {
  const isMobile = useMediaQuery("(max-width: 991px)");
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  useUpdateChart();

  const onMenuClose = () => {
    onMenuToggle(false);
  };

  useEffect(() => {
    if (profile?.id) return;
    dispatch(getProfileInfo());
  }, [dispatch, profile]);

  if (!profile?.id) return null;

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer} onClick={onMenuClose}>
        <NavLink to={ROUTES.marketView.path}>
          <Slogan size="small" />
        </NavLink>
      </div>
      <div className={styles.sliderConatiner}>{!isMobile && <LiveMarketSlider />}</div>
      <div className={styles.itemsGroup}>
        {!isMobile && <UserInfo isMobile={false} onSelect={onMenuToggle} />}
        {isMobile && <BurgerMenu onToggle={onMenuToggle} toggle={toggle} />}
      </div>
    </div>
  );
};

interface IBurgerMenuProps {
  onToggle: () => void;
  toggle: boolean;
}

const BurgerMenu: FC<IBurgerMenuProps> = ({ onToggle, toggle }) => {
  return (
    <div
      className={`${styles.burgerMenu} ${toggle ? styles.opened : ""}`}
      onClick={() => onToggle()}
    >
      <p></p>
      <p></p>
      <p></p>
    </div>
  );
};

export const BreadCrumbs: FC = () => {
  const location = useLocation();

  const routesPathList = useMemo(() => {
    const pathObj = Object.values(ROUTES) as unknown as (string | { path: string; key?: string })[];
    const filtered = pathObj.filter((i) => typeof i !== "string") as {
      path: string;
      key?: string;
    }[];

    const paths = filtered
      .filter((i) => location.pathname.includes(i.path))
      .sort((a, b) => a.path.length - b.path.length);

    const foundPath = paths?.[paths?.length - 1];

    return foundPath?.key ? foundPath.key.split("/") : null;
  }, [location]);

  return (
    <div className={styles.breadCrumbs}>
      {2 <= (routesPathList?.length || 0)
        ? routesPathList?.map((path, idx) => (
            <Fragment key={path}>
              <p>{path}</p>
              {routesPathList && routesPathList.length > 1 && idx < routesPathList.length - 1 ? (
                <Arrow />
              ) : null}
            </Fragment>
          ))
        : null}
    </div>
  );
};
