import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
  Ref,
} from "react";

import { ItemType, Popover, RadioOption, RadioSelect, Tag } from "@/components";
import { DropdownMultiSearch } from "@/components/Dropdown/DropdownMultiSearch";
import { DIMENSIONS_FILTER_OPERATORS, FILTER_OPERATORS } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { ExtraDimensionsRef, IExtraDimansion } from "@/types";
import { getExtraFields } from "@/utils";

import styles from "./ExtraDimensions.module.scss";

interface IProps {
  filterParams: Record<string, string[]>;
  setSelected: Dispatch<SetStateAction<Record<string, ItemType<string>[]>>>;
  selected: Record<string, ItemType<string>[]>;
  dimensions: IExtraDimansion[];
  setDimensions: Dispatch<SetStateAction<IExtraDimansion[]>>;
  operation: Record<string, string>;
  setOperation: Dispatch<SetStateAction<Record<string, string>>>;
  ref: Ref<ExtraDimensionsRef>;
}

export const ExtraDimensions: FC<IProps> = forwardRef<ExtraDimensionsRef, IProps>(
  (
    { filterParams, setSelected, selected, dimensions, setDimensions, operation, setOperation },
    ref
  ) => {
    const { drsMeta } = useAppSelector(discoverDrsMetaState);
    const handleSelect = (name: string, item: ItemType<string>[]) => {
      const filteredItem = item.filter((subItem) => subItem.value.trim() !== "");

      setSelected((state) => {
        const nextState = { ...state };

        if (filteredItem.length === 0) {
          delete nextState[name];
        } else {
          nextState[name] = filteredItem;
        }

        return nextState;
      });
    };

    useImperativeHandle(ref, () => ({
      reset: () => setFields(),
    }));

    useEffect(() => {
      if (!dimensions.length) return;
      const defaultOperators = dimensions.reduce((acc, dim) => {
        acc = { ...acc, [dim.name]: FILTER_OPERATORS.eq.id };
        return acc;
      }, {});
      setOperation(defaultOperators);
    }, [dimensions, setOperation]);

    const regularDimensions = useMemo(
      () =>
        drsMeta
          ? [
              ...drsMeta.format.dimensions,
              ...drsMeta.format.metrics,
              ...drsMeta.format.time_dimensions,
              ...(drsMeta.format.revision_date ? [drsMeta.format.revision_date] : []),
              ...(drsMeta.format.revision_status ? [drsMeta.format.revision_status] : []),
            ]
          : [],
      [drsMeta]
    );

    const setFields = useCallback(() => {
      if (!drsMeta?.fields) return;
      const extra = getExtraFields(drsMeta.fields, regularDimensions);
      setDimensions(extra || []);
    }, [drsMeta?.fields, regularDimensions, setDimensions]);

    useEffect(() => {
      setFields();
    }, [setFields]);

    const handleRemove = (key: string) => () => {
      setDimensions(dimensions.filter((item) => item.name !== key));
      setSelected({});
    };

    const handleSelectOperator = (id: string, dimension: string) => () => {
      setOperation((state) => ({
        ...state,
        [dimension]: id,
      }));
    };

    if (!dimensions.length) {
      return null;
    }

    const content = (name: string) => {
      return (
        <div className={styles.content}>
          <RadioSelect>
            {DIMENSIONS_FILTER_OPERATORS.map((item) => (
              <RadioOption
                key={item.key}
                selected={operation[name] === item.key}
                onClick={handleSelectOperator(item.key, name)}
              >
                {item.value}
              </RadioOption>
            ))}
          </RadioSelect>
          <DropdownMultiSearch
            list={
              filterParams?.[name]?.map((param) => ({
                key: param,
                value: param,
              })) || []
            }
            onSelect={handleSelect}
            selected={selected[name] || []}
            label=""
            name={name}
          />
        </div>
      );
    };

    return (
      <div className={styles.container}>
        <div className={styles.sectionName}>
          <span>Extra Fields</span>
        </div>
        {dimensions.map(({ name, type }) => (
          <Popover disabled={type === "datetime"} key={name} content={<div>{content(name)}</div>}>
            <Tag color="primary" onDelete={handleRemove(name)}>
              <div className={styles.tagContent}>
                <div>{name}</div>
                {selected?.[name]?.length && (
                  <div>
                    [
                    <span>
                      {
                        DIMENSIONS_FILTER_OPERATORS.find((oper) => oper.key === operation[name])
                          ?.value
                      }{" "}
                    </span>
                    {selected?.[name]?.map((item) => (
                      <span className={styles.comma} key={item.id}>
                        {item.value}
                      </span>
                    ))}
                    ]
                  </div>
                )}
              </div>
            </Tag>
          </Popover>
        ))}
      </div>
    );
  }
);
