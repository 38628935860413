import { createSlice, createAsyncThunk, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { odinChatServices } from "@/services";
import { IListItemToUpdate } from "@/types";
import { IChatMessage, IMessageData } from "@/types/odinChat";

import { RootState } from "..";

export interface IPaging {
  page: number;
  perpage: number;
  preloader: boolean;
}

interface IInitialState {
  isLoading: boolean;
  list: IChatMessage[] | null;
  isRequested: boolean;
  idToUpdate: IListItemToUpdate | null;
}

export const DEFAULT_PER_PAGE = 30;

const initialState: IInitialState = {
  isLoading: true,
  list: [],
  isRequested: false,
  idToUpdate: null,
};

export const fetchMessagesListFocus = createAsyncThunk(
  "odinCharts/fetchMessagesList",
  async (uid: string) => {
    const { data } = await odinChatServices.getMessages(uid, {
      page: 1,
      perpage: DEFAULT_PER_PAGE,
    });
    return data.result;
  }
);

export const fetchUserMessageFocus = createAsyncThunk(
  "odinCharts/fetchUserMessage",
  async (msgData: IMessageData) => {
    const { data } = await odinChatServices.sendUserMessage(msgData);
    return data.result;
  }
);

export const updateOdinChartsSlice = createSlice({
  name: "odinCharts",
  initialState,
  reducers: {
    init: (state) => {
      state.isLoading = true;
    },
    setRequested: (state) => {
      state.list = [{ status: "In progress" }] as any;
    },
    setIdToUpdate: (state, { payload }: PayloadAction<IListItemToUpdate | null>) => {
      state.idToUpdate = payload;
    },
  },
  // get messages list at first time
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesListFocus.pending, (state) => {
        state.isLoading = true;
        state.isRequested = true;
      })
      .addCase(fetchMessagesListFocus.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.isLoading = false;
      })
      .addCase(fetchMessagesListFocus.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isRequested = false;
      });

    // send user message to odin-bot
    builder
      .addCase(fetchUserMessageFocus.pending, (state) => {
        //
      })
      .addCase(fetchUserMessageFocus.fulfilled, (state, { payload }) => {
        const message = state.list?.find((msg) => msg.id === "-1") || null;
        if (message && payload.id) {
          message.id = payload.id;
        }
      })
      .addCase(fetchUserMessageFocus.rejected, (state, { payload }) => {
        //
      });
  },
});

const root = (state: RootState) => state;

export const updateOdinChartsState = createSelector(root, (state) => state.updateOdinCharts);

export const { init, setRequested, setIdToUpdate } = updateOdinChartsSlice.actions;

export default updateOdinChartsSlice.reducer;
