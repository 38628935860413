export interface IFormat {
  replace?: Record<string, string>;
  floatDigits: { value: number; change: number };
  postfix: Record<"value" | "change", string>;
  order?: string[];
}

export const cardFormatRules: Record<string, IFormat> = {
  indices: {
    replace: {
      "CBOE Volatility Index": "CBOE VIX",
    },
    floatDigits: {
      value: 2,
      change: 2,
    },
    postfix: {
      value: "",
      change: "%",
    },
  },
  "market-yeld": {
    replace: {
      "Effective Fed Funds Rate": "Fed Funds Rate",
      "13-Week Treasury Bill Yield": "US 13-Week",
      "5-Year Treasury Yield": "US 5-Year",
      "10-Year Treasury Yield": "US 10-Year",
      "30-Year Treasury Yield": "US 30-Year",
    },
    floatDigits: {
      value: 3,
      change: 3,
    },
    postfix: {
      value: "",
      change: "",
    },
  },
  commodities: {
    replace: {
      "Silver Near Term (NYM $/ozt)": "Silver",
      "Crude Oil NYMEX Near Term ($/bbl)": "Crude Oil",
      "Gold NYMEX Near Term ($/ozt)": "Gold",
      "Corn (Cen Ill $/bu)": "Corn",
      "Copper NYMEX Near Term ($/lb)": "Copper",
    },
    floatDigits: {
      value: 2,
      change: 2,
    },
    postfix: {
      value: "",
      change: "%",
    },
    order: [
      "Crude Oil NYMEX Near Term ($/bbl)",
      "Gold NYMEX Near Term ($/ozt)",
      "Silver Near Term (NYM $/ozt)",
      "Copper NYMEX Near Term ($/lb)",
      "Corn (Cen Ill $/bu)",
    ],
  },
  "extra-rates": {
    floatDigits: {
      value: 4,
      change: 2,
    },
    postfix: {
      value: "",
      change: "%",
    },
  },
};
