export const CRITERIA_LIST = [
  "Ticker",
  "Company Name",
  "Sector",
  "Industry Group",
  "Industry",
  "Sub Industry",
];

export const CRITERIA_LIST_FILTER = [
  {
    label: "Sector",
    value: "Sector",
  },
  {
    label: "Industry Group",
    value: "Industry Group",
  },
  {
    label: "Industry",
    value: "Industry",
  },
  {
    label: "Sub Industry",
    value: "Sub Industry",
  },
];

export const CRITERIA_LIST_FILTER_BEAT_MISS = [
  {
    label: "Revenue",
    value: "revenue_beat_miss",
    value2: "revenue_confident",
  },
  {
    label: "Gross Income",
    value: "gross_income_beat_miss",
    value2: "gross_income_confident",
  },
  {
    label: "Gross Margin",
    value: "gross_margin_beat_miss",
    value2: "gross_margin_confident",
  },
  {
    label: "EBITDA",
    value: "ebitda_beat_miss",
    value2: "ebitda_confident",
  },
  {
    label: "EBITDA Margin",
    value: "ebitda_margin_beat_miss",
    value2: "ebitda_margin_confident",
  },
];

export const EXCLUDE_KEY = "NA";
