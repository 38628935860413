import dayjs from "dayjs";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";

import { Loader } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { socketService } from "@/services";
import { AdminPanelState } from "@/store/adminPanel/adminPanel";
import { getFolders } from "@/store/focus/foldersManage";
import {
  addMessage,
  fetchMarkMessagesAsReaded,
  fetchMessagesList,
  fetchMessagesListSilent,
  fetchMoreMessagesList,
  fetchRefuseChatRequest,
  fetchUserMessage,
  odinChatState,
} from "@/store/odinchat";
import { profileInfo } from "@/store/profile";
import { IChatMessage } from "@/types/odinChat";
import { commandHelper, commandsInterceptor, prepareMessagesList } from "@/utils";

import { InputMessageBox, MessagesListBox } from "./components";
import { ITEM_DATA_NAME } from "./hooks/useMessagesObserevr";
import { useUnrededCounter } from "./hooks/useUnreadedCounter";

import styles from "./UserOdinChat.module.scss";

const INITIAL_REQUEST_DELAY = 1_200; // milliseconds

export const UserOdinChat: FC = () => {
  const dispatch = useAppDispatch();
  const { list, isLoading, paging, optionsList, unreaded, isCancelInProgress } =
    useAppSelector(odinChatState);
  const { profile, uid } = useAppSelector(profileInfo);
  const timerId = useRef<NodeJS.Timer | null>(null);
  const isRequested = useRef(false);
  const unreadedCount = useUnrededCounter(unreaded);
  const { pages } = useAppSelector(AdminPanelState);

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  // const updateMessages = useCallback((uid: string | null, delay = INITIAL_REQUEST_DELAY) => {
  //   if (uid) dispatch(fetchMessagesListSilent(uid));
  // }, []);

  const isInputDisabled = useMemo(
    () => list?.some(({ status }) => "In progress" === status),
    [list]
  );

  const messageHandler = useCallback(
    (text: string) => {
      const messageItem: IChatMessage = {
        id: "-1",
        created_at: dayjs().tz().format(),
        text,
        type: "WEB",
        from_phone: uid || "",
        to_phone: "CHATBOT",
        parent_id: "",
        status: "In progress",
        is_new_user_message: true,
        is_read: true,
      };

      if (uid) {
        if (timerId.current) clearInterval(timerId.current);
        // intercepting commands
        const [helpTextList, newText] = commandsInterceptor(text, pages);
        //
        if (helpTextList?.length) {
          helpTextList.forEach((helpText, id) => {
            socketService.addHelpMessage(helpText, uid, id);
          });
        }
        dispatch(addMessage(messageItem));
        dispatch(fetchUserMessage({ user: uid, text: newText })).then(() => {
          dispatch(fetchMarkMessagesAsReaded({ uid: "", ids: [] }));
        });
      }

      commandHelper.setItem(text);
    },

    [profile, list, uid]
  );

  const messagesList = useMemo(() => {
    const user = { name: profile?.first_name || null, lastName: profile?.last_name || null };
    return uid ? prepareMessagesList(list, uid, user) : [];
  }, [list, profile]);

  const loadMoreDataHandler = useCallback(() => {
    if (!paging.preloader && uid) {
      dispatch(fetchMoreMessagesList(uid));
    }
  }, [paging.preloader, uid]);

  const toMessageHandler = useCallback(() => {
    if (unreaded) {
      const id = unreaded.slice(0, 1)[0] || null;
      const item = document.querySelector(`[${ITEM_DATA_NAME}="${id}"]`);
      item?.scrollIntoView({ block: "center", behavior: "smooth" });
      dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids: unreaded }));
    }
  }, [unreaded]);

  const refuseMessageHandler = useCallback(() => {
    const lastId = list?.slice(-1).pop()?.id;
    if (uid !== null && lastId && lastId != "-1") {
      const message: IChatMessage = {
        id: "#",
        created_at: dayjs().tz().format(),
        text: "🤔 STOPPED by the user!",
        type: "WEB",
        from_phone: "CHATBOT",
        to_phone: uid || "",
        parent_id: "#",
        status: "Completed",
        is_new_user_message: false,
        is_read: true,
      };
      dispatch(fetchRefuseChatRequest({ message, id: lastId }));
      dispatch(fetchMessagesListSilent(uid)).then(() => {
        dispatch(fetchMarkMessagesAsReaded({ uid: "-", ids: unreaded || [] }));
      });
    }
  }, [uid, list, unreaded]);

  //get messages after getting unreaded list once
  useEffect(() => {
    const getData = async () => {
      if (uid && unreaded !== null && isRequested.current === false) {
        isRequested.current = true;
        await dispatch(fetchMessagesList(uid));
      }
    };
    getData();
  }, [uid, unreaded]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={`${styles.preloaderContainer} ${paging.preloader ? styles.show : styles.hide}`}
        >
          {paging.preloader && <Loader />}
        </div>
        <MessagesListBox
          list={messagesList}
          loading={isLoading}
          onLoadMoreData={loadMoreDataHandler}
          preloader={paging.preloader}
          unreaded={unreaded}
        />
      </div>
      <div className={styles.inputContainer}>
        {unreadedCount && (
          <div className={styles.unreaded} onClick={toMessageHandler}>
            {unreadedCount}
          </div>
        )}
        <InputMessageBox
          disabled={isInputDisabled}
          refuseDisabled={uid === null}
          onMessage={messageHandler}
          optionsList={optionsList}
          onCancel={refuseMessageHandler}
          inProgress={isCancelInProgress}
        />
      </div>
    </div>
  );
};
