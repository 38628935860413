import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { GoBack, Heading } from "@/components";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import { IDrs } from "@/types";

import styles from "./SubcategoryInner.module.scss";

interface IProps {
  setSelectedDrs: (drs: IDrs[]) => void;
  selectedDrs: IDrs[];
}

export const SubcategoryInner: FC<IProps> = ({ setSelectedDrs, selectedDrs }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    setSelectedDrs([]);
  };

  const handleClick = (drs: IDrs) => () => {
    navigate(`details/${drs.drs_id}`);
  };

  return (
    <div className={styles.container}>
      <Heading type="h3" className={styles.subHeading}>
        {selectedDrs[0].subcategory.includes("Unassigned")
          ? "Miscellaneuos"
          : selectedDrs[0].subcategory}
      </Heading>
      <GoBack onClick={handleBack} />
      <div className={styles.sourcesAvailable}>
        <span>{`${selectedDrs.length} sources available`}</span>
      </div>
      <ul className={styles.list}>
        {selectedDrs.map((source) => (
          <li key={source.drs_id} onClick={handleClick(source)}>
            <span className={styles.name} dangerouslySetInnerHTML={{ __html: source.name }} />
            <div className={styles.tagsArrowContainer}>
              <div className={styles.tags}>
                {source.tickers?.map((ticker) => (
                  <span key={ticker} className={styles.tag}>
                    {ticker}
                  </span>
                ))}
              </div>
              <div className={styles.arrow}>
                <Arrow />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
