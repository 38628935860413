import { FC } from "react";

import { INavState } from "@/types";

import styles from "./Navigation.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";

interface IProps {
  onLeft: () => void;
  onRight: () => void;
  navState: INavState;
}

export const Navigation: FC<IProps> = ({ onLeft, onRight, navState }) => {
  const { left, right } = navState;
  return (
    <div className={styles.container}>
      <div className={`${styles.arrowWrapper} ${left ? "" : styles.disabled}`} onClick={onLeft}>
        <Arrow className={styles.arrowLeft} />
        <span className={styles.leftText}>Prev Template</span>
      </div>
      {/* <span>Page: 1</span> */}
      <div className={`${styles.arrowWrapper} ${right ? "" : styles.disabled}`} onClick={onRight}>
        <span className={styles.rightText}>Next Template</span>
        <Arrow className={styles.arrowRight} />
      </div>
    </div>
  );
};
