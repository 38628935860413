import { Dispatch, FC, SetStateAction, useState } from "react";

import { Button, CustomSelect } from "@/components";
import { useOuterClick, useAppSelector } from "@/hooks";
import { ReactComponent as FilterIcon } from "@/images/filter.svg";
import { economicCalendarState } from "@/store/dataBrowser/economicCalendar";

import styles from "./Filter.module.scss";

interface IProps {
  selectedCountry: string | undefined;
  setSelectedCountry: Dispatch<SetStateAction<string | undefined>>;
}

export const Filter: FC<IProps> = ({ selectedCountry, setSelectedCountry }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { countries, isLoadingCountries } = useAppSelector(economicCalendarState);
  const [refOutside] = useOuterClick(setShowFilter, false);

  const isDisablesReset = !selectedCountry;

  const handleSelectType = (selected: string) => {
    setSelectedCountry(selected);
  };

  const handelResetFilter = () => {
    setSelectedCountry(undefined);
  };

  return (
    <div ref={refOutside} className={styles.container}>
      <FilterIcon onClick={() => setShowFilter(!showFilter)} className={styles.icon} />
      {showFilter && (
        <div className={styles.filterContainer}>
          <div className={styles.label}>
            <span>Filtering by</span>
          </div>
          <div className={styles.countrySelect}>
            <div className={styles.field}>
              <span>Country</span>
            </div>
            <CustomSelect
              onChange={handleSelectType}
              options={countries?.map((item) => ({
                label: item,
                value: item,
              }))}
              value={selectedCountry}
              isLoading={isLoadingCountries}
            />
          </div>
          <div className={styles.resetFilter}>
            <Button
              disabled={isDisablesReset}
              onClick={handelResetFilter}
              text="Reset Filter"
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};
