import { FC, Fragment, memo, useCallback, useEffect, useRef, useState } from "react";

import { Button, Heading } from "@/components";
import { Skeleton } from "@/components/Skeleton/Skeleton";
import { tikersMetaList } from "@/constants/etfIntelligence";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useCreateCSV, useAppSelector, useAppDispatch } from "@/hooks";
import {
  etfIntelligenceState,
  fetchTopTickersList,
} from "@/store/forecast/forecastEtfIntelligence";
import { DirrectionType, IEtfTicker, ITickerMeta, ITickersList } from "@/types";
import { convertToCSVArray } from "@/utils";

import { InfinitList } from "./InfinitList/InfinitList";
import { Sorter } from "./Sorter/Sorter";
import styles from "./TickersTable.module.css";
import { NoData } from "../NoData/NoData";

const DEFAULT_COUNT = 10;

export const TickersTable: FC = () => {
  const dispatch = useAppDispatch();
  const { requestStr: request, tickersTable, category } = useAppSelector(etfIntelligenceState);
  const tikersTopList = tickersTable[category];
  const isRequested = useRef<string | null>(null);
  const [isInViewport, invewportItemRef] = useInViewportElement(false);

  useEffect(() => {
    if (isRequested.current !== request && isInViewport) {
      tikersTopList.forEach(
        ({ values, query, url }) =>
          values === null &&
          dispatch(
            fetchTopTickersList({
              query,
              url,
            })
          )
      );
      isRequested.current = request;
    }
  }, [tikersTopList, isInViewport, request]);
  return (
    <div className={styles.container} ref={invewportItemRef}>
      {tikersTopList.map((table, idx) => (
        <div className={styles.tableContainre} key={table.title}>
          <TickersList table={table} key={table.query} formatter={tikersMetaList[idx].formatter} />
        </div>
      ))}
    </div>
  );
};

interface ITickersListProps {
  table: ITickersList;
  formatter: (data: IEtfTicker, meta: ITickerMeta | null) => string;
}

export const TickersList: FC<ITickersListProps> = memo(({ table, formatter }) => {
  const [dirrection, setDirrection] = useState<DirrectionType>("default");
  const [showMore, setShowMore] = useState(false);

  const createCSV = useCreateCSV();
  const changeDirHandle = () => {
    if (dirrection === "asc") setDirrection("desc");
    else if (dirrection === "desc") setDirrection("default");
    else if (dirrection === "default") setDirrection("asc");
  };

  const downloadHandle = useCallback(
    (table: ITickersList) => {
      const csvData = convertToCSVArray(table);
      createCSV(csvData, table.title);
    },
    [createCSV]
  );

  const seeMoreHandle = useCallback(() => {
    setShowMore((prev) => !prev);
  }, []);

  return (
    <Fragment>
      <div className={styles.headerWrapper}>
        <Heading className={styles.heading} type="h3">
          {table.title}
        </Heading>
        {!!table.values?.length && <Sorter dirrection={dirrection} onChange={changeDirHandle} />}
      </div>
      {table.values ? (
        <NoData show={!table.values.length}>
          <div className={styles.infiniteWrapper}>
            <InfinitList
              list={table.values}
              formatter={formatter}
              sortDir={dirrection}
              meta={table.meta}
              showMore={showMore}
              defaultCount={DEFAULT_COUNT}
            />
            {!!table.values.length && (
              <div className={styles.btnContainer}>
                {table.values.length > DEFAULT_COUNT ? (
                  <div>
                    <Button
                      type="primary"
                      onClick={seeMoreHandle}
                      size="small"
                      text={showMore ? "Cancel" : "See more"}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <div>
                  <Button
                    type="secondary"
                    text="Download CSV"
                    onClick={() => downloadHandle(table)}
                    size="small"
                  />
                </div>
              </div>
            )}
          </div>
        </NoData>
      ) : (
        <div>
          <Skeleton loading rows={10} active lineHeight="36px">
            <div></div>
          </Skeleton>
        </div>
      )}
    </Fragment>
  );
});
