import { FC, memo, useRef } from "react";

import { ChartEquities, Heading } from "@/components";
import { useInViewportElement } from "@/hooks";
import { checkDataTickerInfo, getSeriesDataCategory, isObjectEmpty } from "@/utils";

import styles from "./Charts.module.scss";

interface IProps {
  chartData: any;
  command?: string;
}

const DEFAULT_CHART_HEIGHT = 190;
const WITH_TABS_CHART_HEIGHT = 175;

export const ForecastChart: FC<IProps> = memo(({ chartData, command }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewportElement(ref, false);
  const keys = Object.keys(chartData || {}).filter(
    (key) => isObjectEmpty(chartData[key]) === false
  );
  const defaultTab = keys[0];
  let chart;
  try {
    chart = getSeriesDataCategory(chartData[defaultTab] || {}, {
      defaultSelected: false,
      isHideEmpty: true,
      isSorted: true,
    });
  } catch (e) {
    console.error(e);
    return (
      <div className={styles.error}>
        <p>Data is not available</p>
      </div>
    );
  }

  const quarters =
    chartData && defaultTab
      ? (chartData._meta[defaultTab]?.fiscal_quarters as Record<string, string>) || null
      : null;

  const quartersEndMonth =
    chartData && defaultTab
      ? (chartData._meta[defaultTab]?.quarters_end_month as Record<string, string>) || null
      : null;

  const [tickerInfo, isValid] = checkDataTickerInfo(command || null);
  const titleText = isValid
    ? tickerInfo?.industry
      ? `INDUSTRY: ${tickerInfo.industry}`
      : tickerInfo?.sector
      ? `SECTOR: ${tickerInfo?.sector}`
      : tickerInfo?.company
      ? `COMPANY: ${tickerInfo?.company} (${tickerInfo.ticker})`
      : tickerInfo?.ticker
      ? `COMPANY: (${tickerInfo.ticker})`
      : null
    : null;

  return (
    <div className={styles.container} ref={ref}>
      <Heading className={styles.heading} type="h5">
        {titleText}
      </Heading>
      <div className={styles.content}>
        <div className={styles.labels}>
          {keys.map((key, i) => {
            if ("_meta" === key) return null;
            return (
              <span className={`${0 === i ? styles.selected : ""}`} key={key}>
                {key}
              </span>
            );
          })}
        </div>
        {isInViewport ? (
          <ChartEquities
            height={keys.length ? WITH_TABS_CHART_HEIGHT : DEFAULT_CHART_HEIGHT}
            ticker={keys[0]}
            isQuarters
            data={chart.data}
            quarters={quarters}
            hasEndDate={!!quartersEndMonth}
            endQuartersDate={quartersEndMonth ? quartersEndMonth : undefined}
            isChartPreview
          />
        ) : (
          <div style={{ height: "300px", width: "300px" }}></div>
        )}
      </div>
    </div>
  );
});
