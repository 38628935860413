import { FC, useMemo } from "react";

import { ExtendedColumns, Table } from "@/components";
import { IMarketDrivers } from "@/types";

import styles from "./MarketDriversTable.module.scss";

interface IProps {
  data: IMarketDrivers[] | null;
}

export const MarketDriversTable: FC<IProps> = ({ data }) => {
  const tableData = useMemo(() => data, [data]);
  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Indicator Name",
        accessor: "Indicator Name",
        canSort: true,
        minWidth: 220,
        Cell: ({ value }: { value: string }) => {
          return <p className={styles.indicatorName}>{value}</p>;
        },
      },
      {
        Header: "Definition",
        accessor: "Definition",
        canSort: true,
        minWidth: 220,
        Cell: ({ value }: { value: string }) => {
          return <p className={styles.description}>{value}</p>;
        },
      },
      {
        Header: "Type",
        accessor: "Type",
        canSort: true,
        minWidth: 120,
        Cell: ({ value }: { value: string }) => {
          return <p className={styles.type}>{value}</p>;
        },
      },
    ],
    []
  );
  return (
    <div className={styles.container}>
      <Table<IMarketDrivers>
        size="small"
        columns={columns}
        data={tableData ?? []}
        isNotClickable={true}
      />
    </div>
  );
};
