import { useCallback, useState } from "react";

import { FeedbackService } from "@/services/feedbackService";
import { notification } from "@/utils/notification";

export const useFeedback = () => {
  const [isLoading, setLoading] = useState(false);
  const [isOk, setOk] = useState<boolean | null>(null);

  const sendFeedback = useCallback(async (form: FormData) => {
    setLoading(true);
    try {
      await FeedbackService.feedback(form);
      setOk(true);
    } catch (e) {
      notification.error("Something is wrong please try again!");
      setOk(false);
    } finally {
      setLoading(false);
    }
  }, []);
  return { sendFeedback, isLoading, isOk, setOk };
};
