import { useState } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks";
import FolderFocusIcon from "@/images/folder-focus.svg";
import FolderIcon from "@/images/folder.svg";
import { addDataToFolder } from "@/store/focus/folderSelected";
import { foldersManageState } from "@/store/focus/foldersManage";
import { FolderTypeEnum } from "@/types";

export const useSaveToFolder = (
  command: string | null,
  name: string | null,
  type: FolderTypeEnum
) => {
  const dispatch = useAppDispatch();
  const { list, isLoading: isLoadingFolders } = useAppSelector(foldersManageState);
  const [discription, setDiscription] = useState("");

  const folders = list.map((folder) => ({
    icon: "FOCUS" === folder.name ? FolderFocusIcon : FolderIcon,
    title: folder.name,
    key: folder.id,
  }));

  const handleSelectedFolder = (key: string | number) => {
    if (!command) return;
    const payload = {
      command,
      type: type || "ForecastEquities",
      description: `${name}`,
      title: discription,
    };
    dispatch(addDataToFolder({ id: key, data: payload }));
  };

  const handleAddDiscription = (value: string) => {
    setDiscription(value);
  };

  return { folders, handleSelectedFolder, handleAddDiscription };
};
