import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { ForecastService } from "@/services/forecastService";
import { ITickerType } from "@/types/forecast";
import { notification } from "@/utils";

export type DataType = Record<string, Record<string, Record<string | "_meta", number>>>;

export const useGetEquitiesChart = (
  tickerInfo: ITickerType | null
): {
  isLoading: boolean;
  data: DataType;
} => {
  const [data, setData] = useState<DataType>({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  const getChart = useCallback(async () => {
    setIsLoading(true);
    let resultData: DataType = {};
    try {
      if (tickerInfo?.ticker) {
        const res = await ForecastService.getEquitiesChart(tickerInfo.ticker);
        resultData = res.data.result;
      } else if (tickerInfo?.industry) {
        const query = encodeURIComponent(tickerInfo.industry);
        const res = await ForecastService.getIndustryChart(query);
        resultData = res.data.result;
      } else if (tickerInfo?.sector) {
        const query = encodeURIComponent(tickerInfo.sector);
        const res = await ForecastService.getSectorChart(query);
        resultData = res.data.result;
      }
      setData(resultData);
    } catch (e) {
      notification.error("Something is wrong please try again!");
    } finally {
      setIsLoading(false);
    }
  }, [tickerInfo?.industry, tickerInfo?.sector, tickerInfo?.ticker]);

  useEffect(() => {
    getChart();
  }, [dispatch, getChart]);

  return { isLoading, data };
};
