import { FC, memo, useEffect, useMemo, useState } from "react";

import { ChartHomePage, DropdownOutline, GaugeChart, ItemType, LoaderWrapper } from "@/components";
import { GaugeLineChart } from "@/components/Chart/GaugeLineChart";
import { SpiderChart } from "@/components/Chart/SpiderChart";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import {
  ChartTypeEnum,
  IChartCard,
  IClosedMarket,
  IDaysRange,
  IGenericChartData,
  IMarketSpider,
  IPreformingChart,
  MarketCondition,
  ResultType,
} from "@/types";
import { getSeriesDataCategory, orderObject } from "@/utils";

import { useGetMarketViewData } from "../../hooks/useGetMarketViewData";
import { TableMarketView } from "../TableMarketView/TableMarketView";

import styles from "./ChartCard.module.scss";

interface IProps {
  data: IChartCard;
}

interface ISpecificChartProps {
  onRefrash: () => void;
  selectDaysRange: (range?: IDaysRange) => void;
  toggle: boolean;
  isLoading: boolean;
  cardHeight: number;
}

const getSpecificChart = (
  type: ChartTypeEnum,
  data: ResultType,
  { onRefrash, selectDaysRange, isLoading, cardHeight, toggle }: ISpecificChartProps
) => {
  if (ChartTypeEnum.GAUGE === type) {
    return (
      <GaugeChart
        title={null}
        height={cardHeight}
        gaugeData={{
          min: -10,
          max: 10,
          value: (data as MarketCondition)?.score,
          unit: "",
          subtitle: "",
          labelName: (data as MarketCondition)?.trajectory,
        }}
      />
    );
  }
  if (ChartTypeEnum.SPIDER === type) {
    const orderedKeys = [
      "macro",
      "fundamentals",
      "sentiment",
      "technical",
      "valuation",
      "liquidity",
    ];
    const ordered = orderObject(data as IMarketSpider, orderedKeys);
    return <SpiderChart height={cardHeight} data={ordered} />;
  }
  if (ChartTypeEnum.GAUGE_LINE === type) {
    return <GaugeLineChart height={cardHeight} data={[data as number]} />;
  }
  if (ChartTypeEnum.TABLE === type) {
    return (
      <TableMarketView
        onRefrash={onRefrash}
        selectDaysRange={selectDaysRange}
        data={data as IClosedMarket | null}
        isLoading={isLoading}
        height={cardHeight}
        toggle={toggle}
      />
    );
  }
  if (ChartTypeEnum.COLUMN === type) {
    const transformedData = (Array.isArray(data) ? data : []).reduce(
      (
        result: { data: (string | number)[][]; name: string; type: string },
        entry: IPreformingChart
      ) => {
        const ticker = entry["Ticker"];
        const returnPercent = entry["Return (%)"];

        result.data.push([ticker, returnPercent]);

        return result;
      },
      { data: [], name: "Return (%)", type: "column" }
    );
    return <ChartHomePage type="column" height={cardHeight} data={[transformedData]} />;
  }
  const formattedData = getSeriesDataCategory(data as IGenericChartData, {
    defaultSelected: false,
    isReversed: false,
  }).data;

  return (
    <ChartHomePage
      isMultyAxes={ChartTypeEnum.ONE_AXIS_LINE === type}
      height={cardHeight}
      data={formattedData}
    />
  );
};

export const calculateCardHeight = () => {
  // const totalAvailableHeight = window.innerHeight * 1.5; // You can adjust this based on your actual available space
  // const calculatedHeight = totalAvailableHeight / MAX_CARDS_TO_DISPLAY;
  return 340;
};

export const ChartCard: FC<IProps> = memo(({ data }) => {
  const [selected, setSelected] = useState<Record<string, ItemType<string>> | null>(null);
  const [selectedDaysRange, setSelectedDaysRange] = useState<IDaysRange>();
  const [toggle, setToggle] = useState(false);
  const [isRefrash, setRefrash] = useState(false);
  const isMobile = useMediaQuery("(max-width: 575px)");

  const { title, type, link, requestType } = data;

  const dropdownList = useMemo(() => Object.keys(data?.dropdown || {}), [data?.dropdown]);

  const onRefrash = () => {
    setRefrash(true);
  };

  const toggleHandler = () => {
    setToggle((prev) => !prev);
  };

  const { result, isLoading, hasError } = useGetMarketViewData(link, {
    selected,
    requestType,
    hasParams: !!dropdownList.length,
    selectedDaysRange, // move in context
    isRefrash, // move in context
  });

  useEffect(() => {
    const defaultSelected = dropdownList.reduce((acc, name) => {
      if (name === "title") return acc;
      acc = {
        ...acc,
        [name]: {
          key: data?.dropdown[name]?.[0],
          value: data?.dropdownLabel?.[data?.dropdown[name]?.[0]] ?? data?.dropdown[name]?.[0],
        },
      };
      return acc;
    }, {});
    setSelected(defaultSelected);
  }, [data?.dropdown, data?.dropdownLabel, dropdownList]);

  const handleSelect = (name: string, item: ItemType<string>) => {
    setSelected((state) => ({
      ...state,
      [name]: item,
    }));
  };

  const selectDaysRange = (range?: IDaysRange) => {
    setSelectedDaysRange(range);
    setRefrash(false);
  };

  const chart = useMemo(
    () =>
      getSpecificChart(type, result, {
        onRefrash,
        selectDaysRange,
        isLoading,
        cardHeight: calculateCardHeight(),
        toggle,
      }),
    [isLoading, result, type, toggle]
  );

  const titleText = toggle ? "Closest Market Pattern Matches" : title;
  const buttonText = toggle ? "Market Value" : "Pattern Matches";

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles.title}>{titleText}</div>
        {dropdownList.map((name) => (
          <div key={name}>
            {name === "title" ? (
              <div className={`${styles.title} ${styles.inner}`}>{data?.dropdown[name]}</div>
            ) : (
              <div
                style={{ width: isMobile ? data?.dropdownWidth[name] : "auto" }}
                className={styles.dropdown}
              >
                <DropdownOutline
                  list={
                    data?.dropdown?.[name].map((item: string) => ({
                      key: item,
                      value: data?.dropdownLabel?.[item] ?? item,
                    })) || []
                  }
                  onSelect={handleSelect}
                  selected={selected ? selected[name] : null}
                  label=""
                  name={name}
                />
              </div>
            )}
          </div>
        ))}
        <ForwardButton
          visible={!!data?.hasTable && !isLoading}
          text={buttonText}
          onClick={toggleHandler}
        />
      </div>
      <div className={styles.chart}>
        <LoaderWrapper loading={isLoading} removeContentOnLoading={false}>
          {!hasError ? (
            <div className={styles.chartContainer}>{chart}</div>
          ) : (
            <div className={styles.emptyData}>No Data.</div>
          )}
        </LoaderWrapper>
      </div>
    </div>
  );
});

interface IForwardButtonProps {
  visible: boolean;
  text: string;
  onClick: () => void;
}

const ForwardButton: FC<IForwardButtonProps> = ({ visible, onClick, text }) => {
  if (!visible) return null;
  return (
    <div className={styles.linkButton} onClick={onClick}>
      <span>{text}</span>
      <Arrow />
    </div>
  );
};
