import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellProps, Column } from "react-table";

import { Button, ExtendedColumns, Pagination, TableExpand } from "@/components";
import { Skeleton } from "@/components/Skeleton/Skeleton";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useCreateCSV, useAppDispatch, useAppSelector } from "@/hooks";
import {
  etfIntelligenceState,
  fetchNewETFTableData,
} from "@/store/forecast/forecastEtfIntelligence";
import { INewETFTableItem } from "@/types";
import { convertTbaleToCSV } from "@/utils";

import { CellItem } from "./CellItem";

import styles from "./ETFTable.module.scss";

const MIN_PAGE_SIZE = 10;

interface IProps {
  tableName: string;
}

export const NewETFTable: FC<IProps> = ({ tableName }) => {
  const { requestStr: request, category, newEtfTable } = useAppSelector(etfIntelligenceState);
  const tableData = newEtfTable[category];
  const dispatch = useAppDispatch();
  const [isInViewport, invewportItemRef] = useInViewportElement(false);
  const isRequested = useRef<string | null>(null);
  const [show, setShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(MIN_PAGE_SIZE);

  const createCSV = useCreateCSV();

  useEffect(() => {
    if (isRequested.current !== request && isInViewport && tableData.table === null) {
      dispatch(fetchNewETFTableData());
      isRequested.current = request;
    }
    if (isInViewport) setShow(true);
  }, [request, isInViewport, tableData]);

  const downloadCSVHandle = useCallback(() => {
    if (tableData.table) {
      const csvData = convertTbaleToCSV(tableData.table);
      createCSV(csvData, tableName);
    }
  }, [tableData.table, tableName]);

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  }, []);

  const resetPageHandler = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const etfTableColumns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "ETF Name",
        accessor: "symbol",
        canSort: true,
        minWidth: 150,
        Cell: (prop: CellProps<object>) => {
          const { symbol, index } = prop.row.original as INewETFTableItem;
          return <CellItem valueText={symbol} postfix="" tooltip={index} />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        canSort: false,
        disableSortBy: true,
        isTruncated: true,
        minWidth: 300,
      },
      {
        Header: "Management Strategy",
        accessor: "management_strategy",
        canSort: false,
        disableSortBy: true,
        minWidth: 180,
      },
      {
        Header: "Nr. of Holdings",
        accessor: "nr_of_holdings",
        canSort: false,
        disableSortBy: true,
        minWidth: 140,
      },
      {
        Header: "Expense Ratio",
        accessor: "expense_ratio",
        canSort: false,
        disableSortBy: true,
        minWidth: 130,
      },
      {
        Header: "AUM($M)",
        accessor: "aum",
        canSort: false,
        disableSortBy: true,
        minWidth: 110,
      },
      {
        Header: "Issuer",
        accessor: "issuer",
        canSort: false,
        disableSortBy: true,
        isTruncated: true,
        minWidth: 250,
      },
      {
        Header: "Inception Date",
        accessor: "inception_date",
        canSort: false,
        disableSortBy: true,
        minWidth: 130,
      },
    ],
    []
  );

  const totalPages = tableData.table ? Math.ceil(tableData.table.length / perPage) : null;

  return (
    <div className={styles.container} ref={invewportItemRef}>
      {show && (
        <div className={styles.simpleTableContainer}>
          {tableData.table ? (
            <div className={styles.simpleTable}>
              <TableExpand<INewETFTableItem>
                columns={etfTableColumns}
                data={tableData.table || []}
                scrollWidth={1500}
                size="medium"
                currentPage={currentPage}
                perPage={perPage}
                resetPage={resetPageHandler}
                isNotClickable
              />
              <div
                className={styles.btnGroupContainer}
                style={{
                  paddingTop: tableData.table.length === 0 ? 0 : "var(--spacing-m)",
                }}
              >
                {tableData.table.length > MIN_PAGE_SIZE && (
                  <div className={styles.pagination}>
                    <Pagination
                      onPage={setCurrentPage}
                      onChangePerPage={selectPerPageHandler}
                      perPage={perPage}
                      currentPage={currentPage}
                      totalCount={totalPages}
                      count={tableData.table.length}
                      perPageList={[10, 20, 50, 100]}
                      showTotal={false}
                    />
                  </div>
                )}
                {tableData.table.length > 0 && (
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      type="primary"
                      onClick={downloadCSVHandle}
                      text="Download CSV"
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Skeleton loading active lineHeight="28px" rows={5}>
              <div />
            </Skeleton>
          )}
        </div>
      )}
    </div>
  );
};
