import { AxiosError } from "axios";
import { FC, Fragment, useCallback, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  Button,
  Confirm,
  GoBack,
  InfoHeading,
  Loader,
  ModalLayout,
  Pagination,
  TableExpand,
} from "@/components";
import { paginator as defaultPaginator } from "@/constants/strategy";
import { useExtendPagination } from "@/hooks";
import { useGetUserDataDetailsQuery } from "@/services/apiQuery/userData";
import { UserDataFileType } from "@/types";

import { FileNotFound } from "./FileNotFound/FileNotFound";
import { useRemoveModal } from "./hooks/useRemoveModal";
import { useTableDate } from "./hooks/useTableData";

import styles from "./MyDataDetails.module.scss";

import { ReactComponent as AllocationIcon } from "@images/allocation.svg";
import { ReactComponent as RemoveIcon } from "@images/nest.svg";
import { ReactComponent as ReturnIcon } from "@images/war-room.svg";

interface IErrorResponse extends AxiosError {
  data: unknown;
}

const NOT_FOUND = 404;

export const MyDataDetails: FC = () => {
  const navigate = useNavigate();
  const { id = "0" } = useParams();
  const [searchParams] = useSearchParams();

  const type = (searchParams.get("type") || null) as UserDataFileType | null;
  const fileName = searchParams.get("name");

  const {
    data,
    isFetching: isLoadingData,
    error,
  } = useGetUserDataDetailsQuery({ id, type: type || "allocations" });

  const onComplete = useCallback(() => {
    navigate("..");
  }, []);

  const { confirmRemoveHandler, removeHandler, cancelHandler, showModal, isRemoving } =
    useRemoveModal(onComplete);

  const { tableData, columns } = useTableDate(type, data);

  const { totalPages, paginator, perPageHandler, pageHandler, resetPages } = useExtendPagination({
    list: tableData,
    page: 1,
    perPage: defaultPaginator.default,
  });

  const isNoDataFound = useMemo(() => {
    const responseError = error as IErrorResponse;
    if (responseError?.status === NOT_FOUND) return true;
    return false;
  }, [error]);

  const removeFileHandler = () => {
    if (id && type) {
      removeHandler({ id: Number(id), type });
    }
  };

  const title = type === "allocations" ? "Allocations" : "Returns";

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <InfoHeading>My Data</InfoHeading>
        <div className={styles.navGroup}>
          <GoBack onClick={() => navigate(-1)} />
        </div>
        {isNoDataFound ? (
          <FileNotFound title="Sorry, the related CSV file was not found!" />
        ) : (
          <div className={styles.content}>
            {isLoadingData ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                <div className={styles.tableContainer}>
                  <div className={styles.headingRowGroup}>
                    <div className={styles.smallIconWrapper}>
                      {type === "allocations" ? (
                        <AllocationIcon color={"var(--primary"} />
                      ) : (
                        <ReturnIcon />
                      )}
                    </div>
                    <p>
                      {title}:<span>{fileName}</span>
                    </p>
                    {isRemoving ? (
                      <div className={styles.loaderWrapper}>
                        <Loader />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${styles.innerTableWrapper} ${isRemoving ? styles.disable : ""}`}
                  >
                    <TableExpand
                      columns={columns}
                      data={tableData}
                      size="medium"
                      currentPage={paginator.page}
                      perPage={paginator.perPage}
                      resetPage={resetPages}
                      isNotClickable={true}
                    />
                  </div>

                  <div className={styles.buttonWrapper}>
                    <Button
                      text="Delete File"
                      danger
                      onClick={removeFileHandler}
                      disabled={isRemoving}
                      IconComponent={RemoveIcon}
                    />
                  </div>
                </div>

                <div className={styles.pagination}>
                  <Pagination
                    onPage={pageHandler}
                    onChangePerPage={perPageHandler}
                    perPage={paginator.perPage}
                    currentPage={paginator.page}
                    perPageList={defaultPaginator.perPageList}
                    totalCount={totalPages}
                    count={tableData.length}
                    showTotal={true}
                    disabled={isRemoving}
                    countLabel="Total Rows"
                  />
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
      <ModalLayout show={showModal} onClose={cancelHandler}>
        <Confirm
          onCancel={cancelHandler}
          onConfirm={confirmRemoveHandler}
          text="Do you really want to DELETE this file?"
          type="delete"
        />
      </ModalLayout>
    </div>
  );
};
