import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";

import {
  createChartList,
  createEtfTable,
  createInfoTable,
  createPieChartList,
  createTickersList,
  getCategories,
} from "@/constants/etfIntelligence";
import { EtfIntelligenceService } from "@/services/etfIntelligence";
import {
  Categories,
  IEtfChart,
  IEtfCHartData,
  IPieChartData,
  IPieChartItem,
  IETFTableData,
  ITickersList,
  IInfoTableData,
  INewETFTableItem,
  INewETFTableData,
  IETFTableItem,
  IMetaDate,
  ITickerData,
  IExpenseRatioItem,
  Management,
  Assets,
  IExtendMetaData,
} from "@/types/etfIntelligence";
import { createMetaData, mapTopList, notification } from "@/utils";

import { RootState } from "..";

interface IInitialState {
  loading: boolean;
  pieCharts: Record<Categories, IPieChartData[]>;
  charts: Record<Categories, IEtfCHartData[]>;
  infoTable: Record<Categories, IInfoTableData>;
  etfTable: Record<Categories, IETFTableData>;
  newEtfTable: Record<Categories, INewETFTableData>;
  tickersTable: Record<Categories, ITickersList[]>;
  requestCount: number;
  initialized: boolean;
  metaDate: IMetaDate | null;
  requestStr: string;
  management: Management;
  category: Categories;
  assets: Assets;
}

const initialState: IInitialState = {
  loading: false,
  pieCharts: createPieChartList(),
  charts: createChartList(),
  infoTable: createInfoTable(),
  etfTable: createEtfTable<IETFTableItem[]>(),
  newEtfTable: createEtfTable<INewETFTableItem[]>(),
  tickersTable: createTickersList(),
  requestCount: 0,
  initialized: false,
  metaDate: null,
  requestStr: "all/all/all",
  management: "all",
  category: "All",
  assets: "All",
};

export const fetchPieChartData = createAsyncThunk(
  "forecastEtfIntelligence/fetchPieChartData",
  async (meta: { query: string; key: string }, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const { key, query } = meta;
    const metaData = { query, category, management, assets };
    const { data } = await EtfIntelligenceService.getChartData<IPieChartItem[]>(metaData);
    return { data: data.result, key };
  }
);

export const fetchChartData = createAsyncThunk(
  "forecastEtfIntelligence/fetchChartData",
  async (meta: { query: string; id: number }, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const { id, query } = meta;
    const metaData = { query, category, management, assets };
    const { data } = await EtfIntelligenceService.getChartData<IEtfChart>(createMetaData(metaData));
    return { data: data.result, id };
  }
);

export const fetchETFTableData = createAsyncThunk(
  "forecastEtfIntelligence/fetchETFTableData",
  async (_, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const metaData = { category, management, assets };
    const { data } = await EtfIntelligenceService.getETFTableData(metaData);
    return data.result;
  }
);

export const fetchNewETFTableData = createAsyncThunk(
  "forecastEtfIntelligence/fetchNewETFTableData",
  async (_, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const metaData = { category, management, assets };
    const { data } = await EtfIntelligenceService.getNewETFTableData(metaData);
    return data.result;
  }
);

export const fetchTopTickersList = createAsyncThunk(
  "forecastEtfIntelligence/fetchTopTickersList",
  async (meta: { query: string; url: string | null }, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const { url, query } = meta;
    const metaData = { category, management, url, query, assets };
    const { data } = await EtfIntelligenceService.getTickersList(metaData);
    return { list: data.result, key: meta.query };
  }
);

export const fetchInfoMetaData = createAsyncThunk(
  "forecastEtfIntelligence/fetchInfoMetaData",
  async () => {
    const { data } = await EtfIntelligenceService.getInfoMeta();
    const metaDateRes = await EtfIntelligenceService.getInfoDate();
    return { data, metaDate: metaDateRes.data };
  }
);

export const fetchInfoTableData = createAsyncThunk(
  "forecastEtfIntelligence/fetchInfoTableData",
  async (_, { getState }) => {
    const {
      forecastEtfIntelligence: { category, management, assets },
    } = getState() as RootState;
    const metaData: IExtendMetaData = { category, assets, management };
    const { data } = await EtfIntelligenceService.getInfoTableData(metaData);
    return { data: data.result, category };
  }
);

export const forecastEtfSlice = createSlice({
  name: "forecastEtfIntelligence",
  initialState,
  reducers: {
    setLoading: (state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) => {
      state.loading = loading;
    },
    initialize: (state) => {
      state.tickersTable = createTickersList();
      state.charts = createChartList();
      state.pieCharts = createPieChartList();
      state.etfTable = createEtfTable();
      state.infoTable = createInfoTable();
      state.newEtfTable = createEtfTable();
      state.category = "All";
      state.management = "all";
      state.assets = "All";
    },
    makeRequest: (
      state,
      {
        payload: { category, management, request, assets },
      }: PayloadAction<{
        request: string;
        category: Categories;
        management: Management;
        assets: Assets;
      }>
    ) => {
      state.requestStr = request;
      state.management = management;
      state.category = category;
      state.assets = assets;

      state.infoTable[state.category].error = null;
      state.infoTable[state.category].data = null;

      state.pieCharts[state.category].forEach((pieChhartItem) => {
        pieChhartItem.data = null;
        pieChhartItem.error = null;
      });

      state.charts[state.category].forEach((chartItem) => {
        chartItem.data = null;
        chartItem.error = null;
      });

      state.tickersTable[state.category].forEach((tickersItem) => {
        tickersItem.values = null;
        tickersItem.error = null;
      });

      state.etfTable[state.category].table = null;
      state.etfTable[state.category].error = null;

      state.newEtfTable[state.category].table = null;
      state.newEtfTable[state.category].error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfoTableData.pending, (state) => {
        state.requestCount++;
      })
      .addCase(fetchInfoTableData.fulfilled, (state, { payload: { data } }) => {
        state.infoTable[state.category].data = data;
        state.requestCount--;
      })
      .addCase(fetchInfoTableData.rejected, (state, { payload }) => {
        state.requestCount--;
        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus)) {
        notification.error("Error while requesting info data!");
        state.infoTable[state.category].error = "error";
        // }
      });

    builder
      .addCase(fetchInfoMetaData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInfoMetaData.fulfilled, (state, { payload: { data, metaDate } }) => {
        if (data && metaDate) {
          const categories = getCategories();
          state.loading = false;
          const list = categories.reduce((acc, name) => {
            acc[name] = data.map((meta, idx) => ({
              data: null,
              meta: { ...meta, id: idx },
              error: null,
            }));
            return acc;
          }, {} as Record<Categories, IEtfCHartData[]>);
          state.charts = list;
          state.initialized = true;
          state.metaDate = metaDate;
        }
      })
      .addCase(fetchInfoMetaData.rejected, (state, { payload }) => {
        state.loading = false;
        // if (!checkHTTPError.isAccessDenied(payload as number))
        notification.error("Error while getting etf charts data!");
      });

    builder
      .addCase(fetchTopTickersList.pending, (state, { meta }) => {
        state.requestCount++;
      })
      .addCase(fetchTopTickersList.fulfilled, (state, { payload: { list, key } }) => {
        const tableList = state.tickersTable[state.category].find((list) => list.query === key);
        if (tableList) {
          if (!Array.isArray(list) && list.hasOwnProperty("meta")) {
            const topList = list as ITickerData;
            tableList.values = topList.values;
            tableList.meta = topList.meta;
          }
          if (Array.isArray(list)) {
            const topList = mapTopList(list as IExpenseRatioItem[]);
            tableList.meta = tableList.meta ? tableList.meta : null;
            if (
              list.length &&
              (list[0].hasOwnProperty("expense_ratio") || list[0].hasOwnProperty("divident_yield"))
            ) {
              tableList.values = topList.values;
            } else {
              tableList.values = [];
            }
          }
        }
        state.requestCount--;
      })
      .addCase(fetchTopTickersList.rejected, (state, { meta, payload }) => {
        const tickersList = state.tickersTable[state.category].find(
          (table) => table.query === meta.arg.query
        );
        if (tickersList) tickersList.error = "error";

        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus))
        notification.error("Error while getting etf top list data!");
        state.requestCount--;
      });

    builder
      .addCase(fetchETFTableData.pending, (state) => {
        state.requestCount++;
      })
      .addCase(fetchETFTableData.fulfilled, (state, { payload }) => {
        state.etfTable[state.category].table = payload;
        state.requestCount--;
      })
      .addCase(fetchETFTableData.rejected, (state, { payload }) => {
        state.etfTable[state.category].error = "error";
        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus))
        notification.error("Error while getting etf table data!");
        state.requestCount--;
      });

    builder
      .addCase(fetchChartData.pending, (state) => {
        state.requestCount++;
      })
      .addCase(fetchChartData.fulfilled, (state, { payload: { data, id } }) => {
        const chartItem = state.charts[state.category].find((chart) => chart.meta.id === id);
        if (chartItem) chartItem.data = data;

        state.requestCount--;
      })
      .addCase(fetchChartData.rejected, (state, { payload, meta }) => {
        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus)) {
        const chartItem = state.charts[state.category].find(
          (chart) => chart.meta.id === meta.arg.id
        );
        if (chartItem) chartItem.error = "error";
        notification.error("Error while getting etf table data!");
        // }
        state.requestCount--;
      });

    builder
      .addCase(fetchPieChartData.pending, (state) => {
        state.requestCount++;
      })
      .addCase(fetchPieChartData.fulfilled, (state, { payload: { data, key } }) => {
        const pieChart = state.pieCharts[state.category].find((chart) => chart.key === key);
        if (pieChart) {
          pieChart.data = data;
        }
        state.requestCount--;
      })
      .addCase(fetchPieChartData.rejected, (state, action) => {
        const { meta, payload } = action;
        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus)) {
        notification.error("Error while getting pie chart data!");
        const pieChart = state.pieCharts[state.category].find(
          (pieChart) => pieChart.key === meta.arg.key
        );
        if (pieChart) pieChart.error = "error";
        // }
        state.requestCount--;
      });

    builder
      .addCase(fetchNewETFTableData.pending, (state) => {
        state.requestCount++;
      })
      .addCase(fetchNewETFTableData.fulfilled, (state, { payload }) => {
        state.newEtfTable[state.category].table = payload;
        state.requestCount--;
      })
      .addCase(fetchNewETFTableData.rejected, (state, { payload }) => {
        state.newEtfTable[state.category].error = "error";
        // if (checkHTTPError.isSomeError(payload as AxiosRejectedStatus))
        notification.error("Error while getting etf table data!");
        state.requestCount--;
      });
  },
});

const state = (state: RootState) => state;

export const etfIntelligenceState = createSelector(state, (state) => state.forecastEtfIntelligence);

export const getChartData =
  (id: number, category: Categories) =>
  (state: RootState): IEtfCHartData | null =>
    state.forecastEtfIntelligence.charts[category].find((chart) => chart.meta.id === id) || null;

export const { setLoading, initialize, makeRequest } = forecastEtfSlice.actions;

export default forecastEtfSlice.reducer;
