import { useEffect, useMemo, useState } from "react";

import { debounce } from "@/utils";

const SHOW_UNREADED_DELAY = 500; // milliseconds

export const useUnrededCounter = (unreaded: string[] | null) => {
  const [counter, setCounter] = useState(0);

  const debounced = useMemo(
    () =>
      debounce((len?: number) => {
        setCounter(len || 0);
      }, SHOW_UNREADED_DELAY),
    []
  );

  useEffect(() => {
    debounced(unreaded?.length);
  }, [unreaded, debounced]);

  if (counter === 0) return null;
  return counter > 99 ? "+99" : counter.toString();
};
