import { FC } from "react";

import { ContentLayout, FooterLayout } from "@/components";
import { CompanyManagement } from "@/features";

export const CompanyManagementPage: FC = () => {
  return (
    <FooterLayout>
      <ContentLayout>
        <CompanyManagement />
      </ContentLayout>
    </FooterLayout>
  );
};
