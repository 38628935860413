import {
  ChangeEvent,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Button,
  ChartHomePage,
  InputItem,
  ITabItem,
  LoaderWrapper,
  ModalLayout,
  Tab,
} from "@/components";
import { Heading } from "@/components/Heading/Heading";
import ReloadIcon from "@/images/reload.svg";
import { IDaysRange, IMarketDrivers, IMatchingRank, IMatchingRankTableData } from "@/types";
import { getSeriesDataCategory } from "@/utils";

import { MarketDriversTable } from "../MarketDriversTable/MarketDriversTable";

import styles from "./DetailsViewModal.module.scss";

interface IProps {
  selected: IMatchingRankTableData | null;
  setSelected: Dispatch<SetStateAction<IMatchingRankTableData | null>>;
  data: IMatchingRank | null;
  setDaysRange: Dispatch<SetStateAction<IDaysRange | null>>;
  onRefrash: () => void;
  isLoading: boolean;
  daysRange: IDaysRange | null;
  resetDaysRange: () => void;
}

type ITabType = "Market Action" | "Market Drivers";

export const DetailsViewModal: FC<IProps> = memo(
  ({
    selected,
    setSelected,
    data,
    setDaysRange,
    onRefrash,
    isLoading,
    daysRange,
    resetDaysRange,
  }) => {
    const [activeTab, setActiveTab] = useState<ITabType>("Market Action");

    const chartData = useMemo(() => {
      return selected?.Date && data?.graphs[selected.Date]
        ? (data.graphs[selected.Date] as IMarketDrivers[] | null)
        : null;
    }, [data?.graphs, selected?.Date]);

    const handleChange = useCallback(
      (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setDaysRange((state) => ({
          ...state,
          [name]: e.target.value,
        }));
      },
      [setDaysRange]
    );

    const tableData = useMemo(
      () =>
        selected?.Date
          ? data?.drivers
              .filter((item) => selected?.Date === item.Date)
              ?.map((i) => ({ ...i, Definition: i.Definition || "-" }))
          : [],
      [data?.drivers, selected?.Date]
    );

    const disabled =
      !(daysRange?.days_after || daysRange?.days_before) ||
      Number.isNaN(Number(daysRange?.days_after || "")) ||
      Number.isNaN(Number(daysRange?.days_before || "")) ||
      (Number(daysRange?.days_before) === 0 && Number(daysRange?.days_after) === 0);

    const tabsList: ITabItem<ITabType>[] = [
      {
        key: "Market Action",
        title: `Market Action`,
        component: (
          <div className={styles.markeActionTab}>
            <div className={styles.daysSelect}>
              <InputItem
                onChange={handleChange("days_before")}
                label="Days Before"
                name="days_before"
                type="number"
                symbol=""
                placeholder="number"
                value={daysRange?.days_before?.toString() || ""}
                max={90}
                min={0}
                isInteger
              />
              <InputItem
                onChange={handleChange("days_after")}
                label="Days After"
                name="days_after"
                type="number"
                symbol=""
                placeholder="number"
                value={daysRange?.days_after?.toString() || ""}
                max={90}
                min={0}
                isInteger
              />
              <div className={styles.button}>
                <Button
                  disabled={isLoading || disabled}
                  icon={ReloadIcon}
                  text="Refresh"
                  onClick={onRefrash}
                />
              </div>
              <div className={styles.button}>
                {!!daysRange && (
                  <Button
                    disabled={isLoading}
                    text="Reset"
                    onClick={resetDaysRange}
                    danger
                    type="gost"
                  />
                )}
              </div>
            </div>
            <ChartData chartData={chartData} isLoading={isLoading} />
          </div>
        ),
      },
      {
        key: "Market Drivers",
        title: "Market Drivers",
        component: <MarketDriversTable data={tableData || null} />,
      },
    ];

    useEffect(() => {
      setActiveTab("Market Action");
    }, []);

    const changeTabHandler = (key: string) => {
      setActiveTab(key as ITabType);
    };

    const closeModal = () => {
      setSelected(null);
      setActiveTab("Market Action");
    };

    return (
      <ModalLayout show={!!selected} onClose={closeModal}>
        <div className={styles.container}>
          <div className={styles.close} onClick={closeModal}></div>
          <div className={styles.head}>
            <Heading type="h3">{`Matching Rank #${selected?.Rank}: ${selected?.Date}`}</Heading>
          </div>
          <Tab<ITabType>
            tabs={tabsList}
            selected={activeTab}
            onChange={changeTabHandler}
            helpLoadingText="Loading chart data..."
          />
        </div>
      </ModalLayout>
    );
  }
);

const ChartData = memo((props: any) => {
  const { chartData, isLoading } = props;

  const data = useMemo(() => {
    const series = getSeriesDataCategory(chartData, {
      defaultSelected: false,
      isReversed: false,
      isHideEmpty: true,
      isSorted: true,
    }).data;

    return series;
  }, [chartData]);

  return (
    <LoaderWrapper loading={isLoading} fullWidth>
      <ChartHomePage
        height={300}
        data={data}
        isMultyAxes={false}
        title="Market Change"
        isFullDate
      />
    </LoaderWrapper>
  );
});
