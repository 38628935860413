import { AxiosResponse } from "axios";

import axios from "@/services/axios.config";
import {
  IDaysRange,
  IRangeSelected,
  ITicker,
  ITickerCommodities,
  ITickerExRates,
  ITickerMarketYeld,
} from "@/types";

export const HomeServices = {
  getHomePageData: (query: string, params: IDaysRange | IRangeSelected | null) =>
    axios.get(`/home/${query}`, params ? { params } : undefined),
  postHomePageData: (query: string, params: IDaysRange | IRangeSelected | null) =>
    axios.post(`/home/${query}`, params ? { ...params } : undefined),

  getTickers: (): Promise<AxiosResponse<{ result: ITicker[] }>> => axios.get("/home/indices"),
  getMarketYeldTickers: (): Promise<AxiosResponse<{ result: ITickerMarketYeld[] }>> =>
    axios.get("/home/market-yield"),
  getCommoditiesTickers: (): Promise<AxiosResponse<{ result: ITickerCommodities[] }>> =>
    axios.get("/home/commodities"),
  getExRatesTickers: (): Promise<AxiosResponse<{ result: ITickerExRates[] }>> =>
    axios.get("/home/exchange-rates"),
};
