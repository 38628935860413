import { FC, useMemo } from "react";

import { useAppSelector } from "@/hooks";
import { etfIntelligenceState } from "@/store/forecast/forecastEtfIntelligence";

import { EtfChart } from "../EtfChart/EtfChart";

import styles from "./ChartsList.module.scss";

export const ChartsList: FC = () => {
  const { management, charts, category } = useAppSelector(etfIntelligenceState);
  const list = charts[category];

  const slisedList = useMemo(() => {
    return list.filter((chart) => {
      const urlStr = chart.meta.url;
      if (
        management === "active" &&
        (urlStr?.includes("chart=cif") || urlStr?.includes("chart=aff"))
      )
        return false;
      if (
        management === "passive" &&
        (urlStr?.includes("chart=cif") || urlStr?.includes("chart=acf"))
      )
        return false;
      return true;
    });
  }, [list, management]);

  return (
    <div className={styles.defaultChartContainer}>
      {slisedList.map((chart) => (
        <EtfChart key={chart.meta.name} chartData={chart} />
      ))}
    </div>
  );
};
