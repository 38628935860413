import { useEffect, useMemo, useRef, useState } from "react";

const FIRST_RENDER_DELAY = 10;

export const useInViewportElement = (
  initialValue: boolean,
  { root = null, threshold = 0, rootMargin = "0px" }: IntersectionObserverInit = {}
): [boolean, React.MutableRefObject<HTMLDivElement | null>] => {
  const [isIntersecting, setIntersecting] = useState(initialValue);
  const itemRef = useRef<HTMLDivElement | null>(null);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        root,
        threshold,
        rootMargin,
      }
    );
  }, []);

  useEffect(() => {
    const element = itemRef.current as Element;

    setTimeout(() => observer.observe(element), FIRST_RENDER_DELAY);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [isIntersecting, itemRef];
};
