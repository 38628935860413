import dayjs from "dayjs";
import { FC } from "react";

import { SmallChart } from "@/components";
import { DATE_ISO_US } from "@/constants";
import { ICommonStrategyData, StrategyTypeEnum } from "@/types";
import { getSeriesData } from "@/utils";

import styles from "./CommonStrategyItem.module.scss";

import { ReactComponent as StrategyIcon } from "@images/strategy.svg";

const DEFAULT_ITEMS_COUNT = 365;

interface IProps {
  item: ICommonStrategyData;
  onSelect: (item: ICommonStrategyData) => void;
}

export const CommonStrategyItem: FC<IProps> = ({ item, onSelect }) => {
  const { equity_line, id, strategy_type, name, description, type, updated_at } = item;
  const cutObjectFields = (obj: Record<string, number> | null) => {
    const result: Record<string, number> = {};
    if (obj === null) return result;

    const keys = Object.keys(obj);
    const lastFiveKeys = keys.slice(-DEFAULT_ITEMS_COUNT);
    lastFiveKeys.forEach((key) => {
      result[key] = obj[key];
    });
    return result;
  };
  const chart = getSeriesData({ Equity: cutObjectFields(equity_line || null) }, false);

  const color = type?.includes(StrategyTypeEnum.STRATEGY_OVERLAY)
    ? "var(--secondary)"
    : "var(--primary)";

  return (
    <div className={styles.container} onClick={() => onSelect(item)} data-id={id}>
      <div className={styles.rowtitle}>
        <div className={styles.left}>
          <span>Name:</span>
          <span>{name}</span>
        </div>
        <div className={styles.right}>
          <span>Type:</span>
          <span>{strategy_type}</span>
        </div>
      </div>

      <div className={styles.rowBlocks}>
        <div className={styles.left} style={{ color }}>
          <StrategyIcon />
          <p className={styles.textFooter}>
            <span>ID:</span>
            <span>{id}</span>
          </p>
        </div>
        <div className={styles.right}>
          <div className={styles.chartWrapper}>
            <SmallChart data={chart.data} />
          </div>

          <div className={styles.infoWrapper}>
            <p>
              Description: <span>{description}</span>
            </p>
            <p>
              Updated: <span>{dayjs(updated_at).format(DATE_ISO_US)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
