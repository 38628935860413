import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { FooterLayout } from "@/components";
import { UserStatusDetails } from "@/features";
import { UsersManagement } from "@/features/UsersManagement/UsersManagement";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchAllUsers, usersState } from "@/store/users";

export const UsersManagementMain: FC = () => {
  const { users } = useAppSelector(usersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (users.length) return;
    dispatch(fetchAllUsers());
  }, [users.length, dispatch]);

  return (
    <FooterLayout>
      <Routes>
        <Route path="/" element={<UsersManagement />} />
        <Route path="/:userId" element={<UserStatusDetails />} />
      </Routes>
    </FooterLayout>
  );
};
