import { TIME_SERIES_TYPE } from "@/types";

export const OPTIONS = [
  {
    label: TIME_SERIES_TYPE.DEFAULT,
    value: "Default View",
  },
  {
    label: TIME_SERIES_TYPE.TIME_SERIES,
    value: "Series View",
  },
];

export const FILTER_OPERATORS = {
  eq: { id: "eq", name: "=" },
  lt: { id: "lt", name: "<" },
  lte: { id: "lte", name: "<=" },
  gt: { id: "gt", name: ">" },
  gte: { id: "gte", name: ">=" },
  ne: { id: "ne", name: "!=" },
  and: { id: "and", name: "&" },
  starts_with: { id: "starts_with", name: "Starts with" },
  ends_with: { id: "ends_with", name: "Ends with" },
  contains: { id: "contains", name: "Like" },
};

export const DEFAULT_AGG = {
  id: "raw",
  name: "Raw",
};

export const QUERY_TYPES = {
  [DEFAULT_AGG.id]: DEFAULT_AGG.id,
  aggregation: "aggregation",
};

export const METRIC_AGGS = [
  { id: DEFAULT_AGG.id, name: DEFAULT_AGG.name, types: ["int", "float", "Decimal", "str"] },
  { id: "avg", name: "Average", types: ["int", "float", "Decimal"] },
  { id: "count", name: "Count", types: ["int", "float", "Decimal"] },
  { id: "max", name: "Maximum", types: ["int", "float", "Decimal"] },
  { id: "min", name: "Minimum", types: ["int", "float", "Decimal"] },
  { id: "sum", name: "Sum", types: ["int", "float", "Decimal"] },
  { id: "variance", name: "Variance", types: ["int", "float", "Decimal"] },
  { id: "std", name: "Standard deviation", types: ["int", "float", "Decimal"] },
];

export const DEFAULT_DATE_LEVEL = {
  id: "year",
  name: "Year",
};

export const DATE_LEVEL_OPTIONS = [
  { id: DEFAULT_DATE_LEVEL.id, name: DEFAULT_DATE_LEVEL.name },
  { id: "quarter", name: "Quarter Mar" },
  { id: "quarter_feb", name: "Quarter Apr" },
  { id: "quarter_mar", name: "Quarter May" },
  { id: "month", name: "Month" },
  { id: "week", name: "Week Mon" },
  { id: "week_tue", name: "Week Tue" },
  { id: "week_wed", name: "Week Wed" },
  { id: "week_thu", name: "Week Thu" },
  { id: "week_fri", name: "Week Fri" },
  { id: "week_sat", name: "Week Sat" },
  { id: "week_sun", name: "Week Sun" },
  { id: "day", name: "Day" },
];

export const GRANULARITY_TO_DATE_LEVEL_MAP = {
  daily: "day",
  weekly: "week",
  monthly: "month",
  quarterly: "quarter",
  yearly: "year",
};

export const LIST_FILTER_OPERATORS = [
  { value: FILTER_OPERATORS.eq.name, key: FILTER_OPERATORS.eq.id },
  { value: FILTER_OPERATORS.lt.name, key: FILTER_OPERATORS.lt.id },
  { value: FILTER_OPERATORS.lte.name, key: FILTER_OPERATORS.lte.id },
  { value: FILTER_OPERATORS.gt.name, key: FILTER_OPERATORS.gt.id },
  { value: FILTER_OPERATORS.gte.name, key: FILTER_OPERATORS.gte.id },
  { value: FILTER_OPERATORS.ne.name, key: FILTER_OPERATORS.ne.id },
];

export const DIMENSIONS_FILTER_OPERATORS = [
  { value: FILTER_OPERATORS.eq.name, key: FILTER_OPERATORS.eq.id },
  { value: FILTER_OPERATORS.ne.name, key: FILTER_OPERATORS.ne.id },
  // { value: FILTER_OPERATORS.contains.name, key: FILTER_OPERATORS.contains.id },
  // { value: FILTER_OPERATORS.starts_with.name, key: FILTER_OPERATORS.starts_with.id },
  // { value: FILTER_OPERATORS.ends_with.name, key: FILTER_OPERATORS.ends_with.id },
];

export const DEFAULT_FORMAT_OPTONS: Intl.NumberFormatOptions = {
  style: "decimal",
  compactDisplay: "short",
  notation: "compact",
};

export const repalceList = [
  {
    key: "globaleconomy",
    replace: "Global Economy",
  },
  {
    key: "globalemployment",
    replace: "Global Employment",
  },
];

export const regex = /\[([^\]]+)\]/;
