import defaultAxios, { AxiosResponse } from "axios";

import {
  IUserLogin,
  IUserResetPassword,
  IUserInvite,
  IUserRegistrating,
  IUser,
  IUserDemoRequest,
  IUserModifyAccessPayload,
  IUserLearnMoreRequest,
} from "@/types";

import axios, { ITokens } from "./axios.config";

const BDF_SUBSCRIPTION_URL = "https://n2pxhso7m6dqc1is.bigdatafed.com/v1/bdf-website-server/api";
const API_UNSUBSCRIPTION_URL = "https://mx.bigdatafed.com/api";

const BASIC_AUTH_PASSWORD = "P57Ksi1UzjivnYF6C2VNzeXfh69WG2F";
const BASIC_AUTH_USERNAME = "bdf";

export const UserService = {
  userLogin: (user: IUserLogin): Promise<AxiosResponse<ITokens>> =>
    axios.post("/auth/refresh-token", user),
  userInvite: (params: IUserInvite): Promise<AxiosResponse<any>> =>
    axios.post("/users/invite", params),
  sendEmail: (email: string): Promise<AxiosResponse> =>
    axios.post("/users/reset-password-request", { email }),
  resetPassword: (resetData: IUserResetPassword) => axios.post("/users/reset-password", resetData),
  registratingUser: (registrateData: IUserRegistrating) =>
    axios.post("/users/register", registrateData),
  getUsers: async (): Promise<AxiosResponse<IUser[]>> => axios.get("/users"),
  modifyAccess: async (id: number, data: IUserModifyAccessPayload): Promise<AxiosResponse<IUser>> =>
    axios.post(`/users/${id}/modify-access`, data),
  demoRequest: (userData: IUserDemoRequest): Promise<AxiosResponse<{ result: string | null }>> =>
    axios.post("/contact-us", userData),
  learnMoreRequest: (
    userData: IUserLearnMoreRequest,
    docId: string
  ): Promise<AxiosResponse<Blob>> =>
    axios.post(`/admin/documents/${docId}/download-public`, userData, { responseType: "blob" }),
  subscribeRequest: (data: { email: string }): Promise<AxiosResponse<string>> =>
    defaultAxios.post(`${BDF_SUBSCRIPTION_URL}/subscribe`, data),
  unsubscribeRequest: (email: string): Promise<AxiosResponse<string>> =>
    defaultAxios.patch(
      `${API_UNSUBSCRIPTION_URL}/unsub-from-subs`,
      { email },
      {
        headers: {
          Authorization: "Basic " + window.btoa(`${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`),
        },
      }
    ),
};
