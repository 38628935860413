import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { createCompanies, editCompanies } from "@/store/companies";
import { CompanyForm, ICompany, ICompanyPayload } from "@/types/company";
import { validate } from "@/utils";

type FieldsType = Record<keyof CompanyForm, { value: string; error: string | null }>;

const createFields = (): FieldsType => ({
  name: {
    value: "",
    error: null,
  },
  aum: {
    value: "",
    error: null,
  },
  strategy: {
    value: "",
    error: null,
  },
  type: {
    value: "",
    error: null,
  },
  location: {
    value: "",
    error: null,
  },
  comment: {
    value: "",
    error: null,
  },
});

export const useCompanyForm = (
  selected: ICompany | null,
  close: () => void,
  showModal: boolean
) => {
  const [form, setForm] = useState(createFields);
  const [isActive, setActive] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof FieldsType;
    const value = e.target.value;
    setForm((prev) => ({
      ...prev,
      [fieldName]: {
        value,
        error: value ? null : prev[fieldName].error,
      },
    }));
  };

  const handleSubmitForm = () => {
    const data = Object.keys(form).reduce((acc, key) => {
      const fieldKey = key as keyof CompanyForm;
      acc[fieldKey] = form[fieldKey].value;
      return acc;
    }, {} as ICompanyPayload);

    const [validatedForm, isValid] = validate(form);

    if (isValid) {
      if (selected) {
        data.is_active = isActive;
        dispatch(editCompanies({ id: selected.id, data }));
      } else {
        delete data.is_active;
        dispatch(createCompanies(data));
      }
      close();
    } else setForm(validatedForm as FieldsType);
  };

  useEffect(() => {
    if (selected) {
      const fields = createFields();
      const updatedFields = Object.keys(fields).reduce((acc, key) => {
        const fieldKey = key as keyof CompanyForm;
        acc[fieldKey] = {
          ...fields[fieldKey],
          value: selected[fieldKey]?.toString() || "",
        };

        return acc;
      }, {} as Record<keyof CompanyForm, { value: string; error: string | null }>);
      setForm(updatedFields);
      setActive(selected.is_active);
    }
  }, [selected]);

  useLayoutEffect(() => {
    if (showModal && !selected) {
      setForm(createFields());
    }
  }, [showModal, selected]);

  return {
    handleInputChange,
    handleSubmitForm,
    setActive,

    form,
    isActive,
  };
};
