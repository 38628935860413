export const PDFfontSize = {
  default: 14,
  main: 16,
  large: 20,
  small: 12,
};

export const PDFColor = {
  primary: "#093B79",
  secondary: "#008DDD",
  black30: "#b9b9b9",
  black50: "#8b8b8b",
  black: "#171717",
  header: "#D5E7F1",
};
