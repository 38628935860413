import { FC, Fragment } from "react";

import { ReactComponent as Empty } from "@/images/empty.svg";

import styles from "./NoData.module.css";

interface IProps {
  show: boolean;
  children: React.ReactNode;
}

export const NoData: FC<IProps> = ({ show, children }) => {
  if (!show) return <Fragment>{children}</Fragment>;
  return (
    <div className={styles.noDataContainer}>
      <div className={styles.noDataIconWrapper}>
        <Empty />
      </div>
      <p>No Data yet.</p>
    </div>
  );
};
