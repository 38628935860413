export const ROUTES = {
  root: {
    path: "/",
  },
  home: {
    path: "/home",
    key: "Home",
  },
  marketView: {
    path: "/market-view",
  },
  login: {
    path: "/login",
  },
  demoRequest: {
    path: "/demo-request",
  },
  forgotPassword: {
    path: "/forgot-password",
  },
  resetPassword: {
    path: "/reset-password",
  },
  invite: {
    path: "/invite-user",
  },
  registrate: {
    path: "/register-user",
  },
  UserProfile: {
    path: "/user-profile",
    key: "User Profile",
  },
  AdminPanel: {
    path: "/admin-panel",
    key: "Admin Panel",
  },
  userData: {
    path: "/user-data",
    key: "My Data",
  },
  userDataDetails: {
    path: "/user-data/details",
    key: "My Data/Details",
  },
  modelingRoot: {
    path: "/modeling",
    key: "Modeling",
  },
  modeling: {
    path: "/portfolio-builder",
    key: "Portfolio Builder",
  },
  modelingPortfolioBuilder: {
    path: "/modeling/portfolio-builder",
    key: "Portfolio Builder",
  },
  // for portfolios
  portfolioBuilderRoot: {
    path: "/portfolio-builder/multi-strategy",
    key: "Portfolio Builder",
  },
  portfolioBuilder: {
    path: "/modeling/portfolio-builder/multi-strategy",
    key: "Portfolio Builder/Multi Strategy",
  },
  portfolioPreview: {
    path: "/modeling/portfolio-builder/multi-strategy/preview",
    key: "Portfolio Builder/Multi Strategy",
  },
  portfolioEdit: {
    path: "/modeling/portfolio-builder/multi-strategy/edit",
    key: "Portfolio Builder/Multi Strategy",
  },
  portfolioCreate: {
    path: "/modeling/portfolio-builder/multi-strategy/new",
    key: "Portfolio Builder/Multi Strategy",
  },
  portfolioPermissions: {
    path: "/modeling/portfolio-builder/multi-strategy/permissions",
    key: "Portfolio Builder/Multi Strategy",
  },
  allocation: {
    path: "/portfolio-builder/allocation",
    key: "Portfolio Builder/Allocation",
  },

  // for strategies
  strategiesBuilderRoot: {
    path: "/portfolio-builder/single-strategy",
    key: "Portfolio Builder/Single Strategy",
  },
  strategiesBuilder: {
    path: "/modeling/portfolio-builder/single-strategy",
    key: "Portfolio Builder/Single Strategy",
  },
  strategiesPreview: {
    path: "/modeling/portfolio-builder/single-strategy/preview",
    key: "Portfolio Builder/Single Strategy",
  },
  strategiesEdit: {
    path: "/modeling/strategies/builder/edit",
    key: "Portfolio Builder/Single Strategy",
  },
  strategyCreate: {
    path: "/modeling/portfolio-builder/single-strategy/new",
    key: "Portfolio Builder/Single Strategy",
  },
  strategiesCustomIndicator: {
    path: "/modeling/portfolio-builder/single-strategy/custom-indicator",
    key: "Portfolio Builder/Single Strategy/Custom Indicator",
  },

  //Comapre
  compareStrategies: {
    path: "/portfolio-builder/compare",
    key: "Portfolio Builder/Compare",
  },

  reporting: {
    path: "/reporting",
    key: "Reporting",
  },
  eventsCalendar: {
    path: "/data/events-calendar",
    key: "Events Calendar",
  },
  ETFIntelligence: {
    path: "/data/etf-intelligence",
    key: "ETF Intelligence",
  },
  focus: {
    path: "/data/focus",
    key: "Focus",
  },
  catalog: {
    path: "/data/catalog",
    key: "Data",
  },
  dataDrsDetails: {
    path: "data/details/:drsId",
    key: "Data/Details",
  },

  // forecasts
  forecastEquities: {
    path: "/forecasts/companies",
    key: "Forecasts/Companies",
  },
  forecastMacro: {
    path: "/forecasts/macro",
    key: "Forecasts/Macro",
  },
  forecastSectors: {
    path: "/forecasts/sectors",
    key: "Forecasts/Sectors",
  },
  forecastIndustries: {
    path: "/forecasts/industries",
    key: "Forecasts/Industries",
  },
  odinchat: {
    path: "/odinchat",
    key: "Odin Chat",
  },

  // Macro planner
  macroPlanner: {
    path: "/modeling/macro-planner",
    key: "Macro Planner",
  },

  // Resources
  resources: {
    path: "/resources",
    key: "Resources",
  },
  // terms & conditions
  disclosures: {
    path: "/disclosures",
    key: "Disclosures",
  },
  privacyPolicy: {
    path: "/privacy-policy",
    key: "Privacy Policy",
  },
  companyManagement: {
    path: "/company-management",
    key: "Company Management",
  },
  usersManagement: {
    path: "/users-management",
    key: "Users Management",
  },
  insights: {
    path: "/insights",
    key: "Insights",
  },
};

export const PORTFOLIO_ROUTES = {
  portfolioPreview: "preview/:portfolioId",
  portfolioEdit: "edit/:portfolioId",
  portfolioCreate: "new",
  portfolioPermissions: "permissions/:portfolioId",
  default: "/portfolio-builder/multi-strategy",
};

export const STRATEGIES_ROUTES = {
  strategyPreview: "preview/:strategyId",
  strategyEdit: "edit/:strategyId",
  strategyCreate: "new",
  customIndicator: "custom-indicator",
  customIndicatorEdit: "custom-indicator/:indicatorId",
  default: "/portfolio-builder/single-strategy",
};

export const DATA_PORTAL_ROUTES = {
  eventsCalendar: "/data-portal/events-calendar",
  discover: "/data-portal/discover",
  discoverDrsDetails: "details/:drsId",
};

export const SCROLL_TO_ID = "scroll_to_id";
export const PREVENT_SCROLL = "prevent_scroll";
