import { useEffect, useMemo, useRef, useState } from "react";

import { PRESENTATION_KEY } from "@/constants";
import { useMediaQuery } from "@/hooks/useMediaQuery";

const OVER_TIME = 10 * 1000; // 10sec over time for stop presentation
const ANIMATION_DELAY_TIME = 1 * 1000; // animation delay 1s
const START_VIDEO_DELAY_TIME = 1 * 1000; // start video delay 2s
const START_POSTER_DELAY = 500;
const POSTER_SHOW_DELAY = 3 * 1000;

export const usePresentation = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [startAnimation, setStartAnimation] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [documentReady, setDocumentReady] = useState({
    canPlay: false,
    hasIteractions: false,
    canClose: false,
    error: false,
  });

  const isMobileDevice = useMediaQuery("(max-width: 991px)");

  const playVideoHandler = () => {
    setDocumentReady((prev) => ({ ...prev, canPlay: true }));
    setLoading(false);
  };

  const closeWelcomeWindowHandler = () => {
    setShowWelcome(false);
  };

  const onError = () => {
    console.warn("Error occurred during play Video...");
    setDocumentReady((prev) => ({ ...prev, canClose: true, error: true }));
    localStorage.setItem(PRESENTATION_KEY, "presented");
  };

  const isPresentationShowed = useMemo(() => {
    return localStorage.getItem(PRESENTATION_KEY) as "presented" | null;
  }, []);

  const manuallyPlayHandler = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      videoRef.current.play().catch(() => {
        //
      });
    }
  };

  useEffect(() => {
    const clickDocumentHandler = () => {
      setDocumentReady((prev) => ({ ...prev }));
    };

    const startVideo = () => {
      if (videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current
          .play()
          .then(() => {
            videoRef.current?.pause();
            if (videoRef.current) videoRef.current.currentTime = 0;
            setDocumentReady((prev) => ({ ...prev, hasIteractions: true }));
            setTimeout(() => {
              videoRef.current?.play();
              setTimeout(() => {
                setDocumentReady((prev) => ({ ...prev, canClose: true }));
                localStorage.setItem(PRESENTATION_KEY, "presented");
              }, OVER_TIME);
            }, START_VIDEO_DELAY_TIME);
          })
          .catch(() => {
            window.addEventListener("click", clickDocumentHandler);
          });
      }
    };

    if (documentReady.canPlay && !documentReady.hasIteractions && startAnimation.third)
      startVideo();

    return () => {
      window.removeEventListener("click", clickDocumentHandler);
    };
  }, [documentReady, startAnimation]);

  useEffect(() => {
    setTimeout(() => {
      setStartAnimation((prev) => ({ ...prev, first: true }));
      setTimeout(() => {
        setStartAnimation((prev) => ({ ...prev, second: true }));
        setTimeout(() => {
          setStartAnimation((prev) => ({ ...prev, third: true, second: false }));
        }, POSTER_SHOW_DELAY);
      }, START_POSTER_DELAY);
    }, ANIMATION_DELAY_TIME);
  }, []);

  return {
    onError,
    playVideoHandler,
    manuallyPlayHandler,
    closeWelcomeWindowHandler,

    isPresentationShowed,
    startAnimation,
    isMobileDevice,
    documentReady,
    showWelcome,
    loading,
    videoRef,
  };
};
