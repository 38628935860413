import { FC, useMemo } from "react";

import { LoaderWrapperCustom } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as TrendIcon } from "@/images/chart-histogram-two-filled.svg";
import { ReactComponent as TableIcon } from "@/images/table-icon.svg";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { discoverQueryDataState, resetSelected } from "@/store/discover/queryData";
import { IFilterCriteria, TIME_SERIES_TYPE } from "@/types";

import styles from "./TimeSeriesFormatSelect.module.scss";

interface IProps {
  setSelectedType: (item: TIME_SERIES_TYPE) => void;
  selected: TIME_SERIES_TYPE;
  setCurrentPage: (current: number) => void;
  filterCriteria: IFilterCriteria | undefined;
}

export const TimeSeriesFormatSelect: FC<IProps> = ({
  setSelectedType,
  selected,
  filterCriteria,
}) => {
  const dispatch = useAppDispatch();
  const { drsMeta } = useAppSelector(discoverDrsMetaState);
  const { isLoading } = useAppSelector(discoverQueryDataState);

  const hasTimeSeries = useMemo(
    () =>
      1 ===
        (filterCriteria?.fields?.filter((field) =>
          drsMeta?.format?.time_dimensions.some((timeDimension) => field === timeDimension)
        ).length || drsMeta?.format?.time_dimensions.length) && !!drsMeta?.format?.metrics.length,
    [drsMeta?.format?.metrics.length, drsMeta?.format?.time_dimensions, filterCriteria?.fields]
  );

  const handleSelect = (item: TIME_SERIES_TYPE) => {
    setSelectedType(item);
    dispatch(resetSelected());
  };

  return (
    <LoaderWrapperCustom loading={isLoading} transparent>
      <div className={styles.radioButtons}>
        <label className={styles.radioButtonLabel}>
          <input
            type="radio"
            value={TIME_SERIES_TYPE.DEFAULT}
            checked={selected === TIME_SERIES_TYPE.DEFAULT}
            onChange={() => handleSelect(TIME_SERIES_TYPE.DEFAULT)}
          />
          <span className={styles.button}>
            <TableIcon />
            <span>Table view</span>
          </span>
        </label>
        <label className={styles.radioButtonLabel}>
          <input
            type="radio"
            value={TIME_SERIES_TYPE.TIME_SERIES}
            checked={selected === TIME_SERIES_TYPE.TIME_SERIES}
            onChange={() => handleSelect(TIME_SERIES_TYPE.TIME_SERIES)}
            disabled={!hasTimeSeries}
          />
          <div className={styles.button}>
            <TrendIcon />
            <span>Chart view</span>
          </div>
        </label>
      </div>
    </LoaderWrapperCustom>
  );
};
