import { ContentLayout, FooterLayout } from "@/components";
import { Resources } from "@/features";

export const ResourcesPage = () => {
  return (
    <FooterLayout showLiveMarketSlider>
      <Resources />
    </FooterLayout>
  );
};
