import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useOuterClick } from "@/hooks";
import { debounce } from "@/utils";

import { ItemType } from "../DropdownSearch";

const DEFAULT_ITEM_WIDTH = 70;
const INPUT_MIN_WIDTH = 120;
const OVERFLOW_ITEM_WIDTH = 80;

interface IuseDropdownMultiSearchProps {
  list: ItemType[];
  selected: ItemType[];
  itemWidth?: number;
  onSelect: (item: ItemType[]) => void;
  onBlur?: () => void;
  transformTextToUpper?: boolean;
}

export const useDropdownMultiSearch = ({
  list,
  selected,
  itemWidth = DEFAULT_ITEM_WIDTH,
  transformTextToUpper = true,
  onSelect,
  onBlur,
}: IuseDropdownMultiSearchProps) => {
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState("");
  const [filteredList, setFilteredList] = useState<ItemType[]>(list);
  const [labelsCount, setLabelsCount] = useState(20);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const prevStateRef = useRef(false);

  const [itemRef] = useOuterClick(setToggle, false);

  const selectHandler = (item: ItemType) => {
    const foundOldItem = selected.find((i) => i.value === item.value);

    if (foundOldItem) {
      onSelect(selected.filter((i) => i.value !== item.value));
    } else {
      const foundItemFromList = list.find((i) => i.key === item.key);
      if (foundItemFromList) onSelect([...selected, item]);
    }
    setValue("");
  };

  const clearHandler = () => {
    onSelect([]);
    setLabelsCount(20);
    setValue("");
  };

  const closeHandler = () => {
    setToggle(false);
  };

  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (transformTextToUpper) setValue(ev.target.value.toUpperCase());
    else setValue(ev.target.value);
  };

  const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    setToggle(true);
    ev.target.select();
    debuncedSearch(value);
  };

  const recalculateSize = () => {
    const containerWidth = inputContainerRef.current?.clientWidth || 0;
    const maxLabelsWidth = containerWidth - INPUT_MIN_WIDTH;

    let width = 16;
    let count = 0;

    selected.forEach(() => {
      if (width + itemWidth + OVERFLOW_ITEM_WIDTH <= maxLabelsWidth) {
        width += itemWidth;
        count++;
      }
    });

    if (selected.length > 0) setLabelsCount(count);
  };

  const isOverflow = useMemo(() => {
    return selected.length > labelsCount;
  }, [selected, labelsCount]);

  const itemsCount = useMemo(() => {
    return selected.length - labelsCount;
  }, [selected, labelsCount]);

  const toggleHandler = () => {
    inputRef.current?.focus();
    setToggle((prev) => !prev);
    if (!toggle) debuncedSearch(value);
  };

  const debuncedSearch = useMemo(() => {
    return debounce((value: string) => {
      if (value) {
        const searchWard = value.trim().split(",").slice(-1).pop() || "";
        const filtered = list.filter((i) =>
          i.value.trim().toLowerCase().includes(searchWard.trim().toLowerCase())
        );

        if (filtered.length) {
          setFilteredList(filtered);
        } else setFilteredList([]);
      } else setFilteredList(list);
    }, 300);
  }, [list]);

  const removeItemHandler = useCallback(
    (value: string) => {
      onSelect(selected.filter((i) => i.value !== value));
    },
    [onSelect, selected]
  );

  useEffect(() => {
    if (document.activeElement === inputRef.current) {
      debuncedSearch(value);
      setToggle(true);
    }
  }, [value, debuncedSearch]);

  // will call this action on change toggle state from open to close
  useEffect(() => {
    if (onBlur && !toggle && prevStateRef.current) {
      onBlur();
    }
    prevStateRef.current = toggle;
  }, [toggle, onBlur]);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  useEffect(() => {
    const windowResizeHandler = (ev: UIEvent) => {
      recalculateSize();
    };

    window.addEventListener("resize", windowResizeHandler);
    recalculateSize();

    return () => {
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, [selected]);

  useEffect(() => {
    const escHandler = (ev: KeyboardEvent) => {
      const lastItem = selected.at(-1)?.value;
      const value = inputRef.current?.value.trim() || "";
      if (ev.code === "Escape") {
        setToggle(false);
        inputRef.current?.blur();
      }
      if (ev.code === "Enter" && inputRef.current) {
        selectHandler({ key: value, value });
        setToggle(false);
        setValue("");
        // inputRef.current?.blur();
      }
      if (ev.code === "Backspace" && lastItem && value === "") {
        removeItemHandler(lastItem);
      }
    };

    inputRef.current?.addEventListener("keydown", escHandler);

    return () => {
      inputRef.current?.removeEventListener("keydown", escHandler);
    };
  }, [list, selected, removeItemHandler, selectHandler]);

  const containerStyle = useMemo(
    () => ({
      "--label-item-width": `${itemWidth}px`,
      "--extended-item-width": `${OVERFLOW_ITEM_WIDTH}px`,
    }),
    [itemWidth]
  );

  return {
    inputContainerRef,
    itemRef,
    inputRef,
    value,
    toggle,
    labelsCount,
    isOverflow,
    itemsCount,
    containerStyle,

    clearHandler,
    selectHandler,
    changeHandler,
    focusHandler,
    filteredList,
    removeItemHandler,
    toggleHandler,
    closeHandler,
  };
};
