import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { Loader } from "@components/Loader/Loader";

import { Button } from "../Button/Button";

import styles from "./EmptyContainer.module.scss";

interface IEmptyContainerProps {
  title: string;
  isEmpty: boolean | null;
  children: React.ReactNode;
  loadingHelpText?: string;
}

export const EmptyContainer: FC<IEmptyContainerProps> = ({
  isEmpty,
  children,
  title,
  loadingHelpText,
}) => {
  const navigate = useNavigate();

  const gobackHandler = () => {
    navigate("..");
  };

  if (isEmpty === null)
    return (
      <div className={styles.loaderContainer}>
        <Loader helpText={loadingHelpText} />
      </div>
    );
  return (
    <Fragment>
      {isEmpty ? (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.innerContainer}>
              <p>{title}</p>
              <div>
                <Button type="secondary" text="Go back" onClick={gobackHandler} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};
