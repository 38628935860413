import { RefObject, useEffect, useMemo, useRef, useState } from "react";

export const useInfiniteScroll = <T extends object>(
  root: RefObject<HTMLDivElement | null>,
  defaultCount: number,
  rows: T[]
) => {
  const [end, setEnd] = useState(defaultCount - 1);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const totalRows = useMemo(() => {
    return rows.slice(0, end + 1);
  }, [rows, end]);

  useEffect(() => {
    let lastelement: HTMLTableSectionElement | null = null;
    if (tableBodyRef.current && rows.length > defaultCount) {
      lastelement = tableBodyRef.current.querySelector(`[data-key="${end}"`);
      if (lastelement) observer?.observe(lastelement);
    }
    return () => {
      if (lastelement) observer?.unobserve(lastelement);
    };
  }, [end, observer, rows, defaultCount]);

  useEffect(() => {
    const options = {
      root: root.current,
      rootMargin: "0px 0px 200px 0px",
      threshold: 0.01,
    };

    const callbackObserevr = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setEnd((prev) => (prev += defaultCount));
        }
      });
    };

    const observer = new IntersectionObserver(callbackObserevr, options);
    setObserver(observer);

    return () => {
      observer.disconnect();
    };
  }, [defaultCount]);

  return {
    listRef: tableBodyRef,
    list: totalRows,
  };
};
