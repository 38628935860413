import { useCallback, useState } from "react";

import { IHistoryData } from "@/types";

const SEPARATOR = ",";

export const useDownloadHistorical = () => {
  const [progress, setProgress] = useState(false);
  const downloadCSV = useCallback((fileName: string | null, data: IHistoryData[]) => {
    const createCSVData = () => {
      try {
        const cols = Object.keys(data[0]) as (keyof IHistoryData)[];
        const getValue = (value?: string | number | null): string => {
          return value !== null && value !== undefined
            ? `${
                Number.isNaN(Number(value))
                  ? value
                  : Number(value) % 1 !== 0
                  ? Number(value).toFixed(5)
                  : Number(value).toString()
              }`
            : "-";
        };

        let result = data
          .map<string>((rowData) => {
            return cols.map((key) => getValue(rowData[key])).join(SEPARATOR);
          })
          .join("\n");

        const header = cols
          .map((i) => i.toLowerCase().replace("aumfraction", "aum_fraction"))
          .join(SEPARATOR);
        if (data.length > 0) result = header + "\n" + result;

        const a = document.createElement("a");
        const blob = new Blob([result], { type: "text/csv" });
        a.href = window.URL.createObjectURL(blob);
        a.download = `${fileName?.trim()}.csv`;

        a.click();
        a.remove();
      } catch {
        console.warn("Error to create CSV!");
      } finally {
        setTimeout(() => setProgress(false), 1000);
      }
    };

    setProgress(true);
    createCSVData();
  }, []);

  return { downloadCSV, progress };
};
