import { FC, SetStateAction, Dispatch, useState, useEffect } from "react";

import { Popover, RadioSelect, RadioOption, Tag } from "@/components";
import { METRIC_AGGS, regex } from "@/constants/discover";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { IAggregators } from "@/types";
import { getMetaConfig, getOriginalName } from "@/utils";

import styles from "./MetricsOptions.module.scss";

interface IProps {
  setAggregators: Dispatch<SetStateAction<IAggregators[]>>;
  metrics: string[];
  setTimeDimensionsOptions: Dispatch<SetStateAction<any>>;
  timeDimensions: string[];
}

export const MetricsOptions: FC<IProps> = ({
  setAggregators,
  metrics,
  timeDimensions,
  setTimeDimensionsOptions,
}) => {
  const [selectedMetric, setSelectedMetric] = useState<string>();
  const [metricList, setMetricList] = useState<string[]>([]);

  const { drsMeta } = useAppSelector(discoverDrsMetaState);

  const handleClick = (name: string) => () => {
    setSelectedMetric(name);
  };

  let aggr: IAggregators[] = [];

  useEffect(() => {
    const convertMetric = metrics.map(
      (metric) => getMetaConfig(metric, drsMeta?.fields)?.label || metric
    );
    setMetricList(convertMetric);
  }, [drsMeta?.fields, metrics]);

  const handleSelectOperator = (agg: string) => () => {
    const metr = metricList.map((metric) => {
      const name = metric.replace(regex, "").trim();
      if (agg === METRIC_AGGS[0].id) {
        aggr = [];
        return name;
      }
      if (selectedMetric === metric) {
        aggr.push({
          field: getOriginalName(name, drsMeta?.fields),
          function: agg,
          label: `${name} [${agg}]`,
        });
        return `${name} [${agg}]`;
      }
      if (!metric.match(regex)?.length) {
        aggr.push({
          field: getOriginalName(name, drsMeta?.fields),
          function: METRIC_AGGS[1].id,
          label: `${name} [${METRIC_AGGS[1].id}]`,
        });
        return `${name} [${METRIC_AGGS[1].id}]`;
      } else {
        aggr.push({
          field: getOriginalName(name, drsMeta?.fields),
          function: metric.match(regex)?.[1] || METRIC_AGGS[1].id,
          label: `${name} [${metric.match(regex)?.[1]}]`,
        });
      }
      return metric;
    });
    setAggregators(aggr);
    if (metr) {
      setMetricList(metr);
    }
  };

  const getFieldMetrics = drsMeta?.fields.find((filed) => selectedMetric?.includes(filed.label));

  const content = (metric: string) => {
    return (
      <div className={styles.content}>
        <RadioSelect>
          {METRIC_AGGS.filter((item) => item.types.includes(getFieldMetrics?.type ?? "")).map(
            (item) => (
              <RadioOption
                key={item.id}
                selected={metric.includes(`[${item.id}]`)}
                onClick={handleSelectOperator(item.id)}
              >
                {item.name}
              </RadioOption>
            )
          )}
        </RadioSelect>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionName}>
        <span>Metrics</span>
      </div>
      {metricList.map((metric) => (
        <Popover key={metric} content={<div>{content(metric)}</div>}>
          <Tag color="primary" onClick={handleClick(metric)}>
            {metric}
          </Tag>
        </Popover>
      ))}
    </div>
  );
};
