import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { paginator } from "@/constants";
import { HomeServices } from "@/services/homeServices";
import { ITicker, ITickerCommodities, ITickerExRates, ITickerMarketYeld } from "@/types";

import { RootState } from ".";

interface IPages {
  strategies: number;
  portfolios: number;
}
interface IPerPages {
  strategies: number;
  portfolios: number;
}

interface IInitialState {
  loading: boolean;
  page: IPages;
  perPage: IPerPages;
  tickers: {
    indices: ITicker[] | null;
    marketYeld: ITickerMarketYeld[] | null;
    commodities: ITickerCommodities[] | null;
    exchangeRates: ITickerExRates[] | null;
  };
}

const initialState: IInitialState = {
  loading: false,
  page: {
    strategies: paginator.page,
    portfolios: paginator.page,
  },
  perPage: {
    strategies: paginator.small,
    portfolios: paginator.small,
  },
  tickers: {
    indices: null,
    marketYeld: null,
    commodities: null,
    exchangeRates: null,
  },
};

export const fetchTickersData = createAsyncThunk("home/fetchTickersData", async () => {
  const { data } = await HomeServices.getTickers();
  return data.result;
});

export const fetchMarketYeldTickersData = createAsyncThunk(
  "home/fetchMarketYeldTickersData",
  async () => {
    const { data } = await HomeServices.getMarketYeldTickers();
    return data.result;
  }
);

export const fetchCommoditiesTickersData = createAsyncThunk(
  "home/fetchCommoditiesTickersData",
  async () => {
    const { data } = await HomeServices.getCommoditiesTickers();
    return data.result;
  }
);

export const fetchExRatesTickersData = createAsyncThunk(
  "home/fetchExRatesTickersData",
  async () => {
    const { data } = await HomeServices.getExRatesTickers();
    return data.result;
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    init: (state) => {
      state.loading = false;
    },
    setHomePage: (
      state,
      { payload }: PayloadAction<{ strategies?: number; portfolios?: number }>
    ) => {
      state.page = { ...state.page, ...payload };
    },
    setHomePerPage: (
      state,
      { payload }: PayloadAction<{ strategies?: number; portfolios?: number }>
    ) => {
      state.perPage = { ...state.perPage, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickersData.fulfilled, (state, { payload }) => {
        if (payload) state.tickers.indices = payload;
      })
      .addCase(fetchTickersData.rejected, (state) => {
        state.tickers.indices = [];
      });

    builder
      .addCase(fetchMarketYeldTickersData.fulfilled, (state, { payload }) => {
        if (payload) state.tickers.marketYeld = payload;
      })
      .addCase(fetchMarketYeldTickersData.rejected, (state) => {
        state.tickers.marketYeld = [];
      });

    builder
      .addCase(fetchCommoditiesTickersData.fulfilled, (state, { payload }) => {
        if (payload) state.tickers.commodities = payload;
      })
      .addCase(fetchCommoditiesTickersData.rejected, (state) => {
        state.tickers.commodities = [];
      });

    builder
      .addCase(fetchExRatesTickersData.fulfilled, (state, { payload }) => {
        if (payload) state.tickers.exchangeRates = payload;
      })
      .addCase(fetchExRatesTickersData.rejected, (state) => {
        state.tickers.exchangeRates = [];
      });
  },
});

const state = (state: RootState) => state;

export const selectHome = createSelector(state, (state) => state.home);

export const selectHomeData = (state: RootState) => state.market;
export const selectTickers = (state: RootState) => state.home.tickers;
export const { init, setHomePage, setHomePerPage } = homeSlice.actions;

export default homeSlice.reducer;
