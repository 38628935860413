import dayjs from "dayjs";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import {
  DateObj,
  DatePickerRange,
  LoaderWrapperCustom,
  PaginationReq,
  TableSort,
} from "@/components";
import { DATE_ISO } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchUserReport, userReportState } from "@/store/report";
import { IUser } from "@/types";

import styles from "./UserActivity.module.scss";

interface IProps {
  selected: IUser | null;
}

interface ISortingObj {
  direction: "desc" | "asc";
  column: number;
}

export const UserActivity: FC<IProps> = memo(({ selected }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [sorting, setSorting] = useState<ISortingObj | null>(null);
  const [dateRange, setDateRange] = useState<DateObj>();
  const { results, isLoading, pages } = useAppSelector(userReportState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selected?.email) return;
    dispatch(
      fetchUserReport({
        email: selected?.email,
        page: currentPage,
        per_page: perPage,
        ...(sorting && {
          ordering_column: sorting.column,
          ordering: sorting.direction,
        }),
        ...(dateRange?.start && { date_from: dayjs(dateRange?.start).format(DATE_ISO) }),
        ...(dateRange?.end && { date_to: dayjs(dateRange?.end).format(DATE_ISO) }),
      })
    );
  }, [currentPage, dispatch, selected, perPage, sorting, dateRange?.start, dateRange?.end]);

  const tableData = useMemo(() => results, [results]);

  const getColumns = Object.keys(results?.[0] || {});

  const getColumnsTable = useMemo(
    () =>
      getColumns?.map((key, i) => {
        return {
          Header: key,
          accessor: key,
          canSort: true,
          isTruncated: false,
          minWidth: i === 0 ? 200 : 100,
        };
      }),
    [getColumns]
  );

  const selectPerPageHandler = useCallback((value: number) => {
    if (!setCurrentPage || !setPerPage) return;
    setPerPage(value);
    setCurrentPage(1);
  }, []);

  const resetPageHandler = useCallback(() => {
    if (!setCurrentPage) return;
    setCurrentPage(1);
  }, []);

  const handleSort = (sorting: Record<string, boolean>) => {
    const colIndex = getColumns.indexOf(Object.keys(sorting)[0]);
    if (colIndex === -1) {
      setSorting(null);
    } else {
      setSorting({
        column: colIndex,
        direction: sorting[Object.keys(sorting)[0]] ? "asc" : "desc",
      });
    }
  };

  return (
    <LoaderWrapperCustom loading={isLoading} transparent>
      <div className={styles.dateRange}>
        <DatePickerRange label="" onSelect={setDateRange} />
      </div>
      <div>
        {getColumns && (
          <div className={styles.tableContainer}>
            <TableSort
              columns={getColumnsTable}
              data={tableData}
              size="medium"
              currentPage={currentPage}
              perPage={perPage}
              resetPage={resetPageHandler}
              onSort={handleSort}
              oneColumnSort
            />
          </div>
        )}
        <div>
          <PaginationReq
            onPage={setCurrentPage}
            onChangePerPage={selectPerPageHandler}
            perPage={perPage}
            currentPage={currentPage}
            totalCount={pages}
            count={tableData?.length}
            perPageList={[50, 100, 150, 200]}
            showTotal={false}
          />
        </div>
      </div>
    </LoaderWrapperCustom>
  );
});
