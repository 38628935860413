import { useEffect, useState } from "react";

import { AdminPanelService } from "@/services";
import { ApprovalStatus, IApproval, IUserApproval } from "@/types/adminPanel";
import { notification } from "@/utils";

const mapTo = (list: IApproval[]): IUserApproval[] => {
  return list.map(({ id, first_name, last_name, email, created_at, company, status }, idx) => ({
    id,
    email,
    company,
    status,
    idx: idx + 1,
    name: first_name || last_name,
    request_date: created_at,
  }));
};

export const useUsersApproval = () => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [usersData, setUsersData] = useState<IUserApproval[] | null>(null);

  const getUsersData = async () => {
    try {
      const { data } = await AdminPanelService.getApprovalsList();
      setUsersData(mapTo(data.result));
    } catch (e: unknown) {
      notification.error("Sorry, some errors occurred while getting the data list.");
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };
  const updateStatus = async (statusData: { status: ApprovalStatus }, id: string) => {
    try {
      const { data } = await AdminPanelService.updateApprovalStatus(statusData, id);

      const found = usersData ? usersData.find((d) => d.id.toString() === id) || null : null;
      if (found) found.status = data.result.status;
      const updatedData = usersData ? [...usersData] : null;
      setUsersData(updatedData);

      notification.success("Status updated successful!");
    } catch (e: unknown) {
      notification.error(
        "Sorry, some error occurred during updating status! Please, try again later."
      );
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  const changeApprovalStatus = async (status: ApprovalStatus, id: string) => {
    setUpdating(true);
    updateStatus({ status }, id);
  };

  useEffect(() => {
    if (usersData === null) {
      getUsersData();
      setLoading(true);
    }
  }, [usersData]);

  return {
    changeApprovalStatus,
    usersData,
    loading,
    updating,
  };
};
