import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "@/images/arrow.svg";

import styles from "./GoBack.module.scss";

interface IProps {
  onClick?: () => void;
  path?: string;
  text?: string;
  type?: "secondary" | "primary";
}

export const GoBack: FC<IProps> = ({ onClick, text, type = "primary", path }) => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    if (onClick) onClick();
    if (path) navigate(path);
  };
  return (
    <button
      className={`${styles.goBack} ${type === "secondary" ? styles.secondary : ""}`}
      onClick={navigateHandler}
    >
      <Arrow />
      {!!text && <span>{text}</span>}
    </button>
  );
};
