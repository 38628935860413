import { AxiosResponse } from "axios";

import axios from "@/services/axios.config";
import { IDaysRange, IRangeSelected, ResultType } from "@/types";

export const MarketServices = {
  getMarketData: (
    query: string,
    params: IDaysRange | IRangeSelected | null
  ): Promise<AxiosResponse<{ result: ResultType }>> =>
    axios.get(`/home/${query}`, params ? { params } : undefined),
  postMarketData: (
    query: string,
    params: IDaysRange | IRangeSelected | null
  ): Promise<AxiosResponse<{ result: ResultType }>> =>
    axios.post(`/home/${query}`, params ? { ...params } : undefined),
};
