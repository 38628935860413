import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useOuterClick } from "@/hooks";
import { commandHelper, debounce } from "@/utils";

import styles from "./InputMessageItem.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear.svg";
import { ReactComponent as WriteIcon } from "@images/write.svg";

export interface ISerchItem {
  value: string;
  key: string;
}

interface IProps {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onSelectItem: (item: ISerchItem) => void;
  searchList: ISerchItem[];
  label: string;
  name: string;
  placeholder?: string;
  value?: string;
  isRequired?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const InputMessageItem = React.forwardRef<HTMLInputElement, IProps>(
  (
    { label, isRequired, onChange, onClear, onSelectItem, searchList, children, ...inputProps },
    ref
  ) => {
    const [toogle, setToggle] = useState(false);
    const [filteredList, setFilteredList] = useState<ISerchItem[]>(searchList);
    const [itemRef] = useOuterClick(setToggle, false);

    const close = useCallback(() => {
      setToggle(false);
    }, []);

    const onSelectCommand = useCallback((command: string | null) => {
      if (command) onSelectItem({ value: command, key: "-" });
    }, []);

    useEscape(close);
    useArrowsKeyPress(onSelectCommand);

    const debouncedToggle = useMemo(() => {
      return debounce((state: boolean, value?: string) => {
        if (value) {
          const found = searchList.filter((option) =>
            option.value.toLocaleLowerCase().includes(value.toLocaleLowerCase())
          );
          setFilteredList(found);
        }

        setToggle(state);
      }, 1000);
    }, [setToggle, searchList]);

    const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChange(ev);
      if (ev.target.value.trim()) debouncedToggle(true, ev.target.value.trim());
      else debouncedToggle(false);
    };

    const focusHandler = () => {
      itemRef.current?.setAttribute("style", "outline: 2px solid var(--main-color);");
    };
    const blurHandler = () => {
      itemRef.current?.setAttribute("style", "outline: 1px solid var(--main-color);");
    };

    useEffect(() => {
      if (!inputProps.value) {
        setToggle(false);
        debouncedToggle(false);
      }
    }, [inputProps.value]);

    const onSelectItemHandler = (item: ISerchItem) => {
      setToggle(false);
      onSelectItem(item);
    };

    return (
      <div className={styles.container}>
        {!!label && (
          <label htmlFor={inputProps.name} className={`${isRequired ? styles.required : ""}`}>
            {label}
          </label>
        )}
        <div className={styles.fieldWrapper}>
          <span className={styles.icon}>
            <WriteIcon />
          </span>
          <div className={styles.input} ref={itemRef}>
            <input
              placeholder={inputProps.placeholder}
              onChange={changeHandler}
              onFocus={focusHandler}
              onBlur={blurHandler}
              ref={ref}
            />
            {!!filteredList.length && (
              <div className={`${styles.listContainer} ${toogle ? styles.open : styles.close}`}>
                <div className={styles.innerWrapper}>
                  <ul className={styles.list}>
                    {filteredList.map((item) => (
                      <li
                        className={styles.listItem}
                        onClick={() => onSelectItemHandler(item)}
                        key={item.key}
                      >
                        {item.value}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div
            className={`${styles.clearBtnContainer} ${!inputProps.value ? styles.disabled : ""}`}
            onClick={onClear}
          >
            <ClearIcon />
          </div>
          {children && (
            <Fragment>
              <div className={styles.inputInner}>{children}</div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
);

export const useEscape = (cb: () => void) => {
  useEffect(() => {
    const keypressHandler = (ev: KeyboardEvent) => {
      if (ev.code === "Escape") {
        cb();
      }
    };

    document.addEventListener("keyup", keypressHandler);

    return () => {
      document.removeEventListener("keyup", keypressHandler);
    };
  }, [cb]);
};

export const useArrowsKeyPress = (cb: (command: string | null) => void) => {
  useEffect(() => {
    const keypressHandler = (ev: KeyboardEvent) => {
      if (ev.code === "ArrowUp") {
        const command = commandHelper.getPrevItem();
        cb(command);
      }
      if (ev.code === "ArrowDown") {
        const command = commandHelper.getNexItem();
        cb(command);
      }
    };
    document.addEventListener("keyup", keypressHandler);

    return () => {
      document.removeEventListener("keyup", keypressHandler);
    };
  }, [cb]);
};
