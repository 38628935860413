import { useAppSelector } from "@/hooks";
import { portfolioBuilderState } from "@/store/portfolios/portfolioBuilder";
import { verifyIsEmptyDataFields } from "@/utils";

export const useCheckBacktestResult = () => {
  const { isPerformanceView, results } = useAppSelector(portfolioBuilderState);
  const isBacktestingResult = isPerformanceView && !verifyIsEmptyDataFields(results);

  return isBacktestingResult;
};
