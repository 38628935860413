import { ILabelItem, ItemType } from "@/components";
import {
  AllocationStyleType,
  ExitStyleType,
  OptionType,
  RebalanceFrequencyType,
  StrikeRatioType,
  TradingBasketType,
  TradingInstrumentType,
  UnderlyingType,
  UpdateFrequencyType,
  WeightingScheme,
} from "@/types";
import {
  MAX_PERCENTAGE_VALUE,
  MAX_RATIO_VALUE,
  MAX_STOP_GAIN_VALUE,
  MAX_STOP_LOSS_VALUE,
} from "@/utils";

export const indicatorsType = [
  {
    key: "technical",
    value: "Technical",
  },
  {
    key: "fundamental",
    value: "Fundamental",
  },
  {
    key: "economic",
    value: "Economic",
  },
  {
    key: "event",
    value: "Event",
  },
  {
    key: "valuation",
    value: "Valuation",
  },
  {
    key: "forecast",
    value: "Forecast",
  },
  {
    key: "custom",
    value: "Custom",
  },
];

export const operatorsList = [
  "Number",
  "+",
  "-",
  "*",
  ">",
  "<",
  "=",
  "≠",
  "and",
  "or",
  "not",
  "(",
  ")",
  "[",
  "]",
];
export const customOperatorsList = [
  "Number",
  "TRUE",
  "FALSE",
  "+",
  "-",
  "*",
  "/",
  "^",
  ">",
  "<",
  ">=",
  "<=",
  "=",
  "≠",
  "(",
  ")",
];

export const paginator = {
  perPagesCommonStrategiesList: [6, 10, 20, 50],
  perPageHomeList: [5, 10, 25],
  perPageList: [10, 25, 50],
  defaultPages: [5, 10, 20],
  small: 5,
  normal: 6,
  default: 10,
  medium: 25,
  large: 50,
  page: 1,
};

export const labels: ILabelItem<string>[] = [
  {
    label: "Core",
    value: "core",
  },
  {
    label: "Overlay",
    value: "overlay",
  },
];

export const directionList: ItemType<string>[] = [
  {
    value: "Buy (long)",
    key: "long",
  },
  {
    value: "Sell (short)",
    key: "short",
  },
];

export const instrumentList: ItemType<TradingInstrumentType>[] = [
  {
    key: "stocks/etfs",
    value: "Stocks/ETFs",
  },
  {
    key: "options",
    value: "Options",
  },
  {
    key: "baskets",
    value: "Baskets",
  },
  {
    key: "cryptos",
    value: "Cryptos",
  },
];

export const tradingBasketList: ItemType<TradingBasketType>[] = [
  {
    key: "sp500",
    value: "S&P 500",
  },
  {
    key: "nasdaq100",
    value: "NASDAQ 100",
  },
  {
    key: "mid_cap",
    value: "Mid-Cap",
  },
  {
    key: "small_cap",
    value: "Small-Cap",
  },
  {
    key: "custom-basket",
    value: "Custom Basket",
  },
];

export const customBasketExtraList = [
  {
    key: "Utilities",
    value: "S&P 500 - Utilities",
  },
  {
    key: "Technology",
    value: "S&P 500 - Technology",
  },
  {
    key: "Real Estate",
    value: "S&P 500 - Real Estate",
  },
  {
    key: "Materials",
    value: "S&P 500 - Materials",
  },
  {
    key: "Industrials",
    value: "S&P 500 - Industrials",
  },
  {
    key: "Health Care",
    value: "S&P 500 - Health Care",
  },
  {
    key: "Financials",
    value: "S&P 500 - Financials",
  },
  {
    key: "Energy",
    value: "S&P 500 - Energy",
  },
  {
    key: "Consumer Staple",
    value: "S&P 500 - Consumer Staple",
  },
  {
    key: "Consumer Discretionary",
    value: "S&P 500 - Consumer Discretionary",
  },
  {
    key: "Communication Services",
    value: "S&P 500 - Communication Services",
  },
];

export const updateFrequencyList: ItemType<UpdateFrequencyType>[] = [
  {
    key: "Daily",
    value: "Daily",
  },
  {
    key: "Weekly",
    value: "Weekly",
  },
  {
    key: "Monthly",
    value: "Monthly",
  },
  {
    key: "Quarterly",
    value: "Quarterly",
  },
];

export const rebalanceFrequencyList: ItemType<RebalanceFrequencyType>[] = [
  {
    key: "Daily",
    value: "Daily",
  },
  {
    key: "Weekly",
    value: "Weekly",
  },
  {
    key: "Monthly",
    value: "Monthly",
  },
  {
    key: "Quarterly",
    value: "Quarterly",
  },
];

export const weightingSchemeList: ItemType<WeightingScheme>[] = [
  {
    key: "Cap-Weight",
    value: "Cap-Weight",
  },
  {
    key: "Equal-Weight",
    value: "Equal-Weight",
  },
  {
    key: "Factor-Weight",
    value: "Factor-Weight",
  },
  {
    key: "Inverse-Volatility-Weight",
    value: "Inverse-Volatility-Weight",
  },
];

export const optionsType: ItemType<OptionType>[] = [
  {
    key: "buy call",
    value: "Buy Call",
  },
  {
    key: "buy put",
    value: "Buy Put",
  },
  {
    key: "sell call",
    value: "Sell Call",
  },
  {
    key: "sell put",
    value: "Sell Put",
  },
];

export const underlyingList: ItemType<UnderlyingType>[] = [
  {
    key: "equities",
    value: "Equities",
  },
  {
    key: "etfs",
    value: "ETFs",
  },
  {
    key: "index",
    value: "Index",
  },
];

export const indexTickerList = ["SPX", "VIX", "NDX"];

export const strikeRatioList: ItemType<StrikeRatioType>[] = [
  {
    key: "ATM",
    value: "ATM",
  },
  {
    key: "XX percentage OTM",
    value: "Percentage OTM",
  },
  {
    key: "XX percentage ITM",
    value: "Percentage ITM",
  },
  {
    key: "XX STDEV OTM",
    value: "STDEV OTM",
  },
  {
    key: "XX STDEV ITM",
    value: "STDEV ITM",
  },
];

export const allocationStyleList: ItemType<AllocationStyleType>[] = [
  {
    key: "notional",
    value: "Notional",
  },
  {
    key: "valueBased",
    value: "Valued-based",
  },
];

export const exitStyleList: ItemType<ExitStyleType>[] = [
  { value: "Hold to Expiration", key: "hold-to-maturity" },
  { value: "Entry/Exit Signals", key: "entry-exit-signals" },
];

export const fields = [
  { key: "expiration_min_days", name: "minDays" },
  { key: "expiration_max_days", name: "maxDays" },
  { key: "stop_gain", name: "stopGain" },
  { key: "stop_loss", name: "stopLoss" },
  { key: "strike_ratio", name: "ratio" },
  { key: "strike_ratio", name: "percentage" },
];

export const toolTipHelperList = {
  strategyName: "Should be at least 3 characters.",
  minDays: "Min Days to Expire should be a number between 1 and 3,650",
  maxDays: "Max Days to Expire should be a number between 1 and 3,650, and >= then Min Days.",
  stopGain: `Stop Gain should be a number between 0 and ${MAX_STOP_GAIN_VALUE}.`,
  stopLoss: `Stop Loss should be a negative number between 0 and ${MAX_STOP_LOSS_VALUE}.`,
  ratio: `Should be a number between 0 and ${MAX_RATIO_VALUE}.`,
  percentage: `Should be a number between 0 and ${MAX_PERCENTAGE_VALUE}.`,
};

export const indexTickers = [
  {
    key: "SPX",
    value: "SPX",
  },
  {
    key: "SPXW",
    value: "SPXW",
  },
  {
    key: "NDX",
    value: "NDX",
  },
  {
    key: "VIX",
    value: "VIX",
  },
];

export const myStrategyFilters = {
  options: false,
  baskets: false,
  stockEtfs: false,
  cryptos: false,
  typeCore: false,
  typeOverlay: false,
};

export type MyStrategyFilterType = keyof typeof myStrategyFilters;
