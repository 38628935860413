import React, { Fragment, useState } from "react";

import { Loader } from "@components/Loader/Loader";

import { useOuterClick } from "@/hooks";

import { ItemType } from "./Dropdown";
import { TooltipWrapper } from "../Tooltip/Tooltip";

import styles from "./DropdownOutline.module.scss";

interface IDropdownProps<T, K extends string> {
  list: ItemType<K>[];
  onSelect: (name: T, item: ItemType<K>) => void;
  selected: ItemType<K> | null;
  label: string;
  name: T;
  disable?: boolean;
  placeholder?: string;
  loading?: boolean;
  isRequired?: boolean;
  icon?: string;
}

export function DropdownOutline<T, K extends string>({
  list,
  onSelect,
  selected,
  label,
  name,
  disable,
  placeholder,
  loading,
  isRequired,
  icon,
}: IDropdownProps<T, K>) {
  const [toggle, setToggle] = useState(false);
  const [itemRef] = useOuterClick(setToggle, false);

  const selectHandler = (item: ItemType<K>, isSubitem = false) => {
    if (!isSubitem) {
      onSelect(name, item);
      setToggle(false);
    }
  };

  const getActiveStyle = (item: ItemType<K>) => {
    return selected?.key === item.key ? styles.active : "";
  };

  const toggleHandle = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setToggle((prev) => !prev);
  };

  const selectedItemText = list.reduce((acc, i) => {
    if (i.subItems) {
      const sub = i.subItems.find((subItem) => subItem.key === selected?.key);
      if (sub) acc = `${i.value} / ${selected?.value}`;
    }
    if (i.key === selected?.key) acc = selected.value;
    return acc;
  }, placeholder || "Select");

  return (
    <div
      className={`${styles.container} ${disable || loading ? styles.disable : ""} ${
        toggle ? styles.open : ""
      } ${loading ? styles.loading : ""}`}
    >
      <div ref={itemRef}>
        {label && <p className={`${styles.label} ${isRequired ? styles.required : ""}`}>{label}</p>}
        <div className={styles.dropdownContainer} onClick={toggleHandle}>
          {icon && (
            <div className={styles.iconContainer}>
              <img src={icon} alt="icon" />
            </div>
          )}
          {loading ? (
            <div className={styles.loadingWrapper}>
              <Loader />
            </div>
          ) : (
            <TooltipWrapper text={selectedItemText}>
              <span className={`${styles.selectedItem} ${selected ? styles.selected : ""}`}>
                {selectedItemText}
              </span>
            </TooltipWrapper>
          )}
        </div>
        <div className={styles.listContainer}>
          <div className={styles.wrapper}>
            <ul className={styles.list}>
              {list.length === 0 ? (
                <Fragment>
                  <li className={`${styles.listItem}`}>Not Avalable</li>
                </Fragment>
              ) : (
                <Fragment>
                  {list.map((item) => (
                    <Fragment key={item.key}>
                      <li
                        className={`${styles.listItem} ${getActiveStyle(item)} ${
                          item?.subItems ? styles.subitemsLablel : ""
                        }`}
                        onClick={() => selectHandler(item, !!item?.subItems)}
                      >
                        {item.value}
                      </li>
                      {item.subItems && (
                        <div key={item.key + "-subitem"}>
                          {item.subItems.map((subitem) => (
                            <li
                              key={subitem.key}
                              className={`${styles.subItem} ${
                                selected?.value === subitem.value ? styles.active : ""
                              }`}
                              onClick={() => selectHandler(subitem)}
                            >
                              - {subitem.value}
                            </li>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
