import { FC, useEffect, useState } from "react";

import { ChartEquities, LoaderWrapper } from "@/components";
import { Heading } from "@/components/Heading/Heading";
import { COLORS } from "@/constants";
import { FolderTypeEnum } from "@/types";
import { checkDataTickerInfo, getSeriesDataCategory, isObjectEmpty } from "@/utils";

import { useGetEquitiesChart } from "../../../../hooks/useGetEquitesChart";

import styles from "./ForecastDetailsView.module.scss";

interface IProps {
  command: string | null;
  type: FolderTypeEnum;
  title?: string | null;
}

export const ForecastDetailsView: FC<IProps> = ({ command, type, title }) => {
  const [activeTab, setActiveTab] = useState("");

  const [tickerInfo, isValid] = checkDataTickerInfo(command);
  const { isLoading, data } = useGetEquitiesChart(tickerInfo);

  const chart = getSeriesDataCategory(data?.[activeTab], {
    defaultSelected: false,
    type: (data?._meta?.[activeTab]?.type as unknown as string) || "spline",
    isSorted: true,
    isHideEmpty: true,
  });

  const quarters =
    data && activeTab
      ? (data._meta[activeTab]?.fiscal_quarters as unknown as Record<string, string>) || null
      : null;

  const quartersEndMonth =
    data && activeTab
      ? (data._meta[activeTab]?.quarters_end_month as unknown as Record<string, string>) || null
      : null;

  const handleTabClick = (tab: string) => () => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const keys = Object.keys(data || {});
    if (!keys.length) return;
    setActiveTab(keys[0]);
  }, [data]);

  const renderTabs = () => {
    return (
      <div className={styles.tabs}>
        {Object.keys(data ?? {})
          .filter((tab) => tab !== "_meta" && isObjectEmpty(data[tab]) === false)
          .map((tab) => (
            <div
              key={tab}
              className={`${styles.tab} ${activeTab === tab ? styles.active : ""}`}
              onClick={handleTabClick(tab)}
            >
              {tab}
            </div>
          ))}
      </div>
    );
  };

  const getColor = (index: number) => {
    return COLORS[index % COLORS.length];
  };

  const titleText = isValid
    ? tickerInfo?.industry
      ? `INDUSTRY: ${tickerInfo.industry}`
      : tickerInfo?.sector
      ? `SECTOR: ${tickerInfo?.sector}`
      : tickerInfo?.company
      ? `COMPANY: ${tickerInfo?.company} (${tickerInfo.ticker})`
      : tickerInfo?.ticker
      ? `COMPANY: (${tickerInfo.ticker})`
      : null
    : null;
  //
  return (
    <LoaderWrapper loading={isLoading} atom fullWidth animatedHeight>
      <div className={styles.chart}>
        <div className={styles.head}>
          <Heading type="h3" className={styles.heading}>
            {/* Odin-Generated Forecast ({ticker}) */}
            {titleText}
          </Heading>
        </div>
        {renderTabs()}
        <div className={styles.charWrapper}>
          <ChartEquities
            data={chart.data.map((series, index) => ({
              ...series,
              color: chart.data.length !== 1 ? getColor(index) : "#00be4f",
              showInLegend: chart.data.length !== 1,
              negativeColor: "#ff372b",
            }))}
            isQuarters
            ticker={activeTab}
            quarters={quarters}
            hasEndDate={!!quartersEndMonth}
            endQuartersDate={quartersEndMonth ? quartersEndMonth : undefined}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};
