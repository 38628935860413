import { ItemType } from "@/components/Dropdown/DropdownSearch";
import { ERRORS, customBasketExtraList, customBasketsIndicatorsList } from "@/constants";
import { customIndicatorService } from "@/services";
import { IndicatorOperatorType } from "@/store/strategies/customIndicator";
import { IConstructorForm, IIndicatorData, IOperator, TradingInstrumentType } from "@/types";

import { notification } from "./notification";
import { notifyCSVError } from "./warRoom";

interface IParams {
  value: string;
  index: number;
  category?: TradingInstrumentType | null;
  defaultValue?: string;
  operator?: IOperator;
  argName?: string;
  info?: string;
}

export const formatOperator = (operator: IndicatorOperatorType): string => {
  return `${operator.argName ? operator.argName + "=" + (operator.category ? "'" : "") : ""}${
    operator.isNumber
      ? operator.numValue || ""
      : operator.value === "T"
      ? "TRUE"
      : operator.value === "F"
      ? "FALSE"
      : operator.value === "="
      ? "=="
      : operator.value === "S&P 500"
      ? "sp500"
      : operator.value === "≠"
      ? "!="
      : customBasketTickerReplacer(operator.value)
  }${operator.argName && !!operator.category ? "'" : ""}`;
};

export const resetConstructorForm = (
  name: string | null = null,
  description: string | null = null
): IConstructorForm => {
  return {
    instrumentType: "stocks/etfs",
    functionItem: null,
    inputType: "function",
    ticker: null,
    name,
    error: null,
    description,
    descriptionError: null,
    basket: null,
  };
};

export const createSimpleOperator = (params: IParams): IndicatorOperatorType => {
  const { index, value, defaultValue, argName, info } = params;

  return {
    index,
    value,
    isNumber: value === "Number",
    numValue: value === "Number" ? defaultValue || "" : "undefined",
    isOperator: true,
    argName,
    info,
  };
};

export const createTickerOperator = (params: IParams): IndicatorOperatorType => {
  const { index, value, category, argName, info } = params;

  return {
    index,
    value,
    isOperator: false,
    category: category || undefined,
    argName,
    info,
  };
};

export const createFunctionOperator = (params: IParams): IndicatorOperatorType[] => {
  const { index, value, operator, argName, info } = params;

  const result: IndicatorOperatorType[] = [
    {
      index,
      value,
      isOperator: false,
      type: "function-operator",
      info: operator?.operator_description,
      argName,
    },
  ];

  if (operator) result.push(...createOperands(operator || null, index));
  return result;
};

export const createOperands = (operator: IOperator | null, index: number) => {
  const result: IndicatorOperatorType[] = [];
  let shiftIndex = 1;

  result.push({
    ...createSimpleOperator({ value: "(", index: index + shiftIndex }),
    isUnerasable: true,
  });

  if (operator && operator.input_arguments) {
    shiftIndex++;

    operator.input_arguments.forEach((arg, idx) => {
      const params: IParams = {
        value: "",
        index: index + shiftIndex,
        argName: arg,
        info: operator.description[arg],
      };

      if (arg.toLowerCase() === "tickers") {
        params.value = "Ticker";
        params.category = "stocks/etfs";
        result.push(createTickerOperator(params));
        shiftIndex++;
      } else if (arg.toLowerCase() === "x") {
        params.value = "Function";
        result.push(...createFunctionOperator(params));
        shiftIndex++;
      } else {
        params.value = "Number";
        params.defaultValue = operator.default_values[arg];
        result.push(createSimpleOperator(params));
        shiftIndex++;
      }

      // add comma after each argument
      if (idx < operator.input_arguments.length - 1) {
        result.push({
          ...createSimpleOperator({ value: ",", index: index + shiftIndex }),
          isUnerasable: true,
        });
        shiftIndex++;
      }
    });
  }

  result.push({
    ...createSimpleOperator({ value: ")", index: index + shiftIndex }),
    isUnerasable: true,
  });

  return result;
};

export const getFuncEndIndex = (
  operator: IndicatorOperatorType,
  constructor: IndicatorOperatorType[] | null
): number => {
  let bracketsCounter = 0;
  const rest = constructor?.slice(operator.index) || null;
  const len = constructor?.length || null;
  let countIndex: number | null = null;
  let startIndex: number | null = null;

  if (rest && len !== null) {
    rest.forEach((o) => {
      if (o.type === "function-operator") {
        if (startIndex === null) startIndex = o.index;
      }
      if (o.value === "(") bracketsCounter++;
      if (o.value === ")") {
        bracketsCounter--;
        if (countIndex === null && bracketsCounter === 0 && startIndex !== null)
          countIndex = o.index - startIndex + 1;
      }
    });
  }

  return countIndex !== null ? countIndex || 1 : 1;
};

export const formatExitFormula = (list: IndicatorOperatorType[] | null): string => {
  return (
    list
      ?.map((i) => formatOperator(i))
      .join("")
      .replaceAll("!=", " != ")
      .replaceAll("==", " == ")
      .replaceAll(/([/^*+-])/gi, " $1 ")
      .replaceAll(/([><]=?)/gi, " $1 ") || ""
  );
};

export const prepareExitIndicatorFormula = (
  constructor: IndicatorOperatorType[] | null
): string[] => {
  const result: string[] = [];
  let count = 0;
  try {
    while (constructor && count < constructor.length) {
      const item = constructor[count];
      if (item.type === "function-operator") {
        const countIndex = getFuncEndIndex(item, constructor);
        const funcResult = formatExitFormula(
          constructor.slice(item.index, item.index + countIndex)
        );
        result.push(funcResult.replaceAll(" ", ""));
        count = count + countIndex;
      } else {
        result.push(formatExitFormula([item]).replaceAll(" ", ""));
        count++;
      }
    }
  } catch {
    console.log(ERRORS.formatError);
  }

  return result;
};

export const convertIndicatorData = (data: IIndicatorData | null) => {
  let constructor = null;

  try {
    constructor = data
      ? (JSON.parse(data?.meta?.constructor || "null") as IndicatorOperatorType[])
      : null;
  } catch {
    console.error("Can not parse constructor object in custom indicator.");
  }

  return {
    form: resetConstructorForm(data?.name || "", data?.description || ""),
    constructor,
    fileName: data?.meta?.fileName || null,
    signal: data?.signal || null,
  };
};

export interface IAddFunctionArgs {
  constructor: IndicatorOperatorType[];
  item: ItemType;
  activeIndex: number;
  operator?: IOperator;
  isReplaceMode: boolean;
}

export const addFunctionOperator = ({
  isReplaceMode,
  constructor,
  activeIndex,
  operator,
  item,
}: IAddFunctionArgs) => {
  let result = [];
  let indexFrom = isReplaceMode ? activeIndex + 1 : activeIndex;
  const currentOperator = constructor[activeIndex];

  if (currentOperator.type === "function-operator" && isReplaceMode) {
    indexFrom += getFuncEndIndex(constructor[activeIndex], constructor) - 1;
  }

  const restItems = constructor.slice(indexFrom);
  const functionOperator = createFunctionOperator({
    value: item.value,
    index: activeIndex,
    operator,
  });

  functionOperator[0].argName = constructor[activeIndex]?.argName;
  if (restItems[0]) restItems[0].argName = undefined;

  result = [...constructor.slice(0, activeIndex), ...functionOperator, ...restItems];
  result.forEach((i, idx) => (i.index = idx));

  return result;
};

export interface IAddDefaultArgs {
  constructor: IndicatorOperatorType[];
  category: TradingInstrumentType | null;
  item: ItemType;
  activeIndex: number;
  isTicker: boolean;
  isReplaceMode: boolean;
}

export const addDefaultOperator = ({
  category,
  item,
  activeIndex,
  isTicker,
  constructor,
  isReplaceMode,
}: IAddDefaultArgs) => {
  let result = [];
  let indexFrom = isReplaceMode ? activeIndex + 1 : activeIndex;
  const currentOperator = constructor[activeIndex];

  const newOperator = isTicker
    ? createTickerOperator({ value: item.value, index: activeIndex, category })
    : createSimpleOperator({ value: item.value, index: activeIndex });

  if (currentOperator.type === "function-operator" && isReplaceMode) {
    indexFrom += getFuncEndIndex(currentOperator, constructor) - 1;
  }

  newOperator.argName = currentOperator.argName;
  const restItems = constructor.slice(indexFrom);
  if (restItems[0]) restItems[0].argName = undefined;

  result = [...constructor.slice(0, activeIndex), newOperator, ...restItems];
  result.forEach((i, idx) => (i.index = idx));
  return result;
};

export interface IAppendOperatorArgs {
  item: ItemType;
  index: number;
  category: TradingInstrumentType | null;
  isFunction: boolean;
  operator?: IOperator;
  isTicker: boolean;
}

export const appendOperatorToEnd = ({
  item,
  category,
  isFunction,
  index,
  operator,
  isTicker,
}: IAppendOperatorArgs) => {
  const result = [];

  if (isFunction) {
    result.push(...createFunctionOperator({ value: item.value, index, operator }));
  } else {
    if (isTicker) {
      result.push(createTickerOperator({ value: item.value, index, category }));
    } else result.push(createSimpleOperator({ value: item.value, index }));
  }
  return result;
};

export interface IBasketItemArgs {
  ticker: string | null;
  basket: ItemType[] | null;
  isSimpleOperator?: boolean;
}

export const createBasketItem = ({ ticker, basket, isSimpleOperator }: IBasketItemArgs) => {
  if (isSimpleOperator) return null;
  const basketTicker = customBasketsIndicatorsList.find((item) => item.key === ticker);
  const isBasketTickers = ticker === "custom-basket";

  return isBasketTickers && basket
    ? { value: basket.map((i) => i.value).join(","), key: "custom-ticker-items" }
    : basketTicker || null;
};

export const indicatorsReplacer = (formula: string[]): string[] => {
  const result = formula.map((item) => {
    return customBasketTickerReplacer(item)
      .replaceAll("!=", " != ")
      .replaceAll("==", " == ")
      .replaceAll(/([/^*+-])/gi, " $1 ")
      .replaceAll(/([><]=?)/gi, " $1 ");
  });
  return result.map((i) => i.trim());
};

export const customBasketTickerReplacer = (item: string): string => {
  const list = item.split(",");
  return list
    .map((i) => {
      const foundItem = customBasketExtraList.find((ticker) => ticker.value === i);
      if (foundItem) return foundItem.key;
      return i;
    })
    .map((i) => {
      const foundItem = customBasketsIndicatorsList.find((ticker) => ticker.value === i);
      if (foundItem) return foundItem.replaceWith;
      return i;
    })
    .join(",");
};

export interface IIndicatorFormDataConfig {
  formula: string[];
  name: string;
  isSignal: boolean;
  constructor: IndicatorOperatorType[] | null;
  fileName: string | null;
  csvFile: File | null;
  description: string;
}

export const createIndicatorFormData = (config: IIndicatorFormDataConfig) => {
  const { name, isSignal, constructor, fileName, csvFile, formula, description } = config;
  const formData = new FormData();

  const indicatorData: IIndicatorData = {
    name: name.trim(),
    type: "technical",
    formula: isSignal ? null : indicatorsReplacer(formula),
    description,
    meta: {
      constructor: isSignal ? null : JSON.stringify(constructor),
    },
  };

  if (isSignal && indicatorData.meta && fileName) {
    indicatorData.meta.fileName = fileName;
  }

  if (isSignal && indicatorData.meta && csvFile) {
    indicatorData.meta.fileName = fileName ? fileName : csvFile.name;
    formData.append("csv_file", csvFile);
  }

  formData.append("body", JSON.stringify(indicatorData));

  return {
    formData,
  };
};

export const canSaveOrUpdate = (config: { isValid: boolean; isFormulaValid: boolean }) => {
  const { isFormulaValid, isValid } = config;
  return isValid && isFormulaValid;
};
