import { ComponentWithPermissions } from "@/components";

const eventsCalendarLoader = () =>
  import("./EventCalendarPage").then(({ EventCalendarPage }) => ({
    default: EventCalendarPage,
  }));

export const EventsCalendarPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={eventsCalendarLoader}
      title="Event Calendar"
      pageKey={"Events-Calendar"}
    />
  );
};
