import { FC, useEffect, useRef, useState } from "react";

import { useGetEarningsStatusQuery } from "@/services/apiQuery/slider";
import { CardEnum } from "@/types/slider";

import { CardItem } from "./components/CardItem/CardItem";
import { useSlideScroll } from "./hooks/useSlideScroll";

import styles from "./CardSlider.module.scss";

import { ReactComponent as ArrowIcon } from "@images/arrow.svg";

const cards = [1, 2, 3, 4, 5];

export const CardSlider: FC = () => {
  const { data, isFetching } = useGetEarningsStatusQuery(CardEnum.SP500);
  const { mouseEnterHandler, mouseLeaveHandler, onMouseWheelHandler, containerRef, isMobile } =
    useSlideScroll();

  return (
    <div
      className={`${styles.container} ${isMobile ? styles.mobile : ""}`}
      onMouseEnter={mouseEnterHandler}
      onWheel={onMouseWheelHandler}
      onMouseLeave={mouseLeaveHandler}
      ref={containerRef}
    >
      <div className={styles.innerSlider}>
        {cards.map((key, idx) => (
          <CardItem key={key} card={data ? data[idx] || null : null} />
        ))}
      </div>
    </div>
  );
};

interface IHorizontalScroll {
  children: React.ReactNode;
  keyId: string;
}

export const HorizontalScroll: FC<IHorizontalScroll> = ({ children, keyId }) => {
  const { mouseEnterHandler, mouseLeaveHandler, onMouseWheelHandler, containerRef, isMobile } =
    useSlideScroll(keyId);

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${styles.scroll} ${isMobile ? styles.mobile : ""}`}
        onMouseEnter={mouseEnterHandler}
        onWheel={onMouseWheelHandler}
        onMouseLeave={mouseLeaveHandler}
        ref={containerRef}
      >
        <div className={styles.inner}>{children}</div>
      </div>
      <button className={styles.actionButton} data-action={`arrow-${keyId}`}>
        <ArrowIcon />
      </button>
    </div>
  );
};
