import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import { UserRoleEnum } from "@/constants";
import { ProfileService, baseURL } from "@/services";
import { IUser } from "@/types/user";
import { notification } from "@/utils/notification";
import { pagePermission } from "@/utils/pagesPermissions";

import { RootState } from ".";

interface IProfileState {
  profile: IUser | null;
  isLoading: boolean;
  isLoadingImage: boolean;
  uid: string | null;
}

const initialState: IProfileState = {
  profile: null,
  isLoading: false,
  isLoadingImage: false,
  uid: null,
};

export const getProfileInfo = createAsyncThunk("profile/getInfo", async () => {
  const results = await ProfileService.getProfile();
  return results.data;
});

export const updateProfileInfo = createAsyncThunk("profile/updateInfo", async (data: any) => {
  const results = await ProfileService.updateProfile(data);
  return results.data;
});

export const updateProfileImage = createAsyncThunk(
  "profile/updateImage",
  async (data: FormData) => {
    const results = await ProfileService.updateProfileImage(data);
    return results.data;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.profile = null;
      state.uid = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileInfo.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.isLoading = false;
        state.uid = window.btoa(`${payload.email}:url=${baseURL}`);
        pagePermission.init(payload);
      })
      .addCase(getProfileInfo.pending, (state) => {
        state.isLoading = true;
        state.profile = initialState.profile;
      })
      .addCase(getProfileInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateProfileInfo.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.isLoading = false;
        notification.success("Updated!");
      })
      .addCase(updateProfileInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfileInfo.rejected, (state, action) => {
        state.isLoading = false;
        notification.error("Something is wrong please try later!");
      })
      .addCase(updateProfileImage.fulfilled, (state, { payload }) => {
        if (state.profile) {
          state.profile = {
            ...state.profile,
            profile_image: payload.profile_image,
          };
        }
        state.isLoadingImage = false;
        notification.success("Updated!");
      })
      .addCase(updateProfileImage.pending, (state) => {
        state.isLoadingImage = true;
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.isLoadingImage = false;
        notification.error("Something is wrong please try later!");
      });
  },
});

const root = (state: RootState) => state;

export const profileInfo = createSelector(root, (state) => state.profile);

export const userRole = createSelector(root, (state) => ({
  isSuperAdmin: state.profile.profile?.role === UserRoleEnum.SUPERADMIN,
  isAdmin: state.profile.profile?.role === UserRoleEnum.ADMIN,
  isSales: state.profile.profile?.role === UserRoleEnum.SALES,
  isRegular: state.profile.profile?.role === UserRoleEnum.REGULAR,
}));

export const { resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
