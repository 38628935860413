import { FC, useEffect, useRef, useState } from "react";

import { Slogan } from "@/components";
import { IVideoType } from "@/types/odinChat";

import styles from "./VideoObj.module.scss";

interface IProps {
  messageId?: string;
  data: IVideoType;
}

export const VideoObj: FC<IProps> = ({ messageId, data }) => {
  const [isPlay, setPlay] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (isPlay && videoRef.current) videoRef.current.play();
  }, [isPlay]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{data.title}</p>
      <div className={styles.videoWrapper}>
        <video controls={isPlay} ref={videoRef}>
          <source src={data.url} type="video/mp4" />
        </video>
        <div
          className={`${styles.frontContainer} ${isPlay ? styles.playing : ""}`}
          onClick={() => setPlay(true)}
        ></div>
        <div className={styles.logo}>
          <Slogan size="small" />
        </div>
      </div>
    </div>
  );
};
