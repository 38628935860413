import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Button, InputItem, LoaderWrapper, ModalLayout } from "@/components";
import { Heading } from "@/components/Heading/Heading";

import styles from "./Discription.module.scss";

interface IProps {
  folderKey: string | number | null;
  setFolderKey: Dispatch<SetStateAction<string | number | null>>;
  handleAddDiscription: (value: string) => void;
  handleSelectedFolder: (key: string | number) => void;
}

export const DiscriptionModal: FC<IProps> = ({
  folderKey,
  setFolderKey,
  handleAddDiscription,
  handleSelectedFolder,
}) => {
  const handleSave = () => {
    if (!folderKey) return;
    handleSelectedFolder(folderKey);
    setFolderKey(null);
  };

  const handleCloseModal = () => {
    setFolderKey(null);
  };

  return (
    <ModalLayout show={!!folderKey} onClose={handleCloseModal}>
      <div className={styles.container}>
        <Heading type="h4">Description (Optional)</Heading>
        <div className={styles.close} onClick={handleCloseModal} />
        <div className={styles.content}>
          <InputItem
            onChange={(e) => handleAddDiscription(e.target.value)}
            label=""
            name=""
            type="input"
            placeholder="Type Description"
          />
          <div className={styles.button}>
            <LoaderWrapper loading={false}>
              <Button onClick={handleSave} text="Save" />
            </LoaderWrapper>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
