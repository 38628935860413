import { CancelToken } from "axios";

import {
  Assets,
  Categories,
  Management,
  IEtfTicker,
  ITickerData,
  INewETFTableItem,
  ITickersList,
} from "@/types/etfIntelligence";

interface IRequestMetaData {
  query: string;
  category: Categories;
  management: Management;
  assets: Assets;
}

export const createMetaData = (metaData: IRequestMetaData): IRequestMetaData => {
  const preparedMeta = { ...metaData };
  if (metaData.management === "all") {
    if (metaData.query.includes("chart=acf")) preparedMeta.management = "active";
    if (metaData.query.includes("chart=aff")) preparedMeta.management = "passive";
  }
  return preparedMeta;
};

export const mapTopList = <I, U extends object[]>(list: U): ITickerData => {
  const topList: ITickerData = {
    meta: null,
    values: [],
  };
  list.forEach((item) => {
    const records = Object.entries(item);
    const result = records.reduce((acc, record, idx) => {
      const [, value] = record;
      if (idx === 0) acc.name = value;
      if (idx === 1) acc.value = value;
      return acc;
    }, {} as IEtfTicker);
    topList.values.push(result);
  });
  return topList;
};

export const splitByGroups = <U>(list: U[] | null, columns: number): U[][] | null => {
  let group: U[] = [];
  if (list) {
    const listLen = list.length;
    const count = Math.ceil(listLen / columns);
    let totalCount = 0;
    return list.reduce((acc, item, idx) => {
      if (group.length < count) {
        group.push(item);
        totalCount++;
      } else {
        acc.push(group);
        group = [item];
      }
      if (idx === listLen - 1 && totalCount < listLen) {
        acc.push(group);
      }
      return acc;
    }, [] as U[][]);
  }
  return null;
};

export const convertToCSVArray = (table: ITickersList): string[][] => {
  const makeString = (item: string[], isString = false) => [
    `"${item[0]}"`,
    isString ? `"${item[1]}"` : item[1],
  ];

  return (
    table.values?.reduce((acc, item, idx) => {
      if (idx === 0) acc.push(makeString(Object.keys(item), true));
      acc.push(makeString(Object.values(item)));
      return acc;
    }, [] as string[][]) || []
  );
};

export const convertTbaleToCSV = (table: INewETFTableItem[]): string[][] => {
  const mapedData = table.map(
    ({
      name,
      symbol,
      aum,
      management_strategy,
      nr_of_holdings,
      expense_ratio,
      issuer,
      inception_date,
    }) => ({
      "ETF Name": symbol,
      Name: name,
      "Management Strategy": management_strategy,
      "Nr. of Holdings": nr_of_holdings,
      "Expense Ratio": expense_ratio,
      "AUM($M)": aum,
      Issuer: issuer,
      "Inception Date": inception_date,
    })
  );

  const makeRow = (items: (string | number | null)[]) =>
    items.map((i) => (i === null ? `"-"` : Number(i) ? `${i}` : `"${i}"`));

  return (
    mapedData.reduce((acc, item, idx) => {
      if (idx === 0) acc.push(makeRow(Object.keys(item)));
      acc.push(makeRow(Object.values(item)));
      return acc;
    }, [] as string[][]) || []
  );
};

export function getPercentChangeColor(
  change: number | string | null,
  isPositiveColor = true
): string {
  let color = "";

  if (change == null) return color;

  const preparedChange = Number(change);
  if (preparedChange > 0) {
    color = isPositiveColor ? "var(--success)" : "";
  } else if (preparedChange < 0) {
    color = "var(--error)";
  }
  return color;
}
