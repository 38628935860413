import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ContentLayout, FooterLayout, InfoHeading, SubTitle, Tab } from "@/components";
import { faqDescriptionList } from "@/constants/faqDescription";
import { PortfolioMain, StrategiesDashboard } from "@/features";
import { usePageInfo } from "@/hooks";

import { AllocationPermissions, ComparePermissions } from "./ModelingPermissions";

import styles from "./Modeling.module.scss";

const enum TabsEnum {
  MULTI_STRATEGY = "Multi Strategy",
  SINGLE_STRATEGY = "Single Strategy",
  COMPARE = "Compare",
  ALLOCATION_OPTIMIZER = "Allocation Optimizer",
}

const tabMapper: Record<string, { key: string; faq: keyof typeof faqDescriptionList }> = {
  "Multi Strategy": { key: "multi-strategy", faq: "portfolioMultiStrategy" },
  "Single Strategy": { key: "single-strategy", faq: "portfolioSingleStrategy" },
  "Allocation Optimizer": { key: "#", faq: "portfolioAllocationOptimizer" },
  Compare: { key: "#", faq: "portfolioAllocationOptimizer" },
};

const getCurrentTab = (currentTab: string | null) => {
  return currentTab ? decodeURIComponent(currentTab) : TabsEnum.SINGLE_STRATEGY;
};

export const Modeling = () => {
  const [search, setSearch] = useSearchParams();
  const currentTab = getCurrentTab(search.get("current_tab"));
  const [activeTab, setActiveTab] = useState<string>(currentTab);

  const { textInfo, video } = usePageInfo(tabMapper[activeTab].key);

  const selectTabHandle = (tabName: string) => {
    setActiveTab(tabName);
    setSearch({ current_tab: encodeURIComponent(tabName) });
  };

  const tabsList = useMemo(
    () => [
      {
        key: TabsEnum.SINGLE_STRATEGY,
        title: TabsEnum.SINGLE_STRATEGY,
        component: <StrategiesDashboard />,
      },
      {
        key: TabsEnum.MULTI_STRATEGY,
        title: TabsEnum.MULTI_STRATEGY,
        component: <PortfolioMain />,
      },
      {
        key: TabsEnum.ALLOCATION_OPTIMIZER,
        title: TabsEnum.ALLOCATION_OPTIMIZER,
        component: <AllocationPermissions />,
      },
      {
        key: TabsEnum.COMPARE,
        title: TabsEnum.COMPARE,
        component: <ComparePermissions />,
      },
    ],
    []
  );

  return (
    <FooterLayout showLiveMarketSlider>
      <ContentLayout>
        <div className={styles.container}>
          <InfoHeading
            helpInfo={textInfo}
            tutorialLink={video}
            faqList={faqDescriptionList[tabMapper[activeTab].faq]}
          >
            Portfolio Builder - <SubTitle text={activeTab} />
          </InfoHeading>
          <Tab tabs={tabsList} selected={activeTab} onChange={selectTabHandle} />
        </div>
      </ContentLayout>
    </FooterLayout>
  );
};
