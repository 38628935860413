import AssetAllocation_1 from "@images/solutions/asset_allocation_1.jpg";
import AssetAllocation_2 from "@images/solutions/asset_allocation_2.jpg";
import Crypto_1 from "@images/solutions/crypto_1.jpg";
import Crypto_2 from "@images/solutions/crypto_2.jpg";
import EcosystemDiscovery from "@images/solutions/discretionary_ecosystem_discovery.jpg";
import DiscretionaryForecast_1 from "@images/solutions/discretionary_forecast_1.jpg";
import DiscretionaryForecast_2 from "@images/solutions/discretionary_forecast_2.jpg";
import DiscretionaryForecast_3 from "@images/solutions/discretionary_forecast_3.jpg";
import DiscretionaryForecast_4 from "@images/solutions/discretionary_forecast_4.jpg";
import DiscretionaryForecast_5 from "@images/solutions/discretionary_forecast_5.jpg";
import DiscretionaryForecast_6 from "@images/solutions/discretionary_forecast_6.jpg";
import DiscretionaryForecast_7 from "@images/solutions/discretionary_forecast_7.jpg";
import GeographicalSales from "@images/solutions/discretionary_geographical_sales.jpg";
import ManagementGuidance from "@images/solutions/discretionary_management_guidance.jpg";
import OperatingMetrics from "@images/solutions/discretionary_operating_metrics.jpg";
import OperatingMetrics_2 from "@images/solutions/discretionary_operating_metrics_2.jpg";
import ProductSegmentSales from "@images/solutions/discretionary_product_segment_sales.jpg";
import ProductSegmentSales_2 from "@images/solutions/discretionary_product_segment_sales_2.jpg";
import Hedging_1 from "@images/solutions/hedging_1.jpg";
import Hedging_2 from "@images/solutions/hedging_2.jpg";
import Hedging_3 from "@images/solutions/hedging_3.jpg";
import Hedging_4 from "@images/solutions/hedging_4.jpg";
import Hedging_5 from "@images/solutions/hedging_5.jpg";
import Hedging_6 from "@images/solutions/hedging_6.jpg";
import Systematic_1 from "@images/solutions/systematic_1.jpg";
import Systematic_2 from "@images/solutions/systematic_2.jpg";
import Systematic_3 from "@images/solutions/systematic_3.jpg";

export const navMenuList = [
  {
    key: "discretionary",
    title: "Discretionary",
    description:
      "Discretionary investors heavily rely on consensus, where analysts tend to follow each other. We believe our scientific approach to forecasting offers accurate, comprehensive, systematic, and unbiased results.",
    list: [
      "Quick and in depth analysis via chat at the tip of your fingertips",
      "Scientific equity forecasts to verify your short/long holdings",
      "From fundamentals to portfolio using our modeling tools",
      "Macro forecasts for short to mid-term planning",
    ],
    slides: [""],
  },
  {
    key: "systematic",
    title: "Systematic",
    description:
      "Systematic investors heavily rely on rules put forth by experts. Quickly testing the accuracy of these observations, especially over a long period of history is complicated. Issues like survivorship bias and more are handled by our system.",
    list: [
      "Build long/short strategies from 400K+ prebuilt factors or custom indicators",
      "Model baskets with correct lists in time and price-adjusted for corporate actions",
      "Model macro and option strategies for indexes and ETFs",
      "Mix-and-match strategies to construct a portfolio",
    ],
    slides: [""],
  },
  {
    key: "hedging",
    title: "Hedging",
    description:
      "Popular mechanical hedging techniques such as the collar strategy or its variations are expensive, ineffective, and unsustaintable. Harnessing big data and examining markets from multiple angles empowers OdinUltra users to develop cost-effective hedging strategies capable of enhancing the risk-return profile of the overall hedged portfolio. Key ingredients here include the ability to systematically monitor several signals and to execute the hedging trades with discipline.",
    list: [
      "Construct hedges using macro trading instruments including ETFs and equity or index options",
      "Hypothesis-testing on entry or exit conditions with complex logic on fundamental, technical, economic, events, or macro indicators.",
      "Build hedges based on user’s forward expected macro conditions and typical expected market behavior under those conditions",
    ],
    slides: [""],
  },
  {
    key: "crypto",
    title: "Crypto",
    description:
      "As crypto finds it is place in asset allocation, the need for active crypto portfolio management is rising. Volatilities in Crypto assets have usually been a cause of discomfort for investors. With OdinUltra, portfolio managers can combine various technical metrics and cryptocurrencies, other assets, and macro factors to create active multi-crypto portfolios with less volatility and more desirable risk/reward  balance. OdinUltra enables portfolio managers to quickly create and test new dynamic and active crypto portfolios with no coding.",
    list: [
      "Construct single or multiple digital currency strategies",
      "Test crypto trading rules using technical indicators as well as macroeconomic or cross-asset signals",
      "Evaluate crypto market indicators in equity or macro portfolios",
      "Access the marginal effect of crypto strategies in multi-asset portfolios",
    ],
    slides: [""],
  },
  {
    key: "asset-allocation",
    title: "Asset Allocation",
    description:
      "Asset allocation is an age old problem. Tried and true methods, such as 60/40, no longer work. Fixed allocations are dangerous, and more dynamic approaches are needed to account for ever-changing market conditions and the macro environment. Input your own macro hypothesis and allow AI optimized allocation.",
    list: [
      "Optimize allocation across multiple asset classes",
      "Model allocation within a single asset class (equity sectors, fixed income, commodities)",
      "Multi-manager allocation between different managers",
      "Multi-strategy allocation for various strategies",
    ],
    slides: [""],
  },
];

export const sliderList: Record<string, { key: string; image: string }[]> = {
  discretionary: [
    {
      key: "1",
      image: OperatingMetrics,
    },
    {
      key: "2",
      image: OperatingMetrics_2,
    },
    {
      key: "3",
      image: ProductSegmentSales,
    },
    {
      key: "4",
      image: ProductSegmentSales_2,
    },
    {
      key: "5",
      image: GeographicalSales,
    },
    {
      key: "6",
      image: EcosystemDiscovery,
    },
    {
      key: "7",
      image: ManagementGuidance,
    },
    {
      key: "8",
      image: DiscretionaryForecast_1,
    },
    {
      key: "9",
      image: DiscretionaryForecast_2,
    },
    {
      key: "10",
      image: DiscretionaryForecast_3,
    },
    {
      key: "11",
      image: DiscretionaryForecast_4,
    },
    {
      key: "12",
      image: DiscretionaryForecast_5,
    },
    {
      key: "13",
      image: DiscretionaryForecast_6,
    },
    {
      key: "14",
      image: DiscretionaryForecast_7,
    },
  ],
  systematic: [
    {
      key: "1",
      image: Systematic_1,
    },
    {
      key: "2",
      image: Systematic_2,
    },
    {
      key: "3",
      image: Systematic_3,
    },
  ],
  hedging: [
    {
      key: "1",
      image: Hedging_1,
    },
    {
      key: "2",
      image: Hedging_2,
    },
    {
      key: "3",
      image: Hedging_3,
    },
    {
      key: "4",
      image: Hedging_4,
    },
    {
      key: "5",
      image: Hedging_5,
    },
    {
      key: "6",
      image: Hedging_6,
    },
  ],
  crypto: [
    {
      key: "1",
      image: Crypto_1,
    },
    {
      key: "2",
      image: Crypto_2,
    },
  ],
  "asset-allocation": [
    {
      key: "1",
      image: AssetAllocation_1,
    },
    {
      key: "2",
      image: AssetAllocation_2,
    },
  ],
};
