const originalLog = console.log;

if (process.env.NODE_ENV === "production") {
  console.warn("LOG WRAPPER ENABLED!");
} else {
  console.warn("LOG WRAPPER DISBALED!");
}

console.log = function LogWrapper(...args) {
  if (process.env.NODE_ENV !== "production") {
    originalLog(...args);
  }
};
