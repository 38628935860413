import { FC, useMemo } from "react";

import { ExtendedColumns, Table, TableExpand } from "@/components";
import { IChatTableData } from "@/types/odinChat";

import styles from "./TableChatObj.module.scss";

interface ITableChatObjProps {
  data: IChatTableData;
}

export const TableChatObj: FC<ITableChatObjProps> = ({ data }) => {
  const { df, title } = data;

  const columns: ExtendedColumns = useMemo(() => {
    const cols = Object.keys(df).map((colName, idx) => ({
      Header: colName,
      accessor: colName,
      canSort: false,
      disableSortBy: true,
      minWidth: colName.length * 8 < 50 ? 50 : colName.length * 8 > 400 ? 400 : colName.length * 8,
    }));
    return cols;
  }, [df]);

  const rowData = useMemo(() => {
    try {
      const keys = Object.keys(df);
      const values = Object.values(df);
      const rows = values[0].map((_, index) => {
        const obj = keys.reduce<Record<string, number | string | null>>((acc, key, idx) => {
          acc[key] = values[idx][index];
          return acc;
        }, {});
        return obj;
      });
      return rows;
    } catch {
      console.warn("Some chat data table are wrong!");
      return [];
    }
  }, []);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <TableExpand columns={columns} data={rowData} />
      </div>
    </div>
  );
};
