import dayjs from "dayjs";
import { FC, Fragment, useMemo, useState } from "react";

import {
  ChartWarRoomReturns,
  ExtendedColumns,
  ModalLayout,
  Pagination,
  TableExpand,
} from "@/components";
import { DATE_ISO_US_SHORT } from "@/constants";
import { useAbortRequest } from "@/hooks";
import {
  IChartCategorySieriesData,
  IReturnsMunthlyItem,
  ReturnsTableDataType,
} from "@/types/warRoom";
import { alfaNumericSorter, isObjectEmpty } from "@/utils";
import { formatReturnChartData } from "@/utils/warRoom";

import styles from "./ReturnsData.module.scss";

interface IReturnsDataProps {
  tableData: ReturnsTableDataType[] | null;
  chartData: Record<string, IReturnsMunthlyItem[]> | null;
  assetsList: string[] | null;
}

const columnWidth = [230];
const DEFAULT_WIDTH = 155;
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;

export const ReturnsData: FC<IReturnsDataProps> = ({ tableData, chartData, assetsList }) => {
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [returnChartData, setReturnChartData] = useState<IChartCategorySieriesData | null>(null);
  const abort = useAbortRequest();

  const filteredTableData = useMemo(() => {
    return (
      tableData?.map((td) => {
        const keys = Object.keys(td);
        return keys.reduce((acc, key) => {
          acc[key.replaceAll(".", "")] = td[key];
          return acc;
        }, {} as ReturnsTableDataType);
      }) || null
    );
  }, [tableData]);

  const setCurrentPage = (pageValue: number) => {
    setPage(pageValue);
  };

  const perPageHandler = (perPageValue: number) => {
    setPerPage(perPageValue);
  };

  const resetPageHandler = () => {
    setPage(DEFAULT_PAGE);
  };

  const selectHandler = (row: Record<string, any>) => {
    const rowKey = row["date"];
    if (assetsList && assetsList.length > 0 && chartData && rowKey) {
      setReturnChartData(formatReturnChartData(chartData[rowKey], assetsList));
    }
    setShowModal(true);
  };

  const closeHandler = () => {
    abort.cancel();
    setShowModal(false);
  };

  const isEmptyData = isObjectEmpty(chartData || {}) || !returnChartData;
  const totalPages = filteredTableData ? Math.ceil(filteredTableData.length / perPage) : null;
  const period = returnChartData
    ? `${dayjs(returnChartData.dates[0]).format(DATE_ISO_US_SHORT)} - ${dayjs(
        returnChartData.dates.at(-1)
      ).format(DATE_ISO_US_SHORT)}`
    : "";

  const columns: ExtendedColumns = useMemo(
    () =>
      Object.keys(filteredTableData ? filteredTableData[0] : {}).map((key, idx) => ({
        Header: key === "date" ? "Date" : key,
        accessor: key,
        minWidth: columnWidth[idx] || DEFAULT_WIDTH,
        Cell: ({ value }: object & { value: number | null }) => (
          <span>{idx > 0 && value ? value?.toFixed(2) : value}</span>
        ),
        canSort: true,
        sortType: (a, b, colId) => alfaNumericSorter(a.values[colId], b.values[colId]),
      })),
    [filteredTableData]
  );

  return (
    <Fragment>
      <div className={styles.container}>
        <TableExpand<object>
          data={filteredTableData || []}
          columns={columns}
          scrollWidth={750}
          onSelect={selectHandler}
          size="medium"
          currentPage={page}
          perPage={perPage}
          resetPage={resetPageHandler}
        />
        <div className={styles.pagination}>
          <Pagination
            onChangePerPage={perPageHandler}
            onPage={setCurrentPage}
            perPage={perPage}
            currentPage={page}
            totalCount={totalPages}
            count={filteredTableData?.length || 0}
            perPageList={[10, 25, 50]}
            showTotal={false}
          />
        </div>
      </div>
      <ModalLayout show={showModal} onClose={closeHandler}>
        <div className={styles.modalContainer}>
          <h2>
            Monthly Period Returns: <span>{period}</span>
          </h2>
          <div className={styles.close} onClick={closeHandler}></div>
          <div className={styles.modalInner}>
            <div className={styles.chartWrapper}>
              {isEmptyData ? (
                <div className={styles.emptyData}>No Data</div>
              ) : (
                <ChartWarRoomReturns data={returnChartData} yTitle="Returns (%)" />
              )}
            </div>
          </div>
        </div>
      </ModalLayout>
    </Fragment>
  );
};
