import { useCallback, useEffect, useMemo, useState } from "react";

import { discoverService } from "@/services";
import { IMetaDetails, IQueryPayload } from "@/types";
import { notification } from "@/utils";

export const useGetFilterParams = (
  drsMeta: IMetaDetails | null,
  dimensions: string[] | undefined
) => {
  const [filterParams, setFilterParams] = useState<Record<string, string[]>>({});
  const [isLoadingParams, setLoadingParams] = useState<boolean>(false);
  const queryParams = useMemo(
    () =>
      dimensions?.map((item) => {
        return {
          drs_id: drsMeta?.drs_id,
          table: drsMeta?.name,
          select: {
            type: "distinct",
            fields: [item],
          },
          page: 1,
          per_page: 5000,
        };
      }),
    [dimensions, drsMeta?.drs_id, drsMeta?.name]
  );
  const getAllData = useCallback(async (query: IQueryPayload) => {
    try {
      const res = await discoverService.fetchData(query);
      if (!res?.data.results.length) {
        return null;
      }
      return res?.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  useEffect(() => {
    if (!queryParams?.length) return;
    setLoadingParams(true);
    Promise.all(queryParams.map((item) => getAllData(item as IQueryPayload)))
      .then((res) =>
        setFilterParams(
          res.reduce((acc, item, i) => {
            if (!item) return acc;
            acc = {
              ...acc,
              [item.meta[0].name]: item?.results.flat(),
            };
            return acc;
          }, {})
        )
      )
      .catch((e) => {
        notification.error("Something is wrong please try later!");
      })
      .finally(() => {
        setLoadingParams(false);
      });
  }, [getAllData, queryParams]);
  return { filterParams, isLoadingParams };
};
