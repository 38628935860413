import { FC } from "react";

import styles from "./NoDataFiles.module.scss";

import BGImage from "@images/bg-fill.jpg";
import { ReactComponent as NoFilesIcon } from "@images/no-data-files.svg";

interface IProps {
  title: string;
  subTitle?: string;
}

export const NoDataFiles: FC<IProps> = ({ title, subTitle = "Please, upload some files." }) => {
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${BGImage})` }}>
      <div className={styles.iconWrapper}>
        <NoFilesIcon />
      </div>
      <div className={styles.textContainer}>
        <p>{title}</p>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};
