import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { notification } from "@/utils/notification";

import { RootState } from "./index";
import { KeyDriversService } from "../services/keydriversService";

interface ITikersState {
  isLoading: boolean;
  results: Record<string, Record<string, number>> | null;
}

const initialState: ITikersState = {
  results: null,
  isLoading: false,
};

export const fetchKeyDriversCharts = createAsyncThunk(
  "keyDriversList/list",
  async (ticker: string) => {
    const res = await KeyDriversService.fetchKeyDriverCharts(ticker);
    return res?.data.result;
  }
);

export const keyDriversSlice = createSlice({
  name: "keyDriversList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeyDriversCharts.fulfilled, (state, action) => {
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchKeyDriversCharts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchKeyDriversCharts.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const keyDriversState = createSelector(state, (state) => state.keyDrivers);

export default keyDriversSlice.reducer;
