import { FC } from "react";

import { ButtonLink, InfoHeading, LoaderWrapper } from "@/components";
import { ROUTES, UserRoleEnum } from "@/constants";
import { useAppSelector, useUserPermissions } from "@/hooks";
import InviteIcon from "@/images/userInvite.svg";
import { profileInfo } from "@/store/profile";
import { usersState } from "@/store/users";

import { UsersTable } from "./components/UsersTable/UsersTable";

import styles from "./UsersManagement.module.scss";

export const UsersManagement: FC = () => {
  const { users, isLoading } = useAppSelector(usersState);
  const { profile } = useAppSelector(profileInfo);
  const { canInviteUser, userRole } = useUserPermissions();

  const usersList = users.filter((user) => user.id !== profile?.id);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <InfoHeading>Users Management</InfoHeading>
        <div className={styles.heading}>Users</div>
        <div className={styles.group}>
          <p className={styles.helpText}>Please, select a user to change User permission status.</p>
          {canInviteUser("invite") ? (
            <div>
              <ButtonLink
                icon={InviteIcon}
                text="Invite User"
                to={ROUTES.invite.path}
                disable={isLoading}
              />
            </div>
          ) : null}
        </div>

        <section className={styles.tableSection}>
          <LoaderWrapper loading={isLoading} transparent>
            <div className={styles.table}>
              <UsersTable users={usersList} isLoading={isLoading} />
            </div>
          </LoaderWrapper>
        </section>
      </div>
    </div>
  );
};
