import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalLayout } from "@/components";
import { LogoutDialog } from "@/components/Dialogs/LogoutDialog";
import { PRESENTATION_KEY, ROUTES, userSeesion } from "@/constants";
import { useAppDispatch, useOuterClick } from "@/hooks";
import { authService } from "@/services";
import { getFolders } from "@/store/focus/foldersManage";
import { init } from "@/store/login";

import { UserMenu } from "./components/UserMenu/UserMenu";
import { UserMenuMobile } from "./components/UserMenuMobile/UserMenuMobile";

interface IProps {
  isMobile: boolean;
  onSelect?: () => void;
}

export const UserInfo: FC<IProps> = ({ isMobile, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMoadl, setShowModal] = useState(false);
  const [itemRef] = useOuterClick(setIsOpen, false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  const confirmLogout = () => {
    navigate(ROUTES.root.path, { replace: true });
    authService.logout();
    userSeesion.remove();
    dispatch(init());
    //localStorage.removeItem(PRESENTATION_KEY);
  };

  const cancelLogout = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    setShowModal(true);
    setIsOpen((prev) => !prev);
  };

  const handleClickMenu = () => {
    setIsOpen((prev) => !prev);
    if (onSelect) onSelect();
  };

  const toggleHandler = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div ref={itemRef}>
      {isMobile ? (
        <UserMenuMobile
          handleLogout={handleLogout}
          handleClickMenu={handleClickMenu}
          hendleToggle={toggleHandler}
          isOpen={isOpen}
        />
      ) : (
        <UserMenu handleLogout={handleLogout} handleClickMenu={handleClickMenu} isOpen={isOpen} />
      )}
      <ModalLayout show={showMoadl} onClose={cancelLogout}>
        <LogoutDialog onCancel={cancelLogout} onConfirm={confirmLogout} />
      </ModalLayout>
    </div>
  );
};
