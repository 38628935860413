import { FC, memo, useCallback, useMemo, useState } from "react";

import { ExtendedColumns, LoaderWrapper, Pagination, Table } from "@/components";
import { IEconomicCalendar } from "@/types";

import styles from "./EconomicCalendarTable.module.scss";

interface IProps {
  EconomicCalendarData: IEconomicCalendar[];
  isLoading: boolean;
}

export const EconomicCalendarTable: FC<IProps> = memo(({ EconomicCalendarData, isLoading }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);
  const paginatedList = useMemo(
    () =>
      EconomicCalendarData
        ? EconomicCalendarData.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
        : [],
    [page, EconomicCalendarData, perPage]
  );
  const totalPages = EconomicCalendarData ? Math.ceil(EconomicCalendarData.length / perPage) : null;
  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        canSort: false,
        disableSortBy: true,
        minWidth: 150,
      },
      {
        Header: "Country",
        accessor: "country",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
      },
      {
        Header: "Event",
        accessor: "name",
        canSort: true,
        isTruncated: false,
        minWidth: 240,
      },
      {
        Header: "Period",
        accessor: "period",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Prior",
        accessor: "prior",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Actual",
        accessor: "actual",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Consensus",
        accessor: "consensus",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Importance",
        accessor: "importance",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Surprise",
        accessor: "surprise",
        minWidth: 100,
        canSort: false,
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "time",
        minWidth: 120,
        canSort: false,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <LoaderWrapper loading={isLoading} atom fullWidth>
      <Table<IEconomicCalendar> columns={columns} data={paginatedList} isNotClickable />
      {paginatedList.length > 0 && (
        <div className={styles.pagination}>
          <Pagination
            onPage={setPage}
            onChangePerPage={selectPerPageHandler}
            perPage={perPage}
            currentPage={page}
            totalCount={totalPages}
            count={EconomicCalendarData?.length}
            perPageList={[10, 20, 50, 100]}
            countLabel="Total items"
          />
        </div>
      )}
    </LoaderWrapper>
  );
});
