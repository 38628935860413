import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dataBrowserService } from "@/services";
import { ICompanyCalendar } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface ICompanyCalendarState {
  data: ICompanyCalendar[];
  isLoading: boolean;
}

const initialState: ICompanyCalendarState = {
  data: [],
  isLoading: false,
};

export const getCompanyCalendar = createAsyncThunk(
  "companyCalendar/get",
  async ({
    start,
    end,
    tiker,
    index,
  }: {
    start?: string;
    end?: string;
    tiker?: string;
    index?: string;
  }) => {
    const response = await dataBrowserService.getCompanyCalendar(start, end, tiker, index);
    return response.data.result || [];
  }
);

export const portfolioBuilderSlice = createSlice({
  name: "companyCalendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyCalendar.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getCompanyCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyCalendar.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const companyCalendarState = createSelector(state, (state) => state.companyCalendar);

export default portfolioBuilderSlice.reducer;
