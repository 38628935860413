import { Dispatch, FC, useEffect } from "react";

import { DateObj, DatePickerRange, Popover, Tag } from "@/components";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { getDateISOString } from "@/utils";

import styles from "./DateDimensions.module.scss";

interface IProps {
  setDateRanges: Dispatch<React.SetStateAction<Record<string, DateObj>>>;
  dateRanges: Record<string, DateObj>;
  setTimeDimensions: Dispatch<React.SetStateAction<string[]>>;
  timeDimensions: string[];
}

export const DateDimensions: FC<IProps> = ({
  setDateRanges,
  dateRanges,
  setTimeDimensions,
  timeDimensions,
}) => {
  const { drsMeta } = useAppSelector(discoverDrsMetaState);

  interface IDate {
    start: string | null;
    end: string | null;
  }

  const handleSelectRange = (key: string) => (range: DateObj) => {
    setDateRanges((state: Record<string, DateObj>) => {
      const nextState = { ...state };

      if (!range.end && !range.start) {
        delete nextState[key];
      } else {
        nextState[key] = range;
      }

      return nextState;
    });
  };

  useEffect(() => {
    setTimeDimensions(drsMeta?.format?.time_dimensions ?? []);
  }, [drsMeta?.format?.time_dimensions, setTimeDimensions]);

  const handleRemove = (key: string) => () => {
    setTimeDimensions(timeDimensions.filter((label) => label !== key));
  };

  if (drsMeta?.format?.time_dimensions.length === 1) {
    return null;
  }

  const content = (key: string) => {
    return (
      <div className={styles.content}>
        <div className={styles.inputItem}>
          <DatePickerRange
            date={(dateRanges?.[key] || { start: null, end: null }) as IDate}
            onSelect={handleSelectRange(key)}
            label={key}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionName}>
        <span>Date Dimensions</span>
      </div>
      {timeDimensions.map((timeDimension) => (
        <Popover key={timeDimension} content={<div>{content(timeDimension)}</div>}>
          <Tag
            color="primary"
            canDelete={timeDimensions.length > 1}
            onDelete={handleRemove(timeDimension)}
          >
            <div className={styles.tagContent}>
              <div>{timeDimension}</div>
              {dateRanges?.[timeDimension] && (
                <>
                  [{getDateISOString(dateRanges?.[timeDimension]?.start)} /{" "}
                  {getDateISOString(dateRanges?.[timeDimension]?.end)}]
                </>
              )}
            </div>
          </Tag>
        </Popover>
      ))}
    </div>
  );
};
