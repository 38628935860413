import { FC } from "react";

import { ContentLayout, FooterLayout, UnderConstruction } from "@/components";
import { UserProfile } from "@/features";

export const UserProfilePage: FC = () => {
  return (
    <FooterLayout showFooter={true}>
      <ContentLayout>
        <UserProfile />
      </ContentLayout>
    </FooterLayout>
  );
};
