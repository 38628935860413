import { useEffect, useMemo, useState } from "react";

import { ExtendedColumns } from "@/components";
import { useAppSelector } from "@/hooks";
import { strategiesState } from "@/store/strategies/strategies";
import {
  createStrategyTableColumns,
  createPerformanceSeriesData,
  prepareStrategyTable,
} from "@/utils";

export const usePreviewPerformanceData = () => {
  const [isPreviewReady, setIsPreviewReady] = useState(false);
  const { strategy, isPreviewLoading } = useAppSelector(strategiesState);

  const noData = strategy?.length === 0;
  const strategyPerformance = (strategy && strategy[0]) || null;
  const isBenchmarkData = !!strategyPerformance?.data.equity.benchmarkLine;

  const tableData = useMemo(() => {
    return strategyPerformance ? prepareStrategyTable(strategyPerformance.data) : [];
  }, [strategy, strategyPerformance]);

  const chartData = useMemo(() => {
    return createPerformanceSeriesData(strategyPerformance);
  }, [strategy, strategyPerformance]);

  const columns: ExtendedColumns = useMemo(
    () => createStrategyTableColumns(isBenchmarkData),
    [isBenchmarkData]
  );

  useEffect(() => {
    if (!isPreviewLoading && strategy) setIsPreviewReady(true);
    else setIsPreviewReady(false);
  }, [isPreviewLoading, strategy]);

  return {
    isPreviewLoading,
    isPreviewReady,
    tableData,
    chartData,
    noData,
    columns,
  };
};
