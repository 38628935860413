import { AxiosResponse, CancelTokenSource } from "axios";

import {
  ICommonStrategyData,
  IStrategyData,
  INewStrategyData,
  StrategiesBackTestingDataResponse,
  StrategyUserPermission,
  IStartegyCard,
  IPermissionsData,
  IStrategyBase,
  IDataPerformance,
  NewStartegyConfig,
  IMetricIndicator,
  UnderlyingType,
} from "@/types";

import axios, { $instansAxios } from "./axios.config";

export const strategiesService = {
  abort: new AbortController(),
  fetchStrategy: (id: number): Promise<AxiosResponse<{ result: IStrategyData }>> =>
    axios.get(`/strategies/${id}`),
  fetchStrategyList: (): Promise<AxiosResponse<{ result: ICommonStrategyData[] }>> =>
    axios.get("/strategies/default"),
  fetchStrategyWithEquitiesList: (
    requestedPage = 1
  ): Promise<AxiosResponse<{ result: ICommonStrategyData[] }>> =>
    axios.get(`/strategies/default-with-equities?page=${requestedPage}`),
  searchStrategyWithEquitiesList: (
    query: string
  ): Promise<AxiosResponse<{ result: ICommonStrategyData[] }>> =>
    axios.get(`/strategies/default-with-equities?search_keyword=${query}`),
  fetchBackTestingData: (
    key: string,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<StrategiesBackTestingDataResponse[]>> =>
    $instansAxios.get(
      start
        ? `/strategy-backtesting/strategies/backtesting-data?key=${key}&start_date=${start}&end_date=${end}&t=1`
        : `/strategy-backtesting/strategies/backtesting-data?key=${key}&t=1`
    ),
  getStrategyPerformancePreview(
    config: NewStartegyConfig
  ): Promise<AxiosResponse<{ result: IDataPerformance | null }>> {
    this.abort = new AbortController();
    return axios.post("/strategies/live-performance", config, { signal: this.abort.signal });
  },

  fecthStrategyPerformanceData(
    id: number | string,
    dateRangeQuery?: string
  ): Promise<AxiosResponse<{ result: IStrategyBase[] | IDataPerformance | null }>> {
    this.abort = new AbortController();
    return axios.get(
      `strategies/${encodeURIComponent(id)}/performance${
        typeof id === "string"
          ? "?default=true" + "&" + dateRangeQuery || ""
          : dateRangeQuery
          ? "?" + dateRangeQuery
          : ""
      }`,
      { signal: this.abort.signal }
    );
  },
  getMyStrategies: (): Promise<AxiosResponse<{ result: IStrategyData[] }>> =>
    axios.get("/strategies"),
  createStrategy: (data: INewStrategyData): Promise<AxiosResponse<IStrategyData>> =>
    axios.post("/strategies", data),
  updateStrategy: (data: INewStrategyData, id: number): Promise<AxiosResponse<object>> =>
    axios.put(`/strategies/${id}`, data),
  deleteStrategy: (id: number): Promise<AxiosResponse<object>> => axios.delete(`/strategies/${id}`),
  getStrategyPermissions: (
    id: number
  ): Promise<AxiosResponse<{ result: StrategyUserPermission[] }>> =>
    axios.get(`/strategies/${id}/permissions`),
  getTickersLIst: (tickerType: UnderlyingType): Promise<AxiosResponse<{ result: string[] }>> =>
    axios.get(`/tickers/${tickerType === "equities" ? "us-non-otc" : "etfs"}`),
  getMetricsList: (): Promise<AxiosResponse<{ result: IMetricIndicator[] }>> =>
    axios.get(`/strategies/advanced-filtering-conditions`),
  savePermissions: (
    id: number,
    data: IPermissionsData["data"]
  ): Promise<AxiosResponse<{ result: string }>> =>
    axios.post(`/strategies/${id}/permissions`, data),
  duplicateStrategy: ({
    id,
    name,
  }: IStartegyCard): Promise<AxiosResponse<{ result: IStrategyData }>> =>
    axios.post(`/strategies/${id}/duplicate`, { name }),
  changeFavoriteStatus: (config: {
    value: boolean;
    id: number;
  }): Promise<AxiosResponse<{ result: IStrategyData }>> =>
    axios.put(`/strategies/${config.id}`, {
      show_on_home: config.value,
    }),

  getLivePositions(
    id: number
  ): Promise<AxiosResponse<{ result: Record<string, number | string | null>[] }>> {
    this.abort = new AbortController();
    return axios.get(`/strategies/${id}/live-positions`, { signal: this.abort.signal });
  },
};
