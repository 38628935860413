import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, FooterLayout, Loader } from "@/components";
import { ROUTES } from "@/constants";
import { getPDF } from "@/features/Resources/components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "@/hooks";
import axios from "@/services/axios.config";
import { AdminDocumentsState, getDocuments } from "@/store/adminPanel/documents";

import { InfoHeading } from "../Heading/Heading";

import styles from "./NoAccess.module.scss";

import { ReactComponent as PermissionImage } from "@images/permissions.svg";

interface IProps {
  title: string;
}

const BROCHURE_DOC = "OdinUltra_Brochure";

export const NoAccess: FC<IProps> = ({ title }) => {
  const { documents, isLoading } = useAppSelector(AdminDocumentsState);
  const [progress, setProgress] = useState(false);
  const dispatch = useAppDispatch();

  const uploadDocument = async (name: string, id: number) => {
    await getPDF(name, id);
    setProgress(false);
  };

  const getAccessHandle = () => {
    const document = documents?.find((d) => d.key === BROCHURE_DOC);

    if (document) {
      uploadDocument(BROCHURE_DOC, document.id);
      setProgress(true);
    }
  };

  useEffect(() => {
    if (!documents || documents?.length === 0) {
      dispatch(getDocuments());
    }
  }, [documents]);

  return (
    <FooterLayout showFooter={false}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <InfoHeading>{title}</InfoHeading>
          <div className={styles.content}>
            <p className={styles.mainTitle}>Sorry, You cannot see the contents of this page.</p>
            <p className={styles.subTitle}>
              Your profile does not have access to this module of the product.
            </p>
            <p className={styles.info}>
              For further information on how to subscribe to this feature, please{" "}
              <a href="mailto:support@odinultra.ai">contact us.</a>
            </p>
            <div className={styles.buttonWrapper}>
              {isLoading || progress ? (
                <Loader />
              ) : (
                <Button text="Learn More" type="secondary" onClick={getAccessHandle} />
              )}
            </div>
            <PermissionImage />
          </div>
        </div>
      </div>
    </FooterLayout>
  );
};
