import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { userSeesion } from "@/constants";
import { authService, UserService } from "@/services";
import { IUserLogin } from "@/types";
import { validate } from "@/utils";
import { pagePermission } from "@/utils/pagesPermissions";

type FormType = Record<string, { value: string; error: string | null }>;
const DELAY = 500;

export const useForm = (formInit: FormType) => {
  const [form, setForm] = useState(formInit);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLogined, setIsLogined] = useState(false);
  const [isUserDeactivated, setDeactivatedUser] = useState(false);
  const navigate = useNavigate();

  const loginUser = async (userLogin: IUserLogin) => {
    try {
      const { data } = await UserService.userLogin(userLogin);
      authService.saveTokens(data.access, data.refresh);
      setForm((prev) => ({ ...prev, password: { value: "", error: null } }));
      setTimeout(() => setIsLogined(true), DELAY);
      userSeesion.setUser(userLogin.email);
    } catch (err) {
      const error = err as AxiosError<{ error: any }>;
      const errorCode = error?.response?.data?.error?.error_code;
      setForm((prev) => ({
        ...prev,
        password: {
          value: "",
          error: "Login or Password invalid! Try again.",
        },
      }));
      if (errorCode === 156) setDeactivatedUser(true);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { login, password } = form;
    const pass = password.value.trim();
    const userlogin = login.value.trim();
    if (showPass && pass && login) {
      const [validatedForm, isValid] = validate(form, "password");
      if (isValid) {
        setLoading(true);
        loginUser({ email: userlogin, password: pass });
      } else {
        setForm(validatedForm);
      }
    }
    if (!showPass && userlogin) {
      const [validatedForm, isValid] = validate(form, "login");
      if (isValid) setShowPass(true);
      else setForm(validatedForm);
    }
  };

  const deniedSubmitHandler = () => {
    navigate("/");
  };

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    const fieldKey = ev.target.name;
    setForm((prev) => ({
      ...prev,
      [fieldKey]: { error: null, value: inputValue },
    }));
  }, []);

  const resetForm = () => {
    setShowPass(false);
  };

  useEffect(() => {
    const homePath = pagePermission.setDefaultPath(userSeesion.getUser());
    if (isLogined) navigate(homePath, { replace: true });
  }, [isLogined]);

  return {
    form,
    showPass,
    loading,
    isLogined,
    isUserDeactivated,
    deniedSubmitHandler,
    submitHandler,
    changeHandler,
    resetForm,
  };
};
