export const Errors = {
  data: {
    get: "Sorry! Something went wrong during getting data",
  },
  create: {
    startegy: "Sorry! Something went wrong during Creating new strategy.",
  },
  update: {
    strategy: "Sorry! Something went wrong during Updating strategy.",
    permissions: "Sorry! Something went wrong during Updating user permissions.",
  },
  delete: {
    strategy: "Sorry! Something went wrong during Delete strategy.",
  },
  copy: {
    strategy: "Sorry! Something went wrong during copy strategy.",
  },
  preview: {
    strategy: "Sorry! Something went wrong during performance preview.",
  },
};
