import { useEffect, useRef, useState } from "react";

import { debounce } from "@/utils";

interface IDetectEmptySpaceOptions {
  count: number;
  visibleRows: number;
  itemHeight: number;
  scrollContainerID?: string;
  bottomOffset?: number;
}

const BOTTOM_OFFSET = 50;

export const useDetectEmptySpace = ({
  count,
  visibleRows,
  itemHeight,
  scrollContainerID = "#main-scroll-layout",
  bottomOffset = BOTTOM_OFFSET,
}: IDetectEmptySpaceOptions) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [isListOverflow, setIsListOverflow] = useState(false);

  const checkPosition = () => {
    if (listRef.current) {
      const { bottom } = listRef.current.getBoundingClientRect();
      const height = (count > visibleRows ? visibleRows : count) * itemHeight;
      const isOverflow = height + bottom + bottomOffset >= window.innerHeight;

      setIsListOverflow(isOverflow);
    }
  };

  useEffect(() => {
    const scrollHandler = debounce(() => {
      checkPosition();
    }, 100);

    const scrollContainer = document.querySelector(scrollContainerID);
    scrollContainer?.addEventListener("scroll", scrollHandler);
    scrollHandler();
    return () => {
      scrollContainer?.removeEventListener("scroll", scrollHandler);
    };
  }, [count]);

  return {
    listRef,
    isListOverflow,
  };
};
