import { useCallback, useEffect, useRef, useState } from "react";

import { INavState } from "@/types";
import { debounce } from "@/utils";

const defaultNavState = { left: false, right: false, isScrolling: false };

export const useScrollNavigation = <T extends Array<unknown>>(
  list: T | null,
  isDisabled = false
) => {
  const scrollItemRef = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef(0);
  const [navState, setNavState] = useState<INavState>(defaultNavState);

  useEffect(() => {
    const container = scrollItemRef.current;
    const element = elementRef.current;
    let isMobile = false;

    const debounsedScrollState = debounce(() => {
      setNavState((prev) => ({ ...prev, isScrolling: false }));
    }, 300);

    const mouseRollHandler = (event: WheelEvent) => {
      event.preventDefault();
      let scroll = scrollRef.current;
      const scrollWidth = container?.scrollWidth ? container.scrollWidth : 0;
      const containerWidth = container?.clientWidth || 0;
      const elemntWidth = element?.clientWidth || 0;
      if (!isMobile) {
        if (event.deltaY > 0) {
          if (scrollWidth && scroll < scrollWidth - containerWidth) {
            scroll = scroll + elemntWidth;
            setNavState({ left: true, right: true, isScrolling: true });
          }
          if (scrollWidth && scroll > scrollWidth - containerWidth) {
            scroll = scrollWidth - containerWidth;
            setNavState({ left: true, right: false, isScrolling: true });
          }
          container?.setAttribute("style", `transform: translate(${-scroll}px)`);
          debounsedScrollState();
        }
        if (event.deltaY < 0) {
          if (scrollWidth && scroll >= elemntWidth) {
            scroll = scroll - elemntWidth;
            setNavState({ left: true, right: true, isScrolling: true });
          }
          if (scrollWidth && scroll < elemntWidth) {
            scroll = 0;
            setNavState({ left: false, right: true, isScrolling: true });
          }
          container?.setAttribute("style", `transform: translate(${-scroll}px)`);
          debounsedScrollState();
        }
        scrollRef.current = scroll;
      }
    };

    const resizeHandler = debounce(() => {
      scrollRef.current = 0;
      container?.setAttribute("style", `transform: translate(${scrollRef.current}px)`);
      isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
      if (isMobile) {
        scrollItemRef.current?.classList.add("scrollMobile");
      } else {
        scrollItemRef.current?.classList.remove("scrollMobile");
      }
      if (
        scrollItemRef.current &&
        scrollItemRef.current.scrollWidth > scrollItemRef.current.clientWidth
      )
        setNavState({ left: false, right: true, isScrolling: false });
    }, 100);

    resizeHandler();
    if (!isDisabled) scrollItemRef.current?.addEventListener("wheel", mouseRollHandler);
    window.addEventListener("resize", resizeHandler);

    return () => {
      scrollItemRef.current?.removeEventListener("wheel", mouseRollHandler);
      window.removeEventListener("resize", resizeHandler);
    };
  }, [list, isDisabled]);

  const toLeftHandler = useCallback(() => {
    if (
      elementRef.current &&
      scrollItemRef.current &&
      scrollRef.current - elementRef.current.clientWidth > 0
    ) {
      scrollRef.current = scrollRef.current - elementRef.current.clientWidth;
      setNavState((prev) => ({ ...prev, left: true, right: true }));
    } else {
      scrollRef.current = 0;
      setNavState((prev) => ({ ...prev, right: true, left: false }));
    }
    scrollItemRef.current?.setAttribute("style", `transform: translate(${-scrollRef.current}px)`);
  }, []);

  const toRightHandler = useCallback(() => {
    if (
      elementRef.current &&
      scrollItemRef.current &&
      scrollRef.current + elementRef.current.clientWidth <
        scrollItemRef.current?.scrollWidth - scrollItemRef.current.clientWidth
    ) {
      scrollRef.current = scrollRef.current + elementRef.current.clientWidth;
      setNavState((prev) => ({ ...prev, left: true, right: true }));
    } else {
      scrollRef.current =
        (scrollItemRef.current?.scrollWidth || 0) - (scrollItemRef.current?.clientWidth || 0);
      setNavState((prev) => ({ ...prev, left: true, right: false }));
    }
    scrollItemRef.current?.setAttribute("style", `transform: translate(${-scrollRef.current}px)`);
  }, []);

  const callbackElementRef = useCallback((element: HTMLDivElement | null, idx: number) => {
    if (idx === 0 && element) elementRef.current = element;
  }, []);

  return {
    elementRef: callbackElementRef,
    toRightHandler,
    toLeftHandler,
    scrollItemRef,
    scrollRef,
    navState,
  };
};
