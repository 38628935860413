import { FC, useMemo } from "react";

import { SortArrows } from "@/components";
import { DirrectionType } from "@/types";

import styles from "./Sorter.module.css";

interface ISorterProps {
  dirrection: DirrectionType;
  onChange: () => void;
}

export const Sorter: FC<ISorterProps> = ({ onChange, dirrection }) => {
  const tooltipText = useMemo(() => {
    if (dirrection === "asc") return "Click to sort descend.";
    if (dirrection === "desc") return "Click to sort default.";
    return "Click to sort ascend.";
  }, [dirrection]);
  return (
    // <Tooltip title={tooltipText} placement="top">
    <div className={styles.arrowsContainer} onClick={onChange}>
      {/* <div className={`${styles.arrowItem} ${dirrection === "asc" ? styles.active : ""}`} />
      <div
        className={`${styles.arrowItem} ${styles.rotated} ${
          dirrection === "desc" ? styles.active : ""
        }`}
      /> */}
      <SortArrows
        isSortable
        isSorting={dirrection !== "default"}
        isDescDir={dirrection === "desc"}
      />
    </div>
    // </Tooltip>
  );
};
