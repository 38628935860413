import React, { FC, Fragment, memo, useRef, useState } from "react";

import { Switch, TooltipWrapper } from "@/components";
import { operatorsList, customOperatorsList } from "@/constants";
import { useOuterClick } from "@/hooks";
import { IndicatorOperatorType } from "@/store/strategies/customIndicator";

import styles from "./ConstructorItem.module.scss";

import { ReactComponent as DeleteIcon } from "@images/clear.svg";

interface IEmptyItemProps<U> {
  onSelect: (item: string, operator?: U) => void;
  onDelete?: (operator: U) => void;
  onTickerSelect?: (operator: U) => void;
  onChangeValue: (value: string, index: number) => void;
  onCheck?: (value: boolean) => void;
  operator?: U;
  isEmpty?: boolean;
  isChecked?: boolean;
  disableList?: boolean[];
  info?: string;
  isCustom?: boolean;
}

const DROPDOWN_LIST_WIDTH = 216;
const OFFSET_LEFT = 12;

function Constructor<U extends IndicatorOperatorType>({
  onSelect,
  onDelete,
  operator,
  onTickerSelect,
  isEmpty,
  onCheck,
  isChecked,
  onChangeValue,
  disableList,
  info,
  isCustom,
}: IEmptyItemProps<U>) {
  const [toggle, setToggle] = useState(false);

  const listRef = useRef<HTMLDivElement | null>(null);
  const triangleRef = useRef<HTMLDivElement | null>(null);

  const toggleHandler = (opeartor?: IndicatorOperatorType) => {
    if (!(opeartor && opeartor.isUnerasable) || (opeartor.isUnerasable && opeartor.value === ")")) {
      setToggle(true);

      const table = document.getElementById("constructor-table");
      if (listRef.current && itemRef.current && table) {
        if (itemRef.current.offsetLeft + DROPDOWN_LIST_WIDTH > table.clientWidth) {
          const offsetLeft = itemRef.current.offsetLeft + DROPDOWN_LIST_WIDTH - table.clientWidth;
          listRef.current?.setAttribute("style", `left: ${-offsetLeft}px;`);
          triangleRef.current?.setAttribute("style", `left: ${OFFSET_LEFT + offsetLeft}px;`);
        } else {
          listRef.current?.setAttribute("style", "left: 0;");
        }
      }
      if (onTickerSelect && operator) onTickerSelect(operator);
    }
  };

  const selectHandler = (item: string, operator?: U) => {
    if (
      item === "Number" &&
      disableList &&
      disableList[operator ? operator.index : disableList.length - 1] === false
    )
      return false;

    setToggle(false);
    onSelect(item, operator);
  };

  const deleteHandler = (ev: React.MouseEvent) => {
    if (onDelete && operator) {
      ev.stopPropagation();
      onDelete(operator);
      setToggle(false);
    }
  };

  const [itemRef] = useOuterClick(setToggle, false);

  const calculateDisbaleStyle = (idx: number) => {
    const index = !!(disableList && disableList.length - 1 >= 0);
    return disableList &&
      index &&
      !disableList[operator ? operator.index : disableList.length - 1] &&
      idx === 0
      ? styles.disableNumber
      : "";
  };

  const calculateStyle = (item: string) => {
    if (item === "TRUE" || item === "FALSE") return styles.inputBooleanItem;
    if (item === "Number") return styles.inputNumberItem;
    return "";
  };

  const canPastLeft = operator?.isUnerasable && operator.value === ")";

  return (
    <div
      className={`${styles.container} ${
        operator ? (operator.isOperator ? styles.operator : styles.ticker) : ""
      } ${operator?.isNumber ? styles.inputNumber : ""} ${
        ["TRUE", "FALSE"].includes(operator?.value || "") ? styles.booleanType : ""
      } ${operator?.isUnerasable ? styles.uneraseble : ""} ${canPastLeft ? styles.canPast : ""}`}
      ref={itemRef}
    >
      {info ? (
        <TooltipWrapper text={info}>
          <Operator
            operator={operator}
            toggle={toggle}
            onToggle={toggleHandler}
            onDelete={deleteHandler}
            onChangeValue={onChangeValue}
            isUnerasable={operator?.isUnerasable}
          />
        </TooltipWrapper>
      ) : (
        <Operator
          operator={operator}
          toggle={toggle}
          onToggle={toggleHandler}
          onDelete={deleteHandler}
          onChangeValue={onChangeValue}
          isUnerasable={operator?.isUnerasable}
        />
      )}
      {
        <div className={`${styles.listContainer} ${toggle ? styles.open : ""}`} ref={listRef}>
          <div className={styles.triangle} ref={triangleRef}></div>
          <div className={styles.listInner}>
            <div
              className={`${styles.wrapper} ${isChecked && !canPastLeft ? styles.replacable : ""}`}
            >
              {(isCustom ? customOperatorsList : operatorsList).map((item, idx) => (
                <div
                  className={`${styles.item} ${calculateStyle(item)} ${calculateDisbaleStyle(idx)}`}
                  key={`item-${idx}`}
                  data-type={item}
                  onClick={() => selectHandler(item, operator)}
                >
                  {item}
                </div>
              ))}
            </div>
            {!isEmpty && !operator?.isUnerasable && (
              <div className={styles.footer}>
                <Switch
                  label={isChecked ? "Replace Mode" : "Insert Mode"}
                  onChange={onCheck}
                  checked={isChecked || false}
                  color={isChecked ? "var(--error)" : "var(--secondary)"}
                />
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
}

export const ConstructorItem = memo(Constructor);

interface IOperatorProps {
  operator?: IndicatorOperatorType;
  onToggle: (operator?: IndicatorOperatorType) => void;
  onDelete: (ev: React.MouseEvent) => void;
  toggle: boolean;
  onChangeValue: (value: string, index: number) => void;
  isUnerasable?: boolean;
}

const Operator: FC<IOperatorProps> = ({
  operator,
  onToggle,
  toggle,
  onDelete,
  onChangeValue,
  isUnerasable,
}) => {
  return (
    <Fragment>
      {!operator && (
        <div
          className={`${styles.emptyInner} ${toggle ? styles.active : ""}`}
          onClick={() => onToggle(operator)}
        >
          +
        </div>
      )}
      {operator && (
        <div
          className={`${styles.operatorInner} ${toggle ? styles.active : ""}`}
          onClick={() => onToggle(operator)}
        >
          {operator.isOperator ? (
            <Fragment>
              {operator.isNumber ? (
                <div className={styles.inputNumberWrapper}>
                  <input
                    onChange={(ev) => onChangeValue(ev.currentTarget.value, operator.index)}
                    placeholder="Number value"
                    type="number"
                    value={operator.numValue}
                  />
                </div>
              ) : (
                operator.value
              )}
            </Fragment>
          ) : (
            <div
              className={`${
                operator?.type === "function-operator" ? styles.functionOperator : ""
              } ${styles.tickerText}`}
            >
              {operator.value}
            </div>
          )}
          {!isUnerasable && (
            <div className={styles.iconContainer} onClick={onDelete}>
              <DeleteIcon />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
