import { FC, useRef } from "react";

import { Button } from "@/components";

import styles from "./UploadDataWithInfo.module.scss";

import { ReactComponent as TableHelpIcon } from "@images/table-2.svg";

interface IProps {
  title: string;
  helpText: string[];
  helpTitle: string;
  disabled?: boolean;
  onUpload: (files: FileList | null) => void;
}

export const UploadDataWithInfo: FC<IProps> = ({
  helpText,
  title,
  helpTitle,
  disabled,
  onUpload,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadHandler = () => {
    onUpload(fileInputRef.current?.files || null);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };
  const uploadClickHandler = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.iconWrapper}>
          <TableHelpIcon />
        </div>
        <ul className={styles.list}>
          <li className={styles.text}>{helpTitle}</li>
          {helpText.map((text, idx) => (
            <li className={styles.text} key={idx}>
              {text}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.rightSide}>
        <p className={styles.title}>{title}</p>
        <div className={styles.btnWrapper}>
          <Button
            text="Upload CSV"
            type="primary"
            disabled={disabled}
            onClick={uploadClickHandler}
          />
        </div>
      </div>
      <input
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={uploadHandler}
        accept=".csv"
      />
    </div>
  );
};
