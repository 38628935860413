import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { ReportService } from "@/services";
import { IUserReport } from "@/types/report";

import { RootState } from "./index";
import { IStrategyBase } from "../types/strategies";

interface IUserReportData {
  isLoading: boolean;
  requested: boolean;
  results: any[];
  pages: number;
}

const initialState: IUserReportData = {
  results: [],
  pages: 1,
  isLoading: false,
  requested: false,
};

export const fetchUserReport = createAsyncThunk(
  "userReport/fetch",
  async ({ email, date_from, date_to, page, ordering_column, ordering, per_page }: IUserReport) => {
    const res = await ReportService.getUserReport({
      email,
      date_from,
      date_to,
      page,
      ordering_column,
      ordering,
      per_page,
    });
    return res?.data.result;
  }
);

export const userReportSlice = createSlice({
  name: "userReport",
  initialState,
  reducers: {
    resetResult: (state) => {
      state.results = initialState.results;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserReport.fulfilled, (state, action) => {
        state.requested = true;
        state.results = action.payload.data;
        state.pages = action.payload.pages;
        state.isLoading = false;
      })
      .addCase(fetchUserReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserReport.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error.message);
      });
  },
});

export const { resetResult } = userReportSlice.actions;

const state = (state: RootState) => state;

export const userReportState = createSelector(state, (state) => state.userReport);

export default userReportSlice.reducer;
