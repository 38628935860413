import { FC, Fragment, memo, useRef } from "react";

import { usePagination } from "./hooks/usePagination";

import styles from "./Pagination.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";
import { ReactComponent as ArrowsGroupIcon } from "@images/arrows-group.svg";
import { ReactComponent as DotsIcon } from "@images/dots.svg";

interface IProps {
  onPage: (page: number) => void;
  onChangePerPage: (value: number) => void;
  onGetMore?: () => void;
  perPage: number;
  currentPage: number;
  perPageList: number[];
  totalCount: number | null;
  count: number;
  showTotal?: boolean;
  disabled?: boolean;
  countLabel?: string;
  hasMoreItems?: boolean;
}

export const Pagination: FC<IProps> = memo(
  ({
    onPage,
    onChangePerPage,
    onGetMore,
    perPage,
    currentPage,
    totalCount,
    count,
    perPageList,
    showTotal = true,
    disabled,
    countLabel,
    hasMoreItems,
  }) => {
    const {
      leftSidePagesList,
      rightSidePagesList,
      middleSidePagesList,
      prevPageHandler,
      nextPageHandler,
      selectPageHandler,
      prevHandle,
      nextHandle,
      calcWidth,
    } = usePagination({ onPage, currentPage, totalCount, perPage, count });

    if (totalCount === 0 || count === 0) return null;

    return (
      <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
        <div className={styles.inner}>
          <PerPage perPage={perPage} perPageList={perPageList} onSelect={onChangePerPage} />
          <div className={styles.pagesWrapper}>
            <PrevPageIcon onClick={prevPageHandler} disabled={currentPage === 1} />
            <ul className={styles.pagesList}>
              {leftSidePagesList.map((page) => (
                <li
                  className={`${styles.pageItem} ${
                    currentPage.toString() === page ? styles.active : ""
                  }`}
                  key={page}
                  title={page}
                  onClick={() => selectPageHandler(page)}
                  style={{ width: calcWidth(page) }}
                >
                  {page}
                </li>
              ))}
              <ArrowsGroupNav
                dir="left"
                onClick={prevHandle}
                show={leftSidePagesList.length === 1 && !!totalCount && totalCount > perPage}
              />
              {middleSidePagesList.map((page) => (
                <li
                  className={`${styles.pageItem} ${
                    currentPage.toString() === page ? styles.active : ""
                  }`}
                  key={page}
                  title={page}
                  onClick={() => selectPageHandler(page)}
                  style={{ width: calcWidth(page) }}
                >
                  {page}
                </li>
              ))}
              <ArrowsGroupNav
                dir="right"
                onClick={nextHandle}
                show={rightSidePagesList.length === 1}
              />
              {rightSidePagesList.map((page) => (
                <li
                  className={`${styles.pageItem} ${
                    currentPage.toString() === page ? styles.active : ""
                  }`}
                  key={page}
                  title={page}
                  onClick={() => selectPageHandler(page)}
                  style={{ width: calcWidth(page) }}
                >
                  {page}
                </li>
              ))}
            </ul>
            {hasMoreItems ? (
              <p className={styles.moreItem} onClick={onGetMore}>
                Get More
              </p>
            ) : null}
            <NextPageIcon onClick={nextPageHandler} disabled={currentPage === totalCount} />
          </div>
          {count && showTotal && (
            <div className={styles.footerInfo}>
              <span>{countLabel || "Total Templates"}:</span>
              <span>{count}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
);

interface IPageIcon {
  onClick: () => void;
  disabled: boolean;
}

export const PrevPageIcon: FC<IPageIcon> = ({ onClick, disabled }) => {
  return (
    <div
      className={`${styles.iconContainer} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
      title="Previous page"
    >
      <Arrow style={{ transform: "rotate(-90deg)" }} />
    </div>
  );
};

export const NextPageIcon: FC<IPageIcon> = ({ onClick, disabled }) => {
  return (
    <div
      className={`${styles.iconContainer} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
      title="Next page"
    >
      <Arrow style={{ transform: "rotate(90deg)" }} />
    </div>
  );
};

interface IArrowsGroupNavProps {
  dir: "left" | "right";
  show: boolean;
  onClick: () => void;
}

export const ArrowsGroupNav: FC<IArrowsGroupNavProps> = ({
  dir = "left",
  show = true,
  onClick,
}) => {
  if (!show) return null;
  return (
    <Fragment>
      {dir === "left" ? (
        <li className={styles.navGroupArrows} onClick={onClick}>
          <ArrowsGroupIcon style={{ transform: "rotate(-180deg)" }} title="Previous 5" />
          <DotsIcon />
        </li>
      ) : (
        <li className={styles.navGroupArrows} onClick={onClick} title="Next 5">
          <ArrowsGroupIcon />
          <DotsIcon />
        </li>
      )}
    </Fragment>
  );
};

interface IPerPageProps {
  perPage: number;
  perPageList: number[];
  onSelect: (value: number) => void;
}

export const PerPage: FC<IPerPageProps> = ({ perPage, perPageList, onSelect }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const selectHandle = (item: number) => {
    onSelect(item);
    itemRef.current?.setAttribute("style", "height: 0");
    setTimeout(() => {
      itemRef.current?.setAttribute("style", "height: auto");
    }, 300);
  };
  return (
    <div className={styles.perPageWrapper}>
      <p className={styles.perPageText}>Per page:</p>
      <p className={styles.perPageCount}>{perPage}</p>
      <div className={styles.listContainer} ref={itemRef}>
        <ul>
          {perPageList.map((item) => (
            <li
              key={item}
              onClick={() => selectHandle(item)}
              className={perPage === item ? styles.active : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
