import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";

import { ICompany, ICompanyPayload } from "@/types/company";
import { notification } from "@/utils/notification";

import { RootState } from ".";
import { CompanyService } from "../services/companyService";

export interface IUsersState {
  isLoading: boolean;
  companies: ICompany[] | null;
}

const initialState: IUsersState = {
  isLoading: false,
  companies: null,
};

export const fetchCompanies = createAsyncThunk("company/fetchCompanies", async () => {
  const companies = await CompanyService.getCompanies();
  return companies.data;
});

export const editCompanies = createAsyncThunk(
  "company/editCompany",
  async ({ id, data }: { id: number; data: ICompanyPayload }) => {
    const company = await CompanyService.updateCompany(id, data);
    return company.data;
  }
);

export const createCompanies = createAsyncThunk(
  "company/createCompany",
  async (data: ICompanyPayload) => {
    const company = await CompanyService.createCompany(data);
    return company.data;
  }
);

export const ProfileSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(editCompanies.fulfilled, (state, action) => {
        state.companies =
          state.companies?.map((company) => {
            if (company.id === action.payload.id) {
              return action.payload;
            }
            return company;
          }) || null;
        state.isLoading = false;
        notification.success("Updated!");
      })
      .addCase(editCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCompanies.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(createCompanies.fulfilled, (state, action) => {
        state.companies?.push(action.payload);
        state.isLoading = false;
        notification.success("Created!");
      })
      .addCase(createCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCompanies.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      });
  },
});

const state = (state: RootState) => state;

export const companiesState = createSelector(state, (state) => state.companies);

export default ProfileSlice.reducer;
