import { FC, Fragment, useCallback, useEffect, useMemo } from "react";

import { LoaderWrapper } from "@/components";
import { paginator } from "@/constants";
import { MyStrategies } from "@/features/StrategiesDashboard";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { selectHome, setHomePage, setHomePerPage } from "@/store/home";
import { profileInfo } from "@/store/profile";
import { fetchMyStrategiesData, selectMyStrategies } from "@/store/strategies/my_strategies";
import { prepareStrategiesList } from "@/utils";

interface IProps {
  placeholderComponent: () => JSX.Element;
}

export const FavoriteStrategise: FC<IProps> = ({ placeholderComponent }) => {
  const { page, perPage } = useAppSelector(selectHome);

  const { list, isLoading } = useAppSelector(selectMyStrategies);
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  const strategiesList = useMemo(() => {
    const myStartegiesList =
      list && profile && list.filter((item) => item.user_id === profile.id && item.show_on_home);
    return myStartegiesList ? prepareStrategiesList(myStartegiesList) : [];
  }, [list, profile]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setHomePage({ strategies: page }));
  }, []);

  const selectPerPageHandler = useCallback((perpage: number) => {
    dispatch(setHomePage({ strategies: 1 }));
    dispatch(setHomePerPage({ strategies: perpage }));
  }, []);

  const paginatorHome = useMemo(() => {
    return {
      page: page.strategies,
      perPage: perPage.strategies,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageHomeList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  const PlaceHolder = placeholderComponent;

  useEffect(() => {
    if (!list) {
      dispatch(fetchMyStrategiesData());
    }
  }, []);

  return (
    <Fragment>
      {list === null || strategiesList.length > 0 ? (
        <LoaderWrapper loading={isLoading} atom fullWidth>
          <MyStrategies strategiesList={strategiesList} paginator={paginatorHome} isFavorites />
        </LoaderWrapper>
      ) : (
        <PlaceHolder />
      )}
    </Fragment>
  );
};
