import { FC } from "react";

import { ReactComponent as HandShakeIcon } from "@images/icons/shake-hands.svg";

import styles from "./MessageDialog.module.scss";

interface IMessageContent {
  message: string | null;
  title?: string;
  footnote?: string;
  img?: string;
  themInverse?: boolean;
}
export const MessageContent: FC<IMessageContent> = ({
  message,
  title = "Thank you for submission!",
  footnote = "You can check your inbox for the report shortly.",
  img = HandShakeIcon,
  themInverse = false,
}) => {
  if (message === null) return null;
  return (
    <div className={`${styles.container} ${themInverse ? styles.inverse : ""}`}>
      <div className={styles.messageContent}>
        <h1 className={styles.title}>{title}</h1>
        <HandShakeIcon className={styles.image} title="shake-hands" />
        <p className={styles.text}>{message}</p>
        <p className={styles.footnote}>{footnote}</p>
      </div>
    </div>
  );
};
