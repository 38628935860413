/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import Highcharts, { AxisCrosshairOptions } from "highcharts";
import HC_boost from "highcharts/modules/boost";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useRef, useState } from "react";

import {
  AXIS_MAIN_COLOR,
  AXIS_PREVIEW_COLOR,
  COLORS,
  DATE_ISO_US,
  DATE_ISO_US_SHORT,
} from "@/constants";

import { exportingOptions } from "./utils";
import { Button } from "../Button/Button";

HC_boost(Highcharts);
HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

interface LineChartProps {
  ticker?: string;
  data?: any[];
  dates?: string[];
  isQuarter?: boolean;
  height?: number;
  toggleButtonEnambled?: boolean;
  legendEnabled?: boolean;
  exporting?: boolean;
  isRevers?: boolean;
  isChartPreviw?: boolean;
}
export const DiscoverChart: FC<LineChartProps> = ({
  ticker,
  data,
  isQuarter,
  height = 500,
  toggleButtonEnambled = true,
  legendEnabled = true,
  exporting = true,
  isRevers = true,
  isChartPreviw,
}) => {
  const ref = useRef<HighchartsReact.RefObject | null>(null);
  const crossHair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };
  const lineColor = isChartPreviw ? AXIS_PREVIEW_COLOR : AXIS_MAIN_COLOR;
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      xDateFormat: "%b \n %e \n %Y",
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    chart: {
      //@ts-ignore
      zoomType: "x",
      type: "spline",
      marginTop: isChartPreviw ? 5 : 35,
      style: {
        fontFamily: "Inter",
      },
      animation: false,
      width: undefined,
    },
    plotOptions: {
      series: {
        boostThreshold: 5000, // Adjust the threshold as needed
        animation: false,
        lineWidth: 1.5,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: isChartPreviw ? false : legendEnabled,
      useHTML: true,
      layout: "horizontal", // default
      itemDistance: 50,
      maxHeight: 100,
    },
    exporting: {
      enabled: isChartPreviw ? false : exporting,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "separator",
            "downloadCSV",
          ],
        },
      },
      ...exportingOptions(),
    },
    colors: COLORS,

    yAxis: {
      title: {
        style: {
          fontSize: "12px",
        },
        margin: isChartPreviw ? 0 : undefined,
        x: isChartPreviw ? -10 : 0,
      },
      labels: {
        enabled: isChartPreviw ? false : true,
      },
      gridLineWidth: 0,
      lineWidth: 1,
      crosshair: crossHair,
      lineColor,
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: isChartPreviw ? "Date" : "",
      },
      type: "category",
      labels: {
        formatter(): string {
          const date = dayjs(this.value);
          return isQuarter
            ? `F${date.format("Q")}Q${date.format("YY")}`
            : date.format(DATE_ISO_US_SHORT);
        },
        enabled: isChartPreviw ? false : true,
      },
      lineColor,
    },
  });

  useEffect(() => {
    const chart = ref.current?.chart;

    if (chart) {
      chart.showLoading();

      // Create an empty series to hold the data points
      const series = chart.addSeries(
        {
          name: "Data",
          data: [],
          type: "spline",
        },
        false
      );

      // Add data points in chunks
      const chunkSize = 500; // Adjust the chunk size as needed
      const totalPoints = data?.length || 0;

      let i = 0;
      let addedPoints = 0;

      const addDataPoints = () => {
        const start = performance.now(); // Record the start time

        while (i < totalPoints && addedPoints < chunkSize) {
          const point = data?.[i];
          const formattedDate = dayjs(point.date).format(DATE_ISO_US);
          series.addPoint([formattedDate, point.value], false);
          i++;
          addedPoints++;

          // Check if time limit (16ms) for each frame is reached
          if (performance.now() - start > 16) {
            // Schedule the next chunk of data to be added in the next frame
            requestAnimationFrame(addDataPoints);
            return;
          }
        }

        if (i < totalPoints) {
          // Schedule the next chunk of data to be added in the next frame
          requestAnimationFrame(addDataPoints);
        } else {
          // All data points added, redraw the chart
          chart.redraw();
          chart.hideLoading();
        }
      };

      // Start adding data points
      addDataPoints();
    }
    setChartOptions((state) => ({
      ...state,
      yAxis: {
        title: {
          text: isChartPreviw ? "VALUE" : ticker || "",
        },
        enabled: false,
      },
      series: data?.map((serie, idx) => ({
        ...serie,
        data: isRevers ? [...data[idx].data].reverse() : [...data[idx].data],
      })),
    }));
  }, [data, ticker]);

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height } }}
      />
      {toggleButtonEnambled && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px" }}>
          <div>
            <Button
              onClick={() => {
                const chart = ref?.current?.chart;
                chart?.series.forEach(function (series) {
                  if (series.visible) {
                    series.setVisible(false, false);
                  } else {
                    series.setVisible(true, false);
                  }
                });
                chart?.redraw();
              }}
              text="Toggle All"
            />
          </div>
        </div>
      )}
    </div>
  );
};
