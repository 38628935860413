import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, FooterLayout, Heading } from "@/components";
import { authService } from "@/services";

import styles from "./TermsConditions.module.scss";

interface IProps {
  autoscroll?: boolean;
}

export const Disclosures: FC<IProps> = ({ autoscroll }) => {
  const isUserLogined = authService.isLogined();
  const navigate = useNavigate();

  return (
    <FooterLayout showFooter={false} autoscroll={autoscroll}>
      <div className={`${styles.container} ${isUserLogined ? "" : styles.blackTheme}`}>
        <Heading type="h1" className={styles.heading}>
          Disclosures
        </Heading>
        <div className={styles.innerContent}>
          <p>
            Welcome to the www.OdinUltra.ai and www.OdinUltra.com (the “Website”) for OdinUltra.
            This Website provides you with general information about OdinUltra.
          </p>
          <br />
          <p>
            <b>PLEASE READ THESE TERMS OF USE CAREFULLY.</b> By accessing and using this Website,
            you acknowledge that you have read, understand, and agree to be bound by all of the
            terms and conditions set forth below. We reserve the right to amend these terms of use
            from time to time by posting the amended terms on this Website.
          </p>
          <br />
          <p>
            <b>Access to the Website:</b> The Website is made available to all persons aged 18 or
            older who agree to be bound by the terms and conditions set forth in these terms of use.
            You agree not to modify, reproduce, distribute, rent, lease, loan, sell, or create
            derivative works based upon the materials on this Website, or post any content from this
            Website to newsgroups, mail lists or electronic bulletin boards, without the prior
            written consent of an authorized representative of OdinUltra.
          </p>
          <br />
          <p>
            <b>No Offer, Investment Advice, or Performance Guarantees:</b> Neither the information
            nor any opinion expressed on this Website constitutes an offer by OdinUltra to buy or
            sell any securities or financial instruments, or to provide any investment advice or
            service. The services, securities and financial instruments described on this Website
            may not be available to or suitable for you, and not all strategies are always
            appropriate. The value and income of any of the securities or financial instruments
            mentioned on this Website can fall as well as rise, and an investor may get back less
            than he or she invested. Foreign-currency denominated securities and financial
            instruments are subject to fluctuations in exchange rates that could have a positive, or
            adverse, effect on the value, price or income of such securities and financial
            instruments. Past performance is not necessarily a guide to future performance.
            Independent advice should be sought in all cases.
          </p>
          <br />
          <p>
            <b>Responsible Behavior:</b> In addition to the obligations set forth above, you agree
            not to access or use this Website to: a) reproduce, duplicate, copy, sell, trade, resell
            or exploit for any commercial purposes, any portion of this Website, use of this
            Website, or access to this Website; b) impersonate any person or entity or falsely state
            or otherwise misrepresent your affiliation with a person or entity; c) forge headers or
            otherwise manipulate identifiers in order to disguise the origin of any content
            transmitted on this Website; d) make available any content that you do not have a right
            to make available under any law or under contractual or fiduciary relationships; e) make
            available any content that infringes any patent, trademark, trade secret, copyright or
            other proprietary rights of any party; f) make available any content that is unlawful,
            harmful, threatening, tortious, defamatory, obscene, invasive of another's privacy, or
            otherwise objectionable; g) make available any unsolicited or unauthorized advertising,
            promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any
            other form of solicitation; h) make available any material that contains software
            viruses or any other computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer software or hardware or telecommunications
            equipment; i) interfere with or disrupt this Website or servers or networks connected to
            this Website, or disobey any requirements, procedures, policies or regulations of
            networks connected to this Website; j) intercept, collect or store personal data about
            current or former clients of OdinUltra or any other visitors to this Website; or k)
            violate any applicable local, state, national or international laws or regulations.
          </p>
          <br />
          <p>
            <b>Links to Third Party Websites:</b> This Website may contain links to other Websites,
            including links to the Websites of companies that provide related information, products
            and services. These links are solely for the convenience of visitors to this Website,
            and the inclusion of such links does not necessarily imply an affiliation, sponsorship
            or endorsement. OdinUltra makes no representations whatsoever regarding such third party
            Websites. You should review the privacy policies of other Websites carefully before
            providing any information to such Websites. We are not responsible for the content or
            availability of third party Websites, and shall not be responsible or liable for any
            information, opinions, advice, products, or services available on or through such third
            party Websites.
          </p>
          <br />
          <p>
            <p>Links to This Website:</p> We ask that you notify us in advance of your intention to
            link to the public portion of this Website by emailing us at
            legalnotices@bigdatafed.com. You may not (i) hyperlink to the private portion of this
            Website or any of the pages on the private portion of this Website from another Website,
            (ii) display this Website in frames, or (iii) display any of the content found on this
            Website via inline links, without express written permission from an authorized
            representative of OdinUltra.
          </p>
          <br />
          <p>
            <b>Our Rights in This Website:</b> OdinUltra and our suppliers reserve all right, title
            and interest in and to this Website and all materials, information, and compilations of
            information available on or through this Website, as well as the domain name, design,
            layout, and "look and feel" of this Website, the trademarks, service marks, and trade
            dress related thereto, and all other intellectual property and other proprietary rights
            embodied therein and appurtenant thereto. You agree that you will have no rights in or
            to any of the foregoing, express or implied, other than the rights expressly granted in
            these terms of use, or otherwise expressly granted to you in writing by an authorized
            representative of OdinUltra.
          </p>
          <br />
          <p>
            <b>Disclaimer of Representations and Warranties:</b> THIS WEBSITE IS PROVIDED ON AN "AS
            IS" AND "AS AVAILABLE" BASIS. OdinUltra MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
            KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, REGARDING YOUR ACCESS TO OR USE OF THIS
            WEBSITE, OR THE PRODUCTS, SERVICES OR INFORMATION DESCRIBED ON OR AVAILABLE THROUGH THIS
            WEBSITE. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ANY AND ALL
            REPRESENTATIONS AND WARRANTIES OF ACCURACY, COMPLETENESS, TIMELINESS, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
            THIS WEBSITE INCLUDES FACTS, VIEWS AND OPINIONS OF INDIVIDUALS AND ORGANIZATIONS DEEMED
            OF INTEREST. NOTHING CONTAINED ON THIS WEBSITE SHALL BE CONSTRUED AS AN ENDORSEMENT OF
            THESE VIEWS OR OPINIONS.
          </p>
          <br />
          <p>
            <b>Limitations of Liability:</b> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
            NO EVENT SHALL OdinUltra OR OUR AFFILIATES, DIRECTORS, OFFICERS, AGENTS, EMPLOYEES OR
            LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL,
            INCIDENTAL, EXEMPLARY, PUNITIVE, OR SIMILAR DAMAGES (INCLUDING LOSS OF USE, LOSS OF
            PROFITS OR LOSS OF DATA) ARISING OUT OF OR RELATED TO THIS WEBSITE OR YOUR USE OF THIS
            WEBSITE, OR FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON THE INFORMATION
            PROVIDED ON OR AVAILABLE THROUGH THIS WEBSITE, REGARDLESS OF THE FORM OF ACTION, WHETHER
            IN CONTRACT, TORT OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <br />
          <p>
            POWERED BY <strong style={{ fontSize: "1.2em" }}>FINANCIAL AI™</strong>, WE PROVIDE THE
            ANALYZING AND COMPILING DATA FOR MEASURING THE PERFORMANCE OF FINANCIAL MARKETS.
          </p>
        </div>
        <div className={styles.buttonBlock}>
          <Button
            text="Go Back"
            type={isUserLogined ? "primary" : "white"}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </FooterLayout>
  );
};
