import PiwikPro from "@piwikpro/react-piwik-pro";

export const statisticService = {
  initPiwik() {
    if (process.env?.NODE_ENV === "production") {
      PiwikPro.initialize(
        process.env?.REACT_APP_ANALYTICS_CONTAINER_ID || "-",
        "https://bigdatafed.containers.piwik.pro"
      );
      console.log(
        "Analytics enabled. ID: ...",
        process.env?.REACT_APP_ANALYTICS_CONTAINER_ID?.slice(-6)
      );
    } else {
      console.warn("Analytics in Disabled MODE!");
    }
  },
};
