import { useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { ForecastService } from "@/services/forecastService";
import { IForcastChart, IForecastExtraChart } from "@/types/forecast";

import { notification } from "../../../../../utils/notification";

export const useGetMacroChart = (
  command: string | null
): {
  isLoading: boolean;
  data: IForcastChart | IForecastExtraChart | undefined;
} => {
  const [data, setData] = useState<IForcastChart | IForecastExtraChart | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const getChart = useCallback(async (command: string) => {
    setIsLoading(true);
    try {
      const res = await ForecastService.getMacroChart(command);
      setData(res.data.result);
    } catch (e) {
      notification.error("Something is wrong please try again!");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!command) return;
    getChart(command);
  }, [command, getChart]);

  return { isLoading, data };
};
