import { useEffect, useRef, useState } from "react";

import { debounce } from "@/utils";

export const useInViewport = <U extends HTMLElement>(
  rootRef?: React.MutableRefObject<HTMLDivElement | null>,
  delay = 300
): [React.MutableRefObject<U | null>, boolean] => {
  const [isInViewport, setIsInViewport] = useState(false);
  const invewportItemRef = useRef<U | null>(null);
  useEffect(() => {
    const scrollHandler = debounce(() => {
      //
      const rect = invewportItemRef.current?.getBoundingClientRect();
      if (rect && rect.bottom >= 0 && rect.bottom <= window.innerHeight) {
        setIsInViewport(true);
      } else if (rect && (rect.bottom < -100 || rect.bottom > window.innerHeight)) {
        setIsInViewport(false);
      }
    }, delay);

    if (rootRef) rootRef.current?.addEventListener("scroll", scrollHandler);
    else document.body.addEventListener("scroll", scrollHandler);

    return () => {
      if (rootRef) rootRef.current?.removeEventListener("scroll", scrollHandler);
      else document.body.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return [invewportItemRef, isInViewport];
};
