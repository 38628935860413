import { AxiosError } from "axios";
import dayjs from "dayjs";

import { ItemType } from "@/components";
import { DATE_ISO_US } from "@/constants";
import { WarRoomService } from "@/services/warRoomService";
import {
  AssetItem,
  AssetRecordType,
  IChartCategorySieriesData,
  IChartSerieData,
  IPerformanceResponseData,
  IReturnsMunthlyItem,
  PerformanceKeys,
} from "@/types/warRoom";

import { notification } from "./notification";

export const prepareChartData = (data?: Record<string, number | null>) => {
  if (!data) return null;
  return Object.entries(data).map((dataItem) => ({
    name: dataItem[0],
    value: dataItem[1],
  }));
};

export const prepareTableData = (
  performance: IPerformanceResponseData["performance"] | null,
  key: PerformanceKeys
): Record<string, string | number | null>[] | null => {
  if (!performance) return null;
  const data = performance[key];
  const benchmark = performance.Benchmark;

  return [
    {
      name: key as string,
      ...data,
    },
    {
      name: "Benchmark",
      ...benchmark,
    },
  ];
};

export const formatReturnChartData = (
  periodReturns: IReturnsMunthlyItem[] | null,
  assetsList: string[]
): IChartCategorySieriesData | null => {
  if (!periodReturns || periodReturns.length === 0) return null;
  const dates = [...new Set(periodReturns.map((i) => dayjs(i.date).format(DATE_ISO_US)))];
  const result = assetsList.reduce((acc, _, idx) => {
    const assetData = periodReturns.filter((i) => i.assetName === idx + 1);

    acc.push({
      name: assetsList[idx],
      data: assetData.map((i) => [dayjs(i.date).format(DATE_ISO_US), i.return]),
    });
    return acc;
  }, [] as IChartSerieData[]);

  return {
    dates,
    result,
  };
};

export const uncheck = (assetsCheckList: Record<string, AssetItem>) => {
  return Object.entries(assetsCheckList).reduce((result, [key, value]) => {
    result[key] = { ...value, checked: false };
    return result;
  }, {} as Record<string, AssetItem>);
};

export const notifyCSVError = {
  byCode(err: unknown, code: number) {
    const error = err as AxiosError<{ error: any }>;
    const errorCode = error?.response?.data?.error?.erorr_code;
    if (errorCode === code) {
      this.notifyInvalid();
    } else {
      this.notifySome();
    }
    return null;
  },
  notifyInvalid: () => {
    notification.warning(
      "This CSV file are not valid! Please, select a valid CSV file and try again."
    );
  },
  notifySome: () => {
    notification.error(
      "Sorry, some error occurred during uploading CSV file! Please, try again later."
    );
  },
  notifyDelete: () => {
    notification.error(
      "Sorry, some error occurred during deleting CSV file! Please, try again later."
    );
  },
};

export const uploadCSV = async (file: File | null, url?: string) => {
  if (file) {
    const formData = new FormData();
    const fileName = file.name;

    formData.append("body", JSON.stringify({ name: fileName }));
    formData.append("csv_file", file);

    try {
      const { data } = await WarRoomService.uploadCSVFile(formData, url);
      notification.success("CSV File was successfully uploaded!");
      return data.result;
    } catch (err) {
      return notifyCSVError.byCode(err, 100);
    }
  }
  return null;
};

export const hasCSVFile = (list: ItemType<string>[], fileData: File | null) => {
  return !!fileData && list?.find((file) => file.value === fileData.name);
};

export const removeCSVFiles = async (selected: ItemType<string>, url?: string) => {
  try {
    await WarRoomService.removeCSV(selected.key, url);
    notification.success("CSV file was successfully deleted!");
    return true;
  } catch (e) {
    notifyCSVError.notifyDelete();
    return false;
  }
};

export const canCalculateAllocation = (
  assets: Record<string, AssetRecordType>,
  isCustom?: boolean
) => {
  const isSelectedSomeAssets = Object.values(assets)
    .map((group) => Object.values(group))
    .flat()
    .some((i) => i.checked);

  return isCustom ? !!isSelectedSomeAssets : true;
};
