import { useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { getQueryData } from "@/store/discover/queryData";
import {
  TIME_SERIES_TYPE,
  IQueryData,
  ISorting,
  IMetaDetails,
  IFilterCriteria,
  ISetCriteria,
} from "@/types";

function useQueryData({
  drsMeta,
  filterCriteria,
  currentPage,
  perPage,
  selectedSeriesType,
  sort,
  isSelectTimeSeriasMode,
}: {
  drsMeta: IMetaDetails | null;
  filterCriteria: IFilterCriteria | null | undefined;
  currentPage: number;
  perPage: number;
  selectedSeriesType: string;
  sort: ISorting[];
  isSelectTimeSeriasMode: boolean;
}): string | null {
  const [queryToSave, setQueryToSave] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!drsMeta) return;

    const mergeFields = [
      ...drsMeta.format.time_dimensions,
      ...drsMeta.format.dimensions,
      ...drsMeta.format.metrics,
    ];
    const fields = [...(filterCriteria?.fields || mergeFields)];
    const query: IQueryData = {
      drs_id: drsMeta?.drs_id,
      table: drsMeta?.name,
      page: currentPage,
      per_page: perPage,
      select: {
        type: filterCriteria?.aggregators?.length ? "aggregation" : "raw",
        fields,
        ...(filterCriteria?.aggregators?.length && { aggregators: filterCriteria.aggregators }),
        ...(filterCriteria?.aggregators?.length && { groups: filterCriteria.groups }),
      },
      ...(!isSelectTimeSeriasMode && sort.length && { sort }),
      ...(isSelectTimeSeriasMode && {
        output: {
          format: TIME_SERIES_TYPE.TIME_SERIES,
        },
      }),
      ...(Object.entries(filterCriteria?.filter || {}).length >= 2
        ? { filters: filterCriteria?.filter }
        : { filters: (filterCriteria?.filter as unknown as ISetCriteria)?.filters?.[0] }),
      ...(isSelectTimeSeriasMode &&
        filterCriteria?.includeTruncateFilters?.isTruncateFilterEnabled && {
          truncate_filter_dates: !!filterCriteria?.includeTruncateFilters.truncateFilterDates,
        }),
    };

    dispatch(getQueryData(query));

    try {
      const queryData = {
        query,
        dateFiled: drsMeta?.format?.time_dimensions[0],
      };
      setQueryToSave(encodeURIComponent(JSON.stringify(queryData)));
    } catch (e) {
      console.error(e);
    }
  }, [
    currentPage,
    dispatch,
    drsMeta,
    filterCriteria,
    isSelectTimeSeriasMode,
    perPage,
    selectedSeriesType,
    sort,
  ]);

  return queryToSave;
}

export default useQueryData;
