import React, { Fragment } from "react";

import styles from "./RadioList.module.scss";

export interface ILabelItem<K extends string> {
  label: string;
  value: K;
  className?: string;
}

interface IProps<U extends string> {
  name: string;
  labels: ILabelItem<U>[];
  value: string;
  onChange: (value: U) => void;
  disabled?: boolean;
}

export function RadioList<U extends string>({
  name,
  labels,
  onChange,
  value,
  disabled,
}: IProps<U>) {
  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const label = ev.currentTarget.dataset.label as U;
    if (label && !disabled) onChange(label);
  };
  return (
    <Fragment>
      {labels.map((labelItem) => (
        <div
          className={`${styles.container} ${disabled ? styles.disabled : ""} ${
            labelItem.className || ""
          }`}
          key={labelItem.label}
        >
          <label className={styles.label}>
            <input
              className={styles.input}
              type="radio"
              checked={labelItem.value === value}
              name={name}
              onChange={changeHandler}
              data-label={labelItem.value}
            />
            <div className={styles.customButton}></div>
            <div className={styles.labelText}>{labelItem.label}</div>
          </label>
        </div>
      ))}
    </Fragment>
  );
}
