import { ComponentWithPermissions } from "@/components";

const discoverDataLoader = () =>
  import("./Discover").then(({ Discover }) => ({
    default: Discover,
  }));

export const DiscoverDataPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={discoverDataLoader}
      title="Data"
      pageKey={"Discover-Data"}
    />
  );
};
