import Highcharts, { AxisCrosshairOptions } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useState } from "react";

import { COLORS } from "@/constants";
import { IChartCategorySieriesData } from "@/types/warRoom";

import { formatter } from "./utils";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

const CHART_TYPE = "bar";

interface LineChartProps {
  yTitle?: string;
  data: IChartCategorySieriesData;
}
export const ChartWarRoomReturns: FC<LineChartProps> = ({ yTitle, data }) => {
  const crossChair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };

  const categories = data.dates;
  const series = data.result;

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      valueSuffix: "",
      valuePrefix: "",
      formatter,
    },
    plotOptions: {
      column: {
        borderRadius: 4,
      },
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
      selected: 5,
    },
    chart: {
      type: "column",
      marginTop: 35,
      style: {
        fontFamily: "Inter",
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    colors: COLORS,
    yAxis: {
      title: {
        text: yTitle,
      },
      crosshair: crossChair,
      gridLineWidth: 0,
      lineWidth: 1,
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "",
      },
      crosshair: crossChair,
      type: "category",
      categories,
      labels: {
        style: {
          width: 80,
        },
      },
    },
  });

  useEffect(() => {
    setChartOptions((state) => ({
      ...state,
      series: series.map(({ data, name }) => ({
        type: CHART_TYPE,
        data,
        name,
      })),
    }));
  }, [data]);

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: 450 } }}
      />
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
