export enum HedgingTypeEnum {
  DAILY_RETURN = "daily_return",
  "COMPONENT/ALLOCATION" = "component/allocation",
}

export interface IPortfolioHedging {
  id: number;
  name: string;
  csv_url: string;
  type: HedgingTypeEnum;
  company_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}
