import dayjs from "dayjs";
import { FC } from "react";

import { DATE_ISO_TIME } from "@/constants";

import styles from "./AllocationCSVItem.module.scss";

import { ReactComponent as AllocationIcon } from "@images/allocation.svg";

interface IProps {
  name: string;
  created?: string | null;
}

export const AllocationCSVItem: FC<IProps> = ({ name, created }) => {
  return (
    <div className={styles.container}>
      <span className={styles.iconWrapper}>
        <AllocationIcon />
      </span>
      <p>
        Allocation CSV:<span>{name}</span>
      </p>
      {created ? (
        <p>
          Created:<span>{dayjs(created).format(DATE_ISO_TIME)}</span>
        </p>
      ) : null}
    </div>
  );
};
