import React, { useState } from "react";

import { ReactComponent as Arrow } from "@/images/arrow.svg";

import styles from "./Panel.module.scss";

interface PanelProps {
  header: React.ReactNode;
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

interface ICollapseProps {
  defaultActiveKey?: string[];
  children: React.ReactNode;
}

export const Collapse: React.FC<ICollapseProps> = ({ defaultActiveKey = [], children }) => {
  const [activeKeys, setActiveKeys] = useState<string[]>(defaultActiveKey);

  const togglePanel = (key: string) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  return (
    <div className={styles.container}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const key = `panel-${index}`;
          return (
            <Panel
              header={child.props.header}
              key={key}
              onClick={() => togglePanel(key)}
              isActive={activeKeys.includes(key)}
            >
              {child.props.children}
            </Panel>
          );
        }
        return child;
      })}
    </div>
  );
};

export const Panel: React.FC<PanelProps> = ({ header, children, onClick, isActive }) => {
  return (
    <div className={`${styles.panel} ${isActive ? styles.active : ""}`}>
      <div className={styles.header} onClick={onClick}>
        <div>{header}</div>
        <div className={`${styles.arrow} ${isActive ? styles.active : ""}`}>
          <Arrow className={styles.arrowIcon} />
        </div>
      </div>
      {isActive && <div className={styles.content}>{children}</div>}
    </div>
  );
};
