import { FC, memo } from "react";

import { Button, EmptySelected, ItemType } from "@/components";
import { keyMapper, nameReplacer } from "@/utils/compare";

import { CompareItem } from "../CompareItem/CompareItem";

import styles from "./StrategiesGroupList.module.scss";

import { ReactComponent as ComparePlaceholderIcon } from "@images/compare-placeholder.svg";
import { ReactComponent as RemoveIcon } from "@images/nest.svg";

interface IProps {
  list: ItemType<string>[] | null;
  onRemove: (key: string) => void;
}

export const StrategiesGroupList: FC<IProps> = memo(({ list, onRemove }) => {
  const getLabelName = (key: string) => {
    return nameReplacer(key).toUpperCase();
  };
  return (
    <div className={styles.container}>
      {list ? (
        <ul className={styles.list}>
          {list.map(({ key, value }, idx) => (
            <li className={styles.item} key={key}>
              <CompareItem
                name={value}
                itemKey={key}
                index={idx + 1}
                type={keyMapper.getSubType(key)}
                label={getLabelName(key)}
                onRemove={onRemove}
              />
              <div className={styles.btnWrapper}>
                <Button
                  text="Remove"
                  IconComponent={RemoveIcon}
                  danger
                  type="gost"
                  onClick={() => onRemove(key)}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <EmptySelected
          title="You haven't chosen any strategies for comparison yet."
          text="Please, select some strategies for comparing (maximum 5)."
          Icon={ComparePlaceholderIcon}
        />
      )}
    </div>
  );
});
