import { FC, Ref, forwardRef } from "react";

import styles from "./BacktestingEmptyResult.module.scss";

import BGImage from "@images/bg-fill.jpg";
import { ReactComponent as EmptyIcon } from "@images/empty-data.svg";

interface IProps {
  title: string;
  heading?: string;
  isVisible: boolean;
  ref: Ref<HTMLElement>;
}

export const BacktestingEmptyResult: FC<IProps> = forwardRef<HTMLElement, IProps>(
  ({ title, isVisible, heading = "Backtesting Result" }, ref) => {
    if (!isVisible) return null;

    return (
      <section className={styles.emptyResultSection} ref={ref}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.inner} style={{ backgroundImage: `url(${BGImage})` }}>
          <div className={styles.iconContainer}>
            <EmptyIcon />
          </div>
          <div className={styles.textSection}>
            <p className={styles.text}>{title}</p>
            <p className={styles.text}>Please, change the configuration and try again.</p>
          </div>
        </div>
      </section>
    );
  }
);
