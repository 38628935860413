import { useCallback, useEffect, useState } from "react";

import { FILTER_OPERATORS } from "@/constants/discover";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { odinChatServices } from "@/services";
import { discoverService } from "@/services/discoverService";
import { ForecastService } from "@/services/forecastService";
import { foldersDataState, setFolderData } from "@/store/focus/foldersData";
import { FolderTypeEnum, IChartData, IFolder, IResponeData } from "@/types";
import { checkDataMacroInfo, checkDataTickerInfo, getDateISOString } from "@/utils";
import { notification } from "@/utils/notification";

export type IChartReturnData = {
  [key: string]: Record<string, Record<string, Record<string, number>>>;
};

export const useGetFolderCharts = (
  folder: IFolder | null
): { isLoading: boolean; data: IChartReturnData | null } => {
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useAppSelector(foldersDataState);
  const dispatch = useAppDispatch();

  const requests = (command: string, type: FolderTypeEnum) => {
    if (FolderTypeEnum.FORECAST_EQUITIES === type) {
      const [tickerInfo, isValid] = checkDataTickerInfo(command);
      if (!isValid || !tickerInfo) return null;
      const { ticker, industry, sector } = tickerInfo;
      if (ticker) return ForecastService.getEquitiesChart(ticker);
      if (industry) return ForecastService.getIndustryChart(encodeURIComponent(industry));
      if (sector) return ForecastService.getSectorChart(encodeURIComponent(sector));
    }
    if (FolderTypeEnum.MACRO === type) {
      const [macroInfo, isValid] = checkDataMacroInfo(command);
      if (isValid && macroInfo) return ForecastService.getMacroChart(macroInfo.indicator);
      return null;
    }
    if (FolderTypeEnum.ODIN_CHAT === type) {
      return odinChatServices.getMessageItem(command);
    }
    if (FolderTypeEnum.COMMON_API === type) {
      const data = JSON.parse(decodeURIComponent(command));
      const currentDate = new Date();
      const lastYear = new Date(
        currentDate.getFullYear() - 2,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      const defaultParam = {
        field: data.dateFiled,
        operator: FILTER_OPERATORS.gte.id,
        value: getDateISOString(lastYear),
      };

      let query = {
        ...data?.query,
        filters: defaultParam,
      };

      if (data?.query?.filters?.filters) {
        query = {
          ...data?.query,
          filters: {
            ...data.query.filters,
            filters: [...data.query.filters.filters, defaultParam],
          },
        };
      } else if (data?.query?.filters) {
        query = {
          ...data?.query,
          filters: {
            filter_operator: "and",
            filters: [defaultParam, { ...data?.query?.filters }],
          },
        };
      }
      return discoverService.fetchData(query);
    }
    return null;
  };

  const getChart = useCallback(async (command: string, type: FolderTypeEnum) => {
    try {
      const res = await requests(command, type);
      if (FolderTypeEnum.COMMON_API === type) {
        return res?.data as IResponeData;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (res?.data.result.length === 0) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return res?.data.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  useEffect(() => {
    if (!folder?.saved_data.length) {
      dispatch(setFolderData(null));
      setIsLoading(false);
      return;
    }

    if (Object.keys(data?.[folder?.id ?? ""] || {}).length) return;

    setIsLoading(true);

    Promise.all(folder.saved_data?.map(({ command, type }) => getChart(command, type)))
      .then((results) => {
        const dataToSave = () => {
          const newData: IChartData = {};
          results.forEach((chart, index) => {
            const { command, type } = folder.saved_data[index];
            newData[type] = {
              ...newData[type],
              [command]: Array.isArray(chart) ? chart[0] : chart,
            };
          });
          return { ...data, ...{ [folder.id]: newData } };
        };
        dispatch(setFolderData(dataToSave()));
      })
      .catch((e) => {
        notification.error("Something is wrong please try later!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [data, dispatch, folder, getChart]);

  return { isLoading, data: data?.[folder?.id ?? ""] || null };
};
