import { AxiosResponse } from "axios";

import axios from "./axios.config";

const remoteMachineURL = `http://192.168.103:3303/`;

export const createPDFService = {
  abort: new AbortController(),
  init() {
    this.abort = new AbortController();
  },
  fetchChartImage(config: unknown): Promise<AxiosResponse<string>> {
    return axios({
      url: "/highcharts-server",
      // url: remoteMachineURL,
      data: config,
      method: "post",
      responseType: "arraybuffer",
      signal: this.abort.signal,
    });
  },
};
