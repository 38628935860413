import dayjs from "dayjs";
import { FC, memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, ExtendedColumns, Pagination, Table } from "@/components";
import { DATE_ISO_US, getUserAvatar } from "@/constants";
import { IUser } from "@/types";

import styles from "./UsersTable.module.scss";

interface IProps {
  users: IUser[];
  isLoading: boolean;
}

type CellRowExtendType = { cell: { row: { values: IUser } } };

export const UsersTable: FC<IProps> = memo(({ users }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const navigate = useNavigate();

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);
  const paginatedList = useMemo(
    () => (users ? users.slice((page - 1) * perPage, (page - 1) * perPage + perPage) : []),
    [page, users, perPage]
  );
  const totalPages = users ? Math.ceil(users.length / perPage) : null;
  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Avatar",
        accessor: "profile_image",
        canSort: false,
        isTruncated: true,
        disableSortBy: true,
        minWidth: 90,
        Cell: (row: object & { value: string | null }) => {
          const isActive = (row as unknown as CellRowExtendType).cell.row.values.is_active;
          return <Avatar active={isActive} url={getUserAvatar(row.value)} />;
        },
      },
      {
        Header: "Name",
        accessor: "first_name",
        canSort: true,
        minWidth: 180,
        Cell: ({
          value,
          row,
        }: object & { value: string | null; row: any & { original: Record<string, string> } }) => (
          <div className={styles.companyName}>
            <span>{row.original?.first_name} </span>
            <span>{row.original?.last_name}</span>
          </div>
        ),
      },
      {
        Header: "Company Name",
        accessor: "company",
        canSort: true,
        minWidth: 220,
        Cell: ({
          value,
          row,
        }: object & { value: string | null; row: any & { original: Record<string, string> } }) => (
          <span>{row.original?.company?.name} </span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        canSort: true,
        isTruncated: true,
        minWidth: 220,
      },
      {
        Header: "Status",
        accessor: "is_active",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
        Cell: ({ value }: object & { value: number | null }) => (
          <span className={value ? styles.statusActive : styles.statusInactive}>
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
      {
        Header: "Role",
        accessor: "role",
        canSort: true,
        isTruncated: true,
        minWidth: 120,
      },
      {
        Header: "Last Visit",
        accessor: "last_login",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? dayjs(value).format(DATE_ISO_US) : " - "}</span>,
      },
      {
        Header: "Create Date",
        accessor: "created_at",
        canSort: true,
        isTruncated: true,
        minWidth: 150,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? dayjs(value).format(DATE_ISO_US) : " - "}</span>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        canSort: true,
        isTruncated: true,
        minWidth: 220,
        Cell: ({ value }: object & { value: number | null }) => <span>{value || "-"}</span>,
      },
    ],
    []
  );

  const handleSelect = (row: any) => {
    const rowItem = row as IUser;
    navigate(`./${rowItem.id}`);
  };

  return (
    <div className={styles.container}>
      <Table<IUser> columns={columns} data={paginatedList} onSelect={handleSelect} />
      <div className={styles.pagination}>
        <Pagination
          onPage={setPage}
          onChangePerPage={selectPerPageHandler}
          perPage={perPage}
          currentPage={page}
          totalCount={totalPages}
          count={users?.length}
          perPageList={[10, 20, 50, 100]}
          countLabel="Total Users"
        />
      </div>
    </div>
  );
});
