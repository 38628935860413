import { FC, useEffect } from "react";

import { InfoHeading } from "@/components";
import { SideBarKeyEnum } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import { economicCalendarState, getCountryList } from "@/store/dataBrowser/economicCalendar";

import { CompanyCalendar } from "./components/CompanyCalendar/CompanyCalendar";
import { EconomicCalendar } from "./components/EconomicCalendar/EconomicCalendar";

import styles from "./EventsCalendar.module.scss";

export const EventsCalendar: FC = () => {
  const { textInfo, video } = usePageInfo(SideBarKeyEnum.EVENTS_CALENDAR);
  const { countries, isLoadingCountries } = useAppSelector(economicCalendarState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!countries?.length && !isLoadingCountries) dispatch(getCountryList());
  }, [dispatch, countries, isLoadingCountries]);

  return (
    <div className={styles.container}>
      <InfoHeading
        helpInfo={textInfo}
        tutorialLink={video}
        faqList={faqDescriptionList.eventsCalendar}
      >
        Events Calendar
      </InfoHeading>
      <div className={styles.inner}>
        <CompanyCalendar />
        <EconomicCalendar />
      </div>
    </div>
  );
};
