import { FC, useRef } from "react";

import { FooterNavigation } from "@/components/FooterNavigation/FooterNavigation";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as LoudIcon } from "@/images/icons/loud.svg";
import { ReactComponent as MuteIcon } from "@/images/icons/mute.svg";
import { muteVideo, selectHomeStart } from "@/store/homeStart";

import styles from "./HomeVideoThemeLayout.module.scss";

import { ReactComponent as ArrowIcon } from "@images/arrow.svg";
import BackgroundPosterImage from "@images/home-background-poster.jpeg";

const FULL_CONTENT = "https://odin-media.s3.amazonaws.com/presentation/Odin_Ultra_Presentation.mp4";

interface IProps {
  children: React.ReactNode;
}

export const HomeVideoThemeLayout: FC<IProps> = ({ children }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const dispatch = useAppDispatch();
  const { isVideoMuted } = useAppSelector(selectHomeStart);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const soundToggleHandle = () => {
    if (videoRef.current) videoRef.current.muted = !isVideoMuted;
    dispatch(muteVideo(!isVideoMuted));
  };

  const scrollHandle = () => {
    contentRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.videoThemContainer} ${isVideoMuted ? styles.mute : ""}`}>
        <video
          className={styles.video}
          src={FULL_CONTENT}
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          poster={BackgroundPosterImage}
        >
          <source src={FULL_CONTENT} type="vide/mp4" />
        </video>
      </div>
      <div className={styles.inner} ref={contentRef}>
        <span className={styles.action} onClick={soundToggleHandle}>
          {isVideoMuted ? (
            <MuteIcon color="var(--black-90)" />
          ) : (
            <LoudIcon color="var(--black-90)" />
          )}
        </span>
        {children}
      </div>
    </div>
  );
};
