import { FC, useMemo } from "react";

import { ChartKeyDrivers } from "@/components";
import { keyDriversChartConfig } from "@/utils";

import styles from "./KeyDriversChart.module.scss";

interface IProps {
  chartData: Record<string, number> | undefined;
  loading: boolean;
}

export const KeyDriversChart: FC<IProps> = ({ chartData }) => {
  const data = useMemo(() => {
    return keyDriversChartConfig(chartData);
  }, [chartData]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <ChartKeyDrivers height={350} chartData={data} />
      </div>
    </div>
  );
};
