import { FC, Fragment } from "react";

import { Button, Checkbox, Confirm, Heading, Loader, RadioList, SliderRange } from "@/components";
import { ExpectType, economMetricConfig, expectTypeList, sliders } from "@/constants/warRoom";
import { AssetRecordType, IConfigData } from "@/types/warRoom";

import { useBaseAssets } from "../../hooks/useBaseAssets";
import { useCustomAssets } from "../../hooks/useCustomAssets";
import { AssetsCustom } from "../AssetsCustom/AssetsCustom";
import { CustomAssetsForm } from "../CustomAssetsForm/CustomAssetsForm";

import styles from "./SlidersForm.module.scss";

interface ISlidersFormProps<U> {
  onConfirm?: (config: IConfigData) => void;
  onCancel?: () => void;
  isLoading: boolean;
  tickersList: U;
  isCustom?: boolean;
}

const MIN_INTERVAL_DISTANCE = 50;

export function SlidersForm<
  U extends Record<string, { label: string; checked: boolean; key: string }>
>({ onConfirm, onCancel, isLoading, tickersList, isCustom }: ISlidersFormProps<U>) {
  const {
    changeCurrentStatusHandler,
    changeExpectTypeHandler,
    confirmHandler,
    changeHandler,
    setAssets,

    disabledAllOptions,
    disabledHorizon,
    expectType,
    checked,
    assets,
    config,
  } = useBaseAssets(tickersList, onConfirm);

  const {
    confirmFindAllocation,

    // assets
    desiredGroup,
    customAssets,
    assetsGroupList,
    selectedAssets,
    removeAssetHandler,
    selectAssetsHandler,
    selectDesiredGroupHandler,

    // csv files
    removeFileHandler,
    selectCSVHandler,
    uploadCSVHandler,
    removeCSVHandler,
    isCSVListLoading,
    deletingFileName,
    selectedCSVs,
    csvList,

    canCalculate,
    canDelete,
  } = useCustomAssets(confirmHandler, isCustom);

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={(ev) => ev.preventDefault()}>
        {isLoading && (
          <div className={styles.loadingFrontContainer}>
            <Confirm
              onCancel={onCancel}
              progress={isLoading}
              text="Searching for optimal allocations is in progress. This may take a few minutes. Please wait a bit or press Stop to stop searching."
              type="allocation"
            />
          </div>
        )}
        <CustomAssetsForm
          csvList={csvList}
          desiredGroup={desiredGroup}
          selectedCSVFile={selectedCSVs}
          assetsGroupList={assetsGroupList}
          selectedAssets={selectedAssets}
          deletingFileName={deletingFileName}
          onSelectDesiredGroup={selectDesiredGroupHandler}
          onSelectCSVHandler={selectCSVHandler}
          onRemoveCSVHandler={removeCSVHandler}
          onUploadCSVHandler={uploadCSVHandler}
          onSelectAsset={selectAssetsHandler}
          isLoading={isCSVListLoading}
          canDelete={canDelete}
          disabled={isLoading}
          isCustom={isCustom}
        />
        <div className={`${styles.wrapper} ${isLoading ? styles.disable : ""}`}>
          {isCustom ? (
            <Fragment>
              <Heading type="h4" className={styles.customSubheading}>
                Selected assets
              </Heading>
              <AssetsCustom
                assets={customAssets}
                onRemoveAsset={removeAssetHandler}
                filesList={selectedCSVs}
                onRemoveFile={removeFileHandler}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Heading type="h3" className={styles.subheading}>
                Select desired assets
              </Heading>
              <AssetsBase tickersList={tickersList} assets={assets} onSetAssets={setAssets} />
            </Fragment>
          )}
        </div>
        <div className={`${styles.wrapper} ${isLoading ? styles.disable : ""}`}>
          <Heading type="h3" className={styles.subheading}>
            Select your macro expectations
          </Heading>
          <div className={styles.groupSection}>
            <div className={styles.radioGroup}>
              <RadioList
                labels={Object.keys(expectTypeList).map((key) => ({
                  ...expectTypeList[key as keyof ExpectType],
                }))}
                name="expectType"
                value={expectType}
                onChange={changeExpectTypeHandler}
                disabled={disabledAllOptions}
              />
              <Checkbox
                label="Include Current Level"
                onChange={changeCurrentStatusHandler}
                checked={checked}
                id="curStatus"
                name="curStatus"
              />
            </div>
            <SliderRange
              {...sliders.changeRate}
              {...economMetricConfig[expectType as keyof ExpectType]}
              label={expectTypeList[expectType as keyof ExpectType].rangeLabel}
              value={config.change_rate}
              onChange={changeHandler}
              disabled={disabledAllOptions}
            />
            <SliderRange
              {...sliders.horizon}
              value={config.horizon}
              onChange={changeHandler}
              disabled={disabledHorizon || disabledAllOptions}
            />
          </div>
        </div>
        <div className={`${styles.wrapper} ${isLoading ? styles.disable : ""}`}>
          <Heading type="h3" className={styles.subheading}>
            Enter portfolio constraints
          </Heading>
          <div className={styles.groupSection}>
            <SliderRange
              {...sliders.leverage}
              value={config.portfolio_leverage}
              onChange={changeHandler}
              disabled={disabledAllOptions}
            />
            <SliderRange
              {...sliders.beta}
              onChange={changeHandler}
              disabled={disabledAllOptions}
              value={config.portfolio_beta}
            />
            <div className={styles.fullWidth}>
              <SliderRange
                {...sliders.interval}
                value={[config.min_interval, config.max_interval]}
                onChange={changeHandler}
                isRange
                minDistance={MIN_INTERVAL_DISTANCE}
                disabled={disabledAllOptions}
              />
            </div>
          </div>
        </div>
        <div className={styles.group}>
          {isLoading && <Loader />}
          <div className={styles.buttonWrapper}>
            <Button
              type="primary"
              text="Find Optimal Allocation"
              onClick={confirmFindAllocation}
              disabled={isLoading || disabledAllOptions || !canCalculate}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

interface IAssetsBaseProps {
  tickersList: AssetRecordType;
  onSetAssets: React.Dispatch<React.SetStateAction<AssetRecordType>>;
  assets: AssetRecordType;
}

export const AssetsBase: FC<IAssetsBaseProps> = ({ onSetAssets, tickersList, assets }) => {
  const changeAssetHandler = (checked: boolean, field?: string) => {
    if (field) {
      onSetAssets((prev) => ({
        ...prev,
        [field]: {
          ...prev[field as keyof typeof tickersList],
          checked,
        },
      }));
    }
  };

  return (
    <div className={styles.groupSection} key={2}>
      {Object.keys(tickersList).map((key) => (
        <div className={styles.checBoxItem} key={key}>
          <Checkbox {...assets[key]} name={key} onChange={changeAssetHandler} id={key} />
        </div>
      ))}
    </div>
  );
};
