import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";

import { AdminPanelService } from "@/services";
import { IAdminPanelPages, ICreatePanelPages, IMeta } from "@/types/adminPanel";
import { notification } from "@/utils/notification";

import { RootState } from "..";

export interface IAdminPanelState {
  isLoading: boolean;
  isVideoLoading: boolean;
  pages: IAdminPanelPages[] | null;
}

const initialState: IAdminPanelState = {
  isLoading: false,
  isVideoLoading: false,
  pages: null,
};

export const getPages = createAsyncThunk("adminPanel/getPages", async () => {
  const response = await AdminPanelService.getPages();
  return response.data.result;
});

export const updatePage = createAsyncThunk(
  "adminPanel/updatePage",
  async ({ pageId, data }: { pageId: number; data: { key: string; meta: IMeta | undefined } }) => {
    const response = await AdminPanelService.updatePage(pageId, data);
    return response.data.result;
  }
);

export const createPage = createAsyncThunk(
  "adminPanel/createPages",
  async (data: ICreatePanelPages) => {
    const response = await AdminPanelService.createPages(data);
    return response.data.result;
  }
);

export const uploadVideo = createAsyncThunk(
  "adminPanel/uploadVideo",
  async ({ pageId, data }: { pageId: number; data: FormData | null }) => {
    const response = await AdminPanelService.uploadVideo(pageId, data);
    return response.data.result;
  }
);

export const deletePage = createAsyncThunk("adminPanel/deletePages", async (pageId: number) => {
  const response = await AdminPanelService.deletePage(pageId);
  return response.data.result;
});

export const adminPanelSlice = createSlice({
  name: "adminPanel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPages.fulfilled, (state, action) => {
        state.pages = action.payload;
        state.isLoading = false;
      })
      .addCase(getPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPages.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        if (state?.pages) {
          state.pages = state?.pages?.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          });
        }
        state.isLoading = false;
        notification.success("Updated!");
      })
      .addCase(updatePage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePage.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(createPage.fulfilled, (state, action) => {
        state.pages?.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPage.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        if (state?.pages) {
          state.pages = state?.pages?.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          });
        }
        state.isVideoLoading = false;
      })
      .addCase(uploadVideo.pending, (state) => {
        state.isVideoLoading = true;
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isVideoLoading = false;
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        if (state?.pages) {
          state.pages = state.pages?.filter((page) => page.id !== action.payload.id);
        }
        state.isLoading = false;
        notification.success("Deleted!");
      })
      .addCase(deletePage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePage.rejected, (state, action) => {
        if (action.error.message) {
          console.error(action.error.message);
        }
        state.isLoading = false;
      });
  },
});

const state = (state: RootState) => state;

export const AdminPanelState = createSelector(state, (state) => state.adminPanel);

export default adminPanelSlice.reducer;
