import { FooterNavigation } from "@/components";
import { UserDemoInvite } from "@/features";

export const DemoInvite = () => {
  return (
    <>
      <UserDemoInvite />
    </>
  );
};
