import dayjs from "dayjs";
import React, { FC, Fragment, useMemo, useState } from "react";

import { Loader } from "@/components";
import { TIME_ISO, TIME_ISO_US, is24hFormat, getUserAvatar, DATE_ISO_US } from "@/constants";
import { IMessageItem } from "@/types/odinChat";
import { replaceWithEmoji } from "@/utils";

import styles from "./MessageItem.module.scss";

import { ReactComponent as ChatBotIcon } from "@images/chat-bot-2.svg";
import { ReactComponent as WarningIcon } from "@images/warning.svg";

interface IMessageItemProps {
  messageData: IMessageItem;
  children: React.ReactNode;
  profileImage?: string;
}

const isDevelopmentMode = process.env.REACT_APP_STAGING === "staging";

export const MessageItem: FC<IMessageItemProps> = ({ messageData, children, profileImage }) => {
  const {
    message,
    isOdin,
    user,
    date,
    status,
    isNewUserMessage,
    isReaded,
    id,
    isSameUeserMessage,
    data,
  } = messageData;
  const [isErrorVisible, setErrorVisible] = useState(false);

  const parseMessagesWithEmoji = useMemo(() => {
    return replaceWithEmoji(message);
  }, [message]);

  const userName = isOdin ? "Odin" : `${user?.name} ${user?.lastName}`;

  const hasError = useMemo(
    () => "Failed" === status && parseMessagesWithEmoji.toLocaleLowerCase().includes("error"),
    [parseMessagesWithEmoji, status]
  );

  const dateTime = dayjs(date)
    .tz()
    .format(is24hFormat ? TIME_ISO : TIME_ISO_US);

  const dateDate = dayjs(date).tz().format(DATE_ISO_US);

  return (
    <li
      className={`${styles.container} ${isNewUserMessage ? styles.animated : ""} ${
        isSameUeserMessage ? styles.blank : ""
      }`}
      data-message-id={messageData.id}
    >
      <div className={styles.avatarContainer} title={userName}>
        {isOdin ? (
          <ChatBotIcon />
        ) : (
          <div
            className={styles.bgImage}
            style={{
              backgroundImage: `url(${getUserAvatar(profileImage)})`,
            }}
          />
        )}
      </div>
      <div
        className={`${styles.messageContainer}  ${isOdin ? styles.botMessage : ""} ${
          children ? styles.withData : ""
        }`}
      >
        <p className={`${styles.header} ${isOdin ? styles.odin : ""}`}>
          <span className={styles.name}>{userName}</span>
        </p>
        <div
          className={styles.inner}
          style={{
            width: children ? (data && (data.pie || data.clock) ? "550px" : "100%") : "fit-content",
          }}
        >
          {/* {id} */}
          {children ? (
            children
          ) : hasError ? (
            <p>
              {isErrorVisible && isDevelopmentMode ? (
                parseMessagesWithEmoji
              ) : (
                <Fragment>
                  <span className={styles.emoji}>🤔</span> I am having trouble processing this
                  request.
                </Fragment>
              )}
            </p>
          ) : (
            <pre
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: parseMessagesWithEmoji }}
            ></pre>
          )}
          <div className={styles.footer}>
            {status === "Completed" && <p className={styles.completed}></p>}
            {hasError && isDevelopmentMode && (
              <div
                onClick={() => setErrorVisible(!isErrorVisible)}
                className={styles.status}
                title={parseMessagesWithEmoji}
              >
                <WarningIcon />
              </div>
            )}
            {status === "In progress" && <Loader small />}
            <time className={styles.dateTime} dateTime={dateTime} data-date={dateDate}>
              {dateTime}
            </time>
          </div>
        </div>
      </div>
    </li>
  );
};
