import { FC, useEffect, useRef, useState } from "react";

import styles from "./VirtualList.module.scss";

interface IVirtualListProps {
  itemHeight: number;
  visibleRows: number;
  children: React.ReactNode[];
}

export const VirtualList: FC<IVirtualListProps> = ({ itemHeight, visibleRows, children }) => {
  const [start, setStart] = useState(0);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const treshold = 20;
  const listLen = children.length || 0;

  const topHeight = listLen > visibleRows ? itemHeight * start : 0;
  const bottomHeight = listLen > visibleRows ? itemHeight * (listLen - (visibleRows + start)) : 0;
  const listHeight =
    listLen > visibleRows ? itemHeight * visibleRows + 1 : itemHeight * listLen + 1;

  useEffect(() => {
    const scrollHandler = (ev: Event) => {
      const element = ev.target as HTMLDivElement;
      const from = Math.floor(element.scrollTop / itemHeight + 0.2);
      const calculatedFrom = from + visibleRows > listLen ? listLen - visibleRows : from;
      setStart(calculatedFrom);
    };

    itemRef.current?.addEventListener("scroll", scrollHandler);

    return () => {
      itemRef.current?.removeEventListener("scroll", scrollHandler);
    };
  }, [visibleRows, itemHeight, listLen]);

  useEffect(() => {
    setStart(0);
    itemRef?.current?.scrollTo(0, 0);
  }, [listLen]);

  return (
    <div className={styles.listContainer} style={{ height: listHeight }} ref={itemRef}>
      <div style={{ height: topHeight }} />
      {children.slice(start, start + visibleRows + treshold)}
      <div style={{ height: bottomHeight }} />
    </div>
  );
};
