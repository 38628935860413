import { FC, ReactNode } from "react";

import { AtomLoader, DotsLoader } from "@/components";

import styles from "./LoaderWrapperCustom.module.scss";

interface IProps {
  children: ReactNode;
  loading: boolean;
  atom?: boolean;
  transparent?: boolean;
}

export const LoaderWrapperCustom: FC<IProps> = ({ children, loading, atom, transparent }) => {
  return (
    <div className={`${styles.wrapper} ${loading ? styles.loading : ""}`}>
      {loading && (
        <div className={`${styles.innerLoading} ${transparent ? styles.transparent : ""}`}>
          <div className={transparent ? styles.innerEmptyNoBorder : styles.innerEmpty}>
            {atom ? <AtomLoader /> : <DotsLoader />}
          </div>
        </div>
      )}
      {(!loading || transparent) && <div className={styles.inner}>{children}</div>}
    </div>
  );
};
