import { FC, useMemo } from "react";

import { Heading } from "@components/Heading/Heading";

import { createPortfolioTableColumns } from "@/utils";

import { Table } from "../Table/Table";

import styles from "./MetricsTable.module.scss";

interface IProps {
  metrics: any;
  hasTitle?: boolean;
}

export const MetricsTable: FC<IProps> = ({ metrics, hasTitle }) => {
  const hasBenchmark = !!Object.values(metrics.benchmark || {})?.length;
  const dataSource = Object.entries(metrics.value || {}).map((metric, i) => {
    return {
      key: i,
      metric: metrics?.metric[i + 1],
      value: metric[1] || "-",
      ...(hasBenchmark && { benchmark: metrics?.benchmark[i + 1] || "-" }),
    };
  });

  const columns = useMemo(() => createPortfolioTableColumns(hasBenchmark), [hasBenchmark]);

  return (
    <div className={styles.container}>
      {hasTitle && (
        <Heading type="h3" className={styles.heading}>
          Performance Metrics
        </Heading>
      )}
      <Table
        data={dataSource}
        columns={columns.filter((col) => hasBenchmark || col.accessor !== "benchmark")}
      />
    </div>
  );
};
