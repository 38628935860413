import { FC, memo } from "react";

import { InputItem, Dropdown, RadioList, DatePickerRange } from "@/components";
import { directionList, instrumentList, labels, toolTipHelperList } from "@/constants";
import { TradingInstrumentType } from "@/types";

import { useCommonForm } from "../../hooks/useCommonForm";
import { BasketForm } from "../BasketForm/BasketForm";
import { BenchmarkForm } from "../BenchmarkForm/BenchmarkForm";
import { CryptosForm } from "../CryptosForm/CryptosForm";
import { DerivatevesForm } from "../DerivatevesForm/DerivatevesForm";
import { StockETFsForm } from "../StockETFsForm/StockETFsForm";

import styles from "./CreateBaseForm.module.scss";

export const CreateBaseForm: FC = memo(() => {
  const {
    changeNameHandler,
    selectDateHandler,
    radioChangeHandler,
    selectedInstrument,
    selectDirectionHandler,
    selectInstrumentHandler,

    selectTickerHandler,
    selectedTicker,
    selectedDirection,
    disableOptions,
    isLoadingTickers,

    tradingInstrumentType,
    newStrategyFormError,
    newStartegyFormName,
    newStartegyFormDate,
    newStartegyFormType,
    tradingTickers,
    combinedTickersList,
  } = useCommonForm();

  const stockEtfsOptionsProps = {
    selectedTicker,
    isLoadingTickers,
    directionList,
    selectedDirection,
    list: combinedTickersList,
    disableOptions,
    selectDirectionHandler,
    selectTickerHandler,
  };

  const baseProps = {
    tradingTickersList: tradingTickers,
    isLoading: isLoadingTickers,
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.noSpace}`}>
        <div className={styles.inputWrapper}>
          <InputItem
            label="Strategy Name"
            placeholder="Strategy Name"
            name="strategyName"
            type="input"
            onChange={changeNameHandler}
            error={newStrategyFormError}
            value={newStartegyFormName || ""}
            tooltipHelp={toolTipHelperList.strategyName}
            isRequired
          />
        </div>

        <div className={styles.itemWrapper}>
          <Dropdown<"instrument", TradingInstrumentType>
            list={instrumentList}
            onSelect={selectInstrumentHandler}
            selected={selectedInstrument}
            placeholder="Select Trading Instrument"
            name="instrument"
            label="Trading Instrument"
          />
        </div>
      </div>

      {tradingInstrumentType === "stocks/etfs" && <StockETFsForm {...stockEtfsOptionsProps} />}
      {tradingInstrumentType === "cryptos" && <CryptosForm {...baseProps} />}
      {tradingInstrumentType === "baskets" && <BasketForm {...baseProps} />}
      {tradingInstrumentType === "options" && <DerivatevesForm {...baseProps} />}

      <div className={`${styles.row} ${disableOptions ? styles.disabled : ""}`}>
        <div className={styles.itemWrapper}>
          <DatePickerRange
            onSelect={selectDateHandler}
            label="Date Interval"
            date={newStartegyFormDate}
            disabled={disableOptions}
          />
        </div>
        <div className={styles.itemWrapper}>
          <p>Strategy Type</p>
          <div className={styles.radioGroup}>
            <RadioList
              labels={labels}
              name="type"
              onChange={radioChangeHandler}
              value={newStartegyFormType}
              disabled={disableOptions}
            />
          </div>
        </div>
        <div className={styles.itemWrapper}></div>
      </div>

      <BenchmarkForm />
    </div>
  );
});
