import { useCallback, useEffect, useMemo } from "react";

export interface IPaginationData {
  totalCount: number | null;
  currentPage: number;
  perPage: number;
  count: number;
  onPage: (page: number) => void;
}

const createPages = (count: number, start = 0) => {
  if (count > 0)
    return Array(count)
      .fill("")
      .map((_, idx) => (start > 0 ? (idx + start).toString() : (idx + 1).toString()));
  return [];
};

export const usePagination = (paginationData: IPaginationData) => {
  const { currentPage, totalCount, perPage, count, onPage } = paginationData;

  const leftSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    if (totalCount <= 6) return createPages(totalCount);
    return totalCount && totalCount > 5
      ? currentPage > 5
        ? ["1"]
        : createPages(6)
      : createPages(totalCount);
  }, [totalCount, currentPage]);

  const rightSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    return totalCount > 6
      ? currentPage > totalCount - 5 && currentPage > 5
        ? createPages(6, totalCount - 5)
        : [totalCount.toString()]
      : [];
  }, [currentPage, totalCount]);

  const middleSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    return totalCount > 5 && currentPage > 5 && currentPage <= totalCount - 5
      ? createPages(5, currentPage - 2)
      : [];
  }, [currentPage, totalCount]);

  const prevPageHandler = useCallback(() => {
    if (currentPage > 1) onPage(currentPage - 1);
  }, [currentPage]);

  const nextPageHandler = useCallback(() => {
    if (totalCount && currentPage < totalCount) onPage(currentPage + 1);
  }, [currentPage, totalCount]);

  const selectPageHandler = (page: string) => {
    onPage(Number(page));
  };

  const prevHandle = () => {
    if (currentPage - 5 >= 1) onPage(Number(currentPage - 5));
    else onPage(Number(1));
  };

  const nextHandle = () => {
    if (totalCount && currentPage + 5 <= totalCount) onPage(Number(currentPage + 5));
    else onPage(Number(totalCount));
  };

  const calcWidth = (pageString: string) => {
    if (pageString.length >= 4) return 50;
    if (pageString.length >= 3) return 42;
    if (pageString.length >= 2) return 34;
    return 28;
  };

  useEffect(() => {
    if (count && perPage && currentPage > Math.ceil(count / perPage) && currentPage > 1) {
      onPage(currentPage - 1);
    }
  }, [currentPage, count, perPage, onPage]);

  return {
    leftSidePagesList,
    rightSidePagesList,
    middleSidePagesList,
    prevPageHandler,
    nextPageHandler,
    selectPageHandler,
    prevHandle,
    nextHandle,
    calcWidth,
  };
};
