import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";

import { LoaderWrapperCustom, PaginationReq, TableSort } from "@/components";
import { IFieldMeta, IResult, ISorting, TIME_SERIES_TYPE } from "@/types";
import { formatValue } from "@/utils";

import styles from "./DrsTable.module.scss";

interface IProps {
  seriesType: TIME_SERIES_TYPE;
  meta: IFieldMeta[];
  results: (string | number)[][] | IResult[];
  isLoading?: boolean;
  setCurrentPage?: (currentPage: number) => void;
  currentPage?: number;
  setPerPage?: (perPage: number) => void;
  perPage?: number;
  setSort?: Dispatch<SetStateAction<ISorting[]>>;
  hasResetSort?: boolean;
  small?: boolean;
}

export const DrsTable: FC<IProps> = ({
  seriesType,
  meta,
  results,
  isLoading = false,
  setCurrentPage,
  currentPage = 1,
  perPage = 10,
  setPerPage,
  setSort,
  hasResetSort,
  small,
}) => {
  const isDefaultSeriesType = seriesType === TIME_SERIES_TYPE.DEFAULT;

  const getPrepData = useCallback(() => {
    return results?.map((resultObj) => {
      if (Array.isArray(resultObj)) {
        return resultObj?.reduce((acc: Record<string, string | number>, data, i) => {
          acc = {
            ...acc,
            [meta[i].name]: formatValue(data) || "-",
          };
          return acc;
        }, {});
      }
      return [];
    });
  }, [meta, results]);

  const prepDataForTable = useMemo(() => getPrepData(), [getPrepData]);
  const getColumns = useMemo(
    () =>
      isDefaultSeriesType
        ? Object.keys(prepDataForTable?.[0] || {})?.map((key) => {
            return {
              Header: key,
              accessor: (rows: any) => rows[key],
              canSort: true,
              isTruncated: false,
              minWidth: 200,
            };
          })
        : null,
    [isDefaultSeriesType, prepDataForTable]
  );

  const selectPerPageHandler = useCallback((value: number) => {
    if (!setCurrentPage || !setPerPage) return;
    setPerPage(value);
    setCurrentPage(1);
  }, []);

  const resetPageHandler = useCallback(() => {
    if (!setCurrentPage) return;
    setCurrentPage(1);
  }, []);

  const handleSort = (sorting: Record<string, boolean>) => {
    const prepData = Object.entries(sorting).map((sort) => ({
      field: sort[0],
      ascending: sort[1],
    }));
    if (setSort) {
      setSort(prepData);
    }
  };

  return (
    <LoaderWrapperCustom loading={isLoading} atom transparent>
      {isDefaultSeriesType ? (
        <>
          {getColumns && (
            <TableSort
              columns={getColumns}
              data={prepDataForTable}
              size={small ? "small" : "medium"}
              currentPage={currentPage}
              perPage={perPage}
              resetPage={resetPageHandler}
              onSort={handleSort}
              hasResetSort={hasResetSort}
            />
          )}
          <div className={styles.pagination}>
            <PaginationReq
              onPage={setCurrentPage}
              onChangePerPage={selectPerPageHandler}
              perPage={perPage}
              currentPage={currentPage}
              totalCount={null}
              count={prepDataForTable?.length}
              perPageList={[10, 20, 50, 100]}
              showTotal={false}
            />
          </div>
        </>
      ) : null}
    </LoaderWrapperCustom>
  );
};
