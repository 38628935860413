import { FC } from "react";

import dropIcon from "@/images/drop.svg";

import styles from "./DropZone.module.scss";

interface IProps {
  className?: string;
}

export const DropZone: FC<IProps> = ({ className }) => {
  return (
    <div className={`${styles.portfolioContainer} ${className ? styles[className] : ""}`}>
      <div className={styles.inner}>
        <div className={styles.addIcon}>
          <img src={dropIcon} alt="drop" />
        </div>
        <p className={styles.text}>Drag &amp; Drop Template here</p>
      </div>
    </div>
  );
};
