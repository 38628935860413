import { FC } from "react";

import { UserRegistrationForm } from "@/features";

export const UserRegistration: FC = () => {
  return (
    <>
      <UserRegistrationForm />
    </>
  );
};
