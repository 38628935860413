import { FC, Fragment, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, FooterNavigation, InputItem, Loader, MessageContent } from "@/components";
import { useGetDocumentsListQuery } from "@/services/apiQuery/home";
import { IAdminPanelDocuments } from "@/types/adminPanel";

import { useLearnMoreForm } from "./hooks/useLearnMoreForm";

import styles from "./LearnMore.module.scss";

const findCurrentPageDocID = (accessType: string | null, data?: IAdminPanelDocuments[]) => {
  const fundDocument = data?.find(
    (doc) =>
      doc.meta.type === "learn_more" && doc.meta["additional-ctx"]?.toLowerCase() === accessType
  );
  return fundDocument ? fundDocument.id.toString() : null;
};

export const LearnMore: FC = () => {
  const [search] = useSearchParams();
  const { data, isFetching } = useGetDocumentsListQuery(null);

  const accessType = search.get("type") || search.get("solution");
  const docId = findCurrentPageDocID(accessType, data);
  const { form, message, loading, canRequest, changeHandler, submitHandler } = useLearnMoreForm(
    accessType,
    docId || "-"
  );

  const hasDocuments = useMemo(() => {
    return !!docId;
  }, [docId]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        {isFetching ? (
          <div className={styles.pageLoader}>
            <Loader white />
          </div>
        ) : (
          <Fragment>
            {hasDocuments ? (
              <Fragment>
                {message ? (
                  <MessageContent message={message} />
                ) : (
                  <div className={styles.formContainer}>
                    <div className={styles.loader}>{loading ? <Loader white /> : null}</div>
                    <form className={styles.mainForm} onSubmit={submitHandler}>
                      <h1 className={styles.title}>
                        Download Our <span className={styles.topText}>{accessType}</span> White
                        Paper
                      </h1>
                      <div className={styles.fieldWrapper}>
                        <InputItem
                          label=""
                          name="firstName"
                          type="name"
                          placeholder="First Name"
                          onChange={changeHandler}
                          value={form.firstName.value}
                          error={form.firstName.error}
                          disabled={loading}
                          themed
                        />
                      </div>
                      <div className={styles.fieldWrapper}>
                        <InputItem
                          label=""
                          name="lastName"
                          type="name"
                          placeholder="Last Name"
                          onChange={changeHandler}
                          value={form.lastName.value}
                          error={form.lastName.error}
                          disabled={loading}
                          themed
                        />
                      </div>
                      <div className={styles.fieldWrapper}>
                        <InputItem
                          label=""
                          name="email"
                          type="email"
                          placeholder="Email"
                          onChange={changeHandler}
                          value={form.email.value}
                          error={form.email.error}
                          autocomplete="autocomplete"
                          disabled={loading}
                          themed
                        />
                      </div>
                      <div className={styles.fieldWrapper}>
                        <InputItem
                          label=""
                          name="company"
                          type="company"
                          placeholder="Company"
                          onChange={changeHandler}
                          value={form.company.value}
                          error={form.company.error}
                          disabled={loading}
                          themed
                        />
                      </div>
                      <div className={styles.btnWrapper}>
                        <Button text="Submit" type="white" disabled={!canRequest || loading} />
                      </div>
                    </form>
                  </div>
                )}
              </Fragment>
            ) : (
              <NoAccessContent />
            )}
          </Fragment>
        )}

        <FooterNavigation fullWidth />
      </div>
    </div>
  );
};

export const NoAccessContent = () => {
  return (
    <div className={styles.message}>
      <div className={styles.messageContent}>
        <h1 className={styles.title}>Sorry, page not found!</h1>
        <p className={styles.text}>Content for this page is not available yet.</p>
        <p className={styles.footnote}>Please try one more time later.</p>
      </div>
    </div>
  );
};
