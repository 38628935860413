import { navLinks, SideBarKeyEnum } from "@/constants";

export const getAllPages = () =>
  navLinks
    .reduce((acc: { text: string; name: any }[], tab) => {
      if (tab.items) {
        acc = [...acc, ...tab.items.map((sub) => ({ text: sub.text, name: sub.name }))];
      } else if (tab.path && tab.name) {
        acc.push({ text: tab.text, name: tab.name });
      }
      return acc;
    }, [])
    .map((tab) => ({
      value: tab.text,
      key: tab.name || "N/A",
    }))
    .concat([
      {
        value: "Custom Indicator",
        key: SideBarKeyEnum.CUSTOM_INDICATOR,
      },
      {
        value: SideBarKeyEnum.ODINCHAT,
        key: SideBarKeyEnum.ODINCHAT,
      },
    ]);
