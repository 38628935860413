import { FC, Fragment, useMemo } from "react";

import { ChartWarRoom, ExtendedColumns, Table } from "@/components";
import { ICahrtData } from "@/types/warRoom";

import styles from "./PerformanceData.module.scss";

interface IPerformanceDataProps {
  chartData: ICahrtData[] | null;
  tableData: Record<string, string | number | null>[] | null;
  note?: string | null;
}

export const PerformanceData: FC<IPerformanceDataProps> = ({ chartData, tableData, note }) => {
  const selectHandler = () => {
    //
  };

  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        canSort: false,
        minWidth: 230,
        isTruncated: true,
        Cell: ({ value }: object & { value: string }) => (
          <span className={value === "Benchmark" ? styles.remark : ""}>
            {value === "Benchmark" ? `${value} ** ` : value}
          </span>
        ),
      },
      {
        Header: "alpha",
        accessor: "alpha",
        disableSortBy: true,
        minWidth: 90,
        Cell: ({ value }: object & { value: number | null }) => <span>{value?.toFixed(2)}</span>,
      },
      {
        Header: "beta",
        accessor: "beta",
        disableSortBy: true,
        minWidth: 90,
        Cell: ({ value }: object & { value: number | null }) => <span>{value?.toFixed(2)}</span>,
      },
      {
        Header: () => (
          <span>
            return/month (%) <span style={{ color: "var(--secondary)" }}>*</span>
          </span>
        ),
        accessor: "return/month (%)",
        disableSortBy: true,
        minWidth: 150,
        Cell: ({ value }: object & { value: number | null }) => <span>{value?.toFixed(2)}</span>,
      },
      {
        Header: "risk (%)",
        accessor: "annualized risk (%)",
        disableSortBy: true,
        minWidth: 90,
        Cell: ({ value }: object & { value: number | null }) => <span>{value?.toFixed(2)}</span>,
      },
      {
        Header: "sharpe",
        accessor: "sharpe",
        disableSortBy: true,
        minWidth: 90,
        Cell: ({ value }: object & { value: number | null }) => <span>{value?.toFixed(2)}</span>,
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <Fragment>
        {chartData && tableData ? (
          <Fragment>
            <div className={styles.cahrtContainer}>
              <p className={styles.title}>Allocation</p>
              <div className={styles.chartWrapper}>
                <ChartWarRoom data={chartData} ticker="Allocation (%)" />
              </div>
            </div>
            <div className={styles.tableContainer}>
              <p className={styles.title}>Performance Table</p>
              <div className={styles.chartWrapper}>
                <Table<object>
                  data={tableData}
                  columns={columns}
                  scrollWidth={750}
                  onSelect={selectHandler}
                  size="small"
                  isNotClickable
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <div className={styles.nodata}>No Data</div>
        )}
      </Fragment>
    </div>
  );
};
