import Highcharts, { AxisCrosshairOptions, Chart as HighchartsChart } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useMemo, useRef, useState } from "react";

import { AXIS_MAIN_COLOR, AXIS_PREVIEW_COLOR, CHART_COLORS } from "@/constants";
import { IChatSerieData } from "@/types/odinChat";

import { Button } from "../Button/Button";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

const MAX_LABELS_COUNT = 30;
const LABELS_COUNT = 20;

interface LineChartProps {
  chartData: IChatSerieData;
  height?: number;
  isChartPreviw?: boolean;
}
export const OdinChart: FC<LineChartProps> = ({ chartData, height = 350, isChartPreviw }) => {
  const chartComponent = useRef<HighchartsReact.RefObject | null>(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const crossChair: AxisCrosshairOptions = {
    width: 1,
    dashStyle: "Dot",
    color: "#bababa",
  };
  const count = Math.max(
    ...(chartData.seriesData?.map((serie) => serie?.data?.length || 0) || [0])
  );
  const tickInterval = count > MAX_LABELS_COUNT ? Math.round(count / LABELS_COUNT) : 0;
  const lineColor = isChartPreviw ? AXIS_PREVIEW_COLOR : AXIS_MAIN_COLOR;

  const chartOptions = useMemo(() => {
    const options: Highcharts.Options = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: ["bar", "column"].includes(chartData.type) ? false : true,
        valueSuffix: chartData.type === "pie" || chartData?.inPercentage ? "%" : "",
        valuePrefix: "",
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
        selected: 5,
      },
      chart: {
        zooming: {
          type: "x",
        },
        type: chartData.type,
        marginTop: isChartPreviw ? 5 : 35,
        style: {
          fontFamily: "Inter",
        },
        events: {
          selection(e) {
            if (e.resetSelection) {
              setIsZoomed(false);
            } else {
              setIsZoomed(true);
            }
            return true;
          },
        },
      },
      legend: {
        enabled: isChartPreviw ? false : true,
      },
      exporting: {
        enabled: false,
      },
      colors: CHART_COLORS,

      plotOptions: {
        series: {
          stacking: chartData?.inPercentage
            ? "percent"
            : chartData.chartType === "2_axes_type2" || chartData.chartType === "multy_axes"
            ? undefined
            : "normal",
          dataLabels: {
            enabled: ["pie"].includes(chartData.type),
            useHTML: true,
            style: {
              color: "#000000",
            },
          },
          animation: {
            duration: 300,
          },
          marker: {
            radius: isChartPreviw ? 2.5 : 3,
          },
        },
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.2f} %",
          },
          size: isChartPreviw ? 120 : 200,
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
          },
        },
      },

      accessibility:
        chartData.type === "pie" || chartData?.inPercentage
          ? {
              point: {
                valueSuffix: "%",
              },
            }
          : undefined,

      yAxis:
        ["bar", "column"].includes(chartData.type) ||
        chartData.chartType === "2_axes_type2" ||
        chartData.chartType === "multy_axes"
          ? {
              title: {
                text: chartData.ylabel && (chartData.ylabel[0] || "Value"),
                margin: isChartPreviw ? 0 : undefined,
                x: isChartPreviw ? -10 : 0,
              },
              lineWidth: 1,
              gridLineWidth: 0,
              crosshair: crossChair,
              labels: {
                enabled: isChartPreviw ? false : true,
              },
              lineColor,
            }
          : chartData.seriesData?.map((seriesData, idx) => ({
              title: {
                text: chartData.ylabel ? chartData.ylabel[idx] : seriesData.name || "",
                rotation: -90,
                x: idx > 0 ? 10 : 0,
              },
              opposite: idx > 0,
              lineWidth: 1,
              gridLineWidth: 0,
              crosshair: crossChair,
              labels: {
                enabled: isChartPreviw ? false : true,
              },
              lineColor,
            })) || { title: { text: "" } },
      scrollbar: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: chartData.chartType === "2_axes_type2" ? "Quarters" : chartData.XName,
        },
        type: chartData.chartType === "2_axes_type2" ? "category" : "datetime",
        categories: chartData.categories,
        labels: {
          allowOverlap: false,
          enabled: isChartPreviw ? false : true,
        },
        crosshair: crossChair,
        tickInterval,
        lineColor,
      },
      series: chartData.seriesData.map((serie) => ({
        ...serie,
      })),
    };
    if (chartData.type === "spline")
      options.series?.forEach((serie, idx) => {
        if (idx > 0 && !chartData.chartType) serie.yAxis = 1;
        else serie.yAxis = 0;
      });
    return options;
  }, [chartData]);

  const callbackHandler = (chart: HighchartsChart) => {
    //
  };

  const resetZoom = () => {
    if (chartComponent && chartComponent.current) {
      chartComponent.current.chart.zoomOut();
    }
  };

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        ref={chartComponent}
        callback={callbackHandler}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height } }}
      />

      <div className={styles.zoomButton}>
        {isZoomed && <Button text="Reset Zoom" type="secondary" onClick={resetZoom} size="small" />}
      </div>
      <div className={`${styles.logo} ${isChartPreviw ? styles.leftAligned : ""}`}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
