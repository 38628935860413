import { AxiosResponse } from "axios";

import { IPaging } from "@/store/odinchat";
import { IChatMessage, IMessageData, IMessageResponse, IOprionsList } from "@/types/odinChat";

import axios from "./axios.config";

export const odinChatServices = {
  getMessages: (
    uid: string | null,
    paging?: Omit<IPaging, "preloader">
  ): Promise<AxiosResponse<{ result: IChatMessage[] }>> =>
    paging
      ? axios.get(`/chat/list?phone=${uid}&page=${paging.page}&per_page=${paging.perpage}`)
      : axios.get(`/chat/list?phone=${uid}`),
  getUnreaded: (uid: string): Promise<AxiosResponse<{ result: IChatMessage[] | null }>> =>
    axios.get(`/chat/list?phone=${uid}&page=1&per_page=1000&is_read=false&sort_dir=asc`),
  getOdinMessage: (data: {
    uid: string | null;
    id: string;
  }): Promise<AxiosResponse<{ result: IChatMessage }>> =>
    axios.get(`/chat/list?phone=${data.uid}&parent_id=${data.id}`),
  sendUserMessage: ({
    user,
    text,
  }: IMessageData): Promise<AxiosResponse<{ result: IMessageResponse }>> =>
    axios.post("/chat/new", { user, text }),
  setAsReaded: (ids: string[]): Promise<AxiosResponse<any>> =>
    axios.post("/chat/message/viwed", { list: ids }),
  setMessagesAsReaded: (msgData: {
    uid: string;
    ids: string[];
  }): Promise<AxiosResponse<{ result: string }>> =>
    axios.post("/chat/message", { id__in: msgData.ids, is_read: true }),
  setAllMessagesAsReaded: (id: string): Promise<AxiosResponse<{ result: string }>> =>
    axios.post("/chat/message", { id__lte: id, is_read: true }),
  getOptionsList: (): Promise<AxiosResponse<{ result: IOprionsList }>> =>
    axios.get("/chat/options"),
  getMessageItem: (messageId: string): Promise<AxiosResponse<{ result: IChatMessage }>> =>
    axios.get(`/chat/list/${messageId}`),
  cancelCahtRequest: (id: string): Promise<AxiosResponse<{ result: "ok" | null }>> =>
    axios.post("/chat/message", { id__in: [id], status: "Stopped" }),
};
