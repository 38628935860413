import { useCallback, useEffect, useMemo, useState } from "react";

import { MarketServices } from "@/services/marketServices";
import { IDaysRange, IGetHomePageData, IRangeSelected, ResultType } from "@/types";
import { calculateDaysRange, notification } from "@/utils";

export const useGetMarketViewData = (
  url: string | null,
  { selected, requestType, hasParams, selectedDaysRange, isRefrash }: IGetHomePageData
) => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState<ResultType>(null);
  const [hasError, setError] = useState(false);

  const getParams = useMemo(
    () =>
      selected
        ? Object.keys(selected).reduce((acc, key) => {
            acc = {
              ...acc,
              [key]: selected[key].key.replace("EBITDA", "Ebitda"),
            };
            return acc;
          }, {} as IDaysRange)
        : null,
    [selected]
  );

  const getData = useCallback(
    async (link: string | null, params: IDaysRange | IRangeSelected | null) => {
      if (!link) return;
      setLoading(true);
      if (hasParams && !Object.keys(getParams || {}).length) return;
      try {
        const res =
          requestType === "GET"
            ? await MarketServices.getMarketData(link, params)
            : await MarketServices.postMarketData(link, params);
        if (res?.data?.result) {
          setResult(res.data.result);
        }
      } catch (e) {
        setError(true);
        notification.error("Something went wrong. Please refresh the page and try again.");
        setResult([]);
      } finally {
        setLoading(false);
      }
    },
    [getParams, hasParams, requestType]
  );

  useEffect(() => {
    if (
      selectedDaysRange?.days_after !== null &&
      selectedDaysRange?.days_before !== null &&
      isRefrash
    ) {
      const range = calculateDaysRange(selectedDaysRange);
      getData(url, range);
      setError(false);
    }
  }, [getData, isRefrash, selectedDaysRange, url]);

  useEffect(() => {
    if (getParams === null) return;
    getData(url, getParams);
    setError(false);
  }, [getParams, getData, url]);
  return { result, isLoading, hasError };
};
