import { useCallback, useMemo } from "react";

import { ItemType } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { setBencmark, strategiesState } from "@/store/strategies/strategies";

export const useBenchmarkForm = () => {
  const {
    newStartegyForm: { tradingInstrument, benchmarkList, benchmark },
  } = useAppSelector(strategiesState);
  const dispatch = useAppDispatch();

  const disableOptions = !tradingInstrument;

  const benchmarkItemsList = useMemo(() => {
    return (
      benchmarkList?.map((item) => ({
        key: item.ticker,
        value: item.name,
      })) || []
    );
  }, [benchmarkList]);

  const selectBenchmarkHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setBencmark(item.key));
  }, []);

  const selectedBenchmark = useMemo(() => {
    const found = benchmarkList?.find((i) => i.ticker === benchmark);
    return found ? { key: `${found.id}`, value: found.name } : null;
  }, [benchmark, benchmarkList]);

  return {
    disableOptions,
    benchmarkItemsList,
    selectedBenchmark,
    selectBenchmarkHandler,
  };
};
