import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({ text, children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <div className={`${styles.tooltipContainer} ${isTooltipVisible ? styles.show : ""}`}>
        <div className={styles.tooltipContent}>{text}</div>
      </div>
    </div>
  );
};

interface IToolTipWrapperProps {
  children: React.ReactNode;
  text?: string | null;
  show?: boolean;
  delay?: number;
  position?: "top" | "left";
  title?: string;
  truncate?: boolean;
  maxWidth?: string;
  truncateContent?: boolean;
}

export const TooltipWrapper: FC<IToolTipWrapperProps> = ({
  children,
  text,
  show = true,
  delay = 500,
  position = "top",
  title,
  truncate = true,
  maxWidth,
  truncateContent = false,
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [pos, setPos] = useState({ top: 0, left: 0, display: "none", width: 0 });
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const mouseEnterHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    const { top, left, width, height } = ev.currentTarget.getBoundingClientRect();
    timerRef.current = setTimeout(() => {
      if (position === "top") setPos(() => ({ display: "flex", top, left, width }));
      if (position === "left")
        setPos(() => ({ display: "flex", top: top + height / 2, left: left - 8, width: 1 }));
    }, delay);
  };

  const mouseLeaveHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setPos((prev) => ({ ...prev, display: "none" }));
  };

  useEffect(() => {
    if (show === true) setPos({ top: 0, left: 0, display: "none", width: 0 });
  }, [show]);

  useEffect(() => {
    const resizeHandler = () => {
      setPos({ top: 0, left: 0, display: "none", width: 0 });
    };
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div
      className={styles.tooltipContainer}
      ref={itemRef}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      data-truncate={truncateContent}
    >
      {text && show && (
        <Fragment>
          {createPortal(
            <div
              className={`${styles.tooltipTextWrapper} ${position === "left" ? styles.left : ""}`}
              style={{ ...pos, zIndex: 1000 }}
            >
              <div className={styles.tooltipText} style={{ maxWidth }}>
                {!!title && <p data-truncate={truncate}>{title}</p>}
                {text}
              </div>
            </div>,
            document.body
          )}
        </Fragment>
      )}
      {children}
    </div>
  );
};
