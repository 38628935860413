import { useCallback } from "react";

export const useCreateCSV = () => {
  const saveCSVhandler = useCallback((csvRowsData: string[][], fileName: string) => {
    let csvContent = "data:text/csv;charset=utf-8,";

    csvRowsData.forEach(function (rowArray) {
      const row = rowArray.join(",");
      csvContent += `${row}\r\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName.replace(" ", "_")}.csv`);
    document.body.appendChild(link);

    link.click();
  }, []);
  return saveCSVhandler;
};
