import dayjs from "dayjs";
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Button,
  Dropdown,
  InputItem,
  ItemType,
  Loader,
  ModalLayout,
  Pagination,
  Table,
} from "@/components";
import { DATE_ISO_US, paginator } from "@/constants";
import { ExtendedColumns } from "@/hooks";
import { ApprovalStatus, IUserApproval } from "@/types/adminPanel";

import { useUsersApproval } from "../../hooks/useUsersApproval";

import styles from "./UsersApproval.module.scss";

type CellDataType = object & {
  value: string | null;
  row: any & { original: IUserApproval };
};

export const UsersApproval: FC = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [selectedUser, setSelectedUser] = useState<IUserApproval | null>(null);
  const userRef = useRef<IUserApproval | null>(null);

  const { usersData, loading, updating, changeApprovalStatus } = useUsersApproval();

  const selectPerPageHandler = useCallback((value: number) => {
    setPerPage(value);
    setPage(1);
  }, []);

  const handleSelect = (row: object) => {
    const userItem = row as IUserApproval;
    setSelectedUser(userItem);
    userRef.current = userItem;
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  const updateStatusHandle = (status: ApprovalStatus) => {
    if (selectedUser) changeApprovalStatus(status, selectedUser.id.toString());
    setSelectedUser(null);
  };

  const paginatedList = useMemo(
    () => (usersData ? usersData.slice((page - 1) * perPage, (page - 1) * perPage + perPage) : []),
    [page, usersData, perPage]
  );
  const totalPages = usersData ? Math.ceil(usersData.length / perPage) : null;

  const columns: ExtendedColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "idx",
        canSort: true,
        minWidth: 90,
        Cell: ({
          value,
        }: object & {
          value: string;
        }) => <span className={styles.count}>{value}</span>,
      },
      {
        Header: "User Name",
        accessor: "name",
        canSort: true,
        truncated: true,
        minWidth: 180,
      },
      {
        Header: "User Email",
        accessor: "email",
        canSort: true,
        truncated: true,
        minWidth: 250,
      },
      {
        Header: "Company",
        accessor: "company",
        canSort: true,
        truncated: true,
        minWidth: 170,
        Cell: ({
          value,
        }: object & {
          value: string;
        }) => <span className={styles.company}>{value}</span>,
      },
      {
        Header: "Request Date",
        accessor: "request_date",
        canSort: true,
        truncated: false,
        minWidth: 120,
        Cell: ({
          value,
        }: object & {
          value: string | null;
        }) => <span>{value ? dayjs(value).format(DATE_ISO_US) : " - "}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        canSort: true,
        truncated: false,
        minWidth: 110,
        Cell: ({ value, row }: CellDataType) => (
          <StatusCell data={{ updating, id: row.origin?.id, item: userRef.current, value }} />
        ),
      },
    ],
    [updating]
  );

  return (
    <div className={`${styles.container} ${updating ? styles.updating : ""}`}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Fragment>
          <Table<IUserApproval> columns={columns} data={paginatedList} onSelect={handleSelect} />
          <div className={styles.pagination}>
            <Pagination
              onPage={setPage}
              onChangePerPage={selectPerPageHandler}
              perPage={perPage}
              currentPage={page}
              totalCount={totalPages}
              count={usersData?.length || 0}
              perPageList={paginator.perPageList}
              countLabel="Total Users"
            />
          </div>
        </Fragment>
      )}

      <ModalLayout show={!!selectedUser} onClose={closeModal}>
        <UserApprovalStatusDialog
          selectedUser={selectedUser}
          onCancel={closeModal}
          onUpdate={updateStatusHandle}
        />
      </ModalLayout>
    </div>
  );
};

const list: ItemType<ApprovalStatus>[] = [
  {
    key: "approved",
    value: "Approve",
  },
  {
    key: "denied",
    value: "Deny",
  },
];

interface IProps {
  selectedUser: IUserApproval | null;
  onCancel: () => void;
  onUpdate: (status: ApprovalStatus) => void;
}

export const UserApprovalStatusDialog: FC<IProps> = ({ selectedUser, onCancel, onUpdate }) => {
  const [selected, setSelected] = useState<ItemType<ApprovalStatus> | null>(null);

  const selectHandle = (name: string, item: ItemType<ApprovalStatus>) => {
    setSelected(item);
  };

  const updateHandle = () => {
    if (selected) onUpdate(selected?.key);
  };

  useEffect(() => {
    if (selectedUser) {
      const found =
        list.find(
          (i) => selectedUser && i.key === selectedUser.status && selectedUser.status !== "pending"
        ) || null;
      setSelected(found);
    }
  }, [selectedUser]);

  return (
    <div className={styles.modalContainer}>
      <h3 className={styles.title}>Change User Approval Status</h3>
      <p className={styles.userInfo}>
        User: <span>{selectedUser?.name}</span>
      </p>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          name="approval"
          list={list}
          selected={selected}
          onSelect={selectHandle}
          label="Approval Status"
          placeholder="Pending"
        />
      </div>
      <div className={styles.btnGroup}>
        <Button text="Cancel" type="gost" onClick={onCancel} />
        <Button text="Update Status" type="primary" onClick={updateHandle} disabled={!selected} />
      </div>
    </div>
  );
};

interface IStatusCellProps {
  data: { updating: boolean; id?: number; item: IUserApproval | null; value: string | null };
}

const StatusCell: FC<IStatusCellProps> = ({ data }) => {
  const { updating, id, item, value } = data;
  return (
    <Fragment>
      {updating && item && id === item.id ? (
        <Loader small />
      ) : (
        <span className={`${styles.status} ${value ? styles[value] : ""}`}>{value}</span>
      )}
    </Fragment>
  );
};
