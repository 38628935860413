import { ItemType } from "@/components";
import {
  Categories,
  IEtfCHartData,
  IPieChartData,
  ISelectItem,
  ITickersList,
  ITickersMeta,
  IInfoTableData,
  IEtfTicker,
  ITickerMeta,
} from "@/types/etfIntelligence";

export const selectCategoryItemsList: ItemType<string>[] = [
  { value: "All", key: "All" },
  { value: "AI", key: "AI" },
  { value: "Dividend", key: "Dividend" },
  { value: "Volatility", key: "Volatility" },
  { value: "Vanguard", key: "Vanguard" },
  { value: "SPDR", key: "SPDR" },
  { value: "iShares", key: "iShares" },
  { value: "Income", key: "Income" },
  { value: "Momentum", key: "Momentum" },
  { value: "Simplify", key: "Simplify" },
  { value: "VanEck", key: "VanEck" },
];

export const selectManagementItemsList: ItemType<string>[] = [
  { value: "All", key: "all" },
  { value: "Active", key: "active" },
  { value: "Passive", key: "passive" },
];

export const selectAssetItemsList: ItemType<string>[] = [
  { value: "All", key: "All" },
  { value: "Blend", key: "Blend" },
  { value: "Growth", key: "Growth" },
  { value: "Value", key: "Value" },
];

export const DEFAULT_CATEGORY_ITEM = selectCategoryItemsList[0];
export const DEFAULT_MANAGEMENT_ITEM = selectManagementItemsList[0];
export const DEFAULT_ASSET_ITEM = selectAssetItemsList[0];

const formatConfigs = {
  withSign: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  withValue: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

export const formatWithSymbols = (data: IEtfTicker, meta: ITickerMeta | null): string => {
  return data.value === null
    ? "NA"
    : `${meta && meta.prefix ? meta.prefix : ""}${data.value.toLocaleString(
        "en-US",
        formatConfigs.withSign
      )}${meta && meta.postfix ? meta.postfix : ""}`;
};

export const formatValue = (data: IEtfTicker, meta: ITickerMeta | null): string =>
  data.value?.toLocaleString("en-US", formatConfigs.withValue) || "NA";
export const formatIntValue = (data: IEtfTicker, meta: ITickerMeta | null): string =>
  data.value === null
    ? "NA"
    : `${meta && meta.prefix}${data.value.toLocaleString("en-US", formatConfigs.withValue)}`;

export const getCategories = () => selectCategoryItemsList.map((group) => group.key as Categories);

export const pieChartsTitlesList: { title: string; query: string }[] = [
  { title: "Management Strategy", query: "Management%20Strategy&category=" },
  {
    title: "Management Strategy (AUM)",
    query: "Management%20Strategy%20%28AUM%29&category=",
  },
  { title: "Asset Class Size", query: "Asset%20Class%20Size&category=" },
  {
    title: "Asset Class Size (AUM)",
    query: "Asset%20Class%20Size%20%28AUM%29&category=",
  },
  { title: "Asset Class Style", query: "Asset%20Class%20Style&category=" },
  {
    title: "Asset Class Style (AUM)",
    query: "Asset%20Class%20Style%20%28AUM%29&category=",
  },
  { title: "Asset Class", query: "Asset%20Class&category=" },
  { title: "Asset Class (AUM)", query: "Asset%20Class%20%28AUM%29&category=" },
];

export const tikersMetaList: (ITickersMeta & {
  formatter: (data: IEtfTicker, meta: ITickerMeta | null) => string;
  postfix?: string;
})[] = [
  {
    title: "Number of ETFs Holding Ticker",
    query: "number",
    url: null,
    formatter: formatValue,
  },
  {
    title: "Weight in ETFs By Ticker",
    query: "weight",
    url: null,
    formatter: formatWithSymbols,
  },
  {
    title: "ETFs by AUM ($M)",
    query: "aum",
    url: null,
    formatter: formatIntValue,
  },
  {
    title: "ETFs by YTD Performance",
    query: "ytd",
    url: null,
    formatter: formatWithSymbols,
  },
  {
    title: "ETFs by Expense Ratio",
    query: "expense_ratio",
    url: "?type=new&category={Category}&stype=expense_ratio",
    formatter: formatWithSymbols,
    postfix: "%",
  },
  {
    title: "ETFs by Dividend Yield",
    query: "dividend_yield",
    url: "?type=new&category={Category}&stype=dividend_yield",
    formatter: formatWithSymbols,
    postfix: "%",
  },
];

export const createPieChartList = (): Record<Categories, IPieChartData[]> => {
  const groups = getCategories();
  return groups.reduce((acc, name) => {
    acc[name as Categories] = pieChartsTitlesList.map(({ title, query }, idx) => ({
      title,
      data: null,
      query: query + name,
      key: `${name}-pie-chart-${idx + 1}`,
      error: null,
    }));
    return acc;
  }, {} as Record<Categories, IPieChartData[]>);
};

export const createTickersList = (): Record<Categories, ITickersList[]> => {
  const groups = getCategories();
  return groups.reduce((acc, name) => {
    acc[name as Categories] = tikersMetaList.map(({ title, query, url, postfix }) => ({
      values: null,
      error: null,
      meta: postfix ? { postfix, prefix: "" } : null,
      title,
      query,
      url,
    }));
    return acc;
  }, {} as Record<Categories, ITickersList[]>);
};

export const createChartList = (): Record<Categories, IEtfCHartData[]> => {
  const groups = getCategories();
  return groups.reduce((acc, name) => {
    acc[name as Categories] = [];
    return acc;
  }, {} as Record<Categories, IEtfCHartData[]>);
};

type TableData<T> = { table: T | null; error: string | null };

export const createEtfTable = <Table>(): Record<Categories, TableData<Table>> => {
  const groups = getCategories();
  return groups.reduce((acc, name) => {
    acc[name as Categories] = {
      table: null,
      error: null,
    };
    return acc;
  }, {} as Record<Categories, TableData<Table>>);
};

export const createInfoTable = (): Record<Categories, IInfoTableData> => {
  const groups = getCategories();
  return groups.reduce((acc, name) => {
    acc[name as Categories] = {
      data: null,
      error: null,
    };
    return acc;
  }, {} as Record<Categories, IInfoTableData>);
};
