import { FC } from "react";

import { ReactComponent as LogoutIcon } from "@images/icons/logout.svg";

import { Heading, Button } from "@/components";

import styles from "./LogoutDialog.module.scss";

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const LogoutDialog: FC<IProps> = ({ onCancel, onConfirm }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <Heading type="h2" className={styles.heading}>
          User Logout
        </Heading>
        <LogoutIcon className={styles.icon} />
        <p className={styles.infoDescription}>Do you really want to LogOut?</p>
        <div className={styles.btnGroup}>
          <Button text="Logout" onClick={onConfirm} type="primary" />
          <Button text="Cancel" onClick={onCancel} type="gost" />
        </div>
      </div>
    </div>
  );
};
