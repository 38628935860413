/* eslint-disable @typescript-eslint/ban-ts-comment */
import Highcharts from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC } from "react";

import { ISeries } from "@/types";
import { IYoYData } from "@/types/forecast";

import { useEquitiesChartOptions } from "./hooks/useEquitiesChartOptions";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

const FLOAT_DIGITS = 2;

interface LineChartProps {
  ticker?: string;
  data?: ISeries[];
  isQuarters?: boolean;
  quarters?: Record<string, string> | null;
  height?: number;
  forecastKey?: string;
  hasEndDate?: boolean;
  endQuartersDate?: Record<string, string | null>;
  isChartPreview?: boolean;
  name?: string;
  yoyData?: IYoYData;
  deltaData?: IYoYData;
}

export const ChartEquities: FC<LineChartProps> = ({
  ticker,
  data,
  isQuarters,
  quarters,
  height = 350,
  forecastKey,
  hasEndDate,
  endQuartersDate,
  isChartPreview,
  name,
  yoyData = null,
  deltaData = null,
}) => {
  const { chartOptions } = useEquitiesChartOptions({
    data,
    name,
    ticker,
    yoyData,
    quarters,
    deltaData,
    hasEndDate,
    isQuarters,
    forecastKey,
    isChartPreview,
    endQuartersDate,
  });

  return (
    <div
      style={{ minHeight: 350, height: "100%", display: "flex" }}
      className={styles.chartContainer}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height, width: "100%" } }}
      />
      <div className={`${styles.logo} ${isChartPreview ? styles.leftAligned : ""}`}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
