import { FC, memo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "@/hooks";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import { collapsedItems, setCollapsedItems } from "@/store/menu";
import { ICollapsedItems, ILink, ISubLink } from "@/types/sideBar";

import styles from "./SideBar.module.scss";

interface IProps {
  isMenuOpen?: boolean;
  link: ILink | ISubLink;
  onSelect: () => void;
}

export const SideBarLink: FC<IProps> = memo(({ isMenuOpen, link, onSelect }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector(collapsedItems);

  const toggleSubmenuHandler = () => {
    const prevCollapsed = Object.keys(collapsed).reduce<ICollapsedItems>((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    prevCollapsed[link.text] = !collapsed[link.text];
    dispatch(setCollapsedItems(prevCollapsed));
  };

  if ("isSubLink" in link) {
    return (
      <NavLink
        to={link.path}
        className={`${styles["link"]} ${styles["subLink"]} ${
          pathname.includes(link.path) ? styles[isMenuOpen ? "selected" : "selectedCollapsed"] : ""
        } ${link.status ? styles[link.status] : ""}`}
        onClick={onSelect}
      >
        <span className={styles.sideMenuBorder}></span>
        <span className={styles.text}>{link.text}</span>
        <span className={styles.sideMenuBorder}></span>
      </NavLink>
    );
  } else if (!("isSubLink" in link) && link.path) {
    return (
      <NavLink
        to={link.path}
        className={`${styles[isMenuOpen ? "link" : "linkCollapsed"]} ${styles["mainLink"]} ${
          pathname.includes(link.path) ? styles[isMenuOpen ? "selected" : "selectedCollapsed"] : ""
        } ${link.status ? styles[link.status] : ""}`}
        onClick={onSelect}
      >
        <span className={styles.sideMenuBorder}></span>
        <div className={styles.menuIcon}>{link.icon}</div>
        {isMenuOpen && <span className={styles.text}>{link.text}</span>}
        <span className={styles.sideMenuBorder}></span>
      </NavLink>
    );
  }

  return (
    <div
      className={`${styles[isMenuOpen ? "link" : "linkCollapsed"]} ${styles.staticLink} ${
        pathname.includes(link?.name || "")
          ? styles[isMenuOpen ? "selected" : "selectedCollapsed"]
          : ""
      }
         ${collapsed?.[link.text] ? styles.collapsed : ""} 
         ${link.status ? styles[link.status] : ""}`}
      onClick={toggleSubmenuHandler}
    >
      <span className={styles.sideMenuBorder}></span>
      <div className={styles.menuIcon}>{link.icon}</div>
      {isMenuOpen && <span className={styles.text}>{link.text}</span>}
      {isMenuOpen && (
        <div className={styles.arrow}>
          <Arrow className={styles.arrowIcon} />
        </div>
      )}
      <span className={styles.sideMenuBorder}></span>
    </div>
  );
});
