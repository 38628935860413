import dayjs from "dayjs";
import { SeriesOptionsType } from "highcharts";

import { CHART_COLORS } from "@/constants";
import { DataPoint, IChartMeta, IEtfChart } from "@/types";

const SPY_PRICE_COLOR = "#FA4D56";
const MULTIPLIER = 1_000;

export const parseDateToValue = (date: string): number => {
  const parsetDate = dayjs(date).valueOf();
  return Number.isNaN(parsetDate) ? 0 : parsetDate;
};

export const getETFChartOptions = (
  chartData: { data: IEtfChart | null; meta: IChartMeta },
  setShowZoom: (value: boolean) => void,
  prefix?: string | null
): Highcharts.Options => {
  const datesList = chartData?.data?.values.map((valueData) => valueData.date) || [];
  const filteredDates = datesList
    ?.reduce((acc, value: string) => {
      const num = parseDateToValue(value);
      if (!acc.includes(num) && num > 0) acc.push(num);
      return acc;
    }, [] as number[])
    .sort((a, b) => b - a);

  const dateLimit = {
    max: Math.max(...filteredDates),
    min: Math.min(...filteredDates),
  };

  const series = Object.keys(chartData?.data?.meta.indicators || {}).map((key, idx) => {
    const isSPYPriceDataSerie =
      chartData?.data?.meta.indicators[key]?.toLocaleLowerCase() === "spy price";
    const numberMultiplier =
      chartData?.data?.meta.indicators[key]?.toLocaleLowerCase() === "cumulative flows"
        ? MULTIPLIER
        : 1;
    const values: DataPoint[] =
      chartData?.data?.values.filter((value) => value.indicator === Number(key)) || [];
    const data = values
      .map((point) => [
        parseDateToValue(point.date) || null,
        Number.parseFloat((Number(point.value) / numberMultiplier).toFixed(3)),
      ])
      .filter(
        (dataVlue) =>
          dateLimit.max &&
          dateLimit.min &&
          dataVlue[0] &&
          dataVlue[0] >= dateLimit.min &&
          dataVlue[0] <= dateLimit.max
      );

    return {
      data,
      name: chartData?.data?.meta.indicators[key] || "Value",
      type: "spline",
      yAxis: isSPYPriceDataSerie ? 1 : 0,
      color: isSPYPriceDataSerie ? SPY_PRICE_COLOR : undefined,
      marker: {
        enabled: false,
      },
    };
  }) as SeriesOptionsType[];

  return {
    time: {
      useUTC: false,
    },
    chart: {
      zooming: {
        type: "x",
      },
      height: undefined,
      spacingTop: 8,
      spacingBottom: 0,
      marginBottom: 60,

      events: {
        selection: function zooming(e) {
          if (e.resetSelection) {
            setShowZoom(false);
          } else {
            setShowZoom(true);
          }
          return true;
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
    },
    colors: CHART_COLORS,
    xAxis: {
      type: "datetime",
    },
    plotOptions: {
      series: {
        animation: {
          duration: 300,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: chartData.meta.y_axis,
          margin: 10,
        },
        gridLineWidth: 0,
        lineColor: "#0142941a",
        lineWidth: 1,
        labels: {
          formatter: function format() {
            if (this.axis.userOptions.title?.text?.toLowerCase().match("flow"))
              return `${
                Number(this.value) % 1 === 0 ? Number(this.value) : Number(this.value).toFixed(2)
              }`;

            return `${this.value.toLocaleString("en-US", {
              notation: "compact",
              maximumFractionDigits: 2,
            })}`;
          },
          x: -10,
        },
        opposite: false,
      },
      {
        title: {
          text: `<span>${chartData.meta.y2_axis}
            <span style="
              display: inline-block; 
              width: 9px; 
              height: 9px; 
              border-radius: 50%; 
              background-color: ${SPY_PRICE_COLOR}; 
              margin-left: 4px;
            "></span>
            </span>`,
          margin: 15,
          rotation: -90,
          useHTML: true,
        },
        gridLineWidth: 0,
        opposite: true,
        lineColor: "#0142941a",
        lineWidth: 1,
        labels: {
          x: 10,
        },
      },
    ],
    tooltip: {
      valuePrefix: prefix || "",
      xDateFormat: "%b %e, %Y",
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    series,
    legend: {
      enabled: true,
      margin: 20,
      itemStyle: {
        fontSize: "12",
        fontWeight: "400",
        fontFamily: "Inter",
      },
    },
  };
};
