import { FC, useState } from "react";

import { useOuterClick } from "@/hooks";

import styles from "./Filter.module.scss";

import { ReactComponent as FilterIcon } from "@images/filter.svg";

interface IProps {
  disabled?: boolean;
  children?: React.ReactNode;
  active?: boolean;
}

export const Filter: FC<IProps> = ({ disabled, children, active = false }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [refOutside] = useOuterClick(setShowFilter, false);

  return (
    <div className={styles.container} ref={refOutside} data-active={active}>
      <div
        className={`${styles.iconWrapper} ${disabled ? styles.disabled : ""}`}
        onClick={() => setShowFilter((prev) => !prev)}
      >
        <FilterIcon />
      </div>
      {children && showFilter ? (
        <div className={styles.innerMenu}>
          <div className={styles.content}>{children}</div>
        </div>
      ) : null}
    </div>
  );
};
