import { Page, Text, View, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { FC } from "react";

import { PDFColor, PDFfontSize } from "@/constants";

import PDFHeaderLayout from "@images/pdf-layout-header.png";
import QRCode from "@images/qr-code.png";

interface IDefaultLayoutProps {
  children: React.ReactNode;
  noFooter?: boolean;
}

const LINK_URL = "https://odinultra.ai/";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
    paddingTop: 90,
    paddingBottom: 90 + 16,
    flexGrow: 1,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  // layout header styles
  layoutHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },

  // layout footer page number styles
  layoutFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 20,
  },
  layoutPageNumber: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    textAlign: "right",
    left: 0,
    right: 0,
    bottom: 48,
    paddingRight: 20,
    fontSize: PDFfontSize.default,
    color: PDFColor.black30,
  },
  footerQRSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
    height: 70,
  },
  qrImage: {
    height: "100%",
  },
  footerLink: {
    fontSize: PDFfontSize.small,
    fontWeight: 600,
    color: PDFColor.primary,
  },
});

export const DefaultLayout: FC<IDefaultLayoutProps> = ({ children, noFooter }) => {
  return (
    <Page size="A4" style={styles.page} dpi={120} wrap>
      <View style={styles.layoutHeader} fixed>
        <Image src={PDFHeaderLayout} />
      </View>
      <View style={styles.content}>{children}</View>
      {noFooter ? null : (
        <View style={styles.layoutFooter} fixed>
          <View style={styles.footerQRSection}>
            <Image src={QRCode} style={styles.qrImage} />
            <Link src={LINK_URL} style={styles.footerLink}>
              wwww.odinultra.ai
            </Link>
          </View>
        </View>
      )}
      {noFooter ? null : (
        <Text
          render={({ pageNumber, totalPages }) =>
            pageNumber > 1 ? `Page: ${pageNumber} of ${totalPages}` : null
          }
          fixed
          style={styles.layoutPageNumber}
        />
      )}
    </Page>
  );
};
