import { AxiosResponse } from "axios";

import { CustomIndicatorType, IIndicatorData, INewIndicatorData, IOperator } from "@/types";

import axios from "./axios.config";

export const customIndicatorService = {
  getOperators: (): Promise<AxiosResponse<{ result: IOperator[] | null }>> =>
    axios.get("/custom-indicators/operators"),
  getTickersLIst: (
    tickerType: CustomIndicatorType
  ): Promise<AxiosResponse<{ result: string[] | null }>> =>
    axios.get(`/tickers/${tickerType === "equities" ? "us-non-otc" : "etfs"}`),
  createIndicator: (
    data: FormData,
    isSignal: boolean
  ): Promise<AxiosResponse<{ result: IIndicatorData | null }>> =>
    axios({
      url: isSignal ? "/custom-indicators/signal" : "/custom-indicators",
      method: "post",
      data: isSignal ? data : JSON.parse(data.get("body")?.toString() || "null"),
      headers: { "Content-Type": isSignal ? "multipart/form-data" : "application/json" },
    }),
  updateIndicator: (
    data: FormData,
    isSignal: boolean,
    id?: string
  ): Promise<AxiosResponse<{ result: IIndicatorData | null }>> =>
    axios({
      url: isSignal ? `/custom-indicators/signal/${id || "-"}` : `/custom-indicators/${id || "-"}`,
      method: "put",
      data: isSignal ? data : JSON.parse(data.get("body")?.toString() || "null"),
      headers: { "Content-Type": isSignal ? "multipart/form-data" : "application/json" },
    }),
  getIndicatorByID: (ID: string): Promise<AxiosResponse<{ result: IIndicatorData | null }>> =>
    axios.get(`/custom-indicators/${Number.isNaN(Number(ID)) ? "" : Number(ID)}`),
  deleteIndicator: (ID: string): Promise<AxiosResponse<{ result: string | null }>> =>
    axios.delete(`/custom-indicators/${Number.isNaN(Number(ID)) ? "" : Number(ID)}`),
};
