import { ROUTES } from "./routes";

import FocusPlaceholder from "@images/focus-chart-exapmle.jpg";
import PortfolioPlaceholder from "@images/portfolio-item-example.jpg";

export const placeholderItems = {
  focus: {
    image: FocusPlaceholder,
    text: `Here you can choose which data you would like to see from "Data -> Focus". In
    order for them to be displayed on this page, you need to select "Show on Home Page" on the
    selected element.`,
    link: "#",
  },
  portfolios: {
    image: PortfolioPlaceholder,
    text: `Here you can choose which data you would like to see from "Portfolio Constructor -> My 
    portfolios". In order for them to be displayed on this page, you need to select "Show on Home 
    Page" on the selected element.`,
    link: ROUTES.portfolioBuilder.path,
  },
  strategies: {
    image: PortfolioPlaceholder,
    text: `Here you can choose which data you would like to see from "Strategies Builder -> My 
    Strategies". In order for them to be displayed on this page, you need to select "Show on Home 
    Page" on the selected element.`,
    link: ROUTES.strategiesBuilder.path,
  },
};
