import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IFolder, IFolderDataPayload } from "@/types";
import { getListToUpdate, setListToUpdate } from "@/utils";
import { notification } from "@/utils/notification";

import { resetFolderData } from "./foldersData";
import { getFolders } from "./foldersManage";
import { FocusService } from "../../services/focusService";
import { RootState } from "../index";

interface IFolderState {
  selected: IFolder | null;
  isLoading: boolean;
}

const initialState: IFolderState = {
  selected: null,
  isLoading: false,
};

export const getFolder = createAsyncThunk(
  "focus/getFolder",
  async (id: string | number, thunkAPI) => {
    const response = await FocusService.getFolder(id);
    thunkAPI.dispatch(resetSelected());
    return response.data.result;
  }
);

export const addDataToFolder = createAsyncThunk(
  "focus/addToFolders",
  async ({ id, data }: { id: string | number; data: IFolderDataPayload }) => {
    const response = await FocusService.addDataToFolder(id, data);
    return response.data.result;
  }
);

export const deleteItemInFolder = createAsyncThunk(
  "focus/deleteToFolders",
  async (id: string | number, thunkApi) => {
    const response = await FocusService.deleteDataInFolder(id);
    thunkApi.dispatch(resetFolderData());
    thunkApi.dispatch(getFolders());
    return response.data.result;
  }
);

export const updateItemInFolder = createAsyncThunk(
  "focus/updateToFolders",
  async ({ id, data, hash }: { id: number; data: any; hash: string }, thunkApi) => {
    const response = await FocusService.updateDataInFolder(id, data);
    thunkApi.dispatch(resetFolderData());
    thunkApi.dispatch(getFolders());
    const listToUpdate = getListToUpdate();
    setListToUpdate(listToUpdate.filter((item) => item.hash !== hash));
    return response.data.result;
  }
);

export const replaceItemInFolder = createAsyncThunk(
  "focus/replaceToFolders",
  async (
    { id, folder_id, action }: { id: number; folder_id: number; action: "move" | "copy" },
    thunkApi
  ) => {
    const response = await FocusService.replaceDataInFolder(id, folder_id, action);
    thunkApi.dispatch(resetFolderData());
    thunkApi.dispatch(getFolders());
    return response.data.result;
  }
);

export const folderSelectedSlice = createSlice({
  name: "focus/folder",
  initialState,
  reducers: {
    resetSelected: (state) => {
      state.selected = initialState.selected;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFolder.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.isLoading = false;
      })
      .addCase(getFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(addDataToFolder.fulfilled, (state, action) => {
        state.isLoading = false;
        notification.success("Saved!");
      })
      .addCase(addDataToFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDataToFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message?.includes("400")) {
          notification.info("OdinData already saved.");
          return;
        }
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(deleteItemInFolder.fulfilled, (state, action) => {
        notification.success("Deleted!");
        state.isLoading = false;
        if (!state.selected) return;
        state.selected = {
          ...state.selected,
          saved_data: state.selected?.saved_data.filter((item) => item.id !== action.payload.id),
        };
      })
      .addCase(deleteItemInFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItemInFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(updateItemInFolder.fulfilled, (state, action) => {
        notification.success("Updated!");
        state.isLoading = false;
      })
      .addCase(updateItemInFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItemInFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(replaceItemInFolder.fulfilled, (state, action) => {
        notification.success("Updated!");
        state.isLoading = false;
      })
      .addCase(replaceItemInFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(replaceItemInFolder.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const { resetSelected } = folderSelectedSlice.actions;

const state = (state: RootState) => state;

export const folderSelectedState = createSelector(state, (state) => state.folderSelected);

export default folderSelectedSlice.reducer;
