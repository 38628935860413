import { FC, useState } from "react";

import { Menu, ModalLayout, TooltipWrapper } from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { useAppDispatch } from "@/hooks";
import { ReactComponent as ActionsDotsIcon } from "@/images/menu-dots.svg";
import { deletePortfolioHedging } from "@/store/portfolios/portfoliosHedging";
import { IPortfolioHedging } from "@/types";

import useDownloadCsv from "../../hooks/useDownloadScv";

import styles from "./HedgingList.module.scss";

import DownloadIcon from "@images/download.svg";
import DeleteImage from "@images/nest.svg";
import Recommendation from "@images/recommend.svg";

interface IProps {
  item: IPortfolioHedging;
  selectedItem: (item: IPortfolioHedging, isRecommend: boolean) => void;
}

const actionItems = [
  {
    icon: Recommendation,
    title: "Recommendation",
    key: 1,
  },
  {
    icon: DownloadIcon,
    title: "Download",
    key: 2,
  },
  {
    icon: DeleteImage,
    title: "Delete",
    key: 3,
  },
];

export const HedgingListItem: FC<IProps> = ({ item, selectedItem }) => {
  const { name, type } = item;
  const [showConfirm, setShowConfirm] = useState(false);
  const { handleDownloadCsv } = useDownloadCsv();

  const handleSelectedAction = (key: string | number) => {
    if (key === 1) {
      selectedItem(item, true);
    }
    if (key === 2) {
      handleDownloadCsv(item.id, item.name);
    }
    if (key === 3) {
      setShowConfirm(true);
    }
  };
  const dispatch = useAppDispatch();

  const handelConfirmDelete = () => {
    dispatch(deletePortfolioHedging(item.id));
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <div className={styles.itemRow}>
      <div onClick={() => selectedItem(item, false)} className={styles.col}>
        <TooltipWrapper text={name}>
          <p className={styles.title}>{name}</p>
        </TooltipWrapper>
      </div>
      <div className={styles.col}>{type}</div>
      <div className={styles.col}>
        <Menu menuItems={actionItems} handelMenuSelect={handleSelectedAction} hasArrow={false}>
          <div className={styles.menu}>
            <ActionsDotsIcon />
          </div>
        </Menu>
      </div>
      <ModalLayout show={showConfirm} onClose={handleCancel}>
        <Confirm
          onCancel={handleCancel}
          onConfirm={handelConfirmDelete}
          text="Do you really want to delete this Item?"
        />
      </ModalLayout>
    </div>
  );
};
