import { useEffect } from "react";

export const useScrollTop = () => {
  useEffect(() => {
    const item = document.querySelector("#main-scroll-layout");
    if (item) {
      item.scrollTo(0, 0);
    }
  }, []);
};
