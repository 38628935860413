import { FC, Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading, InfoHeading } from "@/components";
import { placeholderItems, SideBarKeyEnum } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { usePageInfo } from "@/hooks";

import { FavoritePortfolios } from "./FavoritePortfolios/FavoritePortfolios";
import { FavoriteStrategise } from "./FavoriteStrategies/FavoriteStrategies";
import { Focus } from "../DataPortal";

import styles from "./HomePage.module.scss";

export const HomePage: FC = () => {
  const EmptyPlaceHolderStrategies = () => {
    return <EmptyItem {...placeholderItems.strategies} />;
  };
  const EmptyPlaceHolderPortfolios = () => {
    return <EmptyItem {...placeholderItems.portfolios} />;
  };

  // const { textInfo, video } = usePageInfo(SideBarKeyEnum.HOME);

  return (
    <div className={styles.container}>
      {/* <InfoHeading helpInfo={textInfo} tutorialLink={video} faqList={faqDescriptionList.home}>
        Home
      </InfoHeading> */}
      <section className={styles.section}>
        <Heading type="h3" className={styles.heading}>
          My Portfolios
        </Heading>
        <div className={styles.scetionContainer}>
          <FavoritePortfolios placeholderComponent={EmptyPlaceHolderPortfolios} />
        </div>
      </section>
      <section className={styles.section}>
        <Heading type="h3" className={styles.heading}>
          My Strategies
        </Heading>
        <div className={styles.scetionContainer}>
          <FavoriteStrategise placeholderComponent={EmptyPlaceHolderStrategies} />
        </div>
      </section>
      <section className={styles.section}>
        <Heading type="h3" className={styles.heading}>
          My Data
        </Heading>
        <div className={styles.scetionContainer}>
          <Focus />
        </div>
      </section>
    </div>
  );
};

interface IEmptyItem {
  text: string;
  image: string;
  link: string;
}

const EmptyItem: FC<IEmptyItem> = ({ text, image, link }) => {
  const navigate = useNavigate();

  const selectHandler = () => {
    navigate(link);
  };
  return (
    <div className={styles.emptyContainer}>
      <img src={image} alt="chart-placeholder" />
      <div className={styles.textSection}>
        <p>There is no data to display here yet.</p>
        <p>
          {text.split('"').map((t, idx) => {
            if (idx % 2 === 0) return <Fragment key={idx}>{t + '"'}</Fragment>;
            return <span key={idx}>{t + '"'}</span>;
          })}
        </p>
        <Button text="Customize" type="gost" onClick={selectHandler} />
      </div>
    </div>
  );
};
