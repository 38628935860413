import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { Confirm, InfoHeading, Loader, ModalLayout, NoDataFiles, Pagination } from "@/components";
import { ROUTES } from "@/constants";
import { paginator as defaultPaginator } from "@/constants/strategy";
import { useExtendPagination } from "@/hooks";
import { useGetUserDataFilesQuery } from "@/services/apiQuery/userData";
import { IUserDataItem } from "@/types";

import { DataItem } from "./DataItem/DataItem";
import { useRemoveModal } from "./hooks/useRemoveModal";
import { useUserFilesList } from "./hooks/useUserFilesList";

import styles from "./MyData.module.scss";

export const MyData: FC = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetUserDataFilesQuery(null);

  const { confirmRemoveHandler, removeHandler, cancelHandler, showModal, isRemoving } =
    useRemoveModal();

  const selectItemHandler = (item: IUserDataItem) => {
    const fileName = encodeURIComponent(item.name);
    navigate(`${ROUTES.userDataDetails.path}/${item.id}?type=${item.type}&name=${fileName}`);
  };

  const removeItemHandler = (item: IUserDataItem) => {
    const { id, type } = item;
    removeHandler({ id, type });
  };

  const { list, isEmptyList } = useUserFilesList(data);
  const { paginatedList, totalPages, paginator, perPageHandler, pageHandler } = useExtendPagination(
    { list, page: 1, perPage: defaultPaginator.default }
  );

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <InfoHeading>My Data</InfoHeading>
        <div className={`${styles.content} ${isFetching ? styles.loading : ""}`}>
          <div className={styles.frontLoader}>
            <Loader />
          </div>
          {!isFetching ? (
            <Fragment>
              {isEmptyList ? (
                <div className={styles.noDataContainer}>
                  <NoDataFiles title="You haven't uploaded CSV files yet." />
                </div>
              ) : (
                <div className={styles.listWrapper}>
                  <p className={styles.heading}>
                    My Data Files
                    {isRemoving ? <Loader /> : null}
                  </p>
                  <ul className={`${styles.list} ${isRemoving ? styles.disabled : ""}`}>
                    {paginatedList?.map((item, index) => (
                      <DataItem
                        item={item}
                        key={index}
                        index={index + 1}
                        onSelect={selectItemHandler}
                        onRemove={removeItemHandler}
                      />
                    ))}
                  </ul>
                </div>
              )}
              <div className={styles.pagination}>
                <Pagination
                  onPage={pageHandler}
                  onChangePerPage={perPageHandler}
                  perPage={paginator.perPage}
                  currentPage={paginator.page}
                  perPageList={defaultPaginator.perPageList}
                  totalCount={totalPages}
                  count={list?.length || 0}
                  showTotal={true}
                  disabled={false}
                  countLabel="Total Files"
                />
              </div>
            </Fragment>
          ) : null}
        </div>
        <ModalLayout show={showModal} onClose={cancelHandler}>
          <Confirm
            onCancel={cancelHandler}
            onConfirm={confirmRemoveHandler}
            text="Do you really want to DELETE this file?"
            type="delete"
          />
        </ModalLayout>
      </div>
    </div>
  );
};
