import { FC, useState } from "react";

import { Button, InputItem, ItemType, RadioList } from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { IMetricIndicator } from "@/types";

import styles from "./AdvancedFilter.module.scss";

import PlusIcon from "@images/plus.svg";

interface IAdvancedFilterProps {
  onSelect: (filter: string) => void;
  metricList: IMetricIndicator[] | null;
}

const initValues = () => ({
  numberValue: "",
  percentValue: "",
});

const labels = [
  { label: "Top", value: "Top" },
  { label: "Bottom", value: "Bottom" },
];

export const AdvancedFilter: FC<IAdvancedFilterProps> = ({ metricList, onSelect }) => {
  const [activeMetric, setActiveMetric] = useState<ItemType<string> | null>(null);
  const [filterType, setFilterType] = useState<string>("Top");
  const [values, setValues] = useState<{ numberValue: string; percentValue: string }>(initValues);

  const numberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    if (name === "percent" && Number(value) >= 0 && Number(value) <= 100)
      setValues((prev) => ({ numberValue: "", percentValue: value }));
    if (name === "number") setValues((prev) => ({ percentValue: "", numberValue: value }));
  };

  const addFilterHandler = () => {
    const formula = `${filterType} ${
      values.percentValue
        ? values.percentValue + " Q "
        : values.numberValue
        ? values.numberValue + " N "
        : ""
    }${activeMetric?.value}`;
    if (activeMetric) {
      setActiveMetric(null);
      setValues(initValues);
      onSelect(formula);
    }
  };

  const selectMetricHandler = (name: string, item: ItemType<string>) => {
    setActiveMetric(item);
  };

  const selectTypeHandler = (value: string) => {
    setFilterType(value);
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.itemsGroup}>
        <RadioList
          labels={labels}
          name="filterType"
          onChange={selectTypeHandler}
          value={filterType}
          disabled={false}
        />
      </div>
      <div className={styles.itemsGroup}>
        <InputItem
          name="percent"
          placeholder="percents"
          label="Percentile"
          type="number"
          value={values.percentValue}
          onChange={numberChangeHandler}
        />
        <InputItem
          name="number"
          placeholder="number"
          label="Number"
          type="number"
          value={values.numberValue}
          symbol="N"
          onChange={numberChangeHandler}
        />
      </div>
      <div className={styles.itemsGroup}>
        <div className={styles.wrapper}>
          <DropdownSearch<"metric">
            list={metricList?.map(({ value, name }) => ({ value: name, key: value })) || []}
            onSelect={selectMetricHandler}
            selected={activeMetric}
            placeholder="Search Metric"
            name="metric"
            label="Metric"
          />
        </div>
      </div>
      <div className={styles.itemsGroup}>
        <Button
          text="Add Filter"
          onClick={addFilterHandler}
          icon={PlusIcon}
          disabled={!activeMetric}
        />
      </div>
    </div>
  );
};
