import { ICollapsedItems, ILink, ISubLink } from "@/types";

export const getCollapsedItems = (links: ILink[], isMenuOpen?: boolean) => {
  const getCollapsedSubItems = (subItems: ISubLink[]) => {
    return subItems.reduce((acc: ICollapsedItems, sub) => {
      acc[sub.text] = !!isMenuOpen;
      return acc;
    }, {});
  };
  return links.reduce((acc: ICollapsedItems, link) => {
    if (link.items) {
      acc = {
        ...acc,
        ...getCollapsedSubItems(link.items),
      };
    }
    acc[link.text] = !!isMenuOpen && !!link.defaultCollapsed;
    return acc;
  }, {});
};
