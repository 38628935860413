import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UserService } from "@/services";
import { notification } from "@/utils";

export const useUnsubscribe = () => {
  const [loading, setLoading] = useState(false);
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const email = search.get("email");

  const cancelHandle = () => {
    navigate("/");
  };

  const unsubscribeRequest = async (email: string) => {
    try {
      await UserService.unsubscribeRequest(email);
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (e: any) {
      notification.error(
        "Sorry, some error occurred during unsubscribing! Please, try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = () => {
    if (email) {
      setLoading(true);
      const encodedEmail = window.atob(email);
      unsubscribeRequest(encodedEmail.trim());
    }
  };

  useEffect(() => {
    if (!email) navigate("/");
  }, [email]);

  return { loading, submitHandler, cancelHandle };
};
