import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IInitialState {
  loading: boolean;
}

const initialState: IInitialState = {
  loading: false,
};

const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    init: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    //
  },
});

export const selectMarket = (state: RootState) => state.market;

export const { init } = marketSlice.actions;

export default marketSlice.reducer;
