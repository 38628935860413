import { FC } from "react";
import { NavLink } from "react-router-dom";

import { getUserAvatar, USER_PROFILE_LINK_NAME } from "@/constants";
import { useAppSelector, useUserPermissions } from "@/hooks";
import { ReactComponent as AdminPanel } from "@/images/admin-panel.svg";
import { ReactComponent as Company } from "@/images/company.svg";
import { ReactComponent as Logout } from "@/images/push-door-filled.svg";
import { ReactComponent as MyData } from "@/images/user-data.svg";
import { ReactComponent as UserInvite } from "@/images/user-invite.svg";
import { ReactComponent as UserProfile } from "@/images/user-profile.svg";
import { ReactComponent as Users } from "@/images/users.svg";
import { profileInfo } from "@/store/profile";
import { UserActions } from "@/types";

import styles from "./UserMenu.module.scss";

interface IProps {
  handleLogout: () => void;
  handleClickMenu: () => void;
  isOpen: boolean;
}

export interface IProfileMenuItem {
  key: string;
  label: string;
  action: UserActions | null;
  icon: JSX.Element;
  path: string;
}

export const profileMenuList: IProfileMenuItem[] = [
  {
    key: "1",
    label: USER_PROFILE_LINK_NAME.profile.label,
    icon: <UserProfile />,
    action: "default",
    path: USER_PROFILE_LINK_NAME.profile.path,
  },
  {
    key: "2",
    label: USER_PROFILE_LINK_NAME.inviteUser.label,
    icon: <UserInvite />,
    action: "invite",
    path: USER_PROFILE_LINK_NAME.inviteUser.path,
  },
  {
    key: "3",
    label: USER_PROFILE_LINK_NAME.companyManagement.label,
    icon: <Company />,
    action: "manage-company",
    path: USER_PROFILE_LINK_NAME.companyManagement.path,
  },
  {
    key: "4",
    label: USER_PROFILE_LINK_NAME.usersManagement.label,
    icon: <Users />,
    action: "manage-user",
    path: USER_PROFILE_LINK_NAME.usersManagement.path,
  },
  {
    key: "5",
    label: USER_PROFILE_LINK_NAME.adminPanel.label,
    icon: <AdminPanel />,
    action: "adminPanel",
    path: USER_PROFILE_LINK_NAME.adminPanel.path,
  },
  {
    key: "6",
    label: USER_PROFILE_LINK_NAME.userData.label,
    icon: <MyData />,
    action: "default",
    path: USER_PROFILE_LINK_NAME.userData.path,
  },
];

export const UserMenu: FC<IProps> = ({ handleLogout, handleClickMenu, isOpen }) => {
  const { profile } = useAppSelector(profileInfo);
  const { hasUserActionTo } = useUserPermissions();

  const isUserRoleDisabled = (action: UserActions | null) => {
    return !hasUserActionTo(action);
  };

  return (
    <div className={styles.container}>
      <div
        onClick={handleClickMenu}
        className={styles.bgimage}
        style={{
          backgroundImage: `url(${getUserAvatar(profile?.profile_image)})`,
        }}
      />
      {isOpen && (
        <div className={styles.info}>
          <div className={styles.userInfoBlock}>
            <NavLink onClick={handleClickMenu} to={USER_PROFILE_LINK_NAME.profile.path}>
              <div
                className={styles.bgimageMenu}
                style={{
                  backgroundImage: `url(${getUserAvatar(profile?.profile_image)})`,
                }}
              />
            </NavLink>
            <span className={styles.name}>
              {profile?.first_name} {profile?.last_name}
            </span>
            <span className={styles.email}>{profile?.email}</span>
          </div>

          <div className={styles.menuItems}>
            {profileMenuList.map(({ key, label, icon, path, action }) => {
              if (isUserRoleDisabled(action)) return null;
              return (
                <NavLink onClick={handleClickMenu} to={path} key={key}>
                  <div className={styles.menuIcon}>{icon}</div>
                  <span>{label}</span>
                </NavLink>
              );
            })}
          </div>

          <div className={styles.logout} onClick={handleLogout}>
            <div className={styles.menuIcon}>
              <Logout />
            </div>
            <span className={styles.text}>{USER_PROFILE_LINK_NAME.logout.label}</span>
          </div>
        </div>
      )}
    </div>
  );
};
