import { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as APIIcon } from "@images/icons/api.svg";
import { ReactComponent as S3ServiceIcon } from "@images/icons/aws-s3.svg";
import { ReactComponent as CSVFileIcon } from "@images/icons/csv-file.svg";
import { ReactComponent as ExcelAddInIcon } from "@images/icons/excel-add-in.svg";
import { ReactComponent as UIIcon } from "@images/icons/visual-ui.svg";

import { ActionNavigation, FooterNavigation } from "@/components";
import ForecastingMain from "@/images/forecasting-main.jpeg";
import ModelingMain from "@/images/modeling-main.jpeg";
import { useGetDocumentsListQuery } from "@/services/apiQuery/home";

import styles from "./Presentation.module.scss";

interface IProps {
  type: "modeling" | "forecasting";
}

const list = {
  forecasting: {
    items: [
      "Scientific independent fundamentals forecasting",
      "Covers 1000+ public companies across all sectors",
      "Unmatched accuracy, more precise than consensus",
      "Factual data only, no panel data",
    ],
    video: "https://odin-media.s3.amazonaws.com/presentation/Forecasts.mp4",
    title: "Forecasting",
    placeholder: ForecastingMain,
  },
  modeling: {
    items: [
      "Construct sophisticated strategies with no coding",
      "Use 400K+ prebuilt fundamental, technical, and macro indicators or build custom factors",
      "AI-optimized asset allocation and multi-manager selection",
      "Covering equities, bonds, commodities, cryptos, and derivatives",
    ],
    video: "https://odin-media.s3.amazonaws.com/presentation/Modeling.mp4",
    title: "Modeling",
    placeholder: ModelingMain,
  },
};

const cardItemsList = {
  visualUI: {
    icon: UIIcon,
    title: "Visual UI",
  },
  csvFile: {
    icon: CSVFileIcon,
    title: "CSV File",
  },
  excelAddIn: {
    icon: ExcelAddInIcon,
    title: "Excel Add-in",
  },
  s3service: {
    icon: S3ServiceIcon,
    title: "AWS S3",
  },
  api: {
    icon: APIIcon,
    title: "API",
  },
};

const ANIMATION_DELAY = 200; // delay in milliseconds
const ANIMATION_DELAY_FAST = 100; // delay in milliseconds

export const Presentation: FC<IProps> = ({ type }) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaceholderLoaded, setLoadPlaceholderStatus] = useState(false);
  const { data, isFetching } = useGetDocumentsListQuery(null);

  const imageLoadHandle = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.classList.add(styles.animated);
      setLoadPlaceholderStatus(true);
    }
  };

  const playVideoHandle = () => {
    videoRef.current?.play();
  };

  const hasLearnMoreDocs =
    data?.find(
      (doc) => doc.meta.type === "learn_more" && doc.meta["additional-ctx"]?.toLowerCase() === type
    ) || null;

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.heading}>
          <h1 className={styles.title}>{list[type].title}</h1>
        </div>
        <section className={styles.mainSection}>
          <div className={styles.leftSide}>
            <ul className={styles.listContainer}>
              {list[type].items.map((text, idx) => (
                <ListItem text={text} key={`list-item-${idx}`} delay={idx * ANIMATION_DELAY_FAST} />
              ))}
            </ul>
            <ActionNavigation
              link={`/learn-more?type=${type}`}
              loading={isFetching}
              hasAccess={!!hasLearnMoreDocs}
            />
          </div>
          <div className={styles.imageSide} ref={imageContainerRef}>
            <div className={styles.imageWrapper} data-can-play={isPlaceholderLoaded}>
              {isPlaceholderLoaded ? (
                <div className={styles.videoWrapper}>
                  <video
                    src={list[type].video}
                    ref={videoRef}
                    poster={list[type].placeholder}
                    playsInline
                    controls={true}
                    muted={true}
                    onCanPlay={playVideoHandle}
                  />
                </div>
              ) : (
                <img src={ForecastingMain} alt="forecasting-graph" onLoad={imageLoadHandle} />
              )}
            </div>
          </div>
        </section>
        {type === "forecasting" ? (
          <section className={styles.deliverySection}>
            <div className={styles.cardsWrapper}>
              {Object.entries(cardItemsList).map(([key, { title, icon }], idx) => (
                <CardItem title={title} icon={icon} delay={idx * ANIMATION_DELAY} key={key} />
              ))}
            </div>
          </section>
        ) : null}
        <FooterNavigation fullWidth />
      </div>
    </div>
  );
};

interface ICardItem {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  delay: number;
}

export const CardItem: FC<ICardItem> = ({ icon: CardIcon, title, delay }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      itemRef.current?.classList.add(styles.animated);
    }, delay);
  }, []);

  return (
    <div className={styles.itemCard} ref={itemRef}>
      <div className={styles.iconWrapper}>
        <CardIcon />
      </div>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

interface IListItemProps {
  text: string;
  delay: number;
}

const ListItem: FC<IListItemProps> = ({ text, delay }) => {
  const itemRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      itemRef.current?.classList.add(styles.animated);
    }, delay);
  }, []);

  return (
    <li className={styles.listItem} ref={itemRef}>
      {text}
    </li>
  );
};
