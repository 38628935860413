import { FC } from "react";

import { DesctiptionSection as DescriptionSection } from "@/components";
import { ITEM_CONFIG } from "@/constants";

import { ChartCard } from "./components/ChartCard/ChartCard";

import styles from "./MarketView.module.scss";

export const MarketView = () => {
  return (
    <div className={styles.container}>
      <section className={styles.inner}>
        {ITEM_CONFIG.map((config) => {
          return <ChartCard key={config.id} data={config} />;
        })}
      </section>

      <DescriptionSection />
    </div>
  );
};
