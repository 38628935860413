import { FC } from "react";

import { strategyType } from "@/constants";
import { StrategyCompareItemType } from "@/types";

import styles from "./CompareItem.module.scss";

import { ReactComponent as BenchmarkIcon } from "@images/benchmark.svg";
import { ReactComponent as RemoveIcon } from "@images/nest.svg";
import { ReactComponent as PortfolioIcon } from "@images/portfolio-icon.svg";
import { ReactComponent as CoreIcon } from "@images/strategy.svg";
import { ReactComponent as TickerIcon } from "@images/ticker.svg";

interface ICompareProps {
  index?: number;
  label: string;
  type?: StrategyCompareItemType | null;
  name?: string | null;
  itemKey?: string | null;
  onRemove?: (key: string) => void;
}

export const CompareItem: FC<ICompareProps> = ({ type, index, name, label, itemKey, onRemove }) => {
  const removeHandler = () => {
    if (onRemove && itemKey) {
      onRemove(itemKey);
    }
  };

  const iconItem: Record<StrategyCompareItemType, JSX.Element> = {
    core: <CoreIcon data-type={type ? strategyType[type].key : "none"} />,
    overlay: <CoreIcon data-type={type ? strategyType[type].key : "none"} />,
    benchmark: <BenchmarkIcon />,
    portfolio: <PortfolioIcon />,
    ticker: <TickerIcon />,
  };

  return (
    <div className={styles.container}>
      {type ? <div className={styles.iconWrapper}>{iconItem[type]}</div> : null}

      <div className={styles.innerText}>
        <p className={styles.textGroup}>
          {label ? (
            <span>{index ? `${label} - ${index}:` : label}</span>
          ) : (
            <span className={styles.empty}>Base item not selected. Please, select one.</span>
          )}
          <span>{name}</span>
        </p>
        {type ? (
          <p className={styles.textGroup}>
            <span>TYPE:</span>
            <span data-type={strategyType[type].key}>{strategyType[type].label}</span>
          </p>
        ) : null}
      </div>

      {itemKey ? (
        <span className={styles.icon} onClick={removeHandler}>
          <RemoveIcon />
        </span>
      ) : null}
    </div>
  );
};
