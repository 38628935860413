import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  fetchMarketYeldTickersData,
  fetchTickersData,
  fetchExRatesTickersData,
  fetchCommoditiesTickersData,
  selectHome,
} from "@/store/home";

const UPDATE_DELAY_TIME = 60 * 1000;

export const useTickersData = () => {
  const dispatch = useAppDispatch();
  const { tickers } = useAppSelector(selectHome);

  useEffect(() => {
    const getTickersData = async () => {
      await dispatch(fetchTickersData());
      await dispatch(fetchMarketYeldTickersData());
      await dispatch(fetchCommoditiesTickersData());
      await dispatch(fetchExRatesTickersData());
    };

    getTickersData();
    let timerId: NodeJS.Timer | null = null;

    // auto updating data in production mode
    if (process.env?.NODE_ENV === "production") {
      timerId = setInterval(() => {
        getTickersData();
      }, UPDATE_DELAY_TIME);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [dispatch]);

  return {
    tickersList: tickers,
  };
};
