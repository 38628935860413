import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, CancelTokenSource } from "axios";

import { WarRoomService } from "@/services/warRoomService";
import { IConfigData, IPerformanceResponseData } from "@/types/warRoom";
import { notification } from "@/utils/notification";

import { RootState } from ".";

interface IInitioalState {
  isLoading: boolean;
  config: any;
  result: IPerformanceResponseData | null;
  error: "Error" | null;
}

export const fetchAllocationPerformance = createAsyncThunk(
  "warRoom/fetchSetting",
  async (config: IConfigData, { rejectWithValue }) => {
    try {
      const { data } = await WarRoomService.getPerformance(config);
      return data.result;
    } catch (err) {
      const error = err as AxiosError | { isCanceled: boolean };
      if (Object.hasOwn(error, "isCanceled")) return rejectWithValue({ isCanceled: true });
      throw err;
    }
  }
);

const initialState: IInitioalState = {
  isLoading: false,
  config: null,
  result: null,
  error: null,
};

export const warRoomSlice = createSlice({
  name: "warRoom",
  initialState,
  reducers: {
    init: (state) => {
      state.result = null;
      state.error = null;
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllocationPerformance.pending, (state) => {
        state.isLoading = true;
        state.result = null;
        state.error = null;
      })
      .addCase(fetchAllocationPerformance.fulfilled, (state, { payload }) => {
        if (payload) {
          state.result = payload;
        }
        state.isLoading = false;
      })
      .addCase(fetchAllocationPerformance.rejected, (state, { payload }) => {
        if (!Object.hasOwn(payload || {}, "isCanceled")) {
          notification.error("Error while calculating Allocation!");
          state.error = "Error";
        }
        state.isLoading = false;
      });
  },
});

const state = (state: RootState) => state;

export const warRoomState = createSelector(state, (state) => state.warRoom);

export const { init } = warRoomSlice.actions;

export default warRoomSlice.reducer;
