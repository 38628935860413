import HC_more from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useMemo } from "react";

import styles from "./Chart.module.scss";

import { ReactComponent as OdinLogoIcon } from "@images/odin-logo-icon.svg";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);
HC_more(Highcharts);

interface IProps {
  title?: string | null;
  data?: Record<string, number>;
  height?: number;
}

const MAX_Y_VALUE = 10;

export const SpiderChart: FC<IProps> = ({ title, data, height = 500 }) => {
  // const calculateColorStops = (value: number): [number, string][] => {
  //   const yellowValue = Math.max(Math.min((value - 2) / 8, 1), 0); // Calculate yellow value
  //   const redValue = Math.max(Math.min((10 - value) / 5, 1), 0); // Calculate red value

  //   return [
  //     [0.1, `rgba(255, ${Math.floor(255 * (1 - redValue))}, 0, 1)`], // Start color (red)
  //     [0.4, `rgba(255, ${Math.floor(255 * (2 - yellowValue))}, 0, 1)`], // Transition to yellow
  //     [1, `rgb(2, 192, 2)`], // End color (green)
  //   ];
  // };

  const averageDataValue = Math.max(
    0,
    Math.min(
      Object.values(data || {})
        .filter((v) => v !== null)
        .reduce((sum, value) => sum + Number(value), 0) / Object.values(data || {}).length,
      MAX_Y_VALUE
    )
  );

  const options = useMemo<Highcharts.Options>(
    () => ({
      chart: {
        polar: true,
        type: "area",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
        x: -80,
      },
      pane: {
        size: "70%",
      },
      xAxis: {
        categories: Object.keys(data || {}),
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          style: {
            color: "#000000",
            fontSize: "13px",
          },
          formatter: function labelFormatter(this: Highcharts.AxisLabelsFormatterContextObject) {
            const label = this.value?.toString();
            return label.charAt(0).toUpperCase() + label.slice(1);
          },
        },
      },
      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: 0,
        max: MAX_Y_VALUE,
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        // pointFormat: "<span>{series.name}: <b>{point.y:,.2f}</b><br/>",
        formatter: function formatLabel(this: Highcharts.TooltipFormatterContextObject) {
          const label = `${
            this.x && typeof this.x === "string"
              ? `${this.x[0].toUpperCase()}${this.x.slice(1)} Score: ${this.y?.toFixed(1)} 
                / ${MAX_Y_VALUE}`
              : this.x
          }`;
          return label;
        },
        style: {
          fontWeight: "600",
          fontSize: "12px",
          color: "var(--primary)",
        },
      },
      // tooltip: {
      //   shared: true,
      //   formatter: function formatLabel(this: Highcharts.TooltipFormatterContextObject) {
      //     const valuationValue =
      //       data?.valuation && this.x && this.x === "valuation" ? data[this.x].toFixed(1) : null;
      //     const label = `${
      //       this.x && typeof this.x === "string"
      //         ? `${this.x[0].toUpperCase()}${this.x.slice(1)} Score: ${
      //             valuationValue ? valuationValue : this.y?.toFixed(1)
      //           }
      //             / ${MAX_Y_VALUE}`
      //         : this.x
      //     }`;
      //     return label;
      //   },
      //   style: {
      //     fontWeight: "600",
      //     fontSize: "12px",
      //     color: "var(--primary)",
      //   },
      // },
      legend: {
        enabled: false,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      series: [
        {
          name: "Actual Spending",
          data: Object.values(data || {}),
          // data: Object.entries(data || {}).map(([key, value]) =>
          //   key === "valuation" ? [key, 10 - value] : [key, value]
          // ),
          pointPlacement: "on",
          type: "area",
          color: {
            radialGradient: {
              cx: 0.38,
              cy: 0.46,
              r: 0.8,
            },
            stops: [
              [0, "rgba(255, 0, 0, 1)"],
              [0.5, "rgba(255, 255, 0,1)"],
              [1, "rgba(0, 255, 0,1)"],
            ],
          },
        },
      ],
    }),
    [averageDataValue, data]
  );

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height } }}
      />
      <div className={styles.logo}>
        <OdinLogoIcon />
      </div>
    </div>
  );
};
