import { FC } from "react";

import { Button, InputItem, LoaderWrapper } from "@/components";
import { useAppSelector } from "@/hooks";
import { profileInfo } from "@/store/profile";

import { useForm } from "../../hooks/useForm";

import styles from "./EditUserData.module.scss";

const initForm = {
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  phone: {
    value: "",
    error: "",
  },
  password: {
    value: "",
    error: "",
  },
  repassword: {
    value: "",
    error: "",
  },
};

export const EditUserData: FC = () => {
  const { form, submitHandler, changeHandler, isDisabled } = useForm(initForm);
  const { isLoading } = useAppSelector(profileInfo);

  return (
    <div className={styles.container}>
      <h2>Edit Profile Data</h2>
      <form autoComplete="off" onSubmit={submitHandler}>
        <LoaderWrapper loading={isLoading} transparent>
          <div className={styles.formInner}>
            <div className={styles.section}>
              <h3>Update Info</h3>
              <InputItem
                label="First Name"
                placeholder="first name"
                name="firstName"
                type="name"
                onChange={changeHandler}
                error={form.firstName.error}
                value={form.firstName.value}
              />
              <InputItem
                label="Last Name"
                placeholder="last name"
                name="lastName"
                type="name"
                onChange={changeHandler}
                error={form.lastName.error}
                value={form.lastName.value}
              />
              <InputItem
                label="Phone"
                placeholder="+1 (408) 609 5037"
                name="phone"
                type="phone"
                onChange={changeHandler}
                error={form.phone.error}
                value={form.phone.value}
              />
            </div>
            <div className={styles.section}>
              <h3>Change Password</h3>
              <InputItem
                label="New Password"
                placeholder="password"
                name="password"
                type="password"
                onChange={changeHandler}
                error={form.password.error}
                value={form.password.value}
                autocomplete="new-password"
              />
              <InputItem
                label="Confirm New Password"
                placeholder="password"
                name="repassword"
                type="password"
                onChange={changeHandler}
                error={form.repassword.error}
                value={form.repassword.value}
              />
            </div>
          </div>
        </LoaderWrapper>
        <div className={styles.btn}>
          <div>
            <Button disabled={isDisabled} text="Save Changes" />
          </div>
        </div>
      </form>
    </div>
  );
};
