import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  nanoid,
} from "@reduxjs/toolkit";

import { MyStrategyFilterType, myStrategyFilters, paginator } from "@/constants";
import { Errors } from "@/constants/errors";
import { strategiesService } from "@/services";
import { IStartegyCard, IStrategyData } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "..";

interface IInitialState {
  isLoading: boolean;
  list: IStrategyData[] | null;
  changeStatusProgress: boolean;
  page: number;
  perPage: number;
  selectedTickersList: string | null;
  filter: Record<MyStrategyFilterType, boolean>;
}

const initialState: IInitialState = {
  isLoading: false,
  list: null,
  changeStatusProgress: false,
  page: paginator.page,
  perPage: paginator.default,
  selectedTickersList: null,
  filter: myStrategyFilters,
};

export const fetchMyStrategiesData = createAsyncThunk(
  "myStrategies/fetchMyStrategiesData",
  async () => {
    const { data } = await strategiesService.getMyStrategies();
    return data.result;
  }
);

export const fetchDeleteStrategy = createAsyncThunk(
  "myStrategies/fetchDeleteStrategy",
  async (id: number) => {
    const { data } = await strategiesService.deleteStrategy(id);
    return { data, id };
  }
);

export const fetchCopyItem = createAsyncThunk(
  "myStrategies/fetchCopyItem",
  async (item: IStartegyCard) => {
    const copiedItem = { ...item, name: `${item.name}-copy-${nanoid(12)}` };
    const { data } = await strategiesService.duplicateStrategy(copiedItem);
    return { data: data.result, id: item.id };
  }
);

export const fetchChangeFavoriteStatus = createAsyncThunk(
  "myStrategies/fetchChangeFavoriteStatus",
  async (config: { value: boolean; id: number }) => {
    const { data } = await strategiesService.changeFavoriteStatus(config);
    return data.result;
  }
);

export const myStrategiesSlice = createSlice({
  name: "myStrategies",
  initialState,
  reducers: {
    initList: (state) => {
      state.list = null;
    },
    deleteStrategy: (state, { payload }: PayloadAction<IStartegyCard>) => {
      const filteredList = state.list?.filter((i) => i.id !== payload.id);
      state.list = filteredList && filteredList.length ? filteredList : null;
    },
    changeFavoriteStatus: (state, { payload }: PayloadAction<number>) => {
      if (state.list) {
        const strategy = state?.list.find((i) => i.id === payload);
        if (strategy) strategy.show_on_home = !strategy.show_on_home;
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    setBasketTickersList: (state, { payload }: PayloadAction<string[] | null>) => {
      state.selectedTickersList = payload ? payload.join(",") || null : null;
    },
    setFilter: (
      state,
      { payload }: PayloadAction<{ name: MyStrategyFilterType; checked: boolean }>
    ) => {
      const { name, checked } = payload;
      state.filter = { ...state.filter, [name]: checked };
      // if (!state.filter.typeCore && !state.filter.typeOverlay && checked) {
      //   state.filter.typeCore = true;
      //   state.filter.typeOverlay = true;
      // }
    },
    resetFilter: (state) => {
      const result = Object.keys(state.filter).reduce((acc, key) => {
        acc[key as MyStrategyFilterType] = false;
        return acc;
      }, {} as Record<MyStrategyFilterType, boolean>);

      state.filter = result;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyStrategiesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMyStrategiesData.fulfilled, (state, { payload }) => {
        if (payload) state.list = payload;
        state.isLoading = false;
      })
      .addCase(fetchMyStrategiesData.rejected, (state) => {
        state.isLoading = false;
        notification.error(Errors.data.get);
      });

    builder
      .addCase(fetchCopyItem.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchCopyItem.fulfilled, (state, { payload }) => {
        const { data, id } = payload;
        if (state.list && data) {
          const parentStrategy = state.list.find((strategy) => strategy.id === id);
          const newStrategy: IStrategyData = {
            ...payload.data,
            permissions: parentStrategy?.permissions,
          };
          state.list.push(newStrategy);
        }
        notification.success("Strategy successful copied!");
      })
      .addCase(fetchCopyItem.rejected, (state) => {
        state.isLoading = false;
        notification.error(Errors.copy.strategy);
      });

    builder
      .addCase(fetchDeleteStrategy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeleteStrategy.fulfilled, (state, { payload }) => {
        if (state.list) {
          state.list = state.list.filter((strategy) => strategy.id !== payload.id);
          notification.success("Strategy successful deleted!");
        }
        state.isLoading = false;
      })
      .addCase(fetchDeleteStrategy.rejected, (state) => {
        state.isLoading = false;
        notification.error(Errors.delete.strategy);
      });

    builder
      .addCase(fetchChangeFavoriteStatus.pending, (state) => {
        state.changeStatusProgress = true;
      })
      .addCase(fetchChangeFavoriteStatus.fulfilled, (state, { payload }) => {
        const strategy = state.list?.find((strategyItem) => strategyItem.id === payload.id);
        if (strategy) {
          strategy.show_on_home = payload.show_on_home;
        }
        state.changeStatusProgress = false;
      })
      .addCase(fetchChangeFavoriteStatus.rejected, (state) => {
        state.changeStatusProgress = false;
      });
  },
});

const state = (state: RootState) => state;

export const selectMyStrategies = createSelector(state, (state) => state.myStrategies);

export const {
  initList,
  deleteStrategy,
  changeFavoriteStatus,
  setPage,
  setPerPage,
  setBasketTickersList,
  setFilter,
  resetFilter,
} = myStrategiesSlice.actions;

export default myStrategiesSlice.reducer;
