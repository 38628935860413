import { AdminPanelState } from "@/store/adminPanel/adminPanel";

import { useAppSelector } from "./redux";

const DEFAULT_INFO = "No info for this section yet...";

export const usePageInfo = (key: string) => {
  const { pages } = useAppSelector(AdminPanelState);
  const page = pages?.find((page) => page.key === key);
  return {
    textInfo: page?.meta.infoText || DEFAULT_INFO,
    video: {
      url: page?.video_url || "",
      title: page?.meta.videoTitle || page?.meta.videoFileName || "",
    },
  };
};
