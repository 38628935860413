import { FC } from "react";
import { Link } from "react-router-dom";

import { CenterNavigation } from "@/components";
import { ROUTES } from "@/constants";

import styles from "./HomeMain.module.scss";

export const HomeMain: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.headingGroup}>
          <h1 className={styles.title}>
            Financial AI<span className={styles.symbol}>™</span>
          </h1>
          <h3 className={styles.subtitle}>Your AI Partner in Asset Management</h3>
        </div>
        <CenterNavigation />
        <div className={styles.footerText}>
          <span className={styles.footerNav}>
            <Link to={ROUTES.disclosures.path}>Disclosures</Link> |{" "}
            <Link to={ROUTES.privacyPolicy.path}>Privacy Policy</Link>
          </span>
          <span className={styles.copyright}>© 2024 Product of Big Data Federation, Inc.</span>
        </div>
      </div>
    </div>
  );
};
