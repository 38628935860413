import { FC, Fragment } from "react";

import styles from "./TabsList.module.scss";

export interface ITab {
  name: string;
  icon: JSX.Element | null;
}

interface ITabListProps {
  tabsList: ITab[] | null | undefined;
  active: string | null;
  onSelect: (tab: string) => void;
  disabledList?: string[];
}

export const TabList: FC<ITabListProps> = ({ tabsList, active, onSelect, disabledList }) => {
  return (
    <Fragment>
      {!!tabsList && (
        <div className={styles.tabsContainer}>
          {tabsList?.map(({ name, icon }) => (
            <div
              className={`${styles.tabItem} ${active === name ? styles.active : ""} ${
                disabledList?.includes(name) ? styles.disabled : ""
              }`}
              key={name}
              onClick={() => !disabledList?.includes(name) && onSelect(name)}
            >
              <div className={styles.tabInner}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <span>{name}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};
