import { Dispatch, FC, SetStateAction, useState } from "react";

import { Button, CustomSelect } from "@/components";
import { useOuterClick } from "@/hooks";
import { ReactComponent as FilterIcon } from "@/images/filter.svg";

import styles from "./CompanyFilter.module.scss";

interface IProps {
  etfHoldings: string | undefined;
  setEtfHoldings: Dispatch<SetStateAction<string | undefined>>;
}

const ETF_HOLDINGS = [
  { label: "S&P 500", value: "SPY" },
  { label: "NASDAQ 100", value: "QQQ" },
  { label: "DOW 30", value: "DIA" },
  { label: "RUSSELL 2000", value: "IWM" },
];

export const CompanyFilter: FC<IProps> = ({ etfHoldings, setEtfHoldings }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [refOutside] = useOuterClick(setShowFilter, false);

  const isDisablesReset = !etfHoldings;

  const handleSelectType = (selectd: string) => {
    setEtfHoldings(selectd);
  };

  const handelResetFilter = () => {
    setEtfHoldings(undefined);
  };

  return (
    <div ref={refOutside} className={styles.container}>
      <FilterIcon onClick={() => setShowFilter(!showFilter)} className={styles.icon} />
      {showFilter && (
        <div className={styles.filterContainer}>
          <div className={styles.label}>
            <span>Filtering by</span>
          </div>
          <div className={styles.countrySelect}>
            <div className={styles.field}>
              <span>Index</span>
            </div>
            <CustomSelect onChange={handleSelectType} options={ETF_HOLDINGS} value={etfHoldings} />
          </div>
          <div className={styles.resetFilter}>
            <Button
              disabled={isDisablesReset}
              onClick={handelResetFilter}
              text="Reset Filter"
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};
