import { FC, Fragment, useEffect, useMemo, useRef } from "react";

import { Skeleton } from "@/components/Skeleton/Skeleton";
import { useInViewport } from "@/features/UserOdinChat/hooks/useInViewport";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchInfoTableData, etfIntelligenceState } from "@/store/forecast/forecastEtfIntelligence";
import { IInfoTableItem } from "@/types";
import { splitByGroups } from "@/utils";

import styles from "./InfoTable.module.scss";

const cols = [1, 2, 3];

export const InfoTable: FC = () => {
  const dispatch = useAppDispatch();
  const { requestStr: request, infoTable, category } = useAppSelector(etfIntelligenceState);

  const infoData = infoTable[category];
  const [isInViewport, invewportItemRef] = useInViewportElement(false);
  const isRequested = useRef<string | null>(null);

  const sectionsList = useMemo(() => {
    return splitByGroups<IInfoTableItem>(infoData.data, 3) || [];
  }, [infoData]);

  useEffect(() => {
    if (isRequested.current !== request && isInViewport) {
      dispatch(fetchInfoTableData());
      isRequested.current = request;
    }
  }, [isInViewport, infoData, request]);

  return (
    <div className={styles.container} ref={invewportItemRef}>
      <div className={styles.inner}>
        {infoData.data ? (
          <Fragment>
            {sectionsList.map((list, idx) => (
              <div className={styles.loadingContainer} key={`info-row-${idx}`}>
                <InfoSection sectionList={list} keyIdx={`info-row-${idx}`} />
              </div>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {cols.map((_, idx) => (
              <Skeleton active rows={4} loading key={idx}>
                <div className={styles.loadingContainer} key={`skeleton-${idx}`}></div>
              </Skeleton>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

interface IInfoSectionProps {
  sectionList: IInfoTableItem[];
  keyIdx: string;
}

const InfoSection: FC<IInfoSectionProps> = ({ sectionList, keyIdx }) => {
  const formatingValue = (item: IInfoTableItem) => {
    return item.value === null
      ? "NA"
      : item.postfix === "B"
      ? `${Number(item.value / 1000).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}${item.postfix}`
      : item.postfix
      ? `${Number(item.value).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}${item.postfix}`
      : item.value.toLocaleString("en-US");
  };
  return (
    <Fragment>
      {sectionList.map((item, idx) => (
        <p className={styles.item} key={item.name.concat(keyIdx, idx.toString())}>
          <span>{item.name}</span>
          <span>
            {item.prefix}
            {formatingValue(item)}
          </span>
        </p>
      ))}
    </Fragment>
  );
};
