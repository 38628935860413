import { FC } from "react";

import { ContentLayout, FooterLayout } from "@/components";
import { AdminPanel } from "@/features/AdminPanel/AdminPanel";

export const AdminPanelPage: FC = () => {
  return (
    <FooterLayout>
      <ContentLayout>
        <AdminPanel />
      </ContentLayout>
    </FooterLayout>
  );
};
