import { useCallback, useMemo } from "react";

import { ItemType } from "@/components";
import { directionList } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { setDirection, setTicker, strategiesState } from "@/store/strategies/strategies";
import { ITickersKeyValueData } from "@/types";
import { findDropdownItem } from "@/utils";

export const useCryptosForm = (tickersList: ITickersKeyValueData) => {
  const {
    newStartegyForm: { direction, tradingInstrument, ticker },
  } = useAppSelector(strategiesState);

  const dispatch = useAppDispatch();

  const cryptosTickersList = useMemo(() => {
    return [...tickersList.cryptos].sort((a, b) => a.value.localeCompare(b.value));
  }, [tickersList]);

  const selectTickerHandler = useCallback(
    (name: string, item: ItemType<string>) => {
      if (tickersList.cryptos.find((i) => i.value === item.value))
        dispatch(setTicker({ ticker: item.value, type: "cryptos" }));
    },
    [tickersList.cryptos]
  );

  const selectDirectionHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setDirection(item.key));
  }, []);

  const selectedDirection = useMemo(
    () => directionList.find((item) => item.key === direction) || null,
    [direction, directionList]
  );

  const selectedTicker = useMemo(() => {
    if (ticker.cryptos) return findDropdownItem(ticker.cryptos, cryptosTickersList);
    return null;
  }, [ticker.cryptos, cryptosTickersList]);

  const disableOptions = !tradingInstrument;

  return {
    cryptosTickersList,
    selectTickerHandler,
    selectedTicker,
    disableOptions,
    selectDirectionHandler,
    directionList,
    selectedDirection,
  };
};
