import { FC, Fragment, memo } from "react";

import { Chart, ExtendedColumns, Heading, Table } from "@/components";
import { ISeries } from "@/types";

import styles from "./PerformanceResult.module.scss";

interface IProps {
  isEmptyResult?: boolean;
  isPreviewReady: boolean;
  chartData: ISeries[];
  tableData: Record<string, string | null>[];
  columns: ExtendedColumns;
  heading?: string;
  fileTitleName?: string;
}

export const PerformanceResult: FC<IProps> = memo(
  ({ isEmptyResult, isPreviewReady, chartData, tableData, columns, heading, fileTitleName }) => {
    if (isEmptyResult) return null;

    return (
      <Fragment>
        {isPreviewReady ? (
          <Fragment>
            <Heading type="h3" className={styles.subHeading}>
              {heading ? heading : "Performance"}
            </Heading>
            <div className={styles.chartpreviewContainer}>
              {chartData.some((i) => i.data.length > 0) ? (
                <Chart data={chartData} fileTitleName={fileTitleName} />
              ) : (
                <div className={styles.emptyData}>Chart Data not available yet.</div>
              )}
            </div>
            <div className={styles.tablePreviewContainer}>
              <Heading type="h3" className={styles.subHeading}>
                Metrics
              </Heading>
              <div className={styles.tablePreviewWrapper}>
                <Table data={tableData} columns={columns} size="medium" isNotClickable />
              </div>
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
);
