import React, { FC, useCallback, useState } from "react";

import styles from "./SolutionSlider.module.scss";

export interface ISolutionSliderItem {
  key: string;
  image: string;
}

interface IProps {
  list: ISolutionSliderItem[];
}

export const SolutionSlider: FC<IProps> = ({ list }) => {
  const [active, setActive] = useState<ISolutionSliderItem>(list[0] || null);

  const selectHandle = (item: ISolutionSliderItem) => {
    setActive(item);
  };

  const nextSlideHandle = () => {
    const index = list.indexOf(active);
    const next = list[index + 1] || list[0];
    setActive(next);
  };

  const prevSlideHandle = () => {
    const index = list.indexOf(active);
    if (index === 0 && list.length) {
      setActive(list[list.length - 1]);
    } else {
      const next = list[index - 1] || list[0];
      setActive(next);
    }
  };

  const SlideImage = useCallback(() => {
    return (
      <div className={styles.imageWrapper}>
        {active ? <img src={active.image} alt={active.key} /> : null}
      </div>
    );
  }, [list, active]);

  const getActiveStyle = (key: string) => {
    return active && key === active.key ? styles.active : "";
  };

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.navArrowLeft} onClick={prevSlideHandle}>
          <button>1</button>
        </div>
        <div className={styles.navArrowRight} onClick={nextSlideHandle}>
          <button></button>
        </div>
        <SlideImage />
      </div>
      <ul className={styles.navigation}>
        {list.map((item, index) => (
          <li
            className={`${styles.navItem} ${getActiveStyle(item.key)}`}
            key={index}
            onClick={() => selectHandle(item)}
          ></li>
        ))}
      </ul>
    </div>
  );
};
