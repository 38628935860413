import { FC } from "react";

import styles from "./EmptySelected.module.scss";

import BGImage from "@images/bg-fill.jpg";

interface IProps {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  text: string;
}

export const EmptySelected: FC<IProps> = ({ Icon, title, text }) => {
  return (
    <div className={styles.emptyContainer} style={{ backgroundImage: `url(${BGImage})` }}>
      <div className={styles.imageContainer}>
        <Icon />
      </div>
      <div className={styles.textContainer}>
        <p>{title}</p>
        <p>{text}</p>
      </div>
    </div>
  );
};
