import { useState, useEffect } from "react";

export const useFilterByCriteria = <T>(
  criteriaList: string[],
  dataArray: T[],
  inputKeywords = ""
) => {
  const [filteredAndSortedDataArray, setFilteredAndSortedDataArray] = useState<T[]>([]);

  useEffect(() => {
    const filterAndSortData = () => {
      const lowerKeywords = inputKeywords?.toLowerCase();

      const filteredArray = dataArray.filter((dataObject) =>
        criteriaList.some((criteria) =>
          (dataObject as any)[criteria]?.toLowerCase().includes(lowerKeywords)
        )
      );

      if (lowerKeywords && criteriaList.length > 0) {
        filteredArray.sort((a, b) => {
          const lowerValueA = criteriaList
            .map((criteria) => (a as any)[criteria]?.toLowerCase())
            .join(" ");

          const lowerValueB = criteriaList
            .map((criteria) => (b as any)[criteria]?.toLowerCase())
            .join(" ");

          const startsWithA = lowerValueA.startsWith(lowerKeywords);
          const startsWithB = lowerValueB.startsWith(lowerKeywords);

          if (startsWithA && !startsWithB) {
            return -1;
          } else if (!startsWithA && startsWithB) {
            return 1;
          } else {
            return lowerValueA.localeCompare(lowerValueB);
          }
        });
      }

      setFilteredAndSortedDataArray(filteredArray);
    };

    filterAndSortData();
  }, [inputKeywords, dataArray, criteriaList.length]);

  return filteredAndSortedDataArray;
};
