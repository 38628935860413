import { FC, useCallback, useEffect, useState } from "react";

import { DateObj, DatePickerRange, Heading, InputItem } from "@/components";
import { EconomicCalendarTable } from "@/features/DataPortal/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { economicCalendarState, getEconomicCalendar } from "@/store/dataBrowser/economicCalendar";
import { IEconomicCalendar } from "@/types";
import { getDate, getDateISOString } from "@/utils";

import { useSearch } from "../../hooks/useSearch";
import { Filter } from "../Filter/Filter";

import styles from "./EconomicCalendar.module.scss";

import { ReactComponent as EconomicCalendarIcon } from "@images/events-calendar-icon.svg";

interface IDate {
  start: string;
  end: string;
}

const SEARCH_FIELD_KEYS: Array<keyof IEconomicCalendar> = ["name"];

const date = getDate();

interface IEconomicCalendarProps {
  data?: any;
}

export const EconomicCalendar: FC<IEconomicCalendarProps> = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(economicCalendarState);

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [EconomicCalendarRange, setEconomicCalendarRange] = useState<IDate>(() => ({
    start: date.currentDate,
    end: date.futureDate,
  }));

  const { search, filteredData, searchHandler } = useSearch<IEconomicCalendar>(
    data,
    SEARCH_FIELD_KEYS
  );

  const handleSelectEconomicCalendarRange = useCallback((range: DateObj) => {
    setEconomicCalendarRange({
      start: getDateISOString(range.start),
      end: getDateISOString(range.end),
    });
  }, []);

  useEffect(() => {
    dispatch(
      getEconomicCalendar({
        start: EconomicCalendarRange.start,
        end: EconomicCalendarRange.end,
        country: selectedCountry,
      })
    );
  }, [EconomicCalendarRange, dispatch, selectedCountry]);

  return (
    <section className={styles.section}>
      <div className={styles.headingGroup}>
        <EconomicCalendarIcon />
        <Heading type={"h3"}>Economic calendar</Heading>
      </div>
      <div className={styles.innerWrapper}>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => searchHandler(ev.target.value)}
            value={search}
            label="Search Event"
            name="search"
            type="search"
            placeholder="Event name"
            noPaddingBottom
            disabled={isLoading}
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.picker}>
            <DatePickerRange
              label="Select Date interval"
              onSelect={handleSelectEconomicCalendarRange}
              date={EconomicCalendarRange}
              MaxDateDisabled
            />
          </div>
          <Filter selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </div>
        <EconomicCalendarTable
          EconomicCalendarData={search ? filteredData : data}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
};
