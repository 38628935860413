import { FC, Fragment } from "react";

import { MetricsTable, NoData } from "@/components";
import { useAppSelector } from "@/hooks/redux";
import { portfolioBuilderState } from "@/store/portfolios/portfolioBuilder";

import styles from "./PortfolioMetrics.module.scss";

export const PortfolioMetrics: FC = () => {
  const { results, isLoading, isPerformanceView } = useAppSelector(portfolioBuilderState);

  return (
    <Fragment>
      {!isLoading && isPerformanceView ? (
        <Fragment>
          {Object.values(results?.metrics || {})?.length ? (
            <div className={styles.container}>
              <MetricsTable metrics={results?.metrics} />
            </div>
          ) : (
            <div className={styles.noDataContainer}>
              <p className={styles.subTitle}>Metrics</p>
              <NoData text="No Data" />
            </div>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
};
