import { Children, FC, Fragment, useMemo, useRef } from "react";

import { cardFormatRules, IFormat } from "@/constants";
import { ITicker, ValueType } from "@/types/home";

import { useSlider } from "./hooks/useSlider";
import { useTickersData } from "./hooks/useTickersData";

import styles from "./LiveMarketSlider.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";

const createEmpty = (): ITicker => ({
  index: "",
  close: null,
  close_perc_change: null,
  date: "",
  last_updated: "",
  prefix: null,
  valueType: "number",
});

const numberFormat = (value: number, floatDigits: number) => {
  return new Intl.NumberFormat("en-us", {
    minimumFractionDigits: floatDigits,
    maximumFractionDigits: floatDigits,
  }).format(Number(value.toFixed(floatDigits)));
};

const formattingValueToString = (
  value: number | null = null,
  floatDigits: number,
  isSigned: boolean,
  postfix?: string
): string => {
  let resultValue = "-";
  if (value !== null) {
    const num = numberFormat(value, floatDigits);
    resultValue = Number(num) === 0 ? "0" : Number(num) > 0 ? (isSigned ? "+" + num : num) : num;
    resultValue = resultValue + (postfix || "");
  }
  return resultValue;
};

export const Carousel: FC<{ children: React.ReactNode[]; refList: HTMLDivElement[] }> = ({
  children,
  refList,
}) => {
  const { actionHandler, containerRef, mainContainerRef, isMobile } = useSlider(children, refList);

  const slides = useMemo(() => {
    if (children.length > 1) {
      const items =
        Children.map(children, (child, idx) => <Fragment key={idx}>{child}</Fragment>) || [];

      return [...items, <Fragment key="last-item">{children[0]}</Fragment>];
    }

    return <Fragment>{children[0]}</Fragment>;
  }, [children]);

  return (
    <div className={styles.container}>
      <div
        className={styles.actionContainrt}
        onClick={() => (isMobile ? undefined : actionHandler())}
      >
        <Arrow />
      </div>
      <div
        className={styles.inner}
        ref={mainContainerRef}
        onClick={() => (isMobile ? actionHandler() : undefined)}
      >
        <div className={styles.wrapper} ref={containerRef}>
          {slides}
        </div>
      </div>
    </div>
  );
};

export const LiveMarketSlider: FC = () => {
  const { tickersList } = useTickersData();
  const refItemsArr = useRef<HTMLDivElement[]>([]);
  const getRefItem = (el: HTMLDivElement | null) => {
    if (el && !refItemsArr.current.includes(el)) {
      refItemsArr.current.push(el);
    }
  };

  const indicesList = tickersList.indices;

  const marketYeldTickersList =
    tickersList.marketYeld?.map((ticker) => ({
      ...createEmpty(),
      index: ticker.source_name,
      close_perc_change: ticker.close_perc_change,
      close: ticker.value,
      valueType: "percent" as ValueType,
    })) || null;

  const commoditiesTickersList =
    cardFormatRules.commodities.order?.map((key) => {
      const ticker = tickersList.commodities?.find((t) => t.name === key);
      if (ticker)
        return {
          ...createEmpty(),
          index: ticker.name,
          close: ticker.value,
          close_perc_change: ticker.close_perc_change,
        };
      return null;
    }) || null;

  const ExRatesTickersList =
    tickersList.exchangeRates?.map((ticker) => ({
      ...createEmpty(),
      index: ticker.currency,
      close: ticker.rate,
      close_perc_change: ticker.close_perc_change,
      prefix: ticker.currency_sign,
    })) || null;

  return (
    <Carousel refList={refItemsArr.current}>
      <div className={styles.groupContainer} ref={getRefItem}>
        {indicesList?.map((tickerCard) => (
          <Fragment key={`indices-${tickerCard.index}`}>
            <SlideCardIndices card={tickerCard} format={cardFormatRules.indices} />
          </Fragment>
        )) || <Empty />}
      </div>
      <div className={styles.groupContainer} ref={getRefItem}>
        {marketYeldTickersList?.map((tickerCard) => (
          <Fragment key={`market-yeld-${tickerCard.index}`}>
            <SlideCardIndices card={tickerCard} format={cardFormatRules["market-yeld"]} />
          </Fragment>
        )) || <Empty />}
      </div>
      <div className={styles.groupContainer} ref={getRefItem}>
        {commoditiesTickersList
          ?.filter((i) => i !== null)
          .map((tickerCard) => (
            <Fragment key={`commodities-${tickerCard?.index}`}>
              <SlideCardIndices card={tickerCard} format={cardFormatRules.commodities} />
            </Fragment>
          )) || <Empty />}
      </div>
      <div className={styles.groupContainer} ref={getRefItem}>
        {ExRatesTickersList?.map((tickerCard, idx) => (
          <Fragment key={`extra-rates-${idx}`}>
            <SlideCardIndices card={tickerCard} format={cardFormatRules["extra-rates"]} />
          </Fragment>
        )) || <Empty />}
      </div>
    </Carousel>
  );
};

const Empty = () => {
  return (
    <Fragment>
      {Array(5)
        .fill("")
        .map((_, idx) => (
          <SlideCardIndices key={idx} format={null} />
        ))}
    </Fragment>
  );
};

interface ISlideCardProps {
  card?: (ITicker & { value?: number | null }) | null;
  format: IFormat | null;
}

export const SlideCardIndices: FC<ISlideCardProps> = ({ card, format }) => {
  const { index, close, close_perc_change, valueType } = card || {};
  const {
    floatDigits = { value: 2, change: 2 },
    postfix = { value: "", change: "" },
    replace = {},
  } = format || {};
  const closeTextValue = formattingValueToString(close, floatDigits.value, false, postfix.value);
  const changeTextValue = formattingValueToString(
    close_perc_change,
    floatDigits.change,
    true,
    postfix.change
  );
  const indexName = replace && index ? replace[index] || index : index;

  return (
    <div className={styles.cardContainer}>
      {close_perc_change !== undefined && close !== undefined ? (
        <div className={styles.innerContainer}>
          <span>
            <p>{indexName}</p>
          </span>
          <div className={styles.row}>
            <span className={`${close === null ? styles.noValue : ""}`}>{closeTextValue}</span>
            {close_perc_change !== null && (
              <span
                className={`${
                  close_perc_change === null || Number.isNaN(Number.parseFloat(changeTextValue))
                    ? styles.noValue
                    : Number(Number.parseFloat(changeTextValue)) === 0
                    ? styles.zero
                    : Number(Number.parseFloat(changeTextValue)) > 0
                    ? styles.positive
                    : styles.negative
                }`}
              >
                {changeTextValue}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.noDataContainer}>
          <div className={styles.inner}>
            <div className={styles.skeleton}></div>
            <div className={styles.skeleton}></div>
          </div>
        </div>
      )}
    </div>
  );
};
