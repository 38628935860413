import { FC } from "react";

import { ReactComponent as InfoIcon } from "@/images/info-white.svg";

import styles from "./CustomLabel.module.scss";

interface IProps {
  onClick: () => void;
  label: string;
  excludeId?: string;
}

export const CustomUploadLabel: FC<IProps> = ({ onClick, label, excludeId = "unset" }) => {
  return (
    <div className={styles.labelWrapper}>
      <span>{label}</span>
      <span className={styles.infoIconContainer} onClick={onClick} data-exclude-id={excludeId}>
        <InfoIcon />
      </span>
    </div>
  );
};
