import { useEffect, useRef } from "react";

import { useMediaQuery } from "@/hooks/useMediaQuery";

export const useSlideScroll = (keyId?: string) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const getScrollWidth = () => {
    return (containerRef.current?.scrollWidth || 0) > (containerRef.current?.clientWidth || 0);
  };

  const mouseEnterHandler = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isScroll = getScrollWidth();
    if (!isMobile && isScroll) {
      const parent = document.getElementById("main-scroll-layout");
      parent?.setAttribute("style", "overflow-y: hidden");
    }
  };

  const mouseLeaveHandler = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isMobile) {
      const parent = document.getElementById("main-scroll-layout");
      parent?.removeAttribute("style");
    }
  };

  const onMouseWheelHandler = (ev: React.WheelEvent<HTMLDivElement>) => {
    const isScroll = getScrollWidth();
    if (ev.deltaY && !isMobile && isScroll) {
      const shift = ev.deltaY * 5 + (containerRef.current?.scrollLeft || 0);
      containerRef.current?.scrollTo({
        left: shift,
      });
    }
  };

  useEffect(() => {
    if (!getScrollWidth() || isMobile) {
      document
        .querySelector(`[data-action="arrow-${keyId}"]`)
        ?.setAttribute("style", "display: none;");
    }
  }, [isMobile, keyId]);

  return {
    mouseEnterHandler,
    mouseLeaveHandler,
    onMouseWheelHandler,

    isMobile,
    containerRef,
  };
};
