import { FC } from "react";

import { TooltipWrapper } from "@/components";
import { notification } from "@/utils";

import styles from "./TextCopy.module.scss";

import { ReactComponent as CopyIcon } from "@images/copy.svg";

interface ITextCopyProps {
  children: React.ReactNode;
  copyText?: string | null;
  helpText?: string;
  actionText?: string;
}

export const TextCopy: FC<ITextCopyProps> = ({ children, copyText, helpText, actionText }) => {
  const copyHandler = () => {
    if (copyText)
      navigator.clipboard.writeText(copyText).then(() => {
        if (actionText) notification.success(actionText);
      });
  };

  return (
    <div className={styles.copyTextContainer} onClick={copyHandler}>
      {children}
      {!!copyText && (
        <div className={styles.copyTextImageWrapper}>
          <TooltipWrapper text={helpText}>
            <CopyIcon />
          </TooltipWrapper>
        </div>
      )}
    </div>
  );
};
