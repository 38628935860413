import { createSlice, createAsyncThunk, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { paginator } from "@/constants";
import { strategiesService } from "@/services";
import { ICommonStrategyData } from "@/types";

import { RootState } from "..";

interface IInitialState {
  isLoading: boolean;
  list: ICommonStrategyData[] | null;
  hasMore: boolean;
  page: number;
  perPage: number;
  requestingPage: number;
}

const initialState: IInitialState = {
  isLoading: false,
  list: null,
  hasMore: true,
  page: paginator.page,
  perPage: paginator.default,
  requestingPage: 1,
};

const DEFAULT_PER_PAGE = 20;

export const fetchCommonStrategies = createAsyncThunk(
  "commonStrategies/list",
  async (_, { getState }) => {
    const {
      commonStrategies: { requestingPage },
    } = getState() as RootState;
    const { data } = await strategiesService.fetchStrategyWithEquitiesList(requestingPage);
    return data?.result;
  }
);

export const searchCommonStrategies = createAsyncThunk(
  "searchCommonStrategies/list",
  async (queryParam: string) => {
    const { data } = await strategiesService.searchStrategyWithEquitiesList(queryParam);
    return data?.result;
  }
);

export const commonStrategiesSlice = createSlice({
  name: "commonStrategies",
  initialState,
  reducers: {
    init: (state) => {
      state.isLoading = false;
    },
    resetList: (state) => {
      state.list = null;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonStrategies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCommonStrategies.fulfilled, (state, { payload }) => {
        state.hasMore = !!payload?.length;
        if (state.hasMore) {
          const list = state.list
            ? state.list.map((i) => [i.id, i] as [number, ICommonStrategyData])
            : [];
          const isNotEmpty = state.list !== null;
          const additionalList = payload.map((i) => [i.id, i] as [number, ICommonStrategyData]);
          const allItemsMapList = new Map([...list, ...additionalList]);
          state.list = Array.from(allItemsMapList).map(([_, item]) => item);

          state.requestingPage++;
          const totalPages = Math.ceil(state.list.length / state.perPage);
          if (isNotEmpty) state.page = totalPages;
          if (payload?.length < DEFAULT_PER_PAGE) state.hasMore = false;
        }
        state.isLoading = false;
      })
      .addCase(fetchCommonStrategies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchCommonStrategies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchCommonStrategies.fulfilled, (state, { payload }) => {
        if (payload) {
          state.list = payload;
        }
        state.hasMore = false;
        state.page = 1;
        state.requestingPage = 1;
        state.isLoading = false;
      })
      .addCase(searchCommonStrategies.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetList, setPage, setPerPage } = commonStrategiesSlice.actions;

const state = (state: RootState) => state;

export const commonStrategiesState = createSelector(state, (state) => state.commonStrategies);

export default commonStrategiesSlice.reducer;
