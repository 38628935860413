import { useCallback, useState } from "react";

import { UserService } from "@/services";
import { notification, validate } from "@/utils";

const formInit: Record<"email", { value: string; error: string | null }> = {
  email: {
    value: "",
    error: "",
  },
};

export const useSubscribeForm = () => {
  const [form, setForm] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const subscribeRequest = async (subscribeData: { email: string }) => {
    try {
      const { data } = await UserService.subscribeRequest(subscribeData);
      setMessage("You are successful subscribed.");
    } catch (e: any) {
      notification.error("Sorry, some error occurred during subscription! Pleas, try later.", true);
      setForm((prev) => ({ email: { ...prev.email, error: "Subscription failed!" } }));
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { email } = form;
    const [validatedForm, isValid] = validate(form);
    if (isValid) {
      setLoading(true);
      subscribeRequest({ email: email.value });
    } else {
      setForm(validatedForm);
    }
  };

  const changeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = ev.target.value;
      const fieldKey = ev.target.name;
      setForm((prev) => ({
        ...prev,
        [fieldKey]: { error: null, value: inputValue },
      }));
    },
    []
  );

  return { form, loading, message, submitHandler, changeHandler };
};
