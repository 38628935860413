import { useMemo } from "react";

import { ExtendedColumns, ItemType } from "@/components";
import { DateType, ICompareResponseData, ISeries } from "@/types";
import {
  createAliases,
  createCompareChartData,
  createCompareTableColumns,
  createCompareTableData,
  filterResultByDate,
} from "@/utils/compare";

interface IConvertResultData {
  result: ICompareResponseData | null;
  base: ItemType<string> | null;
  list: ItemType<string>[] | null;
  isReady: boolean;
  date: DateType | null;
}

export const useConvertResultData = (data: IConvertResultData) => {
  const { result, base, list, isReady, date } = data;

  const columnAliases = useMemo(() => {
    if (isReady && base && list && result) {
      return createAliases(base, list, result);
    }
    return [];
  }, [result, isReady]);

  const tableData = useMemo(() => {
    if (isReady && result) {
      return createCompareTableData(result, columnAliases);
    }
    return [];
  }, [result, isReady, columnAliases]);

  const chartData = useMemo(() => {
    let compareResult: ISeries[] = [];
    if (isReady && result) {
      compareResult = createCompareChartData(result, columnAliases);
    } else return [];
    if (date) {
      return filterResultByDate(compareResult, date);
    }
    return compareResult;
  }, [result, isReady, columnAliases, date]);

  const columns: ExtendedColumns = useMemo(
    () => (result ? createCompareTableColumns(columnAliases.map(([_, alias]) => alias)) : []),
    [result]
  );

  return { tableData, chartData, columns };
};
