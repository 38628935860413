import { ChangeEvent, FC, useEffect, useState } from "react";

import { InputItem } from "@/components";
import { useAppDispatch } from "@/hooks";
import { searchDrs } from "@/store/discover/discoverSearch";
import { debounce } from "@/utils";

import styles from "./Search.module.scss";

// interface IProps {}

export const Search: FC = () => {
  const [inputKeywords, setInputKeywords] = useState<string>();
  const dispatch = useAppDispatch();

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputKeywords(value);
    if (3 <= value.length) {
      dispatch(searchDrs({ query: value }));
    }
  }, 1000);

  const handleEnter = () => {
    if (inputKeywords && 3 <= inputKeywords.length) {
      dispatch(searchDrs({ query: inputKeywords }));
    }
  };

  return (
    <div className={styles.searchWrapper}>
      <InputItem
        onChange={handleSearch}
        onEnter={handleEnter}
        label=""
        name=""
        type="search"
        placeholder="Search"
        noPaddingBottom
      />
    </div>
  );
};
