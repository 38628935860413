import { AxiosResponse } from "axios";

import { IKeyDriversResponse } from "@/types/keyDrivers";

import axios from "./axios.config";

export const KeyDriversService = {
  fetchKeyDriverCharts: (ticker: string): Promise<AxiosResponse<IKeyDriversResponse>> =>
    axios.get(`/key-drivers?ticker=${ticker}`),
};
