import { AxiosResponse } from "axios";

import {
  ApprovalStatus,
  IAdminPanelDocuments,
  IAdminPanelDocumentsOrderingUpdate,
  IAdminPanelPages,
  IApproval,
  ICreatePanelDoc,
  ICreatePanelPages,
} from "@/types/adminPanel";

import axios from "./axios.config";

export const AdminPanelService = {
  createPages: (payload: ICreatePanelPages): Promise<AxiosResponse<{ result: IAdminPanelPages }>> =>
    axios.post("/admin/pages", payload),
  getPages: (): Promise<AxiosResponse<{ result: IAdminPanelPages[] }>> => axios.get(`/admin/pages`),
  deletePage: (pageId: number): Promise<AxiosResponse<{ result: IAdminPanelPages }>> =>
    axios.delete(`admin/pages/${pageId}`),
  updatePage: (
    pageId: number,
    payload: { key: string; meta: any }
  ): Promise<AxiosResponse<{ result: IAdminPanelPages }>> =>
    axios.put(`admin/pages/${pageId}`, payload),
  uploadVideo: (pageId: number, payload: any): Promise<AxiosResponse<any>> =>
    axios.put(`admin/pages/${pageId}/upload-video`, payload),
  createDocuments: (payload: ICreatePanelDoc): Promise<AxiosResponse<any>> =>
    axios.post("/admin/documents", payload),
  getDocuments: (): Promise<AxiosResponse<{ result: IAdminPanelDocuments[] }>> =>
    axios.get(`/admin/documents`),
  deleteDocument: (docId: number): Promise<AxiosResponse<{ result: IAdminPanelDocuments }>> =>
    axios.delete(`admin/documents/${docId}`),
  updateDocumentsOrdering: (
    payload: IAdminPanelDocumentsOrderingUpdate
  ): Promise<AxiosResponse<{ result: IAdminPanelDocuments }>> =>
    axios.put("admin/documents", payload),
  uploadDocument: (
    docId: number,
    payload: FormData
  ): Promise<AxiosResponse<{ result: IAdminPanelDocuments }>> =>
    axios.put(`admin/documents/${docId}/upload-file`, payload),
  getDocument: (id: number) =>
    axios(`/admin/documents/${id}/download`, {
      method: "get",
      responseType: "blob",
    }),
  getApprovalsList: (): Promise<AxiosResponse<{ result: IApproval[] }>> =>
    axios.get(`/admin/approval-list`),
  updateApprovalStatus: (
    data: { status: ApprovalStatus },
    id: string
  ): Promise<AxiosResponse<{ result: IApproval }>> => axios.put(`/admin/approval-list/${id}`, data),
};
