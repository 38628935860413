import Highcharts from "highcharts";

import { CHART_COLORS } from "@/constants";
import { IPieChartItem } from "@/types";

import { menuSlice } from "./../store/menu";

export const getPieChartOptions = (
  data1: IPieChartItem[] | null,
  data2: IPieChartItem[] | null,
  name: string
): Highcharts.Options[] => {
  const createSeriesData = (data: IPieChartItem[] | null) =>
    data?.map(({ name, value }, idx) => ({
      name,
      y: value,
      sliced: !idx,
      overflow: "allow",
      crop: false,
    }));

  const createOptions = (
    serieData: IPieChartItem[] | null,
    legendTitle: string
  ): Highcharts.Options => ({
    time: {
      useUTC: false,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      height: undefined,
      spacingTop: 0,
      marginBottom: 10,
      marginTop: 120,

      plotShadow: false,
      type: "pie",
      style: {
        fontFamily: "Inter",
      },
    },
    colors: CHART_COLORS,

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        // size: 200,
        minSize: 150,
      },
      series: {
        animation: {
          duration: 300,
        },
      },
    },
    series: [
      {
        name,
        type: "pie",
        data: createSeriesData(serieData),
      },
    ],
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    legend: {
      title: {
        text: legendTitle,
        style: {
          color: "#6a7a86",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      enabled: true,
      align: "left",
      verticalAlign: "top",
      layout: "vertical",
      labelFormat: "<b>{name}</b>: {percentage:.1f} %",
      x: -15,
      y: 0,
      maxHeight: 120,
      width: 150,
      floating: true,
    },
  });
  const option1 = createOptions(data1, "By Number");
  const option2 = createOptions(data2, "By AUM");
  if (option2.legend) option2.legend.align = "right";

  return [option1, option2];
};
