import React, { FC, Fragment } from "react";

import { HeaderNavigation } from "@/components/HeaderNavigation/HeaderNavigation";

interface IProps {
  children: React.ReactNode;
}

export const HomeNavLayout: FC<IProps> = ({ children }) => {
  return (
    <Fragment>
      <HeaderNavigation />
      <Fragment>{children}</Fragment>
    </Fragment>
  );
};
