import { FC, useMemo } from "react";
import { createFilter } from "react-select";

import { CustomSelect } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as BenchmarkIcon } from "@/images/benchmark.svg";
import { BenchmarksState } from "@/store/portfolios/benchmarks";
import { portfolioBuilderState, setBenchmark } from "@/store/portfolios/portfolioBuilder";
import { SelectValueType } from "@/types";
import { getBenchmarkName } from "@/utils";

import styles from "./Benchmark.module.scss";

export const BenchmarkEdit: FC = () => {
  const dispatch = useAppDispatch();
  const { results, isLoading: benchmarkLoading } = useAppSelector(BenchmarksState);
  const { benchmark } = useAppSelector(portfolioBuilderState);

  const handleSetBanchmark = (v: SelectValueType) => {
    dispatch(setBenchmark([v as string]));
  };

  const benchmarkOptions = useMemo(
    () =>
      results?.map((benchmark) => ({
        value: benchmark.ticker,
        label: benchmark.name,
      })),
    [results]
  );

  return (
    <section className={styles.section}>
      <p>Benchmark</p>
      <div className={`${styles.container} ${styles.benchmarkEdit}`}>
        <BenchmarkIcon />
        <table>
          <tbody>
            <tr>
              <td>
                <div className={styles.benchmark}>
                  <CustomSelect
                    label=""
                    placeholder="Type"
                    isLoading={benchmarkLoading}
                    options={benchmarkOptions}
                    onChange={handleSetBanchmark}
                    value={getBenchmarkName(results, benchmark[0])}
                    isClearable
                    filterOption={createFilter({ ignoreAccents: false })}
                    noPaddingBottom
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
