import { AxiosResponse } from "axios";

import {
  IChartMeta,
  IDividendYieldItem,
  IETFTableItem,
  IExpenseRatioItem,
  IInfoTableItem,
  IMetaDate,
  INewETFTableItem,
  ITickerData,
  IExtendMetaData,
} from "@/types/etfIntelligence";

import axios from "./axios.config";

const STRATEGY = "&strategy=";
const CATEGORY = "&category=";
const ASSETS = "&style=";

export const EtfIntelligenceService = {
  getInfoTableData: (meta: IExtendMetaData): Promise<AxiosResponse<{ result: IInfoTableItem[] }>> =>
    axios.get(
      "/etf/?type=totals".concat(
        CATEGORY,
        meta.category,
        STRATEGY,
        meta.management,
        ASSETS,
        meta.assets || ""
      )
    ),
  getInfoMeta: (): Promise<AxiosResponse<IChartMeta[]>> => axios.get("/home/charts"),
  getInfoDate: (): Promise<AxiosResponse<IMetaDate>> => axios.get("/etf/?type=last_updated"),
  getTickersList: (
    meta: {
      query: string;
      url: string | null;
    } & IExtendMetaData
  ): Promise<
    AxiosResponse<{ result: ITickerData | IExpenseRatioItem[] | IDividendYieldItem[] }>
  > => {
    const urlLink = meta.url
      ? "/etf/".concat(
          meta.url.replace("{Category}", meta.category),
          STRATEGY,
          meta.management,
          ASSETS,
          meta.assets || ""
        )
      : "/etf/?type=top&filter_by=".concat(
          meta.query,
          CATEGORY,
          meta.category,
          STRATEGY,
          meta.management,
          ASSETS,
          meta.assets || ""
        );
    return axios.get(urlLink);
  },
  getETFTableData: (meta: IExtendMetaData): Promise<AxiosResponse<{ result: IETFTableItem[] }>> =>
    axios.get(
      "/etf/?type=return_vol".concat(
        CATEGORY,
        meta.category,
        STRATEGY,
        meta.management,
        ASSETS,
        meta.assets || ""
      )
    ),
  getChartData: <U>(
    meta: { query: string } & IExtendMetaData
  ): Promise<AxiosResponse<{ result: U }>> =>
    axios.get("/".concat(meta.query, STRATEGY, meta.management, ASSETS, meta.assets || "")),
  getNewETFTableData: (
    meta: IExtendMetaData
  ): Promise<AxiosResponse<{ result: INewETFTableItem[] }>> =>
    axios.get(
      "/etf/?type=new".concat(
        CATEGORY,
        meta.category,
        STRATEGY,
        meta.management,
        ASSETS,
        meta.assets || ""
      )
    ),
};
