import { ItemType } from "@/components";
import { IProfilePagePermissionType } from "@/types";

export const productList: ItemType<string>[] = [
  {
    key: "modeling",
    value: "Modeling Product",
  },
  {
    key: "forecasts",
    value: "Forecast Product",
  },
  {
    key: "odinchat",
    value: "OdinChat Product",
  },
  {
    key: "market_view",
    value: "Market View",
  },
];

export type ProductTypes = "modeling" | "forecasts" | "odinchat";

export const statusList: ItemType<IProfilePagePermissionType>[] = [
  {
    key: "active",
    value: "Active",
  },
  {
    key: "inactive",
    value: "Inactive",
  },
  {
    key: "hidden",
    value: "Hidden",
  },
];

export const statusRadioList = statusList.map(({ key, value }) => ({ value: key, label: value }));
