import React from "react";

import styles from "./Tag.module.scss";

interface TagProps {
  children: React.ReactNode;
  color?: "default" | "primary";
  onClick?: () => void;
  onDelete?: () => void;
  canDelete?: boolean;
}

export const Tag: React.FC<TagProps> = ({
  children,
  color = "default",
  onClick,
  onDelete,
  canDelete = true,
}) => {
  const tagClasses = [styles.tag];

  if (color === "primary") {
    tagClasses.push(styles["tag-primary"]);
  }

  return (
    <div className={tagClasses.join(" ")} onClick={onClick}>
      {children}
      {canDelete && onDelete && (
        <div
          className={styles.deleteIcon} // Apply the delete icon SCSS class
          onClick={onDelete}
        />
      )}
    </div>
  );
};
