import { useEffect } from "react";

export const useGetSrollBarWidth = () => {
  useEffect(() => {
    const div = document.createElement("div");
    div.setAttribute(
      "style",
      "position: fixed; left: 0; right: 0; bottom: 50px; height: 1px; overflow: scroll; background-color: red;"
    );

    document.body.appendChild(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.setAttribute("style", `--sidebar-width: ${scrollWidth}px;`);

    div.remove();
  }, []);
};
