export interface ITicker {
  index: string;
  close: number | null;
  close_perc_change: number | null;
  date: string;
  last_updated: string;
  prefix: string | null;
  valueType: ValueType;
}

export type ValueType = "number" | "percent";

export interface ITickerMarketYeld {
  source_id: string;
  value: number | null;
  source_name: string;
  date: string;
  close_perc_change: number | null;
}

export interface ITickerCommodities {
  name: string;
  value: number | null;
  category: string;
  period_start_date: string;
  last_updated: string;
  close_perc_change: number | null;
}

export interface ITickerExRates {
  currency: string;
  currency_sign: string | null;
  rate: number | null;
  day: string;
  last_updated: string;
  close_perc_change: number | null;
}

export type CardType = "indices" | "market-yeld" | "commodities" | "extra-returns";

export interface IChartCard {
  id: number;
  title: string;
  type: ChartTypeEnum;
  dropdown?: any;
  dropdownWidth?: any;
  dropdownLabel?: any;
  link: string | null;
  requestType: string;
  hasTable?: boolean;
}

export enum ChartTypeEnum {
  GAUGE = "gauge",
  LINE = "spline",
  COLUMN = "column",
  GAUGE_LINE = "gauge_line",
  SPIDER = "spider",
  TABLE = "table",
  BIG_DATA = "big_data",
  ONE_AXIS_LINE = "one_axis_line",
}
