import { ReactComponent as OdinData } from "@/images/data-portal.svg";
import { ReactComponent as Forecast } from "@/images/forecast.svg";
import { ReactComponent as Portfolio } from "@/images/handbag-filled.svg";
import { ReactComponent as Home } from "@/images/home.svg";
import { ReactComponent as MarketView } from "@/images/market-view.svg";
import { ILink } from "@/types";

import { ROUTES } from "./routes";

export enum SideBarKeyEnum {
  // HOME = "home",
  MARKET_VIEW = "market-view",
  SINGLE_STRATEGY = "single-strategy",
  MULTI_STRATEGY = "multi-strategy",
  MULTI_ASSET = "multi-asset",
  EQUITY_SECTORS = "equity-sectors",
  FIXED_INCOME = "fixed-income",
  COMMODITIES = "commodities",
  COMPANIES = "companies",
  INDUSTRIES = "industries",
  SECTORS = "sectors",
  MACRO = "macro",
  EVENTS_CALENDAR = "events-calendar",
  DATA = "data",
  ODINCHAT = "OdinChat",
  CUSTOM_INDICATOR = "custom-indicator",
  ETF_INTELLIGENCE = "etf-intelligence",
}

export const navLinks: ILink[] = [
  // {
  //   path: ROUTES.home.path,
  //   text: "Home",
  //   icon: <Home />,
  //   name: "home",
  // },
  {
    path: ROUTES.marketView.path,
    text: "Market View",
    icon: <MarketView />,
    name: "market-view",
  },
  {
    text: "Modeling",
    name: "portfolio",
    defaultCollapsed: true,
    icon: <Portfolio />,
    items: [
      {
        path: `${ROUTES.modelingPortfolioBuilder.path}`,
        text: "Portfolio Builder",
        isSubLink: true,
        name: "portfolio",
      },
      {
        path: `${ROUTES.macroPlanner.path}`,
        text: "Macro Planner",
        isSubLink: true,
        name: "macro-planner",
      },
    ],
  },

  {
    defaultCollapsed: true,
    text: "Forecasts",
    name: "forecasts",
    icon: <Forecast />,
    items: [
      {
        path: `${ROUTES.forecastEquities.path}`,
        text: "Companies",
        isSubLink: true,
        name: "companies",
      },
      {
        path: `${ROUTES.forecastIndustries.path}`,
        text: "Industries",
        isSubLink: true,
        name: "industries",
      },
      {
        path: `${ROUTES.forecastSectors.path}`,
        text: "Sectors",
        isSubLink: true,
        name: "sectors",
      },
      {
        path: `${ROUTES.forecastMacro.path}`,
        text: "Macro",
        isSubLink: true,
        name: "macro",
      },
    ],
  },
  {
    defaultCollapsed: true,
    text: "Data",
    icon: <OdinData />,
    name: "data",
    items: [
      {
        path: `${ROUTES.focus.path}`,
        text: "Focus",
        name: "focus",
        isSubLink: true,
      },
      {
        path: `${ROUTES.catalog.path}`,
        text: "Catalog",
        name: "data",
        isSubLink: true,
      },
      {
        path: `${ROUTES.eventsCalendar.path}`,
        text: "Events Calendar",
        name: "events-calendar",
        isSubLink: true,
      },
      {
        path: `${ROUTES.ETFIntelligence.path}`,
        text: "ETF Intelligence",
        name: "etf-intelligence",
        isSubLink: true,
      },
    ],
  },
];
