import { useEffect, useState } from "react";
import WebFont from "webfontloader";

const FONTS = ["Archivo", "Inter:400,500,600", "Poppins:400,500,600"];

export const useFontsCheck = () => {
  const [isFontsReady, setIsFontsReady] = useState(false);

  useEffect(() => {
    const nodes: HTMLParagraphElement[] = [];
    try {
      WebFont.load({
        google: {
          families: FONTS,
        },
        loading: function onLoadFonts() {
          setTimeout(() => setIsFontsReady(true), 200);
          console.log("Fonts are loaded...");
        },
      });
    } catch (e) {
      console.warn("Some error during loading fonts.");
      setIsFontsReady(true);
    }
  }, []);

  return isFontsReady;
};
