import { FC, ReactNode, useState } from "react";

import { useOuterClick } from "@/hooks";
import Arrow from "@/images/arrow.svg";

import styles from "./Menu.module.scss";

export interface menuItem {
  icon: string;
  title: string;
  key: string | number;
}

interface IProps {
  menuItems: menuItem[];
  handelMenuSelect: (key: string | number) => void;
  children: ReactNode;
  hasArrow?: boolean;
  width?: string;
  height?: string;
}

export const Menu: FC<IProps> = ({
  menuItems,
  handelMenuSelect,
  children,
  hasArrow = true,
  width = "auto",
  height = "auto",
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [itemRef] = useOuterClick(setMenuOpen, false);
  const handleClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const selectItemHandler = (key: string | number) => {
    handelMenuSelect(key);
    setMenuOpen(false);
  };

  return (
    <div ref={itemRef} className={styles.ActionsMenuContainer}>
      <div onClick={handleClick} className={styles.content}>
        <div className={styles.childrenContainer}>{children}</div>
        {hasArrow && <img className={isMenuOpen ? styles.isOpen : ""} src={Arrow} alt="dots" />}
      </div>
      <div
        style={{ width, height }}
        className={`${styles.menu} ${isMenuOpen ? styles.isOpen : ""}`}
      >
        <div className={styles.listWrapper}>
          <ul>
            {menuItems.map(({ icon, title, key }) => (
              <li key={key} onClick={() => selectItemHandler(key)}>
                <img src={icon} alt="copy" />
                <span className={styles.text}>{title}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className={styles.dialog}></div> */}
      </div>
    </div>
  );
};
