import dayjs from "dayjs";
import Highcharts from "highcharts/highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { FC, useRef } from "react";

import styles from "./Chart.module.scss";

more(Highcharts);

interface IProps {
  time?: string;
  subtitle?: string[] | null;
}

const getNow = (time?: string) => {
  const now = time ? dayjs(time) : dayjs();

  return {
    date: now,
    hours: now.hour() + now.minute() / 60,
    minutes: (now.minute() * 12) / 60 + (now.second() * 12) / 3600,
    seconds: (now.second() * 12) / 60,
  };
};

export const ClockChart: FC<IProps> = ({ time, subtitle }) => {
  const timeRef = useRef(getNow(time));
  const chartOptions: Highcharts.Options = {
    chart: {
      type: "gauge",
      plotBorderWidth: 0,
      plotShadow: false,
      height: "350px",
      animation: {
        duration: 0,
      },
    },

    credits: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    title: {
      text: subtitle ? subtitle.join(" ") : "",
      style: {
        color: "var(--primary)",
        fontSize: "20px",
        fontFamily: "Inter",
      },
    },

    plotOptions: {
      series: {
        animation: {
          duration: 0,
        },
      },
    },

    yAxis: {
      labels: {
        distance: -20,
      },
      min: 0,
      max: 12,
      lineWidth: 0,
      showFirstLabel: false,

      minorTickInterval: "auto",
      minorTickWidth: 1,
      minorTickLength: 5,
      minorTickPosition: "inside",
      minorGridLineWidth: 0,
      minorTickColor: "#666",

      tickInterval: 1,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 10,
      tickColor: "#666",
      title: {
        text: "",
      },
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        type: "gauge",
        data: [{ id: "hour", y: timeRef.current.hours }],
        dial: {
          radius: "60%",
          baseWidth: 4,
          baseLength: "95%",
          rearLength: "0%",
        },
        dataLabels: {
          enabled: false,
        },
      },
      {
        type: "gauge",
        data: [{ id: "minute", y: timeRef.current.minutes }],
        dial: {
          baseLength: "95%",
          rearLength: "0%",
        },
        dataLabels: {
          enabled: false,
        },
      },
      {
        type: "gauge",
        data: [{ id: "second", y: timeRef.current.seconds }],
        dial: {
          radius: "100%",
          baseWidth: 1,
          rearLength: "20%",
        },
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: 350 } }}
      />
    </div>
  );
};
