import UserDefaultIcon from "@/images/user-icon.svg";
import { IRole, IUserRole } from "@/types";

import { ROUTES } from "./routes";

export const DEFAULT_ADMIN_USERS = ["pouya@bigdatafed.com"];

export const getUserAvatar = (profileImage?: string | null): string => {
  if (profileImage) {
    return profileImage;
  }
  return UserDefaultIcon;
};

export enum UserRoleEnum {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  REGULAR = "regular",
  SALES = "sales",
}

export const USER_ROLE: IUserRole[] = [
  { role: UserRoleEnum.SUPERADMIN, title: "Super Admin" },
  { role: UserRoleEnum.ADMIN, title: "Admin" },
  { role: UserRoleEnum.REGULAR, title: "Regular" },
  { role: UserRoleEnum.SALES, title: "Sales" },
];

export const USER_PROFILE_LINK_NAME = {
  profile: {
    label: "Profile",
    path: ROUTES.UserProfile.path,
  },
  inviteUser: {
    label: "Invite User",
    path: ROUTES.invite.path,
  },
  companyManagement: {
    label: "Company Management",
    path: ROUTES.companyManagement.path,
  },
  usersManagement: {
    label: "Users Management",
    path: ROUTES.usersManagement.path,
  },
  adminPanel: {
    label: "Admin Panel",
    path: ROUTES.AdminPanel.path,
  },
  userData: {
    label: "My Data",
    path: ROUTES.userData.path,
  },
  logout: {
    label: "Logout",
    path: null,
  },
};

export const usersRoleList: IRole[] = [
  { key: "regular", value: "Regular" },
  { key: "sales", value: "Sales" },
  { key: "admin", value: "Admin" },
];

export const BDF_COMPANY_ID = 1;
