import { FC, useRef, useState } from "react";

import { Button, Dropdown, ItemType, Loader } from "@/components";
import { DocumentSectionType, DocumentsFoldersType, tabItemsList } from "@/constants";
import { useAppSelector } from "@/hooks";
import { ReactComponent as UploadFilesIcon } from "@/images/admin-upload.svg";
import { ReactComponent as UploadIcon } from "@/images/upload-icon.svg";
import { AdminDocumentsState } from "@/store/adminPanel/documents";
import { notification } from "@/utils";

import { useUploadPDF } from "../../../hooks/useUploadPDF";

import styles from "./DocumentUpload.module.scss";

const MAX_DOC_SIZE = 100 * 1024 * 1024;

const foldersList = tabItemsList
  .filter((item) => item.type === "documents")
  .map(({ key, name }) => ({
    key: key as DocumentsFoldersType,
    value: name,
  }));

const sectionsList: ItemType<DocumentSectionType>[] = [
  {
    key: "modeling",
    value: "Modeling",
  },
  {
    key: "forecasting",
    value: "Forecasting",
  },
  {
    key: "discretionary",
    value: "Discretionary",
  },
  {
    key: "systematic",
    value: "Systematic",
  },
  {
    key: "hedging",
    value: "Hedging",
  },
  {
    key: "crypto",
    value: "Crypto",
  },
  {
    key: "asset-allocation",
    value: "Asset-Allocation",
  },
];

export const DocumentUpload: FC = () => {
  const [selectedFolder, setSelectedFolder] = useState<ItemType<DocumentsFoldersType> | null>(null);
  const [documentSection, setDocumentSection] = useState<ItemType<DocumentSectionType> | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isLoadingDocumnet } = useAppSelector(AdminDocumentsState);

  const isApprovalDocument = selectedFolder?.key === "learn-more" || false;

  const { handleUpload } = useUploadPDF(isApprovalDocument);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file && file.size <= MAX_DOC_SIZE && selectedFolder) {
      handleUpload(file, selectedFolder.key, documentSection?.key || null);
    } else if (file) {
      notification.error("File size exceeds the maximum limit of 100MB.");
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const isDisableUpload = isApprovalDocument
    ? !selectedFolder || !documentSection
    : !selectedFolder;

  const selectHandle = (name: string, item: ItemType<DocumentsFoldersType>) => {
    setSelectedFolder(item);
  };

  const selectSectionHandle = (name: string, item: ItemType<DocumentSectionType>) => {
    setDocumentSection(item);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <UploadFilesIcon />
      </div>
      <div className={styles.uploadSection}>
        <h3>Upload Document</h3>
        {isLoadingDocumnet && <Loader />}
        <div className={styles.uploadButton}>
          <Dropdown<"folder", DocumentsFoldersType>
            name="folder"
            label="Select Folder"
            placeholder="Folder to Upload file"
            list={foldersList}
            selected={selectedFolder}
            onSelect={selectHandle}
            disable={isLoadingDocumnet}
          />
        </div>
        {isApprovalDocument ? (
          <div className={styles.uploadButton}>
            <Dropdown<"section", DocumentSectionType>
              name="section"
              label="Select Document Section"
              placeholder="Section Type"
              list={sectionsList}
              selected={documentSection}
              onSelect={selectSectionHandle}
              disable={isLoadingDocumnet}
            />
          </div>
        ) : null}

        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          id="profileImage"
          onChange={handleChange}
          accept=".pdf"
        />
        <div className={styles.uploadButton}>
          <Button
            IconComponent={UploadIcon}
            onClick={handleButtonClick}
            type="primary"
            text="Select Document File"
            disabled={isDisableUpload || isLoadingDocumnet}
          />
        </div>
      </div>
    </div>
  );
};
