import { FC, Fragment, memo, useCallback, useMemo } from "react";

import { ItemType } from "@/components";
import { customGroupsList } from "@/constants/warRoom";
import { AssetItem, AssetRecordType } from "@/types/warRoom";

import styles from "./AssetsCustom.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear-calendar.svg";

interface IAssetsCustomProps {
  onRemoveAsset: (item: AssetItem, group: string) => void;
  onRemoveFile: (file: ItemType<string>) => void;
  assets: Record<string, AssetRecordType>;
  filesList: ItemType<string>[] | null;
}

export const AssetsCustom: FC<IAssetsCustomProps> = memo(
  ({ onRemoveAsset, assets, filesList, onRemoveFile }) => {
    const getSelectedGroupAssets = useCallback(
      (group: string) => {
        return Object.values(assets[group]).filter((i) => i.checked);
      },
      [assets]
    );

    const selectedGroups = useMemo(() => {
      return customGroupsList.filter((group) => getSelectedGroupAssets(group.key).length);
    }, [getSelectedGroupAssets]);

    const isEmpty = selectedGroups.length === 0 && !filesList?.length;

    return (
      <div className={styles.groupSection}>
        <FilesList filesList={filesList} onRemoveFile={onRemoveFile} />
        <AssetsList
          onRemoveAsset={onRemoveAsset}
          selectedGroups={selectedGroups}
          getSelectedGroupAssets={getSelectedGroupAssets}
        />
        {isEmpty && (
          <div className={styles.emptyContainer}>
            <p>No Desired Assets selected</p>
          </div>
        )}
      </div>
    );
  }
);

interface IFilesListProps {
  filesList: ItemType<string>[] | null;
  onRemoveFile: (file: ItemType<string>) => void;
}

export const FilesList: FC<IFilesListProps> = ({ filesList, onRemoveFile }) => {
  if (!filesList || filesList?.length === 0) return null;

  return (
    <Fragment>
      {
        <div className={styles.assetGroupSection}>
          <p className={`${styles.assetGroupTitle} ${styles.file}`}>Selected CSV files</p>
          <ul className={styles.assetsGroupList}>
            {filesList.map((file, idx) => (
              <li key={file.value} className={styles.assetGroupItem}>
                <span className={styles.iconContainer} onClick={() => onRemoveFile(file)}>
                  <ClearIcon />
                </span>
                <span data-index={`${idx + 1}`}>{file.value}</span>
              </li>
            ))}
          </ul>
        </div>
      }
    </Fragment>
  );
};

interface IAssetsListProps {
  onRemoveAsset: (item: AssetItem, group: string) => void;
  selectedGroups: ItemType<string>[];
  getSelectedGroupAssets: (group: string) => AssetItem[];
}

export const AssetsList: FC<IAssetsListProps> = ({
  onRemoveAsset,
  selectedGroups,
  getSelectedGroupAssets,
}) => {
  return (
    <Fragment>
      {selectedGroups.map(({ key, value }) => (
        <div key={key} className={styles.assetGroupSection}>
          <p className={styles.assetGroupTitle}>{value}</p>
          <ul className={styles.assetsGroupList}>
            {getSelectedGroupAssets(key).map((asset, idx) => (
              <li key={asset.key} className={styles.assetGroupItem}>
                <span className={styles.iconContainer} onClick={() => onRemoveAsset(asset, key)}>
                  <ClearIcon />
                </span>
                <span data-index={`${idx + 1}`}>{asset.label}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Fragment>
  );
};
