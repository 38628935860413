import { AxiosResponse } from "axios";

import { IPortfolioBuilderDataResult, IPortfolioBuilderPayload } from "@/types";

import axios from "./axios.config";

export const portfolioBuilderService = {
  abort: new AbortController(),
  portfolioBuilder(
    id: string,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<IPortfolioBuilderDataResult>> {
    this.abort = new AbortController();
    return axios.get(
      start && end
        ? `/portfolios/${id}/performance?start_date=${start}&end_date=${end}`
        : `/portfolios/${id}/performance`,
      {
        signal: this.abort.signal,
      }
    );
  },
  portfolioBuilderLive(
    data: IPortfolioBuilderPayload
  ): Promise<AxiosResponse<IPortfolioBuilderDataResult>> {
    this.abort = new AbortController();
    return axios.post(
      `portfolios/live-performance`,
      {
        config: data,
      },
      {
        signal: this.abort.signal,
      }
    );
  },
};
