import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ForecastingIcon } from "@images/icons/forecast-white.svg";
import { ReactComponent as ModelingIcon } from "@images/icons/modeling-white.svg";

import { Button } from "@/components";
import { useAppDispatch } from "@/hooks";
import { muteVideo } from "@/store/homeStart";

import styles from "./CenterNavigation.module.scss";

export const CenterNavigation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToHandle = (path: string) => {
    dispatch(muteVideo(true));
    navigate(path);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Button
          type="white"
          text="Forecasting"
          IconComponent={ForecastingIcon}
          size="large"
          onClick={() => navigateToHandle("/forecasting")}
        />
      </div>
      <div className={styles.wrapper}>
        <Button
          type="white"
          text="Modeling"
          IconComponent={ModelingIcon}
          size="large"
          onClick={() => navigateToHandle("/modeling")}
        />
      </div>
    </div>
  );
};
