import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogInIcon } from "@images/icons/login.svg";

import { ROUTES } from "@/constants";
import { useAppDispatch } from "@/hooks";
import { muteVideo } from "@/store/homeStart";

import { Button } from "../Button/Button";

import styles from "./TopNavigation.module.scss";

interface IProps {
  inverse?: boolean;
  onSelect?: () => void;
}

export const TopNavigation: FC<IProps> = ({ inverse = false, onSelect }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const buttonHandle = (path: string) => {
    navigate(path);
    dispatch(muteVideo(true));
    if (onSelect) onSelect();
  };
  const border = inverse ? "1px solid var(--black-90)" : undefined;
  return (
    <div className={styles.container}>
      <div className={`${inverse ? styles.mobile : ""}`}>
        <Button
          text="Request a Demo"
          type="white"
          size="small"
          onClick={() => buttonHandle(ROUTES.demoRequest.path)}
          border={border}
        />
      </div>
      <div className={`${inverse ? styles.mobile : ""}`}>
        <Button
          text="LogIn"
          type="white"
          size="small"
          onClick={() => buttonHandle("/login")}
          border={border}
          IconComponent={LogInIcon}
          rightIcon
        />
      </div>
    </div>
  );
};
