import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy } from "react-table";

import { useFixedColumn } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";

import { useInfiniteScroll } from "./hooks/useInfiniteScroll";
import { ExtendedColumns, SortArrows } from "./Table";

import styles from "./Table.module.scss";

interface IProps<T> {
  columns: ExtendedColumns;
  data: T[];
  scrollWidth?: number;
  onSelect?: (row: object) => void;
  small?: boolean;
  size?: "small" | "medium";
  isNotClickable?: boolean;
  defaultCount: number;
}

export function TableInfinitList<T extends object>({
  columns,
  data,
  onSelect,
  small,
  size,
  isNotClickable = false,
  defaultCount,
}: IProps<T>) {
  const { itemRef, showScroll, tableColumnsWidth } = useFixedColumn(columns);
  const isMobileView = useMediaQuery("(max-width: 575px)");

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const { listRef, list } = useInfiniteScroll(itemRef, defaultCount, rows);

  return (
    <div className={styles.tableContainer} ref={itemRef}>
      <table
        {...getTableProps()}
        className={`${styles.table} ${size ? (small ? styles.small : styles.medium) : ""}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
              style={{ gridTemplateColumns: tableColumnsWidth }}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${styles.tableHeaderCell} ${showScroll ? styles.showScroll : ""}`}
                  >
                    <span className={styles.cellText}>{column.render("Header")}</span>
                    <SortArrows
                      isSorting={column.isSorted}
                      isDescDir={column.isSortedDesc}
                      isSortable={column.canSort}
                    />
                    <span className={styles.tableHeaderSeparator}></span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} ref={listRef}>
          {list.map((row, idx) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`${styles.tableRow} ${isNotClickable ? styles.notClickable : ""}`}
                style={{ gridTemplateColumns: tableColumnsWidth }}
                onClick={() => (onSelect ? onSelect(row.original) : null)}
                data-key={idx}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`${styles.tableRowCell} ${showScroll ? styles.showScroll : ""} ${
                        small ? styles.small : ""
                      }`}
                    >
                      {columns[idx].isTruncated ? (
                        <div className={styles.truncated}>
                          <span>{cell.render("Cell")}</span>
                        </div>
                      ) : (
                        <div className={styles.simpleCell}>{cell.render("Cell")}</div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && <div className={styles.emptyData}>Data not available yet.</div>}
    </div>
  );
}
