import { FC } from "react";

import styles from "./PageTitle.module.scss";

interface IProps {
  children: string;
}

export const PageTitle: FC<IProps> = ({ children }) => {
  return <h2 className={styles.title}>{children}</h2>;
};
