import type { Identifier, XYCoord } from "dnd-core";
import { FC, useRef } from "react";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

import { Button, TooltipWrapper } from "@/components";
import { DocumentsFoldersType } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ReactComponent as NestIcon } from "@/images/nest.svg";
import { AdminPanelService } from "@/services/adminPanelService";
import { AdminDocumentsState, deleteDocument } from "@/store/adminPanel/documents";
import { IAdminPanelDocuments } from "@/types/adminPanel";
import { notification } from "@/utils/notification";

import styles from "./DocumentItem.module.scss";

import { ReactComponent as DragIcon } from "@images/drag-icon.svg";

interface IDocumentItemProps {
  document: IAdminPanelDocuments;
  index: number;
  updateOrder: (hoverIndex: number, dragIndex: number) => void;
  disabled?: boolean;
}

export const DocumentItem: FC<IDocumentItemProps> = ({
  document,
  index,
  disabled,
  updateOrder,
}) => {
  const dispatch = useAppDispatch();
  const handleRemove = () => {
    dispatch(deleteDocument(document.id));
  };
  const ref = useRef<HTMLDivElement>(null);
  const { documents } = useAppSelector(AdminDocumentsState);

  const isMobile = useMediaQuery("(max-width: 575px)");

  const [{ handlerId }, drop] = useDrop<any, void, { handlerId: Identifier | null }>({
    accept: "doc",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      updateOrder(hoverIndex, dragIndex);
      item.index = hoverIndex;
    },
  });

  const UpdateOrdering = async () => {
    const data = (documents || []).map((doc, index) => ({
      id: doc.id,
      ordering: index + 1,
    }));
    try {
      await AdminPanelService.updateDocumentsOrdering({ data });
    } catch (e) {
      notification.error("");
    }
  };

  const getFolderName = (folder: DocumentsFoldersType) => {
    if (folder !== "learn-more") return folder;
    return "Solutions";
  };

  const [{ opacity }, drag] = useDrag({
    type: "doc",
    item: () => {
      return { document, index };
    },
    end: ({ document, index }) => {
      if (document.ordering === index) return;
      UpdateOrdering();
    },
    collect: (monitor: DragSourceMonitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  });
  drag(drop(ref));

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.order}>{index + 1}</div>
      <div ref={ref} className={styles.colItem} style={{ opacity }} data-handler-id={handlerId}>
        <div className={styles.col}>
          <DragIcon />
        </div>
        <div className={styles.col}>
          <TooltipWrapper text={document?.key}>
            <p className={styles.title}>{document?.key}</p>
            <p className={styles.type}>{getFolderName(document.meta.folder)}</p>
          </TooltipWrapper>
        </div>
        <div className={styles.col}>
          <Button
            text={isMobile ? "" : "Remove"}
            onClick={handleRemove}
            IconComponent={NestIcon}
            danger
          />
        </div>
      </div>
    </div>
  );
};
