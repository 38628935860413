import { FC } from "react";

import { Button, FooterNavigation, Loader, Slogan } from "@/components";
import { authService } from "@/services";

import { useUnsubscribe } from "./hooks/useUnsubscribe";

import styles from "./Unsubscribe.module.scss";

export const Unsubscribe: FC = () => {
  const { loading, submitHandler, cancelHandle } = useUnsubscribe();
  const isUserLogged = authService.isLogined();

  return (
    <div className={`${styles.container} ${isUserLogged ? styles.inverseTheme : ""}`}>
      <div className={styles.inner}>
        <Slogan size="medium" asLink={!isUserLogged} black={!isUserLogged} />
        <div className={styles.formContainer}>
          <h1>Do you really want to unsubscribe?</h1>
          <p>
            Your subscription to the articles will be cancelled, and you will not receive the
            insights e-mail notifications anymore.
          </p>
          <p className={styles.mutedText}>Do you want to unsubscribe anyway?</p>
          <div className={styles.btnWrapper}>
            <Button
              text="Unsubscribe"
              type={isUserLogged ? "primary" : "white"}
              onClick={submitHandler}
              disabled={loading}
            />
            <Button
              text="Not Now"
              type={isUserLogged ? "gost" : "white"}
              onClick={cancelHandle}
              disabled={loading}
            />
          </div>
          {loading ? <Loader white={!isUserLogged} /> : null}
        </div>
      </div>
      {isUserLogged ? null : <FooterNavigation fullWidth />}
    </div>
  );
};
