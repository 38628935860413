import React, { useEffect, useRef, useState } from "react";

import { useMediaQuery } from "@/hooks/useMediaQuery";

const SUB_ITEMS_COUNT = 5;
const ITEM_WIDTH = 120;
const ITEM_CSS_VARIABLE = "--card-item-width";
const CLASS_NAME = "full-width";
const CLASS_NAME_MOBILE = "mobile-width";
const ANIMATION_TIME = 700;

export const useSlider = (children: React.ReactNode[], refList: HTMLDivElement[]) => {
  const [count, setCurrent] = useState(0);
  const [subCount, setSubCount] = useState<number | null>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const mainContainerWidth = mainContainerRef.current?.clientWidth || 0;

  const shiftItem = (shift: number) => {
    const widths = refList.map((i) => i.clientWidth);
    for (let i = 0; i < count; i++) {
      shift += widths[i];
    }
    const translate = `transform: translateX(-${shift}px); transition-duration: ${ANIMATION_TIME}ms`;
    containerRef.current?.setAttribute("style", translate);
  };

  const actionHandler = () => {
    const widths = refList.map((i) => i.clientWidth);
    const subItemWidth = widths[count] / SUB_ITEMS_COUNT;

    if (mainContainerWidth + (subCount || 0) * subItemWidth < widths[count] && !isMobile) {
      const subShift = (subCount || 0) * subItemWidth + subItemWidth;
      shiftItem(subShift);
      setSubCount((prev) => (prev || 0) + 1);
    } else {
      shiftItem(widths[count]);
      setCurrent((prev) => prev + 1);
      setSubCount(0);
    }
  };

  useEffect(() => {
    const transitionEndHandler = (event: TransitionEvent) => {
      if (
        count >= children.length &&
        containerRef.current &&
        event.target === containerRef.current
      ) {
        const translate = `transform: translateX(-${0}px); transition-duration: 0s`;
        containerRef.current?.setAttribute("style", translate);
        setCurrent(0);
        setSubCount(0);
      }
    };

    containerRef.current?.addEventListener("transitionend", transitionEndHandler);

    return () => {
      containerRef.current?.removeEventListener("transitionend", transitionEndHandler);
    };
  }, [count, children]);

  useEffect(() => {
    const resizeHandler = () => {
      const width = mainContainerRef.current?.clientWidth || 0;
      const subItemsFullWidth = (ITEM_WIDTH * 2 + 12) * SUB_ITEMS_COUNT;

      if (width > subItemsFullWidth) {
        containerRef.current?.classList.add(CLASS_NAME);
      } else {
        containerRef.current?.classList.remove(CLASS_NAME);
      }
      if (isMobile) {
        containerRef.current?.classList.add(CLASS_NAME_MOBILE);
      } else {
        containerRef.current?.classList.remove(CLASS_NAME_MOBILE);
      }
      const translate = `transform: translateX(-${0}px); transition-duration: 0s`;
      containerRef.current?.setAttribute("style", translate);
      setCurrent(0);
      setSubCount(0);
    };

    resizeHandler();

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [isMobile]);

  useEffect(() => {
    document.body.style.setProperty(ITEM_CSS_VARIABLE, `${ITEM_WIDTH}px`);
  }, []);

  return { actionHandler, containerRef, mainContainerRef, isMobile };
};
