import { FC } from "react";
import ReactSlider from "react-slider";

import styles from "./SliderRange.module.scss";

interface ISiderRangeProps {
  label: string;
  name: string;
  rangeLabelMin?: string;
  rangeLabelMax?: string;
  onChange: (value: any, name: string) => void;
  min: number;
  max: number;
  step?: number;
  isRange?: boolean;
  minDistance?: number;
  disabled?: boolean;
  value?: number | number[];
  showCurValue?: boolean;
}

export const SliderRange: FC<ISiderRangeProps> = ({
  label,
  name,
  onChange,
  rangeLabelMin,
  rangeLabelMax,
  min,
  max,
  step = 1,
  isRange,
  minDistance,
  disabled,
  value,
  showCurValue,
}) => {
  const changeHandler = (value: number | number[]) => {
    onChange(value, name);
  };

  return (
    <div className={styles.sliderContainer}>
      <p className={styles.labaelText}>{label}</p>
      <div className={`${styles.sliderWrapper} ${disabled ? styles.disabled : ""}`}>
        <div className={styles.labelsContainer}>
          <span>{rangeLabelMin}</span>
          {showCurValue && <span>{Array.isArray(value) ? value.join(" / ") : value}</span>}
          <span>{rangeLabelMax}</span>
        </div>
        <ReactSlider
          pearling={isRange}
          minDistance={minDistance}
          min={min}
          max={max}
          step={step}
          className={styles.slider}
          trackClassName={styles.track}
          renderThumb={(props, state) => (
            <div {...props} className={styles.thumb}>
              {state.valueNow}
              <div className={styles.thumbLabel}>{state.valueNow}</div>
            </div>
          )}
          renderTrack={(props, state) => (
            <div
              {...props}
              className={`${styles.track} ${
                isRange
                  ? state.index === 1
                    ? styles.active
                    : ""
                  : state.index === 0
                  ? styles.active
                  : ""
              }`}
            ></div>
          )}
          onChange={changeHandler}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
