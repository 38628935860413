import { FC, ReactNode } from "react";

import styles from "./TabsList.module.scss";

interface IProps {
  id: string;
  activeTab: string | null;
  children: ReactNode;
}

export const TabContent: FC<IProps> = ({ id, activeTab, children }) => {
  return activeTab === id ? (
    <div className={styles.tabContent}>
      <div className={styles.inner}>{children}</div>
    </div>
  ) : null;
};

export const TabContentWrapper: FC<IProps> = ({ id, activeTab, children }) => {
  return activeTab === id ? (
    <div className={styles.tabWrapper}>
      <div className={styles.inner}>{children}</div>
    </div>
  ) : null;
};
