import { FC, useCallback, useEffect, useMemo } from "react";

import { LoaderWrapper } from "@/components";
import { paginator } from "@/constants";
import { CommonStartegies } from "@/features/StrategiesDashboard";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  getPortfolioList,
  managePortfolioState,
  setPage,
  setPerPage,
} from "@/store/portfolios/portfolio";
import { profileInfo } from "@/store/profile";

import { CommonPortfolios, MyPortfolios, SharedPortfolios } from "../components";

import styles from "./Portfolio.module.scss";

export const PortfolioMain: FC = () => {
  const { portfolioList, isLoading, page, perPage } = useAppSelector(managePortfolioState);
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portfolioList.length) return;
    dispatch(getPortfolioList());
  }, [dispatch, portfolioList.length]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const portfoliosPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  const sharedPortfolios = useMemo(() => [], [portfolioList, profile?.id]);

  const myPortfolios = useMemo(
    () => portfolioList.filter(({ user_id }) => profile?.id === user_id),
    [portfolioList, profile?.id]
  );

  const commonPortfolios = useMemo(() => [], [portfolioList]);

  const isSharedPortfolios = !!sharedPortfolios.length;
  const isCommonPortfolios = !!commonPortfolios.length;

  return (
    <div className={styles.container}>
      {isCommonPortfolios && <CommonPortfolios list={commonPortfolios} loading={isLoading} />}
      {isSharedPortfolios && <SharedPortfolios list={sharedPortfolios} loading={isLoading} />}
      <LoaderWrapper loading={isLoading}>
        <MyPortfolios
          portfolioList={myPortfolios}
          allowedDropEffect="copy"
          hasDnDTarget={isCommonPortfolios}
          paginator={portfoliosPaginator}
        />
        <div className={styles.wrapper}>
          <CommonStartegies />
        </div>
      </LoaderWrapper>
    </div>
  );
};
