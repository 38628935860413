import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useInfiniteScroll } from "@/features/Intelligence/hooks/useInfiniteScroll";
import { DirrectionType, IEtfTicker, ITickerMeta } from "@/types";

import styles from "./InfinitList.module.css";
import { Ticker } from "../Ticker/Ticker";

const ADD_COUNT = 20;

interface IInfinityListProps {
  list: IEtfTicker[];
  formatter: (data: IEtfTicker, meta: ITickerMeta | null) => string;
  sortDir: DirrectionType;
  meta: ITickerMeta | null;
  showMore: boolean;
  defaultCount: number;
}

export const InfinitList: FC<IInfinityListProps> = memo(
  ({ list, formatter, sortDir, meta, showMore, defaultCount }) => {
    const listRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [count, setCount] = useState(defaultCount);

    useInfiniteScroll({
      increment: ADD_COUNT,
      listRef: listRef.current,
      rootRef: rootRef.current,
      functionCB: setCount,
      listLen: list.length,
      showMore,
    });

    const sorter = useCallback(
      (tickerA: string, tickerB: string): number => {
        if (sortDir === "asc") return tickerA.localeCompare(tickerB);
        else if (sortDir === "desc") return tickerB.localeCompare(tickerA);
        return 0;
      },
      [sortDir]
    );

    const sortedList = useMemo(() => {
      if (list) {
        if (!showMore) return list.slice(0, defaultCount).sort((a, b) => sorter(a.name, b.name));
        return [...list].sort((a, b) => sorter(a.name, b.name)).slice(0, count);
      }
      return list;
    }, [sorter, list, defaultCount, showMore, count]);

    const itemHeight = useMemo(() => {
      return listRef.current ? (listRef.current.clientHeight || 1) / defaultCount : 1;
    }, [defaultCount, listRef.current]);

    const listHeight = useMemo(() => {
      return itemHeight * (sortedList.length || defaultCount);
    }, [itemHeight, sortedList, defaultCount]);

    useEffect(() => {
      if (showMore) setCount(ADD_COUNT);
      rootRef.current?.scrollTo(0, 0);
    }, [sortDir, showMore]);

    return (
      <div
        className={styles.listContainer}
        ref={rootRef}
        style={{ overflow: showMore ? "auto" : "hidden" }}
      >
        <div ref={listRef} style={{ height: showMore ? listHeight : "auto" }}>
          {sortedList.map((ticker) => (
            <Ticker key={ticker.name} data={ticker} formatter={formatter} meta={meta} />
          ))}
        </div>
      </div>
    );
  }
);
