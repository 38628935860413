import { FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { Button, Collapse, DateObj, ItemType, LoaderWrapperCustom, Panel } from "@/components";
import { useAppSelector } from "@/hooks";
import { discoverDrsMetaState } from "@/store/discover/drsMeta";
import { discoverQueryDataState } from "@/store/discover/queryData";
import {
  ExtraDimensionsRef,
  IAggregators,
  IExtraDimansion,
  IFilter,
  IFilterCriteria,
  ISorting,
} from "@/types";

import { DateDimensions } from "./components/DateDimensions/DateDimensions";
import { DateDimensionsOptions } from "./components/DateDimensionsOptions/DateDimensionsOptions";
import { Dimensions } from "./components/Dimensions/Dimensions";
import { ExtraDimensions } from "./components/ExtraDimensions/ExtraDimensions";
import { Metrics } from "./components/Metrics/Metrics";
import { MetricsOptions } from "./components/MetricsOptions/MetricsOptions";
import { useFiltering } from "../../hooks/useFiltring";
import { useGetFilterParams } from "../../hooks/useGetFilterParams";

import styles from "./Filter.module.scss";

interface IProps {
  setFilterCriteria: (params: IFilterCriteria) => void;
  filterCriteria: IFilterCriteria | undefined;
  setCurrentPage: (current: number) => void;
  showFilter: boolean;
  setSort: React.Dispatch<SetStateAction<ISorting[]>>;
  isSelectTimeSeriasMode: boolean;
}

export const Filter: FC<IProps> = ({
  setFilterCriteria,
  filterCriteria,
  setCurrentPage,
  showFilter,
  setSort,
  isSelectTimeSeriasMode,
}) => {
  const [selected, setSelected] = useState<Record<string, ItemType<string>[]>>({});
  const [operation, setOperation] = useState<Record<string, string>>({});
  const [dimensions, setDimensions] = useState<string[]>([]);

  const [metricSelected, setMetricSelected] = useState<Record<string, IFilter>>({});
  const [operationMetrics, setOperationMetrics] = useState<Record<string, string>>({});
  const [metrics, setMetrics] = useState<string[]>([]);

  const [extraSelected, setExtraSelected] = useState<Record<string, ItemType<string>[]>>({});
  const [extraOperation, setExtraOperation] = useState<Record<string, string>>({});
  const [extraDimensions, setExtraDimensions] = useState<IExtraDimansion[]>([]);

  const [timeDimensionsOptions, setTimeDimensionsOptions] = useState<any>([]);
  const [includeTruncateFilters, setIncludeTruncateFilters] = useState({
    truncateFilterDates: true,
    isTruncateFilterEnabled: false,
  });

  const [dateRanges, setDateRanges] = useState<Record<string, DateObj>>({});
  const [timeDimensions, setTimeDimensions] = useState<string[]>([]);
  const [aggregators, setAggregators] = useState<IAggregators[]>([]);
  const { drsMeta } = useAppSelector(discoverDrsMetaState);
  const { isLoading: isLoadingQuery } = useAppSelector(discoverQueryDataState);
  const ref = useRef<ExtraDimensionsRef | null>(null);
  const extraFields = useMemo(
    () => extraDimensions.filter((item) => item.type !== "datetime").map((item) => item.name),
    [extraDimensions]
  );
  const { isLoadingParams, filterParams } = useGetFilterParams(drsMeta, drsMeta?.format.dimensions);
  const { filterParams: extraFilterParams } = useGetFilterParams(drsMeta, extraFields);
  const timeDimension = drsMeta?.format?.time_dimensions[0];
  const isOneTimeDimension = useMemo(
    () => drsMeta?.format?.time_dimensions.length === 1,
    [drsMeta?.format?.time_dimensions.length]
  );

  const handleResetFilter = () => {
    setTimeDimensions(drsMeta?.format?.time_dimensions || []);
    setMetrics(drsMeta?.format?.metrics || []);
    setDimensions(drsMeta?.format?.dimensions || []);
    setDateRanges({});
    setSelected({});
    setMetricSelected({});
    setAggregators([]);
    setSort([]);
    ref.current?.reset();
  };

  const groups = [
    ...timeDimensions,
    ...dimensions,
    ...extraDimensions.map((item) => item?.name),
  ]?.map((field) => {
    if (timeDimensionsOptions?.[field]?.name) {
      return {
        field: timeDimensionsOptions[field]?.name as string,
        date_level: timeDimensionsOptions[field]?.date_level as string,
      };
    }
    return {
      field,
    };
  });

  useEffect(() => {
    const hasFilteredByDate = filterCriteria?.filter.filters.find(
      (filter) => filter.field === timeDimension
    );
    setIncludeTruncateFilters((state) => ({
      ...state,
      isTruncateFilterEnabled:
        isSelectTimeSeriasMode && !!aggregators.length && isOneTimeDimension && !!hasFilteredByDate,
    }));
  }, [
    aggregators.length,
    filterCriteria?.filter.filters,
    isOneTimeDimension,
    isSelectTimeSeriasMode,
    timeDimension,
  ]);

  const initialData = {
    isOneTimeDimension,
    timeDimension,
    dimensions,
    extraDimensions,
    aggregators,
    timeDimensions,
    groups,
    includeTruncateFilters,
    selected,
    extraSelected,
    metricSelected,
    dateRanges,
    operation,
    extraOperation,
    metrics,
  };

  const { handleApply } = useFiltering(initialData, {
    filterCriteria,
    setFilterCriteria,
    setCurrentPage,
  });

  if (!showFilter) return null;
  return (
    <LoaderWrapperCustom atom loading={isLoadingParams} transparent>
      <div className={styles.container}>
        <div className={styles.filterContainer}>
          <Collapse defaultActiveKey={["panel-0", "panel-1"]}>
            <Panel header="Filtering by">
              <div className={styles.inner}>
                <DateDimensions
                  timeDimensions={timeDimensions}
                  setTimeDimensions={setTimeDimensions}
                  dateRanges={dateRanges}
                  setDateRanges={setDateRanges}
                />
                <Dimensions
                  dimensions={dimensions}
                  setDimensions={setDimensions}
                  filterParams={filterParams}
                  selected={selected}
                  setSelected={setSelected}
                  setOperation={setOperation}
                  operation={operation}
                />
                <Metrics
                  metrics={metrics}
                  setMetrics={setMetrics}
                  metricSelectd={metricSelected}
                  setMetricSelected={setMetricSelected}
                  setOperationMetrics={setOperationMetrics}
                  operationMetrics={operationMetrics}
                />
                <ExtraDimensions
                  ref={ref}
                  dimensions={extraDimensions}
                  setDimensions={setExtraDimensions}
                  filterParams={extraFilterParams}
                  selected={extraSelected}
                  setSelected={setExtraSelected}
                  setOperation={setExtraOperation}
                  operation={extraOperation}
                />
              </div>
            </Panel>
            <Panel header="Operations">
              <div className={styles.inner}>
                <DateDimensionsOptions
                  timeDimensionsOptions={timeDimensionsOptions}
                  setTimeDimensionsOptions={setTimeDimensionsOptions}
                  timeDimensions={timeDimensions}
                  aggregators={aggregators}
                  includeTruncateFilters={includeTruncateFilters}
                  setIncludeTruncateFilters={setIncludeTruncateFilters}
                />
                <MetricsOptions
                  metrics={metrics}
                  setAggregators={setAggregators}
                  timeDimensions={timeDimensions}
                  setTimeDimensionsOptions={setTimeDimensionsOptions}
                />
              </div>
            </Panel>
          </Collapse>
          <div className={styles.apply}>
            <div>
              <Button type="secondary" text="Reset" onClick={handleResetFilter} />
              <Button disabled={isLoadingQuery} text="Apply" onClick={handleApply} />
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapperCustom>
  );
};
