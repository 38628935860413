export interface IDrs {
  name: string;
  category: string;
  subcategory: string;
  drs_id: number;
  db_name?: string;
  jira_link?: string;
  meta: IMeta;
  tags?: string[];
  tickers?: string[];
}

export interface IMeta {
  tables: ITable[];
}

export interface ITable {
  tickers: string[];
  granularity: string;
  fields: IField[];
  label: string;
  name: string;
  description: string;
  tags: string[];
  format: IFormat;
  count?: number | null;
}

export interface IField {
  required_filter: boolean;
  type: string;
  label: string;
  description: string;
  name: string;
}

export interface IFormat {
  metrics: string[];
  time_dimensions: string[];
  revision_status: string;
  dimensions: string[];
  default_time_dimension: string;
  ingestion_timestamp_field: string;
  revision_date: string;
}

export interface IDrsSerachResult {
  name: string;
  time_series: number;
  sub_category: string;
  tables: number;
  jira_link: string;
  category: string;
  drs_id: number;
  tables_periodicities?: string[];
}

export interface IFieldMeta extends IField {
  primary_key: boolean;
  original_name: string;
  visible: boolean;
  description: string;
  label: string;
  name: string;
  required_filter: false;
  type: string;
}

export interface IMetaDetails {
  fields: IFieldMeta[];
  granularity: string;
  format: {
    metrics: string[];
    default_time_dimension: string;
    revision_status: string | null;
    ingestion_timestamp_field: string;
    revision_date: string | null;
    dimensions: string[];
    time_dimensions: string[];
  };
  drs_id: number;
  name: string;
  label: string;
  description: string;
}

export const enum TIME_SERIES_TYPE {
  DEFAULT = "default",
  TIME_SERIES = "time_series",
}

export interface IResultSeries {
  date: string;
  value: number;
}

export interface IResult {
  series_meta: {
    metric: string;
    dimensions: Record<string, string>;
  };
  data: IResultSeries[];
}

export interface ISeriesMeta {
  metric: string;
  time_dimension: string;
  dimensions: {
    year: number;
    month: number;
    state: string;
    type: string;
  };
  uuid: string;
}

export interface IQueryPayload {
  drs_id: number;
  table: string;
  select: {
    type: string;
    fields: string[];
  };
  page: number;
  per_page: number;
  filtring?: any;
}

export interface IResponeData {
  results: string[][];
  meta: IFieldMeta[];
}

export interface Meta {
  required_filter: boolean;
  type: string;
  visible: boolean;
  original_name: string;
  primary_key: boolean;
  label: string;
  name: string;
  description: string;
}

export interface IFilter {
  field: string;
  operator: string;
  value: string | number | string[];
}

export interface IExtraDimansion {
  name: string;
  type: string;
  lable: string;
}

export interface IAggregators {
  field: string;
  function: string;
  label: string;
}

export interface IGroup {
  field: string;
  date_level?: string;
}

export interface ISorting {
  field: string;
  ascending: boolean | undefined;
}

export type ExtraDimensionsRef = {
  reset(): void; // Define the methods that the ref should have
};

export interface IIncludeTruncateFilters {
  truncateFilterDates: boolean;
  isTruncateFilterEnabled: boolean;
}

export interface IQueryData {
  drs_id?: number | undefined;
  table?: string | undefined;
  page: number;
  per_page: number;
  select: {
    type: string;
    fields: string[];
    aggregators?: IAggregators[] | undefined;
    groups?: IGroup[] | undefined;
  };
  output?:
    | {
        format: string;
      }
    | undefined;
  filters?: Record<string, any> | undefined;
  truncate_filter_dates?: boolean | IIncludeTruncateFilters | undefined;
}

export interface ISetCriteria {
  filters: IFilter[];
  filter_operator?: string | undefined;
}

export interface IFilterCriteria {
  filter: ISetCriteria;
  fields?: string[];
  aggregators?: IAggregators[];
  groups?: IGroup[];
  includeTruncateFilters?: IIncludeTruncateFilters;
}
