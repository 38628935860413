import { FC } from "react";

import { TooltipWrapper } from "@/components";
import { getPercentChangeColor } from "@/utils";

import styles from "./ETFTable.module.scss";

interface ICellItemProps {
  valueText: string | number | null;
  postfix?: string | null;
  tooltip?: string | null;
}

export const CellItem: FC<ICellItemProps> = ({ valueText, postfix, tooltip }) => {
  const isNumber = isFinite(Number(valueText));
  const postfixValue = postfix ? postfix : "";
  return (
    <TooltipWrapper text={tooltip}>
      <div className={styles.cellWrapper}>
        {isNumber && (
          <span
            style={{
              color: getPercentChangeColor(valueText),
            }}
            className={tooltip ? styles.withTag : ""}
          >
            {valueText
              ? `${Number(valueText).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}${postfixValue}`
              : `0${postfixValue}`}
          </span>
        )}
        {!isNumber && valueText && (
          <span className={`${styles.text} ${tooltip ? styles.info : ""}`}>{valueText}</span>
        )}
      </div>
    </TooltipWrapper>
  );
};
