import { useCallback, useMemo } from "react";

export interface IPaginationData {
  totalCount: number | null;
  currentPage: number;
  onPage?: (page: number) => void;
}

const createPages = (count: number, start = 0) => {
  if (count > 0)
    return Array(count)
      .fill("")
      .map((_, idx) => (start > 0 ? (idx + start).toString() : (idx + 1).toString()));
  return [];
};

export const usePaginationReq = (paginationData: IPaginationData) => {
  const { currentPage = 1, totalCount, onPage } = paginationData;

  const leftSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    const totalPages = totalCount || 0;
    if (totalPages <= 6) return createPages(totalPages);
    return currentPage > 5 ? ["1"] : createPages(6);
  }, [currentPage, totalCount]);

  const rightSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    const totalPages = totalCount || 0;
    return totalPages > 6 ? [totalPages.toString()] : [];
  }, [currentPage, totalCount]);

  const middleSidePagesList = useMemo(() => {
    if (totalCount === null) return [];
    const totalPages = totalCount || 0;
    return totalPages > 5 && currentPage > 5 && currentPage <= totalPages - 5
      ? createPages(5, currentPage - 2)
      : [];
  }, [currentPage, totalCount]);

  const prevPageHandler = useCallback(() => {
    if (!onPage) return;
    if (currentPage > 1) onPage(currentPage - 1);
  }, [currentPage]);

  const nextPageHandler = useCallback(() => {
    if (!onPage) return;
    if (currentPage) onPage(currentPage + 1);
  }, [currentPage]);

  const selectPageHandler = (page: string) => {
    if (!onPage) return;
    onPage(Number(page));
  };

  const prevHandle = () => {
    if (!onPage) return;
    if (currentPage - 5 >= 1) onPage(Number(currentPage - 5));
    else onPage(Number(1));
  };

  const nextHandle = () => {
    if (!onPage) return;
    if (totalCount && currentPage + 5 <= totalCount) onPage(Number(currentPage + 5));
    else onPage(Number(totalCount));
  };

  const calcWidth = (pageString: string) => {
    if (pageString.length >= 4) return 50;
    if (pageString.length >= 3) return 42;
    if (pageString.length >= 2) return 34;
    return 28;
  };

  return {
    leftSidePagesList,
    rightSidePagesList,
    middleSidePagesList,
    prevPageHandler,
    nextPageHandler,
    selectPageHandler,
    prevHandle,
    nextHandle,
    calcWidth,
  };
};
