import { FC, memo, useMemo, useState } from "react";

import { Menu, Heading, ModalLayout } from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import Rename from "@/images/edit.svg";
import Delete from "@/images/nest.svg";
import { deleteFolder } from "@/store/focus/foldersManage";
import { pagesStatusState } from "@/store/pagesPermission";
import { IFolder } from "@/types";
import { pagePermission } from "@/utils/pagesPermissions";

import { FolderContent } from "../FolderContent/FolderContent";

import styles from "./InnerTab.module.scss";

interface IProps {
  folder: IFolder;
  handleShowModal: () => void;
}

const menuItems = [
  {
    icon: Rename,
    title: "Rename",
    key: 1,
  },
  {
    icon: Delete,
    title: "Delete",
    key: 2,
  },
];

export const InnerTab: FC<IProps> = memo(({ folder, handleShowModal }) => {
  const [show, setShow] = useState(false);
  const { pages } = useAppSelector(pagesStatusState);
  const dispatch = useAppDispatch();
  const handleSelect = (id: string | number) => {
    if (1 === id) {
      handleShowModal();
    }
    if (2 === id) {
      setShow(true);
    }
  };
  const handelConfirmDelete = () => {
    dispatch(deleteFolder(folder.id));
  };

  const handleCancelDelete = () => {
    setShow(false);
  };

  const folderActiveItems = useMemo(() => {
    return pagePermission.filterFolderData(pages, folder);
  }, [pages, folder]);

  return (
    <>
      <div className={styles.topSection}>
        <p className={styles.title}>
          Folder Name:<span>{folder.name}</span>
        </p>
        {folder.name !== "FOCUS" && (
          <Menu menuItems={menuItems} handelMenuSelect={handleSelect}>
            <div className={styles.menuInner}>
              <span>Folder Actions</span>
            </div>
          </Menu>
        )}
      </div>
      <FolderContent folder={folderActiveItems} />
      <ModalLayout show={show} onClose={handleCancelDelete}>
        <Confirm
          onCancel={handleCancelDelete}
          onConfirm={handelConfirmDelete}
          text="Do you really want to delete this Folder?"
        />
      </ModalLayout>
    </>
  );
});
