import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";

import { UserService } from "@/services";
import { notification } from "@/utils/notification";

import { RootState } from "../store";
import { IUser, IUserModifyAccessPayload } from "../types/user";

export interface IUsersState {
  isLoading: boolean;
  users: IUser[];
}

const initialState: IUsersState = {
  isLoading: false,
  users: [],
};

export const fetchAllUsers = createAsyncThunk("users/fetchUsers", async () => {
  const users = await UserService.getUsers();
  return users.data;
});

export const fetchModifyAccess = createAsyncThunk(
  "users/fetchModifyAccess",
  async ({ id, data }: { id: number; data: IUserModifyAccessPayload }) => {
    const users = await UserService.modifyAccess(id, data);
    return users.data;
  }
);

export const ProfileSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        if (action.error.message) {
          notification.error(action.error.message);
        }
        state.isLoading = false;
      })
      .addCase(fetchModifyAccess.fulfilled, (state, action) => {
        state.users = state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        });
        state.isLoading = false;
        notification.success("Updated!");
      })
      .addCase(fetchModifyAccess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchModifyAccess.rejected, (state, action) => {
        if (action.error.message) {
          notification.error(action.error.message);
        }
        state.isLoading = false;
      });
  },
});

const state = (state: RootState) => state;

export const usersState = createSelector(state, (state) => state.users);

export default ProfileSlice.reducer;
