import { FC } from "react";

import { useAppSelector } from "@/hooks";
import { etfIntelligenceState } from "@/store/forecast/forecastEtfIntelligence";
import { IPieChartData, IPieChartMergeData } from "@/types";

import { PieChart } from "../PieChart/PieChart";

import styles from "./ChartsList.module.scss";

const AUM = "(AUM)";

export const PieChartList: FC = () => {
  const { pieCharts, category } = useAppSelector(etfIntelligenceState);
  const pieCharstList = pieCharts[category];

  const mergedData = pieCharstList.reduce((acc: IPieChartMergeData[], pieCharst: IPieChartData) => {
    const findChart = pieCharstList.find(({ title }) => {
      if (title?.includes(AUM)) {
        return title?.replace(` ${AUM}`, "") === pieCharst.title;
      }
      return false;
    });

    if (!pieCharst.title?.includes(AUM)) {
      acc.push({
        title: pieCharst.title || "-",
        chart: {
          key: pieCharst.key,
          query: pieCharst.query,
          data: pieCharst.data,
          error: pieCharst.error,
        },
        chart2: {
          key: findChart?.key || "-",
          query: findChart?.query || "-",
          data: findChart?.data || null,
          error: findChart?.error || null,
        },
      });
    }
    return acc;
  }, []);

  return (
    <div className={styles.pieChartContainer}>
      {mergedData.map((data) => (
        <PieChart chartData={data} key={data.title} />
      ))}
    </div>
  );
};
