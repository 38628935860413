import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Button, Checkbox, Dropdown, ItemType } from "@/components";
import { UserRoleEnum } from "@/constants";
import { useAppDispatch, useUserPermissions } from "@/hooks";
import { IUser } from "@/types";

import { fetchModifyAccess } from "../../../../store/users";

import styles from "./UsersEdit.module.scss";

interface IProps {
  selected: IUser | null;
  isLoading?: boolean;
  setSelected?: Dispatch<SetStateAction<IUser | null>>;
}

const USER_ROLE = Object.values(UserRoleEnum).map((role) => ({
  value: role,
  key: role,
}));

export const UsersEdit: FC<IProps> = ({ selected, isLoading, setSelected }) => {
  const [role, setRole] = useState<UserRoleEnum>(UserRoleEnum.REGULAR);
  const [isActive, setActive] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { userRole } = useUserPermissions();

  const handleSelect = (_name: unknown, role: ItemType<UserRoleEnum>) => {
    setRole(role.key);
  };

  const handleClose = () => {
    setRole(UserRoleEnum.REGULAR);
  };

  const isDisabled = userRole.isAdmin && selected?.role === UserRoleEnum.SUPERADMIN;

  useEffect(() => {
    setRole(selected?.role || UserRoleEnum.REGULAR);
    setActive(selected?.is_active || false);
  }, [selected?.is_active, selected?.role]);

  const handleSend = () => {
    const data = {
      role,
      is_active: isActive || false,
    };
    if (selected?.id) {
      dispatch(fetchModifyAccess({ id: selected.id, data }));
    } else if (role) {
      //
    }
    handleClose();
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        Modify Access for user: {selected?.first_name} {selected?.last_name}
      </p>
      <div className={styles.dropdown}>
        <Dropdown<string, UserRoleEnum>
          onSelect={handleSelect}
          label="Select Role"
          name="Modify Access"
          list={USER_ROLE.filter(({ key }) =>
            userRole.isAdmin ? key !== UserRoleEnum.SUPERADMIN : true
          )}
          selected={{ key: role, value: role }}
          disable={isDisabled}
          placeholder={UserRoleEnum.SUPERADMIN}
        />
      </div>
      <Checkbox
        disabled={isDisabled}
        checked={isActive}
        label="Active"
        id="Active"
        onChange={setActive}
      />
      <div className={styles.modalFooter}>
        <div className={styles.buttons}>
          <Button
            disabled={isLoading || isDisabled}
            onClick={handleSend}
            text="Update"
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};
