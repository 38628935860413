import { FC } from "react";

import { Heading, Button, Loader } from "@/components";
import { ReactComponent as PerformanceIcon } from "@/images/chart-histogram-two-filled.svg";
import { ReactComponent as StopIcon } from "@/images/stop.svg";

import styles from "./LogoutDialog.module.scss";

import { ReactComponent as DeleteIcon } from "@images/nest.svg";
import { ReactComponent as SaveIcon } from "@images/save-v2.svg";

type ConfirmType =
  | "delete"
  | "save"
  | "progress"
  | "live-position"
  | "allocation"
  | "compare"
  | "calculate";

interface IProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  text?: string;
  type?: ConfirmType;
  progress?: boolean;
}

interface IAction {
  title: string;
  actionTitle?: string;
  actionText?: string;
  abortText?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Icon2?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  btnType?: "secondary" | "primary";
  isBtnDanger?: boolean;
}

const actionType: Record<ConfirmType, IAction> = {
  delete: {
    title: "Delete Confirmation",
    actionText: "Delete",
    abortText: "Cancel",
    Icon: DeleteIcon,
    isBtnDanger: true,
  },
  save: {
    title: "Save Confirmation",
    actionTitle: "Saving in progress",
    actionText: "Save",
    abortText: "Cancel",
    Icon: SaveIcon,
    btnType: "secondary",
  },
  progress: {
    title: "Modeling in progress...",
    abortText: "Stop Modeling",
    btnType: "primary",
    Icon: PerformanceIcon,
    Icon2: StopIcon,
  },
  allocation: {
    title: "Searching Optimal Allocation...",
    abortText: "Stop Searching",
    btnType: "primary",
    Icon2: StopIcon,
  },
  "live-position": {
    title: "Creating Live Positions...",
    abortText: "Stop Live Positions",
    btnType: "primary",
    Icon2: StopIcon,
  },
  compare: {
    title: "Comparison in progress...",
    abortText: "Stop Comparison",
    btnType: "primary",
    Icon2: StopIcon,
  },
  calculate: {
    title: "Calculating in progress...",
    abortText: "Stop Calculating",
    btnType: "primary",
    Icon2: StopIcon,
  },
};

export const Confirm: FC<IProps> = ({
  onCancel,
  onConfirm,
  text = "Do you really want to delete portfolio?",
  type = "delete",
  progress,
}) => {
  const { Icon, Icon2, actionText, abortText, btnType, isBtnDanger, title, actionTitle } =
    actionType[type];
  const disabled = (type === "delete" || type === "save") && progress;
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <Heading type="h2" className={styles.heading}>
          {progress && actionTitle ? actionTitle : title}
        </Heading>
        <p className={styles.infoDescription}>{text}</p>
        {progress && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        <div className={styles.btnGroup}>
          {!!onConfirm && (
            <Button
              danger={isBtnDanger}
              IconComponent={Icon}
              text={actionText || "Submit"}
              onClick={onConfirm}
              type={btnType}
              disabled={progress}
            />
          )}
          {!!onCancel && (
            <Button
              text={abortText || "Cancel"}
              onClick={onCancel}
              disabled={disabled}
              IconComponent={Icon2}
            />
          )}
        </div>
      </div>
    </div>
  );
};
