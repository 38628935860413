import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

import { DATE_ISO } from "@/constants";
import { strategiesService } from "@/services";
import { notification } from "@/utils";

import { useDownloadHistorical } from "./useDownloadHistorical";

type LivePositionData = Record<string, number | string | null>;

export const useDownloadLivePositions = (): [
  (id: number, fileName: string | null) => Promise<void>,
  boolean,
  AbortController
] => {
  const [livePositionProgress, setLivePositionProgress] = useState(false);
  const [livePositionData, setLivePositionData] = useState<LivePositionData[] | null>(null);
  const { downloadCSV, progress } = useDownloadHistorical();
  const cancel = strategiesService.abort;

  const downloadLivePositionsHandler = useCallback(
    async (id: number, fileName: string | null) => {
      const csvFileName = fileName
        ? `${fileName}_live_position_${dayjs().format(DATE_ISO)}`
        : `strategy_${id}_live_position_${dayjs().format(DATE_ISO)}`;
      const getLivePositionsData = async (id: number) => {
        try {
          const { data } = await strategiesService.getLivePositions(id);
          if (data.result?.length) {
            downloadCSV(csvFileName, data.result);
            setLivePositionData(data.result);
            notification.success(
              `Live Position was downloaded with success as (${fileName})! Please, check your download folder.`
            );
          } else {
            notification.warning("Currently, there are no positions for this strategy.");
          }
        } catch (err) {
          console.warn("Live position data not available!");
          const error = err as AxiosError | { isCanceled: boolean };
          if (!Object.hasOwn(error, "isCanceled")) {
            notification.error(
              "Sorry, an error occurred while getting the live position data! Please, try again later."
            );
          }
        } finally {
          setLivePositionProgress(false);
        }
      };

      if (livePositionData) {
        downloadCSV(csvFileName, livePositionData);
      } else {
        setLivePositionProgress(true);
        await getLivePositionsData(id);
      }
    },
    [livePositionData]
  );

  return [downloadLivePositionsHandler, livePositionProgress || progress, cancel];
};
