import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserService } from "@/services";
import { IUserResetPassword } from "@/types";
import { validate } from "@/utils";

type FormType = Record<string, { value: string; error: string | null }>;

const EXPIRATION_ERROR = ["The link you followed for reset password, has expired or invalid!"];

interface ICompleteStatus {
  password: boolean;
  email: boolean;
  error: string[] | null;
}

export const useForgotForm = (formInit: FormType) => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState(formInit);
  const [showPasswords, setShowPasswords] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completeStatus, setCompleteStatus] = useState<ICompleteStatus>({
    password: false,
    email: false,
    error: null,
  });

  const resetPassword = async (resetData: IUserResetPassword) => {
    try {
      await UserService.resetPassword(resetData);
      setCompleteStatus((prev) => ({ ...prev, password: true }));
    } catch (err) {
      setCompleteStatus((prev) => ({ ...prev, error: EXPIRATION_ERROR }));
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = async (email: string) => {
    try {
      await UserService.sendEmail(email);
      setCompleteStatus((prev) => ({ ...prev, email: true }));
    } catch (err) {
      setForm((prev) => ({
        ...prev,
        email: {
          value: "",
          error: "Sorry! User with provided email not exist.",
        },
      }));
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { email, password, repassword } = form;
    const pass = password.value.trim();
    const mail = email.value.trim();
    const repass = repassword.value.trim();

    if (showPasswords && (pass || repass)) {
      const [validatedPassForm, isValidPass] = validate(form, "password");
      const [validatedForm, isValidRepass] = validate(validatedPassForm, "repassword");
      const [validated, isValid] = validate(form, ["password", "repassword"]);

      if (isValidPass && isValidRepass) {
        const token = searchParams.get("token");
        if (isValid && token) {
          setLoading(true);
          resetPassword({ password: pass, token });
        } else setForm(validated);
      } else {
        setForm(validatedForm);
      }
    }

    if (!showPasswords && mail) {
      const [validatedForm, isValid] = validate(form, "email");
      if (isValid) {
        setLoading(true);
        sendEmail(form.email.value);
      } else {
        setForm(validatedForm);
      }
    }
  };

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    const fieldKey = ev.target.name;
    setForm((prev) => ({
      ...prev,
      [fieldKey]: { error: null, value: inputValue },
    }));
  }, []);

  useEffect(() => {
    const token = searchParams ? searchParams.get("token") : null;
    if (token) setShowPasswords(true);
  }, [searchParams]);

  return { form, showPasswords, loading, completeStatus, submitHandler, changeHandler };
};
