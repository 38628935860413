import { FC } from "react";

import { UserInviteForm } from "@/features";

interface IProps {
  noSlogan?: boolean;
}

export const UserInvite: FC<IProps> = ({ noSlogan }) => {
  return (
    <>
      <UserInviteForm noSlogan={noSlogan} />
    </>
  );
};
