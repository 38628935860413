import { IListItemToUpdate } from "@/types";
import { IMacroInfoType, ITickerType } from "@/types/forecast";

const EXEC_LIST_TO_UPDATE = "execListToUpdate";
export const getListToUpdate = (): IListItemToUpdate[] => {
  const item = localStorage.getItem(EXEC_LIST_TO_UPDATE);
  return item ? JSON.parse(item) : [];
};

export const setListToUpdate = (listToUpdate: IListItemToUpdate[]) =>
  localStorage.setItem("execListToUpdate", JSON.stringify(listToUpdate));

export const parsedData = (encodedString: string | null): object | null => {
  if (encodedString === null) return null;
  try {
    return JSON.parse(encodedString);
  } catch (e) {
    console.log("Error to decode JSON! ", e);
    return null;
  }
};

export function checkObjectFields<U extends object>(obj: U | null) {
  if (obj === null) return false;
  const keys = Object.keys(obj) as (keyof U)[];
  return obj && keys.every((k) => k in obj);
}

export const checkDataTickerInfo = (command: string | null): [ITickerType | null, boolean] => {
  const data = parsedData(command) as ITickerType | null;
  return [data, checkObjectFields(data)];
};

export const checkDataMacroInfo = (command: string | null): [IMacroInfoType | null, boolean] => {
  const data = parsedData(command) as IMacroInfoType | null;
  return [data, checkObjectFields(data)];
};

export const createMacroCommand = (indicator: string, isExtraData: boolean) => {
  return JSON.stringify({
    indicator,
    isExtraData,
  });
};
