import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { FC, memo, useEffect, useMemo, useRef, useState } from "react";

import { Loader } from "@/components";
import { useInViewportElement } from "@/features/UserOdinChat/hooks/useInViewportElemet";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fetchPieChartData, etfIntelligenceState } from "@/store/forecast/forecastEtfIntelligence";
import { IPieChartMergeData } from "@/types";
import { getPieChartOptions } from "@/utils";

import { NoData } from "../NoData/NoData";

import styles from "./PieChart.module.scss";

import { ReactComponent as LogoOdin } from "@images/logo-odin.svg";

interface IProps {
  chartData: IPieChartMergeData;
}

export const PieChart: FC<IProps> = memo(({ chartData }) => {
  const dispatch = useAppDispatch();
  const { requestStr: request } = useAppSelector(etfIntelligenceState);

  const { chart, chart2, title } = chartData;
  const isRequested = useRef<string | null>(null);
  const [isInViewport, invewportItemRef] = useInViewportElement(false);
  const [show, setShow] = useState(false);
  const noData = !chart.data?.length;

  useEffect(() => {
    if (isRequested.current !== request && isInViewport) {
      dispatch(
        fetchPieChartData({
          query: "etf/?type=pie&chart_name=" + chart.query,
          key: chart.key,
        })
      );
      dispatch(
        fetchPieChartData({
          query: "etf/?type=pie&chart_name=" + chart2.query,
          key: chart2?.key,
        })
      );
      isRequested.current = request;
    }
    if (isInViewport) setShow(true);
  }, [chartData, isInViewport, request]);

  const chartOptions = useMemo(() => {
    return getPieChartOptions(chart.data, chart2.data, title);
  }, [chart, chart2, title]);

  return (
    <div className={styles.container} ref={invewportItemRef}>
      {show && (
        <div className={styles.inner}>
          <p className={styles.title}>{title}</p>
          {!noData && (
            <div className={styles.watermarkImageContainer}>
              <LogoOdin />
            </div>
          )}
          <div className={styles.chartContainer}>
            {!chart.data || !chart2.data ? (
              <Loader helpText="Loading charts..." />
            ) : (
              <NoData show={noData}>
                <div className={styles.chartsGroup}>
                  {chartOptions.map((option, i) => (
                    <HighchartsReact
                      key={i}
                      highcharts={Highcharts}
                      options={option}
                      containerProps={{
                        style: {
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          jusifyContent: "center",
                          minWidth: 250,
                        },
                      }}
                    />
                  ))}
                </div>
              </NoData>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
