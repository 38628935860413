import { CustomEvent } from "@piwikpro/react-piwik-pro";
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button, ISerchItem, InputMessageItem, TooltipWrapper } from "@/components";

import styles from "./InputMessageBox.module.scss";

import SendIcon from "@images/send-message.svg";
import StoplIcon from "@images/stop.svg";

interface IProps {
  onMessage: (message: string) => void;
  onCancel: () => void;
  disabled?: boolean;
  optionsList: string[] | null;
  refuseDisabled?: boolean;
  inProgress?: boolean;
}

const sendAnalyticsChatData = (value?: string) => {
  if (process.env.NODE_ENV === "production") {
    CustomEvent.trackEvent("OdinChat", "Command", value?.trim() || "-");
  }
};

export const InputMessageBox: FC<IProps> = memo(
  ({ onMessage, onCancel, disabled, optionsList, refuseDisabled, inProgress }) => {
    const [value, setValue] = useState<string | undefined>("");
    const inputRef = useRef<HTMLInputElement | null>(null);

    const setElementRef = useCallback((el: HTMLInputElement | null) => {
      inputRef.current = el;
    }, []);

    const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const text = ev.target.value;
      setValue(text);
    };

    const sendMessageHandler = () => {
      if (value?.trim()) onMessage(value);
      clearHandler();

      sendAnalyticsChatData(value);
      // trackEvent(category: string, action: string, name?: string, value?: number)
    };

    const selectItemHandler = useCallback((item: ISerchItem) => {
      setValue(item.value);
      if (inputRef.current) inputRef.current.value = item.value;
    }, []);

    const clearHandler = () => {
      setValue("");
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current?.focus();
      }
    };

    const refuseHandler = () => {
      onCancel();
      inputRef.current?.focus();
    };

    const searchList = useMemo(() => {
      return optionsList?.map((value, key) => ({ value, key: key.toString() })) || [];
    }, [optionsList]);

    useEffect(() => {
      const keypressHandler = (ev: KeyboardEvent) => {
        if (disabled) return;
        if (ev.code === "Enter" && ev.shiftKey === false) {
          setValue("");
          if (inputRef.current?.value.trim()) {
            onMessage(inputRef.current.value);

            sendAnalyticsChatData(inputRef.current.value);
            inputRef.current.value = "";
          }
        }
      };

      window.addEventListener("keypress", keypressHandler);
      return () => {
        window.removeEventListener("keypress", keypressHandler);
      };
    }, [onMessage]);

    return (
      <div className={styles.inputContainer}>
        <div className={styles.wrapper}>
          <InputMessageItem
            placeholder="Ask Odin"
            label=""
            name="message"
            onChange={changeHandler}
            value={value}
            ref={(el) => setElementRef(el)}
            disabled={disabled}
            onClear={clearHandler}
            searchList={searchList}
            onSelectItem={selectItemHandler}
          >
            <div className={styles.buttonWrapper}>
              {(!disabled || refuseDisabled) && (
                <TooltipWrapper text="Send message">
                  <Button
                    text=""
                    onClick={sendMessageHandler}
                    icon={SendIcon}
                    disabled={!value?.trim() || refuseDisabled}
                    minWidth="100px"
                  />
                </TooltipWrapper>
              )}
              {disabled && !refuseDisabled && (
                <TooltipWrapper text="Stop your request">
                  <Button
                    text=""
                    onClick={refuseHandler}
                    icon={StoplIcon}
                    disabled={inProgress}
                    minWidth="100px"
                  />
                </TooltipWrapper>
              )}
            </div>
          </InputMessageItem>
        </div>
      </div>
    );
  }
);
