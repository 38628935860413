import React, { FC, Fragment } from "react";

import { Button, FooterNavigation, InputItem, Loader, MessageContent } from "@/components";
import { authService } from "@/services";

import { useSubscribeForm } from "./hooks/useSubscribeForm";

import styles from "./Subscribe.module.scss";

export const Subscribe: FC = () => {
  const { form, loading, message, submitHandler, changeHandler } = useSubscribeForm();
  const isUserLogged = authService.isLogined();

  return (
    <div className={`${styles.container} ${isUserLogged ? styles.inverse : ""}`}>
      <div className={styles.inner}>
        {message ? (
          <Fragment>
            <MessageContent
              title="Thank you for subscription!"
              message={message}
              footnote="You can check your inbox for the insights."
              themInverse={isUserLogged}
            />
          </Fragment>
        ) : (
          <div className={styles.formContainer}>
            <div className={styles.loader}>{loading ? <Loader white={!isUserLogged} /> : null}</div>
            <form className={styles.mainForm} onSubmit={submitHandler}>
              <h1 className={styles.title}>Subscribe to Insights</h1>
              <div className={styles.fieldWrapper}>
                <InputItem
                  label=""
                  name="email"
                  type="email"
                  placeholder="User email"
                  onChange={changeHandler}
                  value={form.email.value}
                  error={form.email.error}
                  disabled={loading}
                  themed={!isUserLogged}
                />
                <div className={styles.btnWrapper}>
                  <Button
                    text="Subscribe"
                    type={isUserLogged ? "primary" : "white"}
                    disabled={loading || !form.email.value.trim() || !!form.email.error}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        {isUserLogged ? null : <FooterNavigation fullWidth />}
      </div>
    </div>
  );
};
