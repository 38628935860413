import { FC } from "react";

import { Heading, ModalLayout } from "@/components";
import { FolderTypeEnum, IChartReturnData, ICommandData, IFolder } from "@/types";
import { IChatMessage } from "@/types/odinChat";

import { DiscoverDetailsView } from "./components/DiscoverDetailsView/DiscoverDetailsView";
import { ForecastDetailsView } from "./components/ForecastDetailsView/ForecastDetailsView";
import { MacroDetailsView } from "./components/MacroDetailsView/MacroDetailsView";
import { OdinChatDetilsView } from "./components/OdinChatDetailsView/OdinChatDetilsView";

import styles from "./PreviewModal.module.scss";

interface IProps {
  commandData: ICommandData | undefined;
  closeModal: () => void;
  data: IChartReturnData | null;
  folder: IFolder;
}

const getChartByType = (
  { type, id, title, description }: ICommandData,
  data: IChartReturnData | null,
  savedDataId: number | undefined,
  closeModal: () => void
) => {
  if (FolderTypeEnum.FORECAST_EQUITIES === type) {
    return <ForecastDetailsView command={id} type={type} title={title} />;
  }
  if (FolderTypeEnum.ODIN_CHAT === type) {
    const messageData = data?.[type]?.[id];
    return (
      <OdinChatDetilsView
        message={messageData as IChatMessage}
        savedDataId={savedDataId}
        commandId={id}
        closeModal={closeModal}
      />
    );
  }
  if (FolderTypeEnum.MACRO === type) {
    return <MacroDetailsView command={id} />;
  }
  if (FolderTypeEnum.COMMON_API === type) {
    return <DiscoverDetailsView command={id} description={description} title={title} />;
  }
  return null;
};

export const PreviewModal: FC<IProps> = ({ closeModal, commandData, data, folder }) => {
  const savedDataId = folder.saved_data.find(({ command }) => commandData?.id === command)?.id;
  return (
    <ModalLayout show={!!commandData?.id} onClose={closeModal}>
      <div className={styles.chart}>
        <div className={styles.close} onClick={closeModal}></div>
        <div className={styles.heading}>
          <Heading type="h3">Chart Preview</Heading>
        </div>
        <div className={styles.wrapper}>
          {commandData && getChartByType(commandData, data, savedDataId, closeModal)}
        </div>
      </div>
      ;
    </ModalLayout>
  );
};
