import { useCallback, useMemo, useState } from "react";

import { ItemType } from "@/components";
import {
  BDF_COMPANY_ID,
  UserRoleEnum,
  productList,
  statusList,
  statusRadioList,
} from "@/constants";
import { useAppSelector } from "@/hooks";
import { authService, AxiosErrorStatus, getErrorStataus, UserService } from "@/services";
import { profileInfo, userRole } from "@/store/profile";
import { IProfilePagePermissionType, IRole, IUserInvite } from "@/types";
import { ICompany } from "@/types/company";
import { notification, validate } from "@/utils";
import { pagePermission } from "@/utils/pagesPermissions";

type FormType = Record<string, { value: string; error: string | null }>;
type CompleteStatus = { isComplete: boolean; error: string[] | null };

const EXIST_ERROR_MESSAGE = ["Sorry! User with this email already exist."];
const INVITE_ERROR_MESSAGE = ["Sorry! You have not permissions for", "this operation."];
const SOME_ERROR_MESSAGE = [
  "Sorry! Error occurred during this operation.",
  "Please, try again later.",
];
const SUCCESS_MESSAGE = "User invitation message was successful sent!";

const delay = async () => {
  return new Promise((resolve) => setTimeout(() => resolve(true), 2000));
};

const createAccessConfig = (): Record<string, IProfilePagePermissionType> => ({
  forecasts: "active",
  modeling: "active",
  odinchat: "active",
});

export const useInviteForm = (
  formInit: FormType,
  defaultRole: ItemType<IRole["key"]>,
  companies: ICompany[] | null
) => {
  const [form, setForm] = useState(() => ({ ...formInit }));
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ItemType<string> | null>(null);
  const [selectedRole, setSelectedRole] = useState<ItemType<IRole["key"]>>(() => ({
    ...defaultRole,
  }));
  const { profile } = useAppSelector(profileInfo);
  const { role, company } = profile || {};
  const [product, setProduct] = useState<ItemType<string> | null>(productList[0]);
  const [accessConfig, setAccessConfig] =
    useState<Record<string, IProfilePagePermissionType>>(createAccessConfig);

  const [completeStatus, setCompleteStatus] = useState<CompleteStatus>({
    isComplete: false,
    error: null,
  });

  const { isSuperAdmin, isSales } = useAppSelector(userRole);
  const isVisible = isSuperAdmin || isSales;

  const isCompanyOwner = role === UserRoleEnum.ADMIN;

  const disable = !!form.email.error || !form.email.value || (!selectedCompany && !isCompanyOwner);

  const invite = async (userInvite: IUserInvite) => {
    try {
      await delay();
      await UserService.userInvite(userInvite);
      setForm((prev) => ({ ...prev, password: { value: "", error: null } }));
      setCompleteStatus({ error: null, isComplete: true });

      notification.success(SUCCESS_MESSAGE);
      setSelectedRole(() => ({ ...defaultRole }));
      setForm(() => ({ ...formInit }));
      setSelectedCompany(null);
      setAccessConfig(createAccessConfig());
    } catch (err) {
      let errMessage: string[] = SOME_ERROR_MESSAGE;
      const errStatus = getErrorStataus(err as AxiosErrorStatus);

      if (errStatus === 400) errMessage = EXIST_ERROR_MESSAGE;
      if (errStatus === 403) errMessage = INVITE_ERROR_MESSAGE;
      if (errStatus !== 401) setCompleteStatus({ error: errMessage, isComplete: true });
      console.warn("Error sending user Email!", errStatus);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { email } = form;
    const mail = email.value.trim();
    if (mail) {
      const [validatedForm, isValid] = validate(form, "email");
      const tokens = authService.getTokens();
      if (isCompanyOwner && isValid && tokens && company) {
        setLoading(true);
        invite({
          email: mail,
          role: selectedRole.key,
          company_id: company.id,
        });
      } else if (
        isValid &&
        tokens &&
        tokens.access &&
        selectedCompany &&
        selectedCompany.key !== undefined
      ) {
        setLoading(true);
        const access: IUserInvite = {
          email: mail,
          role: selectedRole.key,
          company_id: Number(selectedCompany.key),
        };
        if (isVisible) access.access_control = pagePermission.convertConfig(accessConfig);
        invite(access);
      } else {
        setForm(validatedForm);
      }
    }
  };

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    const fieldKey = ev.target.name;
    setForm((prev) => ({
      ...prev,
      [fieldKey]: { error: null, value: inputValue },
    }));
  }, []);

  const slelectHandler = useCallback((name: string, item: ItemType<string>) => {
    setSelectedCompany(item);
  }, []);

  const selectProductHandle = (name: string, item: ItemType<string>) => {
    setProduct(item);
  };

  const selectStatusHandle = (name: string) => {
    const item = statusList.find((i) => i.key === name);
    if (product && item) setAccessConfig((prev) => ({ ...prev, [product.key]: item.key }));
  };

  const activeStatus = useMemo(() => {
    return pagePermission.getActiveStatus(product, accessConfig, statusList);
  }, [accessConfig, product, statusList]);

  const selectRoleHandler = useCallback(
    (name: string, item: ItemType<IRole["key"]>) => {
      setSelectedRole(item);
      if (item.key === "sales" && companies) {
        const foundCompanyItem = companies.find((c) => c.id === BDF_COMPANY_ID);
        if (foundCompanyItem)
          setSelectedCompany({ key: `${foundCompanyItem.id}`, value: foundCompanyItem.name });
      }
    },
    [companies]
  );

  return {
    form,
    loading,
    disable,
    completeStatus,
    selectedCompany,
    selectedRole,
    isCompanyOwner,

    selectProductHandle,
    selectStatusHandle,
    statusList: statusRadioList,
    activeStatus,
    product,
    isVisible,

    slelectHandler,
    submitHandler,
    changeHandler,
    selectRoleHandler,
  };
};
