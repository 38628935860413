import { ComponentWithPermissions } from "@/components";

const forecastMacroLoader = () =>
  import("./ForecastMacro/ForecastMacro").then(({ ForecastMacro }) => ({
    default: ForecastMacro,
  }));

const forecastEquitiesLoader = () =>
  import("./ForecastEquities/ForecastEquities").then(({ ForecastEquities }) => ({
    default: ForecastEquities,
  }));

const forecastIndustriesLoader = () =>
  import("./Industries/Industries").then(({ Industries }) => ({
    default: Industries,
  }));

const forecastSectorsLoader = () =>
  import("./Sectors/Sectors").then(({ Sectors }) => ({
    default: Sectors,
  }));

export const ForecastMacroPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={forecastMacroLoader}
      title="Macro"
      pageKey={"ForecastMacro"}
    />
  );
};

export const ForecastEquitiesPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={forecastEquitiesLoader}
      title="Companies"
      pageKey={"ForecastEquities"}
    />
  );
};

export const ForecastIndustriesPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={forecastIndustriesLoader}
      title="Industries"
      pageKey={"ForecastIndustries"}
    />
  );
};

export const ForecastSectorsPermissions = () => {
  return (
    <ComponentWithPermissions
      componentLoader={forecastSectorsLoader}
      title="Sectors"
      pageKey={"ForecastSectors"}
    />
  );
};
