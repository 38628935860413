import { Dispatch, FC, SetStateAction } from "react";

import { Button, Checkbox, InputItem, ModalLayout } from "@/components";
import { CompanyForm, ICompany } from "@/types/company";

import { useCompanyForm } from "../../hooks/useCompanyForm";

import styles from "./Company.module.scss";

import SaveIcon from "@images/save-v2.svg";

interface IProps {
  selected: ICompany | null;
  showModal: boolean;
  isLoading?: boolean;
  setSelected: Dispatch<SetStateAction<ICompany | null>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const CompanyEdit: FC<IProps> = ({
  selected,
  isLoading,
  showModal,
  setShowModal,
  setSelected,
}) => {
  const handleClose = () => {
    setShowModal(false);
    setSelected(null);
  };

  const { handleInputChange, setActive, handleSubmitForm, form, isActive } = useCompanyForm(
    selected,
    handleClose,
    showModal
  );

  return (
    <ModalLayout show={showModal || !!selected} onClose={handleClose}>
      <form className={styles.container} onSubmit={(ev) => ev.preventDefault()}>
        <h2>{showModal ? "Add New Company" : "Edit Company"}</h2>
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="Name"
          name="name"
          type="input"
          placeholder="type company Name"
          value={form.name.value}
          isRequired
          autofocus
          error={form.name.error}
        />
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="AuM"
          name="aum"
          type="input"
          placeholder="type company AuM"
          value={form.aum.value}
          error={form.aum.error}
        />
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="Strategy"
          name="strategy"
          type="input"
          placeholder="type company Strategy"
          value={form.strategy.value}
          error={form.strategy.error}
        />
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="Type of Client"
          name="type"
          type="input"
          placeholder="client type"
          value={form.type.value}
          error={form.type.error}
        />
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="Location"
          name="location"
          type="input"
          placeholder="type company location"
          value={form.location.value}
          error={form.location.error}
        />
        <InputItem<keyof CompanyForm>
          onChange={handleInputChange}
          label="Comment"
          name="comment"
          type="input"
          placeholder="type a comment"
          value={form.comment.value}
          error={form.comment.error}
        />
        {!!selected && (
          <div className={styles.checkboxWrapper}>
            <Checkbox checked={isActive} label="Active" id="Active" onChange={setActive} />
          </div>
        )}
        <div className={styles.modalFooter}>
          <div className={styles.buttons}>
            <Button onClick={handleClose} text="Close" />
            <Button
              disabled={isLoading || !form.name.value}
              onClick={handleSubmitForm}
              icon={SaveIcon}
              text="Save"
              type="secondary"
            />
          </div>
        </div>
      </form>
    </ModalLayout>
  );
};
