import CustomIndicatorPoster from "@images/posters/custom-indicator.jpg";
import DataPortalPoster from "@images/posters/data-portal.jpg";
import EventsCalendarPoster from "@images/posters/events-calendar.jpg";
import MarketViewPoster from "@images/posters/market-view.jpg";
import OdinCastPoster from "@images/posters/odin-cast.jpg";
import OdinChatPoster from "@images/posters/odin-chat.jpg";
import PortfolioConstructorPoster from "@images/posters/portfolio-constructor.jpg";
import ScenarioPlannerPoster from "@images/posters/scenario-panner.jpg";
import StrategyBuilderPoster from "@images/posters/strategy-builder.jpg";

import { SideBarKeyEnum } from "@/constants";

import { helpInfo } from "./help";

const BASE_URL = "https://odin-media.s3.amazonaws.com";

export const TUTORIALS = {
  [SideBarKeyEnum.MARKET_VIEW]: {
    title: "Market View",
    posterImage: MarketViewPoster,
  },
  [SideBarKeyEnum.MULTI_STRATEGY]: {
    title: "Multi Strategy",
    posterImage: PortfolioConstructorPoster,
  },
  [SideBarKeyEnum.SINGLE_STRATEGY]: {
    title: "Single Strategy",
    posterImage: StrategyBuilderPoster,
  },
  [SideBarKeyEnum.INDUSTRIES]: {
    title: "Forecasts",
    posterImage: OdinCastPoster,
  },
  [SideBarKeyEnum.SECTORS]: {
    title: "Forecasts",
    posterImage: OdinCastPoster,
  },
  [SideBarKeyEnum.COMPANIES]: {
    title: "Forecasts",
    posterImage: OdinCastPoster,
  },
  [SideBarKeyEnum.MACRO]: {
    title: "Macro",
    posterImage: null,
  },
  [SideBarKeyEnum.MULTI_ASSET]: {
    title: "Macro Planner",
    posterImage: ScenarioPlannerPoster,
  },
  [SideBarKeyEnum.FIXED_INCOME]: {
    title: "Macro Planner",
    posterImage: ScenarioPlannerPoster,
  },
  [SideBarKeyEnum.COMMODITIES]: {
    title: "Macro Planner",
    posterImage: ScenarioPlannerPoster,
  },
  [SideBarKeyEnum.EQUITY_SECTORS]: {
    title: "Macro Planner",
    posterImage: ScenarioPlannerPoster,
  },
  [SideBarKeyEnum.EVENTS_CALENDAR]: {
    title: "Macro Planner",
    posterImage: EventsCalendarPoster,
  },
  [SideBarKeyEnum.DATA]: {
    title: "Data",
    posterImage: DataPortalPoster,
  },
  [SideBarKeyEnum.CUSTOM_INDICATOR]: {
    title: "Custom Indicator",
    posterImage: CustomIndicatorPoster,
  },
  [SideBarKeyEnum.ODINCHAT]: {
    title: "OdinChat",
    posterImage: OdinChatPoster,
  },
  [SideBarKeyEnum.ETF_INTELLIGENCE]: {
    title: "ETF Intelligence",
    posterImage: OdinChatPoster,
  },
  default: {
    title: "Default",
    url: `${BASE_URL}/OdinUltra-compressed.mp4`,
    description: helpInfo.noDescription,
    posterImage: "",
  },
};

export type TutorialHelpType = typeof TUTORIALS;

export type DocumentsFoldersType = "overview" | "use-cases" | "learn-more";
export type DocumentSectionType =
  | "forecasting"
  | "modeling"
  | "discretionary"
  | "systematic"
  | "hedging"
  | "crypto"
  | "asset-allocation";

export const enum TabsEnum {
  TUTORIALS = "Tutorials",
  OVERVIEW = "Overview",
  SOLUTIONS = "Solutions",
}

export const queryKeys: Record<TabsEnum, DocumentsFoldersType | "tutorials"> = {
  Overview: "overview",
  Tutorials: "tutorials",
  Solutions: "learn-more",
};

export type TabItemType = "documents" | "tutorials";

export interface ITabItem {
  type: TabItemType;
  key: string;
  name: TabsEnum;
}

export const tabItemsList: ITabItem[] = [
  {
    type: "tutorials",
    key: queryKeys[TabsEnum.TUTORIALS],
    name: TabsEnum.TUTORIALS,
  },
  {
    type: "documents",
    key: queryKeys[TabsEnum.OVERVIEW],
    name: TabsEnum.OVERVIEW,
  },
  {
    type: "documents",
    key: queryKeys[TabsEnum.SOLUTIONS],
    name: TabsEnum.SOLUTIONS,
  },
];
