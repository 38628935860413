import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { updatePage, uploadVideo } from "@/store/adminPanel/adminPanel";
import { IAdminPanelPages, IMeta } from "@/types/adminPanel";
import { notification } from "@/utils";

export const useUploadVideo = (page: IAdminPanelPages | undefined, meta: IMeta) => {
  const [video, setVideo] = useState<File | null>(null);
  const dispatch = useAppDispatch();

  const handleUpload = (video: File | null) => {
    if (video) {
      const allowedFormats = ["video/mp4", "video/webm"]; // Update allowed video formats
      const isValidFormat = allowedFormats.includes(video.type);
      if (isValidFormat) {
        setVideo(video);
        const data = {
          key: page?.key || "",
          meta: {
            ...meta,
            videoFileName: video?.name,
          },
        };
        if (!page?.id) return;
        dispatch(updatePage({ pageId: page?.id, data }));
      } else {
        notification.info("Invalid file format. Please upload a valid video (MP4 or WebM).");
      }
    } else {
      setVideo(null);
    }
  };

  const handleClear = () => {
    setVideo(null);
  };

  const onUpload = useCallback(
    async (pageId: number, video: File | null) => {
      const formData = new FormData();
      formData.append("video", video || "");
      dispatch(uploadVideo({ pageId, data: video ? formData : null }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!video || !page?.id) return;
    onUpload(page?.id, video);
  }, [onUpload, video, page?.id]);

  return {
    video,
    handleUpload,
    handleClear,
    onUpload,
  };
};
