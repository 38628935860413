import { pagesPermissionService } from "@/services";
import { IProfilePagePermissionType, IUser } from "@/types";
import { notification } from "@/utils";
import { pagePermission } from "@/utils/pagesPermissions";

export const useUpdateAccessConfig = (
  accessConfig: Record<string, IProfilePagePermissionType> | null,
  configRef: React.MutableRefObject<Record<string, IProfilePagePermissionType> | null>,
  email: string,
  setProgress: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const saveHandle = () => {
    const updateConfig = async (config: Record<string, IProfilePagePermissionType>) => {
      try {
        const { data } = await pagesPermissionService.updatePagesStatus(email, {
          access_control: {
            ...config,
          },
        });
        if ("message" in data) {
          notification.success(data.message);
          configRef.current = accessConfig;
        }
      } catch (err) {
        notification.error("Sorry, something went wrong during updating user access status!");
      } finally {
        setProgress(false);
      }
    };

    if (accessConfig) {
      updateConfig(pagePermission.convertConfig(accessConfig));
      setProgress(true);
    }
  };

  const canSaveConfig = pagePermission.canSaveStatus(accessConfig, configRef);

  return {
    saveHandle,
    canSaveConfig,
  };
};
