import { FC, memo } from "react";

import styles from "./Button.module.scss";

interface IProps {
  text: string;
  onClick?: (ev: React.MouseEvent) => void;
  disabled?: boolean;
  icon?: string;
  danger?: boolean;
  type?:
    | "primary"
    | "secondary"
    | "white"
    | "gost"
    | "download"
    | "empty"
    | "white"
    | "action"
    | "black";
  size?: "default" | "small" | "large";
  minWidth?: string;
  IconComponent?: React.ComponentType;
  isIconOnly?: boolean;
  rightIcon?: boolean;
  border?: string;
  inverseColor?: boolean;
}

export const Button: FC<IProps> = memo(
  ({
    text,
    onClick,
    disabled,
    icon,
    danger,
    type = "primary",
    size = "default",
    minWidth,
    IconComponent,
    rightIcon = false,
    border,
    inverseColor = false,
  }) => {
    return (
      <div
        className={`${styles.container} ${!text ? styles.iconOnly : ""} ${styles[size]} ${
          inverseColor ? styles.inverse : ""
        }`}
        style={{ minWidth }}
      >
        <button
          style={{ minWidth, border }}
          className={`${styles.button} ${danger ? styles.danger : styles[type]}`}
          onClick={onClick}
          disabled={disabled}
        >
          {!rightIcon && IconComponent ? <IconComponent /> : null}
          {!!icon && <img src={icon} alt="icon" />}
          {!!text && <span>{text}</span>}
          {rightIcon && IconComponent ? <IconComponent /> : null}
        </button>
      </div>
    );
  }
);
