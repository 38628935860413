import { FC } from "react";

import { FooterNavigation } from "@/components";
import { UserLoginForm } from "@/features";

export const UserLogin: FC = () => {
  return (
    <>
      <UserLoginForm />
      <FooterNavigation />
    </>
  );
};
