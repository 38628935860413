import React, { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import App from "@/App";
import { GoBack } from "@/components";
import { sentryReportError } from "@/constants";
import BgImage from "@/images/bg-image.jpg";
import { ReactComponent as WarningIcon } from "@/images/error-icon.svg";

import styles from "./ErrorBoundaries.module.scss";

const isDevelopmentMode = process.env.NODE_ENV !== "production";

interface IProps {
  onReset: () => void;
}

const ErrorInfo: FC<IProps> = ({ onReset }) => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    onReset();
    navigate(-1);
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${BgImage})` }}>
      <div className={styles.inner}>
        <h1>Ooops!</h1>
        <p className={styles.textMessage}>
          Sorry, something went wrong on this page. The development team will fix it soon.
        </p>
        <div className={styles.iconWrapper}>
          <WarningIcon />
        </div>
        <GoBack text="Go Back" onClick={goBackHandler} type="secondary" />
      </div>
    </div>
  );
};

export class ErrorBoundaries extends React.Component<unknown, { hasError: boolean }> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    console.warn("Component Did Catch Error!");
  }

  resetHandler = () => {
    this.setState({ hasError: false });
  };

  static getDerivedStateFromError(error: Error) {
    if (isDevelopmentMode) throw error;

    sentryReportError(
      JSON.stringify({
        err: error.message,
        type: "App Bounbary Error",
      })
    );
    return { hasError: true };
  }

  render() {
    if (isDevelopmentMode) return <App />;
    return this.state.hasError ? <ErrorInfo onReset={this.resetHandler} /> : <App />;
  }
}
