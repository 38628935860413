import dayjs from "dayjs";

import { IKeyDriversChatSerieData } from "@/components";
import { DATE_ISO_US } from "@/constants";
import { CRITERIA_LIST_FILTER_BEAT_MISS } from "@/constants/forecast";
import { IClosedMarket, IDaysRange, IRangeSelected } from "@/types";
import {
  IEHTable,
  IForecastEquities,
  IForecastMacroItem,
  IInvoledTikers,
  IPlotBands,
} from "@/types/forecast";

export const FORECAST_SP_MOVE_KEY = "forecasted_sp500_move";
export const MARKET_PRICE_KEY = "closest-market-matches";

export const getPlotBands = (data: IEHTable | null, indecators: string[]): IPlotBands[] => {
  if (!data || !indecators.includes(data?.meta.indicator)) return [];
  let hasFirst = false;
  let last = "";
  const prep = data?.values.reduce(
    (acc: any, item) => {
      if (item.forecast_finalized && !hasFirst) {
        hasFirst = true;
        acc.start.push(item?.date);
      } else if (!item.forecast_finalized && hasFirst) {
        hasFirst = false;
        acc.end.push(last);
      } else if (item.forecast_finalized) {
        last = item?.date || "";
      }
      return acc;
    },
    { start: [], end: [] }
  );
  const dates = data?.values.map((item) => item.date).reverse();
  return prep.start?.map((_: any, i: number) => {
    return {
      color: "#d9d9d9",
      from: dates.indexOf(prep.start[i]),
      to: dates.indexOf(prep.end[i]),
      zIndex: 1,
    };
  });
};

export const alfaNumericSorter = (a?: string | number, b?: string | number) => {
  if (!a || !b) return 0;

  const [valA, valB] = [a.toString(), b.toString()].map((v) => {
    const res = v.match(/[-]?[0-9.]+/gm);
    if (res) {
      return Number(res[0]);
    }
    return 0;
  });

  const [numA, numB] = [valA, valB].filter((n) => !Number.isNaN(Number(n)));
  if (numA !== undefined && numB !== undefined) return numA - numB;
  else return a.toString().localeCompare(b.toString()) || 0;
};

export const returnSpecificNote = (data: IInvoledTikers[]) => {
  const note1 = "*. No consistent revenue available";
  const note2 = "§. No consistent gross margin available";
  const note3 = "†. No consistent ebitda margin available";
  const noteList = data.reduce((acc: string[], item: IInvoledTikers) => {
    if (item?.note.includes("1") && !acc.includes(note1)) {
      acc.push(note1);
    }
    if (item?.note.includes("2") && !acc.includes(note2)) {
      acc.push(note2);
    }
    if (item?.note.includes("3") && !acc.includes(note3)) {
      acc.push(note3);
    }
    return acc;
  }, []);
  return noteList.map((note) => <span key={note}>{note}</span>);
};

export const replaceSpecialCharacters = (inputString: string): string => {
  const replacements: { [key: string]: string } = {
    "1": "*",
    "2": "§",
    "3": "†",
  };

  const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

  return inputString.replace(regexPattern, (match) => replacements[match]);
};

export const filterObjectsByFields = (
  objects: IForecastEquities[],
  fieldsToHide: string[]
): IForecastEquities[] => {
  return objects.map((obj) => {
    const filteredObj: IForecastEquities = {};
    for (const key in obj) {
      if (!fieldsToHide?.includes(key)) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  });
};

export const formatingDates = (dates: Record<string, number | null> | null) => {
  try {
    if (dates && typeof dates === "object") {
      return Object.keys(dates).reduce<Record<string, number | null>>((acc, dateKye) => {
        const convertedDateKye = dayjs(dateKye).format(DATE_ISO_US);
        acc[convertedDateKye] = dates[dateKye];
        return acc;
      }, {});
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const createFilterText = (options: Record<string, boolean> | null) => {
  const groups = Object.entries(options || {}).reduce<string[]>((result, [key, value]) => {
    const item = CRITERIA_LIST_FILTER_BEAT_MISS.find((i) => i.value === key);
    if (item && options) {
      const confidence = options[item.value2] || null;
      const str = `${item.label} ${value ? "Beat" : "Miss"}${confidence ? " / Confidence" : ""}`;
      result.push(str);
    }
    return result;
  }, []);
  return groups;
};

export const keyDriversChartConfig = (
  chartData: Record<string, number> | undefined
): IKeyDriversChatSerieData => ({
  type: "bar",
  categories: Object.keys(chartData || {}),
  seriesData: [
    {
      type: "bar",
      data: Object.keys(chartData || {}).map((key) => ({
        name: key,
        y: chartData ? chartData[key] : null,
      })),
    },
  ],
});

export const convertToCSVChartArray = (
  data: Record<string, string | number | null>[]
): string[][] => {
  const dataList: string[][] = [];
  try {
    const keys = Object.keys(data[0]);
    dataList.push(keys);

    for (const row of data) {
      const values = keys.map((key) => (row[key] === null ? "-" : row[key]?.toString() || ""));
      dataList.push(values);
    }
  } catch (err) {
    console.warn("Error while creating csv rows! Check input data.");
  }

  return dataList;
};

export const createBubbleCard = (data: IClosedMarket): IForecastMacroItem => {
  const returnItem = data.expected_return?.slice(-1).pop() || null;

  const getValue = (val: string | null) => {
    if (val === null) return null;
    const value = Number.parseFloat(val?.split(" ")[0] || "#");
    return Number.isNaN(value) ? null : value;
  };

  const getColor = (val: string | null) => {
    try {
      if (val === null) return "gray";
      const value = Number.parseFloat(val?.split(" ")[1].replace("(", "").replace("%)", "") || "#");
      if (Number.isNaN(value)) return "gray";
      if (value >= -1 && value <= 1) return "yellow";
      if (value > 1) return "green";
      return "red";
    } catch (e) {
      return "gray";
    }
  };

  return {
    title: "S&P 500 Index",
    value: returnItem ? getValue(returnItem["Weighted Median"]) : null,
    color: returnItem ? getColor(returnItem["Weighted Median"]) : "gray",
    text: "30-Day Forward",
    indicator: FORECAST_SP_MOVE_KEY,
    color_formula: "",
  };
};

export const calculateDaysRange = (selectedDaysRange: IDaysRange | undefined) => {
  const range = selectedDaysRange
    ? Object.keys(selectedDaysRange).reduce((acc, key) => {
        const value = selectedDaysRange[key as keyof (IDaysRange | IRangeSelected)];
        const fieldKey = key as keyof (IDaysRange | IRangeSelected);
        if ((+value).toString() === value) {
          acc[fieldKey] = selectedDaysRange[fieldKey];
        }
        return acc;
      }, {} as IDaysRange | IRangeSelected)
    : null;

  return range;
};
