import { FC } from "react";

import { getUserAvatar } from "@/constants";

import styles from "./Avatar.module.scss";

interface IProps {
  url: string | null;
  wrapped?: boolean;
  active: boolean;
}

export const Avatar: FC<IProps> = ({ url, wrapped, active }) => {
  return (
    <div className={`${styles.avatar} ${wrapped ? styles.wrapped : ""}`}>
      <div
        className={`${styles.bgImage} ${active ? "" : styles.inactive}`}
        style={{
          backgroundImage: `url(${getUserAvatar(url)})`,
        }}
      />
    </div>
  );
};
