import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";

import { Button, Dropdown, ItemType, Loader } from "@/components";
import { InputTextItem } from "@/components/InputItem/InputTextItem";
import { SideBarKeyEnum } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { ReactComponent as SaveIcon } from "@/images/save-v2.svg";
import { AdminPanelState, createPage, getPages, updatePage } from "@/store/adminPanel/adminPanel";
import { IAdminPanelPages, ResetVideoRef } from "@/types/adminPanel";
import { getAllPages } from "@/utils";

import { InputItem } from "../../../../components/InputItem/InputItem";
import { VideoUpload } from "../VideoUpload/VideoUpload";

import styles from "./PageInfo.module.scss";

type PagesGroup = { [key: string]: IAdminPanelPages[] };

const NEW_VIDEO = "+ Add New Video";

export const PageInfo: FC = () => {
  const [selected, setSelected] = useState<ItemType<string> | null>(null);
  const [selectedSub, setSelectedSub] = useState<ItemType<string> | null>(null);
  const [infoText, setInfoText] = useState<string>("");
  const [videoTitle, setVideoTitle] = useState<string>("");

  const dispatch = useAppDispatch();
  const { pages, isLoading } = useAppSelector(AdminPanelState);
  const ref = useRef<ResetVideoRef | null>(null);
  const siedeBarTabsList = getAllPages();
  const page = useMemo(
    () => pages?.find((page) => page.key === selected?.key),
    [pages, selected?.key]
  );

  const subPage = useMemo(
    () => pages?.find((page) => page.key === selectedSub?.key),
    [pages, selectedSub?.key]
  );

  useEffect(() => {
    if (pages?.length) return;
    dispatch(getPages());
  }, [dispatch, pages?.length]);

  const groupePages: PagesGroup = useMemo(
    () =>
      (pages || [])?.reduce((acc, page, _i, pages) => {
        const groupe = pages.filter((page) => page.meta?.attr);
        if (page.meta?.attr) {
          acc = {
            ...acc,
            [page.meta?.attr]: groupe,
          };
        }
        return acc;
      }, {}),
    [pages]
  );

  const ListSelectGoupe =
    groupePages && page?.meta.attr
      ? groupePages[page.meta.attr].map((page) => ({
          key: page.key,
          value: page.key,
        }))
      : [];

  const handleSelectPage = (name: string, item: ItemType<string>) => {
    ref.current?.reset();
    const isCreate = pages?.find((page) => page.key === item.key);
    if (!isCreate) {
      dispatch(
        createPage({
          key: item.key,
          meta: {},
        })
      );
    }
    setSelected(item);
  };

  const handleSelectVideo = (_name: string, item: ItemType<string>) => {
    ref.current?.reset();
    const countMap = ListSelectGoupe.map((item) => item.key);
    let count = 0;
    let newItem: string | undefined = page?.key;
    if (newItem && NEW_VIDEO === item.key) {
      while (countMap.includes(newItem as SideBarKeyEnum)) {
        count++;
        newItem = `${page?.key}-${count}`;
      }
      dispatch(
        createPage({
          key: newItem,
          meta: {
            attr: selected?.key,
          },
        })
      );
    } else {
      setSelectedSub(item);
    }
  };

  const handleSave = () => {
    if (!page) return;
    const data = {
      key: subPage?.key || page.key,
      meta: {
        ...(subPage?.meta || page.meta),
        infoText,
        videoTitle,
      },
    };
    dispatch(updatePage({ pageId: subPage?.id || page.id, data }));
  };

  useEffect(() => {
    setInfoText(subPage?.meta?.infoText || page?.meta?.infoText || "");
    setVideoTitle(subPage?.meta?.videoTitle || page?.meta?.videoTitle || "");
  }, [page, subPage]);

  const handleInputText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInfoText(e.target.value);
  };

  const handleVideoTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(e.target.value);
  };

  const meta = {
    ...(subPage?.meta || page?.meta),
    infoText,
    videoTitle,
  };

  return (
    <div className={styles.container}>
      <div className={styles.groupSelect}>
        <div className={styles.select}>
          <Dropdown
            list={siedeBarTabsList}
            onSelect={handleSelectPage}
            selected={selected}
            label="Menu Page"
            name="Menu Page"
          />
        </div>
        {page?.key === SideBarKeyEnum.ODINCHAT && (
          <div className={styles.select}>
            <Dropdown
              list={[{ key: NEW_VIDEO, value: NEW_VIDEO }].concat(ListSelectGoupe)}
              onSelect={handleSelectVideo}
              selected={selectedSub || selected}
              label="Videos"
              name="Videos"
            />
          </div>
        )}
      </div>
      <VideoUpload ref={ref} page={subPage || page} selectedSub={selectedSub} meta={meta} />
      <div className={styles.inputText}>
        <InputItem
          onChange={handleVideoTitle}
          value={videoTitle}
          label="Title"
          name="Title"
          type="input"
          placeholder="Type video title"
        />
        <InputTextItem
          onChange={handleInputText}
          value={infoText}
          name="Info Description"
          label="Info Description"
          placeholder="Type escription"
        />
      </div>
      <div className={styles.saveButton}>
        {isLoading && <Loader />}
        <div>
          <Button disabled={isLoading} IconComponent={SaveIcon} onClick={handleSave} text="Save" />
        </div>
      </div>
    </div>
  );
};
