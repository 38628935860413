import { FC, Fragment, useState } from "react";

import { FeedbackModal } from "@/features";
import { useAppSelector } from "@/hooks";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { selectIsOpen } from "@/store/menu";

import styles from "./HelpMenu.module.scss";

import { ReactComponent as FeddbackIcon } from "@images/feedback-1.svg";

export const HelpMenu: FC = () => {
  const isMobileView = useMediaQuery("(max-width: 991px)");
  const isSidebarOpen = useAppSelector(selectIsOpen);
  const [showModal, setShowModal] = useState(false);
  const selectHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <div
        className={`${styles.container} ${
          isSidebarOpen ? "" : isMobileView ? "" : styles.collapsed
        }`}
        onClick={selectHandler}
      >
        <div className={styles.menuItem}>
          <div className={styles.iconWrapper}>
            <FeddbackIcon />
          </div>
          <span className={styles.itemText}>Feedback</span>
        </div>
      </div>
      <FeedbackModal show={showModal} onClose={closeModalHandler} />
    </Fragment>
  );
};
