import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { FC } from "react";

import { PDFColor, PDFfontSize } from "@/constants";

interface ISectionTitleProps {
  title: string;
}

const styles = StyleSheet.create({
  // section styles
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingBottom: 12,
    paddingTop: 12,
    borderBottom: "2px solid #80a1ca",
    marginBottom: 16,
  },
  sectionTitleText: {
    color: PDFColor.primary,
    fontSize: PDFfontSize.main,
    textTransform: "capitalize",
  },
});

export const SectionTitle: FC<ISectionTitleProps> = ({ title }) => {
  return (
    <View style={styles.sectionTitle}>
      <Text style={styles.sectionTitleText}>{title}</Text>
    </View>
  );
};
