import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { discoverService } from "@/services";
import { IMetaDetails } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IDiscoverdrsMetaState {
  drsMeta: IMetaDetails | null;
  isLoading: boolean;
}

const initialState: IDiscoverdrsMetaState = {
  drsMeta: null,
  isLoading: false,
};

export const getDrsMeta = createAsyncThunk(
  "discover/drsMeta",
  async ({ drsId, tableName }: { drsId: number; tableName: string }) => {
    const response = await discoverService.fetchMeta(drsId, tableName);
    return response.data;
  }
);

export const discoverDrsMetaSlice = createSlice({
  name: "discoverDrsMeta",
  initialState,
  reducers: {
    resetDrsMeta: (state) => {
      state.drsMeta = initialState.drsMeta;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrsMeta.fulfilled, (state, action) => {
        state.drsMeta = action.payload;
        state.isLoading = false;
      })
      .addCase(getDrsMeta.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDrsMeta.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const { resetDrsMeta } = discoverDrsMetaSlice.actions;

const state = (state: RootState) => state;

export const discoverDrsMetaState = createSelector(state, (state) => state.discoverDrsMeta);

export default discoverDrsMetaSlice.reducer;
