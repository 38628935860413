import { FC, useEffect, useRef, useState } from "react";

import { TUTORIALS } from "@/constants";
import { useOuterClick } from "@/hooks";
import { ITutorial } from "@/types/tutorial";

import { ModalLayout } from "../Layout";

import styles from "./Heading.module.scss";

import { ReactComponent as InfoIcon } from "@images/InfoIcon.svg";
import { ReactComponent as TootorialPlayIcon } from "@images/play-button.svg";

interface IProps {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
  children: React.ReactNode;
}

const faqDescriptionList = {
  "Some Question 1":
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nesciunt, molestiae nobis a dolorem laudantium explicabo asperiores! Cum, iusto quis!",
  "Some Question 2":
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nesciunt, molestiae nobis a dolorem laudantium explicabo asperiores! Cum, iusto quis!",
  "Some Question 3":
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nesciunt, molestiae nobis a dolorem laudantium explicabo asperiores! Cum, iusto quis!",
  "Some Question 4":
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nesciunt, molestiae nobis a dolorem laudantium explicabo asperiores! Cum, iusto quis!",
};

export const Heading: FC<IProps> = ({ className, children, type }) => {
  if (type === "h1")
    return (
      <h1 className={className ? className : `${styles.heading} ${styles.main}`}>{children}</h1>
    );
  if (type === "h2")
    return (
      <h2 className={className ? className : `${styles.heading} ${styles.sub}`}>{children}</h2>
    );
  if (type === "h3")
    return (
      <h3 className={className ? className : `${styles.heading} ${styles.min}`}>{children}</h3>
    );
  if (type === "h4")
    return (
      <h4 className={className ? className : `${styles.heading} ${styles.min16}`}>{children}</h4>
    );
  if (type === "h5")
    return (
      <h4 className={className ? className : `${styles.heading} ${styles.min14}`}>{children}</h4>
    );
  return null;
};

export interface IQuestionAnswer {
  question: string;
  answer: string;
}

interface IInfoProps {
  children: React.ReactNode;
  helpInfo?: string;
  tutorialLink?: ITutorial;
  faqList?: IQuestionAnswer[];
}

type InfoState = { info: boolean; faq: boolean; toggle: boolean };

const initalState = () => ({ info: false, faq: false, toggle: false });

export const InfoHeading: FC<IInfoProps> = ({ children, helpInfo, tutorialLink, faqList }) => {
  const [showInfoState, setShowInfoState] = useState(initalState);
  const [isTutorialVisible, setToggleTutorial] = useState(false);
  const [itemRef] = useOuterClick(setShowInfoState, initalState());
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const closeModalHandler = () => {
    setToggleTutorial(false);
  };

  const [videoContainerRef] = useOuterClick(closeModalHandler, false);

  const infoToggleHandler = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShowInfoState((prev) => ({
      ...prev,
      toggle: prev.toggle && prev.info ? false : true,
      info: !prev.info,
      faq: false,
    }));
  };

  const closeHandler = () => {
    setShowInfoState(initalState());
  };

  const faqToggleHandler = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShowInfoState((prev) => ({
      ...prev,
      toggle: prev.toggle && prev.faq ? false : true,
      info: false,
      faq: !prev.faq,
    }));
  };

  const showModalHandler = () => {
    setToggleTutorial(true);
  };

  useEffect(() => {
    if (videoRef.current && isTutorialVisible) {
      videoRef.current.oncanplay = () => {
        if (videoRef.current) videoRef.current.play();
      };
    }
  }, [isTutorialVisible]);

  return (
    <div
      className={`${styles.container} ${styles.info} ${
        helpInfo && helpInfo.length > 0 ? styles.active : ""
      } ${showInfoState.toggle ? styles.open : ""}`}
      ref={itemRef}
      onClick={closeHandler}
    >
      <div className={styles.titleGroup}>
        <h2 className={styles.heading}>{children}</h2>
        {helpInfo && <InfoIcon onClick={infoToggleHandler} />}
        <p className={`${styles.faq} ${!faqList ? styles.hide : ""}`} onClick={faqToggleHandler}>
          FAQs
        </p>
        {!!tutorialLink && (
          <span className={styles.tutorialBox} onClick={showModalHandler}>
            <span>Tutorial</span>
            <span>
              <TootorialPlayIcon />
            </span>
          </span>
        )}
      </div>
      <InfoDescription showInfoState={showInfoState} helpInfo={helpInfo} faqList={faqList} />

      <ModalLayout show={isTutorialVisible} onClose={closeModalHandler}>
        <div className={styles.videoTutorialContainer} ref={videoContainerRef}>
          <div className={styles.title}>{tutorialLink?.title}</div>
          <div className={styles.close} onClick={closeModalHandler}></div>
          <div className={styles.inner}>
            <video controls ref={videoRef}>
              <source src={tutorialLink?.url || TUTORIALS.default.url} type="video/mp4" />
            </video>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

interface IInfoDescriptionProps {
  helpInfo?: string;
  showInfoState: InfoState;
  faqList?: IQuestionAnswer[];
}

export const InfoDescription: FC<IInfoDescriptionProps> = ({
  showInfoState,
  helpInfo,
  faqList,
}) => {
  const clickHandler = (ev: React.MouseEvent) => {
    ev.stopPropagation();
  };
  return (
    <div className={`${styles.wrapper} ${showInfoState.toggle ? styles.show : ""}`}>
      <div className={styles.infoContainer} onClick={clickHandler}>
        {faqList && <FAQList show={showInfoState.faq} list={faqList} />}
        <HelpInfo show={showInfoState.info} helpInfo={helpInfo} />
      </div>
    </div>
  );
};

interface IFAQListProps {
  show: boolean;
  list: IQuestionAnswer[];
}

export const FAQList: FC<IFAQListProps> = ({ show, list }) => {
  const [active, setActive] = useState("");

  if (!show) return null;

  const selectHandler = (key: string) => {
    setActive((prev) => (prev !== key ? key : ""));
  };

  return (
    <div className={`${styles.faqDescription}`}>
      <h4 className={styles.subHeading}>FAQ</h4>
      <ul className={styles.list}>
        {list.map(({ question, answer }) => (
          <li
            className={`${styles.item} ${active === question ? styles.active : ""}`}
            key={question}
          >
            <p onClick={() => selectHandler(question)}>{question}</p>
            <p>{answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface IHelpInfo {
  show: boolean;
  helpInfo?: string;
}

export const HelpInfo: FC<IHelpInfo> = ({ show, helpInfo }) => {
  if (!show || !helpInfo) return null;
  return (
    <div className={`${styles.inner}`}>
      <h4 className={styles.subHeading}>Information</h4>
      <p dangerouslySetInnerHTML={{ __html: helpInfo }} />
    </div>
  );
};
