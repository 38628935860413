import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Dropdown, GoBack, Heading, InfoHeading, ItemType, Menu } from "@/components";
import { SideBarKeyEnum } from "@/constants";
import { useSaveToFolder } from "@/features/Forecast/components/hooks/useSaveToFolder";
import { useAppSelector, useAppDispatch, usePageInfo } from "@/hooks";
import { ReactComponent as FilterIcon } from "@/images/filter.svg";
import { ReactComponent as SaveIcon } from "@/images/save.svg";
import TableIcon from "@/images/tableIcon.svg";
import { discoverDrsMetaState, getDrsMeta, resetDrsMeta } from "@/store/discover/drsMeta";
import {
  discoverQueryDataState,
  resetSelected as resetSelectedData,
} from "@/store/discover/queryData";
import {
  discoverDrsDetailsState,
  getDrsDetails,
  resetSelected,
} from "@/store/discover/selectedDrs";
import { FolderTypeEnum, IFilterCriteria, ISorting, TIME_SERIES_TYPE } from "@/types";

import { Chart } from "../components/Chart/Chart";
import { DiscriptionModal } from "../components/DiscriptionModal/DiscriptionModal";
import { DrsTable } from "../components/DrsTable/DrsTable";
import { Filter } from "../components/Filter/Filter";
import { SelectTimeSeriesRange } from "../components/SelectTimeSeriesRange/SelectTimeSeriesRange";
import { TimeSeriesFormatSelect } from "../components/TimeSeriesFormatSelect/TimeSeriesFormatSelect";
import useQueryData from "../hooks/useQueryData";

import styles from "./DrsDetails.module.scss";

export const DrsDetails: FC = () => {
  const [selectedTable, setSelectedTable] = useState<ItemType<string> | null>();
  const [selectedSeriesType, setSelectedSeriasType] = useState<TIME_SERIES_TYPE>(
    () => TIME_SERIES_TYPE.DEFAULT
  );
  const [filterCriteria, setFilterCriteria] = useState<IFilterCriteria | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [showFilter, setShowFilter] = useState(false);
  const [folderKey, setFolderKey] = useState<string | number | null>(null);
  const [sort, setSort] = useState<ISorting[]>([]);
  const [hasResetSort, setResetSort] = useState(false);

  const { drsId } = useParams();
  const { drsData, isLoading } = useAppSelector(discoverDrsDetailsState);
  const { drsMeta, isLoading: isLoadingMeta } = useAppSelector(discoverDrsMetaState);
  const { meta, results, isLoading: isLoadingQueryData } = useAppSelector(discoverQueryDataState);
  const { textInfo, video } = usePageInfo(SideBarKeyEnum.DATA);

  const isSelectTimeSeriasMode = useMemo(
    () => selectedSeriesType === TIME_SERIES_TYPE.TIME_SERIES,
    [selectedSeriesType]
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const queryToSave = useQueryData({
    drsMeta, // Pass your props here
    filterCriteria,
    currentPage,
    perPage,
    selectedSeriesType,
    sort,
    isSelectTimeSeriasMode,
  });

  const { folders, handleSelectedFolder, handleAddDiscription } = useSaveToFolder(
    queryToSave,
    drsMeta?.label || "",
    FolderTypeEnum.COMMON_API
  );

  const onSelectFolder = (key: string | number) => {
    setFolderKey(key);
  };

  useEffect(() => {
    if (!drsId) return;
    dispatch(getDrsDetails(Number(drsId)));
    return () => {
      setSelectedTable(null);
      dispatch(resetSelected());
      dispatch(resetSelectedData());
    };
  }, [dispatch, drsId]);

  useEffect(() => {
    if (!drsData?.meta) return;
    setSelectedTable({
      key: drsData.meta.tables[0].name,
      value: drsData.meta.tables[0].label,
    });
  }, [dispatch, drsData, drsId]);

  useEffect(() => {
    if (!drsId || !selectedTable || isLoading) return;
    dispatch(getDrsMeta({ drsId: Number(drsId), tableName: selectedTable.key }));
    return () => {
      dispatch(resetDrsMeta());
    };
  }, [dispatch, drsId, isLoading, selectedTable]);

  const handleBack = () => {
    navigate("..");
    dispatch(resetSelected());
    dispatch(resetSelectedData());
    setSelectedTable(null);
    setResetSort(!hasResetSort);
  };

  const handleSelect = (_key: string, item: ItemType<string>) => {
    dispatch(resetDrsMeta());
    setFilterCriteria(undefined);
    setSelectedTable(item);
    setSelectedSeriasType(TIME_SERIES_TYPE.DEFAULT);
    setSort([]);
    setResetSort(!hasResetSort);
  };

  const perPageUpdate = (perPage: number) => {
    setPerPage(perPage);
  };
  const currentPageUpdate = (current: number) => {
    setCurrentPage(current);
  };

  const handlesetFilterCriteria = (data: IFilterCriteria) => {
    setFilterCriteria(data);
  };

  return (
    <div className={styles.wrapper}>
      <InfoHeading helpInfo={textInfo} tutorialLink={video}>
        Data
      </InfoHeading>
      <div className={styles.container}>
        <Heading type="h3" className={styles.subHeading}>
          {drsData?.name.includes("unassigned")}
        </Heading>
        <GoBack onClick={handleBack} />
        <div className={styles.tableSelector}>
          {!!drsData && (
            <Dropdown
              list={drsData?.meta.tables?.map((table) => ({
                key: table.name,
                value: table.label,
              }))}
              placeholder=""
              name="tables"
              label=""
              onSelect={handleSelect}
              selected={selectedTable || null}
              icon={TableIcon}
            />
          )}
        </div>
        <div className={styles.controls}>
          <div className={styles.firstActionsContainer}>
            <div className={styles.selectFormat}>
              <TimeSeriesFormatSelect
                selected={selectedSeriesType}
                setSelectedType={setSelectedSeriasType}
                filterCriteria={filterCriteria}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className={styles.rangeSelector}>
              <SelectTimeSeriesRange
                setFilterCriteria={handlesetFilterCriteria}
                filterCriteria={filterCriteria}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
          <div className={styles.actionsContainer}>
            <div className={styles.saveMenu}>
              <Menu menuItems={folders} handelMenuSelect={onSelectFolder}>
                <div className={styles.menuInner}>
                  <div className={styles.icon}>
                    <SaveIcon />
                  </div>
                  <span>Save</span>
                </div>
              </Menu>
            </div>
            <div className={styles.filter}>
              <FilterIcon className={styles.icon} onClick={() => setShowFilter(!showFilter)} />
            </div>
          </div>
        </div>
        <Filter
          setCurrentPage={setCurrentPage}
          setFilterCriteria={handlesetFilterCriteria}
          filterCriteria={filterCriteria}
          showFilter={showFilter}
          setSort={setSort}
          isSelectTimeSeriasMode={isSelectTimeSeriasMode}
        />
        <>
          {selectedSeriesType === TIME_SERIES_TYPE.TIME_SERIES ? (
            <Chart
              results={results}
              isLoading={isLoadingQueryData || isLoadingMeta || isLoading}
              height={400}
            />
          ) : (
            <div className={styles.tabel}>
              <DrsTable
                meta={meta}
                results={results}
                isLoading={isLoadingQueryData || isLoadingMeta || isLoading}
                seriesType={selectedSeriesType}
                setCurrentPage={currentPageUpdate}
                setPerPage={perPageUpdate}
                currentPage={currentPage}
                perPage={perPage}
                setSort={setSort}
                hasResetSort={hasResetSort}
              />
            </div>
          )}
        </>
        <DiscriptionModal
          folderKey={folderKey}
          setFolderKey={setFolderKey}
          handleAddDiscription={handleAddDiscription}
          handleSelectedFolder={handleSelectedFolder}
        />
      </div>
    </div>
  );
};
