import { FC } from "react";

import styles from "./Switch.module.scss";

interface IProps {
  checked: boolean;
  label: string;
  color?: string | string[];
  onChange?: (e: boolean, name?: string) => void;
  name?: string;
  disabled?: boolean;
}

const DEFACULT_COLOR = "var(--primary)";

export const Switch: FC<IProps> = ({ checked, label, onChange, color, name, disabled }) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked, name);
  };
  const backgroundColor =
    color && Array.isArray(color)
      ? checked
        ? color[0] || DEFACULT_COLOR
        : color[1] || DEFACULT_COLOR
      : color;
  return (
    <div
      className={`${styles.container} ${checked ? styles.checked : ""} ${
        disabled ? styles.disabled : ""
      } `}
    >
      <div className={styles.inner}>
        <label className={styles.label}>
          <span className={styles.switcher}>
            <span className={styles.slider} style={{ backgroundColor }}></span>
          </span>
          <p>{label}</p>
          <input
            disabled={disabled}
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChangeHandler}
          />
        </label>
      </div>
    </div>
  );
};
