import React, { FC } from "react";

import styles from "./ContentLayout.module.css";

interface IProps {
  children: React.ReactNode;
}

export const ContentLayout: FC<IProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
