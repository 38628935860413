import axios, { AxiosError } from "axios";

import { sentryReportError } from "@/constants";
import { API_ROUTES } from "@/constants/api.routes";

import { socketService } from "./socketio";

export const baseURL = process.env.REACT_APP_BASE_URL;
const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = process.env.REACT_APP_TOKEN;
const COMMON_API_URL =
  process.env.REACT_APP_COMMON_API_URL ||
  "https://n2pxhso7m6dqc1is.bigdatafed.com/v1/common-api-prototype";

axios.defaults.baseURL = baseURL;

const PAGE_PERMISSIONS_ERROR_CODE = 157;

export interface ITokens {
  access: string | null;
  refresh: string | null;
}

export interface IAccesTokenResponse {
  access: string;
}

export interface IAccesToken {
  refresh_token: string;
}

export const axiosInstance = axios.create({
  baseURL,
});

const $instansAxios = axios.create({
  baseURL: API_URL,
});

const $instansCommonApiAxios = axios.create({
  baseURL: COMMON_API_URL,
});

$instansAxios.interceptors.request.use((config) => {
  config.headers.Authorization = `Token ${TOKEN}`;
  return config;
});

class AuthService {
  keyNameAccess: string;
  keyNameRefresh: string;
  constructor(keyName: string) {
    this.keyNameAccess = keyName + "_access";
    this.keyNameRefresh = keyName + "_refresh";
  }
  saveTokens(access: string | null, refresh?: string | null) {
    if (access) {
      localStorage.setItem(this.keyNameAccess, access);
    }
    if (refresh) {
      localStorage.setItem(this.keyNameRefresh, refresh);
    }
  }
  getTokens(): ITokens | null {
    const access = localStorage.getItem(this.keyNameAccess);
    const refresh = localStorage.getItem(this.keyNameRefresh);
    return {
      access: access ? access : null,
      refresh: refresh ? refresh : null,
    };
  }
  isLogined() {
    return !!localStorage.getItem(this.keyNameRefresh);
  }
  logout() {
    localStorage.removeItem(this.keyNameRefresh);
    localStorage.removeItem(this.keyNameAccess);
    socketService.disconnect();
  }
  isPagePermissionsError(error: any) {
    return error.response?.data?.code === PAGE_PERMISSIONS_ERROR_CODE;
  }
  isNotAuthError(error: any) {
    return error.response?.status !== 401;
  }
  isAnyReportError(error: any) {
    return error.response?.status > 403 || (error.response?.status < 503 && error.response);
  }
}

export const AuthErrors = [401, 403];
export const authService = new AuthService("odin");

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url === API_ROUTES.resetPassword) return config;
    if (config.url === API_ROUTES.resetPasswordReques) return config;
    if (config.url === API_ROUTES.login) return config;
    if (config.url === API_ROUTES.registerUser) return config;

    config.headers.Authorization = `Bearer ${authService.getTokens()?.access}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const createAxiosInterceptor = (logout: () => void) => {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // if any error except 401
      if (authService.isNotAuthError(error)) {
        if (axios.isCancel(error)) return Promise.reject({ isCanceled: true });
        if (authService.isAnyReportError(error)) {
          try {
            sentryReportError(
              JSON.stringify({
                err: error.response?.data,
                code: error.response?.status,
                request: error.response.request,
              })
            );
          } catch (e) {
            sentryReportError("Some error form Axios response.");
          }
        }

        // on change page permissions status
        if (authService.isPagePermissionsError(error)) {
          axiosInstance.interceptors.response.eject(interceptor);
          createAxiosInterceptor(logout);
          logout();
        }

        return Promise.reject(error);
      }

      // eject instance to avoid auto loop
      axiosInstance.interceptors.response.eject(interceptor);
      const tokens = authService.getTokens();

      if (!tokens?.refresh) return Promise.reject(error);

      return axios
        .post(`${baseURL}/auth/access-token`, {
          refresh_token: tokens?.refresh,
        })
        .then((response) => {
          authService.saveTokens(response.data.access);
          error.response.config.headers.Authorization = `Bearer ${response.data.access}`;
          return axiosInstance(error.response.config);
        })
        .catch((refresh_error) => {
          logout();
          if (axios.isCancel(error)) return Promise.reject({ isCanceled: true });
          return Promise.reject(refresh_error);
        })
        .finally(() => createAxiosInterceptor(logout));
    }
  );
};

export type AxiosErrorStatus = AxiosError & { isCanceled: boolean };

export const getErrorStataus = (err: AxiosErrorStatus) => {
  if (err.isCanceled) return err;
  return err?.response?.status;
};

export { $instansAxios, $instansCommonApiAxios };

export default axiosInstance;
