import { FC } from "react";

import { SideBarKeyEnum } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { usePageInfo } from "@/hooks";

import { InfoHeading } from "../Heading/Heading";

import styles from "./DesctiptionSection.module.scss";

export const DesctiptionSection: FC = () => {
  const { textInfo, video } = usePageInfo(SideBarKeyEnum.MARKET_VIEW);

  return (
    <section className={styles.container}>
      <InfoHeading helpInfo={textInfo} tutorialLink={video} faqList={faqDescriptionList.marketView}>
        Description
      </InfoHeading>
      <div className={styles.inner}>
        <p className={styles.title}>Market Spider</p>
        <p className={styles.description}>
          This is proprietary measure showing scores along 6 dimension in the US financial market;
          macro, liquidity, fundamentals, valuation, sentiment and technicals. Macro indicates
          overall health of the US economy using key economic indicators. Liquidity measures the
          change in the money supply for the US financial system. Fundamentals measures growth and
          acceleration of key company fundamentals. Valuation indicates composite valuation rank for
          major US indices. Sentiment indicates the rank percentile of AAII bull bear spread.
          Technicals shows rank of market momentum
        </p>

        {/* <p className={styles.title}>Closest Market Pattern Matches</p>
        <p className={styles.description}>
          This is a proprietary real-time measure showing the market pattern closest matching the
          current market day using these 70+ indicators.
        </p> */}

        <p className={styles.title}>Valuation</p>
        <p className={styles.description}>
          This is provides the overall valuation for the major indices and sectors.
        </p>

        <p className={styles.title}>Total Market Put/Call Ratio Trend</p>
        <p className={styles.description}>
          This is Put-Call options contracts ratio for the whole US equity market.
        </p>

        <p className={styles.title}>Best/Worst Performing</p>
        <p className={styles.description}>
          This is provides real-time the “Best” and “Worst” performing tickers for each category
          based on market cap for any specific period.
        </p>

        <p className={styles.title}>Nominal Consumer Spending</p>
        <p className={styles.description}>
          This is a proprietary real-time aggregation providing the total amount of money spent by
          individuals on goods and services, without adjusting for inflation or changes in
          purchasing power.
        </p>
      </div>
    </section>
  );
};
