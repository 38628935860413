import { PageViews } from "@piwikpro/react-piwik-pro";
import { useEffect } from "react";

import { ROUTES } from "@/constants";

export const usePageView = (children: React.ReactNode) => {
  useEffect(() => {
    if (children && process.env.NODE_ENV === "production") {
      const pages = Object.values(ROUTES).filter((p) => p.path !== "/");
      const currentPage = pages.find((p) => window.location.href.includes(p.path)) as
        | { path: string; key?: string }
        | undefined;
      if (currentPage?.key) {
        PageViews.trackPageView(currentPage.key.replace("/", " > "));
      }
    }
  }, [children]);
};
