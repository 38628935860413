import { FC, memo } from "react";

import { TooltipWrapper } from "../Tooltip/Tooltip";

import styles from "./FileSelect.module.scss";

// import { ReactComponent as UploadIcon } from "@images/upload-icon.svg";

interface IProps {
  text: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  helpText?: string;
  isSelected: boolean;
  extentions?: string[];
}

export const FileSelect: FC<IProps> = memo(
  ({ text, onChange, disabled, helpText, isSelected, extentions }) => {
    const accept = extentions ? extentions.join(",") : undefined;
    return (
      <TooltipWrapper text={helpText}>
        <div className={`${styles.container} ${isSelected ? styles.selected : ""}`}>
          <input
            className={styles.uploadField}
            type="file"
            onChange={onChange}
            disabled={disabled}
            title=""
            accept={accept}
          />
          <div className={styles.groupContainer}>
            {/* <UploadIcon /> */}
            <p>{text}</p>
          </div>
        </div>
      </TooltipWrapper>
    );
  }
);
