import { FC, Fragment } from "react";

import styles from "./Skeleton.module.css";

interface ISkeletonProps {
  active?: boolean;
  rows?: number;
  block?: boolean;
  loading: boolean;
  lineHeight?: string;
  height?: string;
  children: React.ReactNode;
}
export const Skeleton: FC<ISkeletonProps> = ({
  active,
  rows = 1,
  block,
  loading,
  children,
  lineHeight,
  height = "100%",
}) => {
  return (
    <Fragment>
      {loading ? (
        <div className={styles.skeletonContainer} style={{ height }}>
          {new Array(!block ? rows : 1).fill("").map((_, idx) => (
            <div
              key={`skeleton-${idx}`}
              style={{ height: lineHeight }}
              className={`${styles.skeletonBlock} ${block ? styles.skeletonBox : ""} ${
                active ? styles.skeletonActive : ""
              }`}
            ></div>
          ))}
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};
