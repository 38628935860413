import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { PortfolioService } from "@/services";
import { notification } from "@/utils/notification";

import { IPremission } from "../../types/permission";
import { RootState } from "../index";

export interface EnvPermissions {
  isLoading: boolean;
  permissions: IPremission[];
  error: boolean;
}

const initialState: EnvPermissions = {
  isLoading: false,
  permissions: [],
  error: false,
};

export const setPortfolioPermissions = createAsyncThunk(
  "portfolios/createPermissions",
  async ({ id, data }: { id: number; data: IPremission[] }, thunkAPI) => {
    try {
      const permissions = await PortfolioService.setPortfolioPermissions(id, data);
      thunkAPI.dispatch(getPortfolioPermissions(id));
      return permissions.data.result;
    } catch (err) {
      throw thunkAPI.rejectWithValue(err);
    }
  }
);

export const getPortfolioPermissions = createAsyncThunk(
  "portfolios/getPermissions",
  async (id: number) => {
    const permissions = await PortfolioService.getPortfolioPermissions(id);
    return permissions.data.result;
  }
);

export const PortfoliosPermissionsSlice = createSlice({
  name: "permissionsSlice",
  initialState,
  reducers: {
    resetPermissions: (state) => {
      state.permissions = initialState.permissions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolioPermissions.fulfilled, (state, action) => {
        state.error = false;
        state.permissions = action.payload;
        state.isLoading = false;
      })
      .addCase(getPortfolioPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPortfolioPermissions.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(setPortfolioPermissions.fulfilled, (state) => {
        state.error = false;
        state.isLoading = false;
        notification.success("Permissions updated!");
      })
      .addCase(setPortfolioPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPortfolioPermissions.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
        notification.error("You do not have permissions to perform this operation.");
      });
  },
});

export const { resetPermissions } = PortfoliosPermissionsSlice.actions;

const state = (state: RootState) => state;

export const PortfoliosPermisionsState = createSelector(state, (state) => state.permissions);

export default PortfoliosPermissionsSlice.reducer;
