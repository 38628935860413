import { paginator } from "@/constants";

import { IUserPermissions } from "./permission";

export interface IPortfolioOverlay {
  strategies: (string | undefined)[];
  weights: (number | null)[];
}

export interface IPortfolioBase extends IPortfolioOverlay {
  rebalance: string;
}

export interface IPortfolioPayload {
  name: string;
  config: {
    base: IPortfolioBase;
    overlay: IPortfolioOverlay;
    benchmark?: string[];
    types?: Record<string, string>;
  };
}

export interface IPortfolio {
  id?: number;
  name: string;
  config: {
    base: IPortfolioBase;
    overlay: IPortfolioOverlay;
    benchmark?: string[];
    start_date?: string | null;
    end_date?: string | null;
    types?: Record<string, PortfolioStrategyTypeEnum>;
  };
  is_default: boolean;
  is_template: boolean;
  user_id?: number;
  company_id: number;
  created_at: string;
  updated_at: string;
  permissions: IUserPermissions;
  user: IPortfoliUser;
  show_on_home?: boolean;
}

export interface IPortfoliUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IMarkAsDefaultTemplatePlayload {
  is_default: boolean;
}

export interface IDuplicateResponse {
  result: IPortfolio;
}

export interface IDuplicate {
  is_template: boolean;
  name: string;
}

export interface IPortfolioListResponse {
  count: number;
  next: number | null;
  previous: number | null;
  result: IPortfolio[];
}

export interface IPortfolioResponse {
  result: IPortfolio;
  count: number;
  next: number | null;
  previous: number | null;
  results: IPortfolio[];
}

export interface IUserPortfolio {
  id: number;
  name: string;
  config: {
    base: string[];
    overlay: string[];
    benchmark?: string[];
  };
  "1_day": number | null;
  mtd: number | null;
  qtd: number | null;
  ytd: number | null;
  company_id: number | null;
  user_id: number;
  created_at: string;
  updated_at: string;
  inception_date: string;
}

export interface IPortfoliosListResponse {
  portfolios: IUserPortfolio[];
}

export interface IBenchmark {
  id: number;
  name: string;
  notes: string;
  ticker: string;
  updated_at: string;
}

export const enum PortfolioStrategyTypeEnum {
  USER_DEFINED = "user_defined",
  TICKER = "ticker",
  ETF = "etf",
  DEFAULT = "default",
}

export interface IPortfolioStrategy {
  name: string;
  type: PortfolioStrategyTypeEnum;
}

export interface IPortfolioStrategies {
  base: IPortfolioStrategy[];
  overlay: IPortfolioStrategy[];
}

export type Paginator = typeof paginator;

export interface IResponseErorr {
  error: {
    details: string;
    error_code: number;
    message: string;
    status_code: number;
    type: string;
  };
}

export interface IPaginator {
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  setPerPage: (page: number) => void;
  perPageList: number[];
}

export interface IAllocationFormData {
  allocation: number;
  start_date?: string;
  end_date?: string;
}
