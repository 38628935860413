import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components";

import styles from "./FileNotFound.module.scss";

import { ReactComponent as NotFoundIcon } from "@images/error-icon.svg";

interface IProps {
  title: string;
}

export const FileNotFound: FC<IProps> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <div className={styles.iconWrapper}>
          <NotFoundIcon />
        </div>
        <p>{title}</p>
      </div>
      <div>
        <Button text="Go Back" type="secondary" onClick={() => navigate("..")} />
      </div>
    </div>
  );
};
