import { FC } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";

import { useAppDispatch } from "@/hooks/redux";
import { duplicatePortfolio } from "@/store/portfolios/portfolio";
import { IDuplicate, IPortfolio } from "@/types";

import { PortfolioItem } from "../PortfolioItem/PortfolioItem";

export interface BoxProps {
  name: string;
}

interface IProps {
  portfolio: IPortfolio;
  hasMenu?: boolean;
  elementRef?: (element: HTMLDivElement | null, idx: number) => void;
  index?: number;
}

interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
  name: string;
}

export const DragPortfolioItem: FC<IProps> = ({ portfolio, hasMenu, elementRef, index }) => {
  const dispatch = useAppDispatch();
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "box",
      item: portfolio,
      end(item: any, monitor: any) {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          const data: IDuplicate = {
            is_template: false,
            name: item.name,
          };
          dispatch(duplicatePortfolio({ id: item.id, data }));
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [portfolio]
  );
  return (
    <div ref={drag} style={{ opacity }}>
      <PortfolioItem
        ref={(ref) => elementRef && elementRef(ref, index ?? 0)}
        portfolio={portfolio}
        hasMenu={hasMenu}
      />
    </div>
  );
};
