import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { PortfolioHedgingService } from "@/services";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface ITikersState {
  isLoading: boolean;
  results: any[];
}

const initialState: ITikersState = {
  results: [],
  isLoading: false,
};

export const fetchPortfolioHedgingList = createAsyncThunk("portfolioHedgingList/list", async () => {
  const res = await PortfolioHedgingService.getPortfoliosHedgingList();
  return res?.data.result;
});

export const createPortfolioHedging = createAsyncThunk(
  "portfolioHedgingList/create",
  async (data: FormData, { rejectWithValue }) => {
    const res = await PortfolioHedgingService.createPortfoliosHedging(data);
    try {
      return res?.data.result;
    } catch (err: any) {
      console.log(err);
      rejectWithValue(err);
    }
  }
);
export const updatePortfolioHedging = createAsyncThunk(
  "portfolioHedgingList/update",
  async ({ id, data }: { id: number; data: FormData }) => {
    const res = await PortfolioHedgingService.updatePortfolioHeding(id, data);
    return res?.data.result;
  }
);

export const deletePortfolioHedging = createAsyncThunk(
  "portfolioHedgingList/delete",
  async (id: number) => {
    const res = await PortfolioHedgingService.deletePortfolioHeding(id);
    return res?.data.result;
  }
);

export const portfolioHedgingSlice = createSlice({
  name: "portfolioHedgingList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioHedgingList.fulfilled, (state, action) => {
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPortfolioHedgingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioHedgingList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(createPortfolioHedging.fulfilled, (state, action) => {
        state.results.push(action.payload || []);
        state.isLoading = false;
      })
      .addCase(createPortfolioHedging.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPortfolioHedging.rejected, (state, action) => {
        state.isLoading = false;
        // console.log(action);
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(updatePortfolioHedging.fulfilled, (state, action) => {
        state.results = state.results.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        state.isLoading = false;
      })
      .addCase(updatePortfolioHedging.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePortfolioHedging.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(deletePortfolioHedging.fulfilled, (state, action) => {
        state.results = state.results.filter((item) => item.id !== action.payload.id);
        state.isLoading = false;
      })
      .addCase(deletePortfolioHedging.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePortfolioHedging.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const portfolioHedgingState = createSelector(state, (state) => state.portfolioHedging);

export default portfolioHedgingSlice.reducer;
