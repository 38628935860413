import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ROUTES, SideBarKeyEnum } from "@/constants";
import { usePreviewPerformanceData } from "@/features/StrategiesDashboard";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import { strategiesService } from "@/services";
import {
  fetchDeleteStrategy,
  initList,
  selectMyStrategies,
  setBasketTickersList,
} from "@/store/strategies/my_strategies";
import {
  setErrors,
  setActiveTab,
  resetStrategy,
  strategiesState,
  resetStrategyForm,
  fetchCreateStrategy,
  fetchUpdateStrategy,
  fetchPerformancePrefligth,
  fetchBenchmarksList,
} from "@/store/strategies/strategies";
import { INewStartegyForm } from "@/types";
import {
  isFormTouched,
  prepareEditForm,
  prepareNewStartegyData,
  validateForm,
  validateFormula,
  validateStrategyName,
} from "@/utils";

import { useDownloadLivePositions } from "./useDawnloadLivePositions";
import { useDownloadHistorical } from "./useDownloadHistorical";
import { useGetData } from "./useGetData";

export const useCreateStrategy = (isEditMode?: boolean) => {
  const { newStartegyForm, historical } = useAppSelector(strategiesState);
  const { list } = useAppSelector(selectMyStrategies);
  const [isSavingData, setIsSavingData] = useState(false);
  const [showModal, setShowModal] = useState({
    save: false,
    remove: false,
    live: false,
    backtest: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { strategyId } = useParams();
  const sectionRef = useRef<{ result: HTMLElement | null; empty: HTMLElement | null }>({
    result: null,
    empty: null,
  });
  const isDefaultMemorizedRef = useRef<boolean>(false);
  const [formPrevState, setFormPrevState] = useState<INewStartegyForm | null>(null);
  const { downloadCSV, progress } = useDownloadHistorical();
  const [downloadLivePositionSCV, livePositionProgress, cancel] = useDownloadLivePositions();
  const {
    tradingInstrument: { type },
  } = newStartegyForm;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { chartData, tableData, isPreviewReady, isPreviewLoading, columns } =
    usePreviewPerformanceData();

  const { isEmpty, isLoadingData } = useGetData({
    isEditMode,
    strategyId,
  });

  const strategyName = searchParams.get("name");

  const [canCreate, canPreview] = useMemo(() => validateForm(newStartegyForm), [newStartegyForm]);

  const canSaveOrCreate = useMemo(() => {
    if (isFormTouched(newStartegyForm, formPrevState)) return canCreate;
    return false;
  }, [formPrevState, newStartegyForm, canCreate]);

  const isVisibleLivePositions = formPrevState?.tradingInstrument.type === type && isEditMode;

  const { textInfo, video } = usePageInfo(SideBarKeyEnum.SINGLE_STRATEGY);

  const backHandler = useCallback(() => {
    navigate(`${ROUTES.modelingPortfolioBuilder.path}?current_tab=Single Strategy`);
    strategiesService.abort.abort();
  }, [strategiesService.abort]);

  const selectTabHandler = useCallback((key: string) => {
    dispatch(setActiveTab(key));
  }, []);

  const confirmBacktestHandler = useCallback(() => {
    const { config } = prepareNewStartegyData(newStartegyForm);
    if (newStartegyForm.benchmark) config.benchmark = newStartegyForm.benchmark;
    dispatch(fetchPerformancePrefligth({ data: { config } })).finally(() => {
      setShowModal((prev) => ({ ...prev, backtest: false }));
    });
    setShowModal((prev) => ({ ...prev, backtest: true }));
  }, [newStartegyForm]);

  const cancelBacktestHandler = () => {
    setShowModal((prev) => ({ ...prev, backtest: false }));
    strategiesService.abort.abort();
  };

  const removeHandler = () => {
    setShowModal((prev) => ({ ...prev, remove: true }));
  };

  const cancelHandler = () => {
    setShowModal((prev) => ({ ...prev, remove: false }));
  };

  const cancelSaveHandler = () => {
    setShowModal((prev) => ({ ...prev, save: false }));
  };

  const cancelLivePositionHandler = () => {
    setShowModal((prev) => ({ ...prev, live: false }));
    cancel.abort();
  };

  const confirmRemoveHandler = () => {
    if (strategyId)
      dispatch(fetchDeleteStrategy(Number(strategyId))).then(() => {
        navigate("..");
      });
    setIsSavingData(true);
    setShowModal((prev) => ({ ...prev, remove: false }));
  };

  const downloadHistoricalHandler = () => {
    const fileName = newStartegyForm.name
      ? `${newStartegyForm.name}_strategy_historical`
      : `Strategy_${strategyId}_historical`;
    if (historical) downloadCSV(fileName, historical);
  };

  const confirmLivePositionHandler = () => {
    if (strategyId) {
      const fileName = newStartegyForm.name;
      downloadLivePositionSCV(Number(strategyId), fileName).then(() => {
        setShowModal((prev) => ({ ...prev, live: false }));
      });
    }
    setShowModal((prev) => ({ ...prev, live: true }));
  };

  const confirmSaveStrategyHandler = useCallback(() => {
    const error = validateStrategyName(newStartegyForm.name);
    const entryStatus = validateFormula(newStartegyForm.conditions.entry.formula);
    const exitStatus = validateFormula(newStartegyForm.conditions.exit.formula);
    if (!entryStatus && !exitStatus && !error) {
      setIsSavingData(true);
      // update existing strategy
      if (isEditMode) {
        const id = Number.isInteger(Number(strategyId)) ? Number(strategyId) : null;
        dispatch(fetchUpdateStrategy({ form: newStartegyForm, id })).then(() => {
          dispatch(initList());
          dispatch(resetStrategyForm());
          setIsSavingData(false);
        });
        setShowModal((prev) => ({ ...prev, save: false }));
      }
      // create new startegy
      else {
        dispatch(fetchCreateStrategy(newStartegyForm)).then(() => {
          dispatch(initList());
          dispatch(resetStrategyForm());
          dispatch(setBasketTickersList([]));
          setIsSavingData(false);
        });
      }
    } else {
      dispatch(setErrors({ name: error, entryStatus, exitStatus }));
    }

    setFormPrevState(newStartegyForm);
    setShowModal((prev) => ({ ...prev, save: false }));
  }, [newStartegyForm, isEditMode, strategyId]);

  const saveStrategyHandler = () => {
    if (isEditMode) setShowModal((prev) => ({ ...prev, save: true }));
    else confirmSaveStrategyHandler();
  };

  const isEmptyBacktestingResult = useMemo(
    () => !chartData.some((i) => i.data.length > 0) && !tableData.length && isPreviewReady,
    [tableData, chartData, isPreviewReady]
  );

  useEffect(() => {
    if (isEmptyBacktestingResult) {
      sectionRef.current.empty?.scrollIntoView({ block: "center", behavior: "smooth" });
    } else {
      if (isPreviewReady) {
        sectionRef.current.result?.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [isPreviewReady, isEmptyBacktestingResult]);

  useEffect(() => {
    return () => {
      dispatch(resetStrategy());
    };
  }, []);

  useEffect(() => {
    if (newStartegyForm.benchmarkList === null) {
      dispatch(fetchBenchmarksList());
    }
  }, [newStartegyForm.benchmarkList]);

  useEffect(() => {
    if (list?.length && isDefaultMemorizedRef.current === false) {
      const strategy = list.find((s) => s.id === Number(strategyId));
      const { metricsList, tickers } = newStartegyForm.tradingInstrument.equityBaskets;
      if (strategy && metricsList) {
        const form = prepareEditForm(strategy, metricsList);
        setFormPrevState(form);
        dispatch(setBasketTickersList(tickers));
        isDefaultMemorizedRef.current = true;
      }
    }
  }, [list, strategyId, newStartegyForm]);

  useEffect(() => {
    return () => {
      strategiesService.abort.abort();
    };
  }, []);

  return {
    isEmptyBacktestingResult,
    isVisibleLivePositions,
    livePositionProgress,
    isPreviewLoading,
    canSaveOrCreate,
    newStartegyForm,
    isPreviewReady,
    isLoadingData,
    strategyName,
    isSavingData,
    canPreview,
    sectionRef,
    strategyId,
    historical,
    showModal,
    chartData,
    tableData,
    progress,
    isEmpty,
    columns,

    confirmSaveStrategyHandler,
    confirmLivePositionHandler,
    downloadHistoricalHandler,
    cancelLivePositionHandler,
    confirmBacktestHandler,
    cancelBacktestHandler,
    confirmRemoveHandler,
    saveStrategyHandler,
    cancelSaveHandler,
    selectTabHandler,
    cancelHandler,
    removeHandler,
    backHandler,

    textInfo,
    video,
  };
};
