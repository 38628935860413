import { FC } from "react";
import { NavLink } from "react-router-dom";

import { getUserAvatar, USER_PROFILE_LINK_NAME } from "@/constants";
import { useAppSelector, useUserPermissions } from "@/hooks";
import { ReactComponent as Arrow } from "@/images/arrow.svg";
import { ReactComponent as Logout } from "@/images/push-door-filled.svg";
import { profileInfo } from "@/store/profile";
import { UserActions } from "@/types";

import { profileMenuList } from "../UserMenu/UserMenu";

import styles from "./UserMenuMobile.module.scss";

interface IProps {
  handleLogout: () => void;
  handleClickMenu: () => void;
  hendleToggle: () => void;
  isOpen: boolean;
}

export const UserMenuMobile: FC<IProps> = ({
  handleLogout,
  handleClickMenu,
  hendleToggle,
  isOpen,
}) => {
  const { profile } = useAppSelector(profileInfo);
  const { hasUserActionTo } = useUserPermissions();

  const isUserRoleDisabled = (action: UserActions | null) => {
    return !hasUserActionTo(action);
  };

  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ""}`}>
      <div
        onClick={hendleToggle}
        className={`${styles.userInfoBlock} ${isOpen ? "" : styles.collapsed}`}
      >
        <div
          className={styles.bgImageMenu}
          style={{
            backgroundImage: `url(${getUserAvatar(profile?.profile_image)})`,
          }}
        />
        <div className={styles.userInfo}>
          <span className={styles.name}>
            {profile?.first_name} {profile?.last_name}
          </span>
          <span className={styles.email}>{profile?.email}</span>
        </div>
        <div className={styles.arrow}>
          <Arrow className={styles.arrowIcon} />
        </div>
      </div>

      <div className={`${styles.menuItems} ${isOpen ? styles.isOpen : ""}`}>
        <div className={styles.links}>
          {profileMenuList.map(({ key, label, icon, path, action }) => {
            if (isUserRoleDisabled(action)) return null;
            return (
              <NavLink onClick={handleClickMenu} to={path} key={key}>
                <div className={styles.menuIcon}>{icon}</div>
                <span>{label}</span>
              </NavLink>
            );
          })}
        </div>

        <div className={styles.logout} onClick={handleLogout}>
          <div className={styles.menuIcon}>
            <Logout />
          </div>
          <span>{USER_PROFILE_LINK_NAME.logout.label}</span>
        </div>
      </div>
    </div>
  );
};
