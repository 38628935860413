import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { updateProfileImage } from "@/store/profile";
import { notification } from "@/utils";

const useUploadImage = () => {
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const dispatch = useAppDispatch();

  const handleUpload = (image: File | null) => {
    if (image) {
      const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
      const isValidFormat = allowedFormats.includes(image.type);
      if (isValidFormat) {
        setProfileImage(image);
      } else {
        notification.info("Invalid file format. Please upload a JPG, JPEG, or PNG image.");
      }
    } else {
      setProfileImage(null);
    }
  };

  const handleClear = () => {
    setProfileImage(null);
  };

  const update = useCallback(async () => {
    if (!profileImage) return;
    const formData = new FormData();
    formData.append("profile_image", profileImage);
    dispatch(updateProfileImage(formData));
  }, [dispatch, profileImage]);

  useEffect(() => {
    update();
  }, [update]);

  return {
    profileImage,
    handleUpload,
    handleClear,
  };
};

export default useUploadImage;
