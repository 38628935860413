import { useMemo } from "react";

import { userRole } from "@/store/profile";
import { UserActions } from "@/types";

import { useAppSelector } from "./redux";

export const useUserPermissions = () => {
  const { isAdmin, isSuperAdmin, isSales, isRegular } = useAppSelector(userRole);

  const actions: Record<UserActions, boolean> = useMemo(
    () => ({
      invite: isSuperAdmin || isSales,
      "manage-user": isSuperAdmin || isAdmin || isSales,
      "manage-company": isSuperAdmin || isSales,
      download: isSuperAdmin,
      default: isSuperAdmin || isAdmin || isSales || isRegular,
      adminPanel: isSuperAdmin,
    }),
    [isSuperAdmin, isAdmin, isSales, isRegular]
  );

  const permissions = useMemo(() => {
    return {
      canInviteUser: (action: UserActions | null) =>
        action === "invite" && (isSuperAdmin || isSales),
      canManageUser: (action: UserActions | null) =>
        action === "manage-user" && (isSuperAdmin || isAdmin || isSales),
      canManageCompany: (action: UserActions | null) =>
        action === "manage-company" && (isSuperAdmin || isSales),
      canDownload: (action: UserActions | null) => action === "download" && isSuperAdmin,
      hasUserActionTo: (action: UserActions | null) => (action ? actions[action] : false),
      userRole: { isSuperAdmin, isAdmin, isSales },
    };
  }, [actions]);

  return permissions;
};
