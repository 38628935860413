import { createSlice, createAsyncThunk, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { ODIN_HELP_ID_PREFIX, odinChatServices } from "@/services";
import { IChatMessage, IMessageData, ISocketChatMessage, ISocketMessage } from "@/types/odinChat";
import { convertAsNewMessage, convertToChatMessages } from "@/utils";

import { RootState } from "./index";

export interface IPaging {
  page: number;
  perpage: number;
  preloader: boolean;
}

interface IInitialState {
  isLoading: boolean;
  list: IChatMessage[] | null;
  paging: IPaging;
  optionsList: string[] | null;
  unreaded: string[] | null;
  lastUnreadedId: number | null;
  lastUserMessageID: string | null;
  isStoped: boolean;
  isCancelInProgress: boolean;
}

export const DEFAULT_PER_PAGE = 10;

const initialState: IInitialState = {
  isLoading: true,
  list: null,
  paging: {
    page: 2,
    perpage: DEFAULT_PER_PAGE,
    preloader: false,
  },
  optionsList: null,
  unreaded: null,
  lastUnreadedId: null,
  lastUserMessageID: null,
  isStoped: false,
  isCancelInProgress: false,
};

export const fetchOptionsList = createAsyncThunk("odin-chat/fetchOptionsList", async () => {
  const { data } = await odinChatServices.getOptionsList();
  return data.result;
});

export const fetchMarkMessagesAsReaded = createAsyncThunk(
  "odin-chat/fetchMarkMessagesAsReaded",
  async (msgData: { uid: string; ids: string[]; isUnmarkAll?: boolean }) => {
    try {
      if (msgData.isUnmarkAll) {
        const lastID = msgData.ids.slice(-1)[0] as string;
        await odinChatServices.setAllMessagesAsReaded(lastID);
      } else await odinChatServices.setMessagesAsReaded(msgData);
      return { ids: msgData.ids };
    } catch (e) {
      console.log("Error....", e);
      return { ids: msgData.ids };
    }
  }
);

export const fetchMoreMessagesList = createAsyncThunk(
  "odin-chat/fetchMoreMessagesList",
  async (uid: string, { getState }) => {
    const { odinChat } = getState() as RootState;
    const perpage = odinChat.list?.length ? odinChat.list?.length : DEFAULT_PER_PAGE;
    const { data } = await odinChatServices.getMessages(uid, {
      page: 1,
      perpage: perpage + DEFAULT_PER_PAGE,
    });
    return data.result;
  }
);

export const fetchMessagesList = createAsyncThunk(
  "odin-chat/fetchMessagesList",
  async (uid: string, { getState }) => {
    const { odinChat } = getState() as RootState;
    const unreadedLen = odinChat.unreaded?.length || 0;
    const perpage = odinChat.list && odinChat.list.length ? odinChat.list.length : DEFAULT_PER_PAGE;
    const { data } = await odinChatServices.getMessages(uid, {
      page: 1,
      perpage: perpage + unreadedLen,
    });
    return data.result;
  }
);

export const fetchUserMessage = createAsyncThunk(
  "odin-chat/fetchUserMessage",
  async (msgData: IMessageData) => {
    const { data } = await odinChatServices.sendUserMessage(msgData);
    return data.result;
  }
);

export const fetchMessagesListSilent = createAsyncThunk(
  "odin-chat/fetchMessagesListSilent",
  async (uid: string, { getState }) => {
    const { odinChat } = getState() as RootState;
    const perpage = odinChat.list && odinChat.list.length ? odinChat.list.length : DEFAULT_PER_PAGE;
    const { data } = await odinChatServices.getMessages(uid, {
      page: 1,
      perpage,
    });
    return data.result;
  }
);

export const fetchUnreaded = createAsyncThunk("odin-chat/fetchUnreaded", async (uid: string) => {
  const { data } = await odinChatServices.getUnreaded(uid);
  return data.result;
});

export const fetchRefuseChatRequest = createAsyncThunk(
  "odin-chat/fetchRefuseChatRequest",
  async (messageData: { message: IChatMessage; id: string }) => {
    const { data } = await odinChatServices.cancelCahtRequest(messageData.id);
    return messageData.message;
  }
);

export const odinChatSlice = createSlice({
  name: "odin-chat",
  initialState,
  reducers: {
    init: (state) => {
      state.paging.page = 1;
      state.paging.preloader = false;
      state.isLoading = true;
      state.lastUserMessageID = null;
      state.isStoped = false;
      state.isCancelInProgress = false;
    },
    addMessage: (state, { payload }: PayloadAction<IChatMessage>) => {
      if (state.list) state.list.push(payload);
      else state.list = [payload];
    },
    unmarkNewUserMessage: (state) => {
      const foundMessage = state.list?.find((m) => m.is_new_user_message);
      if (foundMessage) foundMessage.is_new_user_message = false;
    },
    setNewChatMessage: (state, { payload }: PayloadAction<ISocketChatMessage>) => {
      if (state.isStoped) return;
      const newMessage =
        "id" in payload.Body ? convertAsNewMessage(payload.Body as ISocketMessage) : null;
      const isHelpMessage =
        "id" in payload.Body && payload.Body.id.toString().includes(ODIN_HELP_ID_PREFIX);

      // add new messge to list
      if (newMessage && state.list) {
        const ids = state.list.map((m) => m.id);
        const isExistMessage = ids.includes(newMessage.id);

        // updating old message with new message
        if (isExistMessage) {
          const foundOldMsg = state.list.find((msg) => msg.id === newMessage.id);
          if (foundOldMsg) {
            const index = state.list.indexOf(foundOldMsg);
            state.list[index] = { ...foundOldMsg, ...newMessage };
          }
        } else {
          state.list.push(newMessage);
        }
      }
      // set complete status
      if (state.list && payload.Body.status === "Completed") {
        state.list?.forEach((i) => {
          if (i.status === "In progress" || i.status === "Stopped") i.status = "Completed";
        });
      }
      // set failed status
      if (state.list && payload.Body.status === "Failed") {
        state.list.forEach((msg) => {
          if (msg.parent_id === payload.Body.parent_id || msg.id === payload.Body.parent_id) {
            msg.status = "Failed";
          }
        });
      }

      // add new message if list is empty
      if (!state.list && newMessage) {
        state.list = [newMessage];
      }

      // add new unreaded message ID
      if (state.unreaded && newMessage && !isHelpMessage) {
        const isNewUnreadedExist = !!state.unreaded.find((umsgID) => umsgID === newMessage.id);
        if (!isNewUnreadedExist) {
          state.unreaded.push(newMessage.id);
        }
      } else if (!state.unreaded && newMessage && !isHelpMessage) {
        state.unreaded = [newMessage.id];
      }
    },
    addOneTimeMessage: (
      state,
      { payload }: PayloadAction<{ messageItem: IChatMessage; newText: string; uid: string }>
    ) => {
      const userMSG: IChatMessage = { ...payload.messageItem, is_read: true, status: "Completed" };
      const newMSG: IChatMessage = {
        ...payload.messageItem,
        text: payload.newText,
        from_phone: "CHATBOT",
        parent_id: "None",
        status: "Completed",
        to_phone: payload.uid,
        id: `help-${new Date().valueOf()}`,
      };
      if (state.list) {
        state.list.push(userMSG, newMSG);
      } else {
        state.list = [userMSG, newMSG];
      }
    },
    setLastUserMessageID: (state, { payload }: PayloadAction<string>) => {
      state.lastUserMessageID = payload;
    },
  },
  // get messages list at first time
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMessagesList.fulfilled, (state, { payload }) => {
        state.list = convertToChatMessages(payload);
        state.list.reverse();
        state.isLoading = false;
      })
      .addCase(fetchMessagesList.rejected, (state, { payload }) => {
        state.isLoading = false;
      });

    // send user message to odin-bot
    builder
      .addCase(fetchUserMessage.pending, (state) => {
        state.isStoped = false;
      })
      .addCase(fetchUserMessage.fulfilled, (state, { payload }) => {
        const message = state.list?.find((msg) => msg.id === "-1") || null;
        if (message && payload.id) {
          message.id = payload.id.toString();
          state.lastUserMessageID = payload.id;
        }
      })
      .addCase(fetchUserMessage.rejected, (state, { payload }) => {
        //
      });

    // get messages in silent mode
    builder
      .addCase(fetchMessagesListSilent.pending, (state) => {
        //
      })
      .addCase(fetchMessagesListSilent.fulfilled, (state, { payload }) => {
        const newList = convertToChatMessages(payload);
        if (newList && state.list) {
          newList.reverse();
          const ids = state.list.map((m) => m.msg_id);
          const newIds = newList.map((m) => m.msg_id);
          const newMessages = newList.filter((msg) => !ids.includes(msg.msg_id));
          state.list.push(...newMessages);
          ids.forEach((msgId) => {
            if (newIds.includes(msgId)) {
              const updatedMessage = newList.find((m) => m.msg_id === msgId);
              const oldMessage = state.list?.find((m) => m.msg_id === msgId);
              const index = oldMessage && state.list ? state.list.indexOf(oldMessage) : -1;
              if (updatedMessage && oldMessage && index !== -1 && state.list) {
                state.list[index] = updatedMessage;
              }
            }
          });
        }
        if (!state.list && newList) {
          newList.reverse();
          state.list = newList;
        }
        if (newList && !state.unreaded?.length) {
          state.unreaded = newList.filter((msg) => msg.is_read === false).map((msg) => msg.id);
        }
      })
      .addCase(fetchMessagesListSilent.rejected, (state, { payload }) => {
        //
      });

    // get additiontal paginated messages
    builder
      .addCase(fetchMoreMessagesList.pending, (state) => {
        state.paging.preloader = true;
      })
      .addCase(fetchMoreMessagesList.fulfilled, (state, { payload }) => {
        const list = convertToChatMessages(payload);
        if (list) list.reverse();
        if (state.list) {
          const oldListIds = state.list.map((msg) => msg.id);
          const filtered = list.filter((i) => !oldListIds.includes(i.id));
          if (filtered.length) state.list.unshift(...filtered);
        } else {
          state.list = list;
        }
        state.paging.preloader = false;
      })
      .addCase(fetchMoreMessagesList.rejected, (state, { payload }) => {
        state.paging.preloader = false;
      });

    // mark messges as Readed
    builder
      .addCase(fetchMarkMessagesAsReaded.pending, (state) => {
        //
      })
      .addCase(fetchMarkMessagesAsReaded.fulfilled, (state, { payload }) => {
        if (payload.ids && state.unreaded) {
          state.list =
            state.list?.map((msg) => {
              const foundID = payload.ids.find((id) => msg.id === id);
              if (foundID) msg.is_read = true;
              return msg;
            }) || null;
          state.unreaded = null;
        }
      })
      .addCase(fetchMarkMessagesAsReaded.rejected, (state, { payload }) => {
        //
      });

    // get options help list
    builder
      .addCase(fetchOptionsList.pending, (state) => {
        //
      })
      .addCase(fetchOptionsList.fulfilled, (state, { payload }) => {
        state.optionsList = payload.result;
      })
      .addCase(fetchOptionsList.rejected, (state, { payload }) => {
        //
      });

    // check unreaded messages
    builder
      .addCase(fetchUnreaded.pending, (state) => {
        //
      })
      .addCase(fetchUnreaded.fulfilled, (state, { payload }) => {
        if (Array.isArray(payload)) {
          state.unreaded = payload.map((msg) => msg.id);
        }
        state.unreaded = [];
      })
      .addCase(fetchUnreaded.rejected, (state, { payload }) => {
        //
      });

    // refuse odin chat messages request
    builder
      .addCase(fetchRefuseChatRequest.pending, (state) => {
        state.isStoped = false;
        state.isCancelInProgress = true;
      })
      .addCase(fetchRefuseChatRequest.fulfilled, (state, { payload }) => {
        state.list?.forEach((item) => {
          if (item.status === "In progress") item.status = "Completed";
        });

        if (state.list) {
          state.list.push(payload);
        } else {
          state.list = [payload];
        }
        state.isStoped = true;
        state.isCancelInProgress = false;
      })
      .addCase(fetchRefuseChatRequest.rejected, (state, { payload }) => {
        state.isCancelInProgress = false;
      });
  },
});

const root = (state: RootState) => state;

export const odinChatState = createSelector(root, (state) => state.odinChat);

export const {
  init,
  addMessage,
  unmarkNewUserMessage,
  setNewChatMessage,
  addOneTimeMessage,
  setLastUserMessageID,
} = odinChatSlice.actions;

export default odinChatSlice.reducer;
