import { FC, useState } from "react";

import { useOuterClick } from "@/hooks";
import { ReactComponent as ActionsDotsIcon } from "@/images/menu-dots.svg";

import styles from "./ActionsMenu.module.scss";

import { ReactComponent as Arrow } from "@images/arrow.svg";

export interface IMenuItem {
  icon: JSX.Element;
  label: string;
  type: "copy" | "delete" | "edit" | "view";
  key: string;
  disabled?: boolean;
}

interface IProps {
  menuItems: IMenuItem[];
  onSelect: (item: IMenuItem) => void;
  disabled?: boolean;
}

export const ActionsMenu: FC<IProps> = ({ menuItems, onSelect, disabled }) => {
  const [toggle, setToggle] = useState(false);
  const [itemRef] = useOuterClick(setToggle, false);

  const toggleHandler = () => {
    if (!disabled) setToggle((prev) => !prev);
  };

  const selectHandler = (item: IMenuItem, ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    ev.stopPropagation();
    onSelect(item);
    setToggle(false);
  };

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ""}`}
      ref={itemRef}
      style={{
        minWidth: toggle ? "calc(100% - var(--spacing-m) - 4px)" : "32px",
        maxWidth: toggle ? "calc(100% - var(--spacing-m) - 4px)" : "32px",
      }}
      data-menu="menu-actions"
    >
      <ul className={`${styles.itemsList} ${toggle ? styles.open : ""}`} onClick={toggleHandler}>
        {menuItems.map((item) => (
          <li
            className={`${styles.item} ${item.disabled ? styles.disabled : ""}`}
            key={item.key}
            onClick={(ev) => selectHandler(item, ev)}
          >
            {item.icon}
            <p className={styles.label}>{item.label}</p>
          </li>
        ))}
      </ul>
      <div
        className={`${styles.navButtonContainer} ${toggle ? styles.openNav : ""}`}
        onClick={toggleHandler}
      >
        <div className={styles.arrowContainer}>
          <ActionsDotsIcon className={styles.menu} />
        </div>
        <div className={styles.arrowContainer}>
          <Arrow />
        </div>
      </div>
    </div>
  );
};
