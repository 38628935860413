import { AxiosResponse } from "axios";

import { ICompanyCalendarResult, IEconomicCalendarResult } from "@/types";

import axios from "./axios.config";

export const dataBrowserService = {
  getEconomicCalendar: (
    start?: string,
    end?: string,
    country?: string
  ): Promise<AxiosResponse<IEconomicCalendarResult>> => {
    return axios.get("/databrowser/economic-calendar", {
      params: {
        ...(start && { date_from: start }),
        ...(end && { date_to: end }),
        ...(country && { country }),
      },
    });
  },
  getCompanyCalendar: (
    start?: string,
    end?: string,
    tiker?: string,
    index?: string
  ): Promise<AxiosResponse<ICompanyCalendarResult>> => {
    return axios.get("/databrowser/company-calendar", {
      params: {
        ...(start && { date_from: start }),
        ...(end && { date_to: end }),
        ...(tiker && { tiker }),
        ...(index && { index }),
      },
    });
  },
  getCountryList: (): Promise<AxiosResponse<{ result: string[] }>> =>
    axios.get("/databrowser/economic-calendar/countries"),
};
