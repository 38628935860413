import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { InfoHeading, TabList } from "@/components";
import { TabContent } from "@/components/TabsList/TabContent";
import { TabsEnum, queryKeys, tabItemsList } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as DocIcon } from "@/images/document.svg";
import { ReactComponent as TutorialIcon } from "@/images/tutorialIcon.svg";
import { AdminDocumentsState, getDocuments } from "@/store/adminPanel/documents";

import { Documents } from "./components/Documents/Documents";
import { Tutorials } from "./components/Tutorials/Tutorials";

import styles from "./Resources.module.scss";

const tabIcons = {
  [queryKeys[TabsEnum.TUTORIALS]]: <TutorialIcon />,
  [queryKeys[TabsEnum.OVERVIEW]]: <DocIcon />,
  [queryKeys[TabsEnum.SOLUTIONS]]: <DocIcon />,
};

const tabsList = tabItemsList.map(({ key, name }) => ({
  key,
  name,
  icon: tabIcons[key],
}));

export const Resources: FC = () => {
  const { documents, isLoading } = useAppSelector(AdminDocumentsState);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string | null>(() => TabsEnum.TUTORIALS);
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    const targetTab = searchParams.get("target");
    const tab = tabsList.find((t) => t.key === targetTab);
    setActiveTab(tab?.name || TabsEnum.TUTORIALS);
  }, [tabsList]);

  const handleSelectTab = (tab: string) => {
    setActiveTab(tab);
    const targetTab = tabsList.find((t) => t.name === tab);
    setSearchParams({ target: (targetTab?.key || "tutorials") as TabsEnum });
  };

  const overviewList = documents?.filter((i) => i.meta?.folder === "overview") || null;
  const solutions = documents?.filter((i) => i.meta?.folder === "learn-more") || null;

  useEffect(() => {
    if (documents?.length) return;
    dispatch(getDocuments());
  }, [dispatch, documents?.length]);

  return (
    <div className={styles.container}>
      <InfoHeading>Resources</InfoHeading>
      <TabList tabsList={tabsList} active={activeTab} onSelect={handleSelectTab} />
      <TabContent id={TabsEnum.TUTORIALS} activeTab={activeTab}>
        <Tutorials />
      </TabContent>
      <TabContent id={TabsEnum.OVERVIEW} activeTab={activeTab}>
        <Documents isLoading={isLoading} list={overviewList} tab={TabsEnum.OVERVIEW} />
      </TabContent>
      <TabContent id={TabsEnum.SOLUTIONS} activeTab={activeTab}>
        <Documents isLoading={isLoading} list={solutions} tab={TabsEnum.SOLUTIONS} />
      </TabContent>
    </div>
  );
};
