import { useMemo, useState } from "react";

import { ItemType } from "@/components";
import { statusList, statusRadioList } from "@/constants";
import { IProfilePagePermissionType } from "@/types";
import { pagePermission } from "@/utils/pagesPermissions";

type AccessConfigActionType = React.Dispatch<
  React.SetStateAction<Record<string, IProfilePagePermissionType> | null>
>;
type AccessConfigType = Record<string, IProfilePagePermissionType> | null;

export const useSelectPageAccessProduct = (
  setAccessConfig: AccessConfigActionType,
  accessConfig: AccessConfigType
) => {
  const [product, setProduct] = useState<ItemType<string> | null>(null);

  const selectProductHandle = (name: string, item: ItemType<string>) => {
    setProduct(item);
  };

  const selectStatusHandle = (name: string) => {
    const item = statusList.find((i) => i.key === name);
    if (product && item) setAccessConfig((prev) => ({ ...prev, [product.key]: item.key }));
  };

  const activeStatus = useMemo(() => {
    return pagePermission.getActiveStatus(product, accessConfig, statusList);
  }, [accessConfig, product, statusList]);

  return {
    product,
    activeStatus,
    statusList: statusRadioList,
    selectProductHandle,
    selectStatusHandle,
  };
};
