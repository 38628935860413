import { AxiosResponse } from "axios";

import { ITikersResponse } from "@/types";

import axios from "./axios.config";

export const TikersService = {
  fetchTikersList: (): Promise<AxiosResponse<ITikersResponse>> => axios.get("/tickers/us-non-otc"),
  fetchTikersEtfsList: (): Promise<AxiosResponse<ITikersResponse>> => axios.get("/tickers/etfs"),
  fetchTikersSp500List: (): Promise<AxiosResponse<ITikersResponse>> => axios.get("/tickers/sp500"),
};
