import { TUTORIALS, TutorialHelpType } from "./tutorials";

export interface IHelpMessageReplaceList {
  message: string[];
  tutorial: TutorialHelpType["OdinChat"];
}

export const helpReplaceList: IHelpMessageReplaceList[] = [
  {
    message: ["help", "help odinchat", "help odin chat"],
    tutorial: TUTORIALS.OdinChat,
  },
  {
    message: ["help portfolio"],
    tutorial: TUTORIALS["multi-strategy"],
  },
  {
    message: ["help scenario"],
    tutorial: TUTORIALS.commodities,
  },
  {
    message: ["help strategy"],
    tutorial: TUTORIALS["single-strategy"],
  },
  {
    message: ["help forcast", "help odincast", "help odin cast"],
    tutorial: TUTORIALS.companies,
  },
  {
    message: ["help data", "help data portal", "help dataportal"],
    tutorial: TUTORIALS.data,
  },
];
